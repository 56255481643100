import React, { Component, Fragment } from "react";
import { Button, Modal, Tabs, notification, Form, Input, Icon, Row, Col } from "antd";
import { GoogleLogin } from 'react-google-login';
import { withStore } from '../../../hocs/withStore';
import _ from 'lodash';
import { axiosInstance as api } from '../../../utilities/configureAxios';
import url from '../../../config/url';
import Loading from '../../components/Loading';
import google from "../../../assets/images/icons/google.svg";
import phone from "../../../assets/images/icons/phone.svg";
// Importing images
import sb_logo from "../../../assets/images/sb-logo.svg";
import logo from "../../../assets/images/full-logo.svg";
import women from "../../../assets/images/user/icon-women.svg";
import { Helmet } from "react-helmet"


// for tabs
const { TabPane } = Tabs;
const TITLES = "Navigator"

class Login extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            phone_login_modal: false,
            show_otp: false,
            resendButton: true,
            data: {
                mobile_no: null
            }
        };
    }

    googleCallback = (response) => {
        this.setState({ loading: true });
        const { history, store } = this.props;
        api
            .post('/login', { access_token: response.accessToken })
            .then(res => {
                const name = _.get(res, 'data.data.name', null);
                const designation = _.get(res, 'data.data.designation', null);
                const email = _.get(res, 'data.data.email', null);
                const level = _.get(res, 'data.data.level', null);
                const profile_pic = _.get(res, 'data.data.profile_pic', null);
                const is_profile_complete = _.get(res, 'data.data.is_profile_complete', false);
                const access_token = _.get(res, 'data.data.access_token', null);
                const is_reporting_manager = _.get(res, 'data.data.is_reporting_manager', null);
                var user_roles = [];
                var user_roles_name = [];

                api.defaults.headers.common['Authorization'] = `Bearer ${access_token}`;

                api
                    .get(url["myRoles"])
                    .then(res => {
                        user_roles = _.get(res, 'data.data', [])
                        user_roles_name = _.map(user_roles, 'name', [])
                        store.setData({
                            user: { name, designation, email, level, token: access_token, is_reporting_manager },
                            profile_pic,
                            user_roles_name,
                            is_profile_complete
                        });

                        this.setState({ loading: false });
                        history.push('/dashboard/home');
                        notification['success']({
                            message: "Logged in successfully!"
                        });
                    })
                    .catch(error => {
                        notification['error']({
                            message: _.get(error, 'response.data.errors.message', "Oops! Something went wrong!")
                        });
                        this.setState({ loading: false });
                    });
            })
            .catch(error => {
                notification['error']({
                    message: _.get(error, 'response.data.errors.message', "Can not fetch roles!")
                });
                this.setState({ loading: false });
            });
    }

    googleCallbackError = (error) => {
        this.setState({ loading: false });
    }

    // modal
    state = {
        modal1: false,
        modal2: false,
    };

    handleCancel = e => {
        this.props.form.resetFields();
        this.setState({
            phone_login_modal: false, show_otp: false
        });
    };

    handleOK = e => {
        const { history, store } = this.props;
        e.preventDefault();
        this.props.form.validateFieldsAndScroll((err, values) => {

            if (!err) {
                this.setState({
                    show_otp: false,
                    loading: true,
                    phone_login_modal: false
                });

                api.
                    post(url["verifyOtp"], values)
                    .then(res => {

                        const name = _.get(res, 'data.data.name', null);
                        const designation = _.get(res, 'data.data.designation', null);
                        const email = _.get(res, 'data.data.email', null);
                        const level = _.get(res, 'data.data.level', null);
                        const profile_pic = _.get(res, 'data.data.profile_pic', null);
                        const is_profile_complete = _.get(res, 'data.data.is_profile_complete', false);
                        const access_token = _.get(res, 'data.data.access_token', null);
                        const is_reporting_manager = _.get(res, 'data.data.is_reporting_manager', null);
                        var user_roles = [];
                        var user_roles_name = [];

                        api.defaults.headers.common['Authorization'] = `Bearer ${access_token}`;

                        api
                            .get(url["myRoles"])
                            .then(res => {
                                user_roles = _.get(res, 'data.data', [])
                                user_roles_name = _.map(user_roles, 'name', [])
                                store.setData({
                                    user: { name, designation, email, level, token: access_token, is_reporting_manager },
                                    profile_pic,
                                    user_roles_name,
                                    is_profile_complete
                                });

                                this.setState({ loading: false });
                                history.push('/dashboard/home');
                                notification['success']({
                                    message: "Logged in successfully!"
                                });
                            })
                            .catch(error => {
                                notification['error']({
                                    message: _.get(error, 'response.data.errors.message', "Oops! Something went wrong!")
                                });
                                this.setState({ loading: false });
                            });




                    }).catch(error => {
                        this.setState({ show_otp: true })
                        const error_code = _.get(error, 'response.status');
                        if (error_code && error_code == '422') {
                            notification['error']({
                                message: Object.values(_.get(error, 'response.data.errors', "Oops! Something went wrong!"))
                            });
                        }
                        else {
                            notification['error']({
                                message: _.get(error, 'response.data.errors.message', "Oops! Something went wrong!")
                            });
                        }
                        this.setState({ loading: false, phone_login_modal: true });
                    });

            }
        });
    };

    sendOTP = e => {
        e.preventDefault();
        this.props.form.validateFieldsAndScroll((err, values) => {

            if (!err) {
                this.setState({
                    show_otp: true
                });

                api.
                    post(url["sendOtp"], values)
                    .then(data => {
                        notification['success']({
                            message: "OTP sent on your phone!"
                        });
                        this.setState({ loading: false, data: values });
                        setTimeout(() => this.setState({ resendButton: false }), 30000);
                    }).catch(error => {
                        this.setState({ show_otp: false })
                        const error_code = _.get(error, 'response.status');
                        if (error_code && error_code == '422') {
                            notification['error']({
                                message: Object.values(_.get(error, 'response.data.errors', "Oops! Something went wrong!"))
                            });
                        }
                        else {
                            notification['error']({
                                message: _.get(error, 'response.data.errors.message', "Oops! Something went wrong!")
                            });
                        }
                        this.setState({ loading: false });
                    });
            }
        });
    };

    reSendOTP = () => {

        this.setState({ resendButton: true })
        api.
            post(url["sendOtp"], this.state.data)
            .then(data => {
                notification['success']({
                    message: "OTP sent on your phone!"
                });
                this.setState({ loading: false });
                setTimeout(() => this.setState({ resendButton: false }), 30000);
            }).catch(error => {
                this.setState({ show_otp: false })
                const error_code = _.get(error, 'response.status');
                if (error_code && error_code == '422') {
                    notification['error']({
                        message: Object.values(_.get(error, 'response.data.errors', "Oops! Something went wrong!"))
                    });
                }
                else {
                    notification['error']({
                        message: _.get(error, 'response.data.errors.message', "Oops! Something went wrong!")
                    });
                }
                this.setState({ loading: false });
            });

    }
    phoneLogin = () => {
        this.setState({
            phone_login_modal: true
        });
    };



    render() {
        const { store: { data: { user } } } = this.props;
        const {store:{data:{user_roles_name}}} = this.props;
        const is_contract_employee = user_roles_name.indexOf("contract_employee") != -1;
        if (user && user.token) {
            if(is_contract_employee){
                window.location = "/dashboard/work-reports";
            }else{
                window.location = "/dashboard/home";  
            }
        }
        const { loading, show_otp, resendButton } = this.state;
        const { getFieldDecorator } = this.props.form;
        return (
            <Fragment>
                <Helmet>
                    <title>
                        {TITLES}
                    </title>
                </Helmet>

                {loading && (
                    <div className="loader">
                        <Loading />
                    </div>
                )}
                <div className="main-login-container">
                    <div className="login-container">
                        <div className="login-content">
                            <div className="login-box">
                                <div className="logo-box mb--60">
                                    <img className="company-logo" src={logo} alt="logo" />
                                </div>
                                <h1 className="header-title lg">Login to <span className="highlight">Navigator</span></h1>
                                <p className="header-description info lg">Login to start using your employee dashboard</p>
                                <div className="heroCTA">
                                    <GoogleLogin
                                        clientId={`${process.env.REACT_APP_GOOGLE_CLIENT_ID}`}
                                        render={renderProps => (
                                            <Button className="btn primary-shadow" size="large" onClick={renderProps.onClick}><img className="google-icon" src={google} alt="google icon" /> <span className="pl--10">Login with Google</span></Button>
                                        )}
                                        onSuccess={this.googleCallback}
                                        onFailure={this.googleCallbackError}
                                    />
                                    <Button className="btn primary-shadow phone-button" size="large" onClick={this.phoneLogin}><Icon className="phone-icon" type="phone" /> <span className="pl--10">Login using Mobile</span></Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="powered-by-box">
                    <div className="link-content">
                        <p className="text">Interested in trying out Navigator for your organization? <a className="link" href="https://forms.gle/AigPvrrjJFpocEyV8" target="_blank">Click here</a></p>
                    </div>
                    <div className="logo-content">
                        <p className="text-poweredby sm">Powered by</p>
                        <a href="https://squareboat.com" target="_blank"><img className="footer-logo" src={sb_logo} alt="logo" /></a>
                    </div>
                </div>

                <Modal
                    title="Mobile Login"
                    visible={this.state.phone_login_modal}
                    footer={null}
                    // onOk={this.handleOk}
                    onCancel={this.handleCancel}
                >
                    <div className="form-content">
                        <Row className="row" gutter={30}>
                            <Col className="gutter-row col" xs={24} sm={24} md={24} lg={24} xl={24}>
                                <div className="">
                                    <Form.Item label="Mobile Number" className="" colon={false}>
                                        {getFieldDecorator('mobile_no', {
                                            rules: [
                                                {
                                                    required: true,
                                                    message: 'Please enter mobile number!'
                                                },
                                                {
                                                    max: 10,
                                                    min: 10,
                                                    message: 'Please enter valid mobile number!'
                                                }
                                            ],
                                            validateTrigger: 'onBlur'
                                        })(
                                            <Input className="input" type="text" inputMode="numeric" pattern="[0-9]*" maxLength="10" minLength="10" disabled={show_otp} placeholder="Enter Mobile No." onBlur={e => e.target.value = e.target.value.replace(/\D/g, '')} />
                                        )}
                                    </Form.Item>
                                </div>
                            </Col>
                        </Row>
                    </div>
                    <div className="form-content">
                        <Row className="row" gutter={30}>
                            <Col className="gutter-row col" xs={24} sm={24} md={24} lg={24} xl={24}>
                                <div className="">
                                    {show_otp &&
                                        <Form.Item label="OTP" className="" colon={false}>
                                            {getFieldDecorator('otp', {
                                                rules: [{ required: true, message: 'Please enter otp!' }],
                                                validateTrigger: 'onBlur'
                                            })(
                                                <Input className="input" type="text" inputMode="numeric" pattern="[0-9]*" maxLength="6" placeholder="Enter OTP" onBlur={e => e.target.value = e.target.value.replace(/\D/g, '')} />
                                            )}
                                        </Form.Item>}
                                </div>
                            </Col>
                        </Row>
                    </div>
                    <div className="text-right">
                        {show_otp && <Button className="btn btn-light-warning text-uppercase  ml--10" size="large" disabled={resendButton} onClick={this.reSendOTP}>Resend OTP</Button>}
                        <Button className="btn btn-light-success text-uppercase ml--10" size="large" onClick={show_otp ? this.handleOK : this.sendOTP}>{show_otp ? "Submit" : "Send OTP"}</Button>
                    </div>
                </Modal>

            </Fragment>
        )
    }
}

export default Form.create({ name: 'login_form' })(withStore(Login));