import React from "react";
import {
  Icon,
  Button,
  Input,
  Drawer,
  Row,
  Col,
  Modal,
  Pagination,
  Tooltip,
  Spin,
  Empty,
} from "antd";

import { getFilteredLeave } from "../../../DataManager";
import EditLeaveType from "../../EditLeaveType/EditLeaveType";
import FilterLeave from "../../../../FilterLeave";
import AddLeaveType from "../../AddLeaveType/AddLeaveType";

const ManageLeavesTypes = ({ setButtonVisible,   }) => {
  const [isDrawerVisible, setIsDrawerVisible] = React.useState(false);
  const [leaveData, setLeaveData] = React.useState([]);
  const [loader, setLoader] = React.useState(false);
  const [pageNumber, setPageNumber] = React.useState(1);
  const [paginationTotal, setPaginationTotal] = React.useState(null);
  const [leaveID, setLeaveID] = React.useState("");
  const [modalVisible, setModal1Visible] = React.useState(false);
  const [leaveTypesModalVisible, setLeaveTypesModal1Visible] = React.useState(false);
  const [clickedOnSave, setClickedOnSave] = React.useState(false);
  const [filterData, setFilterData] = React.useState({});
  const [searchValue, setSearchValue] = React.useState("");
  const [showReseTButton, setShowResetButton] = React.useState("");
  const [activeData, setActiveData] = React.useState("");
  const [dataUpdate,setDataUpdate]=React.useState(false);

  // HIDING CORNER BUTTONS
  // React.useEffect(() => {
  //   setButtonVisible((prevValue) => {
  //     return {
  //       ...prevValue,
  //       addLeave: false,
  //       leaveType: true,
  //       createLeaveType: false,
  //     };
  //   });
  // }, []);

  // SHOW DRAWER
  const showDrawer = (id) => {
    setLeaveID(id);
    setIsDrawerVisible(true);
  };

  // CLOSE DRAWER
  const closeDrawer = () => {
    setIsDrawerVisible(false);
  };

  // Fetching Leave Data
  const loadLeaveData = () => {
    let filteredData = {};
    Object.keys(filterData).forEach((key) => {
      if (filterData[key] !== undefined) {
        filteredData[key] = filterData[key];
      }
    });
    return getFilteredLeave({ ...filteredData, page: pageNumber })
      .then((res) => {
        
        setPaginationTotal(res.meta.pagination.total);
        setLeaveData(res.data);
        setLoader(false);
      })
      .catch((error) => {
        // notification.error({
        //   message: error.response.data.errors.message,
        // });
      });
  };

  // RESET filter
  const resetFilter = () => {
    setFilterData({});
    setPageNumber(1);
    setShowResetButton(false);
    setActiveData("");
  };

  // Handling Pagination
  const handlePagination = (pageNumber) => {
    setPageNumber(pageNumber);
  };

  // When Clicked On Save Button
  React.useEffect(() => {
    if (clickedOnSave) {
      setLoader(true);
      loadLeaveData(pageNumber);
      setClickedOnSave(false);
    }
  }, [clickedOnSave]);

  // WHEN PAGINATION IS CHANGED
  React.useEffect(() => {
    // setLoader(true);
    loadLeaveData(pageNumber);
    setDataUpdate(false)
  }, [pageNumber, filterData,dataUpdate]);

  React.useEffect(() => {
    setLoader(true);
  }, [pageNumber,dataUpdate]);

  // HandleSearch
  const handleSearch = (e) => {
    const value = e.target.value;
    setSearchValue(value);
  };

  React.useEffect(() => {
    setFilterData((prev) => ({ ...prev, q: searchValue }));
  }, [searchValue]);

  return (
    <>
      {loader && (
        <div className="centerMe" style={{height :'500px',display:'flex',alignItems:'center',justifyContent:'center'}}>
          <Spin />
        </div>
      )}

      {!loader && (
        <section className="content-section manageLeaves-section">
          <div className="content">
            <div className="content-header single-searchBar leave-types-display" >
              <div className="right single-item">
                {/* ->>>>>>>>>>>>>>>>>>>>>>>>>>>> If flter ki koi bhi condition true hogi toa reset visible hoga warna nahi */}
                {showReseTButton && (
                  <Button
                    className="btn btn-danger text-uppercase"
                    onClick={resetFilter}
                  >
                    <Icon type="close" /> Reset
                  </Button>
                )}
                <Button
                  className="btn btn-warning text-uppercase"
                  onClick={() => setModal1Visible(true)}
                >
                  <Icon type="filter" /> Filter
                </Button>
                <div className="search-box">
                  <Input
                    onChange={handleSearch}
                    value={searchValue}
                    placeholder="Search"
                    suffix={<Icon type="search" />}
                  />
                </div>
              </div>

              <Button
                className="btn text-uppercase btn-primary btn-one margin-small-screen"
                onClick={() =>
                  setLeaveTypesModal1Visible(true)
                }
              >
                <span className="lg-hide">
                  <Icon type="form" />
                </span>
                Add Leave Type
              </Button>

            </div>

            <div className="content-body">
              <div className="head-row-box sm-hide">
                <Row className="row" style={{marginBottom:'2rem',padding:'1rem'}}>
                  {/* NAME */}
                  <Col
                    className="col"
                    xs={24}
                    sm={24}
                    md={24}
                    lg={8}
                    xl={8}
                    xxl={8}
                  >
                    <div className="head-title item name" style={{paddingLeft:'6px',color: '#0D264C',fontSize: '1rem'}}>Name</div>
                  </Col>

                  {/* APPLICABLE FOR ALL */}
                  <Col
                    className="col"
                    xs={24}
                    sm={24}
                    md={6}
                    lg={4}
                    xl={4}
                    xxl={4}
                  >
                    <div className="head-title item applicableForAll" style={{color: '#0D264C',fontSize: '1rem'}}>
                      Applicable For All
                    </div>
                  </Col>

                  {/* AUTOMATED */}
                  <Col
                    className="col"
                    xs={24}
                    sm={24}
                    md={6}
                    lg={4}
                    xl={4}
                    xxl={4}
                  >
                    <div className="head-title item automated" style={{color: '#0D264C',fontSize: '1rem'}}>Automated</div>
                  </Col>

                  {/* ACTIVE */}
                  <Col
                    className="col"
                    xs={24}
                    sm={24}
                    md={6}
                    lg={4}
                    xl={4}
                    xxl={4}
                  >
                    <div className="head-title item active" style={{color: '#0D264C',fontSize: '1rem'}}>Active</div>
                  </Col>

                  {/* ACTION */}
                  <Col
                    className="col"
                    xs={24}
                    sm={24}
                    md={6}
                    lg={4}
                    xl={4}
                    xxl={4}
                  >
                    <div className="head-title item action" style={{color: '#0D264C',fontSize: '1rem'}}>Actions</div>
                  </Col>
                </Row>
              </div>
              <div className="body-row-box">
                {/* EMPLOYEE CARD START FROM HERE */}
                {leaveData.map((leave_type) => {
                  return (
                    <div className="card emp-card" key={leave_type.id}>
                      <Row className="row manage-leave-types-display">
                        {/* NAME */}
                        <Col
                          className="col"
                          xs={24}
                          sm={16}
                          md={24}
                          lg={8}
                          xl={8}
                          xxl={8}
                        >
                          <div className="empName-box fx fx--ai-c" style={{color: '#0D264C',fontSize: '0.9rem'}}>
                            <div className="empName">
                              <span className="label lg-hide">Name: </span>
                              {leave_type.name}
                              {/* <span className="lg-hide me-margin-bottom"></span> */}
                            </div>
                          </div>
                        </Col>

                        {/* Applicable For All */}
                        <Col
                          className="col"
                          xs={24}
                          sm={8}
                          md={6}
                          lg={4}
                          xl={4}
                          xxl={4}
                        >
                          <div className="empName-box fx fx--ai-c">
                            <div className="empName" style={{color: '#0D264C',fontSize: '0.9rem'}}>
                              <span className="label lg-hide">
                                Applicable For All:{" "}
                              </span>
                              {leave_type.is_applicable_for_all
                                ? "True"
                                : "False"}
                            </div>
                          </div>
                        </Col>

                        {/* Automated */}
                        <Col
                          className="col"
                          xs={24}
                          sm={8}
                          md={6}
                          lg={4}
                          xl={4}
                          xxl={4}
                        >
                          <div className="empName-box fx fx--ai-c">
                            <div className="empName" style={{color: '#0D264C',fontSize: '0.9rem'}}>
                              <span className="label lg-hide">Automated: </span>
                              {leave_type.is_automated ? "True" : "False"}
                            </div>
                          </div>
                        </Col>

                        {/* Active */}
                        <Col
                          className="col"
                          xs={24}
                          sm={8}
                          md={6}
                          lg={4}
                          xl={4}
                          xxl={4}
                        >
                          <div className="empName-box fx fx--ai-c">
                            <div className="empName" style={{color: '#0D264C',fontSize: '0.9rem'}}>
                              <span className="label lg-hide">Active: </span>
                              {leave_type.is_active ? "True" : "False"}
                            </div>
                          </div>
                        </Col>

                        <Col
                          className="col"
                          xs={24}
                          sm={8}
                          md={6}
                          lg={4}
                          xl={4}
                          xxl={4}
                        >
                          <div className="actions-box " style={{paddingLeft:'11px'}}>
                            <span className="label lg-hide me-margin-bottom-1">
                              {" "}
                            </span>
                            <div className="fx fx--ai-c me-flex-end">
                              <Tooltip placement="bottom" title={"Edit Leave"}>
                                <Button
                                style={{fontSize: '0.9rem'}}
                                  type="link"
                                  onClick={() => {
                                    showDrawer(leave_type.id);
                                  }}
                                  className="btn btn-light-info btn-icon text-uppercase"
                                >
                                  <Icon type="edit" theme="filled" />
                                </Button>
                              </Tooltip>
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>

          {/* for Edit leaves */}
          <Drawer
            className="navigator-drawer manageLeaves-drawer"
            title="Manage"
            placement="right"
            width={500}
            onClose={closeDrawer}
            visible={isDrawerVisible}
            bodyStyle={{ padding: 0 }}
            destroyOnClose={true}
          >
            <EditLeaveType
              id={leaveID}
              isDrawerVisible={isDrawerVisible}
              setClickedOnSave={setClickedOnSave}
              setIsDrawerVisible={setIsDrawerVisible}
            />
          </Drawer>
        </section>
      )}

      {/* filter Modal */}
      <Modal
        className="filter-modal profileAp-modal"
        title="Filter Leave"
        centered
        visible={modalVisible}
        onOk={() => setModal1Visible(false)}
        onCancel={() => setModal1Visible(false)}
        footer={null}
        destroyOnClose={true}
      >
        <div className="filter-employee-modal-container">
          <FilterLeave
            filterData={filterData}
            setFilterData={setFilterData}
            setShowResetButton={setShowResetButton}
            setModal1Visible={setModal1Visible}
            activeData={activeData}
            setActiveData={setActiveData}
          />
        </div>
      </Modal>

      <Drawer
            className="navigator-drawer createLeaveType-drawer"
            title="Add Leave Type"
            placement="right"
            width={500}
            onClose={() =>
              setLeaveTypesModal1Visible(false)
            }
            visible={leaveTypesModalVisible}
            bodyStyle={{ padding: 0 }}
            destroyOnClose={true}
          >
            <AddLeaveType closeModal={setLeaveTypesModal1Visible} setDataUpdate={setDataUpdate} />
          </Drawer>

      {/* Pagination */}
      {!loader &&
        (leaveData ? (
          <section className="pagination-container">
            <div className="pagination-box text-right">
              <Pagination
                className="pagination"
                defaultCurrent={pageNumber}
                total={paginationTotal}
                onChange={handlePagination}
              />
            </div>
          </section>
        ) : (
          <Empty />
        ))}
    </>
  );
};

export default ManageLeavesTypes;
