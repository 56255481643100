import React from "react";

import {
  Icon,
  Button,
  Card,
  Select,
  Collapse,
  Row,
  Col,
  Popover,
  Drawer,
  Pagination,
  Spin,
  Empty,
  notification
} from "antd";

import leave from "../../../assets/images/icons/sibebar/leave-management.svg";
import info from "../../../assets/images/icons/leave/info.svg";
import CardHeader from "./MultipleDay/CardHeader";
import ApplyForLeave from "./ApplyLeave/ApplyForLeave.js";
import { getEmployeeLeave, getMyAllLeave } from "./DataManager";
import ProfileComplete from "../../../hocs/ProfileComplete";
import NotificationUpdate from "../../../hocs/NotificationUpdate";
import { withStore } from "../../../hocs/withStore";
import RequireAuth from "../../../hocs/RequireAuth";


const LeaveDashboard = ({componentRefresh, setComponentRefresh}) => {
  const [fileImages, setFileImages] = React.useState([]);
  const [visible, setVisible] = React.useState(false);
  const [paginationTotal, setPaginationTotal] = React.useState(null);
  const [year, setYear] = React.useState([]);
  const [leaveCard, setLeaveCard] = React.useState([]);
  const [myLeaves, setMyLeaves] = React.useState([]);
  const [loader, setLoader] = React.useState(true);
  const [leavesLoader, setLeavesLoader] = React.useState(false);
  const [payload, setPayload] = React.useState([]);
  const [pageNumber, setPageNumber] = React.useState(1);

  

  // FETCHING all leaves
  const loadLeavesOfEmployee = () => {
    return getEmployeeLeave()
      .then((res) => {
        setLeaveCard(res.data);
      })
      .catch((error) => {
        notification.error(error.response.data.errors);
      });
  };

  React.useEffect(() => {
    loadLeavesOfEmployee();
    loadMyLeave();
  }, [pageNumber,payload]);

  React.useEffect(()=>{
    loadLeavesOfEmployee()
  },[myLeaves])

// Handling Pagination
const handlePagination = (pageNumber) => {
  setPageNumber(pageNumber);
};



  // FETCHING my leave
  const loadMyLeave = () => {
    setLeavesLoader(true);
    return getMyAllLeave(payload,pageNumber)
      .then((res) => {
        setPaginationTotal(res.meta.pagination.total);
        setMyLeaves(res.data);
        setLeavesLoader(false);
        setLoader(false);
      })
      .catch((error) => {
        notification.error(error.response.data.errors);
        setLeavesLoader(false);
        setLoader(false);
      });
  };

  // SETTING YEAR
  const handleYear = (value) => {
    setPayload(value);
  };

  // MAPPING YEAR
  React.useEffect(() => {
    const allYears = [];
    for (let i = Number(new Date().getFullYear()) + 1; i >= Number(2021); i--) {
      allYears.push(i);
    }
    setYear(allYears);
  }, []);

  // year selectbox
  const { Option } = Select;

  

  // Collapse
  const { Panel } = Collapse;
  return (
    <>
      <div className="leave-dashboard-container">
        {/* page Header */}
        <div className="page-header">
          {/* PAGE TITLE */}
          <div className="page-title">
            <h1 className="title">
              <img className="icon icon-itassets" src={leave} alt="icon" />{" "}
              Out of Office
            </h1>
          </div>

          {/* APPLY FOR LEAVE BUTTON */}
          <Button
            className="btn text-uppercase btn-primary"
            onClick={() => setVisible(true)}
          >
            <span className="lg-hide">
              <Icon type="plus" />
            </span>
            Apply for Leave
          </Button>
        </div>

        {/* Card Section */}

        {loader && (
          <div className="center-me">
            <Spin />
          </div>
        )}

        {!loader && (
          <section className="card-conatiner">
            <Row className="row" gutter={30}>
              {/* Card One  */}
              {leaveCard &&
                leaveCard.map((leave) => (
                  <Col
                    className="gutter-row col"
                    xs={24}
                    sm={24}
                    md={12}
                    lg={6}
                    xl={6}
                    xxl={6}
                  >
                    <Card className="card card-shadow" style={{marginBottom:'2rem'}}>
                      <div className="card-title">
                        <span 
                        className="card-font-lg "
                        style={{color:leave.color ? `${leave.color}` : '#A67AFF'}}
                        >
                        
                          {Math.round(leave.remaining_counts * 100) / 100}
                        </span>
                        /{Math.round(leave.initial_counts * 100) / 100}
                      </div>
                      <p className="card-description">{leave.name}</p>
                      <p className="card-description sub-text"><i>{new Date(leave.start_date).toLocaleDateString()}
                          &nbsp;-&nbsp;{new Date(leave.end_date).toLocaleDateString()}</i></p>
                    </Card>
                  </Col>
                ))}
            </Row>
          </section>
        )}

        {!loader && leavesLoader && (
          <div className="center-me">
            <Spin />
          </div>
        )}
        
        {/* Leave Request Section */}
        {!loader && !leavesLoader && (
          <section className="content-section leaveRequest-section">
            <div className="content-header">
              <h2 className="title">Leave Requests</h2>
              <div className="right filters">
                <div className="day-info-box">
                  {/* i button  */}
                  <Popover
                    placement="left"
                    overlayClassName="dayInfo-popover"
                    trigger="click"
                    getPopupContainer={(trigger) => trigger.parentNode}
                    content={
                      <div className="day-info-value">
                        <div className="full-day value">
                          <span className="colorCode"></span>Full Day
                        </div>
                        <div className="first-half value">
                          <span className="colorCode"></span>First Half
                        </div>
                        <div className="second-half value">
                          <span className="colorCode"></span>Second Half
                        </div>
                      </div>
                    }
                  >
                    <div className="day-info">
                      <img className="icon" src={info} alt="info icon" />
                    </div>
                  </Popover>
                </div>
                <div className="year-filter">
                  {/* YEAR */}
                  <Select
                    allowClear={true}
                    className="year-selectbox selectbox"
                    style={{ width: 85 }}
                    onChange={handleYear}
                    value={payload}
                    placeholder="Year"
                  >
                    {year.map((singleYear) => {
                      let year = singleYear.toString();
                      return (
                        <Option key={year} value={year}>
                          {year}
                        </Option>
                      );
                    })}
                  </Select>
                </div>
              </div>
            </div>

            {/* _______________BODY___________________ */}
            <div className="content-body">
              {myLeaves.length === 0 ? null : (
                <div className="head-row-box">
                  <div className="part first-part" >
                    <div className="head-title item date">Date</div>
                    <div className="head-title item leaveReason">
                      Reason of leave
                    </div>
                  </div>
                  <div className="part second-part" >
                    <div className="head-title item leaveType">Leave type</div>
                    <div
                      className="head-title item status"
                      style={{ textAlign: "center" }}
                    >
                      Status
                    </div>
                    <div
                      className="head-title item status"
                      style={{ textAlign: "center" }}
                    >
                      Action{" "}
                    </div>
                  </div>
                </div>
              )}
              <div className="body-row-box">
                {myLeaves.length === 0 ? (
                  <div className="center-me">
                    <Empty />
                  </div>
                ) : (
                  myLeaves.map((leave,index) => (
                    <Collapse
                      className="leave-description-card"
                      bordered={false}
                      showArrow={false}
                    >
                      <Panel
                        header={<CardHeader 
                          leave={leave}
                          setDataUpdate={loadMyLeave}
                          loader={setLoader}
                          index={index}
                          />}
                        key={index + 1}
                        showArrow={false}
                      >
                      </Panel>
                    </Collapse>
                  ))
                )}
              </div>
            </div>
          </section>
        )}
        {/* Pagination */}
      {!loader && !leavesLoader && myLeaves.length > 0 && (
        <section className="pagination-container">
          <div className="pagination-box text-right">
            <Pagination
              className="pagination"
              total={paginationTotal}
              defaultCurrent={pageNumber}
              onChange={handlePagination}
            />
          </div>
        </section>
      )}

        {/* for apply leave */}
        <Drawer
          className="navigator-drawer applyLeave-drawer"
          title="Apply For Leave"
          placement="right"
          width={500}
          onClose={() => {
            setVisible(false);
            setFileImages([])
          }}
          visible={visible}
          bodyStyle={{ padding: 0 }}
          destroyOnClose={true}
        >
          {/* <ApplyLeave /> */}
          <ApplyForLeave
            closeModal={() => setVisible(false)}
            setDataUpdate={loadMyLeave}
            fileImages={fileImages} 
            setFileImages={setFileImages}
          />
        </Drawer>
      </div>
    </>
  );
};
export default withStore(RequireAuth(ProfileComplete(NotificationUpdate(LeaveDashboard))));

