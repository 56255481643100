import {
  Button,
  Col,
  Icon,
  Modal,
  Popover,
  Row,
  Spin,
  notification,
} from "antd";
import React, { Component, Fragment, useEffect, useState } from "react";
import { axiosInstance as api } from "../../../../../utilities/configureAxios";
import _ from "lodash";
import moment from "moment";
import Flickity from "react-flickity-component";
// import url from '../../../../config/url';

class EmployeeHistory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      modal1Visible: false,
      assetIndexToShow: null,
      imageIndexToShow: null,
      imagesToShow: 4,
      maxImagesToShow: 6,
      maxSliderLength: 10,
      employee: [],
      arrayShowMore: [],
    };
  }

  setModal1Visible(modal1Visible) {
    this.setState({ modal1Visible });
  }

  showImageModal = (assetIndex, imageIndex) => {
    this.setState({
      modal1Visible: true,
      assetIndexToShow: assetIndex,
      imageIndexToShow: imageIndex,
    });
  };

  handleShowMoreImages = (assetIndex) => {
    this.setState((prevState) => ({
      arrayShowMore: [...prevState.arrayShowMore, assetIndex],
      // imagesToShow: this.state.imagesToShow + this.state.maxImagesToShow
    }));
  };
  handleShowLessImages = (assetIndex) => {
    const arr = this.state.arrayShowMore;
    const index = arr.indexOf(assetIndex);

    if (index > -1) {
      // only splice array when item is found
      arr.splice(index, 1); // 2nd parameter means remove one item only
    }

    this.setState((prevState) => ({
      arrayShowMore: arr,
    }));
  };

  closeModal = () => {
    this.setState({
      modal1Visible: false,
      assetIndexToShow: null,
      imageIndexToShow: null,
    });
  };

  componentDidMount() {
    this.setState({ loading: true });
    api
      .get(
        `${process.env.REACT_APP_API_URL}/assets/employee/${this.props.employee_uid}/asset-history?includes=allocated_images`
      )
      .then((res) => {
        const employeeData = _.get(res, "data.data", []);
        this.setState({ employee: employeeData, loading: false });
      })
      .catch((error) => {
        notification["error"]({
          message: _.get(
            error,
            "response.data.errors.message",
            "Oops! Something went wrong!"
          ),
        });
        this.setState({ loading: false });
      });
  }

  render() {
    const {
      employee,
      loading,
      assetIndexToShow,
      imageIndexToShow,
      imagesToShow,
      arrayShowMore,
    } = this.state;
    return (
      <React.Fragment>
        {loading ? (
          <div className="page-spinner">
            {" "}
            <Spin />
          </div>
        ) : (
          <div className="assetsHistory-Container">
            {/* Employee History List */}
            <div className="assetHistory-list animated slideInRight">
              <Row gutter={30} className="row">
                <Col className="col gutter-row" lg={24}>
                  <div className="histroyList-content">
                    {employee && employee.length > 0 ? (
                      <h2 className="historyList-title">
                        {employee[0]["assigned_to"]} Asset History
                        <span role="img" aria-label="thumbs">
                          👍
                        </span>
                      </h2>
                    ) : (
                      <h2 className="historyList-title">
                        Asset History
                        <span role="img" aria-label="thumbs">
                          👍
                        </span>
                      </h2>
                    )}

                    {employee &&
                      employee.map((history, assetIndex) => {
                        return (
                          <div className="asset-history-block" key={assetIndex}>
                            <div className="asset-history-view">
                              <div className="history-description">
                                <div className="user-history-block">
                                  <div className="user-info-box">
                                    <div
                                      className="user-name"
                                      style={{
                                        display: "flex",
                                        justifyContent: "flex-start",
                                        fontSize: "12px",
                                      }}
                                    >
                                      {/* {history.assigned_to}{' '} */}
                                      {employee && history.asset_title && (
                                        <div className="time-stamp from ">
                                          <span className="status-badge">
                                            {history.asset_title},{" "}
                                            {history.asset_type} - (
                                            {history.reference_id})
                                          </span>
                                        </div>
                                      )}
                                    </div>
                                    <div className="action-box">
                                      <Popover
                                        placement="left"
                                        content={
                                          <div className="historyNotes-popoverContent">
                                            <div className="content">
                                              <div className="form-group">
                                                <label className="label">
                                                  Allocation Notes
                                                </label>
                                                <div className="value">
                                                  {history.allocation_notes
                                                    ? history.allocation_notes
                                                    : "N/A"}
                                                </div>
                                              </div>
                                              <div className="form-group">
                                                <label className="label">
                                                  Deallocation Notes
                                                </label>
                                                <div className="value">
                                                  {history.deallocation_notes
                                                    ? history.deallocation_notes
                                                    : "N/A"}
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        }
                                        title="Asset Allocation/Deallocation Logs"
                                        trigger="click"
                                      >
                                        <Icon type="more" />
                                      </Popover>
                                    </div>
                                  </div>

                                  <div className="timeStamp-block mt--10">
                                    <div className="time-stamp from">
                                      <span className="icon">
                                        ⏱{" "}
                                        <span className="status-badge success">
                                          Allocated
                                        </span>
                                      </span>{" "}
                                      {moment(history.assigned_at).format(
                                        "LLLL"
                                      )}
                                    </div>
                                    {history.returned_at ? (
                                      <div className="time-stamp to">
                                        <span className="icon">
                                          ⏱{" "}
                                          <span className="status-badge danger">
                                            Deallocated
                                          </span>
                                        </span>{" "}
                                        {moment(history.returned_at).format(
                                          "LLLL"
                                        )}
                                      </div>
                                    ) : (
                                      <div className="time-stamp to">
                                        <span className="icon">⏱</span> Not
                                        Returned
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                            {history.allocated_images[0] && (
                              <Fragment>
                                <div
                                  className="history-imageBox"
                                  style={{
                                    marginLeft: "0rem",
                                    marginBottom: "10px",
                                  }}
                                >
                                  <div className="">
                                    <label className="label">
                                      Related Images
                                    </label>
                                    <div
                                      className="image-box"
                                      style={{
                                        display: "flex",
                                        flexWrap: "wrap",
                                      }}
                                    >
                                      {history.allocated_images
                                        .slice(
                                          0,
                                          !(
                                            arrayShowMore.indexOf(assetIndex) >
                                            -1
                                          )
                                            ? 4
                                            : 10
                                        )
                                        .map((image, imageIndex) => (
                                          <div
                                            className="historyImg"
                                            style={{
                                              width: "20%",
                                              marginBottom: "14px",
                                              marginRight: "10px",
                                            }}
                                            key={imageIndex}
                                          >
                                            <div
                                              className="history-img"
                                              style={{
                                                backgroundImage: `url(${image.url})`,
                                              }}
                                            ></div>
                                            <div
                                              className="img-overlay"
                                              onClick={() =>
                                                this.showImageModal(
                                                  assetIndex,
                                                  imageIndex
                                                )
                                              }
                                            >
                                              <Icon
                                                className="view-icon"
                                                type="eye"
                                              />
                                            </div>
                                          </div>
                                        ))}
                                    </div>
                                  </div>
                                </div>
                                {history.allocated_images.length >
                                  imagesToShow && (
                                  <div
                                    className="show-more-less-button"
                                    style={{
                                      display: "flex",
                                      justifyContent: "flex-end",
                                    }}
                                  >
                                    {!(
                                      arrayShowMore.indexOf(assetIndex) > -1
                                    ) && (
                                      <Button
                                        type="link"
                                        onClick={() =>
                                          this.handleShowMoreImages(assetIndex)
                                        }
                                      >
                                        Show more
                                      </Button>
                                    )}
                                    {arrayShowMore.indexOf(assetIndex) > -1 && (
                                      <Button
                                        type="link"
                                        onClick={() =>
                                          this.handleShowLessImages(assetIndex)
                                        }
                                      >
                                        Show less
                                      </Button>
                                    )}
                                  </div>
                                )}
                                <Modal
                                  className="history-assetImage"
                                  title="View Asset history image"
                                  centered
                                  visible={this.state.modal1Visible}
                                  destroyOnClose
                                  footer={false}
                                  onCancel={this.closeModal}
                                >
                                  {assetIndexToShow !== null &&
                                    imageIndexToShow !== null && (
                                      <div className="history-assetImageContainer">
                                        <Flickity
                                          className={"carousel"}
                                          elementType={"div"}
                                          options={{
                                            initialIndex: imageIndexToShow,
                                          }}
                                          disableImagesLoaded={false}
                                          reloadOnUpdate
                                          static
                                        >
                                          {employee[
                                            assetIndexToShow
                                          ].allocated_images.map(
                                            (image, index) => (
                                              <div
                                                className="carousel-img"
                                                style={{
                                                  backgroundImage: `url(${image.url})`,
                                                }}
                                                key={index}
                                              />
                                            )
                                          )}
                                        </Flickity>
                                      </div>
                                    )}
                                </Modal>
                              </Fragment>
                            )}
                            {employee && history.deallocated_by && (
                              <div
                                className="asset-deallocateBy-box"
                                style={{ marginLeft: "0rem" }}
                              >
                                <p className="deallocatedBy-text">
                                  <ul>
                                    <li>
                                      <span className="deallocated-message">
                                        <strong className="user-name">
                                          {history.deallocated_by}
                                        </strong>{" "}
                                        deallocated this Asset.
                                      </span>
                                    </li>
                                  </ul>
                                </p>
                              </div>
                            )}
                          </div>
                        );
                      })}
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        )}
      </React.Fragment>
    );
  }
}

export default EmployeeHistory;
