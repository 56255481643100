import { Select, DatePicker, Spin } from "antd";
import React, { useState, useEffect, useLayoutEffect } from "react";
import ProjectTable from "./ProjectTable";
import moment from "moment";
import { axiosInstance as api } from "../../../utilities/configureAxios";
import url from "../../../config/url";
import { useHistory } from "react-router";
import { useLocation } from "react-router-dom";

const ProjectWise = () => {
  const history = useHistory();
  const { search } = useLocation();
  const parseQueryString = (queryString) => {
    let results = {};
    if (queryString.charAt(0) == "?") {
      queryString = queryString.substring(1);
    }
    queryString = queryString.split("&");
    queryString.forEach((query) => {
      let res = query.split("=");
      if (res.length > 1) {
        results = { ...results, [res[0]]: res[1] };
      } else {
        results = { ...results, [res[0]]: null };
      }
    });
    return results;
  };
  const values = parseQueryString(search);
  const [pageNumber, setPageNumber] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [perPage, setPerPage] = useState(1);
  const [baseFilter, setBaseFilter] = useState(values.employee ? 0 : 1);
  let initialRange = [moment().subtract(14, "d"), moment()];
  const [dateRange, setDateRange] = useState(initialRange);
  const [allProjects, setAllProjects] = useState([]);
  const [allEmployees, setAllEmployees] = useState([]);
  const [projectIDSelector, setProjectIDSelector] = useState(
    values.project ?? 0
  );
  const [employeeIDSelector, setEmployeeIDSelector] = useState(
    values.employee ?? 0
  );
  const [loading, setLoading] = useState(false);
  const [hourLoader, setHourLoader] = useState(false);
  const [projectLoader, setProjectLoader] = useState(false);
  const [totalHours, setTotalHours] = useState(0);
  const [totalAllHours, setTotalAllHours] = useState([]);
  const [employeeTotalAllHours, setEmployeeTotalAllHours] = useState([]);
  const [employeeProjectHours, setEmployeeProjectHours] = useState(0);

  const resetPagination = () => {
    setPageNumber(1);
    setTotalCount(0);
    setPerPage(1);
  };
  const setQueryParam = (type, id) => {
    resetPagination();
    history.push({
      pathname: "/dashboard/admin/project-dashboard",
      search: `?${type === 1 ? "project" : "employee"}=${id}`,
    });
  };
  // Project specific api calls
  function fetchAllProjects() {
    setLoading(true);
    api
      .get(`${url["projectList"]}?bulk=1&order_by_title=1`)
      .then((res) => {
        setAllProjects(res.data.data);
        if (!(values.employee || values.project)) {
          setQueryParam(1, res.data.data[0].id);
          setProjectIDSelector(res.data.data[0].id);
        }
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  }

  function fetchSpecificProjectHours() {
    const startDate = dateRange[0]?.format("YYYY-MM-DD");
    const endDate = dateRange[1]?.format("YYYY-MM-DD");
    setProjectLoader(true);
    setTotalHours(0);
    api
      .get(
        `${url["projectList"]}/${
          projectIDSelector !== 0 ? projectIDSelector : 0
        }/logged-hours?from_date=${startDate}&to_date=${endDate}`
      )
      .then((res) => {
        setTotalHours(res.data.data);
        setProjectLoader(false);
      })
      .catch((error) => {
        setProjectLoader(false);
      });
  }
  function fetchSpecificProjectTotalHours() {
    const startDate = dateRange[0]?.format("YYYY-MM-DD");
    const endDate = dateRange[1]?.format("YYYY-MM-DD");
    setHourLoader(true);
    setTotalAllHours(0);
    api
      .get(
        `${url["projectList"]}/${projectIDSelector}/graph?from_date=${startDate}&to_date=${endDate}`
      )
      .then((res) => {
        setTotalAllHours(res.data.data?.dates);
        setHourLoader(false);
      })
      .catch((error) => {
        setHourLoader(false);
      });
  }

  function fetchAllEmployee() {
    // setLoading(true);
    api
      .get(`${url["getEmployees"]}?order_by_name=1`)
      .then((res) => {
        setAllEmployees(res.data.data);
        if (!(values.employee || values.project)) {
          setQueryParam(0, res.data.data[0].uuid);
          setEmployeeIDSelector(res.data.data[0].uuid);
        }
        // setLoading(false);
      })
      .catch((error) => {
        // setLoading(false);
      });
  }
  function fetchEmployeeProjectHours() {
    const startDate = dateRange[0]?.format("YYYY-MM-DD");
    const endDate = dateRange[1]?.format("YYYY-MM-DD");
    setProjectLoader(true);
    setEmployeeProjectHours([]);
    api
      .get(
        `${url["createUser"]}/${
          employeeIDSelector !== 0 ? employeeIDSelector : 0
        }/project-logged-hours?from_date=${startDate}&to_date=${endDate}`
      )
      .then((res) => {
        setEmployeeProjectHours(res.data.data);
        setProjectLoader(false);
      })
      .catch((error) => {
        setProjectLoader(false);
      });
  }
  function fetchSpecificEmployeeTotalHours() {
    const startDate = dateRange[0]?.format("YYYY-MM-DD");
    const endDate = dateRange[1]?.format("YYYY-MM-DD");
    setHourLoader(true);
    setEmployeeTotalAllHours(0);
    api
      .get(
        `${url["createUser"]}/${
          employeeIDSelector !== 0 ? employeeIDSelector : 0
        }/graph?from_date=${startDate}&to_date=${endDate}`
      )
      .then((res) => {
        setEmployeeTotalAllHours(res.data.data?.dates);
        setHourLoader(false);
      })
      .catch((error) => {
        setHourLoader(false);
      });
  }

  // fetching all the projects
  useEffect(() => {
    fetchAllEmployee();
    fetchAllProjects();
  }, []);
  useEffect(() => {
    if (employeeIDSelector !== 0) {
      fetchEmployeeProjectHours();
      // fetchEmployeeSpecificWorkReport();
      fetchSpecificEmployeeTotalHours();
    }
  }, [baseFilter, employeeIDSelector, dateRange]);

  // fetching total hours and work reports on the basis of project selected
  useEffect(() => {
    if (projectIDSelector !== 0) {
      fetchSpecificProjectHours();
      fetchSpecificProjectTotalHours();
      // fetchSpecificProjectWorkReport();
    }
  }, [projectIDSelector, dateRange]);

  const { Option } = Select;
  const { RangePicker } = DatePicker;

  // Filter `option.label` match the user type `input`
  const filterOption = (input, option) => {
    return (option?.props.children ?? "")
      .toLowerCase()
      .includes(input.toLowerCase());
  };

  return (
    <div id="project__dashboard">
      {!loading ? (
        <div
          className="project-dashboard-container "
          style={{ position: "relative" }}
        >
          <div className="content-header animated slideInUp">
            <div className="left__side">
              <div className="left-inside">
                <Select
                  allowClear={true}
                  className="status-selectbox selectbox"
                  // style={{
                  //   width: "200px",
                  //   marginBottom: "2rem",
                  //   marginRight: "10px",
                  // }}
                  placeholder="Status"
                  onChange={(val) => {
                    setBaseFilter(val);
                    resetPagination();
                    if (val === 0) {
                      setQueryParam(0, allProjects[0].id);
                    } else {
                      setQueryParam(1, allEmployees[0].uuid);
                    }
                    setProjectIDSelector(allProjects[0].id);
                    setEmployeeIDSelector(allEmployees[0].uuid);
                  }}
                  defaultValue={1}
                  showSearch
                  filterOption={filterOption}
                  value={baseFilter}
                >
                  <Option value={1}>Project Wise</Option>
                  <Option value={0}>Employee Wise</Option>
                </Select>

                {baseFilter === 1 ? (
                  <Select
                    showSearch
                    allowClear={true}
                    className="status-selectbox selectbox projects"
                    // style={{
                    //   width: "200px",
                    //   marginBottom: "2rem",
                    //   marginRight: "10px",
                    // }}
                    placeholder="Select type"
                    onChange={(val) => {
                      setQueryParam(1, val);
                      resetPagination();
                      setProjectIDSelector(val);
                    }}
                    defaultValue={allProjects[0]?.title}
                    value={projectIDSelector}
                    filterOption={filterOption}
                  >
                    {allProjects.length !== 0 &&
                      allProjects.map((project, idx) => {
                        return (
                          <Option value={project?.id} key={project?.id}>
                            {project?.title}
                          </Option>
                        );
                      })}
                  </Select>
                ) : (
                  <Select
                    showSearch
                    filterOption={filterOption}
                    allowClear={true}
                    className="status-selectbox selectbox"
                    // style={{
                    //   width: "200px",
                    //   marginBottom: "2rem",
                    //   marginRight: "10px",
                    // }}
                    placeholder="Status"
                    onChange={(val) => {
                      setQueryParam(0, val);
                      resetPagination();
                      setEmployeeIDSelector(val);
                    }}
                    defaultValue={allEmployees[0]?.title}
                    value={employeeIDSelector}
                  >
                    {allEmployees.map((employee, idx) => {
                      return (
                        <Option value={employee.uuid} key={employee.uuid}>
                          {employee.name}
                        </Option>
                      );
                    })}
                  </Select>
                )}
              </div>

              <RangePicker
                defaultValue={dateRange}
                onChange={(value, dateString) => {
                  setDateRange(value);
                  resetPagination();
                }}
                disabledDate={(current) => {
                  return current > new Date();
                }}
                allowClear={false}
                format={"DD-MMM-YYYY"}
                className="status-selectbox selectbox"
                style={{ margin: "0 auto", height: "100%", width: "300px" }}
              />
            </div>
            <div className="right__side">
              <p className="para">Total Hours Consumed</p>

              {hourLoader ? (
                <div className="hours">
                  <Spin />
                </div>
              ) : (
                <p className="hours">
                  {baseFilter === 1
                    ? totalHours !== 0 && totalHours?.total_time
                    : employeeProjectHours !== 0 &&
                      employeeProjectHours?.total_time}
                </p>
              )}
            </div>
          </div>
          <div className="content-body animated slideInUp">
            <div className="first-section">
              <ProjectTable
                // projectSpecificData={projectSpecificData}
                // employeeWRData={employeeSpecificData}
                hoursArr={totalHours.users}
                totalProjectHours={totalAllHours}
                employeeTotalAllHours={employeeTotalAllHours}
                employeeProjectHours={employeeProjectHours.projects}
                loading={loading}
                projectLoader={projectLoader}
                dateRange={dateRange}
                type={baseFilter}
                employeeIDSelector={employeeIDSelector}
                projectIDSelector={projectIDSelector}
                pageNumber={pageNumber}
                setPageNumber={setPageNumber}
                totalCount={totalCount}
                setTotalCount={setTotalCount}
                perPage={perPage}
                setPerPage={setPerPage}
                setQueryParam={setQueryParam}
                onProjectClick={(id, type) => {
                  setBaseFilter(type);
                  if (type === 1) {
                    setProjectIDSelector(id);
                  } else {
                    setEmployeeIDSelector(id);
                  }
                }}
              />
            </div>
          </div>
        </div>
      ) : (
        <div className="centerMe__pr">
          <Spin />
        </div>
      )}
    </div>
  );
};

export default ProjectWise;
