import React, { Fragment, useEffect, useState } from "react";
import {
  Spin,
  Row,
  Form,
  Col,
  DatePicker,
  Button,
  Select,
  InputNumber,
  notification,
  Icon,
} from "antd";
import { getOrgTeams } from "../../../services/ProjectService";
import { getOrganizationEmployeesCurrent } from "../../admin/components/LeaveManagement/DataManager";
import { updateProjectTeam } from "../../../services/ProjectService";
import moment from "moment";
import _ from "lodash";

const AdminEditTeam = (props) => {
  var initialLoading = props.loading;
  const autoUpdate = props.autoUpdate;
  const closeModal = props.closeModal;
  const { getFieldDecorator, validateFieldsAndScroll, setFields, getFieldValue } = props.form;
  const [loading, setLoading] = useState(false);
  const [btnDisable, setBtnDisable] = useState(false);
  const [employees, setEmployees] = useState([]);
  const [employee_data, setEmployeeData] = useState([]);
  const [teams, setTeams] = useState([]);
  const [shorteams, setShortageTeam] = useState([]);
  const [org_team_data, setOrgTeamData] = useState([]);
  const defaultData = props.data;

  const disabledDate = (current,index) => {
   let project_end_date = defaultData.end_date ? defaultData.end_date : null;
    let project_start_date = defaultData.start_date ? defaultData.start_date : null;
    let end_date = getFieldValue(`end_date${index}`);
    
    if((project_start_date && project_end_date && end_date && moment(end_date) < moment(project_end_date) )
    || (project_start_date && end_date)
    ){
      return (
        current &&
        (current < moment(project_start_date).endOf("day").subtract("1", "day") ||
          current > moment(end_date).endOf("day"))
      );
    }
    if (project_start_date && project_end_date ) {
      return (
        current &&
        (current < moment(project_start_date).endOf("day").subtract("1", "day") ||
          current > moment(project_end_date).endOf("day"))
      );
    }else {
      return current && current < moment(project_start_date).endOf("day").subtract("1", "day");
    }
  };

  const disabledendDate = (current,index) => {
    let project_end_date = defaultData.end_date ? defaultData.end_date : null;
     let project_start_date = defaultData.start_date ? defaultData.start_date : null;
     let start_date = getFieldValue(`start_date${index}`);
     if((project_start_date && project_end_date && start_date && moment(start_date) > moment(project_start_date) )
     || (project_start_date && start_date)
     ){
       return (
         current &&
         (current < moment(start_date).endOf("day").subtract("1", "day") ||
           current > moment(project_end_date).endOf("day"))
       );
     }
     if (project_start_date && project_end_date ) {
       return (
         current &&
         (current < moment(project_start_date).endOf("day").subtract("1", "day") ||
           current > moment(project_end_date).endOf("day"))
       );
     }else {
       return current && current < moment(project_start_date).endOf("day").subtract("1", "day");
     }
   };

  const [formData, setFormData] = useState({
    users: defaultData && defaultData.users ? defaultData.users : [],
    shortage: defaultData && defaultData.shortage ? defaultData.shortage : [],
  });
  const [formData1, setFormData1] = React.useState({
    teams_loading: true,
    shortageRole: "",
  });
  const { Option } = Select;

  const getOrgTeamsData = () => {
    setFormData1((prevData) => {
      return {
        ...prevData,
        teams_loading: true,
      };
    });
    getOrgTeams().then((res) => {
      setTeams(res && res.data && res.data.data);
      setShortageTeam(res && res.data && res.data.data);
      let arr = [];
      res.data.data.forEach((val) => {
        arr[val.id] = val.title;
      });
      setOrgTeamData(arr);
      setFormData1((prevData) => {
        return {
          ...prevData,
          teams_loading: false,
        };
      });
    });
  };
  useEffect(() => {
    getOrgTeamsData();
  }, []);

  const onSubmit = () => {
    validateFieldsAndScroll((err, values) => {
      if (!err) {
        setLoading(true);
        updateProjectTeam(formData, defaultData.id)
          .then((res) => {
            notification.success({
              message: "Updated successfully",
            });
            closeModal();
            setLoading(false);
            autoUpdate();
            setBtnDisable(false);
          })
          .catch((error) => {
            getOrgTeamsData();
            loadOrganizationEmployees();
            setLoading(false);
           if (error && error.response && error.response.status) {
              if (error.response.status === 422) {
                let errors = error.response.data.errors;
                let errorObject = {};
                Object.keys(errors).forEach((key) => {
                  let err = [];
                  errors[key].forEach((value) => {
                    err.push(new Error(value));
                  });
                  errorObject[key] = { value: formData[key], errors: err };
                });
                setFields(errorObject);
              } else if (
                error &&
                error.response &&
                error.response.data &&
                error.response.data.errors
              ) {
                notification.error(error.response.data.errors);
              }
            }
            setBtnDisable(false);
          });
      }
    });
  };

  // FETCHING ALL EMPLOYEES NAMES
  const loadOrganizationEmployees = () => {
    return getOrganizationEmployeesCurrent()
      .then((res) => {
        let arr = [];
        setEmployees(res.data);
        res.data.forEach((val) => {
          arr[val.uuid] = val.name;
        });
        setEmployeeData(arr);
      })
      .catch((error) => {
        notification.error(
          error && error.response && error.response.data && error.response.data.errors
        );
      });
  };
  React.useEffect(() => {
    loadOrganizationEmployees();
  }, []);

  return (
    <Fragment>
      <div className="action-box" style={{ margin: "10px", marginLeft: "1px" }}>
        <Button
          className="btn btn-light-primary btn-icon"
          onClick={() => {
            setFormData((prev) => ({
              ...prev,
              users: [
                ..._.cloneDeep(prev.users),
                {
                  end_date: "",
                  id: undefined,
                  start_date: "",
                  team_id: undefined,
                },
              ],
            }));
          }}
        >
          Add Team
        </Button>
      </div>
      <div className="add-new-profile-modal-container">
        <section>
          {loading || initialLoading ? (
            <div className="page-spinner">
              <Spin />
            </div>
          ) : (
            <>
              <Form>
                <Row className="" gutter={30}>
                  <Col
                    className="gutter-row first-half-col"
                    xs={24}
                    sm={24}
                    md={24}
                    lg={24}
                    xl={24}
                  >
                    {formData.users.map((team, index) => (
                      <div className="form-content">
                        <Row className="" gutter={30}>
                          <Col className="gutter-row" xs={24} sm={24} md={24} lg={5} xl={5}>
                            <div className="form-content form-group">
                              <div className="team-dropdown dropdown">
                                <Form.Item label="">
                                  {getFieldDecorator(`name${index}`, {
                                    initialValue: team.name,
                                    rules: [
                                      {
                                        required: true,
                                        message: "Select Employee",
                                      },
                                    ],
                                  })(
                                    <Select
                                      className="team-selectbox selectbox"
                                      showSearch
                                      style={{ width: 200 }}
                                      placeholder="All Employee"
                                      optionFilterProp="children"
                                      allowClear={true}
                                      onChange={(value) => {
                                        setFormData((prev) => ({
                                          ...prev,
                                          users: prev.users.map((each, idx) =>
                                            idx !== index
                                              ? each
                                              : { ...each, id: value, name: employee_data[value] }
                                          ),
                                        }));
                                      }}
                                    >
                                      {employees &&
                                        employees.map((employee) => (
                                          <Option key={employee.id} value={employee.uuid}>
                                            {employee.name}
                                          </Option>
                                        ))}
                                    </Select>
                                  )}
                                </Form.Item>
                              </div>
                            </div>
                          </Col>
                          <Col className="gutter-row" xs={24} sm={24} md={24} lg={5} xl={5}>
                            <div className="form-content form-group">
                              <div className="team-dropdown dropdown">
                                <Form.Item label="">
                                  {getFieldDecorator(`team${index}`, {
                                    initialValue: team.role,
                                    rules: [
                                      {
                                        required: true,
                                        message: "Select team",
                                      },
                                    ],
                                  })(
                                    <Select
                                      className="team-selectbox selectbox"
                                      showSearch
                                      style={{ width: "200" }}
                                      placeholder="Select Team"
                                      optionFilterProp="children"
                                      loading={formData1.teams_loading}
                                      //onChange={(val) => setTeamValue(val)}
                                      onChange={(value) =>
                                        setFormData((prev) => ({
                                          ...prev,
                                          users: prev.users.map((each, idx) =>
                                            idx !== index ? each : { ...each, team_id: value }
                                          ),
                                        }))
                                      }
                                    >
                                      {teams &&
                                        teams.map((team) => (
                                          <Option key={team.id} value={team.id}>
                                            {team.title}
                                          </Option>
                                        ))}
                                    </Select>
                                  )}
                                </Form.Item>
                              </div>
                            </div>
                          </Col>
                          <Col className="gutter-row" xs={24} sm={24} md={12} lg={5} xl={5}>
                            <Form.Item>
                              {getFieldDecorator(`start_date${index}`, {
                                initialValue: team.start_date ? moment(team.start_date) : "",
                                rules: [
                                  {
                                    required: false,
                                    message: "Select a valid date",
                                  },
                                ],
                              })(
                                <DatePicker
                                  placeholder="Start Date"
                                  className="ml-2"
                                 disabledDate={(value)=>disabledDate(value,index)}
                                  
                                  
                                  onChange={(value) =>
                                    setFormData((prev) => ({
                                      ...prev,
                                      users: prev.users.map((each, idx) =>
                                        idx !== index
                                          ? each
                                          : {
                                              ...each,
                                              start_date: moment(value).format("YYYY-MM-DD"),
                                            }
                                      ),
                                    }))
                                  }
                                />
                              )}
                            </Form.Item>
                          </Col>

                          <Col className="gutter-row" xs={24} sm={24} md={12} lg={5} xl={5}>
                            <Form.Item>
                              {getFieldDecorator(`end_date${index}`, {
                                initialValue: team.end_date ? moment(team.end_date) : "",
                                rules: [
                                  {
                                    required: false,
                                    message: "Select a valid date",
                                  },
                                ],
                              })(
                                <DatePicker
                                  placeholder="End Date"
                                  className="ml-2"
                                  disabledDate={(value)=>disabledendDate(value,index)}
                                  onChange={(value) =>
                                    setFormData((prev) => ({
                                      ...prev,
                                      users: prev.users.map((each, idx) =>
                                        idx !== index
                                          ? each
                                          : {
                                              ...each,
                                              end_date: moment(value).format("YYYY-MM-DD"),
                                            }
                                      ),
                                    }))
                                  }
                                />
                              )}
                            </Form.Item>
                          </Col>
                          {/* delete btn */}
                          <Col className="gutter-row " xs={4} sm={4} md={4} lg={4} xl={4}>
                            <div className="action-box">
                              <Button
                                className="btn btn-light-danger btn-icon"
                                onClick={() => {
                                  setFormData((prev) => ({
                                    ...prev,
                                    users: prev.users.filter((f, i) => i !== index),
                                  }));
                                }}
                              >
                                <Icon type="delete" theme="filled" />
                              </Button>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    ))}
                  </Col>
                </Row>
                <Row className="" gutter={30}>
                  <h3 className="title " style={{ margin: "10px", padding: "10px" }}>
                    Shortage Teams
                  </h3>

                  <div className="action-box" style={{ margin: "16px" }}>
                    <Button
                      className="btn btn-light-primary btn-icon"
                      onClick={() => {
                        setFormData((prev) => ({
                          ...prev,
                          shortage: [
                            ..._.cloneDeep(prev.shortage),
                            {
                              id: undefined,
                              counts: undefined,
                              team_id: undefined,
                            },
                          ],
                        }
                         
                        ));
                        
                      }}
                    >
                      Add Short team
                    </Button>
                  </div>
                  <Col
                    className="gutter-row first-half-col"
                    xs={24}
                    sm={24}
                    md={24}
                    lg={24}
                    xl={24}
                  >
                    {formData.shortage.map((team, index) => (
                      <div className="form-content">
                        <Row className="" gutter={30}>
                          <Col className="gutter-row" xs={24} sm={24} md={24} lg={5} xl={5}>
                            <div className="form-content form-group">
                              <div className="team-dropdown dropdown">
                                <Form.Item label="">
                                  {getFieldDecorator(`team_new${index}`, {
                                    initialValue: team.team_id ? org_team_data[team.team_id] : "",
                                    rules: [
                                      {
                                        required: true,
                                        message: "Select team",
                                      },
                                    ],
                                  })(
                                    <Select
                                      className="team-selectbox selectbox"
                                      showSearch
                                      style={{ width: "200" }}
                                      placeholder="Select Team"
                                      optionFilterProp="children"
                                      loading={formData1.teams_loading}
                                      //onChange={(val) => setTeamValue(val)}
                                      onChange={(value) => {
                                        setFormData((prev) => ({
                                          ...prev,
                                          shortage: prev.shortage.map((each, idx) =>
                                            idx !== index ? each : { ...each, team_id: value }
                                          ),
                                        }));

                                      
                                      }}
                                    >
                                      {shorteams && shorteams.map((team,index) => (
                                               <Option key={team.id} value={team.id}>
                                              {team.title}
                                            </Option>
                                          ))}
                                    </Select>
                                  )}
                                </Form.Item>
                              </div>
                            </div>
                          </Col>
                          <Col className="gutter-row" xs={24} sm={24} md={24} lg={5} xl={5}>
                            <div className="form-content leaveType-cont">
                              <div className="form-group">
                                <Form.Item name="isShort" colon={false}>
                                  {getFieldDecorator(`team_shortage${index}`, {
                                    initialValue: team.counts,
                                    rules: [
                                      {
                                        pattern: /^[1-9]$|^[1-9][0-9]$|^[1-9][0-9][0-9]$|^(1000)$/,
                                        message: "Invalid Value",
                                      },
                                      {
                                        required: true,
                                        message: "Enter a number",
                                      },
                                    ],
                                  })(
                                    <InputNumber
                                      onChange={(value) =>
                                        setFormData((prev) => ({
                                          ...prev,
                                          shortage: prev.shortage.map((each, idx) =>
                                            idx !== index ? each : { ...each, counts: value }
                                          ),
                                        }))
                                      }
                                      className="isShort-number"
                                      name="Shortage"
                                      placeholder="Short by"
                                    ></InputNumber>
                                  )}
                                </Form.Item>
                              </div>
                            </div>
                          </Col>
                          {/* delete btn */}
                          <Col className="gutter-row " xs={4} sm={4} md={4} lg={4} xl={4}>
                            <div className="action-box">
                              <Button
                                className="btn btn-light-danger btn-icon"
                                onClick={() => {
                                  setFormData((prev) => ({
                                    ...prev,
                                    shortage: prev.shortage.filter((f, i) => i !== index),
                                  }));
                                }}
                              >
                                <Icon type="delete" theme="filled" />
                              </Button>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    ))}
                  </Col>
                </Row>
                <Row>
                  <Col></Col>
                  <Col>
                    <div style={{ marginTop: "1rem", width: "100%", textAlign: "right" }}>
                      <Button className="btn-primary" onClick={onSubmit} disabled={btnDisable}>
                        Submit
                      </Button>
                    </div>
                  </Col>
                </Row>
              </Form>
              <div></div>
            </>
          )}
        </section>
      </div>
    </Fragment>
  );
};

export default Form.create()(AdminEditTeam);
