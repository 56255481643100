import { getAssetsHistoryService } from '../../../../../services/AssetService';

export const getAssetsHistory = (id, page) => {
    return getAssetsHistoryService(id, page)
        .then(result => {
            return result.data
        })
        .catch(error => {
            throw error
        })
}