import moment from "moment";
import { get } from "lodash";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import React, { Component, Fragment } from "react";
import { getOrgProject } from "../../../admin/components/Settings/DataManager";
import data_setting from "../../../../config/data_setting";
import {
  Icon,
  Input,
  Button,
  notification,
  Form,
  DatePicker,
  Upload,
  Modal,
  TimePicker,
  Radio,
  Select,
} from "antd";
import calendar from "../../../../assets/images/icons/monthly-calendar-white.svg";
import { getDateCalculationApi } from "../../../leave/components/DataManager";

let id = 1;

// for datepicker
function onChange(date, dateString) {}

// for textarea
const { TextArea } = Input;
const { Option } = Select;

// for upload
function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}

function checkDate() {
  return moment().hour() >= 11;
}

function getDate() {
  return checkDate() ? moment() : moment().subtract(1, "days");
}

class SendWorkReport extends Component {
  constructor(props) {
    super(props);
    const requestBody = JSON.parse(localStorage.getItem("requestBody"));
    this.state = {
      previewVisible: false,
      previewImage: "",
      fileList: [],
      addMoreWorkStatus: [],
      saveButtonLoading: false,
      defaultDate: getDate().format("YYYY-MM-DD"),
      projects: [],
      projectsloading: false,
      requestBody: {
        date: getDate().format("YYYY-MM-DD"),
        day_type: "full_day",
        work_reports: [
          {
            project_id: null,
            description: null,
            images: [],
            duration: null,
          },
        ],
      },
    };
  }

  setDefaultDraftReport() {
    localStorage.setItem(
      "requestBody",
      JSON.stringify({
        date: getDate().format("YYYY-MM-DD"),
        day_type: "full_day",
        work_reports: [
          {
            project_id: null,
            description: null,
            images: [],
            duration: null,
          },
        ],
      })
    );
  }

  componentDidMount() {
    const requestBody = JSON.parse(localStorage.getItem("requestBody"));
    if (!requestBody) {
      this.setDefaultDraftReport();
    }
    this.loadOrganizationProjectFunction();
  }

  // for datepicker
  getCalendarContainer1() {
    return this.d || document.getElementById("calendarContainer1");
  }

  // for upload
  handleCancel = () => this.setState({ previewVisible: false });

  handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }

    this.setState({
      previewImage: file.url || file.preview,
      previewVisible: true,
    });
  };

  dataUrlToFile = async (url, filename, mimeType) => {
    mimeType = mimeType || (url.match(/^data:([^;]+);/) || "")[1];
    return fetch(url)
      .then(function (res) {
        return res.arrayBuffer();
      })
      .then(function (buf) {
        return new File([buf], filename, { type: mimeType });
      });
  };

  loadOrganizationProjectFunction = () => {
    this.setState({ projectsloading: true });
    let page = 1;
    let bulk = true;
    return getOrgProject(page, bulk)
      .then((res) => {
        this.setState(
          { projects: res.data, projectsloading: false },
          async () => {
            const requestBody = JSON.parse(localStorage.getItem("requestBody"));
            if (requestBody) {
              this.setState({
                ...this.state,
                requestBody: {
                  ...requestBody,
                  work_reports: await Promise.all(
                    requestBody.work_reports.map(async (report) => {
                      return {
                        ...report,
                        images: await Promise.all(
                          report.images.map(async (image) => {
                            return {
                              ...image,
                              originFileObj: await this.dataUrlToFile(
                                image.originFileObj,
                                image.name,
                                image.type
                              ),
                            };
                          })
                        ),
                      };
                    })
                  ),
                },
              });
            }
          }
        );
      })
      .catch((error) => {
        notification.error({
          message: error.response.data.errors.message,
        });
      });
  };

  // for adding fields
  remove = (key) => {
    let { requestBody } = this.state;
    let workReport = requestBody.work_reports;
    workReport = workReport.filter((item, index) => index != key);

    this.setState((state) => ({
      requestBody: {
        date: state.requestBody.date,
        day_type: state.requestBody.day_type,
        work_reports: workReport,
      },
    }));
  };

  add = () => {
    this.setState((state) => ({
      requestBody: {
        date: state.requestBody.date,
        day_type: state.requestBody.day_type,
        work_reports: [
          ...state.requestBody.work_reports,
          { project_id: null, description: null, images: [] },
        ],
      },
    }));
  };

  setFileList = async (fileList, key) => {
    let { requestBody } = this.state;
    let workReport = requestBody.work_reports;
    workReport[key].images = fileList;
    this.setState((state) => ({
      requestBody: {
        date: state.requestBody.date,
        day_type: state.requestBody.day_type,
        work_reports: workReport,
      },
    }));
  };

  setRequestBodyHeaderValue = (value, type) => {
    let { requestBody } = this.state;

    if (type === "date") {
      if (value) {
        const format = "YYYY-MM-DD";
        let date = value.format(format);
        requestBody.date = date;
      }
    }

    if (type === "day_type") {
      requestBody.day_type = value;
    }

    this.setState({ requestBody: requestBody });
  };

  setStateWorkReportsValue = (value, field, key) => {
    let state = this.state;
    let temp = state.requestBody.work_reports[`${key}`];
    if (field === "images") {
      let arr = temp[`${field}`];
      arr.push(value);
      temp[`${field}`] = arr;
    } else if (field === "duration") {
      let duration = value.hour() * 60 + value.minute();
      temp[`${field}`] = duration;
    } else {
      temp[`${field}`] = value;
    }
    state.requestBody.work_reports[`${key}`] = temp;
    this.setState(state);
  };

  saveDraft = async () => {
    localStorage.setItem(
      "requestBody",
      JSON.stringify({
        ...this.state.requestBody,
        work_reports: await Promise.all(
          this.state.requestBody.work_reports.map(async (report) => {
            const images = await Promise.all(
              report.images.map(async (file) => {
                const base64 = await getBase64(file.originFileObj);
                return {
                  ...file,
                  originFileObj: base64,
                };
              })
            );
            return {
              ...report,
              images,
            };
          })
        ),
      })
    );
    notification["success"]({
      message: "Work log draft saved",
    });
  };

  disabledDate = (current) => {
    // Can not select days after today and before start Date
    const start = moment();
    if (checkDate()) {
      return current.format("YYYY-MM-DD") !== start.format("YYYY-MM-DD");
    } else {
      return (
        current.format("YYYY-MM-DD") !==
        start.subtract(1, "days").format("YYYY-MM-DD")
      );
    }
  };

  handleSubmit = () => {
    this.setState({ saveButtonLoading: true });
    let { requestBody, defaultDate } = this.state;

    this.props.form.validateFields((err, values) => {
      if (!err) {
        let formData = new FormData();
        formData.append("date", getDate().format("YYYY-MM-DD"));
        requestBody.day_type &&
          formData.append("day_type", requestBody.day_type);

        requestBody.work_reports.forEach(function (item, workReportIndex) {
          formData.append(
            `work_reports[${workReportIndex}][project_id]`,
            item.project_id
          );
          formData.append(
            `work_reports[${workReportIndex}][description]`,
            item.description
          );
          formData.append(
            `work_reports[${workReportIndex}][duration]`,
            item.duration
          );
          item.images &&
            item.images.forEach(function (image, imageIndex) {
              formData.append(
                `work_reports[${workReportIndex}][images][${imageIndex}]`,
                image.originFileObj
              );
            });
        });

        this.props
          .addNewWorkReportPropFunction(formData)
          .then((res) => {
            this.setState({ saveButtonLoading: false });
            localStorage.setItem("requestBody", null);
            notification["success"]({
              message: "Work log submitted",
            });
            this.props.loadWorkReports();
          })
          .catch((error) => {
            this.setState({ saveButtonLoading: false });
            const error_code = get(error, "response.status");

            if (error_code && error_code == "422") {
              notification["error"]({
                message: "Invalid Data",
                description: Object.values(
                  get(
                    error,
                    "response.data.errors",
                    "Oops! Something went wrong!"
                  )
                ),
              });
            } else {
              notification["error"]({
                message: get(
                  error,
                  "response.data.errors.message",
                  "Oops! Something went wrong!"
                ),
              });
            }
          });
      } else {
        this.setState({ saveButtonLoading: false });
      }
    });
  };

  onChange(time, timeString) {}

  getWorkUpdateTemplate = (item, key) => {
    const {
      previewVisible,
      previewImage,
      fileList,
      addMoreWorkStatus,
      requestBody,
      projects,
    } = this.state;
    const uploadButton = (
      <div>
        <Icon type="plus" />
        <div className="ant-upload-text">Upload</div>
      </div>
    );
    const { getFieldDecorator } = this.props.form;
    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 4 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 24 },
      },
    };

    return (
      <Fragment key={key}>
        <div className="addWorkReport-block">
          <div className="addWorkReport-fields form-group">
            <Form.Item {...formItemLayout}>
              <div className="addWorkReport-content">
                <div className="form-content form-group">
                  <Form.Item label="Select Project" colon={false}>
                    {/* // <Input className="input" maxLength={255} placeholder="Enter project" onChange={e => { this.setStateWorkReportsValue(e.currentTarget.value, 'project_id', key) }} /> */}
                    {getFieldDecorator(`project_id[${key}]`, {
                      initialValue: item.project_id,
                      rules: [
                        {
                          required: true,
                          message: "Project required",
                        },
                      ],
                    })(
                      <Select
                        showSearch
                        className="input"
                        style={{ width: "100%" }}
                        onChange={(value) => {
                          this.setStateWorkReportsValue(
                            value,
                            "project_id",
                            key
                          );
                        }}
                        placeholder="All Employee"
                        getPopupContainer={(trigger) => trigger.parentNode}
                        filterOption={(input, option) =>
                          option.props.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        <Option key="0" value="">
                          -Select Project-
                        </Option>
                        {projects &&
                          projects.map((project) => (
                            <Option key={project.title} value={project.id}>
                              {project.title}
                            </Option>
                          ))}
                      </Select>
                    )}
                  </Form.Item>
                </div>

                <div className="form-content form-group">
                  <Form.Item label="Enter detailed description" colon={false}>
                    {getFieldDecorator(`description[${key}]`, {
                      initialValue: item.description,
                      rules: [
                        {
                          required: true,
                          message: "Description required",
                        },
                      ],
                      validateTrigger: "onBlur",
                    })(
                      <CKEditor
                        editor={ClassicEditor}
                        style={{ minHeight: "700px" }}
                        data={item.description}
                        config={{
                          removePlugins: ["Table", "MediaEmbed", "BlockQuote"],
                          toolbar: [
                            "heading",
                            "|",
                            "bold",
                            "italic",
                            "link",
                            "numberedList",
                            "bulletedList",
                            "insertTable",
                            "tableColumn",
                            "tableRow",
                            "mergeTableCells",
                            "|",
                            "undo",
                            "redo",
                            "underline",
                          ],
                        }}
                        onChange={(event, editor) => {
                          const data = editor.getData();
                          this.setStateWorkReportsValue(
                            data,
                            "description",
                            key
                          );
                        }}
                      />
                    )}
                  </Form.Item>
                </div>

                <div className="form-content form-group">
                  <Form.Item label="Enter number of hours spent" colon={false}>
                    {getFieldDecorator(`duration[${key}]`, {
                      initialValue:
                        item.duration &&
                        moment().startOf("day").add(item.duration, "minutes"),
                      rules: [
                        {
                          required: true,
                          message: "Number of hours required",
                        },
                      ],
                      // validateTrigger: 'onBlur'
                    })(
                      <TimePicker
                        className="timePicker"
                        placeholder="Time spent (hh:mm)"
                        format="HH:mm"
                        minuteStep={15}
                        defaultOpenValue={moment("00:00:00", "HH:mm:ss")}
                        getPopupContainer={(trigger) => trigger.parentNode}
                        onChange={(time) => {
                          this.setStateWorkReportsValue(time, "duration", key);
                        }}
                      />
                    )}
                  </Form.Item>
                </div>

                <div className="form-content">
                  <Form.Item label="Attachments (Optional)" colon={false}>
                    <div className="clearfix">
                      <Upload
                        accept={"image/jpeg,image/jpg,image/png"}
                        beforeUpload={() => false}
                        listType="picture-card"
                        fileList={item.images}
                        onPreview={this.handlePreview}
                        onChange={({ fileList }) => {
                          this.setFileList(fileList, key);
                        }}
                        showUploadList={{
                          showPreviewIcon: false,
                          showRemoveIcon: true,
                          showDownloadIcon: false,
                        }}
                      >
                        {fileList.length >= 2 ? null : uploadButton}
                      </Upload>
                      <Modal
                        className="viewImgModal"
                        visible={previewVisible}
                        footer={null}
                        onCancel={this.handleCancel}
                      >
                        <img
                          alt="example"
                          style={{ width: "100%" }}
                          src={previewImage}
                        />
                      </Modal>
                    </div>
                  </Form.Item>
                </div>
              </div>

              {key > 0 ? (
                <div className="remove-block">
                  <Icon
                    className="dynamic-delete-button"
                    type="close"
                    onClick={() => this.remove(key)}
                  />
                </div>
              ) : null}
            </Form.Item>
          </div>
        </div>
      </Fragment>
    );
  };

  // for radiobtn
  onChange = (e) => {
    this.setState({
      value: e.target.value,
    });
  };

  disableSaveDraft = () => {
    const work_reports = this.state.requestBody.work_reports;
    if (!work_reports.length) {
      return true;
    }
    if (
      !work_reports[0].description &&
      !work_reports[0].images.length &&
      !work_reports[0].project_id &&
      !work_reports[0].duration
    ) {
      return true;
    }
    return false;
  };

  render() {
    const { requestBody, saveButtonLoading } = this.state;
    const { getFieldDecorator } = this.props.form;

    const formItemLayoutWithOutLabel = {
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 24 },
      },
    };

    return (
      <Fragment>
        <div className="sendWorkReport-container">
          <h3 className="text-primary">Send Work Logs 📋</h3>
          <form>
            <div className="form-content form-group">
              <div className="label">Select Date</div>
              <div id="calendarContainer1">
                <Form.Item colon={false}>
                  {getFieldDecorator(`date`, {
                    initialValue: getDate(),
                    rules: [
                      {
                        type: "object",
                        required: true,
                        message: "Work log date required",
                      },
                    ],
                    // validateTrigger: 'onBlur'
                  })(
                    <DatePicker
                      format={data_setting["date_format"]}
                      disabledDate={this.disabledDate}
                      placeholder="Select Date"
                      onChange={(date) => {
                        this.setRequestBodyHeaderValue(date, "date");
                      }}
                      getCalendarContainer={this.getCalendarContainer1}
                      suffixIcon={
                        <img
                          className="calendar-icon"
                          src={calendar}
                          alt="calendar icon"
                        />
                      }
                    />
                  )}
                </Form.Item>
              </div>
            </div>

            <div className="form-content">
              <Form.Item label="Select Day type" colon={false}>
                <div className="selectDayType-block">
                  <Radio.Group
                    className="radioGroup-block"
                    onChange={(e) => {
                      this.setRequestBodyHeaderValue(
                        e.target.value,
                        "day_type"
                      );
                    }}
                    value={requestBody.day_type}
                  >
                    <Radio className="option" value={"full_day"}>
                      Full Day (Min. 9 hrs)
                    </Radio>
                    <Radio className="option" value={"half_day"}>
                      Half Day (Min. 4.5 hrs)
                    </Radio>
                  </Radio.Group>
                </div>
              </Form.Item>
            </div>

            <div className="form-content">
              <Form.Item label="Add Work Status" colon={false}>
                <Form>
                  {/* <div>{addMoreWorkStatus}</div> */}
                  {requestBody.work_reports.map((item, index) =>
                    this.getWorkUpdateTemplate(item, index)
                  )}
                  <Form.Item {...formItemLayoutWithOutLabel}>
                    <Button
                      className="btn btn-light-primary full-width text-uppercase"
                      onClick={this.add}
                    >
                      <Icon type="plus" /> Add more work status
                    </Button>
                  </Form.Item>
                </Form>
              </Form.Item>
            </div>

            <div className="form-content text-center">
              <Button
                className="btn btn-light-secondary text-uppercase"
                size="large"
                onClick={this.saveDraft}
                disabled={this.disableSaveDraft()}
              >
                Save Draft
              </Button>
              <Button
                onClick={() => {
                  this.handleSubmit();
                }}
                disabled={saveButtonLoading}
                loading={saveButtonLoading}
                className="btn btn-success btn-wide text-uppercase ml--30"
              >
                Send
              </Button>
            </div>
          </form>
        </div>
      </Fragment>
    );
  }
}

export default Form.create({ name: "dynamic_form_item" })(SendWorkReport);
