import React, { Component, Fragment } from "react";
import {
  notification,
  Form,
  Icon,
  Input,
  Button,
  Checkbox,
  message,
  Spin,
  Row,
  Col,
  Layout,
  Menu,
  Select,
  Card,
  Tabs,
  DatePicker,
  Radio,
} from "antd";
import male from "../../../assets/images/icons/profile/personal-details/male.svg";
import female from "../../../assets/images/icons/profile/personal-details/female.svg";
import heartMarried from "../../../assets/images/icons/profile/personal-details/heart-married.svg";
import heartUnmarried from "../../../assets/images/icons/profile/personal-details/heart-unmarried.svg";
import veg from "../../../assets/images/icons/profile/personal-details/veg.svg";
import nonveg from "../../../assets/images/icons/profile/personal-details/non-veg.svg";
import wine from "../../../assets/images/icons/profile/personal-details/wine.svg";
import juice from "../../../assets/images/icons/profile/personal-details/juice.svg";
import _ from "lodash";
import moment from "moment";
import { axiosInstance as api } from "../../../utilities/configureAxios";
import data_setting from "../../../config/data_setting";
import { withStore } from "../../../hocs/withStore";
import RequireAuth from "../../../hocs/RequireAuth";

// textarea
const { TextArea } = Input;

// Dropdown
const { Option } = Select;

class PersonalDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      personal_details: null,
    };
  }

  static getDerivedStateFromProps(props, state) {
    return {
      personal_details: props.personal_details,
    };
  }

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        this.setState({ loading: true });
        values["profile_tab"] = "personal";
        const {
          store: { setData },
        } = this.props;
        values.dob_personal = moment(values.dob_personal).format(
          data_setting["post_date_format"]
        );
        values.anniversary_date = moment(values.anniversary_date).format(
          data_setting["post_date_format"]
        );
        //values.dob_official = moment(values.dob_official).format(data_setting["post_date_format"])

        let permanent_address = {};
        permanent_address["pincode"] = values.perm_pincode;
        permanent_address["address"] = values.perm_address;
        permanent_address["city"] = values.perm_city;
        permanent_address["state"] = values.perm_state;
        permanent_address["locality"] = values.perm_locality;
        delete values.perm_pincode;
        delete values.perm_address;
        delete values.perm_city;
        delete values.perm_state;
        delete values.perm_locality;

        let correspondance_address = {};
        correspondance_address["pincode"] = values.pincode;
        correspondance_address["address"] = values.address;
        correspondance_address["city"] = values.city;
        correspondance_address["state"] = values.state;
        correspondance_address["locality"] = values.locality;
        delete values.pincode;
        delete values.address;
        delete values.city;
        delete values.state;
        delete values.locality;

        values["permanent_address"] = permanent_address;
        values["correspondence_address"] = correspondance_address;

        if(values.marital_status !== data_setting["marital_status"]["married"]){
          values["anniversary_date"] = null;
          values["spouse_name"] = null;
        }

        api
          .post("/profile", values)
          .then((data) => {
            const is_profile_complete = _.get(
              data,
              "data.data.is_profile_complete",
              false
            );
            setData({ is_profile_complete });
            notification["success"]({
              message: "Personal details updated successfully!",
            });
            this.setState({ loading: false });
          })
          .catch((error) => {
            const error_code = _.get(error, "response.status");
            if (error_code && error_code == "422") {
              notification["error"]({
                message: Object.values(
                  _.get(
                    error,
                    "response.data.errors",
                    "Oops! Something went wrong!"
                  )
                ),
              });
            } else {
              notification["error"]({
                message: _.get(
                  error,
                  "response.data.errors.message",
                  "Oops! Something went wrong!"
                ),
              });
            }
            this.setState({ loading: false });
          });
      }
    });
  };

  onCheckboxChangeHandle = (event) => {
    const { setFieldsValue, getFieldValue, resetFields } = this.props.form;
    if (event.target.checked) {
      setFieldsValue({
        perm_address: getFieldValue("address"),
        perm_pincode: getFieldValue("pincode"),
        perm_city: getFieldValue("city"),
        perm_state: getFieldValue("state"),
        perm_locality: getFieldValue("locality"),
      });
    } else {
      resetFields([
        "perm_address",
        "perm_pincode",
        "perm_city",
        "perm_state",
        "perm_locality",
      ]);
    }
  };

  render() {
    const { personal_details, loading } = this.state;
    const { getFieldDecorator, getFieldValue } = this.props.form;
    return (
      <Fragment>
        <section className="tab-content-container personal-details-tab card-shadow">
          <Form className="login-form">
            <h3 className="title text-primary">About yourself</h3>
            <Row className="" gutter={30}>
              <Col
                className="gutter-row first-half-col"
                xs={24}
                sm={24}
                md={24}
                lg={12}
                xl={12}
              >
                <div className="form-content">
                  <Row className="" gutter={30}>
                    <Col
                      className="gutter-row"
                      xs={24}
                      sm={24}
                      md={24}
                      lg={12}
                      xl={12}
                    >
                      <div className="form-group">
                        <Form.Item label="First Name" colon={false}>
                          {getFieldDecorator("first_name", {
                            initialValue: personal_details
                              ? personal_details.first_name
                              : "",
                            rules: [
                              {
                                required: true,
                                message: "Please enter first name!",
                              },
                            ],
                            validateTrigger: "onBlur",
                          })(
                            <Input
                              className="input"
                              maxLength="255"
                              placeholder="Enter first name"
                              onBlur={(e) =>
                                (e.target.value = e.target.value.trim())
                              }
                            />
                          )}
                        </Form.Item>
                      </div>
                    </Col>
                    <Col className="" xs={24} sm={24} md={24} lg={12} xl={12}>
                      <div className="form-group">
                        <Form.Item label="Last Name" colon={false}>
                          {getFieldDecorator("last_name", {
                            initialValue: personal_details
                              ? personal_details.last_name
                              : "",
                            rules: [
                              {
                                required: true,
                                message: "Please enter last name!",
                              },
                            ],
                            validateTrigger: "onBlur",
                          })(
                            <Input
                              className="input"
                              maxLength="255"
                              placeholder="Enter Last name"
                              onBlur={(e) =>
                                (e.target.value = e.target.value.trim())
                              }
                            />
                          )}
                        </Form.Item>
                      </div>
                    </Col>
                  </Row>
                  <Row className="" gutter={30}>
                    <Col
                      className="gutter-row"
                      xs={24}
                      sm={24}
                      md={24}
                      lg={12}
                      xl={12}
                    >
                      <div className="form-group">
                        <Form.Item label="Father's Name" colon={false}>
                          {getFieldDecorator("father_name", {
                            initialValue: personal_details
                              ? personal_details.father_name
                              : "",
                            rules: [
                              {
                                required: true,
                                message: "Please enter father's name!",
                              },
                            ],
                            validateTrigger: "onBlur",
                          })(
                            <Input
                              className="input"
                              maxLength="255"
                              placeholder="Enter your father's name"
                              onBlur={(e) =>
                                (e.target.value = e.target.value.trim())
                              }
                            />
                          )}
                        </Form.Item>
                      </div>
                    </Col>
                    <Col className="" xs={24} sm={24} md={24} lg={12} xl={12}>
                      <div className="form-group">
                        <Form.Item label="Mother's Name" colon={false}>
                          {getFieldDecorator("mother_name", {
                            initialValue: personal_details
                              ? personal_details.mother_name
                              : "",
                            rules: [
                              {
                                required: true,
                                message: "Please enter mother's name!",
                              },
                            ],
                            validateTrigger: "onBlur",
                          })(
                            <Input
                              className="input"
                              maxLength="255"
                              placeholder="Enter your mother's name"
                              onBlur={(e) =>
                                (e.target.value = e.target.value.trim())
                              }
                            />
                          )}
                        </Form.Item>
                      </div>
                    </Col>
                  </Row>
                  <Row className="" gutter={30}>
                    <Col
                      className="gutter-row"
                      xs={24}
                      sm={24}
                      md={24}
                      lg={12}
                      xl={12}
                    >
                      <div className="form-group">
                        <Form.Item label="Personal Email ID" colon={false}>
                          {getFieldDecorator("email_personal", {
                            initialValue: personal_details
                              ? personal_details.email_personal
                              : "",
                            rules: [
                              {
                                required: true,
                                message: "Please enter email!",
                              },
                              {
                                type: "email",
                                message: "The input is not valid E-mail!",
                              },
                            ],
                            validateTrigger: "onBlur",
                          })(
                            <Input
                              type="email"
                              maxLength="255"
                              className="input"
                              placeholder="Enter Email ID"
                              onBlur={(e) =>
                                (e.target.value = e.target.value.trim())
                              }
                            />
                          )}
                        </Form.Item>
                      </div>
                    </Col>
                    <Col className="" xs={24} sm={24} md={24} lg={12} xl={12}>
                      <div className="form-group">
                        <Form.Item label="Mobile Number" colon={false}>
                          {getFieldDecorator("mobile", {
                            initialValue: personal_details
                              ? personal_details.mobile
                              : "",
                            rules: [
                              {
                                required: true,
                                message: "Please enter phone number!",
                              },
                            ],
                            validateTrigger: "onBlur",
                          })(
                            <Input
                              className="input"
                              maxLength="10"
                              minLength="10"
                              placeholder="Enter Mobile No."
                              onBlur={(e) =>
                              (e.target.value = e.target.value.replace(
                                /\D/g,
                                ""
                              ))
                              }
                            />
                          )}
                        </Form.Item>
                      </div>
                    </Col>
                  </Row>
                  <Row className="" gutter={30}>
                    <Col
                      className="gutter-row"
                      xs={24}
                      sm={24}
                      md={24}
                      lg={12}
                      xl={12}
                    >
                      <div className="form-group">
                        <Form.Item label="Gender" colon={false}>
                          {getFieldDecorator("gender", {
                            initialValue: personal_details
                              ? personal_details.gender == "male"
                                ? data_setting["gender"]["male"]
                                : personal_details.gender == "female"
                                  ? data_setting["gender"]["female"]
                                  : ""
                              : "",
                            rules: [
                              {
                                required: true,
                                message: "Please select gender!",
                              },
                            ],
                          })(
                            <Radio.Group className="radio-btn">
                              <div className="radio-icon male-icon">
                                <Radio
                                  value={data_setting["gender"]["male"]}
                                  className="male-radio"
                                >
                                  Male
                                </Radio>
                                <img
                                  className="icon male"
                                  src={male}
                                  alt="male"
                                />
                              </div>
                              <div className="radio-icon female-icon">
                                <Radio
                                  value={data_setting["gender"]["female"]}
                                  className="female-radio"
                                >
                                  Female
                                </Radio>
                                <img
                                  className="icon female"
                                  src={female}
                                  alt="Female"
                                />
                              </div>
                            </Radio.Group>
                          )}
                        </Form.Item>
                      </div>
                    </Col>
                    <Col className="" xs={24} sm={24} md={24} lg={12} xl={12}>
                      <div className="form-group">
                        <Form.Item label="Marital Status" colon={false}>
                          {getFieldDecorator("marital_status", {
                            initialValue: personal_details
                              ? personal_details.marrital_status == "Single"
                                ? data_setting["marital_status"]["single"]
                                : personal_details.marrital_status == "Married"
                                  ? data_setting["marital_status"]["married"]
                                  : ""
                              : "",
                            rules: [
                              {
                                required: true,
                                message: "Please select marital status!",
                              },
                            ],
                          })(
                            <Radio.Group className="radio-btn">
                              <div className="radio-icon married-icon">
                                <Radio
                                  value={
                                    data_setting["marital_status"]["married"]
                                  }
                                  className="married-radio"
                                >
                                  Married
                                </Radio>
                                <img
                                  className="icon married"
                                  src={heartMarried}
                                  alt="heart"
                                />
                              </div>
                              <div className="radio-icon unmarried-icon">
                                <Radio
                                  value={
                                    data_setting["marital_status"]["single"]
                                  }
                                  className="unmarried-radio"
                                >
                                  Unmarried
                                </Radio>
                                <img
                                  className="icon unmarried"
                                  src={heartUnmarried}
                                  alt="heart"
                                />
                              </div>
                            </Radio.Group>
                          )}
                        </Form.Item>
                      </div>
                    </Col>
                  </Row>

                  {/* Current Address */}
                  <div className="title-with-flex">
                    <h3 className="title text-primary">Current Address</h3>
                  </div>
                  <Row className="" gutter={30}>
                    <Col
                      className="gutter-row"
                      xs={24}
                      sm={24}
                      md={24}
                      lg={24}
                      xl={24}
                    >
                      <div className="form-group">
                        <Form.Item label="Current Address" colon={false}>
                          {getFieldDecorator("address", {
                            initialValue:
                              personal_details &&
                                personal_details.correspondence_address
                                ? personal_details.correspondence_address
                                  .address
                                : "",
                            rules: [
                              {
                                required: true,
                                message: "Please enter your current Address",
                              },
                            ],
                            validateTrigger: "onBlur",
                          })(
                            <TextArea
                              className="curr_address"
                              rows="3"
                              placeholder="Enter your Current address"
                              onChange={this.onAddressChange}
                              onBlur={(e) =>
                                (e.target.value = e.target.value.trim())
                              }
                            />
                          )}
                        </Form.Item>
                      </div>
                    </Col>
                  </Row>
                  <Row className="" gutter={30}>
                    <Col
                      className="gutter-row"
                      xs={24}
                      sm={24}
                      md={24}
                      lg={12}
                      xl={12}
                    >
                      <div className="form-group">
                        <Form.Item label="Locality" colon={false}>
                          {getFieldDecorator("locality", {
                            initialValue:
                              personal_details &&
                                personal_details.correspondence_address
                                ? personal_details.correspondence_address
                                  .locality
                                : "",
                            rules: [
                              {
                                required: true,
                                message: "Please enter your locality",
                              },
                            ],
                            validateTrigger: "onBlur",
                          })(
                            <Input
                              className="curr_locality"
                              maxLength="255"
                              placeholder="Enter your locality"
                              onChange={this.onLocalityChange}
                              onBlur={(e) =>
                                (e.target.value = e.target.value.trim())
                              }
                            />
                          )}
                        </Form.Item>
                      </div>
                    </Col>
                    <Col className="" xs={24} sm={24} md={24} lg={12} xl={12}>
                      <div className="form-group">
                        <Form.Item label="Pincode" colon={false}>
                          {getFieldDecorator("pincode", {
                            initialValue:
                              personal_details &&
                                personal_details.correspondence_address
                                ? personal_details.correspondence_address
                                  .pincode
                                : "",
                            rules: [
                              {
                                required: true,
                                message: "Please enter your pincode",
                              },
                            ],
                            validateTrigger: "onBlur",
                          })(
                            <Input
                              className="curr_pincode"
                              maxLength="6"
                              minLength="6"
                              placeholder="Enter your pincode"
                              onChange={this.onPincodeChange}
                              onBlur={(e) =>
                              (e.target.value = e.target.value.replace(
                                /\D/g,
                                ""
                              ))
                              }
                            />
                          )}
                        </Form.Item>
                      </div>
                    </Col>
                  </Row>
                  <Row className="" gutter={30}>
                    <Col
                      className="gutter-row"
                      xs={24}
                      sm={24}
                      md={24}
                      lg={12}
                      xl={12}
                    >
                      <div className="form-group">
                        <Form.Item label="City/District/Town" colon={false}>
                          {getFieldDecorator("city", {
                            initialValue:
                              personal_details &&
                                personal_details.correspondence_address
                                ? personal_details.correspondence_address.city
                                : "",
                            rules: [
                              {
                                required: true,
                                message: "Please enter your city",
                              },
                            ],
                            validateTrigger: "onBlur",
                          })(
                            <Input
                              className="curr_city"
                              maxLength="255"
                              placeholder="Enter city/district/town"
                              onChange={this.onCityChange}
                              onBlur={(e) =>
                                (e.target.value = e.target.value.trim())
                              }
                            />
                          )}
                        </Form.Item>
                      </div>
                    </Col>
                    <Col className="" xs={24} sm={24} md={24} lg={12} xl={12}>
                      <div className="form-group">
                        <Form.Item label="State" colon={false}>
                          {getFieldDecorator("state", {
                            initialValue:
                              personal_details &&
                                personal_details.correspondence_address
                                ? personal_details.correspondence_address.state
                                : "",
                            rules: [
                              {
                                required: true,
                                message: "Please select state",
                              },
                            ],
                          })(
                            <Select
                              className="curr_state"
                              showSearch
                              placeholder="-- Select State --"
                              optionFilterProp="children"
                            >
                              <option value="Andhra Pradesh">
                                Andhra Pradesh
                              </option>
                              <option value="Andaman and Nicobar Islands">
                                Andaman and Nicobar Islands
                              </option>
                              <option value="Arunachal Pradesh">
                                Arunachal Pradesh
                              </option>
                              <option value="Assam">Assam</option>
                              <option value="Bihar">Bihar</option>
                              <option value="Chandigarh">Chandigarh</option>
                              <option value="Chhattisgarh">Chhattisgarh</option>
                              <option value="Dadar and Nagar Haveli">
                                Dadar and Nagar Haveli
                              </option>
                              <option value="Daman and Diu">
                                Daman and Diu
                              </option>
                              <option value="Delhi">Delhi</option>
                              <option value="Lakshadweep">Lakshadweep</option>
                              <option value="Puducherry">Puducherry</option>
                              <option value="Goa">Goa</option>
                              <option value="Gujarat">Gujarat</option>
                              <option value="Haryana">Haryana</option>
                              <option value="Himachal Pradesh">
                                Himachal Pradesh
                              </option>
                              <option value="Jammu and Kashmir">
                                Jammu and Kashmir
                              </option>
                              <option value="Jharkhand">Jharkhand</option>
                              <option value="Karnataka">Karnataka</option>
                              <option value="Kerala">Kerala</option>
                              <option value="Madhya Pradesh">
                                Madhya Pradesh
                              </option>
                              <option value="Maharashtra">Maharashtra</option>
                              <option value="Manipur">Manipur</option>
                              <option value="Meghalaya">Meghalaya</option>
                              <option value="Mizoram">Mizoram</option>
                              <option value="Nagaland">Nagaland</option>
                              <option value="Odisha">Odisha</option>
                              <option value="Punjab">Punjab</option>
                              <option value="Rajasthan">Rajasthan</option>
                              <option value="Sikkim">Sikkim</option>
                              <option value="Tamil Nadu">Tamil Nadu</option>
                              <option value="Telangana">Telangana</option>
                              <option value="Tripura">Tripura</option>
                              <option value="Uttar Pradesh">
                                Uttar Pradesh
                              </option>
                              <option value="Uttarakhand">Uttarakhand</option>
                              <option value="West Bengal">West Bengal</option>
                            </Select>
                          )}
                        </Form.Item>
                      </div>
                    </Col>
                  </Row>
                </div>
              </Col>
              <Col
                className="gutter-row second-half-col"
                xs={24}
                sm={24}
                md={24}
                lg={12}
                xl={12}
              >
                <div className="form-content">
                  <Row className="" gutter={30}>
                    <Col
                      className="gutter-row"
                      xs={24}
                      sm={24}
                      md={24}
                      lg={12}
                      xl={12}
                    >
                      <div className="form-group">
                        <Form.Item label="Date of Birth" colon={false}>
                          {getFieldDecorator("dob_personal", {
                            initialValue: personal_details
                              ? personal_details.dob_personal
                                ? moment(personal_details.dob_personal)
                                : personal_details.dob_official
                                  ? moment(personal_details.dob_official)
                                  : ""
                              : "",
                            rules: [
                              {
                                required: true,
                                message: "Please select date of birth!",
                              },
                            ],
                          })(
                            <DatePicker
                              format={data_setting["date_format"]}
                              disabledDate={(current) => {
                                return current > moment();
                              }}
                            />
                          )}
                        </Form.Item>
                      </div>
                    </Col>

                    <Col
                      className="gutter-row"
                      xs={24}
                      sm={24}
                      md={24}
                      lg={12}
                      xl={12}
                    >
                      <div className="form-group">
                        <Form.Item label="Blood Group" colon={false}>
                          {getFieldDecorator("blood_group", {
                            initialValue: personal_details
                              ? data_setting["blood_group"][
                              personal_details.blood_group
                              ]
                              : "",
                            rules: [
                              {
                                required: true,
                                message: "Please select blood group",
                              },
                            ],
                          })(
                            <Select
                              className="selectbox"
                              showSearch
                              placeholder="Select your blood group"
                              optionFilterProp="children"
                            >
                              <Option value={data_setting["blood_group"]["A+"]}>
                                A+
                              </Option>
                              <Option value={data_setting["blood_group"]["A-"]}>
                                A-
                              </Option>
                              <Option value={data_setting["blood_group"]["B+"]}>
                                B+
                              </Option>
                              <Option value={data_setting["blood_group"]["B-"]}>
                                B-
                              </Option>
                              <Option value={data_setting["blood_group"]["O+"]}>
                                O+
                              </Option>
                              <Option value={data_setting["blood_group"]["O-"]}>
                                O-
                              </Option>
                              <Option
                                value={data_setting["blood_group"]["AB+"]}
                              >
                                AB+
                              </Option>
                              <Option
                                value={data_setting["blood_group"]["AB-"]}
                              >
                                AB-
                              </Option>
                              <Option
                                value={data_setting["blood_group"]["Unknown"]}
                              >
                                Unknown
                              </Option>
                            </Select>
                          )}
                        </Form.Item>
                      </div>
                    </Col>
                  </Row>
                  <Row className="" gutter={30}>
                    <Col
                      className="gutter-row"
                      xs={24}
                      sm={24}
                      md={24}
                      lg={24}
                      xl={24}
                    >
                      <div className="form-group">
                        <Form.Item label="College Name" colon={false}>
                          {getFieldDecorator("college", {
                            initialValue: personal_details
                              ? personal_details.college
                              : "",
                            rules: [
                              {
                                required: true,
                                message: "Please enter your college name.",
                              },
                            ],
                            validateTrigger: "onBlur",
                          })(
                            <Input
                              className="input"
                              maxLength="255"
                              placeholder="Enter your college name"
                              onBlur={(e) =>
                                (e.target.value = e.target.value.trim())
                              }
                            />
                          )}
                        </Form.Item>
                      </div>
                    </Col>
                  </Row>
                  {getFieldValue("marital_status") === data_setting["marital_status"]["married"] &&
                    <Row className="" gutter={30}>
                      <Col
                        className="gutter-row"
                        xs={24}
                        sm={24}
                        md={24}
                        lg={12}
                        xl={12}
                      >
                        <div className="form-group">
                          <Form.Item label="Spouse Name" colon={false}>
                            {getFieldDecorator("spouse_name", {
                              initialValue: personal_details
                                ? personal_details.spouse_name
                                : "",
                              rules: [
                                {
                                  required: true,
                                  message: "Please enter your Spouse's name.",
                                },
                              ],
                              validateTrigger: "onBlur",
                            })(
                              <Input
                                className="input"
                                maxLength="255"
                                placeholder="Enter your Spouse's Name"
                                onBlur={(e) =>
                                  (e.target.value = e.target.value.trim())
                                }
                              />
                            )}
                          </Form.Item>
                        </div>
                      </Col>
                      <Col
                        className="gutter-row"
                        xs={24}
                        sm={24}
                        md={24}
                        lg={12}
                        xl={12}
                      >
                        <div className="form-group">
                          <Form.Item label="Anniversary Date" colon={false}>
                            {getFieldDecorator("anniversary_date", {
                              initialValue: personal_details ?
                                (personal_details.anniversary_date
                                  ? moment(personal_details.anniversary_date) : null) : null
                              ,
                              rules: [
                                {
                                  required: true,
                                  message: "Enter your Anniversary Date",
                                },
                              ],
                            })(
                              <DatePicker
                                format={data_setting["date_format"]}
                                disabledDate={(current) => {
                                  return current > moment();
                                }}
                              />
                            )}
                          </Form.Item>
                        </div>
                      </Col>
                    </Row>
                  }
                  <Row className="" gutter={30}>
                    <Col
                      className="gutter-row"
                      xs={24}
                      sm={24}
                      md={24}
                      lg={12}
                      xl={12}
                    >
                      <div className="form-group">
                        <Form.Item label="Meal Preferences" colon={false}>
                          {getFieldDecorator("meal", {
                            initialValue: personal_details
                              ? personal_details.meal == "veg"
                                ? data_setting["meal"]["veg"]
                                : personal_details.meal == "nonveg"
                                  ? data_setting["meal"]["nonveg"]
                                  : ""
                              : "",
                            rules: [
                              {
                                required: true,
                                message: "Please select meal preference",
                              },
                            ],
                          })(
                            <Radio.Group
                              className="radio-btn"
                              onChange={this.onMealPreferences}
                              value={this.state.isVeg}
                            >
                              <div className="radio-icon veg-icon">
                                <Radio
                                  value={data_setting["meal"]["veg"]}
                                  className="veg-radio"
                                >
                                  Veg
                                </Radio>
                                <img className="icon veg" src={veg} alt="veg" />
                              </div>
                              <div className="radio-icon nonveg-icon">
                                <Radio
                                  value={data_setting["meal"]["nonveg"]}
                                  className="nonveg-radio"
                                >
                                  Non Veg
                                </Radio>
                                <img
                                  className="icon nonveg"
                                  src={nonveg}
                                  alt="nonveg"
                                />
                              </div>
                            </Radio.Group>
                          )}
                        </Form.Item>
                      </div>
                    </Col>
                    <Col className="" xs={24} sm={24} md={24} lg={12} xl={12}>
                      <div className="form-group">
                        <Form.Item label="Drink Preferences" colon={false}>
                          {getFieldDecorator("alcohol", {
                            initialValue: personal_details
                              ? personal_details.alcohol == "no"
                                ? data_setting["alcohol"]["no"]
                                : personal_details.alcohol == "yes"
                                  ? data_setting["alcohol"]["yes"]
                                  : ""
                              : "",
                            rules: [
                              {
                                required: true,
                                message: "Please select drink preference",
                              },
                            ],
                          })(
                            <Radio.Group
                              className="radio-btn"
                              onChange={this.onAlcholic}
                              value={this.state.isAlcoholic}
                            >
                              <div className="radio-icon wine-icon">
                                <Radio
                                  value={data_setting["alcohol"]["yes"]}
                                  className="alcoholic-radio"
                                >
                                  Alcoholic
                                </Radio>
                                <img
                                  className="icon wine"
                                  src={wine}
                                  alt="wine"
                                />
                              </div>
                              <div className="radio-icon juice-icon">
                                <Radio
                                  value={data_setting["alcohol"]["no"]}
                                  className="nonalcoholic-radio"
                                >
                                  Non-Alcoholic
                                </Radio>
                                <img
                                  className="icon juice"
                                  src={juice}
                                  alt="juice"
                                />
                              </div>
                            </Radio.Group>
                          )}
                        </Form.Item>
                      </div>
                    </Col>
                  </Row>

                  {/* Permanent Address */}

                  <div className="title-with-flex">
                    <h3 className="title text-primary">Permanent Address</h3>
                    <div className="address-same-checkbox">
                      <Checkbox
                        onChange={this.onCheckboxChangeHandle}
                        className="text-gray"
                      >
                        Same as current address
                      </Checkbox>
                    </div>
                  </div>

                  <Row className="" gutter={30}>
                    <Col
                      className="gutter-row"
                      xs={24}
                      sm={24}
                      md={24}
                      lg={24}
                      xl={24}
                    >
                      <div className="form-group">
                        <Form.Item label="Permanent Address" colon={false}>
                          {getFieldDecorator("perm_address", {
                            initialValue:
                              personal_details &&
                                personal_details.permanent_address
                                ? personal_details.permanent_address.address
                                : "",
                            rules: [
                              {
                                required: true,
                                message: "Please enter address",
                              },
                            ],
                            validateTrigger: "onBlur",
                          })(
                            <TextArea
                              className="perm_address"
                              rows="3"
                              placeholder="Please enter your permanent address"
                              onBlur={(e) =>
                                (e.target.value = e.target.value.trim())
                              }
                            />
                          )}
                        </Form.Item>
                      </div>
                    </Col>
                  </Row>
                  <Row className="" gutter={30}>
                    <Col
                      className="gutter-row"
                      xs={24}
                      sm={24}
                      md={24}
                      lg={12}
                      xl={12}
                    >
                      <div className="form-group">
                        <Form.Item label="Locality" colon={false}>
                          {getFieldDecorator("perm_locality", {
                            initialValue:
                              personal_details &&
                                personal_details.permanent_address
                                ? personal_details.permanent_address.locality
                                : "",
                            rules: [
                              {
                                required: true,
                                message: "Please enter your locality.",
                              },
                            ],
                            validateTrigger: "onBlur",
                          })(
                            <Input
                              className="perm_locality"
                              maxLength="255"
                              placeholder="Enter your locality"
                              onBlur={(e) =>
                                (e.target.value = e.target.value.trim())
                              }
                            />
                          )}
                        </Form.Item>
                      </div>
                    </Col>
                    <Col className="" xs={24} sm={24} md={24} lg={12} xl={12}>
                      <div className="form-group">
                        <Form.Item label="Pincode" colon={false}>
                          {getFieldDecorator("perm_pincode", {
                            initialValue:
                              personal_details &&
                                personal_details.permanent_address
                                ? personal_details.permanent_address.pincode
                                : "",
                            rules: [
                              {
                                required: true,
                                message: "Please type your pincode",
                              },
                            ],
                            validateTrigger: "onBlur",
                          })(
                            <Input
                              className="perm_pincode"
                              maxLength="6"
                              minLength="6"
                              placeholder="Enter enter your pincode"
                              onBlur={(e) =>
                              (e.target.value = e.target.value.replace(
                                /\D/g,
                                ""
                              ))
                              }
                            />
                          )}
                        </Form.Item>
                      </div>
                    </Col>
                  </Row>
                  <Row className="" gutter={30}>
                    <Col
                      className="gutter-row"
                      xs={24}
                      sm={24}
                      md={24}
                      lg={12}
                      xl={12}
                    >
                      <div className="form-group">
                        <Form.Item label="City/District/Town" colon={false}>
                          {getFieldDecorator("perm_city", {
                            initialValue:
                              personal_details &&
                                personal_details.permanent_address
                                ? personal_details.permanent_address.city
                                : "",
                            rules: [
                              {
                                required: true,
                                message: "Please type your city",
                              },
                            ],
                            validateTrigger: "onBlur",
                          })(
                            <Input
                              className="perm_city"
                              maxLength="255"
                              placeholder="Enter city/district/town"
                              onBlur={(e) =>
                                (e.target.value = e.target.value.trim())
                              }
                            />
                          )}
                        </Form.Item>
                      </div>
                    </Col>
                    <Col className="" xs={24} sm={24} md={24} lg={12} xl={12}>
                      <div className="form-group">
                        <Form.Item label="State" colon={false}>
                          {getFieldDecorator("perm_state", {
                            initialValue:
                              personal_details &&
                                personal_details.permanent_address
                                ? personal_details.permanent_address.state
                                : "",
                            rules: [
                              {
                                required: true,
                                message: "Please select state",
                              },
                            ],
                            validateTrigger: "onBlur",
                          })(
                            <Select
                              className="perm_state"
                              showSearch
                              placeholder="-- Select State --"
                              optionFilterProp="children"
                            >
                              <option value="Andhra Pradesh">
                                Andhra Pradesh
                              </option>
                              <option value="Andaman and Nicobar Islands">
                                Andaman and Nicobar Islands
                              </option>
                              <option value="Arunachal Pradesh">
                                Arunachal Pradesh
                              </option>
                              <option value="Assam">Assam</option>
                              <option value="Bihar">Bihar</option>
                              <option value="Chandigarh">Chandigarh</option>
                              <option value="Chhattisgarh">Chhattisgarh</option>
                              <option value="Dadar and Nagar Haveli">
                                Dadar and Nagar Haveli
                              </option>
                              <option value="Daman and Diu">
                                Daman and Diu
                              </option>
                              <option value="Delhi">Delhi</option>
                              <option value="Lakshadweep">Lakshadweep</option>
                              <option value="Puducherry">Puducherry</option>
                              <option value="Goa">Goa</option>
                              <option value="Gujarat">Gujarat</option>
                              <option value="Haryana">Haryana</option>
                              <option value="Himachal Pradesh">
                                Himachal Pradesh
                              </option>
                              <option value="Jammu and Kashmir">
                                Jammu and Kashmir
                              </option>
                              <option value="Jharkhand">Jharkhand</option>
                              <option value="Karnataka">Karnataka</option>
                              <option value="Kerala">Kerala</option>
                              <option value="Madhya Pradesh">
                                Madhya Pradesh
                              </option>
                              <option value="Maharashtra">Maharashtra</option>
                              <option value="Manipur">Manipur</option>
                              <option value="Meghalaya">Meghalaya</option>
                              <option value="Mizoram">Mizoram</option>
                              <option value="Nagaland">Nagaland</option>
                              <option value="Odisha">Odisha</option>
                              <option value="Punjab">Punjab</option>
                              <option value="Rajasthan">Rajasthan</option>
                              <option value="Sikkim">Sikkim</option>
                              <option value="Tamil Nadu">Tamil Nadu</option>
                              <option value="Telangana">Telangana</option>
                              <option value="Tripura">Tripura</option>
                              <option value="Uttar Pradesh">
                                Uttar Pradesh
                              </option>
                              <option value="Uttarakhand">Uttarakhand</option>
                              <option value="West Bengal">West Bengal</option>
                            </Select>
                          )}
                        </Form.Item>
                      </div>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </Form>
          <section className="profile-save-btn-box mt--30">
            <div className="save-btn-box text-right">
              <Button
                className="btn btn-success btn-wide text-uppercase"
                onClick={this.handleSubmit}
                loading={loading}
                disabled={loading}
              >
                Save
              </Button>
            </div>
          </section>
        </section>
      </Fragment>
    );
  }
}

export default Form.create({ name: "personal_details_form" })(
  RequireAuth(withStore(PersonalDetails))
);
