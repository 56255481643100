import React, { Component, useEffect } from "react";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";

export default function Graph({ data }) {
  useEffect(() => {
    am4core.useTheme(am4themes_animated);
    let chart = am4core.create("SalesChart", am4charts.XYChart);

    chart.data = data;
    // console.log({ data });
    data.length > 15 && (chart.scrollbarX = new am4core.Scrollbar());
    data.length > 15 && (chart.scrollbarY = new am4core.Scrollbar());
    chart.logo.disabled = true;
    chart.hiddenState.properties.opacity = 0;
    let finalKey = Object.keys(data[0]);

    // Create axes
    let dateAxis = chart.xAxes.push(new am4charts.DateAxis());
    let valueAxis1 = chart.yAxes.push(new am4charts.ValueAxis());
    let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());

    valueAxis1.numberFormatter = new am4core.NumberFormatter();
    // valueAxis1.numberFormatter.numberFormat = "#' hrs'";
    valueAxis1.numberFormatter.numberFormat = `#${
      window.innerWidth < 640 ? "h" : " hrs"
    }`;
    valueAxis1.min = 0;
    // chart.numberFormatter.numberFormat = `#${
    //   window.innerWidth < 640 ? "" : " hrs"
    // }`;

    dateAxis.renderer.grid.template.location = 0;
    dateAxis.renderer.minGridDistance = 50;
    dateAxis.renderer.grid.template.disabled = true;
    dateAxis.renderer.ticks.template.disabled = true;
    dateAxis.renderer.labels.template.fill = am4core.color("#C3CDDD");
    // dateAxis.renderer.line.strokeOpacity = 0.2;
    // var valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.renderer.grid.template.disabled = true;
    valueAxis.renderer.labels.template.text = "new";
    valueAxis.renderer.labels.template.fill = am4core.color("#C3CDDD");
    valueAxis.renderer.line.strokeOpacity = 0.2;
    // valueAxis1.title.text = "hrs";

    valueAxis1.renderer.grid.template.disabled = true;
    dateAxis.renderer.grid.template.width = 0;
    dateAxis.renderer.ticks.template.disabled = true;
    dateAxis.dateFormats.setKey("day", "dd MMM");

    // Create series
    let series = chart.series.push(new am4charts.ColumnSeries());
    series.dataFields.valueY = finalKey[1];
    series.dataFields.dateX = finalKey[0];
    series.yAxis = valueAxis1;

    // console.log("series.yAxis", series);

    //  var categoryAxis = employeeChart.categoryAxis;

    // series.name = "Hours";
    series.tooltipText = "[font-size: 12]{valueY} Hrs at {dateX}";
    series.fill = chart.colors.getIndex(0);
    series.strokeWidth = 0;
    series.columns.template.verticalCenter = 0;
    series.columns.template.column.cornerRadiusTopRight = 10;
    series.columns.template.column.cornerRadiusTopLeft = 10;
    series.fill = am4core.color("#247BFF");
    series.clustered = false;
    series.columns.template.width = am4core.percent(40);
    // bullet
    let labelBullet = series.bullets.push(new am4charts.LabelBullet());
    labelBullet.label.text = "{valueY}";
    labelBullet.label.dy = 10;
    labelBullet.label.fontSize = 10;
    labelBullet.label.fill = am4core.color("#fff");

    // Add cursor
    chart.cursor = new am4charts.XYCursor();

    // Add legend
    // chart.legend = new am4charts.Legend();
    // chart.legend.position = "top";

    // Add scrollbar
    // chart.scrollbarX = new am4charts.XYChartScrollbar();
    // chart.scrollbarX.series.push(series);
    // chart.scrollbarX.series.push(series3);
    // chart.scrollbarX.parent = chart.bottomAxesContainer;

    // chart.exporting.menu = new am4core.ExportMenu();
    // chart.exporting.adapter.add("xlsxWorkbook", function (wb) {
    //   // modify and add additional stuff to workbook object
    //   // ...
    //   wb.workbook.SheetNames.push("Data 2");
    //   wb.workbook.Sheets["Data 2"] = wb.xlsx.utils.aoa_to_sheet([
    //     ["Hello", "world"],
    //     ["Hello", "world"],
    //     ["Hello", "world"],
    //   ]);
    //   return wb;
    // });

    return () => {
      if (chart) {
        chart.dispose();
      }
    };
  }, [data]);

  return (
    <div>
      <div id="SalesChart" />
    </div>
  );
}
