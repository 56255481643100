import React, { Component , Fragment } from "react";
import { Icon, Button, Row, Col } from "antd";

export default class ViewEmergencyContacts extends Component {
    constructor(props) {
        super(props);
      }

    static getDerivedStateFromProps(props, state) {
        return {
            emergency_contact_1: props.emergency_contact_1,
            emergency_contact_2: props.emergency_contact_2,
        };
    }
    render() {
        const { emergency_contact_1, emergency_contact_2 } = this.state;
        const { name:e1_name = "-", relation:e1_relation = "-", number:e1_number = "-"} = emergency_contact_1 || {};
        const { name:e2_name = "-", relation:e2_relation = "-", number:e2_number = "-"} = emergency_contact_2 || {};
        const fallback  = "-";
        return (
            <Fragment>
                <section className="content-section emergency-detail-card-section">
                    <Row className="" gutter={ 30 }>
                        <Col className="gutter-row" xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
                            <div className="card card-shadow emergency-detail-card">
                                <h3 className="title text-primary">Emergency Contact #1</h3>
                                <Row className="" gutter={ 30 }>
                                    <Col className="gutter-row" xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                                        <div className="form-content form-group">
                                            <label className="label">Name</label>
                                            <div className="value">{e1_name || fallback}</div>
                                        </div>
                                    </Col>
                                    <Col className="gutter-row" xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                                        <div className="form-content form-group">
                                            <label className="label">Relation</label>
                                            <div className="value">{e1_relation || fallback}</div>
                                        </div>
                                    </Col>
                                    <Col className="gutter-row" xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                                        <div className="form-content form-group">
                                            <label className="label">Mobile Number</label>
                                            <div className="value">{e1_number || fallback}</div>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                        
                        <Col className="gutter-row" xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
                            <div className="card card-shadow emergency-detail-card">
                                <h3 className="title text-primary">Emergency Contact #2</h3>
                                <Row className="" gutter={ 30 }>
                                    <Col className="gutter-row" xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                                        <div className="form-content form-group">
                                            <label className="label">Name</label>
                                            <div className="value">{e2_name || fallback}</div>
                                        </div>
                                    </Col>
                                    <Col className="gutter-row" xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                                        <div className="form-content form-group">
                                            <label className="label">Relation</label>
                                            <div className="value">{e2_relation || fallback}</div>
                                        </div>
                                    </Col>
                                    <Col className="gutter-row" xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                                        <div className="form-content form-group">
                                            <label className="label">Mobile Number</label>
                                            <div className="value">{e2_number || fallback}</div>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    </Row>
                    <div className="floating-btn lg-hide">
                        <Button className="btn btn-floating btn-icon btn-round"><Icon type="plus" /></Button>
                    </div>
                </section>
            </Fragment>
        )
    }
}
