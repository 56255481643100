import React from 'react';
import { Modal, Upload } from "antd";
import moment from "moment";


const CardDetail = ({ leaveData }) => {

  const [imagePreviewVisible, setImagePreviewVisible] = React.useState(false)
  const [imagePreview, setImagePreview] = React.useState("")

  const getImageFiles = (imageUrls) => {
    if (imageUrls) {
      let imageResponse = [];
      imageUrls.forEach((url) => {
        imageResponse.push(
          { "uid": 'uid', "url": url }
        )
      })
      return imageResponse;
    }
    return [];
  }
  // for upload
  const handlePreviewCancel = () => {
    setImagePreviewVisible(false);
    setImagePreview("");
  };

  const handlePreview = (image) => {
    setImagePreview(image.url);
    setImagePreviewVisible(true);
  };

  // const actionTakenOn = new Date(leaveData.action_on).toLocaleString()
  const actionTakenOn = moment(leaveData.action_on, 'YYYY-MM-DD HH:mm:ss').format('DD-MMM-YYYY')
  const appliedOn = moment(leaveData.created_at, 'YYYY-MM-DD HH:mm:ss').format('DD-MMM-YYYY')
  return (
    <>
      <div className="card-body-container leave-details-container">
        <div className="leave-description" style={{ width: '100%' }}>
          <div>
            {leaveData.action_reason ? (
              <p><strong>{leaveData.status === 1 ? "Reason for Approval" : ( leaveData.status === 2 ? "Decline Reason" : "Cancellation Reason")}:&nbsp;</strong>{leaveData.action_reason}</p>
            ) : null}
            {leaveData.action_by ? (<p><strong>{leaveData.status === 1 ? "Approved By" : ( leaveData.status === 2 ? "Declined By" : "Cancelled By")}:&nbsp;</strong>{leaveData.action_by.name}</p>) : null}
            {leaveData.action_on ? (
              <p><strong>{leaveData.status === 1 ? "Approved On" : ( leaveData.status === 2 ? "Declined On" : "Cancelled On")}:&nbsp;</strong>{actionTakenOn}</p>
            ) : null}
            {leaveData.created_at ? (
              <p><strong>Applied On: &nbsp;</strong>{appliedOn}</p>
            ) : null}
            
            {leaveData.images && leaveData.images.length > 0 &&
              <div style={{ paddingTop: '1rem' }}>
                <Upload
                  beforeUpload={() => {
                    return false;
                  }}
                  listType="picture-card"
                  defaultFileList={getImageFiles(leaveData.images)}
                  isImageUrl={true}
                  onPreview={handlePreview}
                  showUploadList={{
                    showPreviewIcon: true,
                    showRemoveIcon: false,
                    showDownloadIcon: false,
                  }}
                >
                  {null}
                </Upload>

                <Modal
                  className="viewImgModal"
                  visible={imagePreviewVisible}
                  footer={null}
                  onCancel={handlePreviewCancel}
                >
                  <img
                    alt="leave image"
                    style={{ width: "100%", maxHeight: "100%" }}
                    src={imagePreview}
                  />
                </Modal>
              </div>
            }
          </div>
        </div>
      </div>
    </>
  );
};

export default CardDetail;
