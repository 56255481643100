import React, { Component, Fragment } from "react";
import { Link } from 'react-router-dom';
import { notification, Icon, Input, Button, message, Spin, Row, Col, Layout, Select, Card, Collapse, Modal, Pagination, spin } from "antd";
import QrReader from 'react-qr-reader'
import lottie from 'lottie-web';
import CardHeader from './CardHeader';
import CardContent from './CardContent';
import { getAssets, getAssetTypes, allocateUsingQr } from './DataManager';
import emptyState from '../../../assets/animation/json/emptyStateNew.json';
import ItAssetsIcon from '../../../assets/images/icons/sibebar/it-assets.svg';


// for Selectbox
const { Option } = Select;

// Pagination
const itemRender = (current, type, originalElement) => {
    if (type === 'prev') {
        return <a className="back-icon"> <Icon type="left" /> Back</a>;
    }
    if (type === 'next') {
        return <a className="next-icon">Next <Icon type="right" /></a>;
    }
    return originalElement;
}

// for collapse panel
const { Panel } = Collapse;

// for lottie
let animObj = null;

export default class ItAssets extends Component {
    constructor(props) {
        super(props);
        this.state = {
            assignedAccordionKey: null,
            result: 'No result',
            qrReaderVisible: false,
            modal1Visible: false,
            assets: [],
            assetTypes: null,
            assetTypesLoading: true,
            assetsloading: false,
            type_id: '',
            loading: false,
            allocated_to_self: true,
            allocationType: 'allocated_to_me',
            q: '',
            pagination: {
                total: null,
                count: null,
                per_page: null,
                current_page: null,
                total_pages: null
            }
        };
    }

    // for lottie
    componentDidMount() {
        // this.lottieAnimation()
        this.loadAssetTypes()
        this.loadAssets('unallocated', this.state.type_id, this.state.allocated_to_self)
    }

    lottieAnimation() {
        //call the loadAnimation to start the animation
        animObj = lottie.loadAnimation({
            container: this.animBox, // the dom element that will contain the animation
            renderer: 'svg',
            loop: true,
            autoplay: true,
            animationData: emptyState // the path to the animation json
        });
    }

    // For modal
    setModal1Visible(qrReaderVisible) {
        this.setState({ qrReaderVisible });
    }

    // for QR Reader
    handleScan = data => {

        if (data) {
            this.setState({
                result: data,
                qrReaderVisible: false,
                loading: true
            })

            allocateUsingQr(data)
                .then(result => {
                    this.setState({ loading: false })

                    notification.success({
                        message: 'Asset Allocated Successfully'
                    })
                    this.loadAssets('unallocated', this.state.type_id, this.state.allocated_to_self)
                })
                .catch(error => {
                    this.setState({ loading: false })

                    notification.error({
                        message: error.response.data.errors.message
                    })
                })
        }
    }

    handleError = err => {
        message.error("QR scanner is only available on Safari for iOS Devices, Please allow access if you are using the same.", 5);
        this.setModal1Visible(false)
    }


    // For accordion dropdown click
    assignedAccordionToggle = (key) => {
        var assignedKey = this.state.assignedAccordionKey;
        this.setState({
            assignedAccordionKey: assignedKey === key ? null : key
        })
    }

    loadAssetTypes() {
        return getAssetTypes()
            .then(res => {

                this.setState({ assetTypesLoading: false, assetTypes: res.data })
            })
            .catch(error => {
                this.setState({ assetTypesLoading: false })
                notification.error({
                    message: error.response.data.errors.message
                })
            })
    }

    loadAssets(status, type_id, allocated_to_self, q = '', page) {
        this.setState({ assetsloading: true })
        return getAssets(status, type_id, allocated_to_self, q, page ? page : 1)
            .then(res => {
                this.setState({ assets: res.data, pagination: res.meta.pagination, assetsloading: false, loading: false })
            })
            .catch(error => {
                notification.error({
                    message: error.response.data.errors.message
                })
                this.setState({ assetsloading: false })
            })
    }

    handleAllcoationChange = (value) => {
        if (value == 'unallocated') {
            this.setState({ allocated_to_self: false, allocationType: value }, () => {
                this.loadAssets('unallocated', this.state.type_id, this.state.allocated_to_self)
            })
        }
        else {
            this.setState({ allocated_to_self: true, allocationType: value }, () => {
                this.loadAssets('unallocated', this.state.type_id, this.state.allocated_to_self)
            })
        }
    }

    handleTypeChange = (value) => {

        this.setState({ type_id: value }, () => {
            this.loadAssets('unallocated', this.state.type_id, this.state.allocated_to_self)
        })
    }

    onChange = (e) => {

        let search = e.target.value
        this.setState({ q: search })
        this.loadAssets('unallocated', this.state.type_id, this.state.allocated_to_self, search)
    }

    onPageChange = (pageNumber) => {
        this.setState({ loading: true });
        const { type_id, allocated_to_self, q } = this.state
        this.loadAssets('unallocated', type_id, allocated_to_self, q, pageNumber)
    }


    render() {
        const { assetTypes, assets, allocationType, assetsloading, loading, allocated_to_self } = this.state
        const { total, per_page, current_page } = this.state.pagination;
        return (
            <Fragment>

                {loading ? <div className="page-spinner" > <Spin /></div> : (
                    <div className="itassets-container">
                        {/* page Header */}
                        <div className="page-header">
                            <div className="page-title">
                                <h2 className="title"><img className="icon icon-itassets" src={ItAssetsIcon} alt="view icon" /> Assets</h2>
                            </div>
                            <Button className="btn btn-secondary lg-hide" onClick={() => this.setModal1Visible(true)}><Icon type="scan" /></Button>
                        </div>

                        {/* page info title */}

                        <section className="page-info-box itAssets-infoBox animated slideInRight">
                            <div className="left multiple-item">
                                <div className="filter-firstHalf">
                                    <div className="filter-dropdown-box allocation-dropdown">
                                        <label className="label">Allocation status</label>
                                        <Select
                                            className="filter-dropdown"
                                            style={{ width: 210 }}
                                            placeholder="Select"
                                            defaultValue={allocated_to_self ? 'allocated_to_self' : 'unallocated'}
                                            onChange={this.handleAllcoationChange}
                                        >
                                            <Option value="allocated_to_self">Allocated to me</Option>
                                            <Option value="unallocated">Unallocated</Option>
                                        </Select>
                                    </div>
                                    <div className="filter-dropdown-box device-type-dropdown">
                                        <label className="label">Asset Type</label>
                                        <Select
                                            showSearch
                                            className="filter-dropdown"
                                            defaultValue=''
                                            style={{ width: 210 }}
                                            placeholder="Select"
                                            onChange={this.handleTypeChange}
                                            filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                        >
                                            <Option key='0' value=''> All Assets </Option>
                                            {assetTypes && assetTypes.map((assetType, index) => (
                                                < Option key={index} value={assetType.id} >
                                                    {assetType.type}
                                                </Option>
                                            ))}
                                        </Select>
                                    </div>
                                </div>
                            </div>
                            <div className="right">
                                <div className="search-box">
                                    <Input placeholder="Search by Reference ID"
                                        suffix={<Icon type="search" />} onChange={this.onChange}
                                    />
                                </div>
                            </div>
                        </section>

                        {/* Accordion Section */}
                        {assetsloading ? <div className="page-spinner" > <Spin /></div> : (
                            <section className="content-section assets-container-section assets-empContainer-section animated slideInUp">
                                {assets.length !== 0 &&
                                    <Fragment>
                                        <div className="content-body">

                                            <div className="head-row-flexView">
                                                <div className="head-row-content">
                                                    <div className="device-name-box">
                                                        <div className="head-title label">Asset Name</div>
                                                    </div>
                                                    <div className="rest-headerColumn-box">
                                                        <div className="head-title label">Asset Type</div>
                                                        <div className="head-title label">Reference ID</div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="accordion-row-box">
                                                <Collapse
                                                    className="itAssets-description-card"
                                                    bordered={false}
                                                    activeKey={this.state.assignedAccordionKey}
                                                >
                                                    {assets.length && assets.map((asset, index) => {
                                                        return (<Panel
                                                            header={
                                                                <CardHeader
                                                                    // showQR={this.toggleQrReader}
                                                                    assetHeader={asset}
                                                                />
                                                            }
                                                            key={index + 1}
                                                            showArrow={false}
                                                            extra={
                                                                <Icon type={this.state.assignedAccordionKey === index + 1 ? "up" : "down"} onClick={() => this.assignedAccordionToggle(index + 1)} />
                                                            } >
                                                            <CardContent assetContent={asset} allocationType={this.state.allocationType} />
                                                        </Panel>)

                                                    })}

                                                </Collapse>
                                            </div>
                                        </div>
                                        {total && per_page && total > per_page &&
                                            <div className="pagination-box text-right">
                                                <Pagination className="pagination" onChange={this.onPageChange} current={current_page} total={total} itemRender={itemRender} pageSize={per_page} />
                                            </div>
                                        }
                                    </Fragment>
                                }

                            </section>
                        )}

                        {/* empty state */}
                        {!assets.length && <div className="emptyState-container animated slideInUp">
                            <Row gutter={30} className="row">
                                <Col className="col gutter-row" lg={24}>
                                    <div className="emptyStateContent">
                                        <div className="lottieAnimaton" ref={ref => this.animBox = ref}></div>
                                        <h2 className="noData-text text-center">No data found!</h2>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                        }

                        <Modal
                            className="qrReader-modal"
                            title="Scan QR Code"
                            centered
                            visible={this.state.qrReaderVisible}
                            onOk={() => this.setModal1Visible(false)}
                            onCancel={() => this.setModal1Visible(false)}
                            footer={null}
                        >
                            {this.state.qrReaderVisible &&
                                <div className="qrReader-container">
                                    <div className="qrReader-content">
                                        <QrReader className="qr-reader"
                                            delay={1000}
                                            onError={this.handleError}
                                            onScan={this.handleScan}
                                        />
                                    </div>
                                </div>
                            }
                        </Modal>
                    </div>
                )}
            </Fragment >
        )
    }
}