import * as APIs from "../constants/BackendConstant";
import { axiosInstance } from "../utilities/configureAxios";

// Creating Leave Type
export const createLeaveTypeService = (body) => {
  let url = APIs.CREATE_LEAVE_TYPES.replace(":leave_type",body.type);
  return axiosInstance.post(url, body);
};

// UPDATING LEAVE
export const updateLeaveTypeService = (id, body) => {
  let url = APIs.UPDATE_LEAVE_TYPE.replace(":leave_type_id", id);
  return axiosInstance.post(url, body);
};

// ASSIGNING LEAVES
export const assignLeave = (body) => {
  let url = APIs.ASSIGN_LEAVE;
  return axiosInstance.post(url, body);
};

// Getting Leave Type DATA
export const getAllLeaveTypeData = (pageNumber) => {
  let url = APIs.LEAVE_TYPE_DATA.replace(":id", 1).replace(":page", pageNumber);
  return axiosInstance.get(url);
};

// Getting Leave of employee
export const getEmployeeLeaves = () => {
  let url = APIs.LIST_OF_EMPLOYEE_LEAVES.replace(":id", 1);
  return axiosInstance.get(url);
};

// Getting Leave of other employee
export const getOtherEmployeeLeaves = (user_id) => {
  let url = APIs.LIST_OF_LEAVES_FOR_OTHER_EMPLOYEE.replace(":id", 1).replace(
    ":user_id",
    user_id
  );
  return axiosInstance.get(url);
};

export const getActiveEmployeeLeaves = () => {
  let url = APIs.LIST_OF_ACTIVE_EMPLOYEE_LEAVES.replace(":id", 1);
  return axiosInstance.get(url);
};

export const getActiveOtherEmployeeLeaves = (user_id) => {
  let url = APIs.LIST_OF_ACTIVE_LEAVES_FOR_OTHER_EMPLOYEE.replace(":id", 1).replace(
    ":user_id",
    user_id
  );
  return axiosInstance.get(url);
};

// Apply Leave of employee
export const applyForEmployeeLeave = (body) => {
  let url = APIs.APPLY_FOR_EMPLOYEE_LEAVES;
  return axiosInstance.post(url, body);
};
// Create Notification
export const createNotification = (body) => {
  let url = APIs.HANDLE_CREATE_NOTIFICATION;
  return axiosInstance.post(url, body);
};
// EDIT Notification
export const EDITNotification = (body,id) => {
  let url = APIs.HANDLE_EDIT_NOTIFICATION.replace(':id',id);
  return axiosInstance.put(url, body);
};


// Apply Leave of employee by admin
export const applyForAdminLeave = (body) => {
  let url = APIs.ASSIGN_LEAVES;
  axiosInstance.defaults.headers.common["Content-Type"] = "multipart/form-data";
  return axiosInstance.post(url, body);
};
export const applyForUpdatedAdminLeave = (body,id) => {
  let url = APIs.ASSIGN_Updated_LEAVES.replace(':id',id);
  axiosInstance.defaults.headers.common["Content-Type"] = "multipart/form-data";
  return axiosInstance.post(url, body);
};

// Getting Leave of employee
export const getMyLeaves = (year = "", pageNumber=1) => {
  let url = APIs.SELF_LIST_OF_LEAVE_REQUEST.replace(":id", 1).replace(":year", year).replace(":pageNumber",pageNumber);
  return axiosInstance.get(url);
};

// Getting calculated leaves
export const getLeavesCalculated = (fromDate, toDate) => {
  let url = APIs.GET_DATES_CALCULATED.replace(":date1", fromDate).replace(
    ":date2",
    toDate
  );
  return axiosInstance.get(url);
};

// Gettingall employee leave
export const getEmpLeaves = (payload = new Date().getFullYear(),pageNumber = 1,allEmployees,leaveSearch) => {
  let url = APIs.MANAGE_LIST_OF_LEAVE.replace(":id", 1).replace(
    ":page_number",
    pageNumber
  ).replace(":year",payload).replace(":user_id",allEmployees).replace(":search",leaveSearch);
  return axiosInstance.get(url);
};
// Gettingall search employee leave
export const getSearchEmpLeaves = (pageNumber = 1, searchquery) => {
  let url = APIs.SEARCH_LIST_OF_LEAVE.replace(":id", 1)
    .replace(":page_number", pageNumber)
    .replace(":searchquery", searchquery);
  return axiosInstance.get(url);
};
// Leave handle
export const leaveAction = (body,id) => {
  let url = APIs.LEAVE_ACTION.replace(":id", id);
  return axiosInstance.put(url,body);
};

// GETTING LEAVE DETAils
export const getCompleteLeaveDetails = (id) => {
  let url = APIs.GET_LEAVE_DETAILS.replace(":id", id).replace(
    ":organizationId",
    1
  );
  return axiosInstance.get(url);
};

// GETTING LEAVE DETAils
export const getOtherEmployeeLeave = (user_id,year) => {
  let url = APIs.GET_EMPLOYEE_LEAVE_DETAILS.replace(":id", 1).replace(
    ":user_id",
    user_id
  ).replace(":year",year);
  return axiosInstance.get(url);
};

// LEAVE DAYS CALCULATION
export const leaveDaysCalculation = (payload) => {
  let myParam = "";
  let count = 0;
  Object.entries(payload).map(([key, val]) => {
    if (count === 0) {
      myParam += `?${key}=${val}`;
    } else {
      myParam += `&${key}=${val}`;
    }
    count++;
  });
  let url = APIs.DAYS_LEAVE_CALCULATION + myParam;
  return axiosInstance.get(url);
};

export const getLeaveListType = (payload) => {
  let myParam = "";
  let count = 0;
  Object.entries(payload).map(([key, val]) => {
    if (count === 0) {
      myParam += `?${key}=${val}`;
    } else {
      myParam += `&${key}=${val}`;
    }
    count++;
  });
  let url = APIs.LEAVE_TYPE_LIST + myParam;
  return axiosInstance.get(url);
};

// FOR ALL REQUESTS
export const listOfLeaves = (payload) => {
  let myParam = "";
  let count = 0;
  payload['paginate'] = 1;
  Object.entries(payload).map(([key, val]) => {
    if (count == 0) {
      myParam += `?${key}=${val}`;
    } else {
      myParam += `&${key}=${val}`;
    }
    count++;
  });
  let url = APIs.LIST_OF_LEAVES + myParam;
  return axiosInstance.get(url);
};

// FOR ALL REQUESTS
export const listOfAssignLeaves = (payload) => {
  let myParam = "";
  let count = 0;
  Object.entries(payload).map(([key, val]) => {
    if (count === 0) {
      myParam += `?${key}=${val}`;
    } else {
      myParam += `&${key}=${val}`;
    }
    count++;
  });
  let url = APIs.LIST_OF_ASSIGN_LEAVES + myParam;
  return axiosInstance.get(url);
};

// GETTING ALL LEAVE TYPES
export const listOfLeaveType = (payload) => {
  let myParam = "";
  let count = 0;
  Object.entries(payload).map(([key, val]) => {
    if (count === 0) {
      myParam += `?${key}=${val}`;
    } else {
      myParam += `&${key}=${val}`;
    }
    count++;
  });
  let url = APIs.LIST_OF_LEAVES_TYPES + myParam;
  return axiosInstance.get(url);
};
