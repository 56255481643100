import React, { Component , Fragment } from "react";
import { Form, Icon, Input, Button, Checkbox, message, Spin, Row, Col, Layout, Menu, Select, Card, Tabs } from "antd";
import car from '../../../../assets/images/icons/icon-car.svg';
import scooter from '../../../../assets/images/icons/icon-scooter.svg';
import cycle from '../../../../assets/images/icons/icon-cycle.svg';


// Dropdown
const { Option } = Select;

function onChangeVal(value) {
}

function onBlur() {
}

function onFocus() {
}

function onSearch(val) {
}

export default class AdminEditVehicleDetails extends Component {
    render() {
        return (
            <Fragment>
                <section className="tab-content-container vehicle-details-tab card-shadow">
                    <form>
                        <h3 className="title text-primary">Vehicle #1</h3>
                        <Row className="" gutter={30}>
                            <Col className="gutter-row" xs={24} sm={24} md={24} lg={4} xl={4}>
                                <div className="form-content form-group">
                                    <label className="label">Vehicle Type</label>
                                    <Select className="selectbox"
                                        showSearch
                                        placeholder="Select Vehicle Type"
                                        optionFilterProp="children"
                                        onChange={onChangeVal}
                                        onFocus={onFocus}
                                        onBlur={onBlur}
                                        onSearch={onSearch}
                                        filterOption={(input, option) =>
                                          option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                    >
                                        <Option value="scooter">Scooter</Option>
                                        <Option value="bike">Bike</Option>
                                        <Option value="car">Car</Option>
                                    </Select>
                                </div>
                            </Col>
                            <Col className="gutter-row" xs={24} sm={24} md={24} lg={4} xl={4}>
                                <div className="form-content form-group">
                                    <label className="label">Brand</label>
                                    <Input className="input" maxLength="255" placeholder="Enter Brand" />
                                </div>
                            </Col>
                            <Col className="gutter-row" xs={24} sm={24} md={24} lg={4} xl={4}>
                                <div className="form-content form-group">
                                    <label className="label">Color</label>
                                    <Input className="input" maxLength="255" placeholder="Enter Color" />
                                </div>
                            </Col>
                            <Col className="gutter-row" xs={24} sm={24} md={24} lg={6} xl={6}>
                                <div className="form-content form-group">
                                    <label className="label">Model/Make</label>
                                    <Input className="input" maxLength="255" placeholder="Enter Model" />
                                </div>
                            </Col>
                            <Col className="gutter-row" xs={24} sm={24} md={24} lg={6} xl={6}>
                                <div className="form-content form-group">
                                    <label className="label">Registration Number</label>
                                    <Input className="input" maxLength="255" placeholder="Enter Reg. No." />
                                </div>
                            </Col>
                        </Row>
                    </form>
                    <div className="add-more-vehicle-row add-more-btn text-right sm-row-align">
                        <Button className="btn btn-light-secondary text-uppercase lg-hide">Cancel</Button>
                        <Button className="btn btn-light-primary text-uppercase">+ Add More Vehicles</Button>
                    </div>
                    {/* <div className="floating-btn lg-hide">
                        <Button className="btn btn-floating btn-icon btn-round"><Icon type="plus" /></Button>
                    </div> */}
                </section>
                
                {/* Vehicle Detail card */}
                <section className="content-section vehicle-detail-card-section">
                    <Row className="" gutter={ 30 }>
                        <Col className="gutter-row" xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
                            <div className="card card-shadow vehicle-card">
                                <h3 className="title text-primary">Vehicle #1</h3>
                                <Row className="" gutter={ 30 }>
                                    <Col className="gutter-row" xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                                        <div className="form-content form-group">
                                            <label className="label">Vehicle Type</label>
                                            <div className="value"><img className="vehicle-icon" src={ scooter } alt="scooter icon" /></div>
                                        </div>
                                    </Col>
                                    <Col className="gutter-row" xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                                        <div className="form-content form-group">
                                            <label className="label">Brand</label>
                                            <div className="value">Yamaha</div>
                                        </div>
                                    </Col>
                                    <Col className="gutter-row" xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                                        <div className="form-content form-group">
                                            <label className="label">Model/Make</label>
                                            <div className="value">Fascino</div>
                                        </div>
                                    </Col>
                                    <Col className="gutter-row" xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                                        <div className="form-content form-group">
                                            <label className="label">Color</label>
                                            <div className="value">Red & Black</div>
                                        </div>
                                    </Col>
                                    <Col className="gutter-row" xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                                        <div className="form-content form-group">
                                            <label className="label">Registration Number</label>
                                            <div className="value">DL9SAM2345</div>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                        
                        <Col className="gutter-row" xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
                            <div className="card card-shadow vehicle-card">
                                <h3 className="title text-primary">Vehicle #2</h3>
                                <Row className="" gutter={ 30 }>
                                    <Col className="gutter-row" xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                                        <div className="form-content form-group">
                                            <label className="label">Vehicle Type</label>
                                            <div className="value"><img className="vehicle-icon" src={ car } alt="car icon" /></div>
                                        </div>
                                    </Col>
                                    <Col className="gutter-row" xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                                        <div className="form-content form-group">
                                            <label className="label">Brand</label>
                                            <div className="value">Audi</div>
                                        </div>
                                    </Col>
                                    <Col className="gutter-row" xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                                        <div className="form-content form-group">
                                            <label className="label">Model/Make</label>
                                            <div className="value">A6</div>
                                        </div>
                                    </Col>
                                    <Col className="gutter-row" xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                                        <div className="form-content form-group">
                                            <label className="label">Color</label>
                                            <div className="value">Black</div>
                                        </div>
                                    </Col>
                                    <Col className="gutter-row" xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                                        <div className="form-content form-group">
                                            <label className="label">Registration Number</label>
                                            <div className="value">DL9SAM2345</div>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                        
                        <Col className="gutter-row" xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
                            <div className="card card-shadow vehicle-card">
                                <h3 className="title text-primary">Vehicle #3</h3>
                                <Row className="" gutter={ 30 }>
                                    <Col className="gutter-row" xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                                        <div className="form-content form-group">
                                            <label className="label">Vehicle Type</label>
                                            <div className="value"><img className="vehicle-icon" src={ cycle } alt="cycle icon" /></div>
                                        </div>
                                    </Col>
                                    <Col className="gutter-row" xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                                        <div className="form-content form-group">
                                            <label className="label">Brand</label>
                                            <div className="value">Cycle</div>
                                        </div>
                                    </Col>
                                    <Col className="gutter-row" xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                                        <div className="form-content form-group">
                                            <label className="label">Model/Make</label>
                                            <div className="value">Racer</div>
                                        </div>
                                    </Col>
                                    <Col className="gutter-row" xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                                        <div className="form-content form-group">
                                            <label className="label">Color</label>
                                            <div className="value">Black</div>
                                        </div>
                                    </Col>
                                    <Col className="gutter-row" xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                                        <div className="form-content form-group">
                                            <label className="label">Registration Number</label>
                                            <div className="value">--</div>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    </Row>
                    <div className="floating-btn lg-hide">
                        <Button className="btn btn-floating btn-icon btn-round"><Icon type="plus" /></Button>
                    </div>
                </section>
                
                <section className="profile-save-btn-box">
                    <div className="save-btn-box text-right">
                        <Button className="btn btn-success btn-wide text-uppercase">Save</Button>
                    </div>
                </section>
            </Fragment>
        )
    }
}