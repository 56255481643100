import { Col, Spin } from "antd";
import React from "react";

const WorkReport = ({ children, loader, data, type }) => {
  if (loader && data.length === 0) {
    return (
      <div className="centerMe__workReport">
        <Spin />
      </div>
    );
  }
  if (data.length === 0) {
    return (
      <div className="body-col-wrap">
        <div className="divider-col" id="divider-col">
          <div className="divider"></div>
        </div>
        <Col className="work-report">
          <div className="header" style={{ textAlign: "center" }}>
            {" "}
            No work report found
          </div>
        </Col>
      </div>
    );
  }
  return <div className="workReport__pd">{children}</div>;
};

export default WorkReport;
