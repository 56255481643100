import React, { Fragment, useEffect, useState } from "react";
import MobileSider from "../../../shared/components/MobileSider";
import { withStore } from "../../../hocs/withStore";
import RequireAuth from "../../../hocs/RequireAuth";
import ProfileComplete from "../../../hocs/ProfileComplete";
import NotificationUpdate from "../../../hocs/NotificationUpdate";
import { axiosInstance as api } from "../../../utilities/configureAxios";

import {
  Card,
  DatePicker,
  Form,
  Empty,
  Spin,
  Row,
  Col,
  Button,
  Carousel,
  notification,
} from "antd";
import { getAllEmployeeList } from "../../dailyStatusManagement/DataManager";
import moment from "moment";
import "./dashboard.scss";
import _ from "lodash";
const DashboardMobile = (store) => {
  const data = JSON.parse(localStorage.getItem("data"));

  const [employeeOnLeave, setEmployeeOnLeave] = useState([]);
  const [employeeOnremote, setEmployeeOnremote] = useState([]);
  const [employeeOnProjectLeave, setEmployeeOnProjectLeave] = useState([]);
  const [worklog, setWorklog] = useState('');

  const [leaveDateList, setLeaveDateList] = useState(moment().format("YYYY-MM-DD"));
  const [loader, setLoader] = useState(false);

  const [leaveDateListRemote, setLeaveDateListRemote] = useState(moment().format("YYYY-MM-DD"));

  const [leaveDateListProjectleave, setLeaveDateListProjectleave] = useState(moment().format("YYYY-MM-DD"));

  const [datePickerValue, setdatePickerValue] = useState(moment());
  const [todaySyntax, setTodaySyntax] = useState("On Leave");

  const [datePickerValueRemote, setdatePickerValueRemote] = useState(moment());
  const [remoteSyntax, setRemoteSyntax] = useState("Remotely Available");

  const [datePickerValueProject, setdatePickerValueProject] = useState(moment());
  const [projectSyntax, setProjectSyntax] = useState("Project Leave");

  const getEmployeeLeaveList = (leaveListDate, type) => {
    setLoader(true);
    return getAllEmployeeList(leaveListDate, type)
      .then((res) => {
        res && res.data && res.data.data && setEmployeeByType(res.data.data, type);
        setLoader(false);

      })
      .catch((error) => {
        notification["error"]({
          message: _.get(
            error,
            "response.data.errors.message",
            "Oops! Something went wrong!"
          ),
        });
        setLoader(false);
      });
  };
  const setEmployeeByType = (employees, type) => {
    switch (type) {
      case 0:
        setEmployeeOnLeave(employees);
        break;
      case 1:
        setEmployeeOnremote(employees);
        break;
      case 2:
        setEmployeeOnProjectLeave(employees);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    getEmployeeLeaveList(leaveDateList, 0);
  }, []);

  useEffect(() => {
    getEmployeeLeaveList(leaveDateListRemote, 1);
  }, []);

  useEffect(() => {
    getEmployeeLeaveList(leaveDateListProjectleave, 2);
  }, []);

  useEffect(() => {
    setLoader(true);
    api
      .get(`${process.env.REACT_APP_API_URL}/users/missing-work-reports`)
      .then(res => {
        const worklog_count = _.get(res, 'data.data.total_counts', [])
        setWorklog(worklog_count);
        setLoader(false);
      })
      .catch(error => {
        notification['error']({
          message: _.get(
            error,
            'response.data.errors.message',
            'Oops! Something went wrong!'
          )
        })
        setLoader(false);
      })
  }, []);

  const onDateChange = (date) => {
    const formattedDate = date.format("YYYY-MM-DD");
    setLeaveDateList(formattedDate);
    setdatePickerValue(date);
    getEmployeeLeaveList(formattedDate, 0);

    const todaySyntax = getTodaySyntax(formattedDate);
    setTodaySyntax(todaySyntax);
  };

  const onDateChangeRemote = (date) => {
    const formattedDate = date.format("YYYY-MM-DD");
    setLeaveDateListRemote(formattedDate);
    setdatePickerValueRemote(date);
    getEmployeeLeaveList(formattedDate, 1);

    const remoteSyntax = getRemoteSyntax(formattedDate);
    setRemoteSyntax(remoteSyntax);
  };

  const onDateChangeProjectLeave = (date) => {
    const formattedDate = date.format("YYYY-MM-DD");
    setLeaveDateListProjectleave(formattedDate);
    setdatePickerValueProject(date);
    getEmployeeLeaveList(formattedDate, 2);

    const projectSyntax = getProjectSyntax(formattedDate);
    setProjectSyntax(projectSyntax);
  };

  const getTodaySyntax = (date) => {
    const diffDays = moment(date).diff(moment().format("YYYY-MM-DD"), "days");
    return diffDays === 0 ? "On Leave" : "On Leave";
  };

  const getRemoteSyntax = (date) => {
    const diffDays = moment(date).diff(moment().format("YYYY-MM-DD"), "days");
    return diffDays === 0 ? "Remotely Available" : "Remotely Available";
  };

  const getProjectSyntax = (date) => {
    const diffDays = moment(date).diff(moment().format("YYYY-MM-DD"), "days");
    return diffDays === 0 ? "Project Leave" : "Project Leave";
  };

  let session = "";
  const getSessionByType = (type) => {
    switch (type) {
      case 0:
        return sessionStatus;
      case 1:
        return sessionStatusRemote;
      case 2:
        return sessionStatusProjectLeave;
      default:
        return () => { };
    }
  };

  const sessionStatus = (startDate, endDate, fromSession, toSession) => {
    if (leaveDateList === startDate) {
      if (fromSession === 0 && toSession === null) {
        session = "First Half";
      } else if (fromSession === 0 && toSession !== null) {
        session = "Full Day";
      } else if (fromSession === 1) {
        session = "Second Half";
      } else {
        session = "Full Day";
      }
    } else if (leaveDateList === endDate && toSession !== null) {
      if (toSession === 0) {
        session = "First Half";
      } else {
        session = "Full Day";
      }
    } else {
      session = "Full Day";
    }
  };

  const sessionStatusProjectLeave = (startDate, endDate, fromSession, toSession) => {
    if (leaveDateListProjectleave === startDate) {
      if (fromSession === 0 && toSession === null) {
        session = "First Half";
      } else if (fromSession === 0 && toSession !== null) {
        session = "Full Day";
      } else if (fromSession === 1) {
        session = "Second Half";
      } else {
        session = "Full Day";
      }
    } else if (leaveDateListProjectleave === endDate && toSession !== null) {
      if (toSession === 0) {
        session = "First Half";
      } else {
        session = "Full Day";
      }
    } else {
      session = "Full Day";
    }
  };
  const sessionStatusRemote = (startDate, endDate, fromSession, toSession) => {
    if (leaveDateListRemote === startDate) {
      if (fromSession === 0 && toSession === null) {
        session = "First Half";
      } else if (fromSession === 0 && toSession !== null) {
        session = "Full Day";
      } else if (fromSession === 1) {
        session = "Second Half";
      } else {
        session = "Full Day";
      }
    } else if (leaveDateListRemote === endDate && toSession !== null) {
      if (toSession === 0) {
        session = "First Half";
      } else {
        session = "Full Day";
      }
    } else {
      session = "Full Day";
    }
  };
  return (
    <>
      <Fragment>
        <div className="dashboard-container lg-hide" style={{ paddingTop: "2rem", margin: "1rem", borderRadius: "25px" }}>
          <h2 className="welcome-message" style={{ paddingLeft: "1rem" }}>
            Hello {data.user.name}!
          </h2>

          {/* spin  */}
          {loader &&
            data.user_roles_name.indexOf("contract_employee") === -1 && (
              <div
                className="centerMe"
                style={{
                  height: "500px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Spin />
              </div>
            )}

          {/* on leave today */}
          {!loader && data.user_roles_name.indexOf("contract_employee") === -1 && (
            <div style={{ display: "flex", flexDirection: "column", marginBottom: "1rem" }}>
              <Form.Item colon={false} style={{ marginBottom: "0px" }}>
                <DatePicker
                  format={"DD/MM/YYYY"}
                  allowClear={false}
                  value={datePickerValue}
                  onChange={(date) => {
                    onDateChange(date, 0);
                  }}
                />
                <Card
                  title={todaySyntax}
                  className="card card-shadow docFolder-card"

                >
                  <div className="card-container" style={{ marginTop: "1rem" }}>
                    <Row type="flex" style={{ width: "100%" }}>
                      {employeeOnLeave.length === 0 ? (
                        <div className="center-me" style={{ paddingTop: "2rem" }}>
                          <Empty />
                        </div>
                      ) : (
                        employeeOnLeave.map((employee) => {
                          const sessionFn = getSessionByType(0);
                          sessionFn(
                            employee.from_date,
                            employee.to_date,
                            employee.from_session,
                            employee.to_session
                          );
                          return (
                            <Col span={24} style={{ marginBottom: "1rem" }}>
                              <div className="card-sub-container">
                                <div className="leave-user-card">
                                  <img
                                    className="image-custom"
                                    src={employee.user.profile_pic}
                                    alt="ProfileImage"
                                  />
                                  <span className="user-name">
                                    {employee.user.name}
                                  </span>
                                  <span className="leave-session">({session})</span>
                                </div>
                              </div>
                            </Col>
                          );
                        })
                      )}
                    </Row>
                  </div>
                </Card>
              </Form.Item>
            </div>
          )}

          {/* remotely available */}
          {!loader && employeeOnremote && (
            <div style={{ borderRadius: "25px" }}>
              <Form.Item colon={false} style={{ marginBottom: "0px" }}>
                <DatePicker
                  format={"DD/MM/YYYY"}
                  allowClear={false}
                  value={datePickerValueRemote}
                  onChange={(date) => {
                    onDateChangeRemote(date, 1);
                  }}
                />
              </Form.Item>
              <Card
                title={remoteSyntax}
                className="card card-shadow docFolder-card"
              >
                <div className="card-container" style={{ marginTop: "1rem" }}>
                  <Row type="flex" style={{ width: "100%" }}>
                    {employeeOnremote.length === 0 ? (
                      <div className="center-me" style={{ paddingTop: "2rem" }}>
                        <Empty />
                      </div>
                    ) : (
                      employeeOnremote.map((employee) => {
                        const sessionFn = getSessionByType(1);
                        sessionFn(
                          employee.from_date,
                          employee.to_date,
                          employee.from_session,
                          employee.to_session
                        );
                        return (
                          <Col span={24}>
                            <div className="card-sub-container">
                              <div className="leave-user-card">
                                <img
                                  className="image-custom"
                                  src={employee.user.profile_pic}
                                  alt="ProfileImage"
                                />
                                <span className="user-name">
                                  {employee.user.name}
                                </span>
                                <span className="leave-session">({session})</span>
                              </div>
                            </div>
                          </Col>
                        );
                      })
                    )}
                  </Row>
                </div>
              </Card>
            </div>


          )}

          {/* project leave */}
          {!loader &&
            data.user_roles_name.indexOf("contract_employee") === -1 && (
              <div style={{ borderRadius: "25px" }}>
                <Form.Item colon={false} style={{ marginBottom: "0px" }}>
                  <DatePicker
                    format={"DD/MM/YYYY"}
                    allowClear={false}
                    value={datePickerValueProject}
                    onChange={(date) => {
                      onDateChangeProjectLeave(date, 2);
                    }}
                  />
                </Form.Item>
                <Card
                  title={projectSyntax}
                  className="card card-shadow docFolder-card"
                >
                  <div className="card-container" style={{ marginTop: "1rem" }}>
                    <Row type="flex" style={{ width: "100%" }}>
                      {employeeOnProjectLeave.length === 0 ? (
                        <div className="center-me" style={{ paddingTop: "2rem" }}>
                          <Empty />
                        </div>
                      ) : (
                        employeeOnProjectLeave.map((employee) => {
                          const sessionFn = getSessionByType(2);
                          sessionFn(
                            employee.from_date,
                            employee.to_date,
                            employee.from_session,
                            employee.to_session
                          );
                          return (
                            <Col span={24}>
                              <div className="card-sub-container">
                                <div className="leave-user-card">
                                  <img
                                    className="image-custom"
                                    src={employee.user.profile_pic}
                                    alt="ProfileImage"
                                  />
                                  <span className="user-name">
                                    {employee.user.name}
                                  </span>
                                  <span className="leave-session">({session})</span>
                                </div>
                              </div>
                            </Col>
                          );
                        })
                      )}
                    </Row>
                  </div>
                </Card>
              </div>
            )}

          {/* worklog missing */}
          {!loader && worklog !== undefined && (
            <Row gutter={8}>
              <Col span={24}>
                {worklog === 0 ? (
                  <Card
                    title="Missed Work Log"
                    className="card card-shadow docFolder-card"
                    bordered={false}
                    style={{ margin: "10px", textAlign: "center" }}
                  >
                    <div
                      style={{
                        textAlign: "center",
                        fontSize: "60px",
                        color: "green",
                        paddingTop: "50px"
                      }}
                    >
                      {worklog}
                    </div>
                  </Card>
                ) : (
                  <Card
                    title="Missed Work Log"
                    className="card card-shadow docFolder-card"
                    bordered={false}
                    style={{ margin: "10px", textAlign: "center" }}
                  >
                    <div style={{ margin: "10px", fontSize: "25px" }}>
                      {worklog <= 4 ? (
                        <div
                          style={{
                            textAlign: "center",
                            fontSize: "60px",
                            color: "yellow",
                            paddingTop: "50px"
                          }}
                        >
                          {worklog}
                        </div>
                      ) : (
                        <div
                          style={{
                            textAlign: "center",
                            fontSize: "60px",
                            color: "red",
                            paddingTop: "50px"
                          }}
                        >
                          {worklog}
                        </div>
                      )}
                    </div>
                  </Card>
                )}
              </Col>
            </Row>
          )}

        </div>
      </Fragment>
    </>
  );
  //   }
};
export default withStore(
  RequireAuth(ProfileComplete(NotificationUpdate(DashboardMobile)))
);
