import React, { Component } from 'react';
import { Row, Col, Form, Spin, notification, Button, DatePicker } from 'antd';
import { billApprove } from '../../../../services/billService';
import moment from 'moment';

class ApproveDate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      expected_payment_date: moment().endOf('month'),
    };
  }

  showNotification = (type, title, subtitle) => {
    notification[type]({
      message: title,
      description: subtitle,
    });
  };

  componentDidMount() {
    this.setState({ expected_payment_date: moment().endOf('month') });
  }

  submitDetails = (e) => {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll(async (err, formData) => {
      if (!err) {
        this.props.setIsStatusUpdateLoading(true);
        const { expected_payment_date } = this.state;
        billApprove(this.props.id, {
          status: 'approved',
          payment_details: '',
          expected_payment_date: expected_payment_date.format('YYYY-MM-DD'),
        })
          .then((res) => {
            this.props.setIsStatusUpdateLoading(false);
            this.props.setIsBillModified(true);
            this.props.closeDrawer();
            this.showNotification('success', 'Updated Bill Status Successfully');
          })
          .catch((e) => {
            this.props.setIsStatusUpdateLoading(false);
            this.showNotification('error', 'Failed to update status');
          });
      }
    });
  };

  onDateChange = (date, dateString) => {
    this.setState({ expected_payment_date: moment(dateString) });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const { expected_payment_date } = this.state; 

    return (
      <div className='uploadBill-container'>
        <form>
          <div className='form-content'>
            <Row className='row'>
              <Col className='col' xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                <div className='form-group'>
                  <Form.Item label='Select Date' colon={false}>
                    {getFieldDecorator('expected_payment_date', {
                      rules: [
                        {
                          required: true,
                          message: 'Select a date',
                        },
                      ],
                      initialValue: expected_payment_date,
                    })(
                      <DatePicker
                        style={{ width: '100%' }}
                        format="YYYY-MM-DD"
                        onChange={this.onDateChange}
                      />
                    )}
                  </Form.Item>
                </div>
              </Col>
            </Row>
          </div>
        </form>
        <div className='formAction-footer-cont'>
          <div className='formAction-footer text-right'>
            <div className='upload-bill-btn-container'>
              <Spin spinning={this.props.isStatusUpdateLoading}>
                <Button onClick={this.submitDetails} className='btn btn-success btn-wide text-uppercase'>
                  Submit
                </Button>
              </Spin>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Form.create()(ApproveDate);
