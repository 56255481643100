import React, { Component, Fragment } from "react";
import { Layout } from "antd";
import Dashboard from "./components/Dashboard";
import { Helmet } from "react-helmet";

const TITLE = "Dashboard"
export default class DashboardManagement extends Component {
  render() {
    return (
      <Fragment>
        <Helmet>
          <title>{TITLE}</title>
        </Helmet>
        <div className="page-wrapper">
          <Dashboard></Dashboard>
        </div>
      </Fragment>
    );
  }
}
