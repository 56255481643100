import React, { Component, Fragment } from "react";
import { notification, Spin, Row, Col, Select, Card } from "antd";
import moment from "moment";
import RequireAuth from "../../../hocs/RequireAuth";
import ProfileComplete from "../../../hocs/ProfileComplete";
import { withStore } from "../../../hocs/withStore";
import _ from 'lodash';
import { axiosInstance as api } from '../../../utilities/configureAxios';
import holidayIcon from '../../../config/holidayIcon';
import PublicHoliday from '../../../assets/images/icons/sibebar/public-holidays.svg';
import NotificationUpdate from "../../../hocs/NotificationUpdate";

// year selectbox

const { Option } = Select;

class Holiday extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            years: [],
            holidays: [],
            selected_year: new Date().getFullYear()
        };
    }

    componentDidMount = () => {
        let year = new Date().getFullYear();
        this.setState({ years: [year - 1, year, year + 1], selected_year: year });
        this.setState({ loading: true });
        api
            .post('/holidays', { year: year })
            .then(res => {
                const holidays = _.get(res, 'data.data', [])
                this.setState({ holidays, loading: false });
            })
            .catch(error => {
                notification['error']({
                    message: _.get(error, 'response.data.errors.message', "Oops! Something went wrong!")
                });
                this.setState({ loading: false });
            });
    }

    onChange = (year) => {
        this.setState({ loading: true });
        api
            .post('/holidays', { year: year })
            .then(res => {
                const holidays = _.get(res, 'data.data', [])
                this.setState({ holidays, loading: false, selected_year: year });
            })
            .catch(error => {
                notification['error']({
                    message: _.get(error, 'response.data.errors.message', "Oops! Something went wrong!")
                });
                this.setState({ loading: false });
            });
    }

    getRandomClass = () => {
        return _.sample(['warning-card', 'primary-card', 'danger-card', 'info-card', 'success-card']);
    }

    getDate = date => {
        let new_date = new Date(date);
        return new_date.toDateString();
    }

    holidayIcon = type => {
        return holidayIcon[type] ? holidayIcon[type] : holidayIcon["Default"];
    }

    render() {
        const { holidays, selected_year, years, loading } = this.state;
        return (
            <Fragment>
                <div className="holiday-dashboard-container">
                    {loading ? (<div className="page-spinner"><Spin /></div>) : (
                        <Fragment>
                            {/* page Header */}
                            <div className="page-header animated slideInUp">
                                <div className="page-title">
                                    <h2 className="title"><img className="icon icon-view" src={PublicHoliday} alt="view icon" /> Public Holidays</h2>
                                    {/* <h2 className="title"><img className="icon icon-view" src={holiday} alt="view icon" /> Public Holidays</h2> */}
                                </div>
                            </div>

                            {/* page info title */}

                            <section className="page-info-box animated slideInRight">
                                <h3 className="info-title">List of all Holidays</h3>
                                <div className="right single-item">
                                    <div className="year-filter">
                                        <Select className="year-selectbox selectbox primary-shadow"
                                            style={{ width: 85 }}
                                            defaultValue={selected_year}
                                            placeholder="Year"
                                            optionFilterProp="children"
                                            onChange={this.onChange}
                                            filterOption={(input, option) =>
                                                option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }>
                                            {years.map(year => (
                                                <Option key={year} disabled={selected_year == year}>{year}</Option>
                                            ))}
                                        </Select>
                                    </div>
                                </div>
                            </section>

                            {/* Holday cards */}
                            <section className="content-section holiday-card-section card-shadow">
                                <Row className="" gutter={24}>
                                    {holidays.map(holiday => (
                                        <Col className="gutter-row" xs={24} sm={24} md={24} lg={8} xl={8} xxl={6}>

                                            <Card className="card new-holiday-card" style={{ backgroundImage: `url(${holiday.url})` }}>
                                                <div className="heading-box">
                                                    <h4 className="title">{holiday.reason}{holiday.type == 2 && <span> *</span>}</h4>
                                                    <p className="date text-gray">{moment(holiday.date, 'YYYY-MM-DD HH:mm:ss').format('DD-MMM-YYYY')}</p>
                                                </div>
                                                {/* <div className="icon-box">
                                                    {holiday.type == 2 ? <img className="holiday-icon" src={lock} alt="icon" /> : <img className="holiday-icon" src={this.holidayIcon(holiday.reason)} alt="icon" />}

                                                </div> */}
                                            </Card>
                                        </Col>
                                    ))}
                                    {holidays.length == 0 &&
                                        <Col className="gutter-row" xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                                            <h3 className="empty-state">No holidays for {selected_year} yet!</h3>
                                        </Col>}
                                </Row>
                            </section>

                            {/* Disclaimer Section*/}
                            <section className="disclaimer-box">
                                <div className="content">
                                    <p className="star-note">* Restricted leave rules will be applicable (Refer Employee Handbook)</p>
                                    <p className="note text-gray">Note: All the above dated are based on information available at the calendar year and may change based on the date of actual observation of the festival.</p>
                                </div>
                            </section>
                        </Fragment>
                    )}
                </div>
            </Fragment>
        )
    }
}

export default RequireAuth(withStore(ProfileComplete(NotificationUpdate(Holiday))));
