import React, { Fragment, useState } from 'react';
import { Icon, Button, notification, Divider } from 'antd';
import { get } from 'lodash';
import moment from 'moment';
import { updateBillStatus } from '../../../../services/billService';
import _ from "lodash";
import RequireAuth from '../../../../hocs/RequireAuth';

const CardHeaderMultipleDay = (props) => {
  const state = {
    auth_user: _.get(props, "store.data.user", []),
    user_roles_name: _.get(props, "store.data.user_roles_name", []),
  };

  const { auth_user } = state;
  const { user_roles_name } = state;
  const admin = user_roles_name.indexOf("admin") != -1;
  const office_admin = user_roles_name.indexOf("office_admin") != -1;
  const { id, start_datetime, end_datetime, title, description, user, amount, status } = get(props, 'data', {});
  const getMonthFromDate = (date) => {
    return moment(date).format('MMM');
  };

  const getDate = (date) => {
    return moment(date).format('DD');
  };

  const showNotification = (type, title, subtitle) => {
    notification[type]({
      message: title,
      description: subtitle
    });
  };

  const handleStatusUpdate = (status, data) => {
    props.setClickedItem(data)
    if (status === 'disapproved') {
      props.setIsReasonDrawerVisible(true);
    }
    else if (status === 'paid') {
      props.setIsPaidDetailsDrawerVisible(true);
    }else if (status === 'approved') {
      props.setIsApproveDetailsDrawerVisible(true);
    }
    else {
      updateStatus(status);
    }
  };

  const updateStatus = (status) => {
    props.setIsStatusUpdateLoading(true);
    updateBillStatus(id, { status })
      .then((res) => {
        props.setIsStatusUpdateLoading(false);
        props.setIsBillModified(true);
        showNotification('success', 'Updated Bill Status Successfully');
      })
      .catch((error) => {
        props.setIsStatusUpdateLoading(false);
        if (error.response.status === 422) {
          notification.error("Something went wrong.");
        } else {
          notification.error(error.response.data.errors);
        }
      });

  };

  const getColor = (status) => {
    switch (status.toLowerCase()) {
      case 'paid':
        return 'success';
      case 'approved':
        return 'info';
      case 'pending':
        return 'warning';
      case 'disapproved':
        return 'danger';
      default:
        return 'secondary';
    }
  };

  return (
    <Fragment>
      <div className='card-header-container'>
        <div className='part first-part'>
          <div className='dateDetail-box' >
            {/* Double */}
            <div className='double'>
              <div className='month-name heading'>{getMonthFromDate(start_datetime)}</div>
              <div className='date-content full-day'>{getDate(start_datetime)}</div>
            </div>

            <div className='double'>
              <div className='month-name heading'>{getMonthFromDate(end_datetime)}</div>
              <div className='date-content full-day'>{getDate(end_datetime)}</div>
            </div>

          </div>


          <div className="empName-box">
            <div
              className="label lg-hide"
              style={{ fontSize: "0.9rem !important" }}
            >
              Employee Profile
            </div>
            <div className='profileAP-container_bill' style={{ backgroundImage: `url(${user.profile_pic})` }}>
            </div>
          </div>

          <div className="empName-box">
            <div
              className="label lg-hide"
              style={{ fontSize: "0.9rem !important" }}
            >
              Employee Name
            </div>
            <div
              className="empName value"
              style={{ fontSize: "0.9rem !important" }}
            >
              {user.name}
            </div>
          </div>
          <div className="leaveType-box" style={{ marginLeft:"10px" }}>
            <div className="label lg-hide">Amount</div>
            <div
              className="leaveType value"
              style={{ fontSize: "0.9rem !important" }}
            >
              ₹ {amount.toLocaleString()}
            </div>
          </div>
        </div>
        <div className='part second-part ' style={{ justifyContent: "start" }}>
          <div className='status-box'>
            <div className='label lg-hide'>Status</div>
            <div className={`status-badge ${getColor(status)} badge-wide badge-bold`}>{status}</div>
          </div>
          {props.isEmpBill ? (

            <div className='icon-box'>
              <div className='label lg-hide'>Action</div>
              <Button
                disabled={(status === 'pending') ? false : true}
                onClick={() => {
                  props.setClickedItem(props.data);
                  props.setisConfirmModalVisible(true);
                }}
                className="btn btn-light-danger btn-icon"
              >
                <Icon type="delete" theme="filled" />
              </Button>

              <Divider type="vertical" />
              <Button
                disabled={(status === 'pending') ? false : true}
                type="link"
                className="btn btn-light-info btn-icon text-uppercase"
                style={{ backgroundColor: (status != 'pending') ? '#f5f5f5' : '' }}
                onClick={() => {
                  props.onEditBill(props.data);
                }}
              >
                <Icon type='edit' theme='filled' />
              </Button>

            </div>
          ) : (
            <div className='action-box'>
              <div className="admin-leave-action">

                {(status === "pending") && !office_admin &&
                  (
                    <Button
                      className="btn  btn-danger text-uppercase"
                      style={{ marginRight: "5px", fontSize: "0.9rem", width: '55%', textAlign: 'center', padding: '0px' }}
                      onClick={() => handleStatusUpdate('disapproved', props.data)}
                    >
                      Disapprove
                    </Button>
                  )
                }
                {(status === "pending" || status === "disapproved") && !office_admin &&
                  (
                    <Button
                      className="btn  btn-success text-uppercase"
                      style={{ fontSize: "0.9rem", width: '45%', textAlign: 'center', padding: '0px' }}
                      onClick={() => handleStatusUpdate('approved', props.data)}
                    >
                      Approve
                    </Button>
                  )
                }
                {(status === 'approved') && !office_admin &&
                  (
                    <Button
                      className="btn  btn-danger text-uppercase"
                      style={{ marginRight: "5px", fontSize: "0.9rem", width: '55%', textAlign: 'center', padding: '0px' }}
                      onClick={() => handleStatusUpdate('disapproved', props.data)}
                    >
                      Decline
                    </Button>
                  )
                }

                {(status == 'approved') && (admin || !auth_user.is_reporting_manager || office_admin) &&
                  (
                    <Button
                      className="btn  btn-primary text-uppercase"
                      style={{ fontSize: "0.9rem", width: '45%', textAlign: 'center', padding: '0px' }}
                      onClick={() => handleStatusUpdate('paid', props.data)}
                    >
                      Pay
                    </Button>
                  )
                }

              </div>
            </div>
          )}
        </div>
      </div>
      <p
        style={{
          marginTop: "1rem",
          wordBreak: "break-All",
          fontSize: "0.9rem",
        }}
      >
        <strong>Expensed On: </strong>
        {title}
      </p>

    </Fragment>
  );
};

export default RequireAuth(CardHeaderMultipleDay)
