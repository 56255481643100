import * as APIs from '../constants/BackendConstant';
import { axiosInstance } from '../utilities/configureAxios';

export const getAttendanceData = (page, month,status) => {
    let url = APIs.GET_SELF_ATTENDANCE.replace(':status', status);
    url = url.replace(':page', page);
    url = url.replace(':month',month.format("YYYY-MM"));
    return axiosInstance.get(url);
  };

  export const getAttendanceDataById = (id,page, month,status) => {
    let url = APIs.GET_SELF_ATTENDANCE_BY_ID.replace(':status', status);
    url = url.replace(':id',id);
    url = url.replace(':page', page);
    url = url.replace(':month',month.format("YYYY-MM"));
    return axiosInstance.get(url);
  };

 