import React, { Component, Fragment } from "react";
import {
  Form,
  notification,
  Input,
  Button,
  Row,
  Col,
  Layout,
  Menu,
  Select,
  Card,
  Tabs,
  DatePicker,
  Radio,
} from "antd";
import _ from "lodash";
import { axiosInstance as api } from "../../../utilities/configureAxios";
import url from "../../../config/url";
import data_setting from "../../../config/data_setting";
import { withStore } from "../../../hocs/withStore";
import RequireAuth from "../../../hocs/RequireAuth";
import moment from "moment";
const { Option } = Select;

// textarea
const { TextArea } = Input;

class EmployeeDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      employee_details: null,
      roles: null,
      managers: null,
      teams: null,
      roles_loading: true,
      managers_loading: true,
      teams_loading: true,
      levels_loading: true,
      loading: false,
      user_roles: null,
      user_roles_id: [],
      showErrors: false,
      user_roles_name: _.get(props, "store.data.user_roles_name", []),
    };
  }

  componentDidMount = () => {
    this.setState({ loading: true });
    const {
      store: { setData, storeData },
    } = this.props;
    api
      .get(url["getManagers"])
      .then((res) => {
        const managers = _.get(res, "data.data", []);
        this.setState({ managers, managers_loading: false });
      })
      .catch((error) => {
        notification["error"]({
          message: _.get(
            error,
            "response.data.errors.message",
            "Oops! Something went wrong!"
          ),
        });
        this.setState({ managers_loading: false });
      });
    api
      .get(url["getTeams"])
      .then((res) => {
        const teams = _.get(res, "data.data", []);
        this.setState({ teams, teams_loading: false });
      })
      .catch((error) => {
        notification["error"]({
          message: _.get(
            error,
            "response.data.errors.message",
            "Oops! Something went wrong!"
          ),
        });
        this.setState({ teams_loading: false });
      });
    api
      .get(url["getLevels"])
      .then((res) => {
        const levels = _.get(res, "data.data", []);
        this.setState({ levels, levels_loading: false });
      })
      .catch((error) => {
        notification["error"]({
          message: _.get(
            error,
            "response.data.errors.message",
            "Oops! Something went wrong!"
          ),
        });
        this.setState({ levels_loading: false });
      });
    api
      .get(url["getRoles"])
      .then((res) => {
        const roles = _.get(res, "data.data", []);
        this.setState({ roles, roles_loading: false });
      })
      .catch((error) => {
        notification["error"]({
          message: _.get(
            error,
            "response.data.errors.message",
            "Oops! Something went wrong!"
          ),
        });
        this.setState({ roles_loading: false });
      });
    api
      .get(url["myRoles"])
      .then((res) => {
        const user_roles = _.get(res, "data.data", []);
        this.setState({ user_roles, user_roles_id: _.map(user_roles, "id") });
        const user_roles_name = _.map(user_roles, "name", []);
        setData({ user_roles_name: [...user_roles_name] });
      })
      .catch((error) => {
        notification["error"]({
          message: _.get(
            error,
            "response.data.errors.message",
            "Could not get roles!"
          ),
        });
      });
    this.setState({ loading: false });
  };

  static getDerivedStateFromProps(props, state) {
    return {
      employee_details: props.employee_details,
    };
  }

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        values.date_of_joining = moment(values.date_of_joining).format(
          data_setting["post_date_format"]
        );
        values.dob_official = moment(values.dob_official).format(
          data_setting["post_date_format"]
        );
        // if(!(this.state.employee_details && this.state.employee_details.employment_type))
        // {
        //     delete values.employment_type;
        // }
        this.setState({ loading: true });
        const {
          store: { setData },
        } = this.props;
        values["profile_tab"] = "employee";
        switch (values["employment_sub_type"]) {
          case "Normal":
            values["employment_sub_type"] =
              data_setting["employment_sub_type"]["normal"];
            break;
          case "Notice Period":
            values["employment_sub_type"] =
              data_setting["employment_sub_type"]["notice_period"];
            break;
          case "Probation":
            values["employment_sub_type"] =
              data_setting["employment_sub_type"]["probation"];
            break;
          default:
            values["employment_sub_type"] =
              data_setting["employment_sub_type"]["normal"];
        }
        api
          .post(url["getProfile"], values)
          .then((data) => {
            const is_profile_complete = _.get(
              data,
              "data.data.is_profile_complete",
              false
            );
            setData({ is_profile_complete });
            notification["success"]({
              message: "Employee details updated successfully!",
            });
            api
              .get(url["myRoles"])
              .then((res) => {
                const user_roles = _.get(res, "data.data", []);
                const user_roles_name = _.map(user_roles, "name", []);
                this.setState({
                  user_roles,
                  user_roles_id: _.map(user_roles, "id"),
                  user_roles_name,
                });
                setData({ user_roles_name: [...user_roles_name] });
              })
              .catch((error) => {
                notification["error"]({
                  message: _.get(
                    error,
                    "response.data.errors.message",
                    "Could not get your roles!"
                  ),
                });
              });
            this.setState({ loading: false });
          })
          .catch((error) => {
            const errorData = _.get(error, "response.data.errors");
            if (errorData) {
              for (const field in errorData) {
                if (Object.prototype.hasOwnProperty.call(errorData, field)) {
                  this.props.form.setFields({
                    [field]: {
                      value: values[field],
                      errors: [new Error(errorData[field])],
                    },
                  });
                }
              }
            } else {
              notification["error"]({
                message: _.get(
                  error,
                  "response.data.errors.message",
                  "Oops! Something went wrong!"
                ),
              });
            }
            this.setState({ loading: false });
          });
      }
    });
  };
  render() {
    const { getFieldDecorator, getFieldValue } = this.props.form;
    const {
      employee_details,
      loading,
      roles,
      managers,
      teams,
      levels,
      levels_loading,
      teams_loading,
      roles_loading,
      managers_loading,
      user_roles_id,
      user_roles_name,
    } = this.state;
    const not_admin = user_roles_name.indexOf("admin") == -1;
    return (
      <Fragment>
        <section className="tab-content-container employee-details-tab card-shadow">
          <form>
            <h3 className="title text-primary">Your employment details</h3>
            <Row className="" gutter={30}>
              <Col className="gutter-row" xs={24} sm={24} md={24} lg={6} xl={6}>
                <div className="form-content form-group">
                  <Form.Item label="Employee ID" colon={false}>
                    {not_admin && (
                      <Input
                        className="input"
                        maxLength="255"
                        value={
                          employee_details ? employee_details.employee_id : ""
                        }
                        disabled
                      />
                    )}
                    {!not_admin &&
                      getFieldDecorator("emp_id", {
                        initialValue: employee_details
                          ? employee_details.employee_id
                          : "",
                        rules: [{ required: true, message: "Please enter ID" }],
                        validateTrigger: "onBlur",
                      })(<Input className="input" placeholder="Enter ID" />)}
                  </Form.Item>
                </div>
              </Col>
              <Col className="gutter-row" xs={24} sm={24} md={24} lg={6} xl={6}>
                <div className="form-content form-group">
                  <Form.Item label="Official Email ID" colon={false}>
                    {not_admin && (
                      <Input
                        type="email"
                        maxLength="255"
                        className="input"
                        value={
                          employee_details
                            ? employee_details.email_official
                            : ""
                        }
                        disabled
                      />
                    )}
                    {!not_admin &&
                      getFieldDecorator("email_official", {
                        initialValue: employee_details
                          ? employee_details.email_official
                          : "",
                        rules: [
                          { required: true, message: "Please enter email" },
                          {
                            type: "email",
                            message: "Please enter valid email",
                          },
                        ],
                        validateTrigger: "onBlur",
                      })(
                        <Input
                          type="email"
                          maxLength="255"
                          className="input"
                          placeholder="Enter Official email ID"
                        />
                      )}
                  </Form.Item>
                </div>
              </Col>
              <Col className="gutter-row" xs={24} sm={24} md={24} lg={6} xl={6}>
                <div className="form-content form-group">
                  <Form.Item label="Reporting Manager" colon={false}>
                    {not_admin && (
                      <Select
                        className="manager-selectbox selectbox"
                        showSearch
                        style={{ width: "100%" }}
                        placeholder="Select reporting manager"
                        optionFilterProp="children"
                        disabled
                        value={
                          !managers_loading &&
                          employee_details &&
                          employee_details.managers
                            ? employee_details.managers[0].id
                            : ""
                        }
                      >
                        {managers &&
                          managers.map((manager) => (
                            <Option key={manager.id} value={manager.id}>
                              {manager.name}
                            </Option>
                          ))}
                      </Select>
                    )}
                    {!not_admin &&
                      getFieldDecorator("managers[0]", {
                        initialValue:
                          !managers_loading &&
                          employee_details &&
                          employee_details.managers
                            ? employee_details.managers[0].id
                            : "",
                      })(
                        <Select
                          className="manager-selectbox selectbox"
                          showSearch
                          style={{ width: "100%" }}
                          placeholder="Select reporting manager"
                          optionFilterProp="children"
                          loading={managers_loading}
                        >
                          {managers &&
                            managers.map((manager) => (
                              <Option key={manager.id} value={manager.id}>
                                {manager.name}
                              </Option>
                            ))}
                        </Select>
                      )}
                  </Form.Item>
                </div>
              </Col>
              <Col className="gutter-row" xs={24} sm={24} md={24} lg={6} xl={6}>
                <div className="form-content form-group">
                  <Form.Item label="Date of Joining" colon={false}>
                    {not_admin && (
                      <DatePicker
                        format={data_setting["date_format"]}
                        value={
                          employee_details && employee_details.date_of_joining
                            ? moment(employee_details.date_of_joining)
                            : ""
                        }
                        disabled
                      />
                    )}
                    {!not_admin &&
                      getFieldDecorator("date_of_joining", {
                        initialValue:
                          employee_details && employee_details.date_of_joining
                            ? moment(employee_details.date_of_joining)
                            : "",
                        rules: [
                          {
                            type: "object",
                            required: true,
                            message: "Please select date of joining!",
                          },
                        ],
                      })(
                        <DatePicker
                          format={data_setting["date_format"]}
                          disabledDate={(current) => {
                            return current > moment();
                          }}
                        />
                      )}
                  </Form.Item>
                </div>
              </Col>
            </Row>
            <Row className="" gutter={30}>
              <Col className="gutter-row" xs={24} sm={24} md={24} lg={6} xl={6}>
                <div className="form-content form-group">
                  <Form.Item label="Employment Type" colon={false}>
                    {not_admin && (
                      <Select
                        className="emp-type-selectbox selectbox"
                        showSearch
                        style={{ width: "100%" }}
                        placeholder="Select Employment Type"
                        optionFilterProp="children"
                        disabled
                        value={
                          employee_details && employee_details.employment_type
                            ? data_setting["employment"][
                                employee_details.employment_type
                              ]
                            : ""
                        }
                      >
                        <Option value={data_setting["employment"]["Full-Time"]}>
                          Full Time
                        </Option>
                        <Option
                          value={data_setting["employment"]["Internship"]}
                        >
                          Internship
                        </Option>
                        <Option value={data_setting["employment"]["Contract"]}>
                          Contract
                        </Option>
                      </Select>
                    )}
                    {!not_admin &&
                      getFieldDecorator("employment_type", {
                        initialValue:
                          employee_details && employee_details.employment_type
                            ? data_setting["employment"][
                                employee_details.employment_type
                              ]
                            : "",
                      })(
                        <Select
                          className="emp-type-selectbox selectbox"
                          showSearch
                          style={{ width: "100%" }}
                          placeholder="Select Employment Type"
                          optionFilterProp="children"
                        >
                          <Option
                            value={data_setting["employment"]["Full-Time"]}
                          >
                            Full Time
                          </Option>
                          <Option
                            value={data_setting["employment"]["Internship"]}
                          >
                            Internship
                          </Option>
                          <Option
                            value={data_setting["employment"]["Contract"]}
                          >
                            Contract
                          </Option>
                        </Select>
                      )}
                  </Form.Item>
                </div>
              </Col>

              <Col className="gutter-row" xs={24} sm={24} md={24} lg={6} xl={6}>
                <div className="form-content form-group">
                  <Form.Item label="Employment Sub Type" colon={false}>
                    {not_admin && (
                      <Select
                        className="emp-type-selectbox selectbox"
                        showSearch
                        style={{ width: "100%" }}
                        placeholder="Select Employment Sub Type"
                        optionFilterProp="children"
                        disabled
                        value={
                          employee_details &&
                          employee_details.employment_sub_type
                        }
                      >
                        <Option value="Probation">Probation</Option>
                        <Option value="Normal">Normal</Option>
                        <Option value="Notice Period">Notice Period</Option>
                      </Select>
                    )}
                    {!not_admin &&
                      getFieldDecorator("employment_sub_type", {
                        initialValue:
                          employee_details &&
                          employee_details.employment_sub_type,
                        rules: [
                          {
                            required: true,
                            message: "Please select valid sub type",
                          },
                        ],
                      })(
                        <Select
                          className="emp-type-selectbox selectbox"
                          showSearch
                          style={{ width: "100%" }}
                          placeholder="Select Employment Sub Type"
                          optionFilterProp="children"
                        >
                          <Option value="Probation">Probation</Option>
                          <Option value="Normal">Normal</Option>
                          <Option value="Notice Period">Notice Period</Option>
                        </Select>
                      )}
                  </Form.Item>
                </div>
              </Col>

              <Col className="gutter-row" xs={24} sm={24} md={24} lg={6} xl={6}>
                <div className="form-content form-group">
                  <Form.Item label="Designation" colon={false}>
                    {not_admin && (
                      <Input
                        className="input"
                        maxLength="255"
                        disabled
                        value={
                          employee_details ? employee_details.designation : ""
                        }
                      />
                    )}
                    {!not_admin &&
                      getFieldDecorator("designation", {
                        initialValue: employee_details
                          ? employee_details.designation
                          : "",
                        rules: [
                          {
                            required: true,
                            message: "Please enter designation!",
                          },
                        ],
                        validateTrigger: "onBlur",
                      })(
                        <Input
                          className="input"
                          maxLength="255"
                          placeholder="Enter designation"
                          onBlur={(e) =>
                            (e.target.value = e.target.value.trim())
                          }
                        />
                      )}
                  </Form.Item>
                </div>
              </Col>
              <Col className="gutter-row" xs={24} sm={24} md={24} lg={6} xl={6}>
                <div className="form-content form-group">
                  <Form.Item label="Team" colon={false}>
                    {not_admin && (
                      <Select
                        className="team-selectbox selectbox"
                        showSearch
                        style={{ width: "100%" }}
                        placeholder="Select Team"
                        optionFilterProp="children"
                        loading={teams_loading}
                        disabled
                        value={
                          !teams_loading &&
                          employee_details &&
                          employee_details.team
                            ? employee_details.team.id
                            : ""
                        }
                      >
                        {teams &&
                          teams.map((team) => (
                            <Option key={team.id} value={team.id}>
                              {team.title}
                            </Option>
                          ))}
                      </Select>
                    )}
                    {!not_admin &&
                      getFieldDecorator("team", {
                        initialValue:
                          !teams_loading &&
                          employee_details &&
                          employee_details.team
                            ? employee_details.team.id
                            : "",
                        rules: [
                          {
                            required: true,
                            message: "Select team",
                          },
                        ],
                        validateTrigger: "onBlur",
                      })(
                        <Select
                          className="team-selectbox selectbox"
                          showSearch
                          style={{ width: "100%" }}
                          placeholder="Select Team"
                          optionFilterProp="children"
                          loading={teams_loading}
                        >
                          {teams &&
                            teams.map((team) => (
                              <Option key={team.id} value={team.id}>
                                {team.title}
                              </Option>
                            ))}
                        </Select>
                      )}
                  </Form.Item>
                </div>
              </Col>
              <Col className="gutter-row" xs={24} sm={24} md={24} lg={6} xl={6}>
                <div className="form-content form-group">
                  <Form.Item label="DOB (Official)" colon={false}>
                    {/* <DatePicker
                                            format={data_setting['date_format']}
                                            value={employee_details && employee_details.dob_official ? moment(employee_details.dob_official) : ""}
                                            disabled
                                        /> */}
                    {getFieldDecorator("dob_official", {
                      initialValue:
                        employee_details && employee_details.dob_official
                          ? moment(employee_details.dob_official)
                          : "",
                      rules: [
                        {
                          type: "object",
                          required: true,
                          message: "Please select date of birth!",
                        },
                      ],
                    })(
                      <DatePicker
                        format={data_setting["date_format"]}
                        disabledDate={(current) => {
                          return current > moment();
                        }}
                      />
                    )}
                  </Form.Item>
                </div>
              </Col>
              <Col className="gutter-row" xs={24} sm={24} md={24} lg={6} xl={6}>
                <div className="form-content form-group">
                  <Form.Item label="Role" colon={false}>
                    {not_admin && (
                      <Select
                        className="role-selectbox selectbox"
                        showSearch
                        mode="multiple"
                        style={{ width: "100%" }}
                        placeholder="Select Role"
                        optionFilterProp="children"
                        loading={roles_loading}
                        value={!roles_loading ? user_roles_id : []}
                        disabled
                      >
                        {roles &&
                          roles.map((role) => (
                            <Option key={role.id} value={role.id}>
                              {role.name.replace("_", " ").toUpperCase()}
                            </Option>
                          ))}
                      </Select>
                    )}
                    {!not_admin &&
                      getFieldDecorator("roles", {
                        initialValue: !roles_loading ? user_roles_id : [],
                        rules: [
                          {
                            required: true,
                            message: "Select role",
                          },
                        ],
                        validateTrigger: "onBlur",
                      })(
                        <Select
                          className="role-selectbox selectbox"
                          showSearch
                          mode="multiple"
                          style={{ width: "100%" }}
                          placeholder="Select Role"
                          optionFilterProp="children"
                          loading={roles_loading}
                        >
                          {roles &&
                            roles.map((role) => (
                              <Option key={role.id} value={role.id}>
                                {role.name.replace("_", " ").toUpperCase()}
                              </Option>
                            ))}
                        </Select>
                      )}
                  </Form.Item>
                </div>
              </Col>
              <Col className="gutter-row" xs={24} sm={24} md={24} lg={6} xl={6}>
                <div className="form-content form-group">
                  <Form.Item label="Level" colon={false}>
                    {not_admin && (
                      <Select
                        className="role-selectbox selectbox"
                        showSearch
                        style={{ width: "100%" }}
                        placeholder="Select Role"
                        optionFilterProp="children"
                        loading={levels_loading}
                        disabled
                        value={
                          !levels_loading &&
                          employee_details &&
                          employee_details.level
                            ? employee_details.level.id
                            : ""
                        }
                      >
                        {levels &&
                          levels.map((level) => (
                            <Option key={level.id} value={level.id}>
                              {level.title}
                            </Option>
                          ))}
                      </Select>
                    )}
                    {!not_admin &&
                      getFieldDecorator("level", {
                        initialValue:
                          !levels_loading &&
                          employee_details &&
                          employee_details.level
                            ? employee_details.level.id
                            : "",
                        rules: [
                          {
                            required: true,
                            message: "Select level",
                          },
                        ],
                        validateTrigger: "onBlur",
                      })(
                        <Select
                          className="role-selectbox selectbox"
                          showSearch
                          style={{ width: "100%" }}
                          placeholder="Select Role"
                          optionFilterProp="children"
                          loading={levels_loading}
                        >
                          {levels &&
                            levels.map((level) => (
                              <Option key={level.id} value={level.id}>
                                {level.title}
                              </Option>
                            ))}
                        </Select>
                      )}
                  </Form.Item>
                </div>
              </Col>
            </Row>
            <h3 className="title text-primary">Your social profiles</h3>
            <Row className="" gutter={30}>
              <Col className="gutter-row" xs={24} sm={24} md={24} lg={6} xl={6}>
                <div className="form-content form-group-card">
                  <Form.Item label="Trello Username" colon={false}>
                    {getFieldDecorator("trello", {
                      initialValue: employee_details
                        ? employee_details.trello
                        : "",
                    })(
                      <Input
                        className="input"
                        maxLength="255"
                        placeholder="Enter trello username"
                        onBlur={(e) => (e.target.value = e.target.value.trim())}
                        onChange={(e) => {
                          this.props.form.setFields({
                            "trello": {
                              value: e.target.value,
                              errors: null,
                            },
                          });
                        }}
                      />
                    )}
                  </Form.Item>
                </div>
              </Col>
              <Col className="gutter-row" xs={24} sm={24} md={24} lg={6} xl={6}>
                <div className="form-content form-group-card">
                  <Form.Item label="Bitbucket Username" colon={false}>
                    {getFieldDecorator("bitbucket", {
                      initialValue: employee_details
                        ? employee_details.bitbucket
                        : "",
                    })(
                      <Input
                        className="input"
                        maxLength="255"
                        placeholder="Enter bitbucket username"
                        onBlur={(e) => (e.target.value = e.target.value.trim())}
                        onChange={(e) => {
                          this.props.form.setFields({
                            "bitbucket": {
                              value: e.target.value,
                              errors: null,
                            },
                          });
                        }}
                      />
                    )}
                  </Form.Item>
                </div>
              </Col>
              <Col className="gutter-row" xs={24} sm={24} md={24} lg={6} xl={6}>
                <div className="form-content form-group-card">
                  <Form.Item label="Dribbble Username" colon={false}>
                    {getFieldDecorator("dribble", {
                      initialValue: employee_details
                        ? employee_details.dribble
                        : "",
                    })(
                      <Input
                        className="input"
                        maxLength="255"
                        placeholder="Enter dribble username"
                        onBlur={(e) => (e.target.value = e.target.value.trim())}
                        onChange={(e) => {
                          this.props.form.setFields({
                            "dribble": {
                              value: e.target.value,
                              errors: null,
                            },
                          });
                        }}
                      />
                    )}
                  </Form.Item>
                </div>
              </Col>
              <Col className="gutter-row" xs={24} sm={24} md={24} lg={6} xl={6}>
                <div className="form-content form-group-card">
                  <Form.Item label="Github Username" colon={false}>
                    {getFieldDecorator("github", {
                      initialValue: employee_details
                        ? employee_details.github
                        : "",
                    })(
                      <Input
                        className="input"
                        maxLength="255"
                        placeholder="Enter Github username"
                        onBlur={(e) => (e.target.value = e.target.value.trim())}
                        onChange={(e) => {
                          this.props.form.setFields({
                            "github": {
                              value: e.target.value,
                              errors: null,
                            },
                          });
                        }}
                      />
                    )}
                  </Form.Item>
                </div>
              </Col>
              <Col className="gutter-row" xs={24} sm={24} md={24} lg={6} xl={6}>
                <div className="form-content form-group-card">
                  <Form.Item label="Twitter Username" colon={false}>
                    {getFieldDecorator("twitter", {
                      initialValue: employee_details
                        ? employee_details.twitter
                        : "",
                    })(
                      <Input
                        className="input"
                        maxLength="255"
                        placeholder="Enter Twitter username"
                        onBlur={(e) => (e.target.value = e.target.value.trim())}
                        onChange={(e) => {
                          this.props.form.setFields({
                            "twitter": {
                              value: e.target.value,
                              errors: null,
                            },
                          });
                        }}
                      />
                    )}
                  </Form.Item>
                </div>
              </Col>
              <Col className="gutter-row" xs={24} sm={24} md={24} lg={6} xl={6}>
                <div className="form-content form-group-card">
                  <Form.Item label="Facebook URL" colon={false}>
                    {getFieldDecorator("facebook", {
                      initialValue: employee_details
                        ? employee_details.facebook
                        : "",
                    })(
                      <Input
                        className="input"
                        maxLength="255"
                        placeholder="Enter Facebook URL"
                        onBlur={(e) => (e.target.value = e.target.value.trim())}
                        onChange={(e) => {
                          this.props.form.setFields({
                            "facebook": {
                              value: e.target.value,
                              errors: null,
                            },
                          });
                        }}
                      />
                    )}
                  </Form.Item>
                </div>
              </Col>
              <Col className="gutter-row" xs={24} sm={24} md={24} lg={6} xl={6}>
                <div className="form-content form-group-card">
                  <Form.Item label="LinkedIn URL" colon={false}>
                    {getFieldDecorator("linkedin", {
                      initialValue: employee_details
                        ? employee_details.linkedin
                        : "",
                    })(
                      <Input
                        className="input"
                        maxLength="255"
                        placeholder="Enter LinkedIn URL"
                        onBlur={(e) => (e.target.value = e.target.value.trim())}
                        onChange={(e) => {
                          this.props.form.setFields({
                            "linkedin": {
                              value: e.target.value,
                              errors: null,
                            },
                          });
                        }}
                      />
                    )}
                  </Form.Item>
                </div>
              </Col>
              <Col className="gutter-row" xs={24} sm={24} md={24} lg={6} xl={6}>
                <div className="form-content form-group-card">
                  <Form.Item label="Instagram Username" colon={false}>
                    {getFieldDecorator("instagram", {
                      initialValue: employee_details
                        ? employee_details.instagram
                        : "",
                    })(
                      <Input
                        className="input"
                        maxLength="255"
                        placeholder="Enter Instagram Username"
                        onBlur={(e) => (e.target.value = e.target.value.trim())}
                        onChange={(e) => {
                          this.props.form.setFields({
                            "instagram": {
                              value: e.target.value,
                              errors: null,
                            },
                          });
                        }}
                      />
                    )}
                  </Form.Item>
                </div>
              </Col>
              <Col className="gutter-row" xs={24} sm={24} md={24} lg={6} xl={6}>
                <div className="form-content form-group-card">
                  <Form.Item label="Blog URL" colon={false}>
                    {getFieldDecorator("blog", {
                      initialValue: employee_details
                        ? employee_details.blog
                        : "",
                    })(
                      <Input
                        className="input"
                        maxLength="255"
                        placeholder="Enter Blog URL"
                        onBlur={(e) => (e.target.value = e.target.value.trim())}
                        onChange={(e) => {
                          this.props.form.setFields({
                            "blog": {
                              value: e.target.value,
                              errors: null,
                            },
                          });
                        }}
                      />
                    )}
                  </Form.Item>
                </div>
              </Col>
              <Col className="gutter-row" xs={24} sm={24} md={24} lg={6} xl={6}>
                <div className="form-content form-group-card">
                  <Form.Item label="Medium URL" colon={false}>
                    {getFieldDecorator("medium", {
                      initialValue: employee_details
                        ? employee_details.medium
                        : "",
                    })(
                      <Input
                        className="input"
                        maxLength="255"
                        placeholder="Enter Medium URL"
                        onBlur={(e) => (e.target.value = e.target.value.trim())}
                        onChange={(e) => {
                          this.props.form.setFields({
                            "medium": {
                              value: e.target.value,
                              errors: null,
                            },
                          });
                        }}
                      />
                    )}
                  </Form.Item>
                </div>
              </Col>
              <Col className="gutter-row" xs={24} sm={24} md={24} lg={6} xl={6}>
                <div className="form-content form-group-card">
                  <Form.Item label="Youtube URL" colon={false}>
                    {getFieldDecorator("youtube", {
                      initialValue: employee_details
                        ? employee_details.youtube
                        : "",
                    })(
                      <Input
                        className="input"
                        maxLength="255"
                        placeholder="Enter Youtube URL"
                        onBlur={(e) => (e.target.value = e.target.value.trim())}
                        onChange={(e) => {
                          this.props.form.setFields({
                            "youtube": {
                              value: e.target.value,
                              errors: null,
                            },
                          });
                        }}
                      />
                    )}
                  </Form.Item>
                </div>
              </Col>
            </Row>
          </form>
        </section>

        <section className="profile-save-btn-box mt--30">
          <div className="save-btn-box text-right">
            <Button
              className="btn btn-success btn-wide text-uppercase"
              onClick={this.handleSubmit}
              loading={loading}
              disabled={loading}
            >
              Save
            </Button>
          </div>
        </section>
      </Fragment>
    );
  }
}
export default Form.create({ name: "employee_contacts_form" })(
  RequireAuth(withStore(EmployeeDetails))
);
