import React, { Component, Fragment } from "react";
import { Layout } from "antd";
import { Helmet } from 'react-helmet'
import ItAssets from "./components/ItAssets";

const TITLE = 'Assets'

export default class ItAssetsManagement extends Component {
  render() {
    return (
      <Fragment>
        <Helmet>
          <title>{TITLE}</title>
        </Helmet>
        <div className="page-wrapper">
          <ItAssets></ItAssets>
        </div>
      </Fragment>
    );
  }
}
