import React, {useState , useEffect} from "react";
import { withStore } from "../../../hocs/withStore";
import NotificationUpdate from "../../../hocs/NotificationUpdate";
import { getSuggestedEmployees } from "../../../services/ProjectService";
import { Spin, Row, Col ,Table,Pagination} from "antd";

const SuggestEmpView = ({ skills }) => {
const [response , setResponse] = useState([]);  
const [pageCurrent, setpageCurrent] = useState(1);
const [loading , setLoading] = useState(false);
  const handlePagination = (pagination) => {
    setpageCurrent(pagination.current);
    getSuggestedemployeeData(pagination.current,skills);
  };

  const getSuggestedemployeeData = (page,skills) => {
    setLoading(true);
    return getSuggestedEmployees(page,skills).then((res) => {
      setResponse(res.data)
      setLoading(false);
    });
  };

  useEffect(()=>{
   setpageCurrent(1)
    if(skills){
      getSuggestedemployeeData(1,skills)
    }
  },[skills])
  var columns = [
    {
      title: "S.No.",
      key: "index",
      className: "text-center",
      render: (text, object, index) => (
        <div>{(pageCurrent - 1) * 10 + (index + 1)}</div>
      ),
    },
    {
      title: "Profile pic",
      dataIndex: "profile_pic",
      className: "profileAP-container",
      render: (data) => {
        return (
          <div
            className="user-img"
            style={{ backgroundImage: `url(${data})` }}
          ></div>
        );
      },
    },
    {
      title: "Emp. Id",
      dataIndex: "employee_id",
      className: "empid",
      render: (id, user) => {
        if (user.is_x !== "0" || user.disable !== "0") {
          return <div className="disabled_user">{id}</div>;
        }
        return id;
      },
    },
    {
      title: "Name",
      dataIndex: "name",
      className: "name",
      render: (name, user) => {
        if (user.is_x !== "0" || user.disable !== "0") {
          return <div className="disabled_user">{name}</div>;
        }
        return name;
      },
    },
    {
      title: "Designation",
      dataIndex: "designation",
      className: "designation",
      render: (designation, user) => {
        if (user.is_x !== "0" || user.disable !== "0") {
          return <div className="disabled_user">{designation}</div>;
        }
        return designation;
      },
    },
  ];
  return loading ? (
    <div className="page-spinner">
      <Spin />
    </div>
  ) : (
    <>
                  <Table
                      className="table-content"
                      columns={columns}
                      dataSource={response.data}
                      rowKey={(data) => data.uuid}
                      onChange={handlePagination}
                      pagination={{
                        total:
                        response &&
                        response.meta &&
                        response.meta.pagination &&
                        response.meta.pagination.total,
                        pageSize:
                        response &&
                        response.meta &&
                        response.meta.pagination &&
                        response.meta.pagination.per_Page,
                        current: pageCurrent,
                        showSizeChanger: false,
                        defaultCurrent: 1,
                      }}
                    />  
    </>
  );
};

export default withStore(NotificationUpdate(SuggestEmpView));
