export default {
    "date_format": "DD/MM/YYYY",
    "post_date_format": "YYYY/MM/DD",
    'employment': {
        'Full-Time': 0,
        'Internship': 1,
        'Contract': 2,
    },
    'employment_sub_type': {
        'normal': 0,
        'notice_period': 1,
        'probation': 2,
    },
    'gender': {
        'male': 0,
        'female': 1
    },
    'document': {
        'file': 1,
        'link': 2
    },
    'marital_status': {
        'single': 0,
        'married': 1
    },
    'alcohol': {
        'yes': 1,
        'no': 0
    },
    'meal': {
        'veg': 0,
        'nonveg': 1
    },
    'blood_group': {
        'A+': 0,
        'A-': 1,
        'B+': 2,
        'B-': 3,
        'O+': 4,
        'O-': 5,
        'AB+': 6,
        'AB-': 7,
        'Unknown': 8,
    },
    'floor_number': {
        'Basement': -1,
        'Ground Floor': 0,
        'First Floor': 1,
        'Second Floor': 2,
        'Third Floor': 3,
        'Fourth Floor': 4,
    }
}