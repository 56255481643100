import * as APIs from '../constants/BackendConstant'
import { axiosInstance } from '../utilities/configureAxios'

export const getEmployeeWorkReportsService = (page) => {
    let url = APIs.GET_EMPLOYEE_WORK_REPORTS
    url = url.replace(':page', page)
    return axiosInstance.get(url)
}

export const addNewWorkReportForEmployee = (body) => {
    return axiosInstance.post(APIs.ADD_NEW_WORK_REPORT_FOR_EMPLOYEE, body);
}
export const getAllEmployeeLeaveList = (body,type) => {

    let url = APIs.GET_ALL_EMPLOYEE_LEAVE_LIST
    url = url.replace(':date', body)
    url = url.replace(':type', type)
    return axiosInstance.get(url)

    // return axiosInstance.get(APIs.GET_ALL_EMPLOYEE_LEAVE_LIST, body);
}


export const getWorkReportsForAdminService = (page, date, user_id) => {
    let url = APIs.GET_WORK_REPORTS_FOR_ADMIN
    url = url.replace(':date', date)
    url = url.replace(':page', page)
    url = url.replace(':user_id', user_id)
    return axiosInstance.get(url)
}

export const getWorkReportsForAdminOfAnEmployeeService = (page, fromDate, toDate, user_id) => {
    let url = APIs.GET_WORK_REPORTS_FOR_ADMIN_OF_AN_EMPLOYEE
    url = url.replace(':from_date', fromDate)
    url = url.replace(':to_date', toDate)
    url = url.replace(':page', page)
    url = url.replace(':user_id', user_id)

    return axiosInstance.get(url)
}



export const getFiterableEmployees = () => {
    let url = APIs.GET_FILTERABLE_EMPLOYEES_FOR_WORK_REPORTS
    return axiosInstance.get(url)
}