import React, { Component, Fragment } from "react";
import { notification, Upload, Icon, Spin, Button, message, Select, Table, Divider, Tooltip } from "antd";
import _ from 'lodash';
import ForAdmin from "../../../hocs/ForAdmin";
import { withStore } from "../../../hocs/withStore";
import { axiosInstance as api } from '../../../utilities/configureAxios';
import "../assets/scss/admin.scss";
import { Helmet } from 'react-helmet'
import SalarySlip from '../../../assets/images/icons/sibebar/salary-slips.svg';

// Dropdown
const { Option } = Select;
const TITLE = 'Salary Slips'

// Pagination
function itemRender(current, type, originalElement) {
    if (type === 'prev') {
        return <a className="back-icon"> <Icon type="left" /> Back</a>;
    }
    if (type === 'next') {
        return <a className="next-icon">Next <Icon type="right" /></a>;
    }
    return originalElement;
}

class SalaryAdminPanel extends Component {

    state = {

        uploadRequestURL: "",
        total: 10,
        per_page: 10,
        current_page: 1,
        employee_total: 10,
        employee_per_page: 10,
        employee_current_page: 1,
        loading: false,
        requestedList: [],
        columns2: [],
        data2: [],
        initialLoading: true,
        getMonthByName: ["Index0", "January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
        //lower table----->
        employeesList: [],
        employeesDetails: [],
        lowerTableLoading: true,
        loadingLower: false,
        loadingButtonIdLower: false,
        lower_month: new Date().getMonth() + 1,
        lower_year: new Date().getFullYear(),
        uploadButtonLoader: true,
        archiveButtonLoader: true,
        denyButtonLoader: true,
    }

    handleRemoveSlipRequest = (keyToRemove, archive) => {
        this.setState({
            loading: true,
            loadingButtonId: keyToRemove
        })

        api.
            post("/salary-slips/deny/" + keyToRemove + "?archive=" + archive)
            .then(data => {
                api.get("/salary-slips?page=1")
                    .then(res => {
                        const total = _.get(res, 'data.meta.pagination.total', 0)
                        const per_page = _.get(res, 'data.meta.pagination.per_page', 1)
                        const current_page = _.get(res, 'data.meta.pagination.current_page', 1)
                        this.setState({
                            requestedList: res.data.data, total, per_page, current_page
                        });
                        let localStoreEmpDetails = [];

                        for (let i = 0; i < res.data.data.length; i++) {
                            let tempDetails = {}
                            tempDetails.key = res.data.data[i].id;
                            tempDetails.name = res.data.data[i].name;
                            tempDetails.month = this.state.getMonthByName[res.data.data[i].month];
                            tempDetails.year = res.data.data[i].year;

                            localStoreEmpDetails.push(tempDetails);
                        }

                        this.setState({
                            data2: localStoreEmpDetails,
                            initialLoading: false,
                            loading: false,
                            loadingButtonId: null

                        })
                        message.success(archive ? 'Request archived' : 'Request Denied');
                    }).catch(error => {
                        message.error("Error while fetching Data");
                        this.setState({
                            initialLoading: false,
                            loadingButtonId: null,
                            loading: false

                        })
                    });

            })
            .catch(err => {
                message.error("Some error occured");
                this.setState({
                    initialLoading: false,
                    loadingButtonId: null,
                    loading: false

                })
            })

    }


    onPageChangeRequested = (pageNumber) => {
        this.setState({ initialLoading: true });
        api.get("/salary-slips?page=" + pageNumber)
            .then(res => {
                const total = _.get(res, 'data.meta.pagination.total', 0)
                const per_page = _.get(res, 'data.meta.pagination.per_page', 1)
                const current_page = _.get(res, 'data.meta.pagination.current_page', 1)
                this.setState({
                    requestedList: res.data.data, total, per_page, current_page
                });
                let localStoreEmpDetails = [];

                for (let i = 0; i < res.data.data.length; i++) {
                    let tempDetails = {}
                    tempDetails.key = res.data.data[i].id;
                    tempDetails.name = res.data.data[i].name;
                    tempDetails.month = this.state.getMonthByName[res.data.data[i].month];
                    tempDetails.year = res.data.data[i].year;

                    localStoreEmpDetails.push(tempDetails);
                }

                this.setState({
                    data2: localStoreEmpDetails,
                    initialLoading: false
                })
            }).catch(error => {
                message.error("Error while fetching Data");
                this.setState({
                    initialLoading: false
                })
            })
    }

    handleDeleteSlip = (slipId, key) => {

        this.setState({
            loadingButtonIdLower: key,
            loadingLower: true
        })
        api
            .delete("/salary-slips/" + slipId)
            .then(data => {

                api.get("/salary?year=" + this.state.lower_year + "&month=" + this.state.lower_month)
                    .then(res => {

                        let localStoreEmpDetails = [];

                        for (let i = 0; i < res.data.data.length; i++) {
                            let tempDetails = {}
                            tempDetails.key = res.data.data[i].uuid;
                            tempDetails.name = res.data.data[i].name;
                            if (res.data.data[i].salary_slip.length > 0) {
                                tempDetails.status = "Uploaded";
                            } else {
                                tempDetails.status = "Not Uploaded";
                            }
                            tempDetails.salary_slip = res.data.data[i].salary_slip;

                            localStoreEmpDetails.push(tempDetails);
                        }

                        this.setState({
                            employeesList: localStoreEmpDetails,
                            lowerTableLoading: false,
                            employeesDetails: res.data.data,
                            loadingButtonIdLower: null,

                        })

                    }).catch(err => {
                        this.setState({
                            lowerTableLoading: false,
                            loadingButtonIdLower: null,
                            loadingLower: false
                        })
                        message.error("Error in fetching salary slip data ")
                    })

            }).catch(err => {
                message.error("Error in deletion");
                this.setState({
                    loadingButtonIdLower: null,
                    loadingLower: false,
                })
            })
    }

    // Table
    columns = [
        {
            title: 'Employee Name',
            dataIndex: 'name',
            className: "employee-name",
        },
        {
            title: 'Status',
            dataIndex: 'status',
            className: "status"
        },
        {
            title: 'Action',
            dataIndex: 'action',
            className: "action",//lower table ==================================
            render: (action, second) => {
                return second.status == "Uploaded" ? (

                    <span>
                        <Tooltip placement="bottom" title={'Upload'}><Button className="btn btn-light-success btn-icon text-uppercase disabled" ><Icon type="file-add" theme="filled" /></Button></Tooltip>
                        <Divider type="vertical" />
                        <Tooltip placement="bottom" title={'View'}><Button href={second.salary_slip[0].url} target="_blank" className="btn btn-light-warning btn-icon text-uppercase" loading={this.state.loadingLower && this.state.loadingButtonIdLower == second.key}><Icon type="eye" theme="filled" /></Button></Tooltip>
                        <Divider type="vertical" />
                        <Tooltip placement="bottom" title={'Delete'}><Button className="btn btn-light-danger btn-icon text-uppercase" onClick={() => { this.handleDeleteSlip(second.salary_slip[0].id, second.key) }} loading={this.state.loadingLower && this.state.loadingButtonIdLower == second.key}><Icon type="delete" theme="filled" /></Button></Tooltip>
                    </span>
                ) : (
                        <span>
                            <Upload {...this.propse}
                                accept='.pdf'
                                showUploadList={false}
                                onChange={
                                    (info) => {
                                        if (info.file.status !== 'uploading') {
                                            {/* message.loading(`${info.file.name} File is uploading...`); */ }
                                        }
                                        if (info.file.status === 'done') {
                                            message.success(`${info.file.name} File uploaded successfully`);
                                        } else if (info.file.status === 'error') {
                                            message.error(`${info.file.name} File upload failed.`);
                                        }
                                    }
                                }
                                beforeUpload={
                                    (file) => {
                                        const formData = new FormData();
                                        formData.append("month", this.state.lower_month);
                                        formData.append("year", this.state.lower_year);
                                        formData.append("file", file);

                                        this.setState({
                                            loadingLower: true,
                                            loadingButtonIdLower: second.key
                                        })

                                        api.post(`/salary-slips/add/${second.key}`, formData).then(data => {

                                            api.get("/salary?year=" + this.state.lower_year + "&month=" + this.state.lower_month)
                                                .then(res => {

                                                    let localStoreEmpDetails = [];

                                                    for (let i = 0; i < res.data.data.length; i++) {
                                                        let tempDetails = {}
                                                        tempDetails.key = res.data.data[i].uuid;
                                                        tempDetails.name = res.data.data[i].name;
                                                        if (res.data.data[i].salary_slip.length > 0) {
                                                            tempDetails.status = "Uploaded";
                                                        } else {
                                                            tempDetails.status = "Not Uploaded";
                                                        }
                                                        tempDetails.salary_slip = res.data.data[i].salary_slip;

                                                        localStoreEmpDetails.push(tempDetails);
                                                    }

                                                    this.setState({
                                                        employeesList: localStoreEmpDetails,
                                                        lowerTableLoading: false,
                                                        employeesDetails: res.data.data
                                                    })

                                                }).catch(err => {
                                                    this.setState({
                                                        lowerTableLoading: false
                                                    })
                                                    message.error("Error in fetching salary slip data ")
                                                })

                                            this.setState({

                                                loadingLower: false,
                                                loadingButtonIdLower: null
                                            })
                                            notification['success']({
                                                message: _.get("Completed!", " ", "Succesfully posted !")
                                            });

                                        }).catch(err => {
                                            message.error("file upload failed.");
                                            this.setState({
                                                loadingLower: false,
                                                loadingButtonIdLower: null
                                            })
                                        })
                                        return false;//not to hit action 
                                    }
                                }
                            >
                                <Tooltip placement="bottom" title={'Upload'}><Button className="btn btn-light-primary btn-icon text-uppercase " loading={this.state.loadingLower && this.state.loadingButtonIdLower == second.key}><Icon type="file-add" theme="filled" /></Button></Tooltip>
                            </Upload>
                            <Divider type="vertical" />
                            <Tooltip placement="bottom" title={'View'}><Button className="btn btn-light-warning btn-icon text-uppercase disabled"><Icon type="eye" theme="filled" /></Button></Tooltip>
                            <Divider type="vertical" />
                            <Tooltip placement="bottom" title={'Delete'}><Button className="btn btn-light-danger btn-icon text-uppercase disabled"><Icon type="delete" theme="filled" /></Button></Tooltip>
                        </span>

                    )
            }


        },
    ];


    // table 2  ============ send columns2 and data2 to state
    columns2 = [
        {
            title: 'Employee Name',
            dataIndex: 'name',
            className: "employee-name",
        },
        {
            title: 'Month',
            dataIndex: 'month',
            className: "month",
        },
        {
            title: 'Year',
            dataIndex: 'year',
            className: "year",
        },
        {
            title: 'Action',
            dataIndex: 'action',
            className: "action",
            render: (action, second) =>
                <span>
                    <Upload {...this.propse}
                        accept='.pdf'
                        showUploadList={false}
                        onChange={
                            (info) => {
                                if (info.file.status !== 'uploading') {
                                    {/* message.loading(`${info.file.name} File is uploading...`); */ }
                                }
                                if (info.file.status === 'done') {
                                    message.success(`${info.file.name} File uploaded successfully`);
                                } else if (info.file.status === 'error') {
                                    message.error(`${info.file.name} File upload failed.`);
                                }
                            }
                        }
                        beforeUpload={
                            (file) => {
                                const formData = new FormData();
                                formData.append("file", file);

                                this.setState({
                                    loading: true,
                                    loadingButtonId: second.key,
                                    archiveButtonLoader: false,
                                    denyButtonLoader: false,
                                })

                                api.post(`/salary-slips/requested/${second.key}`, formData).then(data => {
                                    if (data) {

                                        let newRequestedList = this.state.requestedList.filter(list => {
                                            return list.id != second.key;
                                        });

                                        let localStoreEmpDetails = [];

                                        for (let i = 0; i < newRequestedList.length; i++) {
                                            let tempDetails = {}
                                            tempDetails.key = newRequestedList[i].id;
                                            tempDetails.name = newRequestedList[i].name;
                                            tempDetails.month = this.state.getMonthByName[newRequestedList[i].month];
                                            tempDetails.year = newRequestedList[i].year;

                                            localStoreEmpDetails.push(tempDetails);
                                        }

                                        this.setState({
                                            requestedList: newRequestedList,
                                            data2: localStoreEmpDetails,
                                            loading: false,
                                            loadingButtonId: null
                                        })
                                        notification['success']({
                                            message: _.get("Completed!", " ", "Succesfully posted !")
                                        });
                                    }
                                }).catch(err => {
                                    message.error("file upload failed.");
                                    this.setState({
                                        loading: false,
                                        loadingButtonId: null
                                    })
                                })
                                return false;//not to hit action 
                            }
                        }
                    >

                        <Tooltip placement="bottom" title={'Upload'}><Button className="btn btn-light-primary btn-icon text-uppercase " loading={this.state.loading && this.state.loadingButtonId == second.key && this.state.uploadButtonLoader} ><Icon type="file-add" theme="filled" /></Button></Tooltip>
                    </Upload>
                    <Divider type="vertical" />
                    <Tooltip placement="bottom" title={'Archive'}><Button className="btn btn-light-warning btn-icon text-uppercase" loading={this.state.loading && this.state.loadingButtonId == second.key && this.state.archiveButtonLoader} onClick={() => {
                        this.setState({
                            uploadButtonLoader: false, denyButtonLoader: false, loading: true,
                            loadingButtonId: second.key,
                        }); this.handleRemoveSlipRequest(second.key, 1) // second parameter 1 as archive is done
                    }}><Icon type="download" /></Button></Tooltip>
                    <Divider type="vertical" />
                    <Tooltip placement="bottom" title={'Delete'}><Button className="btn btn-light-danger btn-icon text-uppercase" type="danger" loading={this.state.loading && this.state.loadingButtonId == second.key && this.state.denyButtonLoader} onClick={() => {
                        this.setState({
                            uploadButtonLoader: false, archiveButtonLoader: false, loading: true,
                            loadingButtonId: second.key
                        }); this.handleRemoveSlipRequest(second.key, 0) // second parameter 0 as archive is not done in deny
                    }}><Icon type="delete" theme="filled" /></Button></Tooltip>

                </span>
        },
    ]

    onChangeMonth = (month) => {
        let year = this.state.lower_year;
        this.setState({
            lower_month: month,
            lower_year: year,
            lowerTableLoading: true
        });
        api.get("/salary?year=" + year + "&month=" + month)
            .then(res => {

                let localStoreEmpDetails = [];

                for (let i = 0; i < res.data.data.length; i++) {
                    let tempDetails = {}
                    tempDetails.key = res.data.data[i].uuid;
                    tempDetails.name = res.data.data[i].name;
                    if (res.data.data[i].salary_slip.length > 0) {
                        tempDetails.status = "Uploaded";
                    } else {
                        tempDetails.status = "Not Uploaded";
                    }
                    tempDetails.salary_slip = res.data.data[i].salary_slip;

                    localStoreEmpDetails.push(tempDetails);
                }

                this.setState({
                    employeesList: localStoreEmpDetails,
                    lowerTableLoading: false,
                    employeesDetails: res.data.data,

                })

            }).catch(err => {
                this.setState({
                    lowerTableLoading: false
                })
                message.error("Error in fetching salary slip data ")
            })

    }

    onChangeYear = (year) => {
        let month = this.state.lower_month;
        this.setState({
            lower_month: month,
            lower_year: year,
            lowerTableLoading: true
        });
        api.get("/salary?year=" + year + "&month=" + month)
            .then(res => {

                let localStoreEmpDetails = [];

                for (let i = 0; i < res.data.data.length; i++) {
                    let tempDetails = {}
                    tempDetails.key = res.data.data[i].uuid;
                    tempDetails.name = res.data.data[i].name;
                    if (res.data.data[i].salary_slip.length > 0) {
                        tempDetails.status = "Uploaded";
                    } else {
                        tempDetails.status = "Not Uploaded";
                    }
                    tempDetails.salary_slip = res.data.data[i].salary_slip;

                    localStoreEmpDetails.push(tempDetails);
                }

                this.setState({
                    employeesList: localStoreEmpDetails,
                    lowerTableLoading: false,
                    employeesDetails: res.data.data,

                })

            }).catch(err => {
                this.setState({
                    lowerTableLoading: false
                })
                message.error("Error in fetching salary slip data ")
            })

    }



    componentDidMount = () => {

        api.get("/salary-slips?page=1")
            .then(res => {
                const total = _.get(res, 'data.meta.pagination.total', 0)
                const per_page = _.get(res, 'data.meta.pagination.per_page', 1)
                const current_page = _.get(res, 'data.meta.pagination.current_page', 1)
                this.setState({
                    requestedList: res.data.data, total, per_page, current_page
                });
                let localStoreEmpDetails = [];

                for (let i = 0; i < res.data.data.length; i++) {
                    let tempDetails = {}
                    tempDetails.key = res.data.data[i].id;
                    tempDetails.name = res.data.data[i].name;
                    tempDetails.month = this.state.getMonthByName[res.data.data[i].month];
                    tempDetails.year = res.data.data[i].year;

                    localStoreEmpDetails.push(tempDetails);
                }

                this.setState({
                    data2: localStoreEmpDetails,
                    initialLoading: false
                })
            }).catch(error => {
                message.error("Error while fetching Data");
                this.setState({
                    initialLoading: false
                })
            });


        //change 12 here //Request for lower table goes here ----------->

        api.get("/salary?year=" + this.state.lower_year + "&month=" + this.state.lower_month)
            .then(res => {

                let localStoreEmpDetails = [];
                const employee_total = _.get(res, 'data.meta.pagination.total', 0)
                const employee_per_page = _.get(res, 'data.meta.pagination.per_page', 1)
                const employee_current_page = _.get(res, 'data.meta.pagination.current_page', 1)

                for (let i = 0; i < res.data.data.length; i++) {
                    let tempDetails = {}
                    tempDetails.key = res.data.data[i].uuid;
                    tempDetails.name = res.data.data[i].name;
                    if (res.data.data[i].salary_slip.length > 0) {
                        tempDetails.status = "Uploaded";
                    } else {
                        tempDetails.status = "Not Uploaded";
                    }
                    tempDetails.salary_slip = res.data.data[i].salary_slip;

                    localStoreEmpDetails.push(tempDetails);
                }

                this.setState({
                    employeesList: localStoreEmpDetails,
                    lowerTableLoading: false,
                    employeesDetails: res.data.data,
                    employee_total, employee_per_page, employee_current_page
                })

            }).catch(err => {
                this.setState({
                    lowerTableLoading: false
                })
                message.error("Error in fetching salary slip data ")
            })
    }

    onEmployeePageChange = (pageNumber) => {
        this.setState({ lowerTableLoading: true });
        api
            .get("/salary?year=" + this.state.lower_year + "&month=" + this.state.lower_month + "&page=" + pageNumber)
            .then(res => {
                const employeesList = _.get(res, 'data.data', [])
                const employee_total = _.get(res, 'data.meta.pagination.total', 0)
                const employee_per_page = _.get(res, 'data.meta.pagination.per_page', 0)
                const employee_current_page = _.get(res, 'data.meta.pagination.current_page', 1)
                let localStoreEmpDetails = [];

                for (let i = 0; i < res.data.data.length; i++) {
                    let tempDetails = {}
                    tempDetails.key = res.data.data[i].uuid;
                    tempDetails.name = res.data.data[i].name;
                    if (res.data.data[i].salary_slip.length > 0) {
                        tempDetails.status = "Uploaded";
                    } else {
                        tempDetails.status = "Not Uploaded";
                    }
                    tempDetails.salary_slip = res.data.data[i].salary_slip;

                    localStoreEmpDetails.push(tempDetails);
                }

                this.setState({
                    employeesList: localStoreEmpDetails,
                    lowerTableLoading: false,
                    employeesDetails: res.data.data,
                    loadingButtonIdLower: null,
                    employee_total,
                    employee_per_page,
                    employee_current_page,
                    lowerTableLoading: false
                })
            })
            .catch(error => {
                notification['error']({
                    message: _.get(error, 'response.data.errors.message', "Oops! Something went wrong!")
                });
                this.setState({ lowerTableLoading: false });
            });
    }


    render() {

        let { requestedList, loading, initialLoading, total, per_page, current_page, lowerTableLoading, employeesList, employee_total, employee_per_page, employee_current_page } = this.state;


        let months_array = {
            1: ["January", "JAN"],
            2: ["February", "FEB"],
            3: ["March", "MAR"],
            4: ["April", "APR"],
            5: ["May", "MAY"],
            6: ["June", "JUN"],
            7: ["July", "JUL"],
            8: ["August", "AUG"],
            9: ["September", "SEP"],
            10: ["October", "OCT"],
            11: ["November", "NOV"],
            12: ["December", "DEC"]
        }
        return (
            <Fragment>
                <Helmet>
                    <title>{TITLE}</title>
                </Helmet>
                <div className="page-wrapper">
                    <div className="salaryAP-container">
                        {initialLoading ? (<div className="page-spinner"><Spin /></div>) : (
                            <Fragment>
                                {/* page Header */}
                                <div className="page-header">
                                    <div className="page-title">
                                        <h2 className="title"><img className="icon icon-view" src={SalarySlip} alt="view icon" /> Salary Slips</h2>
                                        {/* <h2 className="title"><img className="icon icon-view" src={compDocument} alt="view icon" /> Salary Slips</h2> */}
                                    </div>
                                </div>

                                {this.state.data2 && Object.keys(this.state.data2).length > 0 && <div>
                                    <section className="page-info-box animated slideInRight">
                                        <h3 className="info-title">Requested Salary Slips</h3>
                                    </section>

                                    <section className="content-section requested-salary-table animated slideInUp">
                                        <div className="tableWrapper">
                                            <Table className="table-content" columns={this.columns2} dataSource={this.state.data2} pagination={{ "onChange": this.onPageChangeRequested, "current": current_page, "total": total, "pageSize": per_page, itemRender: itemRender }} />
                                        </div>
                                    </section>

                                </div>}
                            </Fragment>
                        )}
                        {/* cadidate list table rendering.... */}
                        {lowerTableLoading ? !initialLoading ? (<center><Spin /></center>) : null : (
                            <div>
                                {/* page info title */}
                                <section className="page-info-box animated slideInRight">
                                    <h3 className="info-title">Salary Slip - {months_array[this.state.lower_month][0]} {this.state.lower_year}</h3>
                                    <div className="right multiple-item">
                                        <div className="month-dropdown dropdown mr--10">
                                            <Select className="month-selectbox selectbox primary-shadow"
                                                style={{ width: 100 }}
                                                placeholder="Month"
                                                optionFilterProp="children"
                                                onChange={this.onChangeMonth}
                                                onFocus={this.onFocus}
                                                defaultValue={months_array[this.state.lower_month][1]}
                                                onBlur={this.onBlur}
                                                onSearch={this.onSearch}
                                                filterOption={(input, option) =>
                                                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                }
                                            >
                                                <Option value="1" disabled={this.state.lower_month == "1"}>Jan</Option>
                                                <Option value="2" disabled={this.state.lower_month == "2"}>Feb</Option>
                                                <Option value="3" disabled={this.state.lower_month == "3"}>Mar</Option>
                                                <Option value="4" disabled={this.state.lower_month == "4"}>Apr</Option>
                                                <Option value="5" disabled={this.state.lower_month == "5"}>May</Option>
                                                <Option value="6" disabled={this.state.lower_month == "6"}>Jun</Option>
                                                <Option value="7" disabled={this.state.lower_month == "7"}>Jul</Option>
                                                <Option value="8" disabled={this.state.lower_month == "8"}>Aug</Option>
                                                <Option value="9" disabled={this.state.lower_month == "9"}>Sep</Option>
                                                <Option value="10" disabled={this.state.lower_month == "10"}>Oct</Option>
                                                <Option value="11" disabled={this.state.lower_month == "11"}>Nov</Option>
                                                <Option value="12" disabled={this.state.lower_month == "12"}>Dec</Option>

                                            </Select>
                                        </div>

                                        <div className="year-dropdown dropdown">
                                            <Select className="year-selectbox selectbox primary-shadow"
                                                style={{ width: 100 }}
                                                placeholder="Year"
                                                optionFilterProp="children"
                                                onChange={this.onChangeYear}
                                                onFocus={this.onFocus}
                                                onBlur={this.onBlur}
                                                defaultValue={this.state.lower_year}
                                                onSearch={this.onSearch}
                                                filterOption={(input, option) =>
                                                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                }
                                            >
                                                <Option value={new Date().getFullYear() - 2} disabled={this.state.lower_year == new Date().getFullYear() - 2}>{new Date().getFullYear() - 2}</Option>
                                                <Option value={new Date().getFullYear() - 1} disabled={this.state.lower_year == new Date().getFullYear() - 1} >{new Date().getFullYear() - 1}</Option>
                                                <Option value={new Date().getFullYear()} disabled={this.state.lower_year == new Date().getFullYear()}>{new Date().getFullYear()}</Option>

                                            </Select>
                                        </div>
                                    </div>
                                </section>
                                {/* Table Content */}

                                <section className="content-section salary-table-info animated slideInUp">
                                    <Table className="table-content" columns={this.columns} dataSource={employeesList} pagination={{ "onChange": this.onEmployeePageChange, "current": employee_current_page, "total": employee_total, "pageSize": employee_per_page, itemRender: itemRender }} />
                                </section>

                            </div>
                        )}
                    </div>
                </div>
            </Fragment>
        )
    }
}

export default ForAdmin(withStore(SalaryAdminPanel))