import * as APIs from "../constants/BackendConstant";
import { axiosInstance } from "../utilities/configureAxios";

export const getTeamDirectoryListService = (query = "") => {
  let url = APIs.GET_TEAM_DIRECTORY_LIST + query;
  return axiosInstance.get(url);
};

export const getCompleteTeamDirectoryListService = (query = "") => {
  let url = APIs.GET_COMPLETE_TEAM_DIRECTORY_LIST + query;
  return axiosInstance.get(url);
};

export const getExistingTeamDirectoryListService = (query = "") => {
  let url = APIs.GET_EXISTING_TEAM_DIRECTORY_LIST + query;
  return axiosInstance.get(url);
};

export const getExistingTeamDirectoryListServiceCurrent = (query = "") => {
  let url = APIs.GET_EXISTING_TEAM_DIRECTORY_LIST_CURRENT + query;
  return axiosInstance.get(url);
};
export const getExistingTeamDirectoryListServiceCurrentDisable = (query = "") => {
  let url = APIs.GET_EXISTING_TEAM_DIRECTORY_LIST_CURRENT_DISABLE + query;
  return axiosInstance.get(url);
};

export const getExistingTeamDirectoryListServiceCurr = (query = "") => {
  let url = APIs.GET_EXISTING_TEAM_DIRECTORY_LIST_WITH_SIX_MONTH_EX_EMPLOYEES + query;
  return axiosInstance.get(url);
};
export const getNotification = (page,searchId,searchTitle) => {
  let url = APIs.GET_NOTIFICATION_LIST.replace(":page",page).replace(":notification_id",searchId).replace(":q",searchTitle);
  return axiosInstance.get(url);
};
export const handleCurrentNotification = (id) => {
  let payload = {
    is_seen : 1
  }
  let url = APIs.HANDLE_CURRENT_NOTIFICATION.replace(":id",id);
  return axiosInstance.post(url,payload);
};
export const getNotificationIdData = (page,searchId,searchTitle) => {
  let url = APIs.GET_NOTIFICATION_ID.replace(":page",page).replace(":notification_id",searchId).replace(":q",searchTitle);
  return axiosInstance.get(url);
};

export const getOrganizationEmployeesAttendance = (searchId,page) => {
  let url = APIs.GET_EXISTING_TEAM_DIRECTORY_LIST_ATTENDANCE.replace(":page",page);
  if(searchId){
    url =url.replace(":user_id",searchId);
  }else{
    url =url.replace(":user_id","");
  }
  return axiosInstance.get(url);
};

export const getEmployeeNotificationIdData = (id) => {
  let url = APIs.GET_EMPLOYEE_NOTIFICATION.replace(":id",id);
  return axiosInstance.get(url);
};


export const getAllLeaveTypesDrop = (query = "") => {
  let url = APIs.GET_ALL_LEAVE_TYPES + query;
  return axiosInstance.get(url);
};

export const getPaginatedTeamDirectoryListService = (page, query,body) => {
  console.log("pagination body",body)
  var empType = ''
  let skills = body ? body.filter_skill : '';
  let level = body ? body.filter_level : '';
  let team = body ? body.filter_team : '';
  let hobby = body ? body.filter_hobby : '';
  let employeeFilter = body ? body.filter_is_x : true;
  if(skills || level || team){
    query = ''
  }
  if(body){
    if(body.employment_type !== '')
   empType = `&employment_type=${body.employment_type}` 
  }

  let url = APIs.GET_PAGINATED_TEAM_DIRECTORY_LIST.replace(":page", page).replace(":skill", skills).replace(":hobby",hobby).replace(":level", level).replace(":team", team).replace(":employeeFilter",employeeFilter);
  url = url.replace(":query", query);
  return axiosInstance.get(url + empType);
};

/** empoloee services start  */

/**empoloee services end */
// **************************************
/**admin services start */

/**admin services end */

// **************************************
/**comon services start */

/**comon services end */
