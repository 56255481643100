import _ from 'lodash';
import React, { Component, Fragment } from "react";
import { ExclamationCircleOutlined } from '@ant-design/icons';
import AddLevel from '../../../../../assets/images/icons/sibebar/add-level.svg'
import { notification, Icon, Input, Button, Spin, Row, Col, Form, Card, Tooltip, Pagination, Modal } from "antd";

// For search
const { Search } = Input;
const { confirm } = Modal;


// Pagination
function itemRender(current, type, originalElement) {
    if (type === 'prev') {
        return <a className="back-icon"> <Icon type="left" /> Back</a>;
    }
    if (type === 'next') {
        return <a className="next-icon">Next <Icon type="right" /></a>;
    }
    return originalElement;
}

class AddLevels extends Component {
    constructor(props) {
        super(props)
        this.state = {
            levels: null,
            loading: false,
            total: 0,
            per_page: 1,
            current_page: 1
        }
    }

    componentDidMount() {
        this.getLevelsData()
    }

    getLevelsData = (pageNumber = 1) => {
        this.setState({ loading: true })

        return this.props.getLevels(pageNumber)
            .then((res) => {
                this.setState({
                    levels: res.data,
                    loading: false,
                    total: res.meta.pagination.total,
                    per_page: res.meta.pagination.per_page,
                    current_page: res.meta.pagination.current_page,
                })
                return res
            })
            .catch((error) => {
                this.setState({ loading: false })
                notification.error({
                    message: error.response.data.errors.message
                })
            })
    }

    handleDeleteLevel = (slug) => {
        const that = this;
        confirm({
            title: 'Do you Want to remove the Level?',
            icon: <ExclamationCircleOutlined />,
            onOk() {
                that.props.deleteLevel(slug)
                    .then((result) => {
                        notification.success({
                            message: 'Level deleted sucessfully'
                        })
                        that.getLevelsData()
                    })
                    .catch((error) => {
                        notification.error({
                            message: _.get(error, 'response.data.errors.message', 'Something went wrong')
                        })
                    })
            },
            onCancel() {
            },
        });
    }

    onPageChange = (pageNumber) => {
        this.setState({ loading: true });
        this.getLevelsData(pageNumber)
            .then(res => {
                this.setState({
                    levels: res.data,
                    total: res.meta.pagination.total,
                    per_page: res.meta.pagination.per_page,
                    current_page: res.meta.pagination.current_page,
                    loading: false
                });
            })
            .catch(error => {
                notification['error']({
                    message: "Oops! Something went wrong!"
                });
                this.setState({ loading: false });
            })
    }


    handleAddNewLevel = (e) => {
        e.preventDefault();
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                this.props.addNewLevel(values['title'])
                    .then(result => {
                        notification.success({
                            message: `Level added successfully`
                        });
                        this.getLevelsData()
                    })
                    .catch(error => {
                        const error_code = _.get(error, 'response.status');
                        if (error_code && error_code == '422') {
                            notification['error']({
                                message: Object.values(_.get(error, 'response.data.errors', "Oops! Something went wrong1!"))
                            });
                        }
                        else {
                            notification['error']({
                                message: _.get(error, 'response.data.errors.message', "Oops! Something went wrong2!")
                            });
                        }
                        this.setState({ loading: false });
                    })
            }
        });
    }

    render() {
        const { getFieldDecorator } = this.props.form;
        let { loading, levels, total, per_page, current_page } = this.state
        return (
            <Fragment>
                {loading ? (<center><Spin /></center>) : (
                    <div className="">
                        <div className="addLevels-container addAction-container">

                            {/* Add new level container */}
                            <section className="add-level-box add-item-box card-shadow">
                                <div className="heading">
                                    <h3 className="title text-primary">Add new level</h3>
                                </div>
                                <form>
                                    <Row className="" gutter={30}>
                                        <Col className="gutter-row" xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
                                            <div className="form-content form-group">
                                                <Form.Item label="Level Name" colon={false} >
                                                    {getFieldDecorator('title', {
                                                        rules: [
                                                            {
                                                                required: true,
                                                                message: "Enter a valid Level name"
                                                            }
                                                        ]
                                                    })(
                                                        <Input className="input" placeholder="Enter new level" onBlur={e => e.target.value = e.target.value.trim()} onPressEnter={this.handleAddNewLevel} />
                                                    )}
                                                </Form.Item>
                                            </div>
                                        </Col>
                                        <Col className="gutter-row" xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
                                            <div className="form-content form-group text-right sm-row-align">
                                                <Form.Item label="Add this level" colon={false} className="add-level-label" >
                                                    <Button className="btn btn-light-primary text-uppercase" onClick={this.handleAddNewLevel} >+ Add this Level</Button>
                                                </Form.Item>
                                            </div>
                                        </Col>
                                    </Row>
                                </form>
                            </section>

                            {/* card section */}
                            <section className="content-section card-section level-cards">
                                <Row className="" gutter={30}>
                                    {levels && levels.map((level, index) => (
                                        <Col className="gutter-row" xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
                                            <Card className="card card-shadow level-card">
                                                <div className="icon-box">
                                                    <img className="icon icon-link" src={AddLevel} alt="level image" />
                                                </div>
                                                <div className="title-box">
                                                    <h4 className="title">{level.title}</h4>
                                                </div>
                                                <div className="action-box">
                                                    {/* <Tooltip placement="bottom" title={'Edit'}><Button className="btn btn-light-info btn-icon"><Icon type="edit" theme="filled" /></Button></Tooltip> */}
                                                    <Tooltip placement="bottom" title={'Delete'}><Button onClick={() => this.handleDeleteLevel(level.slug)} className="btn btn-light-danger btn-icon"><Icon type="delete" theme="filled" /></Button></Tooltip>
                                                </div>
                                            </Card>
                                        </Col>
                                    ))}
                                </Row>

                                {total && per_page && total > per_page &&
                                    < div className="pagination-box text-right">
                                        <Pagination className="pagination" onChange={this.onPageChange} current={current_page} total={total} itemRender={itemRender} pageSize={per_page} />
                                    </div>
                                }
                            </section>

                        </div>
                    </div>
                )
                }
            </Fragment>
        )
    }
}

export default Form.create({ name: 'add_levels' })(AddLevels);