import React, { Component, Fragment } from "react";
import { Input, Col, Card, Modal, Tooltip, Button, Icon, Divider } from "antd";
import ViewProfile from "../../admin/components/ViewProfile";
import male from "../../../assets/images/user/male.svg";
import _ from "lodash";
import { withStore } from "../../../hocs/withStore";
import RequireAuth from "../../../hocs/RequireAuth";
import { Link } from "react-router-dom";

// For search
const { Search } = Input;

class TeamDirectory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal1Visible: false,
      user_roles_name: _.get(props, "store.data.user_roles_name", []),
    };
  }
  setModal1Visible(modal1Visible) {
    this.setState({ modal1Visible });
  }

  render() {
    let { data, history } = this.props;
    const { user_roles_name } = this.state;
    const admin = user_roles_name.indexOf("admin") != -1;
    const project_manager = user_roles_name.indexOf("project_manager") != -1;

    return (
      <Fragment>
        <Col
          className="gutter-row"
          xs={24}
          sm={24}
          md={12}
          lg={6}
          xl={6}
          xxl={6}
        >
          <Card
            className="team-card card card-shadow"
            onClick={() => this.setModal1Visible(true)}
          >
            {/* <Tooltip placement="bottom" title={"View dashboard"}>
              <Button
                type="link"
                className="btn btn-light-success btn-icon"
                onClick={() => {
                  // setQueryParam();
                  setQueryParam(data.uuid);
                }}
              >
                <Icon type="info" />
              </Button>
            </Tooltip> */}

            <div
              className="employee-img"
              style={{ backgroundImage: `url(${data ? data.url : male})` }}
            ></div>
            <div className="employee-name">{data && data.name}</div>

            <div className="employee-designation">
              {data && data.designation}
            </div>
            <div className="employee-rank">{data && data.level}</div>
          </Card>
        </Col>
        <Modal
          className="view-profile-modal profileAP-modal"
          title={[
            <div className="view-profile-modalTitle">
              <div
                className="user-img"
                style={{ backgroundImage: `url(${data ? data.url : male})` }}
              ></div>
              <div className="username">{data && data.name}</div>
              <div className="seperator">|</div>
              <div className="designation">{data && data.designation}</div>

              {(admin || project_manager) && <div className="seperator">|</div>}
              {(admin || project_manager) && (
                <Link
                  to={`/dashboard/admin/project-dashboard?employee=${data.uuid}`}
                >
                  View Dashboard
                  {/* <Tooltip placement="bottom" title={"View dashboard"}>
                    <Button
                      type="link"
                      className="btn btn-light-success btn-icon"
                    >
                      <Icon type="info" />
                    </Button>
                  </Tooltip> */}
                </Link>
              )}
            </div>,
          ]}
          centered
          destroyOnClose={true}
          visible={this.state.modal1Visible}
          onOk={() => this.setModal1Visible(false)}
          onCancel={() => this.setModal1Visible(false)}
          footer={null}
        >
          <div className="view-profile-modal-container">
            <ViewProfile employee_uid={data.uuid} />
          </div>
        </Modal>
      </Fragment>
    );
  }
}
// export default TeamDirectory;
export default withStore(RequireAuth(TeamDirectory));
