import React, { Component, Fragment } from "react";
import { Row, Col, Card } from "antd";
import hobby_image from '../../../assets/images/icons/profile/skills/hobby.svg';
import RequireAuth from '../../../hocs/RequireAuth';
import { withStore } from '../../../hocs/withStore';

class ViewHobbies extends Component {
    constructor(props) {
        super(props);
        this.state = {
            user_hobbies: null
        };
    }

    static getDerivedStateFromProps(props, state) {
        return {
            user_hobbies: props.user_hobbies,
        };
    }
    render() {
        const { user_hobbies } = this.state;
        return (
            <Fragment>
                {/* Technical skills cards */}
                <section className="content-section skills-card-section">
                    <h3 className="title text-primary">Hobbies</h3>
                    <Row className="" gutter={30}>
                        {user_hobbies && user_hobbies.map(user_hobby => (
                            <Col className="gutter-row" xs={24} sm={24} md={24} lg={12} xl={12} xxl={8}>
                                <Card className="card card-shadow skills-card">
                                    <div className="skill-icon-box">
                                        <img className="icon icon-link" src={user_hobby.url ? user_hobby.url : hobby_image} alt={user_hobby.hobby_name} />
                                    </div>
                                    <div className="skill-title-box">
                                        <h4 className="title">{user_hobby.hobby_name}</h4>
                                    </div>
                                </Card>
                            </Col>
                        ))}
                        {!user_hobbies || user_hobbies.length == 0 && (
                            <Col className="gutter-row" xs={24} sm={24} md={24} lg={12} xl={12} xxl={8}>
                                <Card className="card card-shadow skills-card">
                                    <h3>No Hobbies found! 😟</h3>
                                </Card>
                            </Col>
                        )}
                    </Row>
                </section>

            </Fragment>
        )
    }
}
export default RequireAuth(withStore(ViewHobbies))