import React from 'react';
import {
  Row,
  Col,
  Input,
  Form,
  Button,
  Select,
  Checkbox,
  InputNumber,
  notification,
} from 'antd';
import _ from "lodash"
import info from '../../../../../../assets/images/icons/leave/info.svg';
import { UserSwitchOutlined } from '@ant-design/icons';

import {
  getLeaveDetails,
  getOrganizationEmployees,
  updateLeaveType,
} from '../../DataManager';


// Our Main Function Starts from here
const EditLeaveType = (props) => {
  const { id, isDrawerVisible, setClickedOnSave, setIsDrawerVisible } = props;
  // FETCHING LEAVE DETAILS
  const [receivedLeaveDetails, setReceivedLeaveDetails] = React.useState([]);

  //State to set form Data
  const [formData2, setFormData2] = React.useState({});

  // FETCHING ALL EMPLOYEES NAME AND ID
  const [allUser, setAllUser] = React.useState([]);

  // EXTRACTING USER ID OF SELECTED EMPLOYEES
  const [extractedUserId, setExtracteduserId] = React.useState([]);

  const { Option } = Select;
  const { getFieldDecorator, getFieldError, setFields } = props.form;

  // HANDLING LEAVE TYPE
  const handleLeaveType = (e) => {
    const leaveType = e.target.value;
    setFormData2((prevData) => {
      return {
        ...prevData,
        leaveType: leaveType,
      };
    });
  };

  const handleLeaveTypeOption = (value) => {
    // const leaveTypeOption = e.target.value;
    setFormData2((prevData) => {
      return {
        ...prevData,
        leaveTypeOption: value,
      };
    });
  };

  // HANDLING ANNUAL CHANGE
  const handleNumberChange = (value) => {
    setFormData2((prevData) => {
      return {
        ...prevData,
        annualCount: value,
      };
    });
  };

  // HANDLING IS AUTOMATIC CHECKBOX
  const isAutomatic = (e) => {
    const checked = e.target.checked;
    setFormData2((prevData) => {
      return {
        ...prevData,
        isAutomatic: checked,
      };
    });
  };

  // HANDLING isApplyingToAll CHECKBOX
  const isApplyingToAll = (e) => {
    const checked = e.target.checked;
    setFormData2((prevData) => {
      return {
        ...prevData,
        isApplyingToAll: checked,
        userNamList: [],
      };
    });
  };

  // HANDLING USER NAME
  const handleUserChange = (value) => {
    setFormData2((prevData) => {
      return {
        ...prevData,
        userNamList: value,
      };
    });
  };

  // HANDLING Apply Immediately CHECKBOX
  const isApplyingImmediately = (e) => {
    const checked = e.target.checked;
    setFormData2((prevData) => {
      return {
        ...prevData,
        isApplyingImmediately: checked,
      };
    });
  };

  // HANDLING IS ACTIVE
  const isActive = (e) => {
    const checked = e.target.checked;
    setFormData2((prevData) => {
      return {
        ...prevData,
        isActive: checked,
      };
    });
  };
  // HANDLING IS PRORATA
  const isProRata = (e) => {
    const checked = e.target.checked;
    setFormData2((prevData) => {
      return {
        ...prevData,
        isProRata: checked,
      };
    });
  };

  // FETCHING ALL EMPLOYEES NAMES
  const loadOrganizationEmployees = () => {
    return getOrganizationEmployees()
      .then((res) => {
        setAllUser(res.data);
      })
      .catch((error) => {
        notification.error({
          // message: error.response.data.errors.message,
        });
      });
  };

  // FETCHING LEAVE DETAILS
  const loadLeaveDetails = (id) => {
    return getLeaveDetails(id)
      .then((res) => {
        const userList = res.data.users ? res.data.users : [];
        let userID = userList.map((user) => user.uuid);
        setExtracteduserId(userID);
        setReceivedLeaveDetails(res.data);
      })
      .catch((error) => {
        notification.error({
          message: error.response.data.errors.message,
        });
      });
  };

  // For getting user Names List
  React.useEffect(() => {
    loadOrganizationEmployees();
  }, []);

  // RERENDER AFTER FETCHING LEAVE DETAILS
  React.useEffect(() => {
    setFormData2({
      o_id: 1,

      leaveType: receivedLeaveDetails.name ? receivedLeaveDetails.name : '',
      leaveTypeOption: receivedLeaveDetails.type ? receivedLeaveDetails.type : '',

      annualCount: receivedLeaveDetails.annual_count
        ? receivedLeaveDetails.annual_count
        : null,

      isAutomatic: receivedLeaveDetails.is_automated
        ? receivedLeaveDetails.is_automated
        : false,

      isApplyingToAll: receivedLeaveDetails.is_applicable_for_all
        ? receivedLeaveDetails.is_applicable_for_all
        : false,

      userNamList: receivedLeaveDetails.users ? extractedUserId : [],
      isApplyingImmediately: receivedLeaveDetails.is_applicable_from_now
        ? receivedLeaveDetails.is_applicable_from_now
        : false,

      isActive: receivedLeaveDetails.is_active
        ? receivedLeaveDetails.is_active
        : false,

      isProRata: receivedLeaveDetails.is_pro_rata
        ? receivedLeaveDetails.is_pro_rata
        : false,
    });
  }, [receivedLeaveDetails]);

  // Clean UP FUNCTION
  React.useEffect(() => {
    if (isDrawerVisible) {
      loadLeaveDetails(id);
    } else {
      // setReceivedLeaveDetails([]);
      setFormData2({
        o_id: 1,

        leaveType: '',
        leaveTypeOption: '',

        annualCount: null,

        isAutomatic: false,

        isApplyingToAll: false,
        isProRata: false,

        userNamList: [],
        isApplyingImmediately: false,
      });
    }
  }, [isDrawerVisible]);

  const handleSave = (e) => {
    props.form.validateFields((error) => {
      if (!error) {
        updateLeaveType(id, {
          o_id: 1,
          name: formData2.leaveType,
          type: formData2.leaveTypeOption,
          annual_count: formData2.annualCount,
          is_applicable_for_all: Number(formData2.isApplyingToAll),
          is_automated: Number(formData2.isAutomatic),
          // is_applicable_from_now: Number(formData2.isApplyingImmediately),
          user_ids: formData2.userNamList,
          is_active: Number(formData2.isActive),
          is_pro_rata: Number(formData2.isProRata),
        })
          .then((res) => {
            notification.success({
              message: 'Leave edited successfully',
            });

            setIsDrawerVisible(false);
            setClickedOnSave(true);
          })
          .catch((error) => {
            if (error.response.status === 422) {
              let errors = error.response.data.errors;
              let errorObject = {};
              Object.keys(errors).forEach((key) => {
                let err = [];
                errors[key].forEach((value) => {
                  err.push(new Error(value));
                });
                key = _.camelCase(key);
                errorObject[key] = { errors: err };
              });
              setFields(errorObject);
            } else {
              notification.error(error.response.data.errors);
            }
          });
      }
    });
  };


  // Getting all error
  const leaveTypeError = getFieldError('name');
  const annualCountError = getFieldError('annualCount');
  const userSelectTypeError = getFieldError('userIds');

  return (
    <>
      <div className="applyLeave-container">

        <Form>
          {/* Leave Type */}
          <div className="form-content leaveType-cont">
            <Row className="row">
              <Form.Item
                label="Leave Type:"
                validateStatus={leaveTypeError ? 'error' : ''}
              >
                {getFieldDecorator('name', {
                  initialValue: formData2.leaveType ? formData2.leaveType : '',
                  rules: [{ required: true, message: 'Enter Leave name' }],
                })(
                  <Input
                    className="input"
                    name="leaveType"
                    placeholder="Enter Leave Type"
                    onChange={handleLeaveType}
                  />
                )}
              </Form.Item>
            </Row>
          </div>

          {/* Total Count */}
          <div className="form-content leaveType-cont">
            <Row className="row">
              <Form.Item
                label="Annual Count"
                validateStatus={annualCountError ? 'error' : ''}
              >
                {/* <label className="myCustomLabel" htmlFor="annualCount">
                  Annual Count <span className="my_custom_star">*</span>:
                </label> */}
                {getFieldDecorator('annualCount', {
                  initialValue: formData2.annualCount
                    ? formData2.annualCount
                    : null,
                  rules: [
                    {
                      required: true,
                      message: 'Enter annual count less than 365',
                    },
                    // {
                    //   maxLength: 365,
                    //   message: 'Number should be less than 365  ',
                    // },
                  ],
                })(
                  <InputNumber
                    className="input"
                    name="annualCount"
                    min={1}
                    max={365}
                    // defaultValue={formData2.annualCount}
                    onChange={handleNumberChange}
                  />
                )}
              </Form.Item>
            </Row>
          </div>

          {/* Add Every Year */}
          <div className="flex-me">
            <div className="form-content leaveType-cont flex-width">
              <Row className="row">
                <Form.Item>
                  <label className="myCustomLabel" htmlFor="isAutomatic">
                    Add Every Year:
                  </label>
                  <Checkbox
                    disabled
                    onChange={isAutomatic}
                    checked={formData2.isAutomatic}
                  ></Checkbox>
                </Form.Item>
              </Row>
            </div>
            <div className="form-content leaveType-cont flex-width">
              <Row className="row">
                <Form.Item>
                  <label className="myCustomLabel" htmlFor="isApplyingToAll">
                    Apply To All:
                  </label>
                  <Checkbox
                    disabled
                    onChange={isApplyingToAll}
                    checked={formData2.isApplyingToAll}
                  ></Checkbox>
                </Form.Item>
              </Row>
            </div>
          </div>

          {/* Select User */}
          <div className="form-content leaveType-cont">
            <Row className="row">
              <Col
                className="col"
                xs={24}
                sm={24}
                md={24}
                lg={24}
                xl={24}
                xxl={24}
              >
                <div className="form-group">
                  <Form.Item
                    label={'User Name'}
                    colon={false}
                    validateStatus={userSelectTypeError ? 'error' : ''}
                  >
                    {getFieldDecorator('userIds', {
                      initialValue: formData2.userNamList
                        ? formData2.userNamList
                        : [],
                      rules: [
                        {
                          required: !formData2.isApplyingToAll,
                          message: 'select a user',
                        },
                      ],
                    })(
                      <div className="leaveType-block">
                        <Select
                          // disabled={formData2.isApplyingToAll ? true : false}
                          disabled
                          value={formData2.userNamList}
                          name="userName"
                          className="leaveType-selectbox"
                          mode="multiple"
                          showSearch
                          placeholder="Select User Name"
                          onChange={handleUserChange}
                          optionLabelProp="label"
                          optionFilterProp="children"
                          filterOption={(input, option) =>
                            option.props.label
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                        >
                          {allUser.map((user) => {
                            return (
                              <Option
                                key={user.id}
                                value={user.uuid}
                                label={user.name}
                              >
                                <div className="demo-option-label-item">
                                  {user.name}
                                </div>
                              </Option>
                            );
                          })}
                        </Select>
                      </div>
                    )}
                  </Form.Item>
                </div>
              </Col>
            </Row>
          </div>

          {/* APPLY IMMEDIATELY || IS_ACTIVE */}
          <div className="flex-me">
            <div className="form-content leaveType-cont flex-width">
              <Row className="row">
                <Form.Item>
                  <label
                    className="myCustomLabel"
                    htmlFor="isApplyingImmediately"
                  >
                    Apply Immediately
                  </label>
                  <Checkbox
                    disabled
                    onChange={isApplyingImmediately}
                    checked={formData2.isApplyingImmediately}
                  ></Checkbox>
                </Form.Item>
              </Row>
            </div>
            <div className="form-content leaveType-cont flex-width">
              <Row className="row">
                <Form.Item>
                  <label className="myCustomLabel" htmlFor="isApplyingToAll">
                    Active Leave:
                  </label>
                  <Checkbox
                    onChange={isActive}
                    checked={formData2.isActive}
                  ></Checkbox>
                </Form.Item>
              </Row>
            </div>

            <div className="form-content leaveType-cont flex-width">
              <Row className="row">
                <Form.Item>
                  <label className="myCustomLabel" htmlFor="isApplyingToAll">
                    Is Pro Rata:
                  </label>
                  <Checkbox
                    disabled
                    onChange={isProRata}
                    checked={formData2.isProRata}
                  ></Checkbox>
                </Form.Item>
              </Row>
            </div>

          </div>
            <div className="form-content leaveType-cont flex-width">
              <Row className="row">
                <Form.Item>
                  <label className="myCustomLabel" htmlFor="isApplyingToAll">
                    Leave Type:
                  </label>
                  <Select
                    value={formData2.leaveTypeOption}
                    className="role-selectbox selectbox"
                    showSearch
                    style={{ width: '100%' }}
                    placeholder="Select Leave Type"
                    optionFilterProp="children"
                    onChange={handleLeaveTypeOption}
                  >
                    <Option key={0} value={0}>Normal Leave</Option>
                    <Option key={1} value={1}>Work From Home</Option>
                    <Option key={2} value={2}>Project Leave</Option>
                  </Select>
                </Form.Item>
              </Row>
            </div>
        </Form>
      </div>

      <div className="formAction-footer-cont">
        <div className="formAction-footer text-right">
          <Button
            type="primary"
            htmlType="submit"
            className="btn btn-wide btn-success apply-btn text-uppercase"
            onClick={handleSave}
          >
            Save
          </Button>
        </div>
      </div>
    </>
  );
};

export default Form.create()(EditLeaveType);
