import React, { Component, Fragment } from "react";
import { Layout } from "antd";
import Profile from "./components/Profile";
import { Helmet } from "react-helmet"

const TITLE = "Profile"

export default class ProfileManagement extends Component {
  render() {
    return (
      <Fragment>
        <Helmet>
          <title>{TITLE}</title>
        </Helmet>
        <div className="page-wrapper">
          <Profile></Profile>
        </div>
      </Fragment>
    );
  }
}
