import React, { Component, Fragment } from "react";
import { notification, Tabs, Spin } from "antd";

import AdminEditPersonalDetails from './AdminEditPersonalDetails';
import AdminEditTechnicalSkills from './AdminEditTechnicalSkills';
import AdminEditVehicleDetails from './AdminEditVehicleDetails';
import AdminEditEmergencyContacts from './AdminEditEmergencyContacts';
import AdminEditBankDetails from './AdminEditBankDetails';
import AdminEditEmployeeDetails from './AdminEditEmployeeDetails';
import AdminEditContractDetails from "./AdminEditContractDetails";
import ForAdminAndHr from "../../../../hocs/ForAdminAndHr";
import { withStore } from "../../../../hocs/withStore";
import _ from 'lodash';
import { axiosInstance as api } from '../../../../utilities/configureAxios';
import url from '../../../../config/url';

import profile from '../../../../assets/images/icons/sibebar/profile.svg';
import AdminEditHobbies from "./AdminEditHobbies";
// for tabs
const { TabPane } = Tabs;

class Profile extends Component {
    constructor(props) {
        super(props);
        this.state = {
            user_details: null,
            loading: false
        };
    }
    componentDidMount = () => {
        this.setState({ loading: true });
        api
            .get(url["getEmployeeDetails"] + "/" + this.props.employee_uid + "?includes=skills,hobbies")
            .then(res => {
                const user_details = _.get(res, 'data.data', [])
                this.setState({ user_details, loading: false });
            })
            .catch(error => {
                notification['error']({
                    message: _.get(error, 'response.data.errors.message', "Oops! Something went wrong!")
                });
                this.setState({ loading: false });
            });


    }
    render() {
        const { user_details, loading } = this.state;
        return (
            <Fragment>
                <div className="profile-dashboard-container edit-team-directory">
                    {/* page Header */}
                    <div className="page-header">
                        <div className="page-title">
                            <h2 className="title"><img className="icon icon-view" src={profile} alt="view icon" /> My Profile</h2>
                        </div>
                    </div>
                    {loading ? (<center><Spin /></center>) : (
                        <section className="page-info-box">
{user_details && user_details.employee_details && user_details.employee_details.employment_type !== 'Contract' ? (
                            <Tabs defaultActiveKey="1">
                                <TabPane tab="Personal Details" key="1">
                                    <AdminEditPersonalDetails
                                        personal_details={user_details ? user_details.personal_details : null}
                                        employee_id={this.props.employee_uid}
                                        />
                                </TabPane>
                                <TabPane tab="Skills" key="2">
                                    <AdminEditTechnicalSkills
                                        user_skills={user_details ? user_details.skills : null}
                                        employee_id={this.props.employee_uid} />
                                </TabPane>
                                {/* <TabPane tab="Vehicle Details" key="3">
                                <VehicleDetails></VehicleDetails>
                            </TabPane> */}
                                     <TabPane tab="Hobbies" key="3">
                                        <AdminEditHobbies
                                            user_hobbies={user_details ? user_details.hobbies : null} 
                                            employee_id={this.props.employee_uid}/>
                                    </TabPane>
                                <TabPane tab="Emergency Contacts" key="4">
                                    <AdminEditEmergencyContacts
                                        emergency_contact_1={user_details && user_details.emergency_contacts ? user_details.emergency_contacts.emergency_contact_1 : null}
                                        emergency_contact_2={user_details && user_details.emergency_contacts ? user_details.emergency_contacts.emergency_contact_2 : null}
                                        employee_id={this.props.employee_uid}
                                    />
                                </TabPane>
                                <TabPane tab="Financial Details" key="5">
                                    <AdminEditBankDetails
                                        bank_details={user_details ? user_details.bank_details : null}
                                        employee_id={this.props.employee_uid} />
                                </TabPane>
                                <TabPane tab="Employee Details" key="6">
                                    <AdminEditEmployeeDetails
                                        employee_details={user_details ? user_details.employee_details : null}
                                        employee_id={this.props.employee_uid} />
                                </TabPane>
                            </Tabs> ) : (
                                <div>
                                    <AdminEditContractDetails
                                        personal_details={user_details ? user_details.personal_details : null}
                                        employee_details={user_details ? user_details.employee_details : null}
                                        default_skills={user_details ? user_details.skills : null}
                                        default_hobbies={user_details ? user_details.hobbies : null}
                                        employee_id={this.props.employee_uid}
                                        company_name={user_details ? user_details.company_name : null}
                                        closeModal={this.props.closeModal}
                                        />
                                </div>
                            )
    }
                        </section>
                    )}
                </div>
            </Fragment>
        )
    }
}

export default ForAdminAndHr(withStore(Profile))