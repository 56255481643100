import React from "react";
import {
  Row,
  Col,
  Input,
  Form,
  Button,
  Select,
  Checkbox,
  InputNumber,
  notification,
  Tooltip,
} from "antd";

import { createLeave, getOrganizationEmployees } from "../../DataManager";
import _ from "lodash";

const AddLeaveType = (props) => {
  const { closeModal, setDataUpdate } = props;
  //State to set form Data
  const [formData2, setFormData2] = React.useState({
    o_id: 1,
    name: "",
    annualCount: null,
    isAutomated: false,
    isApplicableForAll: true,
    userIds: [],
    isApplicableFromNow: false,
    isProRata: false,
  });

  const [allUser, setAllUser] = React.useState([]);
  const [leaveType, setLeaveType] = React.useState([]);

  const { Option } = Select;
  const { getFieldDecorator, getFieldError, setFields } = props.form;

  // HANDLING LEAVE TYPE
  const handleLeaveType = (e) => {
    const name = e.target.value;
    setFormData2((prevData) => {
      return {
        ...prevData,
        name: name,
      };
    });
  };

  // add leave type 
  const handleLeave=(value)=>{
    setLeaveType(value);
  }

  // HANDLING ANNUAL CHANGE
  const handleNumberChange = (value) => {
    setFormData2((prevData) => {
      return {
        ...prevData,
        annualCount: value,
      };
    });
  };

  // HANDLING isAutomated CHECKBOX
  const isAutomated = (e) => {
    const checked = e.target.checked;
    setFormData2((prevData) => {
      return {
        ...prevData,
        isAutomated: checked,
      };
    });
  };

  // HANDLING isApplicableForAll CHECKBOX
  const isApplicableForAll = (e) => {
    const checked = e.target.checked;

    setFormData2((prevData) => {
      return {
        ...prevData,
        isApplicableForAll: checked,
        userIds: [],
      };
    });
  };

  // HANDLING USER NAME
  const handleUserChange = (value) => {
    setFormData2((prevData) => {
      return {
        ...prevData,
        userIds: value,
        isApplicableForAll: false,
      };
    });
  };

  // HANDLING Apply Immediately CHECKBOX
  const isApplicableFromNow = (e) => {
    const checked = e.target.checked;
    setFormData2((prevData) => {
      return {
        ...prevData,
        isApplicableFromNow: checked,
      };
    });
  };
  // HANDLING PRORATA CHECKBOX
  const isProRata = (e) => {
    const checked = e.target.checked;
    setFormData2((prevData) => {
      return {
        ...prevData,
        isProRata: checked,
      };
    });
  };

  // FETCHING ALL EMPLOYEES NAMES
  const loadOrganizationEmployees = () => {
    return getOrganizationEmployees()
      .then((res) => {
        setAllUser(res.data);
      })
      .catch((error) => {
        notification.error(error.response.data.errors);
      });
  };

  // For getting user Names List
  React.useEffect(() => {
    loadOrganizationEmployees();
  }, []);

  const handleApply = (e) => {
    e.preventDefault();
    props.form.validateFields((error) => {
      if (!error) {
        createLeave({
          o_id: 1,
          name: formData2.name,
          is_automated: Number(formData2.isAutomated),
          is_applicable_from_now: Number(formData2.isApplicableFromNow),
          is_applicable_for_all: Number(formData2.isApplicableForAll),
          annual_count: formData2.annualCount,
          user_ids: formData2.userIds,
          is_pro_rata: Number(formData2.isProRata),
          type: leaveType
        })
          .then((res) => {
            notification.success({
              message: "Leave type created successfully",
            });
            closeModal(false);
            setDataUpdate(true)
          })
          .catch((error) => {
            if (error.response.status === 422) {
              let errors = error.response.data.errors;
              let errorObject = {};
              Object.keys(errors).forEach((key) => {
                let err = [];
                errors[key].forEach((value) => {
                  err.push(new Error(value));
                });
                key = _.camelCase(key);
                errorObject[key] = { errors: err };
              });
              setFields(errorObject);
            } else {
              notification.error(error.response.data.errors);
            }
          });
      }
    });
  };

  // Getting all error
  const leaveTypeError = getFieldError("name");
  const annualCountError = getFieldError("annualCount");
  const userSelectTypeError = getFieldError("userName");
  console.log("leaveType=",leaveType);
  return (
    <>
      <div className="applyLeave-container">
        <Form>
          {/* Leave Type */}
          <div className="form-content leaveType-cont">
            <Row className="row">
              <Form.Item
                label="Leave Type:"
                validateStatus={leaveTypeError ? "error" : ""}
              >
                {getFieldDecorator("name", {
                  initialValue: formData2.name ? formData2.name : "",
                  rules: [
                    { required: true, message: "Enter a valid leave name" },
                  ],
                })(
                  <Input
                    type="text"
                    className="input"
                    name="name"
                    placeholder="Enter Leave Type"
                    onChange={handleLeaveType}
                  />
                )}
              </Form.Item>
            </Row>
          </div>

          {/* Total Count */}
          <div className="form-content leaveType-cont">
            <Row className="row">
              <Form.Item
                label="Annual Count"
                validateStatus={annualCountError ? "error" : ""}
              >
                {getFieldDecorator("annualCount", {
                  initialValue: formData2.annualCount
                    ? formData2.annualCount
                    : null,
                  rules: [
                    {
                      required: true,
                      message: "Enter valid count",
                    },
                  ],
                })(
                  <InputNumber
                    className="input"
                    name="annualCount"
                    min={1}
                    max={365}
                    onChange={handleNumberChange}
                  />
                )}
              </Form.Item>
            </Row>
          </div>

          {/* Add Every Year */}
          <div className="flex-me">
            <div className="form-content leaveType-cont flex-width">
              <Row className="row">
                <Form.Item>
                  <label className="myCustomLabel" htmlFor="isAutomated">
                    Add Every Year:
                  </label>
                  <Tooltip title={"Add Every Year"}>
                    <Checkbox onChange={isAutomated}></Checkbox>
                  </Tooltip>
                </Form.Item>
              </Row>
            </div>
            <div className="form-content leaveType-cont flex-width">
              <Row className="row">
                <Form.Item>

                  <label className="myCustomLabel" htmlFor="isApplicableForAll">
                    Apply To All:
                  </label>

                  <Checkbox
                    title="Apply to all"
                    onChange={isApplicableForAll}
                    checked={formData2.isApplicableForAll}
                  ></Checkbox>
                </Form.Item>
              </Row>
            </div>
          </div>

          {/* Select User */}
          <div className="form-content leaveType-cont">
            <Row className="row">
              <Col
                className="col"
                xs={24}
                sm={24}
                md={24}
                lg={24}
                xl={24}
                xxl={24}
              >
                <div className="form-group">
                  {formData2.isApplicableForAll ? null : (
                    <Form.Item
                      name="userName"
                      label={"User Name"}
                      colon={false}
                      validateStatus={userSelectTypeError ? "error" : ""}
                    >
                      {getFieldDecorator("userName", {
                        initialValue: formData2.userIds
                          ? formData2.userIds
                          : [],
                        rules: [
                          {
                            required: !formData2.isApplicableForAll,
                            message: "select a user",
                          },
                        ],
                      })(
                        <Select
                          disabled={formData2.isApplicableForAll}
                          value={formData2.userIds}
                          name="userName"
                          className="leaveType-selectbox"
                          mode="multiple"
                          showSearch
                          placeholder="Select User Name"
                          onChange={handleUserChange}
                          optionLabelProp="label"
                          optionFilterProp="children"
                          filterOption={(input, option) =>
                            option.props.label
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                        >
                          {allUser.map((user) => {
                            return (
                              <Option
                                key={user.id}
                                value={user.uuid}
                                label={user.name}
                              >
                                <div className="demo-option-label-item">
                                  {user.name}
                                </div>
                              </Option>
                            );
                          })}
                        </Select>
                      )}
                    </Form.Item>
                  )}
                </div>
              </Col>
            </Row>
          </div>
          {/* Apply Immediately */}
          <div className="form-content leaveType-cont">
            <Row className="row">
              <Form.Item>
                <label className="myCustomLabel" htmlFor="isApplicableFromNow">
                  Apply Immediately:
                </label>

                <Checkbox onChange={isApplicableFromNow}></Checkbox>
              </Form.Item>
            </Row>
          </div>
          {/* Prorata */}
          <div className="form-content leaveType-cont">
            <Row className="row">
              <Form.Item>
                <label className="myCustomLabel" htmlFor="isApplicableFromNow">
                  Is pro rata:
                </label>
                <Checkbox onChange={isProRata}></Checkbox>
              </Form.Item>
            </Row>
          </div>
          {/* leave type */}
          <label className="myCustomLabel" htmlFor="isApplicableFromNow">
            Leave Type:
          </label>
          <div className="form-content leaveType-cont">
            <Select
              className="role-selectbox selectbox"
              showSearch
              style={{ width: '100%' }}
              placeholder="Select Leave Type"
              optionFilterProp="children"
              onChange={handleLeave}
            >
              <Option key={0} value={0}>Normal Leave</Option>
              <Option key={1} value={1}>Work From Home</Option>
              <Option key={2} value={2}>Project Leave</Option>
            </Select>
          </div>
        </Form>
      </div>

      <div className="formAction-footer-cont">
        <div className="formAction-footer text-right">
          <Button
            type="primary"
            htmlType="submit"
            className="btn btn-wide btn-success apply-btn text-uppercase"
            onClick={handleApply}
          >
            Apply
          </Button>
        </div>
      </div>
    </>
  );
};

export default Form.create()(AddLeaveType);
