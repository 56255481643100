import React from 'react';
import ReactDOM from 'react-dom';
import "./app.scss";
import App from './App';
import * as serviceWorker from './serviceWorker';
import initializeFirebase from './pushNotification';
//import * as Sentry from '@sentry/react'
//import { BrowserTracing } from '@sentry/tracing'

// Sentry.init({
//   dsn: process.env.REACT_APP_SENTRY_DSN,
//   integrations: [new BrowserTracing()],

//   // We recommend adjusting this value in production, or using tracesSampler
//   // for finer control
//   tracesSampleRate: 1.0,
// })

if ("serviceWorker" in navigator) {
    navigator.serviceWorker
      .register("./firebase-messaging-sw.js")
      .then(function(registration) {
        console.log("Registration successful, scope is:", registration.scope);
      })
      .catch(function(err) {
        console.log("Service worker registration failed, error:", err);
      });
  }

ReactDOM.render(<App />, document.getElementById('root'));

serviceWorker.register();

initializeFirebase();

// serviceWorker.unregister();