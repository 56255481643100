import * as SERVICEs from '../../../../services/AssetService';
import { getCompleteTeamDirectoryListService } from "../../../../services/TeamDirectoryService";

export const getAssetTypes = (pageNumber) => {
    return SERVICEs.getAssetTypesService()
        .then(result => {
            return result.data

        })
        .catch(error => {
            throw error
        })
}

export const getAssets = (status, type_id, self, q, page) => {
    let is_issuable = false
    return SERVICEs.getAssetsService(status, type_id, self, q, page, is_issuable)
        .then(result => {
            return result.data
        })
        .catch(error => {
            throw error
        })
}

export const addAssets = (body) => {
    return SERVICEs.addAssetsService(body)
        .then(result => {
            return result.data
        })
        .catch(error => {
            throw error
        })
}

export const editAssets = (id, data) => {
    return SERVICEs.editAssetsService(id, data)
        .then(result => {
            return result.data
        })
        .catch(error => {
            throw error
        })
}

export const deleteAssets = (id) => {
    return SERVICEs.deleteAssetsService(id)
        .then(result => {
            return result
        })
        .catch(error => {
            throw error
        })
}


export const getEmployeeAllocatedAssets = (type_id, id, q, page) => {
    return SERVICEs.getEmployeeAllocatedAssetService(type_id, id, q, page)
        .then(result => {
            return result.data
        })
        .catch(error => {
            throw error
        })
}

export const getOrganizationEmployees = () => {
    return getCompleteTeamDirectoryListService()
        .then(result => {
            return result.data
        })
        .catch(error => {
            throw error
        })
}

export const allocatedDeallocateAsset = (id, qr_scan = false, deallocation_notes) => {
    let body = {
        user_id: null,
        deallocation_notes: deallocation_notes
    }
    return SERVICEs.allocateDeallocateAssetService(id, body, qr_scan)
        .then(result => {
            return result.data
        })
        .catch(error => {
            throw error
        })
}