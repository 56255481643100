import * as APIs from '../constants/BackendConstant';
import { axiosInstance } from '../utilities/configureAxios';

export const getBills = (parameters) => {
  let url = APIs.BILL;
  return axiosInstance.get(url, {
    params: parameters
  });
};

export const createBill = (id,data) => {
  console.log('data', data);
  let url = APIs.BILL;
  return axiosInstance.post(url, data, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
};

export const deleteBill = (id) => {
  let url = `${APIs.BILL}/${id}`;
  return axiosInstance.delete(url);
};


export const updateBillStatus = (id, payload) => {
  let url = `${APIs.BILL}/${id}`;
  return axiosInstance.patch(url, payload);
};

export const billApprove = (id, payload) => {
  let url = `${APIs.BILL}/${id}?expected_payment_date=${payload.expected_payment_date}&status=${payload.status}`;
  return axiosInstance.patch(url);
};

export const updateBill = (id, body) => {
  let url = `${APIs.BILL}`+'/update/'+`${id}`;
  return axiosInstance.post(url, body,{
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
};

export const updateMultipleStatus = (uuids, status) => {
  let url = `${APIs.UPDATE_BILL}`;
  return axiosInstance.post(url, {
    bills: uuids,
    status
  });
};
