import React, { Component, Fragment } from "react";
import { Form, Input, Button, Spin, Row, Col, Select, notification } from "antd";
import _ from 'lodash';
import { axiosInstance as api } from '../../../../utilities/configureAxios';
import url from '../../../../config/url';
import { withStore } from '../../../../hocs/withStore';
import RequireAuth from '../../../../hocs/RequireAuth';
import ForAdminAndHr from "../../../../hocs/ForAdminAndHr";

// Dropdown
const { Option } = Select;
class AdminEditEmergencyContacts extends Component {
    constructor(props) {
        super(props);
        this.state = {
            emergency_contact_1: null,
            emergency_contact_2: null,
            employee_id: null,
        };
    }

    static getDerivedStateFromProps(props, state) {
        return {
            emergency_contact_1: props.emergency_contact_1,
            emergency_contact_2: props.emergency_contact_2,
            employee_id: props.employee_id,
        };
    }

    handleSubmit = e => {
        e.preventDefault();
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                this.setState({ loading: true });
                values["profile_tab"] = "emergency";

                api.
                    post(url["createUser"] + "/" + this.state.employee_id, values)
                    .then(data => {
                        notification['success']({
                            message: "Emergency contacts updated successfully!"
                        });
                        this.setState({ loading: false });
                    }).catch(error => {
                        const error_code = _.get(error, 'response.status');
                        if (error_code && error_code == '422') {
                            notification['error']({
                                message: Object.values(_.get(error, 'response.data.errors', "Oops! Something went wrong!"))
                            });
                        }
                        else {
                            notification['error']({
                                message: _.get(error, 'response.data.errors.message', "Oops! Something went wrong!")
                            });
                        }
                        this.setState({ loading: false });
                    });

            }
        });
    };

    render() {
        const { emergency_contact_1, emergency_contact_2, loading } = this.state;
        const { getFieldDecorator } = this.props.form;
        return (
            <Fragment>
                <section className="tab-content-container emergency-contact-tab card-shadow">
                    <form>
                        <div className="emergency-detail-box">
                            <h3 className="title text-primary">Emergency Contact #1</h3>
                            <Row className="" gutter={30}>
                                <Col className="gutter-row" xs={24} sm={24} md={24} lg={8} xl={8}>
                                    <div className="form-content form-group">
                                        <Form.Item label="Name">
                                            {getFieldDecorator('emergency_contact_1_name', {
                                                initialValue: emergency_contact_1 ? emergency_contact_1.name : "",
                                                rules: [{ required: true, message: 'Please enter name!' }],
                                                validateTrigger: 'onBlur'
                                            })(
                                                <Input className="input" maxLength="255" placeholder="Enter name" onBlur={e => e.target.value = e.target.value.trim()} />
                                            )}
                                        </Form.Item>
                                    </div>
                                </Col>
                                <Col className="gutter-row" xs={24} sm={24} md={24} lg={8} xl={8}>
                                    <div className="form-content form-group">
                                        <Form.Item label="Mobile Number">
                                            {getFieldDecorator('emergency_contact_1_number', {
                                                initialValue: emergency_contact_1 ? emergency_contact_1.number : "",
                                                rules: [{ required: true, message: 'Please enter phone number!' }],
                                                validateTrigger: 'onBlur'
                                            })(
                                                <Input className="input" maxLength="10" minLength="10" placeholder="Enter Mobile No." onBlur={e => e.target.value = e.target.value.replace(/\D/g, '')} />
                                            )}
                                        </Form.Item>
                                    </div>
                                </Col>
                                <Col className="gutter-row" xs={24} sm={24} md={24} lg={8} xl={8}>
                                    <div className="form-content form-group">
                                        <Form.Item label="Relation">
                                            {getFieldDecorator('emergency_contact_1_relation', {
                                                initialValue: emergency_contact_1 ? emergency_contact_1.relation : "",
                                                rules: [{ required: true, message: 'Please enter relation!' }],
                                                validateTrigger: 'onBlur'
                                            })(
                                                <Input className="input" maxLength="255" placeholder="Enter relation" onBlur={e => e.target.value = e.target.value.trim()} />
                                            )}
                                        </Form.Item>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                        <div className="emergency-detail-box">
                            <h3 className="title text-primary">Emergency Contact #2</h3>
                            <Row className="" gutter={30}>
                                <Col className="gutter-row" xs={24} sm={24} md={24} lg={8} xl={8}>
                                    <div className="form-content form-group">
                                        <Form.Item label="Name">
                                            {getFieldDecorator('emergency_contact_2_name', {
                                                initialValue: emergency_contact_2 ? emergency_contact_2.name : "",
                                                rules: [{ required: true, message: 'Please enter name!' }],
                                                validateTrigger: 'onBlur'
                                            })(
                                                <Input className="input" maxLength="255" placeholder="Enter name" onBlur={e => e.target.value = e.target.value.trim()} />
                                            )}
                                        </Form.Item>
                                    </div>
                                </Col>
                                <Col className="gutter-row" xs={24} sm={24} md={24} lg={8} xl={8}>
                                    <div className="form-content form-group">
                                        <Form.Item label="Mobile Number">
                                            {getFieldDecorator('emergency_contact_2_number', {
                                                initialValue: emergency_contact_2 ? emergency_contact_2.number : "",
                                                rules: [{ required: true, message: 'Please enter phone number!' }],
                                                validateTrigger: 'onBlur'
                                            })(
                                                <Input className="input" maxLength="10" minLength="10" placeholder="Enter Mobile No." onBlur={e => e.target.value = e.target.value.replace(/\D/g, '')} />
                                            )}
                                        </Form.Item>
                                    </div>
                                </Col>
                                <Col className="gutter-row" xs={24} sm={24} md={24} lg={8} xl={8}>
                                    <div className="form-content form-group">
                                        <Form.Item label="Relation">
                                            {getFieldDecorator('emergency_contact_2_relation', {
                                                initialValue: emergency_contact_2 ? emergency_contact_2.relation : "",
                                                rules: [{ required: true, message: 'Please enter relation!' }],
                                                validateTrigger: 'onBlur'
                                            })(
                                                <Input className="input" maxLength="255" placeholder="Enter relation" onBlur={e => e.target.value = e.target.value.trim()} />
                                            )}
                                        </Form.Item>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </form>
                </section>

                <section className="profile-save-btn-box mt--30">
                    <div className="save-btn-box text-right">
                        <Button className="btn btn-success btn-wide text-uppercase" onClick={this.handleSubmit} loading={loading} disabled={loading}>Save</Button>
                    </div>
                </section>
            </Fragment>
        )
    }
}

export default Form.create({ name: 'emergency_contacts_form' })(ForAdminAndHr(withStore(AdminEditEmergencyContacts)));