import React, { useEffect, useState, Fragment } from "react";
import { withStore } from "../../../hocs/withStore";
import NotificationUpdate from "../../../hocs/NotificationUpdate";
import {
  getProjectListData,
  getProjectDetails,
  deleteProjectDetails,
  getProjectDetailsEdit,
} from "../../../services/ProjectService";
import {
  Table,
  Tooltip,
  Button,
  Icon,
  Divider,
  Spin,
  Modal,
  Input,
  Select,
  notification,
  Typography,
} from "antd";
import ProjectDetails from "./ProjectDetails";
import ViewProject from "./ViewProject";
import _ from "lodash";
import { useHistory } from "react-router";

const AllProjects = (props) => {
  const state = {
    wasActive: 0,
    hovering: null,
    user_roles_name: _.get(props, "store.data.user_roles_name", []),
    profile_pic: _.get(props, "store.data.profile_pic", null),
    imageLoading: false,
  };
  const history = useHistory();
  const { user_roles_name } = state;
  const admin = user_roles_name.indexOf("admin") != -1;
  const project_manager = user_roles_name.indexOf("project_manager") != -1;
  const autoUpdate = props.autoUpdate;
  const [searchKey, setSearchKey] = useState("");
  const [statusKey, setStatusKey] = useState("1");
  const [allProjectList, setAllProjectList] = useState("");
  const [editProjectList, setEditProjectList] = useState("");
  const [pageCurrent, setpageCurrent] = useState(1);
  const [loading, setLoading] = useState(false);
  const [editModalLoading, setEditModalLoading] = useState(false);
  const [updateModalVisible, setUpdateModalVisible] = useState(false);
  const [viewModalVisible, setViewModalVisible] = useState(false);
  const [value, setValue] = useState("");

  const { Option } = Select;
  const getProjectList = (page, searchKey, statusKey) => {
    setLoading(true);
    return getProjectListData(page, searchKey, statusKey).then((res) => {
      setAllProjectList(res);
      setLoading(false);
    });
  };
  const setQueryParam = (id) => {
    history.push({
      pathname: "/dashboard/admin/project-dashboard",
      search: `?project=${id}`,
    });
  };
  useEffect(() => {
    setLoading(true);
    const timer = setTimeout(() => {
      setpageCurrent(1);
      getProjectList(1, value, statusKey);
    }, 500);
    return () => clearTimeout(timer);
  }, [value, autoUpdate, statusKey]);

  const getProjectDetailsData = (id) => {
    return getProjectDetails(id).then((res) => {
      setEditProjectList(res);
    });
  };

  const getProjectDetailsDataEdit = (id) => {
    return getProjectDetailsEdit(id).then((res) => {
      setEditProjectList(res);
    });
  };
  useEffect(() => {
    setEditModalLoading(false);
  }, [editProjectList]);
  const { confirm } = Modal;
  const deleteProjectDetailsData = (id) => {
    confirm({
      title: "Do you want to delete this project?",
      async onOk() {
        try {
          return deleteProjectDetails(id)
            .then((res) => {
              notification["success"]({
                message: "deleted successfully",
              });
              getProjectList(1, searchKey, statusKey);
            })
            .catch((error) => {
              notification.error({
                message: error.response.data.errors.message,
              });
            });
        } catch (e) {
          return console.log("Oops errors!");
        }
      },
      onCancel() {},
    });
  };

  const COLUMN_STYLE = { width: 200 };
  const columns = [
    {
      title: "S.No.",
      key: "index",
      className: "text-center",
      render: (text, object, index) => (
        <div>{(pageCurrent - 1) * 10 + (index + 1)}</div>
      ),
    },
    {
      title: "Project Name",
      dataIndex: "title",
      key: "id",
      render: (text) => (
        <Typography.Text ellipsis={true} style={COLUMN_STYLE}>
          {text}
        </Typography.Text>
      ),
    },
    {
      title: "No. Of Employees Aligned",
      align: "center",
      dataIndex: "alligned",
      render: (alligned) => <div> {alligned}</div>,
    },
    {
      title: "No. Of Employees Short",
      align: "center",
      dataIndex: "is_short",
      render: (alligned) => <div>{alligned}</div>,
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "id",
      render: (status) => {
        switch (status) {
          case 0:
            return "Pending";
            break;
          case 1:
            return "Active";
            break;
          case 2:
            return "On Hold";
            break;
          case 3:
            return "Compeleted";
            break;
          case 4:
            return "Cancelled";
            break;
          default:
            return "Active";
        }
      },
    },
    {
      title: "Action",
      dataIndex: "",
      className: "action",
      align: "center",
      render: (text) => (
        <span>
          <Fragment>
            {(admin || project_manager) && (
              <Tooltip placement="bottom" title={"Edit Project"}>
                <Button
                  type="link"
                  onClick={() => {
                    setEditModalLoading(true);
                    getProjectDetailsDataEdit(text.id);
                    setUpdateModalVisible(true);
                  }}
                  className="btn btn-light-info btn-icon text-uppercase"
                >
                  <Icon type="edit" theme="filled" />
                </Button>
              </Tooltip>
            )}
            {(admin || project_manager) && <Divider type="vertical" />}
          </Fragment>
          <Tooltip placement="bottom" title={"View Project"}>
            <Button
              type="link"
              onClick={() => {
                setEditModalLoading(true);
                getProjectDetailsDataEdit(text.id);
                setViewModalVisible(true);
              }}
              className="btn btn-light-primary btn-icon text-uppercase"
            >
              <Icon type="eye" theme="filled" />
            </Button>
          </Tooltip>
          {(admin || project_manager) && <Divider type="vertical" />}
          {(admin || project_manager) && (
            <Tooltip placement="bottom" title={"Delete Project"}>
              <Button
                onClick={() => {
                  deleteProjectDetailsData(text.id);
                }}
                className="btn btn-light-danger btn-icon"
              >
                <Icon type="delete" theme="filled" />
              </Button>
            </Tooltip>
          )}
          {(admin || project_manager) && <Divider type="vertical" />}
          {(admin || project_manager) && (
            <Tooltip placement="bottom" title={"View dashboard"}>
              <Button
                type="link"
                className="btn btn-light-success btn-icon"
                onClick={() => {
                  setQueryParam(text.id);
                }}
              >
                <Icon type="info" />
              </Button>
            </Tooltip>
          )}
        </span>
      ),
    },
  ];
  const handlePagination = (pagination) => {
    setpageCurrent(pagination.current);
    getProjectList(pagination.current, searchKey, statusKey);
  };

  return (
    <>
      <div className="projectListFilter">
        <Select
          allowClear={true}
          className="status-selectbox selectbox"
          style={{ width: "200px", marginBottom: "2rem" }}
          placeholder="Status"
          onChange={(val) => setStatusKey(val)}
          // value={payload.statuses}
          defaultValue={"1"}
        >
          <Option value="1">Active</Option>
          <Option value="0">Pending</Option>
          <Option value="2">On Hold</Option>
          <Option value="3">Completed</Option>
          <Option value="4">Cancelled</Option>
        </Select>
        <div className="search-box">
          <Input
            style={{ marginBottom: "2rem" }}
            allowClear={true}
            placeholder="Search By Project Name"
            // value={this.state.name}
            onChange={(e) => {
              setSearchKey(e.target.value);
              setValue(e.target.value);
              //  return debounce(getProjectList(1, e.target.value, statusKey), 2000)
            }}
            suffix={<Icon type="search" />}
          />
        </div>
      </div>
      {loading ? (
        <div className="page-spinner">
          <Spin />
        </div>
      ) : (
        <>
          <Table
            dataSource={
              allProjectList && allProjectList.data && allProjectList.data.data
            }
            columns={columns}
            onChange={handlePagination}
            pagination={{
              total:
                allProjectList &&
                allProjectList.data &&
                allProjectList.data.meta &&
                allProjectList.data.meta.pagination &&
                allProjectList.data.meta.pagination.total,
              pageSize:
                allProjectList &&
                allProjectList.data &&
                allProjectList.data.meta &&
                allProjectList.data.meta.pagination &&
                allProjectList.data.meta.pagination.per_Page,
              current: pageCurrent,
              showSizeChanger: false,
              defaultCurrent: 1,
            }}
          />

          {/* Update Modal */}
          <Modal
            className="profileAP-modal"
            destroyOnClose={true}
            maskClosable={false}
            centered
            visible={updateModalVisible}
            onCancel={() => setUpdateModalVisible(false)}
            footer={null}
          >
            <div className="view-profile-modal-container">
              <ProjectDetails
                loading={editModalLoading}
                autoUpdate={() =>
                  getProjectList(pageCurrent, searchKey, statusKey)
                }
                closeModal={() => setUpdateModalVisible(false)}
                data={
                  editProjectList &&
                  editProjectList.data &&
                  editProjectList.data.data
                }
              />
            </div>
          </Modal>
          {/* View Modal */}
          <Modal
            className="view-profile-modal profileAP-modal"
            destroyOnClose={true}
            maskClosable={false}
            centered
            visible={viewModalVisible}
            onCancel={() => setViewModalVisible(false)}
            footer={null}
          >
            <div className="view-profile-modal-container">
              <ViewProject
                loading={editModalLoading}
                closeModal={() => setViewModalVisible(false)}
                data={
                  editProjectList &&
                  editProjectList.data &&
                  editProjectList.data.data
                }
              />
            </div>
          </Modal>
        </>
      )}
    </>
  );
};

export default withStore(NotificationUpdate(AllProjects));
