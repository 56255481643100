import React from "react";
import {
  Icon,
  Button,
  Input,
  Drawer,
  Select,
  Tooltip,
  Spin,
  notification,
  Table,
} from "antd";
import { RetweetOutlined } from '@ant-design/icons';

import male from "../../../../../../../assets/images/user/male.svg";
import ManageLeavesDrawer from "../../ManageLeavesDrawer/ManageLeavesDrawer";
import {
  getALLEmpLEave,
  getOtherEmployeeLeave,
  getOrganizationEmployeesWithEx,
} from "../../../DataManager";

const ManageLeaves = ({ setButtonVisible, dataUpdate, setDataUpdate }) => {
  // year selectbox
  const { Option } = Select;
  const [loader, setLoader] = React.useState(false);
  const [viewModalVisible, setViewModalVisible] = React.useState(false);
  const [viewModalData, setViewModalData] = React.useState([]);
  const [leaveTypesId, setLeaveTypesId] = React.useState([]);
  const [leaveTypesName, setLeaveTypesName] = React.useState("");
  const [differenceInTime, setDifferenceInTime] = React.useState(0);
  const [dateDifference, setDateDifference] = React.useState(0);
  const [payload, setPayload] = React.useState(new Date().getFullYear());
  const [year, setYear] = React.useState([]);
  const [allEmployees, setAllEmployees] = React.useState([]);
  const [employees, setEmployees] = React.useState([]);
  const [leaveSearch, setLeaveSearch] = React.useState([]);

  // HIDING CORNER BUTTONS
  React.useEffect(() => {
    setButtonVisible((prevValue) => {
      return {
        ...prevValue,
        addLeave: false,
        leaveType: false,
        createLeaveType: true,
      };
    });
  }, []);

  const [allLeaves, setAllLeaves] = React.useState([]);
  const [paginationTotal, setPaginationTotal] = React.useState(null);
  const [pageNumber, setPageNumber] = React.useState(1);
  // FETCHING all leaves
  const loadLeavesOfEmployee = () => {
    return getOtherEmployeeLeave(leaveTypesId, payload)
      .then((res) => {
        setViewModalData(res.data);
      })
      .catch((error) => {
        // notification.error(error.response.data.errors);
      });
  };
  React.useEffect(() => {
    loadLeavesOfEmployee();
  }, [leaveTypesId]);

  const handleSearch = (e) => {
    setPageNumber(1)
    setLeaveSearch(e.target.value);
  };
  React.useEffect(() => {
    setLoader(true);
  }, [payload, pageNumber, allEmployees]);
  // FETCHING ALL LEAVES
  const loadAllLeaves = () => {
    // setLoader(true)
    return getALLEmpLEave(payload, pageNumber, allEmployees, leaveSearch)
      .then((res) => {
        setLoader(false);
        setPaginationTotal(res.meta.pagination.total);
        setAllLeaves(res.data);
      })
      .catch((error) => {
        // notification.error(error.response.data.errors);
      });
  };



  React.useEffect(() => {
    loadAllLeaves();
  }, [pageNumber, payload, allEmployees, leaveSearch]);
  React.useEffect(() => {
    loadAllLeaves();
    setDataUpdate(false);
  }, [dataUpdate]);

  

  // Handling Pagination
  const handlePagination = (pageNumber) => {
    setPageNumber(pageNumber);
  };

  const handleYear = (value) => {
    setPayload(value);
  };

  const showLeave = (leave) => {
    const time1 = new Date(leave.start_date).getTime();
    const time2 = new Date(leave.end_date).getTime();
    const year = new Date().getFullYear();
    const startDate = new Date(year + "-01-01");
    const endDate = new Date(year + "-12-31");
    const startDiff = startDate - time1;
    const endDiff = endDate - time2;
    if (startDiff === 0 && endDiff === 0) {
      return false;
    }
    return true;
  };

  // MAPPING YEAR
  React.useEffect(() => {
    const allYears = [];
    for (let i = Number(new Date().getFullYear()) + 1; i >= Number(2021); i--) {
      allYears.push(i);
    }
    setYear(allYears);
  }, []);

  const dateCalculation = (dateString) => {
    const time1 = new Date().getTime();
    const time2 = new Date(dateString).getTime();
    const Difference_In_Time = time2 - time1;
    const Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
  };

  // SETTING EMPLOYEE STATE
  const handleEmployee = (value) => {
    setPageNumber(1)
    setAllEmployees(value);
  };
  
  // FETCHING ALL EMPLOYEES NAMES
  const loadOrganizationEmployees = () => {
    return getOrganizationEmployeesWithEx()
      .then((res) => {
        setEmployees(res.data);
      })
      .catch((error) => {
        notification.error(error.response.data.errors);
      });
  };
  React.useEffect(() => {
    loadOrganizationEmployees();
  }, []);

  var columns = [
    {
      title: "Profile pic",
      dataIndex: "profile_pic",
      className: "profileAP-container",
      width: "25%",
      render: (data) => {
        return (
          <div
            className="user-img"
            style={{ backgroundImage: `url(${data})` }}
          ></div>
        );
      },
    },
    {
      title: "Emp. Id",
      dataIndex: "employee_id",
      className: "empid",
      width: "25%",
      render: (id, user) => {
        if (user.is_x !== "0" || user.disable !== "0") {
          return <div className="disabled_user">{id}</div>;
        }
        return id;
      },
    },
    {
      title: "Name",
      dataIndex: "name",
      className: "manage-leaves-name",
      width: "25%",
      render: (text) => {
        return (
          <div className="empName-box fx fx--ai-c">
            <div className="empImg-box">
              <div
                className="img"
                style={{ backgroundImage: `url(${male})` }}
              ></div>
            </div>
            <div className="empName">{text}</div>
          </div>
        );
      },
    },
    {
      title: "Action",
      dataIndex: "",
      className: " text-right manage-leaves-actions",
      width: "25%",
      render: (text) => (
        <span>
          <Tooltip title={"Leaves"}>
            <Button
              type="link"
              onClick={() => {
                setViewModalVisible(true);
                setLeaveTypesId(text.id);
                setLeaveTypesName(text.name)
              }}
              className="btn btn-light-info btn-icon text-uppercase"
            >
              <Icon type="eye" theme="filled" />
            </Button>
          </Tooltip>
        </span>
      ),
    },
  ];

  return (
    <>
      {loader && (
        <div
          className="centerMe"
          style={{
            height: "500px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Spin />
        </div>
      )}
      {!loader && (
        <section className="content-section manageLeaves-section">
          <div className="content">
            <div className="content-header single-searchBar manageLeaveFilters">
              <div className="right single-item">
                {/* SELECT EMPLOYEE */}
                <Select
                  allowClear={true}
                  className="empFilter-selectbox selectbox"
                  showSearch
                  style={{ width: 200 }}
                  placeholder="All Employees"
                  name="userName"
                  onChange={handleEmployee}
                  value={allEmployees}
                  filterOption={(input, option) =>
                    option.props.label
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  <Option key={0} value={undefined} label="All Employee">
                    <div className="demo-option-label-item">All Employee</div>
                  </Option>
                  {employees.map((employee) => {
                    return (
                      <Option
                        key={employee.id}
                        value={employee.uuid}
                        label={employee.name}
                      >
                        <div className="demo-option-label-item">
                          {employee.name}
                        </div>
                      </Option>
                    );
                  })}
                </Select>

                <div className="search-box">
                  <Input
                    allowClear={true}
                    onChange={handleSearch}
                    placeholder="Search Leaves"
                    suffix={<Icon type="search" />}
                  />
                </div>
              </div>
              <div style={{display:'flex'}}>

              <div className="year-filter">
                {/* YEAR */}
                <Select
                  // allowClear={true}
                  className="year-selectbox selectbox"
                  style={{ width: 85 }}
                  placeholder="Year"
                  onChange={handleYear}
                  value={payload}
                >
                  {year.map((singleYear) => {
                    let year = singleYear.toString();
                    return (
                      <Option key={year} value={year}>
                        {year}
                      </Option>
                    );
                  })}
                </Select>
              </div>
              </div>
            </div>

            {/* Admin Panel- Table content */}
            <section className="content-section profile-table-info animated slideInUp">
              <div id="container">
                <Table
                  className="table-content"
                  columns={columns}
                  dataSource={allLeaves}
                  rowKey={(employee) => employee.uuid}
                  pagination={{
                    onChange: handlePagination,
                    current: pageNumber,
                    total: paginationTotal,
                    pageSize: 10,
                  }}
                />
              </div>
            </section>
          </div>

          {/* for manage leaves */}
          <Drawer
            className="navigator-drawer manageLeaves-drawer"
            title="Manage"
            placement="right"
            width={500}
            bodyStyle={{ padding: 0 }}
            destroyOnClose={true}
          >
            <ManageLeavesDrawer />
          </Drawer>

          {/* for view leaves */}
          <Drawer
            className="navigator-drawer manageLeaves-drawer"
            title="Leaves Details"
            placement="right"
            width={500}
            onClose={() => {
              setViewModalVisible(false)
              setLeaveTypesId([]);
              setLeaveTypesName('')
            }}
            visible={viewModalVisible}
            bodyStyle={{ padding: 0 }}
            destroyOnClose={true}
          >
          <h2 style={{ marginTop: "70px", paddingLeft: "1.5rem",color: 'rgb(13, 38, 76)',fontSize: '1.5rem',marginBottom:'1rem' }}>{leaveTypesName}</h2>
            <ul style={{listStyleType: "number" }}>
              {viewModalData.length > 0 &&
                viewModalData.map((line) => {
                  dateCalculation(line.end_date);
                  return (
                    <>
                      {differenceInTime > 0 ? null : (
                        <>
                        <li>
                          <div
                            style={{
                              color: "#0D264C",
                              padding: "0 20px 0 0",
                              fontSize: "0.95rem",
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <p style={{ marginBottom: "0px" }}>{line.name}</p>
                            <p style={{ marginBottom: "0px" }}>
                               <i style={{color:line.remaining_counts>0 ? `${line.color}` : 'red'}}>{line.remaining_counts}</i>
                              {"/"}
                              <i style={{color:`${line.color}`}}>{line.initial_counts}</i>
                              
                            </p>
                          </div>
                          {showLeave(line) ? (
                            <p style={{ marginBottom: "2rem" }}>
                              Validity:{" "}
                              <i>{new Date(line.start_date).toDateString()}</i>
                              &nbsp;to&nbsp;
                              <i>{new Date(line.end_date).toDateString()}</i>
                            </p>
                          ) : (
                            <p style={{ marginBottom: "2rem" }}></p>
                          )}
                        </li>
                        </>
                      )}
                    </>
                  );
                })}
            </ul>
          </Drawer>
        </section>
      )}
    </>
  );
};

export default ManageLeaves;
