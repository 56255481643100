import React, { Component, Fragment } from "react";
import { Row, Col } from "antd";
import moment from "moment";
import RequireAuth from "../../../hocs/RequireAuth";
import { withStore } from "../../../hocs/withStore";
import text from "../../../config/text";

class ViewPersonalDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      personal_details: null,
    };
  }

  static getDerivedStateFromProps(props, state) {
    return {
      personal_details: props.personal_details,
    };
  }

  render() {
    const { personal_details } = this.state;
    let mobile = this.state.personal_details
      ? `tel:${this.state.personal_details.mobile}`
      : "";
    let personal_email = this.state.personal_details
      ? `mailto:${this.state.personal_details.email_personal}`
      : "";
    const fallback = "-";
    return (
      <Fragment>
        {personal_details && (
          <section className="tab-content-container personal-details-tab view-personaldetails-tab">
            <h3 className="title text-primary">About yourself</h3>
            <Row className="" gutter={30}>
              <Col
                className="gutter-row first-half-col"
                xs={24}
                sm={24}
                md={24}
                lg={12}
                xl={12}
              >
                <div className="form-content">
                  <Row className="" gutter={30}>
                    <Col
                      className="gutter-row"
                      xs={12}
                      sm={12}
                      md={12}
                      lg={12}
                      xl={12}
                    >
                      <div className="form-group">
                        <label className="label">First Name</label>
                        <div className="value">
                          {personal_details.first_name || fallback}
                        </div>
                      </div>
                    </Col>
                    <Col className="" xs={12} sm={12} md={12} lg={12} xl={12}>
                      <div className="form-group">
                        <label className="label">Last Name</label>
                        <div className="value">
                          {personal_details.last_name || fallback}
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <Row className="" gutter={30}>
                    <Col
                      className="gutter-row"
                      xs={12}
                      sm={12}
                      md={12}
                      lg={12}
                      xl={12}
                    >
                      <div className="form-group">
                        <label className="label">Father's Name</label>
                        <div className="value">
                          {personal_details.father_name || fallback}
                        </div>
                      </div>
                    </Col>
                    <Col className="" xs={12} sm={12} md={12} lg={12} xl={12}>
                      <div className="form-group">
                        <label className="label">Mother's Name</label>
                        <div className="value">
                          {personal_details.mother_name || fallback}
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <Row className="" gutter={30}>
                    <Col
                      className="gutter-row"
                      xs={12}
                      sm={12}
                      md={12}
                      lg={12}
                      xl={12}
                    >
                      <div className="form-group">
                        <label className="label">Personal Email ID</label>
                        <div className="value">
                          <a href={personal_email}>
                            {personal_details.email_personal || fallback}
                          </a>
                        </div>
                      </div>
                    </Col>
                    <Col className="" xs={12} sm={12} md={12} lg={12} xl={12}>
                      <div className="form-group">
                        <label className="label">Mobile Number</label>
                        <div className="value">
                          <a href={mobile}>
                            {personal_details.mobile || fallback}
                          </a>
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <Row className="" gutter={30}>
                    <Col
                      className="gutter-row"
                      xs={12}
                      sm={12}
                      md={12}
                      lg={12}
                      xl={12}
                    >
                      <div className="form-group">
                        <label className="label">Gender</label>
                        <div className="value">
                          {text[personal_details.gender] || fallback}
                        </div>
                      </div>
                    </Col>
                    <Col className="" xs={12} sm={12} md={12} lg={12} xl={12}>
                      <div className="form-group">
                        <label className="label">Marital Status</label>
                        <div className="value">
                          {personal_details.marrital_status || fallback}
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <Row className="" gutter={30}>
                    <Col
                      className="gutter-row"
                      xs={12}
                      sm={12}
                      md={12}
                      lg={12}
                      xl={12}
                    >
                      <div className="form-group">
                        <label className="label">Spouse Name</label>
                        <div className="value">
                          {personal_details.spouse_name || fallback}
                        </div>
                      </div>
                    </Col>
                    <Col className="" xs={12} sm={12} md={12} lg={12} xl={12}>
                      <div className="form-group">
                        <label className="label">Anniversary Date</label>
                        <div className="value">
                          {(personal_details.anniversary_date &&
                            moment(
                              personal_details.anniversary_date,
                              "YYYY-MM-DD HH:mm:ss"
                            ).format("DD-MMM-YYYY")) ||
                            fallback}
                        </div>
                      </div>
                    </Col>
                  </Row>

                  {/* Current Address */}
                  <div className="title-with-flex">
                    <h3 className="title text-primary">Current Address</h3>
                  </div>
                  <Row className="" gutter={30}>
                    <Col
                      className="gutter-row"
                      xs={24}
                      sm={24}
                      md={24}
                      lg={24}
                      xl={24}
                    >
                      <div className="form-group">
                        <label className="label">Current Address</label>
                        <div className="value">
                          {personal_details.correspondence_address &&
                          personal_details.correspondence_address["address"]
                            ? personal_details.correspondence_address["address"]
                            : "-"}
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <Row className="" gutter={30}>
                    <Col
                      className="gutter-row"
                      xs={12}
                      sm={12}
                      md={12}
                      lg={12}
                      xl={12}
                    >
                      <div className="form-group">
                        <label className="label">Locality</label>
                        <div className="value">
                          {(personal_details.correspondence_address &&
                            personal_details.correspondence_address[
                              "locality"
                            ]) ||
                            fallback}
                        </div>
                      </div>
                    </Col>
                    <Col className="" xs={12} sm={12} md={12} lg={12} xl={12}>
                      <div className="form-group">
                        <label className="label">Pincode</label>
                        <div className="value">
                          {personal_details.correspondence_address &&
                          personal_details.correspondence_address["pincode"]
                            ? personal_details.correspondence_address["pincode"]
                            : "-"}
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <Row className="" gutter={30}>
                    <Col
                      className="gutter-row"
                      xs={12}
                      sm={12}
                      md={12}
                      lg={12}
                      xl={12}
                    >
                      <div className="form-group">
                        <label className="label">City/District/Town</label>
                        <div className="value">
                          {personal_details.correspondence_address &&
                          personal_details.correspondence_address["city"]
                            ? personal_details.correspondence_address["city"]
                            : "-"}
                        </div>
                      </div>
                    </Col>
                    <Col
                      className="gutter-row"
                      xs={12}
                      sm={12}
                      md={12}
                      lg={12}
                      xl={12}
                    >
                      <div className="form-group">
                        <label className="label">State</label>
                        <div className="value">
                          {personal_details.correspondence_address &&
                          personal_details.correspondence_address["state"]
                            ? personal_details.correspondence_address["state"]
                            : "-"}
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
              </Col>
              <Col
                className="gutter-row second-half-col"
                xs={24}
                sm={24}
                md={24}
                lg={12}
                xl={12}
              >
                <div className="form-content">
                  <Row className="" gutter={30}>
                    <Col
                      className="gutter-row"
                      xs={12}
                      sm={12}
                      md={12}
                      lg={12}
                      xl={12}
                    >
                      <div className="form-group">
                        <label className="label">DOB</label>
                        <div className="value">
                          {(personal_details.dob_personal &&
                            moment(
                              personal_details.dob_personal,
                              "YYYY-MM-DD HH:mm:ss"
                            ).format("DD-MMM-YYYY")) ||
                            "-"}
                        </div>
                      </div>
                    </Col>
                    <Col className="" xs={12} sm={12} md={12} lg={12} xl={12}>
                      <div className="form-group">
                        <label className="label">DOB (Official)</label>
                        <div className="value">
                          {(personal_details.dob_official &&
                            moment(
                              personal_details.dob_official,
                              "YYYY-MM-DD HH:mm:ss"
                            ).format("DD-MMM-YYYY")) ||
                            "-"}
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <Row className="" gutter={30}>
                    <Col
                      className="gutter-row"
                      xs={24}
                      sm={24}
                      md={24}
                      lg={24}
                      xl={24}
                    >
                      <div className="form-group">
                        <label className="label">College Name</label>
                        <div className="value">
                          {personal_details.college
                            ? personal_details.college
                            : "-"}
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <Row className="" gutter={30}>
                    <Col
                      className="gutter-row"
                      xs={12}
                      sm={12}
                      md={12}
                      lg={12}
                      xl={12}
                    >
                      <div className="form-group">
                        <label className="label">Blood Group</label>
                        <div className="value">
                          {personal_details.blood_group
                            ? personal_details.blood_group
                            : "-"}
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <Row className="" gutter={30}>
                    <Col
                      className="gutter-row"
                      xs={12}
                      sm={12}
                      md={12}
                      lg={12}
                      xl={12}
                    >
                      <div className="form-group">
                        <label className="label">Meal Preferences</label>
                        <div className="value">
                          {text[personal_details.meal]}
                        </div>
                      </div>
                    </Col>
                    <Col className="" xs={12} sm={12} md={12} lg={12} xl={12}>
                      <div className="form-group">
                        <label className="label">Drink Preferences</label>
                        <div className="value">
                          {text["alcohol_" + personal_details.alcohol]}
                        </div>
                      </div>
                    </Col>
                  </Row>

                  {/* Permanent Address */}

                  <div className="title-with-flex">
                    <h3 className="title text-primary">Permanent Address</h3>
                  </div>

                  <Row className="" gutter={30}>
                    <Col
                      className="gutter-row"
                      xs={24}
                      sm={24}
                      md={12}
                      lg={24}
                      xl={24}
                    >
                      <div className="form-group">
                        <label className="label">Permanent Address</label>
                        <div className="value">
                          {personal_details.permanent_address &&
                          personal_details.permanent_address["address"]
                            ? personal_details.permanent_address["address"]
                            : "-"}
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <Row className="" gutter={30}>
                    <Col
                      className="gutter-row"
                      xs={12}
                      sm={12}
                      md={12}
                      lg={12}
                      xl={12}
                    >
                      <div className="form-group">
                        <label className="label">Locality</label>
                        <div className="value">
                          {personal_details.permanent_address &&
                          personal_details.permanent_address["locality"]
                            ? personal_details.permanent_address["locality"]
                            : "-"}
                        </div>
                      </div>
                    </Col>
                    <Col className="" xs={12} sm={12} md={12} lg={12} xl={12}>
                      <div className="form-group">
                        <label className="label">Pincode</label>
                        <div className="value">
                          {personal_details.permanent_address &&
                          personal_details.permanent_address["pincode"]
                            ? personal_details.permanent_address["pincode"]
                            : "-"}
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <Row className="" gutter={30}>
                    <Col
                      className="gutter-row"
                      xs={12}
                      sm={12}
                      md={12}
                      lg={12}
                      xl={12}
                    >
                      <div className="form-group">
                        <label className="label">City/District/Town</label>
                        <div className="value">
                          {personal_details.permanent_address &&
                          personal_details.permanent_address["city"]
                            ? personal_details.permanent_address["city"]
                            : "-"}
                        </div>
                      </div>
                    </Col>
                    <Col className="" xs={12} sm={12} md={12} lg={12} xl={12}>
                      <div className="form-group">
                        <label className="label">State</label>
                        <div className="value">
                          {personal_details.permanent_address &&
                          personal_details.permanent_address["state"]
                            ? personal_details.permanent_address["state"]
                            : "-"}
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </section>
        )}
      </Fragment>
    );
  }
}

export default RequireAuth(withStore(ViewPersonalDetails));
