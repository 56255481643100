import React, { Fragment, useEffect, useState } from "react";
import { Spin, Row, Form, Col, Button,Table } from "antd";
import { getOrgTeams } from "../../../services/ProjectService";
import moment from "moment";
import {
  getProjectUserDetails,
} from "../../../services/ProjectService";
import TextArea from "antd/lib/input/TextArea";

const ViewProject = (props) => {
  const [pageCurrent, setpageCurrent] = useState(1);
  const [editProjectTableList, setEditProjectTableList] = useState("");
  const [allData, setAllData] = useState("");
  const [searchKey, setSearchKey] = useState("");
  const [loading, setLoading] = useState(false);
  var initialLoading = props.loading;
  const [teams, setTeams] = useState([]);
  const defaultData = props.data;
  var initialTeamArr =
    teams &&
    defaultData &&
    defaultData.users &&
    defaultData.users.filter((val) => teams.map((el) => el.id === val.team_id));
  var initialTeam = initialTeamArr && initialTeamArr.map((el) => el.team_id);
  var initialTeamData = [...new Set(initialTeam)];
  var initialShortageArr =
  defaultData && defaultData.shortage.map((el) => el.team_id);
  if(initialShortageArr.length){
    initialShortageArr.map(el=>{
      if(!initialTeamData.includes(el)){
        initialTeamData.push(el)
      }
    })
  }
  var fieldData = initialTeamData.map((valId) =>
    teams.filter((el) => el.id === valId)
  );
  const closeModal = props.closeModal;
  const fallback = "-";
  const getOrgTeamsData = () => {
    getOrgTeams().then((res) => {
      setTeams(res && res.data && res.data.data);
    });
  };
  useEffect(() => {
    getOrgTeamsData();
  }, []);
  const statusValue = (status) => {
    switch (status) {
      case 0:
        return "Pending";
        break;
      case 1:
        return "Active";
        break;
      case 2:
        return "On Hold";
        break;
      case 3:
        return "Completed";
        break;
      case 4:
        return "Cancelled";
        break;
      default:
        return "Active";
    }
  };
  const projectValue = (type) => {
    switch (type) {
      case 1:
        return "EMP";
        break;
      case 2:
        return "IMP";
        break;
      case 3:
        return "INTERNAL";
        break;
      case 4:
        return "RESEARCH";
        break;  
      default:
        return "-";
    }
  };
  const getProjectUsersData = (page,id) => {
    setLoading(true);
    return getProjectUserDetails(page,id).then((res) => {
      setEditProjectTableList(res.data.data);
      setAllData(res.data)
      setLoading(false);
    });
  };
  
  useEffect(()=>{
   
    setpageCurrent(1)
    if(defaultData && defaultData.id && defaultData.id){
      setSearchKey(defaultData.id)
    getProjectUsersData(1,defaultData.id)
    }
  },[defaultData.id])
  const columns = [
    {
      title: "S.No.",
      key: "index",
      className: "text-center",
      render: (text, object, index) => (
        <div>{(pageCurrent - 1) * 10 + (index + 1)}</div>
      ),
    },
    {
      title: "Employee Name",
      dataIndex: "name",
      key: "id",
    },
    {
      title: "From",
      dataIndex: "start_date",
      render: (text) => (
        text ?
        <span>{moment(text).format("DD-MMM-YYYY")}</span> :
        (<span style={{marginLeft : '5px'}}>-</span>)
      ),
    },
    {
      title: "To",
      dataIndex: "end_date",
      render: (text) => (
        text ?
        <span>{moment(text).format("DD-MMM-YYYY")}</span> :
        (<span style={{marginLeft : '5px'}}>-</span>)
      ),
      // render: (join_date, user) => {
      //   let date = (join_date && join_date) || "";
      //   if (user.is_x !== "0" || user.disable !== "0") {
      //     return (
      //       <div className="disabled_user">
      //         {date == ""
      //           ? ""
      //           : moment(date, "YYYY-MM-DD HH:mm:ss").format("DD-MMM-YYYY")}
      //       </div>
      //     );
      //   }
      //   return date == ""
      //     ? ""
      //     : moment(date, "YYYY-MM-DD HH:mm:ss").format("DD-MMM-YYYY");
      // },
    },
    {
      title: "Team",
      dataIndex: "role",
      key: "id",
    },
  ];
  const handlePagination = (pagination) => {
    setpageCurrent(pagination.current);
    getProjectUsersData(pagination.current,searchKey);
  };

  return (
    <Fragment>
      <div className="add-new-profile-modal-container">
        <section>
          <h3 className="title text-primary">Project Details</h3>
          {initialLoading ? (
            <div className="page-spinner">
              <Spin />
            </div>
          ) : (
            <>
              <form>
                <Row className="row" gutter={30}>
                  <Col
                    className="gutter-row first-half-col"
                    xs={24}
                    sm={24}
                    md={24}
                    lg={12}
                    xl={12}
                  >
                    <Row gutter={30}>
                      <Col
                        className="gutter-row"
                        xs={24}
                        sm={24}
                        md={24}
                        lg={24}
                        xl={24}
                      >
                        <div className="form-content form-group">
                          <label className="label">Title</label>
                          <div className="value">
                            {(defaultData && defaultData.title) || fallback}
                          </div>
                        </div>
                      </Col>
                      <Col
                        className="gutter-row"
                        xs={24}
                        sm={24}
                        md={12}
                        lg={12}
                        xl={12}
                      >
                        <div className="form-content form-group">
                          <label className="label">Project Type</label>
                          <div className="value">
                               {(defaultData && defaultData.project_type &&
                              projectValue(defaultData.project_type)) ||
                              fallback}
                              
                          </div>
                        </div>
                      </Col>
                      <Col
                        className="gutter-row"
                        xs={24}
                        sm={24}
                        md={12}
                        lg={12}
                        xl={12}
                      >
                        <div className="form-content form-group">
                          <label className="label">Start Date</label>
                          <div className="value">
                            {(defaultData && defaultData.start_date) ||
                              fallback}
                          </div>
                        </div>
                      </Col>
                      <Col
                        className="gutter-row"
                        xs={24}
                        sm={24}
                        md={12}
                        lg={12}
                        xl={12}
                      >
                        <div className="form-content form-group">
                          <label className="label">End Date</label>
                          <div className="value">
                            {(defaultData && defaultData.end_date) || fallback}
                          </div>
                        </div>
                      </Col>
                      <Col
                        className="gutter-row"
                        xs={24}
                        sm={24}
                        md={12}
                        lg={12}
                        xl={12}
                      >
                        <div className="form-content form-group">
                          <label className="label">Status</label>
                          <div className="value">
                            {(defaultData &&
                              (defaultData.status || defaultData.status == 0) &&
                              statusValue(defaultData.status)) ||
                              fallback}
                          </div>
                        </div>
                      </Col>

                      <Col
                        className="gutter-row"
                        xs={24}
                        sm={24}
                        md={24}
                        lg={24}
                        xl={24}
                      >
                        <div className="form-content form-group">
                          <label className="label">Skills</label>
                          <div className="value">
                            {(defaultData &&
                              defaultData.skills
                                .map((value) => value.title)
                                .join(", ")) ||
                              fallback}
                          </div>
                        </div>
                      </Col>

                      <Col
                        className="gutter-row"
                        xs={24}
                        sm={24}
                        md={24}
                        lg={24}
                        xl={24}
                      >
                        <div className="form-content form-group">
                          <label className="label">Project Description</label>
                          <div className="value">
                            {/* {(defaultData && defaultData.notes) || fallback} */}

                           {defaultData && defaultData.notes &&
                                <TextArea 
                                defaultValue={(defaultData &&
                                  defaultData.notes) ||
                                  fallback}
                                disabled
                                autoSize={{ minRows: 1, maxRows: 6 }}
                                style={{resize: 'none' }}

                                /> ||
                              fallback}
                          </div>
                        </div>
                      </Col>
                      <Col
                        className="gutter-row"
                        xs={24}
                        sm={24}
                        md={12}
                        lg={12}
                        xl={12}
                      >
                        <div className="form-content form-group">
                          <label className="label">Next Deliverable Date</label>
                          <div className="value">
                            {(defaultData &&
                              defaultData.next_deliverable_date) ||
                              fallback}
                          </div>
                        </div>
                      </Col>
                      <Col
                        className="gutter-row"
                        xs={24}
                        sm={24}
                        md={24}
                        lg={24}
                        xl={24}
                      >
                        <div className="form-content form-group">
                          <label className="label">
                            Next Deliverable Notes
                          </label>
                          <div className="value">
                            {defaultData && defaultData.next_deliverable_notes &&
                                <TextArea 
                                defaultValue={(defaultData &&
                                  defaultData.next_deliverable_notes) ||
                                  fallback}
                                disabled
                                autoSize={{ minRows: 1, maxRows: 6 }}
                                style={{resize: 'none' }}
                             /> ||
                              fallback}
                            
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </Col>

                  <Col
                    className="gutter-row second-half-col"
                    xs={24}
                    sm={24}
                    md={24}
                    lg={12}
                    xl={12}
                  >
                    <Row gutter={30}>
                      {/* isShort container starts */}
                      {fieldData &&
                        fieldData.length > 0 &&
                        fieldData.map((fieildInfo, index) => {
                          return (
                            <Col
                              className="gutter-row"
                              xs={24}
                              sm={24}
                              md={24}
                              lg={24}
                              xl={24}
                            >
                                  <div className="form-content form-group">
                                    <label className="label">
                                      {fieildInfo[0] && fieildInfo[0].title}
                                      <div className="form-content leaveType-cont" style={{color:'red',display:'inline-block',marginLeft:'3px'}}>
                                        {defaultData.shortage.filter(
                                          (el) =>
                                            el.team_id ===
                                            initialTeamData[index]
                                        ) &&
                                          defaultData.shortage.filter(
                                            (el) =>
                                              el.team_id ===
                                              initialTeamData[index]
                                          )[0] &&
                                          "("+"Short By" +
                                            ":" +
                                            " " +
                                            defaultData.shortage.filter(
                                              (el) =>
                                                el.team_id ===
                                                initialTeamData[index]
                                            )[0].counts
                                            + ")"}
                                    </div>
                                    </label>
                                    {defaultData.users.filter(
                                      (el) =>
                                        el.team_id === initialTeamData[index]
                                    )
                                      ? defaultData.users
                                          .filter(
                                            (el) =>
                                              el.team_id ===
                                              initialTeamData[index]
                                          )
                                          .map((el) => el.name)
                                          .join(",")
                                      : "-"}
                                  </div>
                            </Col>
                          );
                        })}
                    </Row>
                  </Col>
                </Row>
              </form>
              {loading ? (
              <div className="page-spinner">
                <Spin />
              </div>
            ) : (
              <>
              <Table
            dataSource={editProjectTableList}
            columns={columns}
            onChange={handlePagination}
            pagination={{
              total:
              allData &&
              allData.meta &&
              allData.meta.pagination &&
              allData.meta.pagination.total,
              pageSize:
              allData &&
              allData.meta &&
              allData.meta.pagination &&
              allData.meta.pagination.per_Page,
              current: pageCurrent,
              showSizeChanger: false,
              defaultCurrent: 1,
            }}
          />
          </>
            )}
            </>
          )}
        </section>
        <div style={{ marginTop: "1rem", width: "100%", textAlign: "right" }}>
          <Button className="btn-primary" onClick={closeModal}>
            Close
          </Button>
        </div>
      </div>
    </Fragment>
  );
};

export default Form.create()(ViewProject);
