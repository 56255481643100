import * as APIs from "../constants/BackendConstant";
import { axiosInstance } from "../utilities/configureAxios";

// Getting Project List DATA
export const getProjectListData = (page,searchKey,statusKey) => {
  console.log('statusKey',statusKey)
  console.log('searchKey',searchKey)
  let search = ''
 let status = ''
  if(searchKey !== '' && searchKey){
   search = `&q=${searchKey}` 
  }
  if(statusKey !== '' && statusKey){
    status = `&status=${statusKey}` 
   }
    let url = APIs.GET_PROJECT_LIST.replace(':page',page);
  if(search !== '' && status !== ''){
return axiosInstance.get(url + search + status);
  }else if(search !== ''){
return axiosInstance.get(url + search );
  }else if(status !== ''){
    return axiosInstance.get(url + status );
      }else{
        return axiosInstance.get(url);
          }
  };
  // Getting Employee Project List DATA
export const getEmployeeProjectListData = (page,status='',searchKey='',date='') => {
  let search = ''
  let filter=''
  let endingDate=''
  if(searchKey !== '' && searchKey){
    search = `&q=${searchKey}` 
   }
   if(status !== '' && status =='assigned'){
     filter = `&filter=1`
   }else if(status !== '' && status =='unassigned') {
    filter = `&filter=2`
   }
   if(date != ''){
     endingDate = `&date=${date}`
   }
  let url = APIs.GET_EMPLOYEE_PROJECT_LIST.replace(':page',page);
      if (search !== ''){
        url = url + search ;
      } if(filter !== ''){
        url= url + filter ;
      }
      if(endingDate !== ''){
        url = url + endingDate ;
      }
      
      return axiosInstance.get(url);
          
};
 // Getting Employee Project Details DATA
 export const getEmployeeProjectDetailsData = (id) => {
  let url = APIs.GET_EMPLOYEE_PROJECT_DETAILS.replace(':id',id);
  return axiosInstance.get(url);
};


  // Getting Project List DATA
export const getSkillsData = () => {
  let url = APIs.GET_SKILLS;
  return axiosInstance.get(url);
};
  // Getting Project List DATA
  export const getOrgTeams = () => {
    let url = APIs.ADD_ORG_TEAM;
    return axiosInstance.get(url);
  };



// Creating Project
export const createProject = (body) => {
  let url = APIs.CREATE_PROJECT;
  return axiosInstance.post(url,body);
};
// Update Project
export const updateProject = (body,id) => {
  let url = APIs.GET_PROJECT_DETAILS.replace(':id',id);
  return axiosInstance.put(url,body);
};

// Update Project team
export const updateProjectTeam = (body,id) => {
  let url = APIs.GET_PROJECT_TEAM_DETAILS.replace(':id',id);
  return axiosInstance.put(url,body);
};

// Getting Project details
export const getProjectDetailsEdit = (id) => {
  let url = APIs.GET_PROJECT_DETAILS_EDIT.replace(':id',id);
  return axiosInstance.get(url);
};
export const getProjectUserDetails = (page,id) => {
  let url1 = APIs.GET_PROJECT_USER_DETAILS.replace(':id',id);
  let url = url1.replace(':page',page);
  return axiosInstance.get(url);
};
export const getUserProjectsDetails = (page,id) => {
  let url1 = APIs.GET_USER_PROJECTS_DETAILS.replace(':id',id);
  let url = url1.replace(':page',page);
  return axiosInstance.get(url);
};
export const getProjectDetails = (id) => {
  let url = APIs.GET_PROJECT_DETAILS.replace(':id',id);
  return axiosInstance.get(url);
};
// Getting suggested employee list
export const getSuggestedEmployees = (page,skills) => {
  let url1 = APIs.SUGGESTED_EMPLOYEES.replace(':skills',skills);
  let url = url1.replace(':page',page);
  return axiosInstance.get(url);
};
// Deleting Project details
export const deleteProjectDetails = (id) => {
  let url = APIs.DELETE_PROJECT_DETAILS.replace(':id',id);
  return axiosInstance.delete(url);
};