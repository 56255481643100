import React, { Component, Fragment } from 'react';
import { Helmet } from 'react-helmet';

import EmpBillManagementDashboard from './components/EmpBillManagementDashboard';

const TITLE = 'Bill Management';

export default class EmpBillManagement extends Component {
  render() {
    return (
      <Fragment>
        <Helmet>
          <title>{TITLE}</title>
        </Helmet>
        <div className='page-wrapper'>
          <EmpBillManagementDashboard isEmpBill={true} />
        </div>
      </Fragment>
    );
  }
}
