import React from 'react';
import lottie from 'lottie-web';
import animationData from '../../assets/animation/json/Loading';

let animObj = null;

class Loading extends React.Component {
  constructor() {
    super();
    this.isPlaying = false;
  }
  componentDidMount() {
    animObj = lottie.loadAnimation({
      container: this.animBox,
      renderer: 'svg',
      loop: true,
      autoplay: true,
      animationData: animationData
    });
    // animObj.setSpeed(0.9);
    // this.handlePlay();
    // animObj.onLoopComplete = (() => {
    //   alert(this);
    //   this.isPlaying = false;
    // });
  }
  handleStop = () => {
    animObj.stop();
    animObj.play();
  }
  handlePlay() {
    animObj.play();
    
  }

  handleAnimationPress() {
   
    this.isPlaying === false ? this.handlePlay() : this.handleStop();
    this.isPlaying = !this.isPlaying;
  }

  render() {
    return (
      <div className="out-animation-box">
        {this.props.text}
        <div className="navigator-loader" ref={ref => this.animBox = ref}></div>
      </div>
    );
  }
}

export default Loading;