import React, { Component, Fragment } from "react";
import { Helmet } from 'react-helmet'
import IntercomDirectory from "./components/IntercomDirectory";

const TITLE = 'Intercom Directory'

export default class IntercomDirectoryManagement extends Component {
  render() {
    return (
      <Fragment>
        <Helmet>
          <title>{TITLE}</title>
        </Helmet>
        <div className="page-wrapper">
          <IntercomDirectory></IntercomDirectory>
        </div>
      </Fragment>
    );
  }
}
