import React, { Component, Fragment } from "react";
import { Helmet } from "react-helmet";
import ForAdminAndOfficeAdminAndRM from "../../hocs/ForAdminAndOfficeAdminAndRM";
import { withStore } from "../../hocs/withStore";
import EmpBillManagementDashboard from "./components/EmpBillManagementDashboard";
const TITLE = "Bill Management";


class BillManagement extends Component {
  render() {
    return (
      <Fragment>
        <Helmet>
          <title>{TITLE}</title>
        </Helmet>
        <div className="page-wrapper">
          <EmpBillManagementDashboard isEmpBill={false} />
        </div>
      </Fragment>
    );
  }
}

export default ForAdminAndOfficeAdminAndRM(withStore(BillManagement))