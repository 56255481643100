import moment from "moment";
import { convertMinutesToHoursAndMinutesString } from "../../../utilities/DateUtility";
import renderHTML from "react-render-html";
import React, { Component, Fragment } from "react";
import { getEmployeeWorkReports, addNewWorkReport } from "../DataManager";
import {
  Icon,
  Button,
  Drawer,
  notification,
  Upload,
  Modal,
  Pagination,
  Divider,
  Form,
  Collapse,
  Spin,
} from "antd";
import dailyStatus from "../../../assets/images/icons/sibebar/daily-work-status.svg";
import send from "../../../assets/images/icons/send-icon.svg";
import SendWorkReport from "./SendWorkReportDrawer/SendWorkReport";
import { withStore } from "../../../hocs/withStore";
import RequireAuth from "../../../hocs/RequireAuth";
import ProfileComplete from "../../../hocs/ProfileComplete";
import NotificationUpdate from "../../../hocs/NotificationUpdate";

// collapse
const { Panel } = Collapse;

function callback(key) {}

// Pagination
function itemRender(current, type, originalElement) {
  if (type === "prev") {
    return (
      <a className="back-icon">
        {" "}
        <Icon type="left" /> Back
      </a>
    );
  }
  if (type === "next") {
    return (
      <a className="next-icon">
        Next <Icon type="right" />
      </a>
    );
  }
  return originalElement;
}

// for upload
function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}

class EmpDailyStatusDashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      workReports: null,
      visible: false,
      previewVisible: false,
      previewImage: "",
      fileList: [],
      loading: false,
      pagination: {
        total: 0,
        count: 0,
        per_page: 1,
        current_page: 1,
        total_pages: 1,
      },
    };
  }

  // for drawer
  state = { visible: false };

  componentDidMount() {
    this.getEmployeeWorkReportsFunction();
  }

  showDrawer = () => {
    this.setState({
      visible: true,
    });
  };

  onClose = () => {
    this.setState({
      visible: false,
    });
  };

  // for upload
  handleCancel = () => this.setState({ previewVisible: false });

  handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }

    this.setState({
      previewImage: file.url || file.preview,
      previewVisible: true,
    });
  };

  handleChange = ({ fileList }) => this.setState({ fileList });

  parseDate = (date, returnable) => {
    var date = moment(date, "YYYY-MM-DD HH:mm:ss");
    if (returnable === "weekday") return date.format("dddd").substring(0, 3);
    if (returnable === "dayOfMonth") return date.date();
  };

  prepareFileList = (images) => {
    var returnableFilelist = [];
    images.map((image, index) =>
      returnableFilelist.push({
        uid: index,
        status: "done",
        url: image,
      })
    );
    return returnableFilelist;
  };

  getFormattedDateTime = (dateTime) => {
    return moment(dateTime).format("DD-MM-YYYY HH:mm A");
  };

  getEmployeeWorkReportsFunction = (page = 1) => {
    this.setState({ loading: true, visible: false });
    return getEmployeeWorkReports(page)
      .then((res) => {
        this.setState({
          workReports: res.data,
          pagination: res.meta.pagination,
          loading: false,
        });
      })
      .catch((error) => {
        notification.error({
          message: error.response.data.errors.message,
        });
      });
  };

  addEmployeeWorkReportFunction = (body) => {
    return addNewWorkReport(body);
  };

  onPageChange = (pageNumber) => {
    this.setState({ loading: true });
    this.getEmployeeWorkReportsFunction(pageNumber, "");
  };

  isWorkReportMissing(workReport) {
    if (
      !workReport.id &&
      !workReport.reason.is_weekend &&
      !workReport.reason.is_holiday &&
      workReport.reason.on_leave === 0
    ) {
      return true;
    }
    return false;
  }

  isHolidayOrWeekend(workReport) {
    if (
      workReport.reason &&
      (workReport.reason.is_weekend || workReport.reason.is_holiday)
    ) {
      return true;
    }
    return false;
  }

  rawMarkup() {
    var rawMarkup = this.props.content;
    return { __html: rawMarkup };
  }

  render() {
    const { previewVisible, previewImage, fileList, workReports, loading } =
      this.state;
    const { total, per_page, current_page } = this.state.pagination;
    const uploadButton = (
      <div>
        <Icon type="plus" />
        <div className="ant-upload-text">Upload</div>
      </div>
    );

    return (
      <Fragment>
        <div className="dailyStatus-dashboard-container employee">
          {/* page Header */}
          <div className="page-header">
            <div className="page-title">
              <h2 className="title">
                <img
                  className="icon icon-itassets"
                  src={dailyStatus}
                  alt="icon"
                />{" "}
                Work Logs
              </h2>
            </div>
            <Button
              className="btn btn-primary text-uppercase sendReport-btn"
              onClick={this.showDrawer}
            >
              <img className="icon" src={send} alt="send icon" />
              <span>Send Work Logs</span>
            </Button>
          </div>

          {/* page info title */}
          {/* <section className="page-info-box single-searchBar animated slideInRight">
                        <div className="right">
                            <div className="search-box">
                                <Input placeholder="Search" suffix={<Icon type="search" />} />
                            </div>
                        </div>
                    </section> */}

          {loading ? (
            <div className="page-spinner">
              {" "}
              <Spin />
            </div>
          ) : (
            <section className="content-section dailyStatusInfoCard">
              <Collapse
                expandIconPosition={"right"}
                onChange={callback}
                bordered="false"
              >
                {workReports &&
                  workReports.map((workReport, index) => (
                    <Panel
                      key={index}
                      header={
                        <div className="dayWiseTask-block">
                          <div className="headContent">
                            <div className="part">
                              <div className="heading-details fx fx--ai-c">
                                <div
                                  className={`dateDetail-box ${
                                    this.isHolidayOrWeekend(workReport)
                                      ? "disable"
                                      : ""
                                  }`}
                                >
                                  <div className="single">
                                    <div className="month-name heading">
                                      {this.parseDate(
                                        workReport.date,
                                        "weekday"
                                      )}
                                    </div>
                                    <div className="date-content fullDay">
                                      {this.parseDate(
                                        workReport.date,
                                        "dayOfMonth"
                                      )}
                                    </div>
                                  </div>
                                </div>
                                <div className="workStatus-info">
                                  {workReport.id && (
                                    <div className="work-status text-success text-uppercase">
                                      Received
                                    </div>
                                  )}
                                  {!workReport.id &&
                                    !workReport.reason.is_holiday &&
                                    !workReport.reason.is_weekend &&
                                    workReport.reason.on_leave === 0 && (
                                      <div className="work-status text-danger text-uppercase">
                                        Not updated
                                      </div>
                                    )}
                                  {!workReport.id &&
                                    !workReport.reason.is_holiday &&
                                    !workReport.reason.is_weekend &&
                                    workReport.reason.on_leave === 1 && (
                                      <div className="work-status text-warning text-uppercase">
                                        On Leave
                                      </div>
                                    )}
                                  {!workReport.id &&
                                    workReport.reason.is_weekend &&
                                    !workReport.reason.is_holiday && (
                                      <div className="work-status text-warning text-uppercase">
                                        Weekly Off
                                      </div>
                                    )}
                                  {!workReport.id &&
                                    workReport.reason.is_holiday &&
                                    !workReport.reason.is_weekend && (
                                      <div className="work-status text-warning text-uppercase">
                                        Public Holiday (
                                        {workReport.reason.is_holiday})
                                      </div>
                                    )}
                                  {!workReport.id &&
                                    workReport.reason.is_holiday &&
                                    workReport.reason.is_weekend && (
                                      <div className="work-status text-warning text-uppercase">
                                        Weekly Off and{" "}
                                        {workReport.reason.is_holiday}
                                      </div>
                                    )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      }
                    >
                      {workReport.work_reports && (
                        <div className="bodyContent animated fadeIn">
                          {workReport.work_reports.map(
                            (eachWorkReport, innerIndex) => (
                              <Fragment>
                                <div className="workStatus-info">
                                  <div className="work-status-heading">
                                    {eachWorkReport.title}
                                    {eachWorkReport.duration && (
                                      <div className="project-time-spent">
                                        <i>
                                          (Time Consumed:{" "}
                                          {convertMinutesToHoursAndMinutesString(
                                            eachWorkReport.duration
                                          )}
                                          )
                                        </i>
                                      </div>
                                    )}
                                  </div>
                                  <div className="description">
                                    {renderHTML(eachWorkReport.description)}
                                  </div>
                                  {eachWorkReport.images.length != 0 && (
                                    <div className="imgAttachment-box">
                                      <div className="clearfix">
                                        <Upload
                                          action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                                          listType="picture-card"
                                          fileList={this.prepareFileList(
                                            eachWorkReport.images
                                          )}
                                          onPreview={this.handlePreview}
                                          onChange={this.handleChange}
                                          showUploadList={{
                                            showPreviewIcon: true,
                                            showRemoveIcon: false,
                                            showDownloadIcon: false,
                                          }}
                                        >
                                          {/* {fileList.length >= 2 ? null : uploadButton} */}
                                        </Upload>
                                        <Modal
                                          className="viewImgModal"
                                          visible={previewVisible}
                                          footer={null}
                                          onCancel={this.handleCancel}
                                        >
                                          <img
                                            alt="example"
                                            style={{ width: "100%" }}
                                            src={previewImage}
                                          />
                                        </Modal>
                                      </div>
                                    </div>
                                  )}
                                </div>
                                {workReport.work_reports.length - 1 !=
                                  innerIndex && <Divider type="horizontal" />}
                                {workReport.work_reports.length - 1 ==
                                  innerIndex && (
                                  <div className="status-strip-box fx fx--ai-c fx--jc-sb">
                                    {workReport.day_type && (
                                      <div className="dayStatus">
                                        <i>
                                          <b>Day Status: </b>
                                          {workReport.day_type === "full_day"
                                            ? "Full Day"
                                            : "Half Day"}
                                        </i>
                                      </div>
                                    )}
                                    <div className="timestamp">
                                      <i>
                                        <b>Submitted at: </b>
                                        {this.getFormattedDateTime(
                                          workReport.created_at
                                        )}
                                      </i>
                                    </div>
                                  </div>
                                )}
                              </Fragment>
                            )
                          )}
                        </div>
                      )}

                      {this.isWorkReportMissing(workReport) && (
                        <div className="bodyContent animated fadeIn">
                          <div className="workStatus-info">
                            <div className="emptyState">
                              <div className="message mb--20">
                                OOPS, You forgot to send the work status.
                              </div>
                            </div>
                          </div>
                        </div>
                      )}

                      {!this.isWorkReportMissing(workReport) && !workReport.id && (
                        <div className="bodyContent animated fadeIn">
                          <div className="workStatus-info">
                            <div className="emptyState">
                              <div className="message mb--20">
                                Please post your work status if you have worked
                                on this day.
                              </div>
                              {/* <div className="forgotWorkStatus-sendBtn"><Button className="btn btn-light-primary" onClick={this.showDrawer}>Send it now</Button></div> */}
                            </div>
                          </div>
                        </div>
                      )}
                    </Panel>
                  ))}
              </Collapse>
            </section>
          )}

          {/* Pagination */}
          <section className="pagination-container animated slideInRight">
            <div className="pagination-box text-right">
              <Pagination
                className="pagination"
                onChange={this.onPageChange}
                current={current_page}
                total={total}
                itemRender={itemRender}
                pageSize={per_page}
              />
            </div>
          </section>
        </div>

        {/* send work status Drawer */}
        <Drawer
          className="sendWorkReport-drawer navigator-drawer"
          title="Send Work Logs"
          width={500}
          onClose={this.onClose}
          destroyOnClose={true}
          visible={this.state.visible}
          bodyStyle={{ padding: 0 }}
          maskClosable={false}
        >
          <SendWorkReport
            addNewWorkReportPropFunction={(requestBody) => {
              return this.addEmployeeWorkReportFunction(requestBody);
            }}
            loadWorkReports={() => {
              return this.getEmployeeWorkReportsFunction();
            }}
          />
        </Drawer>
      </Fragment>
    );
  }
}

export default withStore(
  RequireAuth(ProfileComplete(NotificationUpdate(EmpDailyStatusDashboard)))
);
