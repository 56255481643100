import React, { Component, Fragment } from "react";
import { Row, Col, notification } from "antd";
import moment from "moment";
import { withStore } from "../../../hocs/withStore";
import RequireAuth from "../../../hocs/RequireAuth";
import _ from 'lodash';
import { axiosInstance as api } from '../../../utilities/configureAxios';
import url from '../../../config/url';

class ViewEmployeeDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            user_roles_name: _.get(props, 'store.data.user_roles_name', []),
            employee_details: null
        };
    }

    static getDerivedStateFromProps(props, state) {
        return {
            employee_details: props.employee_details,
        };
    }
    render() {
        const { employee_details, user_roles_name } = this.state;
        let official_email = this.state.employee_details ? `mailto:${this.state.employee_details.email_official}` : ''
        const fallback = "-";
        return (
            <Fragment>
                {employee_details && (
                    <section className="tab-content-container bank-details-tab">
                        <h3 className="title text-primary">User Employment Details</h3>
                        <form>
                            <Row className="" gutter={30}>
                                <Col className="gutter-row" xs={12} sm={12} md={12} lg={4} xl={4}>
                                    <div className="form-content form-group">
                                        <label className="label">Employee ID</label>
                                        <div className="value">{employee_details.employee_id || fallback}</div>
                                    </div>
                                </Col>
                                <Col className="gutter-row" xs={12} sm={12} md={12} lg={8} xl={8}>
                                    <div className="form-content form-group">
                                        <label className="label">Official Email ID</label>
                                        <div className="value"><a href={official_email}>{employee_details.email_official || fallback}</a></div>
                                    </div>
                                </Col>
                                <Col className="gutter-row" xs={12} sm={12} md={12} lg={8} xl={8}>
                                    <div className="form-content form-group">
                                        <label className="label">Reporting Manager</label>
                                        <div className="value">{employee_details.managers && employee_details.managers[0].name || fallback}</div>
                                    </div>
                                </Col>
                                <Col className="gutter-row" xs={12} sm={12} md={12} lg={4} xl={4}>
                                    <div className="form-content form-group">
                                        <label className="label">Date of Joining</label>
                                        <div className="value">{employee_details.date_of_joining && moment(employee_details.date_of_joining, 'YYYY-MM-DD HH:mm:ss').format('DD-MMM-YYYY') || fallback}</div>
                                    </div>
                                </Col>
                            </Row>
                            <Row className="" gutter={30}>
                                <Col className="gutter-row" xs={12} sm={12} md={12} lg={4} xl={4}>
                                    <div className="form-content form-group">
                                        <label className="label">Employment Type</label>
                                        <div className="value">{employee_details.employment_type || fallback}</div>
                                    </div>
                                </Col>
                                <Col className="gutter-row" xs={12} sm={12} md={12} lg={8} xl={8}>
                                    <div className="form-content form-group">
                                        <label className="label">Employment Sub Type</label>
                                        <div className="value">{employee_details.employment_sub_type || fallback}</div>
                                    </div>
                                </Col>
                                <Col className="gutter-row" xs={12} sm={12} md={12} lg={8} xl={8}>
                                    <div className="form-content form-group">
                                        <label className="label">Designation</label>
                                        <div className="value">{employee_details.designation || fallback}</div>
                                    </div>
                                </Col>
                                <Col className="gutter-row" xs={12} sm={12} md={12} lg={4} xl={4}>
                                    <div className="form-content form-group">
                                        <label className="label">Team</label>
                                        <div className="value">{employee_details.team && employee_details.team.title || fallback}</div>
                                    </div>
                                </Col>
                            </Row>
                            <h3 className="title text-primary lg-hide">Your social profiles</h3>
                            <Row className="" gutter={30}>
                                <Col className="gutter-row" xs={12} sm={12} md={12} lg={4} xl={4}>
                                    <div className="form-content form-group">
                                        <label className="label">Trello Username</label>
                                        <div className="value">{employee_details.trello || fallback}</div>
                                    </div>
                                </Col>
                                <Col className="gutter-row" xs={12} sm={12} md={12} lg={8} xl={8}>
                                    <div className="form-content form-group">
                                        <label className="label">Github Username</label>
                                        <div className="value">{employee_details.github || fallback}</div>
                                    </div>
                                </Col>
                                <Col className="gutter-row" xs={12} sm={12} md={12} lg={8} xl={8}>
                                    <div className="form-content form-group">
                                        <label className="label">Bitbucket Username</label>
                                        <div className="value">{employee_details.bitbucket || fallback}</div>
                                    </div>
                                </Col>
                                <Col className="gutter-row" xs={12} sm={12} md={12} lg={4} xl={4}>
                                    <div className="form-content form-group">
                                        <label className="label">Dribbble Username</label>
                                        <div className="value">{employee_details.dribble || fallback}</div>
                                    </div>
                                </Col>
                                <Col className="gutter-row" xs={12} sm={12} md={12} lg={4} xl={4}>
                                    <div className="form-content form-group">
                                        <label className="label">Twitter Username</label>
                                        <div className="value">{employee_details.twitter || fallback}</div>
                                    </div>
                                </Col>
                                <Col className="gutter-row" xs={12} sm={12} md={12} lg={8} xl={8}>
                                    <div className="form-content form-group">
                                        <label className="label">Facebook URL</label>
                                        <div className="value">{employee_details.facebook || fallback}</div>
                                    </div>
                                </Col>
                                <Col className="gutter-row" xs={12} sm={12} md={12} lg={8} xl={8}>
                                    <div className="form-content form-group">
                                        <label className="label">LinkedIn URL</label>
                                        <div className="value">{employee_details.linkedin || fallback}</div>
                                    </div>
                                </Col>
                                <Col className="gutter-row" xs={12} sm={12} md={12} lg={4} xl={4}>
                                    <div className="form-content form-group">
                                        <label className="label">Instagram Username</label>
                                        <div className="value">{employee_details.instagram || fallback}</div>
                                    </div>
                                </Col>
                                <Col className="gutter-row" xs={12} sm={12} md={12} lg={4} xl={4}>
                                    <div className="form-content form-group">
                                        <label className="label">Blog URL</label>
                                        <div className="value">{employee_details.blog || fallback}</div>
                                    </div>
                                </Col>
                                <Col className="gutter-row" xs={12} sm={12} md={12} lg={8} xl={8}>
                                    <div className="form-content form-group">
                                        <label className="label">Medium URL</label>
                                        <div className="value">{employee_details.medium || fallback}</div>
                                    </div>
                                </Col>
                                <Col className="gutter-row" xs={12} sm={12} md={12} lg={8} xl={8}>
                                    <div className="form-content form-group">
                                        <label className="label">Youtube URL</label>
                                        <div className="value">{employee_details.youtube || fallback}</div>
                                    </div>
                                </Col>
                            </Row>
                            {(user_roles_name.indexOf("admin") != -1 || user_roles_name.indexOf("hr") != -1) && <div className="admin-shown-row">
                                <h3 className="title text-primary">Employee Status</h3>
                                <Row className="admin-shown-row" gutter={30}>
                                    <Col className="gutter-row" xs={24} sm={24} md={24} lg={12} xl={12}>
                                        <Row>
                                            <Col className="" xs={24} sm={24} md={24} lg={8} xl={8}>
                                                <div className="form-content form-group">
                                                    <label className="label">Date of Exit</label>
                                                    <div className="value">{employee_details.date_of_exit && new Date(employee_details.date_of_exit).toDateString() || fallback}</div>
                                                </div>
                                            </Col>
                                            <Col className="" xs={24} sm={24} md={24} lg={8} xl={8}>
                                                <div className="form-content form-group">
                                                    <label className="label">Ex-employee</label>
                                                    <div className="value">{employee_details.is_x ? "Yes" : "No"}</div>
                                                </div>
                                            </Col>
                                            <Col className="" xs={24} sm={24} md={24} lg={8} xl={8}>
                                                <div className="form-content form-group">
                                                    <label className="label">Work log Reminders</label>
                                                    <div className="value">{employee_details.work_report_reminders === 'enabled' ? 'Enabled' : 'Disabled' || fallback}</div>
                                                </div>
                                            </Col>
                                            <Col className="" xs={24} sm={24} md={24} lg={24} xl={24}>
                                                <div className="form-content form-group">
                                                    <label className="label">Exit Notes</label>
                                                    <div className="value">{employee_details.notes || fallback}</div>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </div>}
                        </form>
                    </section>
                )}
            </Fragment>
        )
    }
}
export default RequireAuth(withStore(ViewEmployeeDetails))