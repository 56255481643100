import React from "react";
import { withStore } from "./withStore";
import RequireAuth from "./RequireAuth";
import NewNotification from "../app/NotificationManagement/components/NewNotification";

export default (ChildComponnent) => {
  const NotificationUpdate = (props) => {
    const notificationData = JSON.parse(localStorage.getItem('notificationData'));
    const currentUrl = window.location.href
    if (notificationData && notificationData.code === 406 && notificationData.errors.message === "Unread notification found.") {
          return (
              <>
            <NewNotification NewNotificationData={notificationData.data} currentUrl={currentUrl}/>
          </>
          );

    } else {
      return <ChildComponnent {...props} />;
    }
  };

  return withStore(RequireAuth(NotificationUpdate));
};
