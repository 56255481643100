import React, { Component, Fragment } from "react";
import { Input, Row, Pagination, Icon, Spin, notification,Button,Modal } from "antd";
import TeamDirectory from "./components/TeamDirectory";
import { Helmet } from 'react-helmet'
import teamDirectory from '../../assets/images/icons/sibebar/team-directory.svg';
import { getPaginatedTeamDirectoryList } from './DataManager';
import FilterEmployee from "../admin/components/FilterEmployee";
import _ from "lodash";
import { axiosInstance as api } from "../../utilities/configureAxios";
import url from "../../config/url";
import ProfileComplete from "../../hocs/ProfileComplete";
import NotificationUpdate from "../../hocs/NotificationUpdate";
import { withStore } from "../../hocs/withStore";
import RequireAuth from "../../hocs/RequireAuth";


const { Search } = Input;
const TITLE = 'Team directory'

// Pagination
function itemRender(current, type, originalElement) {
  if (type === 'prev') {
    return <a className="back-icon"> <Icon type="left" /> Back</a>;
  }
  if (type === 'next') {
    return <a className="next-icon">Next <Icon type="right" /></a>;
  }
  return originalElement;
}

class TeamDirectoryManagement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      total: 0,
      per_page: 1,
      current_page: 1,
      query: '',
      filter_skill: '',
      filter_level: '',
      filter_hobby:"",
      filter_team: '',
      employment_type:"",
      filter_is_x: true,
    }
  }
  componentDidMount() {
    this.getTeamList();
  }

  getTeamList = (page = 1, query = '') => {
    this.setState({ loading: true })
    getPaginatedTeamDirectoryList(page, query)
      .then(res => {

        this.setState({
          data: res.data,
          total: res.meta.pagination.total,
          per_page: res.meta.pagination.per_page,
          current_page: res.meta.pagination.current_page,
          loading: false
        });
      })
      .catch(error => {
        notification['error']({
          message: "Oops! Something went wrong!"
        });
        this.setState({ loading: false });
      })
  }

  onPageChange = (pageNumber) => {
    this.setState({ loading: true });
    getPaginatedTeamDirectoryList(pageNumber, this.state.query, this.state)
      .then(res => {
        this.setState({
          data: res.data,
          total: res.meta.pagination.total,
          per_page: res.meta.pagination.per_page,
          current_page: res.meta.pagination.current_page,
          loading: false
        });
      })
      .catch(error => {
        notification['error']({
          message: "Oops! Something went wrong!"
        });
        this.setState({ loading: false });
      })
  }

  onChange = (e) => {
    this.setState({ query: e.target.value })
    this.getTeamList(1, e.target.value);
  }

// for filter modal
state = {
  modal1Visible: false,
};
setModal1Visible(modal1Visible) {
  this.setState({ modal1Visible });
}

setFilter = (value, form) => {
  this.setState({ loading: false })
  this.setModal1Visible(false);
  this.setState({ loading: true });
  let filter_skill = _.get(value, 'skill', '');
  let filter_level = _.get(value, 'level', '');
  let filter_hobby = _.get(value, "hobby", "");
  let filter_team = _.get(value, 'team', '');
  let filter_is_x = _.get(value, 'is_x', '');
  let employment_type = _.get(value, "employment_type", "");
  this.setState({ filter_skill, filter_level,filter_hobby,filter_team, filter_is_x, employment_type });
  const empType =employment_type !== '' ? `&employment_type=${employment_type}` : '';

  api
    .get(
      `${url['getEmployees']}?paginate=true&hidden_employees=${filter_is_x}&page=1&q=${''}&skill=${filter_skill}&hobbies=${filter_hobby}&level=${filter_level}&team=${filter_team}${empType}`
    )
    .then(res => {
      form.resetFields();
      this.setState({
        data: res.data.data,
        total: res.data.meta.pagination.total,
        per_page: res.data.meta.pagination.per_page,
        current_page: res.data.meta.pagination.current_page,
        loading: false
      })

    })
    .catch(error => {
      notification['error']({
        message: "Oops! Something went wrong!"
      });
      this.setState({ loading: false });
    })
};

resetFilter = (page = 1, query = '') => {
  this.setState({ loading: true });
  getPaginatedTeamDirectoryList(page, query)
      .then(res => {

        this.setState({
          data: res.data,
          total: res.meta.pagination.total,
          per_page: res.meta.pagination.per_page,
          current_page: res.meta.pagination.current_page,
          filter_skill: '',
          filter_level: '',
          filter_team: '',
          filter_hobby:'',
          employment_type:'',
          filter_is_x: true,
          loading: false
        });
      })
      .catch(error => {
        notification['error']({
          message: "Oops! Something went wrong!"
        });
        this.setState({ loading: false });
      })
};
  
  render() {
    let { data, total, per_page, current_page, loading,employment_type } = this.state


    return (
      <div>

        <Fragment>
          <Helmet>
            <title>{TITLE}</title>
          </Helmet>
          <div className="page-wrapper">
            {/* page Header */}
            <Fragment>
              <div className="page-header">
                <div className="page-title">
                  <h2 className="title"><img className="icon icon-view" src={teamDirectory} alt="view icon" /> Team Directory</h2>
                </div>
              </div>
            </Fragment>


            <Fragment>
              {/* page info title */}
              <section className="page-info-box">
                <h3 className="info-title">All Employees</h3>
                <div className="right">
                {(this.state.filter_level ||
                        this.state.filter_skill ||
                        this.state.filter_team ||
                        this.state.filter_hobby ||
                        this.state.employment_type ||
                        this.state.filter_is_x == false) && (
                        <Button
                          className="btn btn-danger text-uppercase"
                          onClick={this.resetFilter}
                        >
                          <Icon type="close" /> Reset
                        </Button>
                      )}
                      <Button
                        className="btn btn-warning text-uppercase"
                        onClick={() => this.setModal1Visible(true)}
                      >
                        <Icon type="filter" /> Filter
                      </Button>
                  <div className="search-box">
                    <Input 
                      allowClear={true}
                      placeholder="Search"
                      onChange={this.onChange}
                      suffix={<Icon type="search" />} />
                  </div>
                </div>
              </section>


              {loading ? (<div className="page-spinner" > <Spin /></div>) : (
                <section>
                  <div className="teamDirectory-container">
                    {/* new layout for team directory */}
                    <section className="content-section team-cardView-container">
                      <Row gutter={30}>
                        {data.map((row, index) => (
                          <TeamDirectory data={row} key={index} />
                        ))}
                      </Row>
                    </section>
                  </div>



                  {data.length != 0 && <section className="pagination-container">
                    <div className="pagination-box text-right">
                      <Pagination className="pagination" onChange={this.onPageChange} current={current_page} total={total} itemRender={itemRender} pageSize={per_page} />
                    </div>
                  </section>
                  }

                </section>
              )}

            </Fragment>

            {/* filter Modal */}
          <Modal
            className="filter-modal profileAp-modal"
            title="Filter Employee"
            centered
            visible={this.state.modal1Visible}
            onOk={() => this.setModal1Visible(false)}
            onCancel={() => this.setModal1Visible(false)}
            footer={null}
          >
            <div className="filter-employee-modal-container">
              <FilterEmployee
                handleSubmit={this.setFilter}
                filter_skill={this.state.filter_skill}
                filter_level={this.state.filter_level}
                filter_hobby = {this.state.filter_hobby}
                filter_team={this.state.filter_team}
                employment_type={employment_type}
                filter_is_x={this.state.filter_is_x}
              />
            </div>
          </Modal>

          </div>
        </Fragment>

      </div>
    );
  }

};
export default withStore(RequireAuth(ProfileComplete(NotificationUpdate(TeamDirectoryManagement))));
