import React, { Component, Fragment } from "react";
import { Icon, Row, Col, Card, Rate } from "antd";
import skill_image from '../../../assets/images/icons/profile/skills/skill.svg';
import RequireAuth from '../../../hocs/RequireAuth';
import { withStore } from '../../../hocs/withStore';

class ViewTechnicalSkills extends Component {
    constructor(props) {
        super(props);
        this.state = {
            user_skills: null
        };
    }

    static getDerivedStateFromProps(props, state) {
        return {
            user_skills: props.user_skills,
        };
    }
    render() {
        const { user_skills } = this.state;
        return (
            <Fragment>
                {/* Technical skills cards */}
                <section className="content-section skills-card-section">
                    <h3 className="title text-primary">Technical Skills</h3>
                    <Row className="" gutter={30}>
                        {user_skills && user_skills.map(user_skill => (
                            <Col className="gutter-row" xs={24} sm={24} md={24} lg={12} xl={12} xxl={8}>
                                <Card className="card card-shadow skills-card">
                                    <div className="skill-icon-box">
                                        <img className="icon icon-link" src={skill_image} alt={user_skill.skill_name} />
                                    </div>
                                    <div className="skill-title-box">
                                        <h4 className="title">{user_skill.skill_name}</h4>
                                        <Rate className="rating" value={user_skill.self_rating} />
                                    </div>
                                </Card>
                            </Col>
                        ))}
                        {!user_skills || user_skills.length == 0 && (
                            <Col className="gutter-row" xs={24} sm={24} md={24} lg={12} xl={12} xxl={8}>
                                <Card className="card card-shadow skills-card">
                                    <h3>No skills found! 😟</h3>
                                </Card>
                            </Col>
                        )}
                    </Row>
                </section>

            </Fragment>
        )
    }
}
export default RequireAuth(withStore(ViewTechnicalSkills))