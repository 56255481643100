import * as APIs from '../constants/BackendConstant';
import { axiosInstance } from '../utilities/configureAxios'

// SKILL Service starts
export const addOrgSkillService = (body) => {
    return axiosInstance.post(APIs.ADD_ORG_SKILL, body);
}

export const updateOrgSkillService = (slug, body) => {
    let url = APIs.EDIT_ORG_SKILL.replace(":slug", slug);
    return axiosInstance.post(url, body);
}

export const deleteOrgSkillService = (slug) => {
    let url = APIs.DELETE_ORG_SKILL.replace(':slug', slug)
    return axiosInstance.delete(url)
}

export const getOrgSkillService = (pageNumber, paginate, q) => {
    let url = APIs.GET_ORG_SKILL.replace(":page", pageNumber);
    url = url.replace(':paginate', paginate);
    url = url.replace(':q', q);
    return axiosInstance.get(url)
} // SKILL Service ends


// TEAM Service starts
export const getOrgTeamService = (pageNumber) => {
    let url = APIs.GET_ORG_TEAM.replace(":page", pageNumber);
    return axiosInstance.get(url)
}

export const addOrgTeamService = (body) => {
    return axiosInstance.post(APIs.ADD_ORG_TEAM, body)
}

export const deleteOrgTeamService = (slug) => {
    let url = APIs.DELETE_ORG_TEAM.replace(':slug', slug)
    return axiosInstance.delete(url)
} // TEAM Service ends


//LEVEL Service starts
export const getOrgLevelService = (pageNumber) => {
    let url = APIs.GET_ORG_LEVEL.replace(":page", pageNumber);
    return axiosInstance.get(url)
}

export const addOrgLevelService = (body) => {
    return axiosInstance.post(APIs.ADD_ORG_LEVEL, body)
}

export const deleteOrgLevelService = (slug) => {
    let url = APIs.DELETE_ORG_LEVEL.replace(':slug', slug)
    return axiosInstance.delete(url)
}

// PROJECT Service starts
export const getOrgProjectService = (pageNumber, bulk) => {
    let url = APIs.GET_ORG_PROJECT.replace(":page", pageNumber);
    url = url.replace(":bulk", bulk);
    return axiosInstance.get(url)
}

export const addOrgProjectService = (body) => {
    return axiosInstance.post(APIs.ADD_ORG_PROJECT, body)
}

export const deleteOrgProjectService = (uuid) => {
    let url = APIs.DELETE_ORG_PROJECT.replace(':uuid', uuid)
    return axiosInstance.delete(url)
} // PROJECT Service ends

// Hobbies Service starts
export const addHobbyService = (body) => {
    return axiosInstance.post(APIs.ADD_HOBBY, body);
}

export const updateHobbyService = (slug, body) => {
    let url = APIs.EDIT_HOBBY.replace(":slug", slug);
    return axiosInstance.post(url, body);
}

export const deleteHobbyService = (slug) => {
    let url = APIs.DELETE_HOBBY.replace(':slug', slug)
    return axiosInstance.delete(url)
}

export const getHobbyService = (pageNumber, paginate, q) => {
    let url = APIs.GET_HOBBY.replace(":page", pageNumber);
    url = url.replace(':paginate', paginate);
    url = url.replace(':q', q);
    return axiosInstance.get(url)
} // Hobbies Service ends





