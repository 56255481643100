import React from "react";
import { Card, notification, Modal, Button,Spin } from "antd";
import "./reactQuillCustomization.scss";
import { handleNotificationStatus } from "../../admin/components/LeaveManagement/DataManager";

const NewNotification = (props) => {
  const NewNotificationData = props.NewNotificationData;
  const currentUrl = props.currentUrl
  const [currentNotificationIndex, setCurrentNotificationIndex] =
    React.useState(0);
    const [newNotificationLoading, setNewNotificationLoading] = React.useState(false);

  const handleCurrentNotification = (id) => {
    setNewNotificationLoading(true);
    return handleNotificationStatus(id)
      .then((res) => {
        setNewNotificationLoading(false);
      })
      .catch((error) => {
        notification.error(error.response.data.errors);
      });
  };

  const newNotificationSubmit = (id) => {
    handleCurrentNotification(id);
    localStorage.setItem("notificationData", null);
    if (currentNotificationIndex === NewNotificationData.length - 1) {
      window.location=currentUrl
    }
  };

  const agreeSyntax = () => {
    if (
      currentNotificationIndex === NewNotificationData.length - 1 &&
      NewNotificationData.length === 1
    ) {
      return "I Agree";
    } else if (currentNotificationIndex === NewNotificationData.length - 1) {
      return "I Agree & Finish";
    } else {
      return "Agree & Next";
    }
  };

  React.useEffect(()=>{
    notification["info"]({
      message: "New Announcement!",
      description: "You have new announcement.",
    });
  },[])


  return (
      <Modal
        className="newNotificationModal"
        style={{ minWidth: "800px !important" }}
        title={
            <div>
              {NewNotificationData[currentNotificationIndex].title}
              {"(" +
                (currentNotificationIndex + 1) +
                "/" +
                NewNotificationData.length +
                ")"}
            </div>
        }
        visible={
          NewNotificationData &&
          NewNotificationData.length > 0 &&
          currentNotificationIndex <= NewNotificationData.length - 1 
        }
        closable={false}
        footer={
          [
                <Button
                  className="btn-primary"
                  onClick={() => {
                    newNotificationSubmit(
                      NewNotificationData[currentNotificationIndex].id
                    );
                    setCurrentNotificationIndex(currentNotificationIndex + 1);
                  }}
                >
                  {agreeSyntax()}
                </Button>,
              ]
        }
      >
        <Card>
          {newNotificationLoading ? (
                <div className="page-spinner-notification">
                  <Spin />
                </div>
              ) : (
          
          NewNotificationData &&
            NewNotificationData.length > 0 &&
            currentNotificationIndex < NewNotificationData.length && (
              <div
                dangerouslySetInnerHTML={{
                  __html:
                    NewNotificationData[currentNotificationIndex].description,
                }}
              />
            )
  )}
        </Card>
      </Modal>
    
  );
};
export default NewNotification;
