import moment from "moment";
import React, { Component, Fragment } from "react";
import { Icon, Upload, Modal } from "antd";
import Flickity from 'react-flickity-component';

// For Upload
function getBase64(file) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });
}

export default class CardContent extends Component {

    constructor(props) {
        super(props)
        this.state = {
            assets: null,
            previewVisible: false,
            previewImage: '',
            imageModalImage: null,
            visible: false, // FOR DRAWER
            fileList: [
                {
                    uid: '-1',
                    name: 'image.png',
                    status: 'done',
                    url: 'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png',
                },
                {
                    uid: '-2',
                    name: 'image.png',
                    status: 'done',
                    url: 'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png',
                },
            ],
        }
    }

    componentDidMount() {
        this.getAssetsContent()
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.assetContent != this.props.assetContent) {
            this.setState({ assets: this.props.assetContent })
        }
    }


    showDrawer = () => {
        this.setState({
            visible: true,
        });
    };

    onClose = () => {
        this.setState({
            visible: false,
        });
    };

    // For Upload
    state = {

    };

    handleCancel = () => this.setState({ previewVisible: false });

    handlePreview = async file => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }

        this.setState({
            previewImage: file.url || file.preview,
            previewVisible: true,
            imageModalImage: 0
        });


    };

    handleChange = ({ fileList }) => this.setState({ fileList });

    getAssetsContent() {
        let asset = this.props.assetContent
        this.setState({ assets: asset })
    }

    showImageModal = (index) => {
        //this.setModal1Visible(true)
        this.setState({ imageModalImage: index })
    }

    render() {

        // for Upload
        const { previewVisible, previewImage, fileList, assets } = this.state;
        const uploadButton = (
            <div>
                <Icon type="plus" />
                <div className="ant-upload-text">Add Photo</div>
            </div>
        );

        return (
            <Fragment>
                <div className="cardContent-container">
                    <div className="cardContent-body">
                        <div className="cardContent-flexView">
                            <div className="notes-content-block">
                                <div className="column">
                                    <div className="notes-box">
                                        <p className="notes"><strong className="text-uppercase">Notes: </strong>{assets && assets.notes}.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="assets-details-content-block">

                                <div className="column">
                                    <div className="assets-info form-group">
                                        <label className="label">Purchase Date:</label>
                                        <div className="value"> {assets && assets.purchase_date ? moment(assets.purchase_date, 'YYYY-MM-DD HH:mm:ss').format('DD-MMM-YYYY') : '-'}</div>
                                    </div>
                                    <div className="assets-info form-group">
                                        <label className="label">Warranty Exp.:</label>

                                        {assets && assets.warranty_exp_date ? moment(assets.warranty_exp_date, 'YYYY-MM-DD HH:mm:ss') <= new Date()
                                            ?
                                            <div className="value text-danger"> {moment(assets.warranty_exp_date, 'YYYY-MM-DD HH:mm:ss').format('DD-MMM-YYYY')} </div>
                                            :
                                            <div className="value"> {moment(assets.warranty_exp_date, 'YYYY-MM-DD HH:mm:ss').format('DD-MMM-YYYY')} </div>
                                            :
                                            <div className="value "> - </div>
                                        }
                                    </div>

                                    <div className="assets-info form-group">
                                        <label className="label">Asset type:</label>
                                        <div className="value">{assets && assets.type.name}</div>
                                    </div>
                                </div>
                                <div className="column">
                                    <div className="assets-info form-group">
                                        <label className="label">Allocated At:</label>
                                        <div className="value">{assets && assets.allocations && moment(assets.allocations[0].assigned_at, 'YYYY-MM-DD HH:mm:ss').format('DD-MMM-YYYY H:mm')}</div>
                                    </div>
                                    <div className="assets-info form-group">
                                        <label className="label">Serial No:</label>
                                        <div className="value">{assets && assets.serial_number ? assets.serial_number : '-'}</div>
                                    </div>
                                    <div className="assets-info form-group">
                                        <label className="label">Status:</label>
                                        {assets && Object.values(assets.status)[0] == 'Active' ?
                                            <div className="status-badge success">{assets && Object.values(assets.status)[0]}</div>
                                            :
                                            <div className="status-badge danger">{assets && Object.values(assets.status)[0]}</div>}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="cardContent-flexView">
                            <div className="uploadImage-block">
                                <div className="clearfix">
                                    <Upload
                                        action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                                        listType="picture-card"
                                        fileList={assets && assets.images}
                                        onPreview={this.handlePreview}
                                        onChange={this.handleChange}
                                        showUploadList={{ showPreviewIcon: true, showRemoveIcon: false, showDownloadIcon: false }}
                                    >
                                        {null}
                                    </Upload>
                                    <Modal className="previewImg-modal" visible={previewVisible} footer={null} onCancel={this.handleCancel}>
                                        <Flickity
                                            className={'carousel'}
                                            elementType={'div'}
                                            options={{
                                                initialIndex: this.state.imageModalImage
                                            }}
                                        >
                                            {assets && assets.images.map((image, index) => (
                                                <div className="carousel-img" style={{ backgroundImage: `url(${image.url})` }} />
                                            ))}
                                        </Flickity>
                                    </Modal>
                                </div>
                            </div>
                            <div className="qrCode-block">
                                <div className="column">
                                    <div className="asset-info">
                                        <div className="qr-code" style={{ backgroundImage: `url(${assets && assets.qr_code})` }}></div>
                                    </div>
                                </div>
                            </div>


                            {/* <PrintComponents
                                trigger={
                                    <div className="asset-info double-width qr-code-block" taget="_blank">
                                        <div className="qr-code" style={{ backgroundImage: `url(${assets && assets.qr_code})` }}></div>
                                        <div className="qr-code-info">
                                            <div className="internal-code text-uppercase">{assets && assets.reference_id}</div>
                                            <div className="powered-by">{assets && assets.organization}</div>
                                            <Tooltip title="Print" placement="bottom"><Button className="btn btn-icon btn-warning print-btn"><Icon type="printer" /></Button></Tooltip>
                                        </div>
                                    </div>
                                }
                            > */}


                            {/* <div className="asset-info double-width qr-code-block" style={{ width: '100%' }}>
                                    <table>
                                        <tr>
                                            <td>
                                                <div style={{ textAlign: 'center', }}>
                                                    <img src={assets && assets.qr_code} alt="img" width="125" height="125" />
                                                    <div style={{ fontSize: '16px', fontWeight: "600", textTransform: 'uppercase', }}>{assets && assets.reference_id}</div>
                                                    <div style={{ fontSize: '10px', }}>{assets && assets.organization}</div>
                                                    <div style={{ fontSize: '10px', }}>Squareboat Solutions Pvt. Ltd.</div>
                                                </div>
                                            </td>
                                        </tr>
                                    </table>
                                </div> */}

                            {/* <div className="asset-info double-width qr-code-block" style={{ border: '1px dashed #d9d9d9', borderRadius: '4px', width: '100%' }}>
                                    <table>
                                        <tr>
                                            <td>
                                                <img src={assets && assets.qr_code} alt="img" width="125" height="125" />
                                            </td>
                                            <td style={{ verticalAlign: 'top', }}>
                                                <div style={{ fontSize: '20px', fontWeight: "600", textTransform: 'uppercase', }}>{assets && assets.reference_id}</div>
                                                <div style={{ textTransform: 'uppercase', fontSize: '12px' }}>{assets && assets.organization}</div>
                                            </td>
                                        </tr>
                                    </table>
                                </div> */}
                            {/* </PrintComponents> */}

                        </div>
                    </div>
                </div>
            </Fragment >
        )
    }
}