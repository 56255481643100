import React from "react";
import {
  Row,
  Col,
  Input,
  Form,
  Button,
  Select,
  Checkbox,
  InputNumber,
  notification,
  DatePicker,
  Spin,
} from "antd";

import _ from "lodash";

import {
  assignLeaveToUser,
  getListOfAssignLeaveType,
  getOrganizationEmployeesCurrent,
} from "../../DataManager";
import moment from "moment";

const AddLeave = (props) => {
  const { setModalVisible, setDataUpdate } = props;
  const [daySyntex, setDaySyntex] = React.useState("Days");
  // FORM DATA
  const [formData2, setFormData2] = React.useState({
    leaveTypeId: undefined,
    userIds: [],
    o_id: 1,
    startDate: moment().startOf("year").format("YYYY-MM-DD"),
    endDate: moment().endOf("year").format("YYYY-MM-DD"),
    quantity: null,
    unlimitedLeaves: false,
  });

  const getDateDifference = (date1, date2) => {
    const time1 = new Date(date1).getTime();
    const time2 = new Date(date2).getTime();
    const Difference_In_Time = time2 - time1;
    const Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
    return Difference_In_Days + 1;
  };

  const [allLeaveNames, setAllLeaveNames] = React.useState([]);
  const [showDate, setShowDate] = React.useState(true);
  const [allUser, setAllUser] = React.useState([]);
  const [disableCheckbox, setDisableCheckbox] = React.useState(false);
  const [visible, setVisible] = React.useState(false);
  const [dateDifference, setDateDifference] = React.useState(
    getDateDifference(formData2.startDate, formData2.endDate)
  );

  const { Option } = Select;
  const { RangePicker } = DatePicker;
  const { getFieldDecorator, getFieldError, setFields, validateFields } =
    props.form;

  // HANDLING LEAVE TYPE
  const handleLeaveTypeChange = (value) => {
    setFields({
      leaveTypeId: {
        value: value,
        errors: undefined,
      },
    });
    setFormData2((prevData) => {
      return {
        ...prevData,
        leaveTypeId: value,
      };
    });
  };

  // handle Quantity
  const handleQuantityChange = (value) => {
    setFields({
      totalDays: {
        value: value,
        errors: undefined,
      },
    });
    setFormData2((prevData) => {
      return {
        ...prevData,
        quantity: value,
      };
    });
  };

  // HANDLE DATE CHANGE
  const handleDateChange = (dates, dateStrings) => {
    const [date1String, date2String] = dateStrings;
    setDisableCheckbox(false);
    let Difference_In_Days = getDateDifference(date1String, date2String);
    setDateDifference(Difference_In_Days);
    Difference_In_Days === 1 ? setDaySyntex("Day") : setDaySyntex("Days");
    setFormData2((prevValue) => {
      return {
        ...prevValue,
        startDate: date1String,
        endDate: date2String,
        unlimitedLeaves: false,
      };
    });
  };

  // HANDLING UNLIMITED LEAVES
  const handleUnlimitedLeaves = (e) => {
    const checked = e.target.checked;
    if (checked) {
      const date1 = new Date(formData2.startDate);
      const date2 = new Date(formData2.endDate);
      const diff = date1.getTime() - date2.getTime();
      const dayDiff = Math.abs(diff / (1000 * 60 * 60 * 24));
      setFormData2((prevData) => {
        return {
          ...prevData,
          quantity: dayDiff,
          unlimitedLeaves: checked,
        };
      });
      handleQuantityChange(dayDiff);
    }
    setFormData2((prevData) => {
      return {
        ...prevData,
        unlimitedLeaves: checked,
      };
    });
  };

  // FETCHING ALL EMPLOYEES NAMES
  const loadOrganizationEmployees = () => {
    return getOrganizationEmployeesCurrent()
      .then((res) => {
        setAllUser(res.data);
      })
      .catch((error) => {
        notification.error(error.response.data.errors);
      });
  };

  // For getting user Names List
  React.useEffect(() => {
    loadOrganizationEmployees();
  }, []);

  // HANDLING USER NAME
  const handleUserChange = (value) => {
    setFormData2((prevData) => {
      return {
        ...prevData,
        userIds: value,
      };
    });
  };

  const handleSave = (e) => {
    validateFields((error) => {
      if (formData2.startDate === "" && formData2.endDate === "") {
        setVisible(false);
        return false;
      }
      if (formData2.userIds.length === 0) {
        setVisible(false);
        return false;
      }
      if (formData2.quantity === null) {
        setVisible(false);
        return false;
      }
      if (formData2.quantity > 365) {
        notification.error({
          message: "The leave counts must not be greater than 365",
        });
        setVisible(false);
        return false;
      }
      if (!error) {
        setVisible(true);
        assignLeaveToUser({
          leave_type_id: formData2.leaveTypeId,
          user_ids: formData2.userIds,
          o_id: 1,
          start_date: formData2.startDate,
          end_date: formData2.endDate,
          leave_counts: formData2.quantity,
        })
          .then((res) => {
            notification.success({
              message: res.data.message,
            });
            setVisible(false);
            setModalVisible(false);
            setDateDifference(0);
            setDataUpdate(true);
          })
          .catch((error) => {
            setDateDifference(0);
            if (error.response.status === 422) {
              let errors = error.response.data.errors;
              let errorObject = {};
              Object.keys(errors).forEach((key) => {
                let err = [];
                errors[key].forEach((value) => {
                  err.push(new Error(value));
                });
                key = _.camelCase(key);
                errorObject[key] = { errors: err };
              });
              setVisible(false);
              setFields(errorObject);
            } else {
              setVisible(false);
              notification.error(error.response.data.errors);
            }
          });
      }
    });
  };

  // FETCHING ALL LEAVE TYPE NAMe
  const getLeaveNames = () => {
    getListOfAssignLeaveType({ o_id: 1 })
      .then((res) => {
        setAllLeaveNames(res.data);
      })
      .catch((error) => {
        notification.error(error.response.data.errors);
      });
  };

  React.useEffect(() => {
    getLeaveNames();
  }, []);

  const leaveSelectTypeError = getFieldError("leaveTypeId");

  return (
    <>
      {visible && (
        <div className="center-me" style={{ height: "700px" }}>
          <Spin />
        </div>
      )}
      {!visible && (
        <div className="applyLeave-container">
          <form>
            {/* SELCET LEAVE TYPE */}
            <div className="form-content leaveType-cont">
              <Row className="row">
                <Col
                  className="col"
                  xs={24}
                  sm={24}
                  md={24}
                  lg={24}
                  xl={24}
                  xxl={24}
                >
                  <div className="form-group">
                    <Form.Item
                      name="leaveTypeId"
                      label={"Leave Type"}
                      colon={false}
                      validateStatus={
                        leaveSelectTypeError ? "error" : undefined
                      }
                    >
                      {getFieldDecorator("leaveTypeId", {
                        initialValue: formData2.leaveTypeId
                          ? formData2.leaveTypeId
                          : undefined,
                        rules: [
                          {
                            required: true,
                            message: "Select leave",
                          },
                        ],
                      })(
                        <div className="leaveType-block">
                          <div className="selectbox">
                            <Select
                              value={formData2.leaveTypeId}
                              name="leaveTypeId"
                              showSearch
                              placeholder="Select Leave Type"
                              onChange={handleLeaveTypeChange}
                              optionLabelProp="label"
                              optionFilterProp="children"
                              className="leaveType-selectbox"
                              filterOption={(input, option) =>
                                option.props.label
                                  .toLowerCase()
                                  .indexOf(input.toLowerCase()) >= 0
                              }
                            >
                              {allLeaveNames.map((leave) => {
                                return (
                                  <Option
                                    key={leave.id}
                                    value={leave.id}
                                    label={leave.name}
                                  >
                                    {leave.name}
                                  </Option>
                                );
                              })}
                            </Select>
                          </div>
                        </div>
                      )}
                    </Form.Item>
                  </div>
                </Col>
              </Row>
            </div>

            {/* SELECT DATES FROM CALENDER */}
            <div className="form-content leaveType-cont">
              <Row className="row">
                <Form.Item colon={false} name="datepicker">
                  <div>
                    <label
                      className="myCustomLabel"
                      htmlFor="isApplyingImmediately"
                    >
                      Applicable For This Year
                    </label>
                    <Checkbox
                      checked={showDate}
                      onChange={(e) => {
                        setShowDate(e.target.checked);
                      }}
                    ></Checkbox>
                  </div>
                  {!showDate &&
                    getFieldDecorator("datepicker", {
                      initialValue: [
                        moment(formData2.startDate),
                        moment(formData2.endDate),
                      ],
                    })(
                      <RangePicker
                        style={{ marginTop: "1rem" }}
                        allowClear={false}
                        onChange={handleDateChange}
                        separator={
                          <Input
                            placeholder={
                              isNaN(dateDifference)
                                ? "0 Days"
                                : dateDifference + " " + daySyntex
                            }
                            disabled
                            style={{
                              right: "40%",
                              left: "32%",
                              position: "absolute",
                              margin: "0 auto",
                              height: "100%",
                              width: "85px",
                              top: "0",
                            }}
                          />
                        }
                      />
                    )}
                </Form.Item>
              </Row>
            </div>

            {/* TOTAL QUANTITY */}
            <div className="form-content leaveType-cont">
              <Row className="row">
                <Form.Item label="Quantity" name="totalDays">
                  {getFieldDecorator("totalDays", {
                    initialValue: formData2.quantity
                      ? formData2.quantity
                      : null,
                    rules: [
                      {
                        required: true,
                        message: "Enter leave quantity",
                      },
                    ],
                  })(
                    <InputNumber
                      className="input"
                      name="totalDays"
                      step={0.5}
                      max={365}
                      onChange={handleQuantityChange}
                    />
                  )}
                </Form.Item>
              </Row>
            </div>

            {/* UNLIMITED LEAVES*/}
            <div className="form-content leaveType-cont">
              <Row className="row">
                <Form.Item>
                  <label
                    className="myCustomLabel"
                    htmlFor="isApplyingImmediately"
                  >
                    Unlimited Leaves
                  </label>
                  <Checkbox
                    checked={formData2.unlimitedLeaves}
                    disabled={disableCheckbox}
                    onChange={handleUnlimitedLeaves}
                  ></Checkbox>
                </Form.Item>
              </Row>
            </div>

            {/* Select User */}
            <div className="form-content leaveType-cont">
              <Row className="row">
                <Col
                  className="col"
                  xs={24}
                  sm={24}
                  md={24}
                  lg={24}
                  xl={24}
                  xxl={24}
                >
                  <Form.Item name="userIds" label={"User Name"} colon={false}>
                    {getFieldDecorator("userIds", {
                      rules: [
                        {
                          required: true,
                          message: "select a user",
                        },
                      ],
                    })(
                      <Select
                        value={formData2.userIds}
                        name="userName"
                        className="leaveType-selectbox"
                        mode="multiple"
                        showSearch
                        placeholder="Select User Name"
                        onChange={handleUserChange}
                        optionLabelProp="label"
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.props.label
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {allUser.map((user) => {
                          return (
                            <Option
                              key={user.id}
                              value={user.uuid}
                              label={user.name}
                            >
                              <div
                                className="demo-option-label-item"
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                }}
                              >
                                {user.name}
                                <i>
                                  {"("}
                                  {user.employee_id}
                                  {")"}
                                </i>
                              </div>
                            </Option>
                          );
                        })}
                      </Select>
                    )}
                  </Form.Item>
                </Col>
              </Row>
            </div>
          </form>
        </div>
      )}

      <div className="formAction-footer-cont">
        <div className="formAction-footer text-right">
          <Button
            onClick={handleSave}
            className="btn btn-wide btn-success apply-btn text-uppercase"
            disabled={visible}
          >
            Apply 
          </Button>
        </div>
      </div>
    </>
  );
};

export default Form.create()(AddLeave);
