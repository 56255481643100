import { allocateDeallocateAssetService } from '../../../../../services/AssetService'
import { getExistingTeamDirectoryListServiceCurrentDisable, getTeamDirectoryListService } from "../../../../../services/TeamDirectoryService";

export const getOrganizationEmployees = () => {
    return getTeamDirectoryListService()
        .then(result => {
            return result.data
        })
        .catch(error => {
            throw error
        })
}
export const getOrganizationEmployeesDisable = () => {
    return getExistingTeamDirectoryListServiceCurrentDisable()
        .then(result => {
            return result.data
        })
        .catch(error => {
            throw error
        })
}

export const allocatedDeallocateAsset = (id, body, qr_scan = false) => {

    return allocateDeallocateAssetService(id, body, qr_scan)
        .then(result => {
            return result.data
        })
        .catch(error => {
            throw error
        })
}