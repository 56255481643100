import * as APIs from '../src/constants/BackendConstant';
import firebase from 'firebase';
import uuid from 'uuid/v4';
import axios from 'axios';
import "firebase/messaging";
import { axiosInstance } from './utilities/configureAxios'


const initializeFirebase = () => {

    var firebaseConfig = {
        apiKey: "AIzaSyCjVyg1OwkmZrRkjvXgiEBJrU67tvLFHVU",
        authDomain: "navigator-247109.firebaseapp.com",
        databaseURL: "https://navigator-247109.firebaseio.com",
        projectId: "navigator-247109",
        storageBucket: "navigator-247109.appspot.com",
        messagingSenderId: "1012611364960",
        appId: "1:1012611364960:web:c58d6a86a6f12a1c1ceb05",
        measurementId: "G-WBDKT0W34R"
    };
    // Initialize Firebase
    firebase.initializeApp(firebaseConfig);


    // Add the public key generated from the console here.
    const messaging = firebase.messaging();
    messaging.usePublicVapidKey("BCibVqvgEyo-mjiJ094mDxDy2hQoi6M0NuXda2pNF0GHxkIe3tz707S26083p87VVM5NZjmzK-H6U5Ht1IqJYl0");

    messaging.requestPermission()
        .then(function () {
            console.log('have permission');
            return messaging.getToken();
        })
        .then(function (token) {

            let device_id = document.cookie.replace(
                /(?:(?:^|.*;\s*)device_id\s*\=\s*([^;]*).*$)|^.*$/,
                "$1"
            );

            var data = JSON.parse(localStorage.getItem("data"));
            var user = data.user.token;

            if (device_id && device_id.length) {

                return createOrUpdateFCMData(device_id, token, user);
            }
            else {
                document.cookie = `device_id=${uuid()}`;
                let device_id = document.cookie.replace(
                    /(?:(?:^|.*;\s*)device_id\s*\=\s*([^;]*).*$)|^.*$/,
                    "$1"
                );
                return createOrUpdateFCMData(device_id, token, user);
            }

        })
        .catch(function (err) {
        });
};

const createOrUpdateFCMData = (device_id, fcm_token, user) => {

    let payload = {
        'fcm_token': fcm_token,
        'device_id': device_id
    }
    var FCM_DATA_URL = APIs.REACT_APP_FCM_DATA_URL;
    axiosInstance
        .post(FCM_DATA_URL, payload, { headers: { Authorization: `Bearer ${user}` } })
        .then(res => {
        })
        .catch(error => {

        });

}

export default initializeFirebase;