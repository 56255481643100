import _ from "lodash";
import React, { Component, Fragment, useState } from "react";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import {notification,Icon,Input,Button,Row,Col,Form,Card,Tooltip,Spin,Pagination,AutoComplete,Modal,Upload,
} from "antd";
import AddHobby from "../../../../../assets/images/icons/sibebar/hobby.svg";
import imageCompression from "browser-image-compression";


// For search
const { Search } = Input;
const { confirm } = Modal;
// const { value, setValue} = useState("");u

function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}
// Pagination
function itemRender(current, type, originalElement) {
  if (type === "prev") {
    return (
      <a className="back-icon">
        {" "}
        <Icon type="left" /> Back
      </a>
    );
  }
  if (type === "next") {
    return (
      <a className="next-icon">
        Next <Icon type="right" />
      </a>
    );
  }
  return originalElement;
}

class AddHobbies extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hobbies: null,
      loading: false,
      total: 0,
      per_page: 1,
      current_page: 1,
      dataSource: [],
      fileList: [],
      visible: false,
      hobby_title: null,
      hobby_slug: null,
      hobby_url: null,
      hobby_images: [],
      title :""
    };
  }

  handleEditCancel = () => {
    this.setState({ visible: false });
  };

  compressed_image = async (fileList) => {
    var options = {
      maxSizeKB: 800,
      maxWidthOrHeight: 1440,
      useWebWorker: true,
    };
    if(fileList[0].originFileObj){
    var file = fileList[0].originFileObj;
    var image = await imageCompression(file, options).then((compressedFile) => {
      var upload_file = new File([compressedFile], file.name);
      return upload_file;
    });
    return image;
  }
   return "image";
  };

  handleEditChange = ({ fileList }) => {
   if (fileList.status == "removed") {
      var images = this.state.hobby_images.filter(function (ele) {
        return ele.uid != fileList.uid;
      });
      this.setState({
        hobby_images: images,
      });
     } else {
        this.setState({ hobby_images: fileList });
    }
  };

  showModal = () => {
    this.setState({
        title:null,
        visible: true,
        hobby_title: null,
        hobby_slug: null,
        hobby_url: null,
        hobby_images: [],

    });
};

  componentDidMount() {
    this.getHobbiesData();
  }

  onHobbyEdit = (hobby) => {
    this.setState({
      visible: true,
      hobby_title: hobby.title,
      hobby_slug: hobby.slug,
      hobby_url: hobby.url,
      hobby_images: hobby.images,
      title:"Edit Hobby"
    });
  };

  getHobbiesData = (pageNumber = 1, paginate = true, q = "") => {
    this.setState({ loading: true });
    return this.props
      .getHobbies(pageNumber, paginate, q)
      .then((res) => {
        this.setState({
          hobbies: res.data,
          loading: false,
          total: res.meta.pagination.total,
          per_page: res.meta.pagination.per_page,
          current_page: res.meta.pagination.current_page,
        });
        return res;
      })
      .catch((error) => {
        this.setState({ loading: false });
        if (error && error.response && error.response.data && error.response.data.errors) {
          notification.error({
            message: error.response.data.errors.message,
          });
        }
      });
  };

  onPageChange = (pageNumber) => {
    this.setState({ loading: true });
    this.getHobbiesData(pageNumber)
      .then((res) => {
        this.setState({
          hobbies: res.data,
          total: res.meta.pagination.total,
          per_page: res.meta.pagination.per_page,
          current_page: res.meta.pagination.current_page,
          loading: false,
        });
      })
      .catch((error) => {
        notification["error"]({
          message: "Oops! Something went wrong!",
        });
        this.setState({ loading: false });
      });
  };

  handleAddNewHobby = (e) => {
    e.preventDefault();
    let hobby_icon = this.props.form.getFieldValue("hobby_icon");
    const { hobby_slug,hobby_images ,title} = this.state;
    if(hobby_images.length == 0 ){
         this.props.form.setFieldsValue({hobby_icon:null});
    }else{
      this.props.form.setFieldsValue({hobby_icon:hobby_images});
    }
    this.props.form.validateFieldsAndScroll(async (err, values) => {
      if (!err) {
        this.setState({ loading: true });
      
        await this.compressed_image(hobby_images).then((images) => {
          let formdata = new FormData();
          formdata.append("title", values["title"]);
          if(images != "image"){
              formdata.append("icon", images);
          }
          let call ;
          if(title)
          {
           call = this.props.editHobby;
          }else{
            call =  this.props.addNewHobby;
          }
          call(hobby_slug,formdata)
            .then((result) => {
              this.setState({ hobby_images: [] });
              notification.success({
                message: `Successful`,
              });
              this.setState({visible:false});
              this.getHobbiesData();
            })
            .catch((error) => {
              const error_code = _.get(error, "response.status");
              if (error_code && error_code == "422") {
                notification["error"]({
                  message: Object.values(
                    _.get(error, "response.data.errors", "Oops! Something went wrong!")
                  ),
                });
              } else {
                notification["error"]({
                  message: _.get(
                    error,
                    "response.data.errors.message",
                    "Oops! Something went wrong!"
                  ),
                });
              }
              this.setState({ loading: false });
            });
        });
      }
    });
  };

  handleDeleteHobby = (slug) => {
    const that = this;
    confirm({
      title: "Do you Want to remove the Hobby?",
      icon: <ExclamationCircleOutlined />,
      onOk() {
        that.props
          .deleteHobby(slug)
          .then((data) => {
            notification.success({
              message: "Hobby deleted successfully",
            });
            that.getHobbiesData();
          })
          .catch((error) => {
            notification.error({
              message: _.get(error, "response.data.errors.message", "Something went wrong"),
            });
          });
      },
      onCancel() {},
    });
  };

  onSearch = (searchText) => {
    let dataSource = [];

    return this.props
      .getHobbies(1, false, searchText)
      .then((res) => {
        let data = res.data;
        data.forEach(function (entry) {
          dataSource.push(entry.title);
        });
        this.setState({ dataSource });
        return res;
      })
      .catch((error) => {
        if(error.response && error.reponse.data && error.response.data.errors)
        notification.error({
          message: error.response.data.errors.message,
        });
      });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const {
      visible,
      title,
      hobby_title,
      hobby_images,
      hobbies,
      loading,
      total,
      per_page,
      current_page,
      dataSource,
    } = this.state;
    const uploadButton = (
      <Button>
        <Icon type="plus" /> icon
      </Button>
    );
   
    return (
      <Fragment>
        {loading ? (
          <center>
            <Spin />
          </center>
        ) : (
          <div className="">
            <div className="addSkills-container addAction-container">
              <Fragment>
                <div className="right">
                  <Button className="btn btn-primary text-uppercase" 
                   onClick={
                    this.showModal }
                     >
                      <Icon type="plus" />Add New Hobby
                  </Button>
                 </div>
                <Modal
                  className="add-new-holiday-modal profileAP-modal"
                  title={title ? title : "Add Hobby"}
                  loading={loading}
                  centered
                  visible={visible}
                  destroyOnClose={true}
                  onOk={this.handleAddNewHobby}
                  onCancel={this.handleEditCancel}
                  footer={[
                    <Button
                      onClick={this.handleAddNewHobby}
                      key="submit"
                      className="btn btn-primary text-uppercase"
                    >
                      Submit
                    </Button>,
                  ]}
                >
                  <div className="add-new-holiday-modal-container">
                    <form>
                      <Row className="row" gutter={30}>
                        <Col
                          className="gutter-row first-half-col"
                          xs={24}
                          sm={24}
                          md={24}
                          lg={24}
                          xl={24}
                        >
                          <Row gutter={30}>
                            <Col className="gutter-row" xs={24} sm={24} md={24} lg={12} xl={12}>
                              <Form.Item label="Hobby Name" colon={false}>
                                {getFieldDecorator("title", {
                                  initialValue: hobby_title ? hobby_title : "",
                                  rules: [
                                    {
                                      required: true,
                                      max: 50,
                                      min: 3,
                                      pattern: "^[a-zA-Z]+([a-zA-Z ])*$",
                                      message: "Enter a valid hobby name",
                                    },
                                  ],
                                })(
                                  <AutoComplete
                                    dataSource={dataSource}
                                    onChange={this.onSearch}
                                    placeholder="Enter new Hobby"
                                  />
                                )}
                              </Form.Item>
                            </Col>
                            <Col className="gutter-row" xs={24} sm={24} md={24} lg={12} xl={12}>
                              <div className="form-content form-group">
                                <div className="emp-type-dropdown dropdown">
                                  <Form.Item  name="hobby_icon" label="Hobby Icon (jpg,png)" colon={false}>
                                    {getFieldDecorator("hobby_icon", {
                                      rules: [
                                        {
                                          required: true,
                                          message: "icon is required",
                                        },
                                      ],
                                    })(
                                      <Upload
                                       type="file"
                                       accept="image/png, image/jpeg, image/svg"
                                        fileList={hobby_images}
                                        onChange={this.handleEditChange}
                                        listType="picture"
                                        beforeUpload={() => false}
                                        showUploadList={{
                                          showPreviewIcon: false,
                                          showRemoveIcon: true,
                                          showDownloadIcon: false,
                                        }}
                                      >
                                        {hobby_images && hobby_images.length >= 1
                                          ? null
                                          : uploadButton}
                                      </Upload>
                                    )}
                                  </Form.Item>
                                </div>
                              </div>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </form>
                  </div>

                  {/* Importing add holiday component */}
                  {/* <AddHoliday></AddHoliday> */}
                </Modal>
                <section className="content-section card-section skill-cards">
                  <Row className="" gutter={30}>
                    {hobbies &&
                      hobbies.map((hobby, index) => (
                        <Col
                          className="gutter-row"
                          xs={24}
                          sm={24}
                          md={24}
                          lg={12}
                          xl={12}
                          xxl={12}
                        >
                          <Card className="card card-shadow skills-card">
                            <div className="icon-box">
                              <img
                                className="icon icon-link"
                                src={hobby.url ? hobby.url : AddHobby}
                                alt="hobby image"
                              />
                            </div>
                            <div className="title-box">
                              <h4 className="title">{hobby.title}</h4>
                            </div>
                            <div className="action-box">
                              <Tooltip placement="bottom" title={"Edit"}>
                                <Button
                                  onClick={() => this.onHobbyEdit(hobby)}
                                  className="btn btn-light-info btn-icon"
                                >
                                  <Icon type="edit" theme="filled" />
                                </Button>
                              </Tooltip>

                              <Tooltip placement="bottom" title={"Delete"}>
                                <Button
                                  className="btn btn-light-danger btn-icon"
                                  onClick={() => this.handleDeleteHobby(hobby.slug)}
                                >
                                  <Icon type="delete" theme="filled" />
                                </Button>
                              </Tooltip>
                            </div>
                          </Card>
                        </Col>
                      ))}
                  </Row>

                  {total && per_page && total > per_page && (
                    <div className="pagination-box text-right">
                      <Pagination
                        className="pagination"
                        onChange={this.onPageChange}
                        current={current_page}
                        total={total}
                        itemRender={itemRender}
                        pageSize={per_page}
                      />
                    </div>
                  )}
                </section>
              </Fragment>
            </div>
          </div>
        )}
      </Fragment>
    );
  }
}

export default Form.create({ name: "add_hobby" })(AddHobbies);
