import React, { Component, Fragment } from 'react';
import {
  Form,
  notification,
  Input,
  Button,
  Row,
  Col,
  Select,
  DatePicker,
  Radio,
} from 'antd';
import moment from 'moment';
import male from '../../../assets/images/icons/profile/personal-details/male.svg';
import female from '../../../assets/images/icons/profile/personal-details/female.svg';
import data_setting from '../../../config/data_setting';
import url from '../../../config/url';
import ForAdminAndHr from '../../../hocs/ForAdminAndHr';
import { withStore } from '../../../hocs/withStore';
import _ from 'lodash';
import { axiosInstance as api } from '../../../utilities/configureAxios';

const { Option } = Select;
class AddProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      roles: null,
      managers: null,
      teams: null,
      roles_loading: true,
      managers_loading: true,
      teams_loading: true,
      levels_loading: true,
      suggest_visible: false,
      suggested_empid: null,
      employment_type_loading: false,
    };
  }

  componentDidMount = () => {
    this.setState({ loading: true });
    api
      .get(url['getRoles'])
      .then((res) => {
        const roles = _.get(res, 'data.data', []);
        this.setState({ roles, roles_loading: false });
      })
      .catch((error) => {
        notification['error']({
          message: _.get(
            error,
            'response.data.errors.message',
            'Oops! Something went wrong!'
          ),
        });
        this.setState({ roles_loading: false });
      });
    api
      .get(url['getManagers'])
      .then((res) => {
        const managers = _.get(res, 'data.data', []);
        this.setState({ managers, managers_loading: false });
      })
      .catch((error) => {
        notification['error']({
          message: _.get(
            error,
            'response.data.errors.message',
            'Oops! Something went wrong!'
          ),
        });
        this.setState({ managers_loading: false });
      });
    api
      .get(url['getTeams'])
      .then((res) => {
        const teams = _.get(res, 'data.data', []);
        this.setState({ teams, teams_loading: false });
      })
      .catch((error) => {
        notification['error']({
          message: _.get(
            error,
            'response.data.errors.message',
            'Oops! Something went wrong!'
          ),
        });
        this.setState({ teams_loading: false });
      });
    api
      .get(url['getLevels'])
      .then((res) => {
        const levels = _.get(res, 'data.data', []);
        this.setState({ levels, levels_loading: false });
      })
      .catch((error) => {
        notification['error']({
          message: _.get(
            error,
            'response.data.errors.message',
            'Oops! Something went wrong!'
          ),
        });
        this.setState({ levels_loading: false });
      });
  };

  handleSubmit = (e) => {
    const { onSubmit } = this.props;
    e.preventDefault();
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        onSubmit(values, this.props.form);
      }
    });
  };

  onChange = (value) => {
    this.setState({ employment_type_loading: true });

    api
      .get('/employees/suggested?employment_type=' + value)
      .then((res) => {
        this.setState({ employment_type_loading: false });
        this.getSuggestedEmpId(res.data.data);
      })
      .catch((error) => {
        notification['error']({
          message: _.get(
            error,
            'response.data.errors.message',
            'Oops! Something went wrong!'
          ),
        });
        this.setState({ teams_loading: false, employment_type_loading: false });
      });
  };

  getSuggestedEmpId(id) {
    if (Number.isInteger(id)) {
      var updated_id = id + 1;
      this.setState({ suggested_empid: updated_id, suggest_visible: true });
      return;
    }
    if (id.length) {
      const regex = /[0-9]+$/;
      var last_num = id.match(regex);
      if (last_num) {
        var num = last_num[0];
        var index = last_num['index'];
        var rem = id.substr(0, index);
        var updated_id = rem + (parseInt(num) + 1);
        this.setState({ suggested_empid: updated_id, suggest_visible: true });
      } else {
        this.setState({ suggested_empid: id + '-1', suggest_visible: true });
      }
    } else {
      this.setState({ suggest_visible: false });
    }
  }

  render() {

    const { onCancel, isLoading } = this.props;
    const { getFieldDecorator } = this.props.form;
    const {
      roles,
      managers,
      teams,
      levels,
      levels_loading,
      teams_loading,
      roles_loading,
      managers_loading,
      suggest_visible,
      suggested_empid,
      employment_type_loading,
    } = this.state;
    return (
      <Fragment>
        <div className="add-new-profile-modal-container">
          <Form onSubmit={this.handleSubmit}>
            <Row className="" gutter={30}>
              <Col
                className="gutter-row first-half-col"
                xs={24}
                sm={24}
                md={24}
                lg={12}
                xl={12}
              >
                <div className="form-content">
                  <Row className="" gutter={30}>
                    <Col
                      className="gutter-row"
                      xs={24}
                      sm={24}
                      md={24}
                      lg={12}
                      xl={12}
                    >
                      <div className="form-content form-group">
                        <Form.Item label="First Name">
                          {getFieldDecorator('first_name', {
                            rules: [
                              {
                                required: true,
                                message: 'Enter valid first name',
                              },
                            ],
                            validateTrigger: 'onBlur',
                          })(
                            <Input
                              id="first-name"
                              className="input"
                              maxLength="255"
                              placeholder="Enter first name"
                              onBlur={(e) =>
                                (e.target.value = e.target.value.trim())
                              }
                            />
                          )}
                        </Form.Item>
                      </div>
                    </Col>
                    <Col
                      className="gutter-row"
                      xs={24}
                      sm={24}
                      md={24}
                      lg={12}
                      xl={12}
                    >
                      <div className="form-content form-group">
                        <Form.Item label="Last Name">
                          {getFieldDecorator('last_name', {
                            rules: [
                              {
                                required: true,
                                message: 'Enter valid last name',
                              },
                            ],
                            validateTrigger: 'onBlur',
                          })(
                            <Input
                              id="last-name"
                              className=""
                              maxLength="255"
                              placeholder="Enter last name"
                              onBlur={(e) =>
                                (e.target.value = e.target.value.trim())
                              }
                            />
                          )}
                        </Form.Item>
                      </div>
                    </Col>
                  </Row>
                  <Row className="" gutter={30}>
                    {/* <Col className="gutter-row" xs={24} sm={24} md={24} lg={12} xl={12}>
                                            <div className="form-content form-group">
                                                <Form.Item label="Date of Birth (official)">
                                                    {getFieldDecorator('dob_official', {
                                                        rules: [
                                                            {
                                                                type: 'object',
                                                                required: true,
                                                                message: 'Please select date of birth!'
                                                            }
                                                        ],
                                                    })(<DatePicker
                                                        format={data_setting['date_format']}
                                                        disabledDate={current => {
                                                            return current > moment();
                                                        }}
                                                    />)}
                                                </Form.Item>
                                            </div>
                                        </Col> */}
                    <Col
                      className="gutter-row"
                      xs={24}
                      sm={24}
                      md={24}
                      lg={12}
                      xl={12}
                    >
                      <div className="form-content form-group">
                        <Form.Item label="Email (official)">
                          {getFieldDecorator('email_official', {
                            rules: [
                              {
                                type: 'email',
                                message: 'Enter valid email',
                              },
                              {
                                required: true,
                                message: 'Enter an email',
                              },
                            ],
                            validateTrigger: 'onBlur',
                          })(
                            <Input
                              id="email-id"
                              type="email"
                              maxLength="255"
                              className="input"
                              placeholder="Enter Official email ID"
                              onBlur={(e) =>
                                (e.target.value = e.target.value.trim())
                              }
                            />
                          )}
                        </Form.Item>
                      </div>
                    </Col>

                    <Col className="" xs={24} sm={24} md={24} lg={12} xl={12}>
                      <div className="form-group">
                        <Form.Item label="Mobile Number">
                          {getFieldDecorator('mobile', {
                            //initialValue: personal_details ? personal_details.mobile : "",
                            rules: [
                              {
                                required: true,
                                message: 'Please enter phone number!',
                              },
                            ],
                            validateTrigger: 'onBlur',
                          })(
                            <Input
                              className="input"
                              maxLength="10"
                              minLength="10"
                              placeholder="Enter Mobile No."
                              onBlur={(e) =>
                              (e.target.value = e.target.value.replace(
                                /\D/g,
                                ''
                              ))
                              }
                            />
                          )}
                        </Form.Item>
                      </div>
                    </Col>
                  </Row>

                  <Row className="" gutter={30}>
                    <Col
                      className="gutter-row"
                      xs={24}
                      sm={24}
                      md={24}
                      lg={12}
                      xl={12}
                    >
                      <div className="form-group">
                        <Form.Item label="Gender">
                          {getFieldDecorator('gender', {
                            rules: [
                              {
                                required: true,
                                message: 'Select gender',
                              },
                            ],
                            validateTrigger: 'onBlur',
                          })(
                            <Radio.Group className="radio-btn">
                              <div className="radio-icon male-icon">
                                <Radio
                                  value={data_setting['gender']['male']}
                                  selected
                                  className="male-radio"
                                >
                                  Male
                                </Radio>
                                <img
                                  className="icon male"
                                  src={male}
                                  alt="male"
                                />
                              </div>
                              <div className="radio-icon female-icon">
                                <Radio
                                  value={data_setting['gender']['female']}
                                  className="female-radio"
                                >
                                  Female
                                </Radio>
                                <img
                                  className="icon female"
                                  src={female}
                                  alt="Female"
                                />
                              </div>
                            </Radio.Group>
                          )}
                        </Form.Item>
                      </div>
                    </Col>
                  </Row>
                </div>
              </Col>
              <Col
                className="gutter-row second-half-col"
                xs={24}
                sm={24}
                md={24}
                lg={12}
                xl={12}
              >
                <div className="form-content">
                  <Row className="" gutter={30}>
                    <Col
                      className="gutter-row"
                      xs={24}
                      sm={24}
                      md={24}
                      lg={12}
                      xl={12}
                    >
                      <div className="form-content form-group">
                        <div className="emp-type-dropdown dropdown">
                          <Form.Item label="Employment Type">
                            {getFieldDecorator('employment_type', {
                              rules: [
                                {
                                  required: true,
                                  message: 'Select employment type',
                                },
                              ],
                              validateTrigger: 'onBlur',
                            })(
                              <Select
                                className="emp-type-selectbox selectbox"
                                showSearch
                                style={{ width: '100%' }}
                                loading={employment_type_loading}
                                disabled={employment_type_loading}
                                placeholder="Select Employment Type"
                                onChange={(value) => {
                                  this.onChange(value);
                                }}
                                optionFilterProp="children"
                              >
                                <Option
                                  value={
                                    data_setting['employment']['Full-Time']
                                  }
                                >
                                  Full Time
                                </Option>
                                <Option
                                  value={
                                    data_setting['employment']['Internship']
                                  }
                                >
                                  Internship
                                </Option>
                                <Option
                                  value={data_setting['employment']['Contract']}
                                >
                                  Contract
                                </Option>
                              </Select>
                            )}
                          </Form.Item>
                        </div>
                      </div>
                    </Col>

                    <Col
                      className="gutter-row"
                      xs={24}
                      sm={24}
                      md={24}
                      lg={12}
                      xl={12}
                    >
                      <div className="form-content form-group">
                        <div className="emp-type-dropdown dropdown">
                          <Form.Item label="Employment Sub Type">
                            {getFieldDecorator('employment_sub_type', {
                              rules: [
                                {
                                  required: true,
                                  message: 'Select employment sub type',
                                },
                              ],
                              validateTrigger: 'onBlur',
                            })(
                              <Select
                                className="emp-type-selectbox selectbox"
                                // showSearch
                                style={{ width: '100%' }}
                                // loading={employment_type_loading}
                                // disabled={employment_type_loading}
                                placeholder="Select Employment Sub Type"
                                onChange={(value) => {
                                }}
                              // optionFilterProp="children"
                              >
                                <Option
                                  value={
                                    data_setting['employment_sub_type']['normal']
                                  }
                                >
                                  Normal
                                </Option>
                                <Option
                                  value={
                                    data_setting['employment_sub_type']['notice_period']
                                  }
                                >
                                  Notice Period
                                </Option>
                                <Option
                                  value={data_setting['employment_sub_type']['probation']}
                                >
                                  Probation
                                </Option>
                              </Select>
                            )}
                          </Form.Item>
                        </div>
                      </div>
                    </Col>

                    <Col
                      className="gutter-row"
                      xs={24}
                      sm={24}
                      md={24}
                      lg={12}
                      xl={12}
                    >
                      <div className="form-content form-group">
                        <Form.Item label="Employee ID">
                          {getFieldDecorator('emp_id', {
                            rules: [
                              {
                                required: true,
                                message: 'Enter valid employee id',
                              },
                            ],
                            validateTrigger: 'onBlur',
                          })(
                            <Input
                              id="employee-id"
                              className="input"
                              maxLength="255"
                              placeholder="Enter Employee ID"
                              onBlur={(e) =>
                                (e.target.value = e.target.value.trim())
                              }
                            />
                          )}
                          {suggest_visible && suggested_empid != 0 && (
                            <span
                              className="text-success"
                              style={{ fontSize: '12px' }}
                            >
                              *Suggested Employee ID: {suggested_empid}
                            </span>
                          )}
                        </Form.Item>
                      </div>
                    </Col>
                  </Row>

                  <Row className="" gutter={30}>
                    <Col
                      className="gutter-row"
                      xs={24}
                      sm={24}
                      md={24}
                      lg={12}
                      xl={12}
                    >
                      <div className="form-content form-group">
                        <div className="level-dropdown dropdown">
                          <Form.Item label="Level">
                            {getFieldDecorator('level', {
                              rules: [
                                {
                                  required: true,
                                  message: 'Select level',
                                },
                              ],
                              validateTrigger: 'onBlur',
                            })(
                              <Select
                                className="level-selectbox selectbox"
                                showSearch
                                style={{ width: '100%' }}
                                placeholder="Select Level"
                                loading={levels_loading}
                              >
                                {levels &&
                                  levels.map((level) => (
                                    <Option key={level.id} value={level.id}>
                                      {level.title}
                                    </Option>
                                  ))}
                              </Select>
                            )}
                          </Form.Item>
                        </div>
                      </div>
                    </Col>
                    <Col
                      className="gutter-row"
                      xs={24}
                      sm={24}
                      md={24}
                      lg={12}
                      xl={12}
                    >
                      <div className="form-content form-group">
                        <Form.Item label="Designation">
                          {getFieldDecorator('designation', {
                            rules: [
                              {
                                required: true,
                                message: 'Enter designation',
                              },
                            ],
                            validateTrigger: 'onBlur',
                          })(
                            <Input
                              className="input"
                              maxLength="255"
                              placeholder="Enter Designation"
                              onBlur={(e) =>
                                (e.target.value = e.target.value.trim())
                              }
                            />
                          )}
                        </Form.Item>
                      </div>
                    </Col>
                  </Row>
                  <Row className="" gutter={30}>
                    <Col
                      className="gutter-row"
                      xs={24}
                      sm={24}
                      md={24}
                      lg={12}
                      xl={12}
                    >
                      <div className="form-content form-group">
                        <div className="team-dropdown dropdown">
                          <Form.Item label="Team">
                            {getFieldDecorator('team', {
                              rules: [
                                {
                                  required: true,
                                  message: 'Select team',
                                },
                              ],
                              validateTrigger: 'onBlur',
                            })(
                              <Select
                                className="team-selectbox selectbox"
                                showSearch
                                style={{ width: '100%' }}
                                placeholder="Select Team"
                                optionFilterProp="children"
                                loading={teams_loading}
                              >
                                {teams &&
                                  teams.map((team) => (
                                    <Option key={team.id} value={team.id}>
                                      {team.title}
                                    </Option>
                                  ))}
                              </Select>
                            )}
                          </Form.Item>
                        </div>
                      </div>
                    </Col>
                    <Col
                      className="gutter-row"
                      xs={24}
                      sm={24}
                      md={24}
                      lg={12}
                      xl={12}
                    >
                      <div className="form-content form-group">
                        <div className="role-dropdown dropdown">
                          <Form.Item label="Role">
                            {getFieldDecorator('roles', {
                              rules: [
                                {
                                  required: true,
                                  message: 'Select role',
                                },
                              ],
                              validateTrigger: 'onBlur',
                            })(
                              <Select
                                className="role-selectbox selectbox"
                                showSearch
                                mode="multiple"
                                style={{ width: '100%' }}
                                placeholder="Select Role"
                                optionFilterProp="children"
                                loading={roles_loading}
                              >
                                {roles &&
                                  roles.map((role) => (
                                    <Option key={role.id} value={role.id}>
                                      {role.name
                                        .replace('_', ' ')
                                        .toUpperCase()}
                                    </Option>
                                  ))}
                              </Select>
                            )}
                          </Form.Item>
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <Row className="" gutter={30}>
                    <Col
                      className="gutter-row"
                      xs={24}
                      sm={24}
                      md={24}
                      lg={12}
                      xl={12}
                    >
                      <div className="form-content form-group">
                        <Form.Item label="Date of Joining">
                          {getFieldDecorator('date_of_joining', {
                            rules: [
                              {
                                type: 'object',
                                required: true,
                                message: 'Please select date of joining!',
                              },
                            ],
                          })(
                            <DatePicker
                              format={data_setting['date_format']}
                              disabledDate={(current) => {
                                return current > moment();
                              }}
                            />
                          )}
                        </Form.Item>
                      </div>
                    </Col>
                    <Col
                      className="gutter-row"
                      xs={24}
                      sm={24}
                      md={24}
                      lg={12}
                      xl={12}
                    >
                      <div className="form-content form-group">
                        <Form.Item label="Reporting Manager">
                          {getFieldDecorator('managers[0]', {
                            rules: [
                              {
                                required: true,
                                message: 'Select reporting manager',
                              },
                            ],
                            validateTrigger: 'onBlur',
                          })(
                            <Select
                              className="manager-selectbox selectbox"
                              showSearch
                              style={{ width: '100%' }}
                              placeholder="Select reporting manager"
                              optionFilterProp="children"
                              loading={roles_loading}
                            >
                              {managers &&
                                managers.map((manager) => (
                                  <Option key={manager.id} value={manager.id}>
                                    {manager.name}
                                  </Option>
                                ))}
                            </Select>
                          )}
                        </Form.Item>
                      </div>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
            <Form.Item className="footer-btn">
              <Button
                type="danger"
                className="btn btn-light-danger text-uppercase"
                onClick={() => onCancel(false)}
              >
                {' '}
                Cancel{' '}
              </Button>
              <Button
                type="primary"
                htmlType="submit"
                className="btn btn-light-success text-uppercase"
                disabled={isLoading}
              >
                {isLoading ? 'Adding...' : 'Add'}
              </Button>
            </Form.Item>
          </Form>
        </div>
      </Fragment>
    );
  }
}

export default Form.create({ name: 'add_user' })(
  ForAdminAndHr(withStore(AddProfile))
);
