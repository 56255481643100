import _ from 'lodash';
import React, { Component, Fragment } from 'react';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import {
  notification,
  Icon,
  Input,
  Button,
  Row,
  Col,
  Form,
  Card,
  Tooltip,
  Spin,
  Pagination,
  AutoComplete,
  Modal,
} from 'antd';
import AddSkill from '../../../../../assets/images/icons/sibebar/add-skill.svg';

// For search
const { Search } = Input;
const { confirm } = Modal;

// Pagination
function itemRender(current, type, originalElement) {
  if (type === 'prev') {
    return (
      <a className="back-icon">
        {' '}
        <Icon type="left" /> Back
      </a>
    );
  }
  if (type === 'next') {
    return (
      <a className="next-icon">
        Next <Icon type="right" />
      </a>
    );
  }
  return originalElement;
}

class AddSkills extends Component {
  constructor(props) {
    super(props);
    this.state = {
      skills: null,
      loading: false,
      total: 0,
      per_page: 1,
      current_page: 1,
      dataSource: [],
    };
  }

  componentDidMount() {
    this.getSkillsData();
  }

  getSkillsData = (pageNumber = 1, paginate = true, q = '') => {
    this.setState({ loading: true });
    return this.props
      .getSkills(pageNumber, paginate, q)
      .then((res) => {
        this.setState({
          skills: res.data,
          loading: false,
          total: res.meta.pagination.total,
          per_page: res.meta.pagination.per_page,
          current_page: res.meta.pagination.current_page,
        });
        return res;
      })
      .catch((error) => {
        this.setState({ loading: false });
        if(error && error.response && error.response.data && error.response.data.errors){
        notification.error({
          message: error.response.data.errors.message,
        });
      }
      });
  };

  onPageChange = (pageNumber) => {
    this.setState({ loading: true });
    this.getSkillsData(pageNumber)
      .then((res) => {
        this.setState({
          skills: res.data,
          total: res.meta.pagination.total,
          per_page: res.meta.pagination.per_page,
          current_page: res.meta.pagination.current_page,
          loading: false,
        });
      })
      .catch((error) => {
        notification['error']({
          message: 'Oops! Something went wrong!',
        });
        this.setState({ loading: false });
      });
  };

  handleAddNewSkill = (e) => {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        this.props
          .addNewSkill(values['title'])
          .then((result) => {
            notification.success({
              message: `Skill added successfully`,
            });
            this.getSkillsData();
          })
          .catch((error) => {
            const error_code = _.get(error, 'response.status');
            if (error_code && error_code == '422') {
              notification['error']({
                message: Object.values(
                  _.get(
                    error,
                    'response.data.errors',
                    'Oops! Something went wrong!'
                  )
                ),
              });
            } else {
              notification['error']({
                message: _.get(
                  error,
                  'response.data.errors.message',
                  'Oops! Something went wrong!'
                ),
              });
            }
            this.setState({ loading: false });
          });
      }
    });
  };

  handleDeleteSkill = (slug) => {
    const that = this;
    confirm({
      title: 'Do you Want to remove the Skill?',
      icon: <ExclamationCircleOutlined />,
      onOk() {
        that.props
          .deleteSkill(slug)
          .then((data) => {
            notification.success({
              message: 'Skill deleted successfully',
            });
            that.getSkillsData();
          })
          .catch((error) => {
            notification.error({
              message: _.get(
                error,
                'response.data.errors.message',
                'Something went wrong'
              ),
            });
          });
      },
      onCancel() {},
    });
  };

  onSearch = (searchText) => {
    let dataSource = [];

    return this.props
      .getSkills(1, false, searchText)
      .then((res) => {
        let data = res.data;
        data.forEach(function (entry) {
          dataSource.push(entry.title);
        });
        this.setState({ dataSource });
        return res;
      })
      .catch((error) => {
        notification.error({
          message: error.response.data.errors.message,
        });
      });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const { skills, loading, total, per_page, current_page, dataSource } =
      this.state;
    return (
      <Fragment>
        {loading ? (
          <center>
            <Spin />
          </center>
        ) : (
          <div className="">
            <div className="addSkills-container addAction-container">
              {/* page Header */}
              {/* <div className="page-header">
                                <div className="page-title">
                                    <h2 className="title"><img className="icon icon-view" src={AddSkill} alt="view icon" /> Add Skills</h2>
                                </div>
                            </div> */}

              {/* <section className="search-section">
                            <div className="search-box">
                                <Search placeholder="Search" />
                            </div>
                        </section> */}

              {/* Add new skill container */}

              <Fragment>
                <section className="add-skill-box add-item-box card-shadow">
                  <div className="heading">
                    <h3 className="title text-primary">Add new skill</h3>
                  </div>
                  <form onSubmit={this.handleAddNewSkill}>
                    <Row className="" gutter={30}>
                      <Col
                        className="gutter-row"
                        xs={24}
                        sm={24}
                        md={24}
                        lg={12}
                        xl={12}
                        xxl={12}
                      >
                        <div className="form-content form-group">
                          {/* <Form.Item label="Skill Name" colon={false} >
                                                        {getFieldDecorator('title', {
                                                            rules: [
                                                                {
                                                                    required: true,
                                                                    message: "Enter a valid skill name"
                                                                }
                                                            ]
                                                        })(
                                                            <Input className="input" onBlur={e => e.target.value = e.target.value.trim()} onPressEnter={this.handleAddNewSkill} placeholder="Enter new skill" />
                                                        )}
                                                    </Form.Item> */}

                          <Form.Item label="Skill Name" colon={false}>
                            {getFieldDecorator('title', {
                              rules: [
                                {
                                  required: true,
                                  message: 'Enter a valid skill name',
                                },
                              ],
                            })(
                              <AutoComplete
                                dataSource={dataSource}
                                //onSelect={onSelect}
                                //onPressEnter={this.handleAddNewSkill}
                                onChange={this.onSearch}
                                //onBlur={e => e.target.value = e.target.value.trim()}
                                placeholder="Enter new skill"
                              />
                            )}
                          </Form.Item>
                        </div>
                      </Col>
                      <Col
                        className="gutter-row"
                        xs={24}
                        sm={24}
                        md={24}
                        lg={12}
                        xl={12}
                        xxl={12}
                      >
                        <div className="form-content form-group text-right sm-row-align">
                          <Form.Item
                            label="Add this skill"
                            colon={false}
                            className="add-skill-label"
                          >
                            <Button
                              className="btn btn-light-primary text-uppercase"
                              onClick={this.handleAddNewSkill}
                            >
                              + Add this Skill
                            </Button>
                          </Form.Item>
                        </div>
                      </Col>
                    </Row>
                  </form>
                </section>
                {/* card section */}
                <section className="content-section card-section skill-cards">
                  <Row className="" gutter={30}>
                    {skills &&
                      skills.map((skill, index) => (
                        <Col
                          className="gutter-row"
                          xs={24}
                          sm={24}
                          md={24}
                          lg={12}
                          xl={12}
                          xxl={12}
                        >
                          <Card className="card card-shadow skills-card">
                            <div className="icon-box">
                              <img
                                className="icon icon-link"
                                src={AddSkill}
                                alt="skill image"
                              />
                            </div>
                            <div className="title-box">
                              <h4 className="title">{skill.title}</h4>
                            </div>
                            <div className="action-box">
                              {/* <Tooltip placement="bottom" title={'Edit'}><Button className="btn btn-light-info btn-icon"><Icon type="edit" theme="filled" /></Button></Tooltip> */}
                              <Tooltip placement="bottom" title={'Delete'}>
                                <Button
                                  className="btn btn-light-danger btn-icon"
                                  onClick={() =>
                                    this.handleDeleteSkill(skill.slug)
                                  }
                                >
                                  <Icon type="delete" theme="filled" />
                                </Button>
                              </Tooltip>
                            </div>
                          </Card>
                        </Col>
                      ))}
                  </Row>

                  {total && per_page && total > per_page && (
                    <div className="pagination-box text-right">
                      <Pagination
                        className="pagination"
                        onChange={this.onPageChange}
                        current={current_page}
                        total={total}
                        itemRender={itemRender}
                        pageSize={per_page}
                      />
                    </div>
                  )}
                </section>
              </Fragment>
            </div>
          </div>
        )}
      </Fragment>
    );
  }
}

export default Form.create({ name: 'add_skill' })(AddSkills);
