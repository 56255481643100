import React, { Component, Fragment } from "react";
import { Row, Col, Select, DatePicker, Button, Icon, Input, Upload, Modal, Form, notification } from "antd";
import lottie from 'lottie-web';
import ForAdminAndOfficeAdmin from "../../../../../hocs/ForAdminAndOfficeAdmin";
import { withStore } from "../../../../../hocs/withStore";
import emptyState from '../../../../../assets/animation/json/emptyState.json';
import moment from 'moment';
import _ from 'lodash';
import { axiosInstance as api } from '../../../../../utilities/configureAxios';
import imageCompression from "browser-image-compression";
// for lottie
let animObj = null;
const dateFormat = 'YYYY-MM-DD';

// for Selectbox
const { Option } = Select;

function handleChange(value) {
}

function handleChanges(value) {
}



// For Textarea
const { TextArea } = Input;
const onTextareaChange = e => {
};

// For Upload
function getBase64(file) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });
}
class AddNewAsset extends Component {

    constructor(props) {
        super(props)
        this.state = {
            previewVisible: false,
            previewImage: '',
            hover: false,
            assetTypes: this.props.assetTypes,
            fileList: [],
            saveButtonLoading: false,
            ifSetPuchaseDate: false,
            editAsset: this.props.editAsset,
            uploadedImages: this.props.editAsset ? this.props.editAsset.images : [],
        }
        animObj = lottie.loadAnimation({
            container: this.animBox, // the dom element that will contain the animation
            renderer: 'svg',
            loop: true,
            autoplay: true,
            animationData: emptyState // the path to the animation json
        });

    }

    // for lottie
    componentDidMount() {
    }


    handleCancel = () => this.setState({ previewVisible: false });

    handlePreview = async file => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }

        this.setState({
            previewImage: file.url || file.preview,
            previewVisible: true,
        });
    };

    // For Datepicker
    onChange = () => {
        const { getFieldValue, setFieldValue, resetFields } = this.props.form;
        var warranty_exp_date = getFieldValue("warranty_exp_date");
        var purchase_date = getFieldValue("purchase_date");
        if (warranty_exp_date < purchase_date) {
            this.props.form.setFieldsValue({
                warranty_exp_date: '',
            });
        };
    }

    handleHover = () => {
      this.setState({ hover: !this.state.hover });
    };

    handleRemoveApi = (file) => {

        api
            .delete('/assets/images/' + file.uid)
            .then(res => {
                var images = this.state.uploadedImages.filter(function (ele) {
                    return ele.uid != file.uid;
                });
                this.setState({
                    uploadedImages: images
                })
                notification['success']({
                    message: 'Removed Successfully'
                });
            })
            .catch(error => {
                notification['error']({
                    message: _.get(error, 'response.data.errors.message', "Oops! Something went wrong!")
                });
                this.setState({ loading: false });
            })


    }

    handleChange = ({ fileList }) => this.setState({ fileList });


    getAssetsData() {
        this.props.loadAssets()
    }

    enableWarrantExpDate = (value) => {
        const { resetFields } = this.props.form;
        if (!value) {
            resetFields(['warranty_exp_date'])
        }
    }

  appendImage = async (fileList, form_data) => {
    var options = {
      maxSizeKB: 800,
      maxWidthOrHeight: 1440,
      useWebWorker: true,
    };
    for (let i = 0; i < fileList.length; i++) {
      var file = fileList[i].originFileObj;
      await imageCompression(file, options)
        .then((compressedFile) => {
          var upload_file = new File([compressedFile], file.name);
          return upload_file;
        })
        .then((file) => {
          form_data.append(`images[${i}]`, file);
        });
    }
    return form_data;
  };

  handleOk = (e) => {
    const { fileList, editAsset } = this.state;
    e.preventDefault();
    this.props.form.validateFieldsAndScroll(async (err, formData) => {
      if (!err) {
        this.setState({ saveButtonLoading: true });
        var form_data = new FormData();
        form_data = await this.appendImage(fileList, form_data);
        form_data.append("type_id", formData.type_id);
        form_data.append("title", formData.title);
        form_data.append("status", formData.status);
        form_data.append("is_issuable", formData.is_issuable);
        form_data.append("reference_id", formData.reference_id);
        if (formData.serial_number) form_data.append("serial_number", formData.serial_number);
        if (formData.purchase_date)
          form_data.append("purchase_date", moment(formData.purchase_date).format(dateFormat));
        if (formData.warranty_exp_date)
          form_data.append(
            "warranty_exp_date",
            moment(formData.warranty_exp_date).format(dateFormat)
          );
        if (formData.notes) form_data.append("notes", formData.notes);

        for (var pair of form_data.entries()) {
          console.log(pair[0] + " - " + pair[1]);
        }

        if (editAsset && editAsset.id) {
          this.props
            .onEditAsset(editAsset.id, form_data)
            .then((res) => {
              this.setState({ saveButtonLoading: false });
              this.getAssetsData();
              notification["success"]({
                message: "Asset edited Successfully",
              });
            })
            .catch((error) => {
              const error_code = _.get(error, "response.status");
              if (error_code && error_code == "422") {
                this.setState({ saveButtonLoading: false });
                notification["error"]({
                  message: "Invalid Data",
                  description: Object.values(
                    _.get(error, "response.data.errors", "Oops! Something went wrong!")
                  ),
                });
              } else {
                this.setState({ saveButtonLoading: false });
                notification["error"]({
                  message: _.get(
                    error,
                    "response.data.errors.message",
                    "Oops! Something went wrong!"
                  ),
                });
                this.setState({ visible: false });
              }
            });
        } else {
          this.props
            .addNewAsset(form_data)
            .then((res) => {
              this.setState({ saveButtonLoading: false });
              this.getAssetsData();
              notification["success"]({
                message: "Asset Added Successfully",
              });
            })
            .catch((error) => {
              this.setState({ saveButtonLoading: false });
              const error_code = _.get(error, "response.status");

              if (error_code && error_code == "422") {
                notification["error"]({
                  message: "Invalid Data",
                  description: Object.values(
                    _.get(error, "response.data.errors", "Oops! Something went wrong!")
                  ),
                });
              } else {
                notification["error"]({
                  message: _.get(
                    error,
                    "response.data.errors.message",
                    "Oops! Something went wrong!"
                  ),
                });
                this.setState({ visible: false });
              }
            });
        }
      }
    });
  };

  render() {
    // for Upload
    const { getFieldDecorator, getFieldValue } = this.props.form;
    const {
      previewVisible,
      previewImage,
      fileList,
      editAsset,
      uploadedImages,
      ifSetPuchaseDate,
      saveButtonLoading,
    } = this.state;
    const uploadButton = (
      <div>
        <Icon type="plus" />
        <div className="ant-upload-text">Add Photo</div>
      </div>
    );
    return (
      <Fragment>
        <div className="addAsset-Container">
          <div className="animated slideInRight">
            <h3 className="drawer-title text-primary mb--20">Asset Details </h3>
            <form className="">
              <div className="form-content">
                <Row className="row" gutter={30}>
                  <Col className="gutter-row col" xs={24} sm={24} md={12} lg={12} xl={12}>
                    <div className="form-group">
                      <Form.Item label="Asset Name">
                        { getFieldDecorator("title", {
                          initialValue: editAsset ? editAsset.title : "",
                          rules: [
                            {
                              required: true,
                              message: "Enter valid asset name",
                            },
                          ],
                          validateTrigger: "onBlur",
                        })(<Input placeholder="Enter asset name" />)}
                      </Form.Item>
                    </div>
                  </Col>
                  <Col className="gutter-row col" xs={24} sm={24} md={12} lg={12} xl={12}>
                    <div className="form-group">
                      <Form.Item label="Asset Status" colon={false}>
                        {getFieldDecorator("status", {
                          initialValue: editAsset ? Object.keys(editAsset.status)[0] : ["1"],
                          rules: [
                            {
                              required: true,
                              message: "Select valid asset status",
                            },
                          ],
                        })(
                          <Select
                            className="device-dropdown"
                            style={{ width: "100%" }}
                            placeholder="Select"
                            onChange={handleChange}
                          >
                            <Option value="1">Active</Option>
                            <Option value="0">Inactive</Option>
                          </Select>
                        )}
                      </Form.Item>
                    </div>
                  </Col>
                </Row>
              </div>

              <div className="form-content">
                <Row className="row" gutter={30}>
                  <Col className="gutter-row col" xs={24} sm={24} md={24} lg={12} xl={12}>
                    <div className="form-group">
                      <Form.Item label="Asset Type" colon={false}>
                        {getFieldDecorator("type_id", {
                          initialValue: editAsset && editAsset.type ? editAsset.type.id : [],
                          rules: [
                            {
                              required: true,
                              message: "Select valid device type",
                            },
                          ],
                        })(
                          <Select
                            showSearch
                            className="device-dropdown"
                            style={{ width: "100%" }}
                            placeholder="Select"
                            onChange={handleChange}
                            filterOption={(input, option) =>
                              option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                          >
                            {this.state.assetTypes.map((asset) => (
                              <Option value={asset.id}>{asset.type}</Option>
                            ))}
                          </Select>
                        )}
                      </Form.Item>
                    </div>
                  </Col>

                  <Col className="gutter-row col" xs={24} sm={24} md={24} lg={12} xl={12}>
                    <div className="form-group">
                      <Form.Item label="Is Issuable ?" colon={false}>
                        {getFieldDecorator("is_issuable", {
                          initialValue: editAsset ? Object.keys(editAsset.is_issuable)[0] : ["1"],
                          rules: [
                            {
                              required: true,
                              message: "Select issuable status",
                            },
                          ],
                        })(
                          <Select
                            className="device-dropdown"
                            style={{ width: "100%" }}
                            placeholder="Select"
                            onChange={handleChange}
                          >
                            <Option value="1">Yes</Option>
                            <Option value="0">No</Option>
                          </Select>
                        )}
                      </Form.Item>
                    </div>
                  </Col>
                </Row>
              </div>

              <div className="form-content">
                <Row className="row" gutter={30}>
                  <Col className="gutter-row col" xs={24} sm={24} md={12} lg={12} xl={12}>
                    <div className="form-group">
                      <Form.Item label="Purchase Date" colon={false}>
                        {getFieldDecorator("purchase_date", {
                          initialValue:
                            editAsset && editAsset.purchase_date
                              ? moment(editAsset.purchase_date)
                              : "",
                          // rules: [
                          //     {
                          //         type: 'object',
                          //         required: true,
                          //         message: 'Please select date of purchase!'
                          //     }
                          // ],
                        })(
                          <DatePicker
                            format="DD-MM-YYYY"
                            onChange={this.enableWarrantExpDate}
                            disabledDate={(current) => {
                              return current > moment();
                            }}
                          />
                        )}
                      </Form.Item>
                    </div>
                  </Col>
                  <Col className="gutter-row col" xs={24} sm={24} md={12} lg={12} xl={12}>
                    <div className="form-group">
                      <Form.Item label="Warranty Exp. Date" colon={false}>
                        {getFieldDecorator("warranty_exp_date", {
                          initialValue:
                            editAsset && editAsset.warranty_exp_date
                              ? moment(editAsset.warranty_exp_date)
                              : "",
                        })(
                          <DatePicker
                            format="DD-MM-YYYY"
                            // disabled={!getFieldValue("purchase_date")}
                            disabledDate={(current) => {
                              return current < getFieldValue("purchase_date");
                            }}
                          />
                        )}
                      </Form.Item>
                    </div>
                  </Col>
                </Row>
              </div>

              <div className="form-content">
                <Row className="row" gutter={30}>
                  <Col className="gutter-row col" xs={24} sm={24} md={12} lg={12} xl={12}>
                    <div className="form-group">
                      {/* <label className="label">Serial No.</label> */}
                      <Form.Item label="Serial No." colon={false}>
                        {getFieldDecorator("serial_number", {
                          initialValue: editAsset ? editAsset.serial_number : null,
                          validateTrigger: "onBlur",
                        })(<Input placeholder="Enter serial no." />)}
                      </Form.Item>
                    </div>
                  </Col>
                  <Col className="gutter-row col" xs={24} sm={24} md={12} lg={12} xl={12}>
                    <div className="form-group">
                      <Form.Item label="Reference ID" colon={false}>
                        {getFieldDecorator("reference_id", {
                          initialValue: editAsset ? editAsset.reference_id : "",
                          rules: [
                            {
                              required: true,
                              message: "Enter valid Reference ID",
                            },
                          ],
                          validateTrigger: "onBlur",
                        })(
                          <Input
                            placeholder="Enter Reference ID."
                            onBlur={(e) => (e.target.value = e.target.value.replace(/\s/g, ""))}
                          />
                        )}
                      </Form.Item>
                    </div>
                  </Col>
                </Row>
              </div>

              <div className="form-content">
                <Row className="row" gutter={30}>
                  <Col className="gutter-row col" xs={24} sm={24} md={24} lg={24} xl={24}>
                    <div className="form-group">
                      <Form.Item label="Notes" colon={false}>
                        {getFieldDecorator("notes", {
                          initialValue: editAsset ? editAsset.notes : null,
                          validateTrigger: "onBlur",
                        })(
                          <TextArea
                            placeholder="Enter assets notes here..."
                            allowClear
                            onTextareaChange={onTextareaChange}
                          />
                        )}
                      </Form.Item>
                    </div>
                  </Col>
                </Row>
              </div>
              {editAsset && editAsset.images && editAsset.images.length >= 0 && (
                <div className="form-content">
                  <Row className="row" gutter={30}>
                    <Col className="gutter-row col" xs={24} sm={24} md={24} lg={24} xl={24}>
                      <div className="form-group">
                        {uploadedImages && uploadedImages.length > 0 && (
                          <label className="label">Uploaded Images</label>
                        )}
                        <div className="clearfix">
                          <div className="upload-image fx">
                            <Upload
                              listType="picture-card"
                              fileList={uploadedImages}
                              onRemove={this.handleRemoveApi}
                              showUploadList={{
                                showPreviewIcon: false,
                                showRemoveIcon: true,
                                showDownloadIcon: false,
                              }}
                            >
                              {null}
                            </Upload>
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
              )}

              <div className="form-content">
                <Row className="row" gutter={30}>
                  <Col className="gutter-row col" xs={24} sm={24} md={24} lg={24} xl={24}>
                    <div className="form-group">
                      {uploadedImages && uploadedImages.length < 10 && (
                        <label className="label">Want to add new images?
                          <i
                            className="fas fa-info-circle"
                            onMouseEnter={this.handleHover}
                            onMouseLeave={this.handleHover}
                          />
                          {this.state.hover && <p>max 10 images</p>}
                         </label>
                      )}
                      <div className="clearfix">
                        <div className="upload-image fx">
                          <Upload
                            listType="picture-card"
                            beforeUpload={() => false}
                            fileList={fileList}
                            onPreview={this.handlePreview}
                            onChange={this.handleChange}
                            showUploadList={{
                              showPreviewIcon: false,
                              showRemoveIcon: true,
                              showDownloadIcon: false,
                            }}
                          >
                            {(uploadedImages ? uploadedImages.length : 0) + fileList.length >= 10
                              ? null
                              : uploadButton}
                          </Upload>

                          <Modal
                            visible={previewVisible}
                            footer={null}
                            onCancel={this.handleCancel}
                          >
                            <img alt="example" style={{ width: "100%" }} src={previewImage} />
                          </Modal>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
            </form>
          </div>
        </div>

        <div className="formAction-footer-cont">
          <div className="formAction-footer text-right">
            <Button
              onClick={this.handleOk}
              key="submit"
              loading={saveButtonLoading}
              className="btn btn-success text-uppercase"
            >
              Save
            </Button>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default Form.create({ name: "add_asset" })(ForAdminAndOfficeAdmin(withStore(AddNewAsset)));

