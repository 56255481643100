import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";
import { Layout } from "antd";

import LeaveManagement from "./leave/LeaveManagement";
import ManageLeaveManagement from "./admin/components/LeaveManagement/LeaveManagement";
import SalaryManagement from "./salary/SalaryManagement";
import DocumentManagement from "./document/DocumentManagement";
import HolidayManagement from "./holiday/HolidayManagement";
import ProfileManagement from "./profile/ProfileManagement";
import SalaryAdminPanel from "./admin/components/SalaryAdminPanel";
import DocumentAdminPanel from "./admin/components/Documents/DocumentAdminPanel";
import DocumentAdminFolder from "./admin/components/Documents/DocumentAdminFolder";
import DocumentFolderList from "./document/components/DocumentFolderList";
import HolidayAdminPanel from "./admin/components/HolidayAdminPanel";
import ProfileAdminPanel from "./admin/components/ProfileAdminPanel";
import ManageAdminPanel from "./admin/components/ManageAdminPanel";
import NotificationManagement from "./NotificationManagement/NotificationManagement";
import EmployeeNotification from "./NotificationManagement/EmployeeNotification";
import NewNotification from "./NotificationManagement/components/NewNotification";
import DashBoardMobile from "./dashboard/components/DashBoardMobile";
import ManageProject from "./ProjectManagement/ManageProject";
import Attendance from "./attendance/Attendance";
import ManageAttendance from "./attendance/ManageAttendance";
import RequireAuth from "../hocs/RequireAuth";
import { withStore } from "../hocs/withStore";
import ManageOrganization from "./superAdmin/components/ManageOrganization";
// import ManagePermission from "./superAdmin/components/ManagePermission";
import TeamDirectoryManagement from "./teamDirectory/TeamDirectoryManagement";
import DailyStatusMangement from "./admin/components/DailyStatusManagement/DailyStatusManagement";
import EmpDailyStatusMangement from "../app/dailyStatusManagement/EmpDailyStatusManagement";
import BillManagement from "../app/BillManagement/BillManagement";
import EmpBillManagement from "../app/BillManagement/EmpBillManagement";

import HeaderPrimary from "../shared/components/Header";
import SiderPrimary from "../shared/components/Sider";
import DashboardManagement from "./dashboard/DashboardManagement";
import Settings from "./admin/components/Settings";
// import AddSkills from './admin/components/AddSkills';
// import AddTeams from './admin/components/AddTeams';
// import AddLevels from './admin/components/AddLevels';
import IntercomDirectoryManagement from "./admin/components/intercomDirectory/IntercomDirectoryManagement";
import EmpIntercomDirectory from "./empIntercomDirectory/components/EmpIntercomDirectory";
import ItAssetsManagement from "./itAssetsManagement/ItAssetsManagement";
import ItAssetsManagementAdminPanel from "./admin/components/ManageItAssetsManagement/ItAssetsManagementAdminPanel";
import ProjDashboard from "./ProjectManagementDashboard/ProjDashboard";

class DashboardContainer extends Component {
  render() {
    return (
      <Layout className="main-container illustrationImage">
        <SiderPrimary></SiderPrimary>

        <div className="parent-content-section">
          {window.location.href.endsWith("/dashboard/home") ? (
            <div className="dashboard-home">
              <HeaderPrimary></HeaderPrimary>
              <Layout className="page-content-section">
                <Switch>
                  <Route path="/dashboard/home" component={DashboardManagement}></Route>
                </Switch>
              </Layout>
            </div>
          ) : (
            <div className="rest-pages">
              <HeaderPrimary></HeaderPrimary>
              <Layout className="page-content-section">
                <Switch>
                  <Route path="/dashboard/leave-management" component={LeaveManagement}></Route>
                  <Route
                    path="/dashboard/admin/leave-management"
                    component={ManageLeaveManagement}
                  ></Route>
                  <Route path="/dashboard/salary" component={SalaryManagement}></Route>
                  <Route path="/dashboard/document" component={DocumentManagement}></Route>
                  <Route
                    path="/dashboard/admin/document-folder"
                    component={DocumentAdminFolder}
                  ></Route>
                  <Route
                    path="/dashboard/document-folder-list"
                    component={DocumentFolderList}
                  ></Route>
                  <Route path="/dashboard/holiday" component={HolidayManagement}></Route>
                  <Route path="/dashboard/profile" component={ProfileManagement}></Route>
                  <Route
                    path="/dashboard/team-directory"
                    component={TeamDirectoryManagement}
                  ></Route>
                  <Route path="/dashboard/admin/salary" component={SalaryAdminPanel}></Route>
                  <Route path="/dashboard/admin/document" component={DocumentAdminPanel}></Route>
                  <Route path="/dashboard/admin/holiday" component={HolidayAdminPanel}></Route>
                  <Route path="/dashboard/employee" component={ProfileAdminPanel}></Route>
                  <Route path="/dashboard/admin/manage" component={ManageAdminPanel}></Route>
                  <Route path="/dashboard/admin/settings" component={Settings}></Route>
                  <Route
                    path="/dashboard/admin/manage-intercom"
                    component={IntercomDirectoryManagement}
                  ></Route>
                  <Route path="/dashboard/intercom" component={EmpIntercomDirectory}></Route>
                  <Route
                    path="/dashboard/admin/manage-it-assets"
                    component={ItAssetsManagementAdminPanel}
                  ></Route>
                   <Route path="/dashboard/admin/bill-management" component={BillManagement}></Route>
                  <Route path="/dashboard/my-bills" component={EmpBillManagement}></Route>
                  <Route
                    path="/dashboard/it-assets-management"
                    component={ItAssetsManagement}
                  ></Route>
                  <Route
                    path="/dashboard/admin/work-reports"
                    component={DailyStatusMangement}
                  ></Route>
                  <Route path="/dashboard/work-reports" component={EmpDailyStatusMangement}></Route>
                 <Route path="/dashboard/attendance" component={Attendance}></Route>
                 <Route path="/dashboard/admin/attendance" component={ManageAttendance}></Route>
                  <Route
                    path="/dashboard/admin/announcements"
                    component={NotificationManagement}
                  ></Route>
                  <Route path="/dashboard/announcements" component={EmployeeNotification}></Route>
                  <Route path="/dashboard/new-announcement" component={NewNotification}></Route>
                  <Route path="/dashboard/Mobile" component={DashBoardMobile}></Route>
                  <Route
                    path="/dashboard/admin/project-management"
                    component={ManageProject}
                  ></Route>
                  <Route
                    path="/dashboard/admin/project-dashboard"
                    component={ProjDashboard}
                  ></Route>
                  <Route
                    path="/dashboard/projects"
                    component={ManageProject}
                  ></Route>

                  {/* <Route path="/dashboard/admin/add-skills" component={AddSkills}></Route>
                  <Route path="/dashboard/admin/add-teams" component={AddTeams}></Route>
                  <Route path="/dashboard/admin/add-levels" component={AddLevels}></Route> */}
                  {/** -------------------------------------------------------------------- */}
                  {/* <Route path="/dashboard/admin/rewards-management" component={SbMilesManagement}></Route>
                  <Route path="/dashboard/vendor-management" component={VendorManageemnt}></Route>
                  <Route path="/dashboard/rewards-management" component={RewardManagement}></Route>
                  <Route path="/dashboard/admin/sprint-management" component={SprintManagement}></Route>
                  <Route path="/dashboard/sprint-management" component={EmpSprintManagement}></Route>
                  <Route path="/dashboard/admin/petty-cash-management" component={PettyCashManagement}></Route>
                  <Route path="/dashboard/admin/bill-management" component={BillManagement}></Route>
                  <Route path="/dashboard/bill-management" component={EmpBillManagement}></Route> */}
                  {/* Super Admin Route */}
                  {/* <Route path="/dashboard/superadmin/organization" component={ManageOrganization}></Route> */}
                  {/* <Route path="/dashboard/superadmin/permission" component={ManagePermission}></Route> */}
                </Switch>
              </Layout>
            </div>
          )}
        </div>
      </Layout>
    );
  }
}

export default RequireAuth(withStore(DashboardContainer));
