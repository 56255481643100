import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import {
  Icon,
  notification,
  Button,
  Row,
  Col,
  Card,
  Pagination,
  Spin,
  Input,
} from 'antd';
import moment from 'moment';
import _ from 'lodash';
import { axiosInstance as api } from '../../../utilities/configureAxios';
import url from '../../../config/url';
import RequireAuth from '../../../hocs/RequireAuth';
import { withStore } from '../../../hocs/withStore';
import ProfileComplete from '../../../hocs/ProfileComplete';
import fileIcon from '../../../config/fileIcon';
import compDocument from '../../../assets/images/icons/icon-comp-document-bg.svg';
import CompanyDocument from '../../../assets/images/icons/sibebar/company-documents.svg';
import folder from '../../../assets/images/icons/sibebar/folder-icon.svg';
import { file } from '@babel/types';
import NotificationUpdate from '../../../hocs/NotificationUpdate';

import {
  getFolder,
  getFolderWiseDocument,
} from '../../admin/components/Documents/DataManager';

// Pagination
function itemRender(current, type, originalElement) {
  if (type === 'prev') {
    return (
      <a className="back-icon">
        {' '}
        <Icon type="left" /> Back
      </a>
    );
  }
  if (type === 'next') {
    return (
      <a className="next-icon">
        Next <Icon type="right" />
      </a>
    );
  }
  return originalElement;
}

// For search
const { Search } = Input;

class Document extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      loading2: false,
      documents: [],
      folders: null,
      slug: '',
      q: '',
      total: 0,
      per_page: 1,
      current_page: 1,
      pagination2: {
        total2: 0,
        count2: 0,
        per_page2: 1,
        current_page2: 1,
        total_pages2: 1,
      },
    };
  }

  componentDidMount = () => {
    this.onPageLoad();
    this.getDocumentFolders();
  };

  onPageLoad = () => {
    const { slug, q } = this.state;
    this.setState({ loading: true });

    getFolderWiseDocument(slug, q, 1)
      .then((res) => {
        const documents = _.get(res, 'data.data', []);
        const total = _.get(res, 'data.meta.pagination.total', 0);
        const per_page = _.get(res, 'data.meta.pagination.per_page', 1);
        const current_page = _.get(res, 'data.meta.pagination.current_page', 1);
        this.setState({
          documents,
          total,
          per_page,
          current_page,
          loading: false,
        });
      })
      .catch((error) => {
        notification['error']({
          message: _.get(
            error,
            'response.data.errors.message',
            'Oops! Something went wrong!'
          ),
        });
        this.setState({ loading: false });
      });
  };

  // onPageChange = (pageNumber) => {
  //     let state = this.state
  //     state.loading = true
  //     state.pagination.current_page = pageNumber
  //     this.setState({ state });

  //     const { slug, q } = this.state

  //     getFolderWiseDocument(slug, q, pageNumber)
  //         .then(res => {
  //             const documents = _.get(res, 'data.data', [])
  //             const pagination = res.data.meta.pagination;
  //             this.setState({ documents, loading: false, pagination });
  //         })
  //         .catch(error => {
  //             notification['error']({
  //                 message: _.get(error, 'response.data.errors.message', "Oops! Something went wrong!")
  //             });
  //             this.setState({ loading: false });
  //         });
  // }

  onPageChange = (pageNumber) => {
    this.setState({ loading: true, current_page: pageNumber });
    const { slug, q } = this.state;

    getFolderWiseDocument(slug, q, pageNumber)
      .then((res) => {
        const documents = _.get(res, 'data.data', []);
        const total = _.get(res, 'data.meta.pagination.total', 0);
        const per_page = _.get(res, 'data.meta.pagination.per_page', 0);
        const current_page = _.get(res, 'data.meta.pagination.current_page', 1);
        this.setState({
          documents,
          total,
          per_page,
          current_page,
          loading: false,
        });
      })
      .catch((error) => {
        notification['error']({
          message: _.get(
            error,
            'response.data.errors.message',
            'Oops! Something went wrong!'
          ),
        });
        this.setState({ loading: false });
      });
  };

  getDate = (date) => {
    let new_date = new Date(date);
    return new_date.toDateString();
  };

  getFileIcon = (type) => {
    return fileIcon[type];
  };

  count = (data) => {
    return data.length;
  };

  onSearchDoc = (e) => {
    this.setState({ q: e.target.value }, () => {
      this.onPageLoad();
    });
  };
  getDocumentFolders = () => {
    return getFolder(1, true)
      .then((res) => {
        const folders = res.data;
        const total2 = res.meta.pagination.total;
        const per_page2 = res.meta.pagination.per_page;
        const current_page2 = res.meta.pagination.current_page;
        const count2 = res.meta.pagination.count;
        const total_pages2 = res.meta.pagination.total_pages;

        const pagination = {
          total2,
          per_page2,
          current_page2,
          count2,
          total_pages2,
        };

        this.setState({ folders: folders, pagination2: pagination });
      })
      .catch((error) => {
        notification['error']({
          message: _.get(
            error,
            'response.data.errors.message',
            'Oops! Something went wrong!'
          ),
        });
        this.setState({ loading: false });
      });
  };

  onPageChange2 = (pageNumber) => {
    this.setState({ loading2: true });
    return getFolder(pageNumber, true)
      .then((res) => {
        const folders = res.data;
        const total2 = res.meta.pagination.total;
        const per_page2 = res.meta.pagination.per_page;
        const current_page2 = res.meta.pagination.current_page;
        const count2 = res.meta.pagination.count;
        const total_pages2 = res.meta.pagination.total_pages;

        const pagination = {
          total2,
          per_page2,
          current_page2,
          count2,
          total_pages2,
        };

        this.setState({
          folders: folders,
          pagination2: pagination,
          loading2: false,
        });
      })
      .catch((error) => {
        notification['error']({
          message: _.get(
            error,
            'response.data.errors.message',
            'Oops! Something went wrong!'
          ),
        });
        this.setState({ loading2: false });
      });
  };

  render() {
    const {
      documents,
      total,
      per_page,
      current_page,
      loading,
      folders,
      loading2,
      q,
    } = this.state;
    const { total2, per_page2, current_page2 } = this.state.pagination2;
    return (
      <div className="document-dashboard-container">
        <Fragment>
          {/* page Header */}
          <div className="page-header animated slideInUp ">
            <div className="page-title">
              <h2 className="title">
                <img
                  className="icon icon-view"
                  src={CompanyDocument}
                  alt="icon"
                />{' '}
                Documents
              </h2>
            </div>
          </div>

          {/* page info title */}

          <section className="page-info-box animated slideInRight">
            <h3 className="info-title">All Documents</h3>
            <div className="right">
              <div className="search-box">
                <Input
                  onChange={this.onSearchDoc}
                  placeholder="Search Documents"
                  suffix={<Icon type="search" />}
                />
              </div>
            </div>
          </section>

          {/* Document cards */}

          {loading ? (
            <center>
              <Spin />
            </center>
          ) : (
            <section>
              <section className="content-section document-card-section animated slideInUp">
                {/* folder structure */}
                {loading2 ? (
                  <center>
                    <Spin />
                  </center>
                ) : (
                  <div className="docFolder-container document-container">
                    {q == '' && (
                      <div>
                        {folders && folders.length != 0 && (
                          <h3 className="text-primary">Folder Directories</h3>
                        )}
                        <Row gutter={30} className="row">
                          {folders &&
                            folders.map((docFolder) => (
                              <Col
                                className="gutter-row col"
                                xs={24}
                                sm={24}
                                md={24}
                                lg={12}
                                xl={12}
                                xxl={12}
                              >
                                <Link
                                  to={
                                    '/dashboard/document-folder-list?id=' +
                                    docFolder.slug
                                  }
                                >
                                  <Card className="card card-shadow docFolder-card">
                                    <div className="icon-box">
                                      <img
                                        className="icon icon-link"
                                        src={folder}
                                        alt="image"
                                      />
                                    </div>
                                    <div className="title-box">
                                      <h4 className="title">
                                        {docFolder.name}
                                      </h4>
                                    </div>
                                  </Card>
                                </Link>
                              </Col>
                            ))}
                        </Row>
                        {total2 > 0 && per_page2 > 0 && total2 > per_page2 && (
                          <div className="pagination-box text-right">
                            <Pagination
                              className="pagination"
                              onChange={this.onPageChange2}
                              current={current_page2}
                              total={total2}
                              itemRender={itemRender}
                              pageSize={per_page2}
                            />
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                )}

                <div className="docFile-container document-container">
                  {folders && folders.length != 0 && (
                    <h3 className="text-primary">Root Directory</h3>
                  )}
                  <Row className="" gutter={24}>
                    {documents.map((document) => (
                      <Col
                        className="gutter-row"
                        key={document.id}
                        xs={24}
                        sm={24}
                        md={24}
                        lg={12}
                        xl={12}
                        xxl={8}
                      >
                        <Card className="card card-shadow doc-card">
                          <div className="doc-icon-box">
                            <img
                              className="icon icon-link"
                              src={this.getFileIcon(document.file_type)}
                              alt="link icon"
                            />
                          </div>
                          <div className="doc-title-box">
                            <h4 className="title">{document.title}</h4>
                            <p className="last-update text-gray">
                              Last modified:{' '}
                              {moment(
                                document.updated_at,
                                'YYYY-MM-DD HH:mm:ss'
                              ).format('DD-MMM-YYYY')}
                            </p>
                          </div>
                          <div className="doc-link-box">
                            {/* <Button className="btn btn-light-primary btn-icon"><Icon type="eye" theme="filled" /></Button> */}
                            <a href={document.url} target="_blank">
                              <Button className="btn btn-light-secondary btn-icon">
                                {document.type == 'link' && (
                                  <Fragment>
                                    <Icon type="link" />
                                  </Fragment>
                                )}
                                {document.type == 'file' && (
                                  <Fragment>
                                    <Icon type="download" />
                                  </Fragment>
                                )}
                              </Button>
                            </a>
                          </div>
                        </Card>
                      </Col>
                    ))}
                  </Row>
                </div>
              </section>

              {documents.length == 0 && (
                <section className="page-info-box text-center">
                  <h3 className="info-title">Sorry! No documents found!</h3>
                </section>
              )}

              {documents.length != 0 &&
                total > 0 &&
                per_page > 0 &&
                total > per_page && (
                  <section className="pagination-container">
                    <div className="pagination-box text-right">
                      <Pagination
                        className="pagination"
                        onChange={this.onPageChange}
                        current={current_page}
                        total={total}
                        itemRender={itemRender}
                        pageSize={per_page}
                      />
                    </div>
                  </section>
                )}
            </section>
          )}
        </Fragment>
      </div>
    );
  }
}

export default RequireAuth(withStore(ProfileComplete(NotificationUpdate(Document))));
