import { getEmployeeWorkReportsService, addNewWorkReportForEmployee,getAllEmployeeLeaveList } from '../../services/DailyStatusService'

export const getEmployeeWorkReports = (page) => {

    return getEmployeeWorkReportsService(page)
        .then(result => {
            return result.data;
        })
        .catch(error => {
            throw error;
        })
}

export const addNewWorkReport = (body) => {

    return addNewWorkReportForEmployee(body)
        .then(result => {
            return result
        })
        .catch(error => {
            throw error
        })
}
export const getAllEmployeeList = (body,type) => {

    return getAllEmployeeLeaveList(body,type)
        .then(result => {
            return result
        })
        .catch(error => {
            throw error
        })
}
