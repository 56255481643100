import moment from 'moment';
import renderHTML from 'react-render-html'
import { convertMinutesToHoursAndMinutesString } from '../../../../../../utilities/DateUtility'
import { getWorkReportsForAdminOfAnEmployee } from '../../DataManager'
import React, { Component, Fragment } from "react";
import { Icon, Input, DatePicker, Upload, Modal, Divider, notification, Collapse, Pagination, Spin, Button } from "antd";
import filterIcon from "../../../../../../assets/images/icons/sprint/filter.svg";
import arrow from "../../../../../../assets/images/icons/sprint/right-arrow.svg";
import calendar from "../../../../../../assets/images/icons/monthly-calendar-white.svg";

const { Panel } = Collapse;

function callback(key) {
}

// Pagination
function itemRender(current, type, originalElement) {
  if (type === 'prev') {
    return <a className="back-icon"> <Icon type="left" /> Back</a>;
  }
  if (type === 'next') {
    return <a className="next-icon">Next <Icon type="right" /></a>;
  }
  return originalElement;
}

// for upload
function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
}


// for textarea
const { TextArea } = Input;

const onTextareaChange = e => {
};

export default class UserWorkStatus extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fromDate: null,
      toDate: null,
      workReports: null,
      user: null,
      endOpen: false,
      value: 1,
      showDetails1: false,
      showDetails2: false,
      showDetails3: false,
      visible: false,
      loading: false,
      previewVisible: false,
      previewImage: '',
      fileList: [],
      pagination: {
        total: 0,
        count: 0,
        per_page: 1,
        current_page: 1,
        total_pages: 1
      }
    }
  }


  componentDidMount() {
    this.setState({
      fromDate: moment().subtract(9, 'days'), toDate: moment(), user: this.props.user
    }, () => { this.getWorkReportOfAnEmployeeForAdminFunction() })
  }

  componentWillUnmount() {

  }

  // ================================ More details toggle ================================
  toggleDetails1 = () => {
    var current_value = this.state.showDetails1;
    this.setState({ showDetails1: !current_value });
  }

  // for upload
  handleCancel = () => this.setState({ previewVisible: false });

  handlePreview = async file => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }

    this.setState({
      previewImage: file.url || file.preview,
      previewVisible: true,
    });
  };

  handleChange = ({ fileList }) => this.setState({ fileList });

  removeImg = (id) => {
    var fileList = this.state.fileList
    var updatedFiles = fileList.filter((file) => {
      return file.uid !== id;
    });
    this.setState({ fileList: updatedFiles })
  }

  // for calendar
  disabledStartDate = fromDate => {
    const { toDate } = this.state;
    if (!fromDate || !toDate) {
      return false;
    }
    return fromDate.valueOf() > toDate.valueOf();
  };

  disabledEndDate = toDate => {
    const { fromDate } = this.state;
    if (!toDate || !fromDate) {
      return false;
    }
    return toDate.valueOf() <= fromDate.valueOf();
  };

  onChange = (field, value) => {
    this.setState({
      [field]: value,
    }, () => { this.getWorkReportOfAnEmployeeForAdminFunction() });
  };

  onStartChange = value => {
    this.onChange('fromDate', value);
  };

  onEndChange = value => {
    this.onChange('toDate', value);
  };

  handleStartOpenChange = open => {
    if (!open) {
      this.setState({ endOpen: true });
    }
  };

  handleEndOpenChange = open => {
    this.setState({ endOpen: open });
  };

  getCalendarContainer1() {
    return this.d || document.getElementById('calendarContainer1');
  }

  getCalendarContainer2() {
    return this.d || document.getElementById('calendarContainer2');
  }

  prepareFileList = (images) => {
    var returnableFilelist = []
    images.map((image, index) => (
      returnableFilelist.push({
        'uid': index,
        'status': 'done',
        'url': image
      })
    ));
    return returnableFilelist
  }

  parseDate = (date, returnable) => {
    var date = moment(date, "YYYY-MM-DD HH:mm:ss");
    if (returnable === "weekday")
      return date.format('dddd').substring(0, 3);
    if (returnable === "dayOfMonth")
      return date.date();
  }

  getFormattedDateTime = (dateTime) => {
    return (moment(dateTime).format('DD-MM-YYYY HH:mm'))
  }

  onPageChange = (pageNumber) => {
    this.setState({ loading: true });
    this.getWorkReportOfAnEmployeeForAdminFunction(pageNumber)
  }

  getWorkReportOfAnEmployeeForAdminFunction = (page = 1) => {
    this.setState({ loading: true })
    const { fromDate, toDate, user } = this.state
    let filterFromDate = moment().subtract(9, 'days')
    let filterToDate = moment()
    filterFromDate = fromDate && fromDate ? fromDate : filterFromDate
    filterToDate = toDate && toDate ? toDate : filterToDate
    let user_id = user.id

    return getWorkReportsForAdminOfAnEmployee(page, filterFromDate.format('YYYY-MM-DD'), filterToDate.format('YYYY-MM-DD'), user_id)
      .then(res => {
        this.setState({ workReports: res.data, pagination: res.meta.pagination, loading: false })
      })
      .catch(error => {
        this.setState({ loading: false })
        notification.error({
          message: error.response.data.errors.message
        })
      })
  }

  isWorkReportMissing(workReport) {
    if (!workReport.id && !workReport.reason.is_weekend && !workReport.reason.is_holiday && workReport.reason.on_leave===0) {
      return true
    }
    return false
  }

  isHolidayOrWeekend(workReport) {
    if (workReport.reason && (workReport.reason.is_weekend || workReport.reason.is_holiday)) {
      return true
    }
    return false
  }


  render() {

    const { previewVisible, previewImage, fileList, fromDate, toDate, endOpen, workReports, loading } = this.state;
    const { total, per_page, current_page } = this.state.pagination;
    const { user } = this.props.user
    const uploadButton = (
      <div>
        <Icon type="plus" />
        <div className="ant-upload-text">Upload</div>
      </div>
    );
    return (
      <Fragment>
        <div className="userWorkStatus-container">
          <h3 className="text-primary">Daily work updates 📋</h3>

          <div className="filter-row">
            <div className="filter-dropdowns fx fx--ai-c">
              <div className="rangePicker-box">
                <div className="from" id="calendarContainer1">
                  <DatePicker
                    disabledDate={this.disabledStartDate}
                    format="DD-MM-YYYY"
                    value={fromDate}
                    placeholder="From"
                    onChange={this.onStartChange}
                    onOpenChange={this.handleStartOpenChange}
                    getCalendarContainer={this.getCalendarContainer1}
                    suffixIcon={
                      <img className="calendar-icon" src={calendar} alt="calendar icon" />
                    }
                  />
                </div>
                <div className="inBetween-arrow">
                  <img className="icon arrrow" src={arrow} alt="arrow icon" />
                </div>
                <div className="to" id="calendarContainer2">
                  <DatePicker
                    disabledDate={this.disabledEndDate}
                    format="DD-MM-YYYY"
                    value={toDate}
                    placeholder="To"
                    onChange={this.onEndChange}
                    open={endOpen}
                    onOpenChange={this.handleEndOpenChange}
                    getCalendarContainer={this.getCalendarContainer2}
                    suffixIcon={
                      <img className="calendar-icon" src={calendar} alt="calendar icon" />
                    }
                  />
                </div>
              </div>
            </div>
          </div>

          {loading ? <div className="page-spinner" > <Spin /></div> : (

            <section className="content-section dailyStatusInfoCard drawerContent">
              <Collapse
                expandIconPosition={"right"}
                onChange={callback}
                bordered="false"
              >
                {workReports && workReports.map((workReport, index) => (
                  <Panel
                    key={index}
                    header={<div className="dayWiseTask-block">
                      <div className="headContent fx fx--ai-c fx--jc-sb" onClick={this.toggleDetails1}>
                        <div className="first-part part">
                          <div className="heading-details fx fx--ai-c">
                            <div className={`dateDetail-box ${this.isHolidayOrWeekend(workReport) ? 'disable' : ''}`}>
                              <div className="single">
                                <div className="month-name heading">{this.parseDate(workReport.date, 'weekday')}</div>
                                <div className="date-content fullDay">{this.parseDate(workReport.date, 'dayOfMonth')}</div>
                              </div>
                            </div>
                            <div className="workStatus-info">
                              {workReport.id && < div className="work-status text-success text-uppercase">Received</div>}
                              {workReport.id && <div className="userInfo">< div className="userName text-success">{workReport.name}</div> <div className="timestamp mt--10"><i><b>Submitted at: </b>{this.getFormattedDateTime(workReport.created_at)}</i></div></div>}
                              {!workReport.id && workReport.reason.is_weekend && !workReport.reason.is_holiday && < div className="work-status text-warning text-uppercase">Weekly Off</div>}
                              {!workReport.id && workReport.reason.is_holiday && !workReport.reason.is_weekend && < div className="work-status text-warning text-uppercase">Public Holiday ({workReport.reason.is_holiday})</div>}
                              {!workReport.id && !workReport.reason.is_holiday && !workReport.reason.is_weekend && workReport.reason.on_leave===0 && < div className="work-status text-danger text-uppercase">Not Updated </div>}
                              {!workReport.id && !workReport.reason.is_holiday && !workReport.reason.is_weekend && workReport.reason.on_leave===1 && < div className="work-status text-warning text-uppercase">On Leave </div>}
                              {!workReport.id && workReport.reason.is_holiday && workReport.reason.is_weekend && < div className="work-status text-warning text-uppercase">Weekly Off and {workReport.reason.is_holiday}</div>}

                            </div>
                          </div>
                        </div>
                      </div>
                    </div>}
                  >

                    {workReport.work_reports &&
                      <div className="bodyContent animated fadeIn">
                        {workReport.work_reports.map((eachWorkReport, innerIndex) => (
                          <Fragment>
                            <div className="workStatus-info">
                              <div className="work-status-heading">
                                {eachWorkReport.title}
                                {eachWorkReport.duration && <div className="project-time-spent"><i>(Time Consumed: {convertMinutesToHoursAndMinutesString(eachWorkReport.duration)})</i></div>}
                              </div>
                              {/* <div className="description">{eachWorkReport.description}</div> */}
                              <div className="description">{renderHTML(eachWorkReport.description)}</div>
                              {eachWorkReport.images.length != 0 &&
                                <div className="imgAttachment-box">
                                  <div className="clearfix">
                                    <Upload
                                      action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                                      listType="picture-card"
                                      fileList={this.prepareFileList(eachWorkReport.images)}
                                      onPreview={this.handlePreview}
                                      onChange={this.handleChange}
                                      showUploadList={{ showPreviewIcon: true, showRemoveIcon: false, showDownloadIcon: false }}
                                    >
                                      {/* {fileList.length >= 2 ? null : uploadButton} */}
                                    </Upload>
                                    <Modal className="viewImgModal" visible={previewVisible} footer={null} onCancel={this.handleCancel}>
                                      <img alt="example" style={{ width: '100%' }} src={previewImage} />
                                    </Modal>
                                  </div>
                                </div>
                              }
                            </div>

                            {((workReport.work_reports.length - 1) != innerIndex) && < Divider type="horizontal" />}
                            {((workReport.work_reports.length - 1) == innerIndex) &&
                              <div className="status-strip-box fx fx--ai-c fx--jc-sb">
                                {workReport.day_type && <div className="dayStatus"><i><b>Day Status: </b>{workReport.day_type === 'full_day' ? 'Full Day' : 'Half Day'}</i></div>}
                                {/* <div className="timestamp text-right"><i><b>Submitted at: </b>{this.getFormattedDateTime(workReport.created_at)}</i></div> */}
                              </div>
                            }
                          </Fragment>
                        ))}
                      </div>
                    }

                    {this.isWorkReportMissing(workReport) &&
                      <div className="bodyContent animated fadeIn">
                        <div className="workStatus-info">
                          <div className="emptyState">
                            <div className="message mb--20">OOPS, Work log is missing for this date.</div>
                            {/* <div className="forgotWorkStatus-sendBtn">
                                                            <Button className="btn btn-light-primary" onClick={this.showDrawer}>Send it now</Button>
                                                        </div> */}
                          </div>
                        </div>
                      </div>
                    }

                    {!this.isWorkReportMissing(workReport) && !workReport.id &&
                      < div className="bodyContent animated fadeIn">
                        <div className="workStatus-info">
                          <div className="emptyState">
                            <div className="message mb--20"> Some days must not be counted :)</div>
                          </div>
                        </div>
                      </div>
                    }
                  </Panel>
                ))}
              </Collapse>
            </section>
          )}

          {/* Pagination */}
          <section className="pagination-container animated slideInRight mt--30">
            <div className="pagination-box text-right">
              <Pagination className="pagination" onChange={this.onPageChange} current={current_page} total={total} itemRender={itemRender} pageSize={per_page} />

            </div>
          </section>

        </div>
      </Fragment >
    )
  }
}