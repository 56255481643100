import * as APIs from '../constants/BackendConstant';
import { axiosInstance } from '../utilities/configureAxios';

// ASSET TYPE SERVICE
export const getPaginatedAssetTypesService = (paginate, pageNumber) => {
  let url = APIs.GET_PAGINATED_ASSET_TYPES.replace(':paginate', paginate); //GET_ASSET_TYPES
  url = url.replace(':page', pageNumber);
  return axiosInstance.get(url);
};

export const getAssetTypesService = () => {
  let url = APIs.GET_ASSET_TYPES; //GET_ASSET_TYPES
  return axiosInstance.get(url);
};

export const addAssetTypesService = (body) => {
  return axiosInstance.post(APIs.ADD_ASSET_TYPES, body);
};

export const deleteAssetTypesService = (id) => {
  let url = APIs.DELETE_ASSET_TYPES.replace(':id', id);
  return axiosInstance.delete(url);
};

// ASSET SERVICE
export const getAssetsService = (
  status,
  type_id,
  self,
  q,
  page,
  is_issuable
) => {
  let url = APIs.GET_ASSETS.replace(':status', status);
  url = url.replace(':type_id', type_id);
  url = url.replace(':self', self);
  url = url.replace(':q', q);
  url = url.replace(':page', page);
  url = url.replace(':is_issuable', is_issuable);
  url =
    status === 'allocated' || self === true
      ? url.replace(':allocated_images', ',allocated_images')
      : url.replace(':allocated_images', '');

  return axiosInstance.get(url);
};

export const addAssetsService = (body) => {
  let url = APIs.ADD_ASSETS;
  return axiosInstance.post(url, body);
};

export const deleteAssetsService = (id) => {
  let url = APIs.DELETE_ASSETS.replace(':uuid', id);
  return axiosInstance.delete(url);
};

export const editAssetsService = (id, body) => {
  let url = APIs.UPDATE_ASSETS.replace(':uuid', id);
  return axiosInstance.post(url, body);
};

export const getAssetsHistoryService = (id, page) => {
  let url = APIs.GET_ASSETS_HISTORY.replace(':id', id);
  url = url.replace(':page', page);
  return axiosInstance.get(url);
};

export const getEmployeeAllocatedAssetService = (type_id = '', id, q, page) => {
  let url = APIs.GET_EMPLOYEE_ALLOCATED_ASSETS.replace(':id', id);
  url = url.replace(':type_id', type_id);
  url = url.replace(':q', q);
  url = url.replace(':page', page);
  return axiosInstance.get(url);
};

export const allocateDeallocateAssetService = (id, body, qr_scan) => {
  let url = APIs.ALLOCATE_DEALLOCATE_ASSET.replace(':id', id);
  url = url.replace(':qr_scan', qr_scan);
  return axiosInstance.post(url, body);
};
