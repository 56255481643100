import React, { Component, Fragment } from "react";
import { Tooltip, Button, Icon, Divider, Drawer, notification, Popover, Input } from "antd";
import AssetsHistory from '../AssetsHistory';
import unassignable from '../../../../../assets/images/icons/unassignable.svg';
import assignable from '../../../../../assets/images/icons/assignable.svg';
import PrintComponents from "react-print-components";

// for textarea
const { TextArea } = Input;


export default class CardHeader extends Component {

    constructor(props) {
        super(props)
        this.state = {
            visible: false,
            assets: null,
            deallocation_notes: null,
            deallocation_loading: false
        }

        this.getAssetsHeader = this.getAssetsHeader.bind(this);
    }

    componentDidMount() {
        this.getAssetsHeader()
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.assetHeader !== this.props.assetHeader) {
            this.setState({ assets: this.props.assetHeader })
        }
    }

    showDrawer = () => {
        this.setState({
            visible: true,
        });
    };

    onClose = () => {
        this.setState({
            visible: false,
        });
    };

    getAssetsData() {
        this.props.loadAssets()
    }

    getAssetsHeader() {
        let asset = this.props.assetHeader
        this.setState({ assets: asset })
    }

    assetDeallocate = (id) => {
        this.setState({ deallocation_loading: true })
        this.props.deallocateAsset(id, this.state.deallocation_notes)
            .then(data => {
                notification.success({
                    message: 'Asset Deallocated successfully'
                });
                this.setState({ deallocation_loading: false })
                this.getAssetsData()
            })
            .catch(error => {
                notification.error({
                    message: error.response.data.errors.message
                });
                this.setState({ deallocation_loading: false })
            })
    }

    onChange = (e) => {
        this.setState({ deallocation_notes: e.target.value })
    }

    handleEditAsset = (asset) => {
        this.props.onEditAsset(asset)
    }

    render() {
        const { assets, deallocation_loading } = this.state

        var showQR = this.props.showQR;




        return (
            <Fragment >
                <div className="cardHeader-container">
                    <div className="cardHeader-flexView">
                        <div className="deviceName-box header-item">
                            <div className="deviceImg-box">
                                {assets && Object.keys(assets.is_issuable)[0] == '1' ?
                                    < Tooltip placement="bottom" title={'Issuable'}>
                                        <div className="device-image" style={{ backgroundImage: `url(${assignable})` }}></div>
                                    </Tooltip>
                                    :
                                    < Tooltip placement="bottom" title={'Non Issuable'}>
                                        <div className="device-image" style={{ backgroundImage: `url(${unassignable})` }}></div>
                                    </Tooltip>
                                }
                            </div>
                            <div className="device-name">{assets && assets.title}</div>
                        </div>
                        <div className="header-half-block">
                            <div className="deviceType-box header-item">
                                <div className="label lg-hide">Device Type</div>
                                <div className="deviceType-content">
                                    <div className="device-img-box">
                                        <div className="device-image" style={{ backgroundImage: `url(${assets && assets.type.url})` }}></div>
                                    </div>
                                    <h4 className="device-type">{assets && assets.reference_id}</h4>
                                </div>
                            </div>
                            <div className="allocatedTo-box assigned header-item">
                                <div className="label lg-hide">Allocated To</div>
                                <div className="allocated-userDetail">
                                    <div className="allocated-userContent">
                                        <div className="user-img-box">
                                            <div className="user-img" style={{ backgroundImage: `url(${assets && assets.allocations.length == 1 && assets.allocations[0].profile_pic})` }}></div>
                                        </div>
                                        <div className="user-name">{assets && assets.allocations.length == 1 && assets.allocations[0].assigned_to}</div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* action buttons */}
                        <div className="action-box">
                            <div className="action-box-buttons">
                                <Popover
                                    content={
                                        <div className="qrCode-optionBlock">

                                            {/* portrait mode */}
                                            <div className="qr-mode portrait mb--15">
                                                <PrintComponents
                                                    trigger={<Button className="btn btn-icon btn-light-primary qr-link portrait full-width text-uppercase"><Icon className="icon portrait-mode" type="mobile" />Print Portrait</Button>}
                                                >
                                                    <div className="qr-code-block" style={{ width: '100%' }}>
                                                        <table>
                                                            <tr>
                                                                <td>
                                                                    <div style={{ textAlign: 'center', }}>
                                                                        <img src={assets && assets.qr_code} alt="img" width="125" height="125" />
                                                                        <div style={{ fontSize: '18px', fontWeight: "600", textTransform: 'uppercase', }}>{assets && assets.reference_id}</div>
                                                                        <div style={{ fontSize: '10px', }}>{assets && assets.organization}</div>
                                                                        {/* <div style={{ fontSize: '12px', }}>Squareboat Solutions Pvt. Ltd.</div> */}
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        </table>
                                                    </div>
                                                </PrintComponents>
                                            </div>

                                            {/* landscape mode */}
                                            <div className="qr-mode landscape mb--15">
                                                <PrintComponents
                                                    trigger={<Button className="btn btn-icon btn-light-warning qr-link landscape full-width text-uppercase"><Icon className="icon landscape-mode" type="mobile" />Print Landscape</Button>}
                                                >
                                                    <div className="qr-code-block" style={{ border: '1px dashed #d9d9d9', borderRadius: '4px', width: '100%' }}>
                                                        <table>
                                                            <tr>
                                                                <td>
                                                                    <img src={assets && assets.qr_code} alt="img" width="125" height="125" />
                                                                </td>
                                                                <td style={{ verticalAlign: 'center', padding: '8px 0', }}>
                                                                    <div style={{ fontSize: '18px', fontWeight: "600", textTransform: 'uppercase', }}>{assets && assets.reference_id}</div>
                                                                    <div style={{ fontSize: '12px' }}>{assets && assets.organization}</div>
                                                                    {/* <div style={{ fontSize: '12px', }}>Squareboat Solutions Pvt. Ltd.</div> */}
                                                                </td>
                                                            </tr>
                                                        </table>
                                                    </div>
                                                </PrintComponents>
                                            </div>

                                            {/* Download QR */}
                                            <div className="qr-mode download">
                                                <Button href={assets && assets.qr_code} target="blank" type="link" className="btn btn-icon btn-light-secondary full-width qrDownload-link text-uppercase"><Icon className="icon qr-icon" type="qrcode" />Download QR Code</Button>
                                            </div>
                                        </div>
                                    }
                                    // title="Title"
                                    trigger="hover"
                                    placement="left"
                                >
                                    {/* action button in asset list */}
                                    <Tooltip placement="bottom" title={'QR Code'}>
                                        <Button type="link" className="btn btn-light-secondary btn-icon"><Icon type="qrcode" /></Button>
                                    </Tooltip>
                                </Popover>
                                <Divider type="vertical" />
                                <Tooltip placement="bottom" title={'History'}>
                                    <Button type="link" className="btn btn-light-success btn-icon" onClick={this.showDrawer}><Icon type="history" /></Button>
                                </Tooltip>
                                <Divider type="vertical" />

                                <Tooltip placement="bottom" title={'Edit'}>
                                    <Button type="link" className="btn btn-light-info btn-icon text-uppercase" onClick={() => this.handleEditAsset(assets)}><Icon type="edit" theme="filled" /></Button>
                                </Tooltip>
                                <Divider type="vertical" />

                                {/* <Popconfirm
                                    placement="bottomLeft"
                                    icon={<Icon type="warning" theme="twoTone" twoToneColor="#fcb103" />}
                                    title="Are you sure you want to Deallocate this Asset?"
                                    content={content}
                                    trigger="click"
                                    onConfirm={() => { this.assetDeallocate(assets.id) }}
                                    okText="Yes"
                                    cancelText="No"
                                >
                                    <Tooltip placement="bottom" title={'Deallocate'}>
                                        <Button className="btn btn-light-danger btn-icon" ><Icon type="logout" /></Button>
                                    </Tooltip>
                                </Popconfirm> */}

                                <Popover
                                    placement="bottomLeft"
                                    icon={<Icon type="warning" theme="twoTone" twoToneColor="#fcb103" />}
                                    title="Are you sure you want to Deallocate this Asset?"
                                    content={
                                        <div className="deallocation-popoverContent">
                                            <div className="content">
                                                <div className="form-group">
                                                    {/* <label className="label">Deallocation Notes (optional)</label> */}
                                                    <TextArea placeholder="Deallocation Notes (optional)" allowClear onChange={this.onChange} maxLength={100}/>
                                                </div>
                                                <div className="footer-cont text-right">
                                                    <div className="footer-action-box">
                                                        {/* <Button className="btn-light-danger btn-sm">No</Button> */}
                                                        <Button className="btn-light-success btn-sm ml--10"
                                                            onClick={() => {
                                                                this.assetDeallocate(assets.id)
                                                            }}
                                                            loading={deallocation_loading}
                                                        >Deallocate</Button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                    trigger="click"
                                    //onConfirm={() => { this.assetDeallocate(assets.id) }}
                                    okText="Yes"
                                    cancelText="No"
                                >
                                    <Tooltip placement="bottom" title={'Deallocate'}>
                                        <Button className="btn btn-light-danger btn-icon" ><Icon type="logout" /></Button>
                                    </Tooltip>
                                </Popover>

                            </div>
                        </div>
                    </div>
                </div>

                <Drawer
                    className="asset-history-drawer navigator-drawer"
                    title="Asset History"
                    width={500}
                    onClose={this.onClose}
                    visible={this.state.visible}
                    bodyStyle={{ padding: 0 }}
                    destroyOnClose={true}
                >
                    <AssetsHistory assetid={assets && assets.id} />
                </Drawer>
            </Fragment >
        )
    }
}