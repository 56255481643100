import React, { Fragment, useState } from 'react';
import { Modal, Upload } from 'antd';
import TextArea from "antd/lib/input/TextArea";
import moment from 'moment';
import { get } from 'lodash';
import Flickity from 'react-flickity-component';

const CardDetailMultipleDay = (props) => {
  const { status, description, start_datetime, end_datetime, images, decline_reason, payment_details, action_by, action_date, paid_by, paid_date } = get(props, 'data', {});
  const [imageIndexToShow, setImageIndexToShow] = useState(null);
  const [imagePreviewVisible, setImagePreviewVisible] = React.useState(false)
  const [imagePreview, setImagePreview] = React.useState("")

  const getImageFiles = (imageUrls) => {
    if (imageUrls) {
      let imageResponse = [];
      imageUrls.forEach((url) => {
        imageResponse.push(
          { "uid": 'uid', "url": url }
        )
      })
      return imageResponse;
    }
    return [];
  }
  // for upload
  const handlePreviewCancel = () => {
    setImagePreviewVisible(false);
    setImagePreview("");
    setImageIndexToShow(null);

  };
  const getFormattedDate = (date) => {
    return moment(date).format('DD MMM YYYY');
  };
  const handlePreview = (image,imageIndex) => {
    setImagePreview(image.url);
    setImagePreviewVisible(true);
    setImageIndexToShow(imageIndex);
  };
  const fallback = "-";
  return (
    <Fragment>
      <div className='card-body-container'>
        <div className='part first-part' style={{ display: 'block' }}>
          <div className='leaveReason-box'>
            <span className='date-label' style={{ opacity: '1', marginBottom: '0.4rem', lineHeight: '30px', fontSize: '1rem' }}><b>Date</b></span>: <span style={{ color: 'black' }}>{getFormattedDate(start_datetime)} -{' '}{getFormattedDate(end_datetime)}</span>

            {action_by && paid_by == "" &&
              (<>
                <div className='label'><b>{status.charAt(0).toUpperCase() + status.slice(1) + ' By'}</b> : {action_by} </div>
                <div className='label'><b>{status.charAt(0).toUpperCase() + status.slice(1) + ' On'}</b> : {getFormattedDate(action_date)} </div>
              </>)
            }
            {action_by && paid_by != "" &&
              (<>
                <div className='label'><b>{'Approved By'}</b> : {action_by} </div>
                <div className='label'><b>{'Approved On'}</b> : {getFormattedDate(action_date)} </div>
              </>)
            }
            {paid_by &&
              (<>
                <div className='label'><b>{status.charAt(0).toUpperCase() + status.slice(1) + ' By'}</b> :{paid_by} </div>
                <div className='label'><b>{status.charAt(0).toUpperCase() + status.slice(1) + ' On'}</b> : {getFormattedDate(paid_date)} </div>
              </>)
            }

            <div className='label'><b>Description</b> : </div>
            <div className='description-bill'> {description &&
              <TextArea
                defaultValue={(description) || fallback}
                disabled
                autoSize={{ minRows: 1, maxRows: 6 }}
                style={{ resize: 'none', backgroundColor: 'white', color: 'black', border: 'none', cursor: 'default' }}
              /> ||
              fallback}</div>

            {
              decline_reason &&
              (<>
                <div className='label'><b>Decline Reason</b> : </div>
                <div className='description-bill'>{decline_reason &&
                  <TextArea

                    defaultValue={(decline_reason) || fallback}
                    disabled

                    autoSize={{ minRows: 1, maxRows: 6 }}
                    style={{ resize: 'none', backgroundColor: 'white', color: 'black', border: 'none', cursor: 'default' }}

                  /> ||
                  fallback}</div>
              </>)
            }
            {
              payment_details &&
              (<>
                <div className='label'><b>Payment Details</b> : </div>
                <div className='description-bill'>{payment_details &&
                  <TextArea

                    defaultValue={(payment_details) || fallback}
                    disabled

                    autoSize={{ minRows: 1, maxRows: 6 }}
                    style={{ resize: 'none', backgroundColor: 'white', color: 'black', border: 'none', cursor: 'default' }}

                  /> ||
                  fallback}</div>
              </>)
            }
          </div>
        </div>

        {images.length !== 0 &&
          <div className='part second-part' style={{ margin: 'auto' }}>
            <Upload
              beforeUpload={() => {
                return false;
              }}
              listType="picture-card"
              defaultFileList={images}
              isImageUrl={true}
              onPreview={handlePreview}
              showUploadList={{
                showPreviewIcon: true,
                showRemoveIcon: false,
                showDownloadIcon: false,
              }}
            >
              {null}
            </Upload>

            <Modal
              title="Assets Images"
              className="viewImgModal"
              visible={imagePreviewVisible}
              footer={null}
              onCancel={handlePreviewCancel}
              centered
              destroyOnClose
            >
              {/* <img
                    alt="bill images"
                    style={{ width: "100%", maxHeight: "100%" }}
                    src={imagePreview}
                  /> */}
            {imageIndexToShow !== null && (
              <div className="history-assetImageContainer">
                <Flickity
                  className={'carousel'}
                  elementType={'div'}
                  options={{
                    initialIndex: imageIndexToShow,
                  }}
                  disableImagesLoaded={false}
                  reloadOnUpdate
                  static
                >
                  {images.map((image, index) => (
                    <div
                      className="carousel-img"
                      style={{ backgroundImage: `url(${image.url})` }}
                      key={index}
                    />
                  ))}
                </Flickity>
              </div>
              )}
            </Modal>
          </div>
        }

      </div>
    </Fragment>
  );
};

export default CardDetailMultipleDay;
