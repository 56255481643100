import React, { Component, Fragment } from "react";
import Document from "./components/Document";
import { Helmet } from "react-helmet";

const TITLE = 'Documents'

export default class DocumentManagement extends Component {
  render() {
    return (
      <Fragment>
        <Helmet>
          <title>{TITLE}</title>
        </Helmet>
        <div className="page-wrapper">
          <Document></Document>
        </div>
      </Fragment>
    );
  }
}
