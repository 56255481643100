import React, { Fragment } from "react";
import {
  Form,
  Input,
  Row,
  Col,
  DatePicker,
  Button,
  Checkbox,
  Select,
  notification,
  Spin
} from "antd";
import "./reactQuillCustomization.scss";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { getOrganizationEmployees } from "../../admin/components/LeaveManagement/DataManager";
import { handleNotificationEDIT } from "../../leave/components/DataManager";
import _ from "lodash";
import moment from "moment"
import "./reactQuillCustomization.scss"

const EditNotification = (props) => {
  const { getFieldDecorator, setFields } = props.form;
  const closeModal = props.closeModal;
  const editModalContent = props.viewCardData
  const handlePagination = props.handlePagination;
  const editModaloading = props.editModaloading;
  const setEditModalLoading = props.setEditModalLoading
  const [allUser, setAllUser] = React.useState([]);
  const [btnVisible, setBtnVisible] = React.useState(true);
const initialCheckboxValue = editModalContent && editModalContent.is_applicable_for_all===0 ? false : true
const initialIsActiveValue = editModalContent && editModalContent.is_active===0 ? false : true
  const [formData, setFormData] = React.useState({
    title:editModalContent && editModalContent.title,
    description: editModalContent && editModalContent.description,
    isApplicableForAll: initialCheckboxValue,
    // user_ids: editModalContent.users,
    announcement_date: moment(editModalContent && editModalContent.announcement_date),
    text: editModalContent && editModalContent.description,
    is_active:initialIsActiveValue,
  });
  const { Option } = Select;
  // FETCHING ALL EMPLOYEES NAMES
  const loadOrganizationEmployees = () => {
    return getOrganizationEmployees()
      .then((res) => {
        setAllUser(res.data);
      })
      .catch((error) => {
        notification.error(error.response.data.errors);
      });
  };

  // For getting user Names List
  React.useEffect(() => {
    loadOrganizationEmployees();
  }, []);
React.useEffect(()=>{
  let userArray = []
  editModalContent && editModalContent.users && editModalContent.users.map(user=>userArray.push(user.uuid))
  setFormData((prevData) => {
    return {
      ...prevData,
      user_ids: userArray,
    };
  });
},[])
  /* Quill modules to attach to editor
   * See https://quilljs.com/docs/modules/ for complete options
   */
  const modules = {
    toolbar: [
      [{ header: "1" }, { header: "2" }, { font: [] }],
      [{ size: [] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      ["link", "image", "video"],
      ["clean"],
    ],
    clipboard: {
      // toggle to add extra line breaks when pasting HTML:
      matchVisual: false,
    },
  };
  /*
   * Quill editor formats
   * See https://quilljs.com/docs/formats/
   */
  const formats = [
    "header",
    "font",
    "size",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
    "video",
  ];

  // HANDLING isApplicableForAll CHECKBOX
  const isApplicableForAll = (e) => {
    setBtnVisible(false)
    const checked = e.target.checked;

    setFormData((prevData) => {
      return {
        ...prevData,
        isApplicableForAll: checked,
        user_ids: [],
      };
    });
  };
  // HANDLING handleIsActive CHECKBOX
  const handleIsActive = (e) => {
    setBtnVisible(false)
    const checked = e.target.checked;

    setFormData((prevData) => {
      return {
        ...prevData,
        is_active: checked,
      };
    });
  };

  // HANDLING USER NAME
  const handleUserChange = (value) => {
    setBtnVisible(false)
    setFormData((prevData) => {
      return {
        ...prevData,
        user_ids: value,
        isApplicableForAll: false,
      };
    });
  };

  // HANDLING Title
  const handleTitle = (e) => {
    setBtnVisible(false)
    const title = e.target.value;
    setFormData((prevData) => {
      return {
        ...prevData,
        title: title,
      };
    });
  };
  // HANDLING Date
  const handleDate = (value) => {
    setBtnVisible(false)
    value &&
    setFormData((prevData) => {
      return {
        ...prevData,
        announcement_date: value.format("DD-MM-YYYY"),
      };
    });
  };

  // const setQuillValue = (value) => {
  //   if(value==='<p>hello</p>'){
  //     setIsTextEditorEmpty(true)
  //   }else{
  //     setIsTextEditorEmpty(false)
  //   }
  //   console.log("text value", value);
  //   setFormData((prevData) => {
  //     return {
  //       ...prevData,
  //       description: value,
  //     };
  //   });
  // };
  const onSubmit = () => {
    setBtnVisible(true)
    props.form.validateFields(async (error, values) => {
      values.is_applicable_for_all= Number(formData.isApplicableForAll)
      values.is_active= Number(formData.is_active)
      if(values.announcement_date){
      values.announcement_date = values.announcement_date.format("YYYY-MM-DD")
      };
      if (values.description === "<p><br></p>") {
        values.description = "";
        return false;
      }
      if(!formData.isApplicableForAll){
        values.user_ids = formData.user_ids
    }else{
      values.user_ids= []
    }
      if (!error) {
    setEditModalLoading(true)
        await handleNotificationEDIT(values,editModalContent.id)
          .then((res) => {
            notification.success({
              message: "Edited successfully",
            });
            closeModal(false);
  window.location="/dashboard/admin/announcements" 
          })
          .catch((error) => {
            if(error && error.response && error.response.status){
            if (error.response.status === 422) {
              let errors = error.response.data.errors;
              let errorObject = {};
              Object.keys(errors).forEach((key) => {
                let err = [];
                errors[key].forEach((value) => {
                  err.push(new Error(value));
                });
                key = _.camelCase(key.split(".")[0]);
                errorObject[key] = { value: formData[key], errors: err };
              });
              setFields(errorObject);
            } }else if(error && error.response && error.response.data && error.response.data.errors){
              notification.error(error.response.data.errors);
            }
          });
      }
    });
  };

  return (
    <Fragment>
      <div style={{ width: "800px !important" }}>
        <section>
          <h3 className="title text-primary">Edit Announcement</h3>
          {editModaloading ? (
            <div className="page-spinner">
              <Spin />
            </div>
          ) : (
            <>
          <form>
            <Row className="row">
              <Form.Item label="Title:">
                {getFieldDecorator("title", {
                  initialValue: formData.title,
                  rules: [{ required: true, message: "Enter a valid Title" }],
                })(
                  <Input
                    type="text"
                    className="input"
                    name="name"
                    placeholder="Enter Title"
                    onChange={handleTitle}
                  />
                )}
              </Form.Item>
            </Row>

            <Row className="row">
              <Col
                className="gutter-row"
                xs={24}
                sm={24}
                md={12}
                lg={12}
                xl={12}
              >
                <Form.Item label="Date">
                  {getFieldDecorator("announcement_date", {
                    initialValue: formData.announcement_date,
                    rules: [{ required: true, message: "Select a valid date" }],
                  })(<DatePicker onChange={handleDate} />)}
                </Form.Item>
              </Col>
              <Col
                className="gutter-row"
                xs={24}
                sm={24}
                md={24}
                lg={24}
                xl={24}
              >
                <Form.Item>
                  <label className="myCustomLabel" htmlFor="isApplicableForAll">
                    Apply To All:
                  </label>
                    <Checkbox
                      title="Apply to all"
                      onChange={isApplicableForAll}
                      checked={formData.isApplicableForAll}
                    ></Checkbox>
                </Form.Item>
              </Col>
              <Col
                className="gutter-row"
                xs={24}
                sm={24}
                md={24}
                lg={24}
                xl={24}
              >
                <Form.Item>
                  <label className="myCustomLabel" htmlFor="isActive">
                    Is Active:
                  </label>
                    <Checkbox
                      title="isActive"
                      onChange={handleIsActive}
                      checked={formData.is_active}
                    ></Checkbox>
                </Form.Item>
              </Col>
            </Row>

            {/* Select User */}
            <div className="form-content leaveType-cont">
              <Row className="row">
                <Col
                  className="col"
                  xs={24}
                  sm={24}
                  md={24}
                  lg={24}
                  xl={24}
                  xxl={24}
                >
                  <div className="form-group">
                    {formData.isApplicableForAll ? null : (
                      <Form.Item
                        name="userName"
                        label={"User Name"}
                        colon={false}
                        // validateStatus={userSelectTypeError ? "error" : ""}
                      >
                        {getFieldDecorator("user_ids", {
                          initialValue: formData.user_ids
                            ? formData.user_ids
                            : [],
                          rules: [
                            {
                              required: !formData.isApplicableForAll,
                              message: "select a user",
                            },
                          ],
                        })(
                          <Select
                            disabled={formData.isApplicableForAll}
                            name="userName"
                            className="leaveType-selectbox"
                            mode="multiple"
                            showSearch
                            placeholder="Select User Name"
                            onChange={handleUserChange}
                            optionLabelProp="label"
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                              option.props.label
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                          >
                            {allUser.map((user) => {
                              return (
                                <Option
                                  key={user.id}
                                  value={user.uuid}
                                  label={user.name}
                                >
                                  <div className="demo-option-label-item">
                                    {user.name}
                                  </div>
                                </Option>
                              );
                            })}
                          </Select>
                        )}
                      </Form.Item>
                    )}
                  </div>
                </Col>
              </Row>
            </div>
          </form>
          <div>
            <Form.Item>
              {getFieldDecorator("description", {
                initialValue: formData.description,
                rules: [{ required: true, message: "Enter a valid text" }],
              })(
                <ReactQuill
                  theme="snow"
                  modules={modules}
                  formats={formats}
                  placeholder="Enter text here..."
                  onChange={()=>setBtnVisible(false)}
                />
              )}
            </Form.Item>
          </div>
          </>
          )}
        </section>
        <div style={{ marginTop: "1rem", width: "100%", textAlign: "right" }}>
          <Button className="btn-primary editModal" disabled={btnVisible} onClick={onSubmit}>
            Submit
          </Button>
        </div>
      </div>
    </Fragment>
  );
};

export default Form.create()(EditNotification);
