import * as SERVICEs from "../../../../services/LeaveService";
import {
  getExistingTeamDirectoryListService,
  getAllLeaveTypesDrop,
  getNotification,
  getNotificationIdData,
  handleCurrentNotification,
  getEmployeeNotificationIdData,
  getExistingTeamDirectoryListServiceCurrent,
  getExistingTeamDirectoryListServiceCurr,
  getExistingTeamDirectoryListServiceCurrentDisable
} from "../../../../services/TeamDirectoryService";

// CREATING LEAVE TYPE
export const createLeave = (body) => {
  return SERVICEs.createLeaveTypeService(body)
    .then((result) => {
      return result.data;
    })
    .catch((error) => {
      throw error;
    });
};

// UPDATING LEAVE TYPE
export const updateLeaveType = (id, body) => {
  return SERVICEs.updateLeaveTypeService(id, body)
    .then((result) => {
      return result.data;
    })
    .catch((error) => {
      throw error;
    });
};

// ASSIGNING LEAVE
export const assignLeaveToUser = (body) => {
  return SERVICEs.assignLeave(body)
    .then((result) => {
      return result.data;
    })
    .catch((error) => {
      throw error;
    });
};

// GETTING ALL USERS NAME
export const getOrganizationEmployees = () => {
  return getExistingTeamDirectoryListService()
    .then((result) => {
      return result.data;
    })
    .catch((error) => {
      throw error;
    });
};

// GETTING ALL USERS NAME excluding ex-user, disable, hidden
export const getExistingTeamDirectoryListDisable = () => {
  return getExistingTeamDirectoryListServiceCurrentDisable()
    .then((result) => {
      return result.data;
    })
    .catch((error) => {
      throw error;
    });
};

export const getOrganizationEmployeesWithEx = () => {
  return getExistingTeamDirectoryListServiceCurr()
    .then((result) => {
      return result.data;
    })
    .catch((error) => {
      throw error;
    });
};
export const getOrganizationEmployeesCurrent = () => {
  return getExistingTeamDirectoryListServiceCurrent()
    .then((result) => {
      return result.data;
    })
    .catch((error) => {
      throw error;
    });
};

export const getNotificationList = (page,searchId,searchTitle) => {
  return getNotification(page,searchId,searchTitle)
    .then((result) => {
      return result.data;
    })
    .catch((error) => {
      throw error;
    });
};

export const handleNotificationStatus = (id) => {
  return handleCurrentNotification(id)
    .then((result) => {
      return result.data;
    })
    .catch((error) => {
      throw error;
    });
};

export const getNotificationId = (page,searchId,searchTitle) => {
  return getNotificationIdData(page,searchId,searchTitle)
    .then((result) => {
      return result.data;
    })
    .catch((error) => {
      throw error;
    });
};
export const getEmployeeNotification = (id) => {
  return getEmployeeNotificationIdData(id)
    .then((result) => {
      return result.data;
    })
    .catch((error) => {
      throw error;
    });
};

// GETTING ALL USERS NAME
export const getAllLeaveTypes = () => {
  return getAllLeaveTypesDrop()
    .then((result) => {
      return result.data;
    })
    .catch((error) => {
      throw error;
    });
};

// GETTING ALL USERS NAME
export const getAllEmployee = () => {
  return SERVICEs.getMyLeaves()
    .then((result) => {
      return result.data;
    })
    .catch((error) => {
      throw error;
    });
};

// GETTING ALL USERS NAME
export const getALLEmpLEave = (
  payload,
  pageNumber,
  allEmployees,
  leaveSearch
) => {
  return SERVICEs.getEmpLeaves(payload, pageNumber, allEmployees, leaveSearch)
    .then((result) => {
      return result.data;
    })
    .catch((error) => {
      throw error;
    });
};

// GETTING ALL USERS NAME
export const getSearchEmpLeaves = (pageNumber, searchquery) => {
  return SERVICEs.getSearchEmpLeaves(pageNumber, searchquery)
    .then((result) => {
      return result.data;
    })
    .catch((error) => {
      throw error;
    });
};

// GETTING ALL USERS NAME
export const getEMPLeave = () => {
  return SERVICEs.getEmpLeaves()
    .then((result) => {
      return result.data;
    })
    .catch((error) => {
      throw error;
    });
};

// GETTING LEAVE DETAILS
export const getLeaveDetails = (id) => {
  return SERVICEs.getCompleteLeaveDetails(id)
    .then((result) => {
      return result.data;
    })
    .catch((error) => {
      throw error;
    });
};

// GETTING EMPLOYEE LEAVE DETAILS
export const getOtherEmployeeLeave = (user_id, year) => {
  return SERVICEs.getOtherEmployeeLeave(user_id, year)
    .then((result) => {
      return result.data;
    })
    .catch((error) => {
      throw error;
    });
};

// GETTING ALL USERS LEAVE TYPE DATA
export const getLeaveData = (pageNumber) => {
  return SERVICEs.getAllLeaveTypeData(pageNumber)
    .then((result) => {
      return result.data;
    })
    .catch((error) => {
      throw error;
    });
};

//GETTING LEAVE TYPE LIST
export const getFilteredLeave = (payload) => {
  return SERVICEs.getLeaveListType(payload)
    .then((result) => {
      return result.data;
    })
    .catch((error) => {
      throw error;
    });
};

//GETTING LIST OF LEAVES
export const getFilteredListOfLeaves = (payload) => {
  return SERVICEs.listOfLeaves(payload)
    .then((result) => {
      return result.data;
    })
    .catch((error) => {
      throw error;
    });
};

//LEAVE DAYS CALCULATION
export const getLeaveDaysCalculation = (payload) => {
  return SERVICEs.leaveDaysCalculation(payload)
    .then((result) => {
      return result.data;
    })
    .catch((error) => {
      throw error;
    });
};

//FETCHING LEAVE TYPE LIST
export const getListOfLeaveType = (payload) => {
  return SERVICEs.listOfLeaveType(payload)
    .then((result) => {
      return result.data;
    })
    .catch((error) => {
      throw error;
    });
};

//FETCHING LEAVE TYPE LIST
export const getListOfAssignLeaveType = (payload) => {
  return SERVICEs.listOfAssignLeaves(payload)
    .then((result) => {
      return result.data;
    })
    .catch((error) => {
      throw error;
    });
};

// APPLY FOR EMPLOYEE LEAVE
export const applyForAdminLeave = (body) => {
  return SERVICEs.applyForAdminLeave(body)
    .then((result) => {
      return result.data;
    })
    .catch((error) => {
      throw error;
    });
};
export const applyForUpdatedAdminLeave = (body,id) => {
  return SERVICEs.applyForUpdatedAdminLeave(body,id)
    .then((result) => {
      return result.data;
    })
    .catch((error) => {
      throw error;
    });
};

// Leave action
export const leaveAction = (body, id) => {
  console.log("rejection body", body);
  return SERVICEs.leaveAction(body, id)
    .then((result) => {
      return result.data;
    })
    .catch((error) => {
      throw error;
    });
};
