import React from "react";
import { Layout } from "antd";
import "./app.scss";
import AppRouter from "./AppRouter";
import StoreProvider from "./providers/StoreProvider";
import packageJson from "../package.json";

const { Content } = Layout;

function App() {
  global.appVersion = packageJson.version;
  return (
    <Content style={{}}>
      <StoreProvider>
        <AppRouter />
      </StoreProvider>
    </Content>
  );
}

export default App;
