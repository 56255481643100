import React, { Component, Fragment } from "react";
import { notification, Icon, Input, Button, Spin, Popconfirm, Row, Col, Form, Table, Divider, Modal, Radio, Tooltip } from "antd";
import { withStore } from "../../../hocs/withStore";
import RequireAuth from "../../../hocs/RequireAuth";
import _ from 'lodash';
import { Helmet } from 'react-helmet'
import url from '../../../config/url';
import { axiosInstance as api } from '../../../utilities/configureAxios';
import ProfileComplete from "../../../hocs/ProfileComplete";
import intercom from '../../../assets/images/icons/sibebar/intercom-directory.svg';
import NotificationUpdate from "../../../hocs/NotificationUpdate";

// For search
const { Search } = Input;
const TITLE = 'Intercom directory'

// Pagination
const itemRender = (current, type, originalElement) => {
    if (type === 'prev') {
        return <a className="back-icon"> <Icon type="left" /> Back</a>;
    }
    if (type === 'next') {
        return <a className="next-icon">Next <Icon type="right" /></a>;
    }
    return originalElement;
}

class EmpIntercomDirectory extends Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            intercoms: [],
        }
    }

    componentDidMount = () => {
        this.setState({ loading: true });
        this.onPageLoad();
    }

    onPageLoad = (query = '') => {
        api
            .get(url['getIntercoms'] + "?q=" + query)
            .then(res => {
                const intercoms = _.get(res, 'data.data', [])
                this.setState({ intercoms, loading: false });
            })
            .catch(error => {
                notification['error']({
                    message: _.get(error, 'response.data.errors.message', "Oops! Something went wrong!")
                });
                this.setState({ loading: false });
            });
    }

    columns = [
        {
            title: 'Room Name',
            dataIndex: 'name',
            className: "Room-name",
        },
        {
            title: 'Room Type',
            dataIndex: 'type',
            className: "Room-type",
        },
        {
            title: 'Floor',
            dataIndex: 'floor_number',
            className: "floor-number",
            render: (floor) => {
                switch (floor) {
                    case -1:
                        return "Basement"
                    case 0:
                        return "Ground Floor"
                    case 1:
                        return "First Floor"
                    case 2:
                        return "Second Floor"
                    case 3:
                        return "Third Floor"
                }
            }
        },
        {
            title: 'Extension Number',
            dataIndex: 'extension_number',
            className: "extension-number",
        }
    ];


    handleCancel = (e) => {
        this.setState({
            visible: false,
            current_intercom: null,
        })
    }


    onChange = (e) => {
        this.onPageLoad(e.target.value);
    }


    render() {
        const { loading, intercoms } = this.state

        return (
            <Fragment>
                <Helmet>
                    <title>{TITLE}</title>
                </Helmet>
                <div className="page-wrapper">
                    <div className="intercomDirectory-container">
                        {loading ? (<div className="page-spinner"><Spin /></div>) : (
                            <Fragment>
                                {/* page Header */}
                                <div className="page-header">
                                    <div className="page-title">
                                        <h2 className="title"><img className="icon icon-intercom" src={intercom} alt="intercom icon" /> Intercom Directory</h2>
                                    </div>
                                </div>
                            </Fragment>
                        )}

                        {loading ? (<div className="page-spinner"><Spin /></div>) : (
                            <Fragment>
                                {/* page info title */}
                                <section className="page-info-box single-searchBar animated slideInRight">
                                    {/* <h3 className="info-title">All Document</h3> */}
                                    <div className="right single-item">
                                        <div className="search-box">
                                            <Input placeholder="Search by Name" onChange={this.onChange} suffix={<Icon type="search" />} />
                                        </div>
                                    </div>
                                </section>

                                {/* Admin Panel- Intercoms table */}
                                <section className="content-section document-table-info animated slideInUp">
                                    <Table className="table-content" columns={this.columns} dataSource={intercoms} rowKey={intercoms => intercoms.slug} pagination={false} />
                                </section>

                            </Fragment>
                        )}
                    </div>
                </div>
            </Fragment>
        )
    }
}

export default Form.create({ name: 'intercoms' })(RequireAuth(withStore(ProfileComplete(NotificationUpdate(EmpIntercomDirectory)))));
