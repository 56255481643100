import React, { Component, Fragment } from 'react';
import {
  notification,
  Icon,
  Input,
  Button,
  Spin,
  Popconfirm,
  Row,
  Col,
  Form,
  Table,
  Divider,
  Modal,
  Radio,
  Tooltip,
  Select,
} from 'antd';
import { withStore } from '../../../../../hocs/withStore';
import NotificationUpdate from '../../../../../hocs/NotificationUpdate';
import _ from 'lodash';
import url from '../../../../../config/url';
import { axiosInstance as api } from '../../../../../utilities/configureAxios';
import ProfileComplete from '../../../../../hocs/ProfileComplete';
import intercom from '../../../../../assets/images/icons/sibebar/intercom-directory.svg';
import ForAdmin from '../../../../../hocs/ForAdmin';

// For search
const { Search } = Input;

class IntercomDirectory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      loading: false,
      intercoms: [],
      current_intercom: null,
      pagination: {
        total: null,
        count: null,
        per_page: null,
        current_page: null,
        total_pages: null,
      },
    };
  }

  componentDidMount = () => {
    this.setState({ loading: true });
    this.onPageLoad();
  };

  onPageLoad = (query = '') => {
    api
      .get(url['getIntercoms'] + '?q=' + query)
      .then((res) => {
        const intercoms = _.get(res, 'data.data', []);
        // const pagination = _.get(res, 'data.meta.pagination', []);
        this.setState({ intercoms, loading: false });
      })
      .catch((error) => {
        notification['error']({
          message: _.get(
            error,
            'response.data.errors.message',
            'Oops! Something went wrong!'
          ),
        });
        this.setState({ loading: false });
      });
  };

  onIntercomDelete = (intercom_slug) => {
    this.setState({ loading: true });
    api
      .delete(url['deleteIntercoms'] + '/' + intercom_slug)
      .then((res) => {
        notification['success']({
          message: 'Intercom deleted successfully',
        });
        api
          .get(url['getIntercoms'])
          .then((res) => {
            const intercoms = _.get(res, 'data.data', []);
            this.setState({ intercoms, loading: false });
          })
          .catch((error) => {
            notification['error']({
              message: _.get(
                error,
                'response.data.errors.message',
                'Oops! Something went wrong!'
              ),
            });
            this.setState({ loading: false });
          });
      })
      .catch((error) => {
        notification['error']({
          message: _.get(
            error,
            'response.data.errors.message',
            'Oops! Something went wrong!'
          ),
        });
        this.setState({ loading: false });
      });
  };

  onIntercomEdit = (current_intercom) => {
    this.setState({ current_intercom, visible: true });
  };

  columns = [
    {
      title: 'Room Name',
      dataIndex: 'name',
      className: 'Room-name',
    },
    {
      title: 'Room Type',
      dataIndex: 'type',
      className: 'Room-type',
    },
    {
      title: 'Floor',
      dataIndex: 'floor_number',
      className: 'Floor',
      render: (floor) => {
        switch (floor) {
          case -1:
            return 'Basement';
          case 0:
            return 'Ground Floor';
          case 1:
            return 'First Floor';
          case 2:
            return 'Second Floor';
          case 3:
            return 'Third Floor';
        }
      },
    },
    {
      title: 'Extension Number',
      dataIndex: 'extension_number',
      className: 'extension-number',
    },
    {
      title: 'Action',
      dataIndex: 'action',
      className: 'action',
      render: (action, intercoms) => (
        <span>
          <Tooltip placement="bottom" title={'Edit'}>
            <Button
              type="link"
              onClick={() => this.onIntercomEdit(intercoms)}
              className="btn btn-light-info btn-icon text-uppercase"
            >
              <Icon type="edit" theme="filled" />
            </Button>
          </Tooltip>
          <Divider type="vericle" />
          <Popconfirm
            placement="bottomLeft"
            icon={
              <Icon type="warning" theme="twoTone" twoToneColor="#fcb103" />
            }
            title="Are you sure you want to delete this Intercom Entry?"
            onConfirm={() => this.onIntercomDelete(intercoms.slug)}
            okText="Yes"
            cancelText="No"
          >
            <Tooltip placement="bottom" title={'Delete'}>
              <Button
                type="link"
                className="btn btn-light-danger btn-icon text-uppercase"
              >
                <Icon type="delete" theme="filled" />
              </Button>
            </Tooltip>
          </Popconfirm>
        </span>
      ),
    },
  ];

  handleOk = (e) => {
    this.setState({ visible: false });
  };

  handleOk = (e) => {
    const { current_intercom } = this.state;
    e.preventDefault();
    this.props.form.validateFieldsAndScroll((err, formData) => {
      if (!err) {
        this.setState({ loading: true });
        api
          .post(
            current_intercom
              ? url['updateIntercoms'] + '/' + current_intercom.slug
              : url['addIntercoms'],
            formData
          )
          .then((res) => {
            api
              .get(url['getIntercoms'])
              .then((res) => {
                const intercoms = _.get(res, 'data.data', []);
                this.setState({
                  intercoms,
                  current_intercom: null,
                  loading: false,
                  visible: false,
                });
              })
              .catch((error) => {
                notification['error']({
                  message: _.get(
                    error,
                    'response.data.errors.message',
                    'Oops! Something went wrong!'
                  ),
                });
                this.setState({
                  current_intercom: null,
                  loading: false,
                  visible: false,
                });
              });
            notification['success']({
              message:
                'Intercom ' +
                (current_intercom ? 'edited' : 'created') +
                ' successfully!',
            });
          })
          .catch((error) => {
            const error_code = _.get(error, 'response.status');
            if (error_code && error_code == '422') {
              notification['error']({
                message: 'Invalid Data',
                description: Object.values(
                  _.get(
                    error,
                    'response.data.errors',
                    'Oops! Something went wrong!'
                  )
                ),
              });
            } else {
              notification['error']({
                message: _.get(
                  error,
                  'response.data.errors.message',
                  'Oops! Something went wrong!'
                ),
              });
              this.setState({ visible: false });
            }
            this.setState({ loading: false });
          });
      }
    });
  };

  handleCancel = (e) => {
    this.setState({
      visible: false,
      current_intercom: null,
    });
  };

  // onPageChange = (pageNumber) => {
  //     this.setState({ loading: true });
  //     api
  //         .get(url['getIntercoms'] + "?page=" + pageNumber)
  //         .then(res => {
  //             const intercoms = _.get(res, 'data.data', [])
  //             const pagination = res.data.meta.pagination;
  //             this.setState({ intercoms, loading: false, pagination });
  //         })
  //         .catch(error => {
  //             notification['error']({
  //                 message: _.get(error, 'response.data.errors.message', "Oops! Something went wrong!")
  //             });
  //             this.setState({ loading: false });
  //         });
  // }

  onChange = (e) => {
    this.onPageLoad(e.target.value);
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const { loading, visible, intercoms, current_intercom } = this.state;
    const { total, per_page, current_page } = this.state.pagination;
    return (
      <Fragment>
        <div className="">
          <div className="intercomDirectory-container">
            {loading ? (
              <div className="page-spinner">
                <Spin />
              </div>
            ) : (
              <Fragment>
                {/* page Header */}
                <div className="page-header">
                  <div className="page-title">
                    <h2 className="title">
                      <img
                        className="icon"
                        src={intercom}
                        alt="intercom icon"
                      />{' '}
                      Intercom Directory
                    </h2>
                  </div>
                  <Button
                    className="btn btn-primary text-uppercase"
                    onClick={() => {
                      this.setState({ visible: true });
                    }}
                  >
                    <Icon type="plus" /> Add New
                  </Button>
                </div>
              </Fragment>
            )}

            {loading ? (
              <div className="page-spinner">
                <Spin />
              </div>
            ) : (
              <Fragment>
                {/* page info title */}
                <section className="page-info-box single-searchBar animated slideInRight">
                  {/* <h3 className="info-title">All Document</h3> */}
                  <div className="right single-item">
                    <div className="search-box">
                      <Input
                        placeholder="Search by Name"
                        onChange={this.onChange}
                        suffix={<Icon type="search" />}
                      />
                    </div>
                  </div>
                </section>

                {/* Admin Panel- Intercoms table */}
                <section className="content-section document-table-info animated slideInUp">
                  <Table
                    className="table-content"
                    columns={this.columns}
                    dataSource={intercoms}
                    rowKey={(intercoms) => intercoms.slug}
                    pagination={false}
                  />
                </section>

                {/* Add New Document or Edit Document Modal */}
                <Modal
                  className="add-new-document-modal profileAP-modal"
                  title="Intercom"
                  centered
                  visible={visible}
                  onOk={this.handleOk}
                  destroyOnClose={true}
                  onCancel={this.handleCancel}
                  footer={[
                    <Button
                      key="back"
                      onClick={this.handleCancel}
                      className="btn btn-light-secondary text-uppercase"
                    >
                      Return
                    </Button>,
                    <Button
                      onClick={this.handleOk}
                      loading={loading}
                      key="submit"
                      className="btn btn-light-success text-uppercase"
                    >
                      Submit
                    </Button>,
                  ]}
                >
                  <div className="add-new-document-modal-container">
                    <Form onSubmit={this.handleOk}>
                      <Row className="" gutter={24}>
                        <Col
                          className="gutter-row"
                          xs={24}
                          sm={24}
                          md={24}
                          lg={8}
                          xl={8}
                          xxl={8}
                        >
                          <div className="form-group">
                            <label className="label">Room name</label>
                          </div>
                        </Col>
                        <Col
                          className="gutter-row"
                          xs={24}
                          sm={24}
                          md={24}
                          lg={16}
                          xl={16}
                          xxl={16}
                        >
                          <div className="form-group">
                            <Form.Item>
                              {getFieldDecorator('room_name', {
                                initialValue:
                                  (current_intercom && current_intercom.name) ||
                                  '',
                                rules: [
                                  {
                                    required: true,
                                    message: 'Enter valid room name',
                                  },
                                ],
                                validateTrigger: 'onBlur',
                              })(
                                <Input
                                  className="input"
                                  maxLength="255"
                                  placeholder="Enter room name"
                                  onBlur={(e) =>
                                    (e.target.value = e.target.value.trim())
                                  }
                                />
                              )}
                            </Form.Item>
                          </div>
                        </Col>
                      </Row>

                      <Row className="" gutter={24}>
                        <Col
                          className="gutter-row"
                          xs={24}
                          sm={24}
                          md={24}
                          lg={8}
                          xl={8}
                          xxl={8}
                        >
                          <div className="form-group">
                            <label className="label">Room type</label>
                          </div>
                        </Col>
                        <Col
                          className="gutter-row"
                          xs={24}
                          sm={24}
                          md={24}
                          lg={16}
                          xl={16}
                          xxl={16}
                        >
                          <div className="form-group">
                            <Form.Item>
                              {getFieldDecorator('room_type', {
                                initialValue:
                                  current_intercom && current_intercom.type
                                    ? current_intercom.type
                                    : '',
                                rules: [
                                  {
                                    required: true,
                                    message: 'Please select room type',
                                  },
                                ],
                              })(
                                <Select
                                  className="curr_ste"
                                  showSearch
                                  placeholder="-- Select room type --"
                                  optionFilterProp="children"
                                >
                                  <option value="">Select room type</option>
                                  <option value="Seating">Seating</option>
                                  <option value="Conference">Conference</option>
                                  <option value="Outdoors">Outdoors</option>
                                  <option value="Pantry">Pantry</option>
                                  <option value="Security">Security</option>
                                  <option value="Others">Others</option>
                                </Select>
                              )}
                            </Form.Item>
                          </div>
                        </Col>
                      </Row>

                      <Row className="" gutter={24}>
                        <Col
                          className="gutter-row"
                          xs={24}
                          sm={24}
                          md={24}
                          lg={8}
                          xl={8}
                          xxl={8}
                        >
                          <div className="form-group">
                            <label className="label">Floor</label>
                          </div>
                        </Col>
                        <Col
                          className="gutter-row"
                          xs={24}
                          sm={24}
                          md={24}
                          lg={16}
                          xl={16}
                          xxl={16}
                        >
                          <div className="form-group">
                            <Form.Item>
                              {getFieldDecorator('floor_number', {
                                initialValue:
                                  current_intercom &&
                                  current_intercom.floor_number >= 0
                                    ? current_intercom.floor_number
                                    : '',
                                rules: [
                                  {
                                    required: true,
                                    message: 'Please select Floor',
                                  },
                                ],
                              })(
                                <Select
                                  className="curr_ste"
                                  showSearch
                                  placeholder="-- Select floor --"
                                  optionFilterProp="children"
                                >
                                  <option value="">Select floor</option>
                                  <option value={-1}>Basement</option>
                                  <option value={0}>Ground Floor</option>
                                  <option value={1}>First Floor</option>
                                  <option value={2}>Second Floor</option>
                                  <option value={3}>Third Floor</option>
                                </Select>
                              )}
                            </Form.Item>
                          </div>
                        </Col>
                      </Row>

                      <Row className="" gutter={24}>
                        <Col
                          className="gutter-row"
                          xs={24}
                          sm={24}
                          md={24}
                          lg={8}
                          xl={8}
                          xxl={8}
                        >
                          <div className="form-group">
                            <label className="label">Extension number</label>
                          </div>
                        </Col>
                        <Col
                          className="gutter-row"
                          xs={24}
                          sm={24}
                          md={24}
                          lg={16}
                          xl={16}
                          xxl={16}
                        >
                          <div className="form-group">
                            <Form.Item>
                              {getFieldDecorator('extension_number', {
                                initialValue:
                                  (current_intercom &&
                                    current_intercom.extension_number) ||
                                  '',
                                rules: [
                                  {
                                    required: true,
                                    message: 'Enter valid extension number',
                                  },
                                ],
                                validateTrigger: 'onBlur',
                              })(
                                <Input
                                  className="input"
                                  maxLength="4"
                                  minLength="4"
                                  placeholder="Enter extension number"
                                  onBlur={(e) =>
                                    (e.target.value = e.target.value.replace(
                                      /\D/g,
                                      ''
                                    ))
                                  }
                                />
                              )}
                            </Form.Item>
                          </div>
                        </Col>
                      </Row>
                    </Form>
                  </div>
                </Modal>
              </Fragment>
            )}
          </div>
        </div>
      </Fragment>
    );
  }
}

export default Form.create({ name: 'intercoms' })(
  ForAdmin(withStore(ProfileComplete(NotificationUpdate(IntercomDirectory))))
);
