import React, { Component , Fragment } from "react";
import { notification, Icon, Input, Button, Popconfirm, Spin, Row, Col, Form, Table, Divider, Modal, Radio, Upload } from "antd";
import ForAdmin from "../../../hocs/ForAdmin";
import { withStore } from "../../../hocs/withStore";
import _ from 'lodash';
import { axiosInstance as api } from '../../../utilities/configureAxios';
import compDocument from '../../../assets/images/icons/icon-comp-document-bg.svg';
import url from '../../../config/url';
import data_setting from '../../../config/data_setting';

class ManageAdminPanel extends Component {
    constructor(props) {
        super(props);
        this.state = {
            team_loading: false,
            skill_loading: false,
            level_loading: false,
            teams: [],
            levels: [],
            skills: [],
            loading: false
        };
        this.baseState = this.state;
    }

    handleLevel = e => {
        this.props.form.validateFields(['level'], (err, values) => {
        if (!err) {
            var formData = new FormData();
            formData.append('title', values.level)
            this.setState({ level_loading: true });
            api
                .post(url["getLevels"], formData)
                .then(res => {
                    notification['success']({
                        message: "Level added successfully!"
                    });
                    this.props.form.setFieldsValue({
                        level: '',
                      });
                    api
                        .get(url["getLevels"])
                        .then(res => {
                            const levels = _.get(res, 'data.data', [])
                            this.setState({ levels, level_loading: false });
                        })
                        .catch(error => {
                            notification['error']({
                                message: _.get(error, 'response.data.errors.message', "Oops! Levels could not be loaded!")
                            });
                            this.setState({ level_loading: false });
                        });
                })
                .catch(error => {
                    const error_code = _.get(error, 'response.status');
                    if(error_code && error_code == '422')
                    {
                        notification['error']({
                            message: "Invalid Data",
                            description: Object.values(_.get(error, 'response.data.errors', "Oops! Something went wrong!"))
                        });
                    }
                    else{
                        notification['error']({
                        message: _.get(error, 'response.data.errors.message', "Oops! Something went wrong!")
                    }); 
                    }
                    this.setState({ level_loading: false });
                });  
        }
    })
    }

    handleTeam = e => {
        this.props.form.validateFields(['team'], (err, values) => {
        if (!err) {
            var formData = new FormData();
            formData.append('title', values.team)
            this.setState({ team_loading: true });
            api
                .post(url["getTeams"], formData)
                .then(res => {
                    notification['success']({
                        message: "Team added successfully!"
                    });
                    this.props.form.setFieldsValue({
                        team: '',
                      });
                      api
                        .get(url["getTeams"])
                        .then(res => {
                            const teams = _.get(res, 'data.data', [])
                            this.setState({ teams, team_loading: false });
                        })
                        .catch(error => {
                            notification['error']({
                                message: _.get(error, 'response.data.errors.message', "Oops! Teams could not be loaded!")
                            });
                            this.setState({ team_loading: false });
                        });
                })
                .catch(error => {
                const error_code = _.get(error, 'response.status');
                if(error_code && error_code == '422')
                {
                    notification['error']({
                        message: "Invalid Data",
                        description: Object.values(_.get(error, 'response.data.errors', "Oops! Something went wrong!"))
                    });
                }
                else{
                    notification['error']({
                    message: _.get(error, 'response.data.errors.message', "Oops! Something went wrong!")
                }); 
                }
                    this.setState({ team_loading: false });
                });  
        }
    })
    }

    handleSkill = e => {
        this.props.form.validateFields(['skill'], (err, values) => {
        if (!err) {
            this.setState({ skill_loading: true });
            var formData = new FormData();
            formData.append('title', values.skill)
            api
                .post( url["getSkills"], formData)
                .then(res => {
                    notification['success']({
                        message: "Skill added successfully!"
                    });
                    this.props.form.setFieldsValue({
                        skill: '',
                      });
                    api
                        .get(url["getSkills"])
                        .then(res => {
                            const skills = _.get(res, 'data.data', [])
                            this.setState({ skills, skill_loading: false });
                        })
                        .catch(error => {
                            notification['error']({
                                message: _.get(error, 'response.data.errors.message', "Oops! Skills could not be loaded!")
                            });
                            this.setState({ skill_loading: false });
                        });
                })
                .catch(error => {
                    const error_code = _.get(error, 'response.status');
                    if(error_code && error_code == '422')
                    {
                        notification['error']({
                            message: "Invalid Data",
                            description: Object.values(_.get(error, 'response.data.errors', "Oops! Something went wrong!"))
                        });
                    }
                    else{
                        notification['error']({
                        message: _.get(error, 'response.data.errors.message', "Oops! Something went wrong!")
                    }); 
                    }
                    this.setState({ skill_loading: false });
                });  
        }
    })
    }

    onTeamDelete = (slug) => {
        this.setState({ team_loading: true });
        api
            .delete(url["getTeams"]+"/"+slug)
            .then(res => {
                notification['success']({
                    message: "Team deleted successfully"
                });
                api
                    .get(url["getTeams"])
                    .then(res => {
                        const teams = _.get(res, 'data.data', [])
                        this.setState({ teams, team_loading: false });
                    })
                    .catch(error => {
                        notification['error']({
                            message: _.get(error, 'response.data.errors.message', "Oops! Teams could not be loaded!")
                        });
                        this.setState({ team_loading: false });
                    });
            })
            .catch(error => {
                notification['error']({
                    message: _.get(error, 'response.data.errors.message', "Oops! Team could not be deleted!")
                });
                this.setState({ team_loading: false });
            });
    }

    onLevelDelete = (slug) => {
        this.setState({ level_loading: true });
        api
            .delete(url["getLevels"]+"/"+slug)
            .then(res => {
                notification['success']({
                    message: "Level deleted successfully"
                });
                api
                    .get(url["getLevels"])
                    .then(res => {
                        const levels = _.get(res, 'data.data', [])
                        this.setState({ levels, level_loading: false });
                    })
                    .catch(error => {
                        notification['error']({
                            message: _.get(error, 'response.data.errors.message', "Oops! Levels could not be loaded!")
                        });
                        this.setState({ level_loading: false });
                    });
            })
            .catch(error => {
                notification['error']({
                    message: _.get(error, 'response.data.errors.message', "Oops! Level could not be deleted!")
                });
                this.setState({ level_loading: false });
            });
    }

    onSkillDelete = (slug) => {
        this.setState({ skill_loading: true });
        api
            .delete(url["getSkills"]+"/"+slug)
            .then(res => {
                notification['success']({
                    message: "Skill deleted successfully"
                });
                api
                    .get(url["getSkills"])
                    .then(res => {
                        const skills = _.get(res, 'data.data', [])
                        this.setState({ skills, skill_loading: false });
                    })
                    .catch(error => {
                        notification['error']({
                            message: _.get(error, 'response.data.errors.message', "Oops! Skills could not be loaded!")
                        });
                        this.setState({ skill_loading: false });
                    });
            })
            .catch(error => {
                notification['error']({
                    message: _.get(error, 'response.data.errors.message', "Oops! Skill could not be deleted!")
                });
                this.setState({ skill_loading: false });
            });
    }

    componentDidMount = () => {
        this.setState({ team_loading: true, skill_loading: true, level_loading: true, loading: true });
        api
            .get(url["getSkills"])
            .then(res => {
                const skills = _.get(res, 'data.data', [])
                // this.setState({ skills });
                this.setState({ skills, skill_loading: false });
            })
            .catch(error => {
                notification['error']({
                    message: _.get(error, 'response.data.errors.message', "Oops! Something went wrong!")
                });
                this.setState({ skill_loading: false });
            });
        api
            .get(url["getTeams"])
            .then(res => {
                const teams = _.get(res, 'data.data', [])
                // this.setState({ teams });
                this.setState({ teams, team_loading: false });
            })
            .catch(error => {
                notification['error']({
                    message: _.get(error, 'response.data.errors.message', "Oops! Something went wrong!")
                });
                this.setState({ team_loading: false });
            });
        api
            .get(url["getLevels"])
            .then(res => {
                const levels = _.get(res, 'data.data', [])
                // this.setState({ levels });
                this.setState({ levels, level_loading: false });
            })
            .catch(error => {
                notification['error']({
                    message: _.get(error, 'response.data.errors.message', "Oops! Something went wrong!")
                });
                this.setState({ level_loading: false });
            });
        this.setState({ loading: false });
    } 

    render() {
        const team_column = [
            {
              title: ' Team Name',
              dataIndex: 'title',
              key: 'name',
              className: 'team-name',
            },
            {
                title: 'Action',
                dataIndex: 'action',
                key: "action",
                className: 'action',
                render: (action, team) => (
                    <span>
                        {/* <Button type="link" size="small" onClick={ () => this.onTeamEdit(team) } className="btn btn-light-info btn-icon text-uppercase"><Icon type="edit" theme="filled" /></Button>
                        <Divider type="verticle" /> */}
                        <Popconfirm
                            placement="bottomLeft"
                            icon={<Icon type="warning" theme="twoTone" twoToneColor="#fcb103" />}
                            title="Are you sure you want to delete this team?"
                            onConfirm={() => this.onTeamDelete(team.slug)}
                            okText="Yes"
                            cancelText="No"
                        >
                            <Button type="link" className="btn btn-light-danger btn-icon text-uppercase"><Icon type="delete" theme="filled" />Delete</Button>
                        </Popconfirm>
                    </span>
                )
            }
          ];
          const skill_column = [
            {
              title: ' Skill Name',
              dataIndex: 'title',
              key: 'name',
              className: 'skill-name',
            },
            {
                title: 'Action',
                dataIndex: 'action',
                key: "action",
                className: 'action',
                render: (action, skill) => (
                    <span>
                        {/* <Button type="link" size="small" onClick={ () => this.onSkillEdit(skill) } className="btn btn-light-info btn-icon text-uppercase"><Icon type="edit" theme="filled" /></Button>
                        <Divider type="verticle" /> */}
                        <Popconfirm
                            placement="bottomLeft"
                            icon={<Icon type="warning" theme="twoTone" twoToneColor="#fcb103" />}
                            title="Are you sure you want to delete this skill?"
                            onConfirm={() => this.onSkillDelete(skill.slug)}
                            okText="Yes"
                            cancelText="No"
                        >
                            <Button type="link" className="btn btn-light-danger btn-icon text-uppercase"><Icon type="delete" theme="filled" />Delete</Button>
                        </Popconfirm>
                    </span>
                )
            }
          ];
          const level_column = [
            {
              title: ' Level Name',
              dataIndex: 'title',
              key: 'name',
              className: 'level-name',
            },
            {
                title: 'Action',
                dataIndex: 'action',
                key: "action",
                className: 'action',
                render: (action, level) => (
                    <span>
                        {/* <Button type="link" size="small" onClick={ () => this.onLevelEdit(level) } className="btn btn-light-info btn-icon text-uppercase"><Icon type="edit" theme="filled" /></Button>
                        <Divider type="verticle" /> */}
                        <Popconfirm
                            placement="bottomLeft"
                            icon={<Icon type="warning" theme="twoTone" twoToneColor="#fcb103" />}
                            title="Are you sure you want to delete this level?"
                            onConfirm={() => this.onLevelDelete(level.slug)}
                            okText="Yes"
                            cancelText="No"
                        >
                            <Button type="link" className="btn btn-light-danger btn-icon text-uppercase"><Icon type="delete" theme="filled" />Delete</Button>
                        </Popconfirm>
                    </span>
                )
            }
          ];
        const { getFieldDecorator } = this.props.form;
        const { loading, level_loading, team_loading, skill_loading, teams, skills, levels } = this.state;
        return (
            <Fragment>
                <div className="page-wrapper">
                    <div className="page-header">
                        <div className="page-title">
                            <h2 className="title"><img className="icon icon-view" src={ compDocument } alt="view icon" /> Miscellaneous</h2>
                        </div>
                    </div>
                    { loading?(<center><Spin /></center> ):(
                            <Fragment>
                    <div className="misc-container">
                        <div className="box-content">
                            <Row className="" gutter={ 24 }>
                                <Col className="gutter-row"  xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                                    <section className="page-info-box form-group">
                                        <h3 className="info-title">Add New Team</h3>
                                    </section>
                                    <div className="form-group">
                                        <div className="add-row">
                                            <Row className="" gutter={ 24 }>
                                                <Col className="gutter-row" xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
                                                    <Row className="" gutter={ 24 }>
                                                        <Col className="gutter-row sm-hide" xs={24} sm={24} md={24} lg={8} xl={8} xxl={8}>
                                                            <div className="form-group">
                                                                <label className="label">Enter Team Name</label>
                                                            </div>
                                                        </Col>
                                                        <Col className="gutter-row" xs={24} sm={24} md={24} lg={16} xl={16} xxl={16}>
                                                            <div className="form-group">
                                                                <div className="input-group">
                                                                    <Form.Item>
                                                                        {getFieldDecorator('team', {
                                                                            rules: [
                                                                            {
                                                                                required: true,
                                                                                message: "Enter valid team name"
                                                                            }
                                                                            ],
                                                                            validateTrigger: 'onChange'
                                                                        })(
                                                                        <Input className="input" maxLength="255" onPressEnter={ this.handleTeam } placeholder="Enter team name" onBlur={e => e.target.value = e.target.value.trim() } />
                                                                        )}
                                                                        <div className="input-group-append">
                                                                            <Button onClick={ this.handleTeam } loading = { team_loading } disabled = { team_loading } key="submit" className="btn btn-success text-uppercase">Add</Button>
                                                                        </div>
                                                                        
                                                                    </Form.Item>
                                                                </div>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            </Row>
                                        </div>
                                        
                                        {/* Table content */}
                                        <div className="table-content">
                                            <Table dataSource={teams} loading={team_loading} columns={team_column} pagination={{"pageSize":"5"}}/>
                                        </div>
                                    </div>
                                </Col>
                                <Col className="gutter-row"  xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                                    <section className="page-info-box form-group">
                                        <h3 className="info-title">Add New Level</h3>
                                    </section>
                                    <div className="form-group">
                                        <div className="add-row">
                                            <Row className="" gutter={ 24 }>
                                                <Col className="gutter-row" xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
                                                    <Row className="" gutter={ 24 }>
                                                        <Col className="gutter-row sm-hide" xs={24} sm={24} md={24} lg={8} xl={8} xxl={8}>
                                                            <div className="form-group">
                                                                <label className="label">Enter level</label>
                                                            </div>
                                                        </Col>
                                                        <Col className="gutter-row" xs={24} sm={24} md={24} lg={16} xl={16} xxl={16}>
                                                            <div className="form-group">
                                                                <div className="input-group">
                                                                    <Form.Item>
                                                                        {getFieldDecorator('level', {
                                                                            rules: [
                                                                            {
                                                                                required: true,
                                                                                message: "Enter valid level name"
                                                                            }
                                                                            ],
                                                                            validateTrigger: 'onChange'
                                                                        })(
                                                                        <Input className="input" maxLength="255" onPressEnter={ this.handleLevel } placeholder="Enter level name" onBlur={e => e.target.value = e.target.value.trim() } />
                                                                        )}
                                                                        <div className="input-group-append">
                                                                            <Button onClick={ this.handleLevel} loading = { level_loading } disabled = { level_loading } key="submit" className="btn btn-success text-uppercase">Add</Button>
                                                                        </div>
                                                                    </Form.Item>
                                                                </div>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            </Row>
                                        </div>
                                        
                                        {/* Table content */}
                                        <div className="table-content">
                                            <Table dataSource={levels} loading={level_loading} columns={level_column} pagination={{"pageSize":"5"}}/>
                                        </div>
                                    </div>
                                </Col>
                                <Col className="gutter-row"  xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                                    <section className="page-info-box form-group">
                                        <h3 className="info-title">Add New Skill</h3>
                                    </section>
                                    <div className="form-group">
                                        <div className="add-row">
                                            <Row className="" gutter={ 24 }>
                                                <Col className="gutter-row" xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
                                                    <Row className="" gutter={ 24 }>
                                                        <Col className="gutter-row sm-hide" xs={24} sm={24} md={24} lg={8} xl={8} xxl={8}>
                                                            <div className="form-group">
                                                                <label className="label">Enter Skill</label>
                                                            </div>
                                                        </Col>
                                                        <Col className="gutter-row" xs={24} sm={24} md={24} lg={16} xl={16} xxl={16}>
                                                            <div className="form-group">
                                                                <div className="input-group">
                                                                    <Form.Item>
                                                                        {getFieldDecorator('skill', {
                                                                            rules: [
                                                                            {
                                                                                required: true,
                                                                                message: "Enter valid skill name"
                                                                            }
                                                                            ],
                                                                            validateTrigger: 'onChange'
                                                                        })(
                                                                        <Input className="input" maxLength="255" onPressEnter={ this.handleSkill }placeholder="Enter skill name" onBlur={e => e.target.value = e.target.value.trim() } />
                                                                        )}
                                                                        <div className="input-group-append">
                                                                            <Button type="submit" onClick={ this.handleSkill } loading = { skill_loading } disabled = { skill_loading } key="submit" className="btn btn-success text-uppercase">Add</Button>
                                                                        </div>
                                                                    </Form.Item>
                                                                </div>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            </Row>
                                        </div>
                                        
                                        {/* Table content */}
                                        <div className="table-content">
                                            <Table dataSource={skills} loading={skill_loading} columns={skill_column} pagination={{"pageSize":"5"}}/>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </div>
                    </Fragment>
                    )}
                </div>
            </Fragment>
        )
    }
}

export default Form.create({ name: 'add_document' })(ForAdmin(withStore(ManageAdminPanel)));