import React, { useEffect, useState, Fragment } from "react";
import { withStore } from "../../../hocs/withStore";
import NotificationUpdate from "../../../hocs/NotificationUpdate";
import { Table, Tooltip, Button, Icon, Input,DatePicker, Spin, Modal,Typography , Select, notification  } from "antd";
import EmployeeViewProject from "./EmployeeViewProject";
import { axiosInstance as api } from "../../../utilities/configureAxios";
import url from "../../../config/url";
import _, { values } from "lodash";
import moment from "moment";
import {
  getEmployeeProjectListData,
  getEmployeeProjectDetailsData,
} from "../../../services/ProjectService";
const { Option } = Select;
const Employee = () => {
  const [allProjectList, setAllProjectList] = useState("");
  const [employeeProjectDetails, setEmployeeProjectDetails] = useState("");
  const [pageCurrent, setpageCurrent] = useState(1);
  const [loading, setLoading] = useState(false);
  const [viewModalLoading, setViewModalLoading] = useState(false);
  const [viewModalVisible, setViewModalVisible] = useState(false);
  const [searchKey, setSearchKey] = useState("");
  const [value, setValue] = useState("");
  const [status , setStatus] = useState("");
  const [ disableDate , setDisableDate] = useState(false);
  const [endingDate , setEndingDate] = useState('');
  const [DownloadLoading, setDownloadLoading ] = useState(false);
  const getEmployeeProjectList = (page, status='',searchKey='',date='') => {
    setLoading(true);
    return getEmployeeProjectListData(page, status,searchKey,date).then((res) => {
      setAllProjectList(res);
      setLoading(false);
    }).catch(error => {
      setLoading(false);
      notification.error({
        message: error.response.data.errors.message
      })
    });
  };
  const handleEmployeeList = () => {
    setDownloadLoading(true);
    let filter;
    let endDate = endingDate;
    if(status == "assigned"){
      filter = 1;
    }else if(status == "unassigned"){
      filter = 2;
      endDate='';
    }else{
      filter = '';
    }
    
    api
      .get(
        `${url["getEmployeeProjectList"]}?q=${searchKey}&filter=${filter}&date=${endDate}`
      )
      .then((res) => {
        const fileURL = window.URL.createObjectURL(
          new Blob([res.data], {
            type: "application/csv",
          })
        );
        let alink = document.createElement("a");
        alink.href = fileURL;
        alink.download = "EmployeeProjectList.csv";
        alink.click();
        setDownloadLoading(false);
      })
      .catch((error) => {
        notification["error"]({
          message: _.get(
            error,
            "response.data.errors.message",
            "Oops! Something went wrong!"
          ),
        });
        setDownloadLoading(false);
      });
  };
 

  const loadEmployees = (page ,status='', searchKey='',date='') => {
     getEmployeeProjectList( page , status, searchKey,date)
      
  }


  const handleStatusChange = (newStatus) => {
  newStatus == 'unassigned' ? setDisableDate(true)  : setDisableDate(false);
  setStatus(newStatus);
  if(newStatus == 'unassigned'){
    loadEmployees(1,newStatus,searchKey);
  }else{
   loadEmployees(1,newStatus,searchKey,endingDate);
  }
  setpageCurrent(1);
  }

  const handleEndingDateChange = (date) => {
    if(date == null){
      date = '';
    }else{
       date = moment(date).format("YYYY-MM-DD");
    }
    
    setEndingDate(date);
    loadEmployees(1,status,searchKey,date);
    setpageCurrent(1);
    }

  const getEmployeeProjectDetails = (id) => {
    return getEmployeeProjectDetailsData(id).then((res) => {
      setEmployeeProjectDetails(res);
      setViewModalLoading(false);
    });
  };
  const COLUMN_STYLE = { width: 300 };
  const columns = [
    {
      title: "S.No.",
      key: "index",
      className: "text-center",
      render: (text, object, index) => (
        <div>{(pageCurrent - 1) * 10 + (index + 1)}</div>
      ),
    },
    {
      title: "Employee ID",
      dataIndex: "employee_id",
      key: "id",
    },
    {
      title: "Employee Name",
      dataIndex: "name",
      key: "id",
    },
    {
      title: "Projects",
      dataIndex: "projects",
      key: "id",
      render: (text) => {
        const textProject = [];
        if (text) {
          text.split(",").map((el, index) => {
            if (index < 3) {
              return textProject.push(el);
            }
          });
        }
        return text ? (
          // <span>
          //   {`${textProject.join(", ").trim()} ${text.split(",").length > 3 ? "..." : ""}`}
          // </span>
          <Typography.Text ellipsis={true} style={COLUMN_STYLE}>
          {text}
        </Typography.Text>
        ) : (
          <span style={{ marginLeft: "20px" }}>-</span>
        );
      },
    },
    {
      title: "Action",
      dataIndex: "",
      className: "action",
      render: (text) => (
        <span>
          <Tooltip placement="bottom" title={"View All Projects"}>
            <Button
              type="link"
              onClick={() => {
                getEmployeeProjectDetails(text.id);
                setViewModalVisible(true);
                setViewModalLoading(true);
              }}
              className="btn btn-light-primary btn-icon text-uppercase"
            >
              <Icon type="eye" theme="filled" />
            </Button>
          </Tooltip>
        </span>
      ),
    },
  ];
  const handlePagination = (pagination) => {
    setpageCurrent(pagination.current);
    if(status == 'unassigned'){
      getEmployeeProjectList(pagination.current,status,searchKey); 
    }else{
    getEmployeeProjectList(pagination.current,status,searchKey,endingDate);
    }
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      // setLoading(true)
      setpageCurrent(1);
      if(status == 'unassigned'){
        getEmployeeProjectList(1,status,value)
      }else{
        getEmployeeProjectList(1,status,value,endingDate)
      }
     
    }, 500)
    return () => clearTimeout(timer)
  }, [value])

  return (
    <>

<section className="page-info-box itAssets-infoBox animated slideInRight">
                <div className="left multiple-item">
                  <div className="filter-firstHalf">
                    <div className="filter-dropdown-box allocation-dropdown" style={{ marginBottom: "2rem" }}>
                      <label className="label">Project Assigned status</label>
                      <Select
                        className="filter-dropdown"
                        defaultValue=""
                        style={{ width: 210 }}
                        placeholder="Select"
                        onChange={handleStatusChange}
                      >
                        <Option value="">All Employees </Option>
                        <Option value="assigned">Assigned Employees</Option>
                        <Option value="unassigned">Unassigned Employees</Option>
                      </Select>
                    </div>
                    <div className="filter-dropdown-box" id="calendarContainer" style={{ marginBottom: "2rem" }}>
                    <div
                      className="rangePicker-box fx fx--ai-c"
                      id="calendarContainer"
                    >
                     
                      <div className="">
                    
                      <label className="label">Project Ending Before   
                        <Tooltip 
                        title="Range of the filter is from current date to the selected date." 
                        >
                        <div style={{display:'inline-block',marginLeft:'5px'}}><i class="fa fa-info-circle" /></div>
                        </Tooltip>
                        </label>
                    
                    
                        {(
                          <DatePicker
                            disabled = {disableDate}
                            placeholder="Date"
                            onChange={handleEndingDateChange}
                           // onOpenChange={handleEndingDateChange}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="right">
                 <Input
                    allowClear={true}
                    placeholder="Search By Name OR ID"
                    onChange={(e) =>{
                      setSearchKey(e.target.value)
                      setValue(e.target.value);
                    }}
                    suffix={<Icon type="search" />}
                  />
                </div>
                    </div>
                </div>
                <div   className="right">
                <Button
                        className="btn btn-primary text-uppercase employee-list"
                        onClick={() => handleEmployeeList()}
                        loading={DownloadLoading}
                      >
                        <Icon type="download" />
                      </Button>
                </div>
              </section>
            
      
      {loading ? (
        <div className="page-spinner">
          <Spin />
        </div>
      ) : (
        <>
          <Table
            dataSource={
              allProjectList && allProjectList.data && allProjectList.data.data
            }
            columns={columns}
            onChange={handlePagination}
            pagination={{
              total:
                allProjectList &&
                allProjectList.data &&
                allProjectList.data.meta &&
                allProjectList.data.meta.pagination &&
                allProjectList.data.meta.pagination.total,
              pageSize:
                allProjectList &&
                allProjectList.data &&
                allProjectList.data.meta &&
                allProjectList.data.meta.pagination &&
                allProjectList.data.meta.pagination.per_Page,
              current: pageCurrent,
              showSizeChanger: false,
              defaultCurrent: 1,
            }}
          />

          {/* View Modal */}
          <Modal
            className="view-profile-modal profileAP-modal"
            destroyOnClose={true}
            maskClosable={false}
            centered
            visible={viewModalVisible}
            onCancel={() => setViewModalVisible(false)}
            footer={null}
          >
            <EmployeeViewProject
              employeeProjectDetails={
                employeeProjectDetails && employeeProjectDetails
              }
              viewModalLoading={viewModalLoading}
            />
          </Modal>
        </>
      )}
    </>
  );
};

export default withStore(NotificationUpdate(Employee));
