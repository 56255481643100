import React, { Component, Fragment } from "react";
import { Icon, Button, Row, Col } from "antd";
import car from '../../../assets/images/icons/icon-car.svg';
import scooter from '../../../assets/images/icons/icon-scooter.svg';
import cycle from '../../../assets/images/icons/icon-cycle.svg';

export default class ViewVehicleDetails extends Component {
    render() {
        return (
            <Fragment>

                {/* Vehicle Detail card */}
                <section className="content-section vehicle-detail-card-section">
                    <Row className="" gutter={30}>
                        <Col className="gutter-row" xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
                            <div className="card vehicle-card one">
                                <h3 className="title text-primary">Vehicle #1</h3>
                                <Row className="" gutter={30}>
                                    <Col className="gutter-row" xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                                        <div className="form-content form-group">
                                            <label className="label">Vehicle Type</label>
                                            <div className="value"><img className="vehicle-icon" src={scooter} alt="scooter icon" /></div>
                                        </div>
                                    </Col>
                                    <Col className="gutter-row" xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                                        <div className="form-content form-group">
                                            <label className="label">Brand</label>
                                            <div className="value">Yamaha</div>
                                        </div>
                                    </Col>
                                    <Col className="gutter-row" xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                                        <div className="form-content form-group">
                                            <label className="label">Model/Make</label>
                                            <div className="value">Fascino</div>
                                        </div>
                                    </Col>
                                    <Col className="gutter-row" xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                                        <div className="form-content form-group">
                                            <label className="label">Color</label>
                                            <div className="value">Red & Black</div>
                                        </div>
                                    </Col>
                                    <Col className="gutter-row" xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                                        <div className="form-content form-group">
                                            <label className="label">Registration Number</label>
                                            <div className="value">DL9SAM2345</div>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </Col>

                        <Col className="gutter-row" xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
                            <div className="card vehicle-card two">
                                <h3 className="title text-primary">Vehicle #2</h3>
                                <Row className="" gutter={30}>
                                    <Col className="gutter-row" xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                                        <div className="form-content form-group">
                                            <label className="label">Vehicle Type</label>
                                            <div className="value"><img className="vehicle-icon" src={car} alt="car icon" /></div>
                                        </div>
                                    </Col>
                                    <Col className="gutter-row" xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                                        <div className="form-content form-group">
                                            <label className="label">Brand</label>
                                            <div className="value">Audi</div>
                                        </div>
                                    </Col>
                                    <Col className="gutter-row" xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                                        <div className="form-content form-group">
                                            <label className="label">Model/Make</label>
                                            <div className="value">A6</div>
                                        </div>
                                    </Col>
                                    <Col className="gutter-row" xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                                        <div className="form-content form-group">
                                            <label className="label">Color</label>
                                            <div className="value">Black</div>
                                        </div>
                                    </Col>
                                    <Col className="gutter-row" xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                                        <div className="form-content form-group">
                                            <label className="label">Registration Number</label>
                                            <div className="value">DL9SAM2345</div>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </Col>

                        <Col className="gutter-row" xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
                            <div className="card vehicle-card three">
                                <h3 className="title text-primary">Vehicle #3</h3>
                                <Row className="" gutter={30}>
                                    <Col className="gutter-row" xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                                        <div className="form-content form-group">
                                            <label className="label">Vehicle Type</label>
                                            <div className="value"><img className="vehicle-icon" src={cycle} alt="cycle icon" /></div>
                                        </div>
                                    </Col>
                                    <Col className="gutter-row" xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                                        <div className="form-content form-group">
                                            <label className="label">Brand</label>
                                            <div className="value">Cycle</div>
                                        </div>
                                    </Col>
                                    <Col className="gutter-row" xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                                        <div className="form-content form-group">
                                            <label className="label">Model/Make</label>
                                            <div className="value">Racer</div>
                                        </div>
                                    </Col>
                                    <Col className="gutter-row" xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                                        <div className="form-content form-group">
                                            <label className="label">Color</label>
                                            <div className="value">Black</div>
                                        </div>
                                    </Col>
                                    <Col className="gutter-row" xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                                        <div className="form-content form-group">
                                            <label className="label">Registration Number</label>
                                            <div className="value">--</div>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    </Row>
                    <div className="floating-btn lg-hide">
                        <Button className="btn btn-floating btn-icon btn-round"><Icon type="plus" /></Button>
                    </div>
                </section>

            </Fragment>
        )
    }
}
