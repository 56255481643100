import React, { Component , Fragment } from "react";
import { Input, Row, Col, Radio, Select, Upload, Button, Icon } from "antd";

// For Input Tag
    
const { Option } = Select;

const children = [];

function handleChange(value) {
}

// For Upload
const fileList = [
    {
      uid: '-1',
      name: 'demo.png',
      status: 'done',
      url: 'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png',
      thumbUrl: 'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png',
    },
];
  
const props = {
    action: 'https://www.mocky.io/v2/5cc8019d300000980a055e76',
    listType: 'picture',
    defaultFileList: [...fileList],
};

export default class EditAdmin extends Component {

    // Radio Button
    state = {
        value: 1,
    };
    
    onChange = e => {
        this.setState({
            value: e.target.value,
        });
    };
    
    render() {
        return (
            <Fragment>
                <div className="add-new-admin-modal-container">
                    <Row className="" gutter={ 24 }>
                        <Col className="gutter-row" xs={24} sm={24} md={24} lg={8} xl={8} xxl={8}>
                            <div className="form-group">
                                <label className="label">Admin Name</label>
                            </div>
                        </Col>
                        <Col className="gutter-row" xs={24} sm={24} md={24} lg={16} xl={16} xxl={16}>
                            <div className="form-group">
                                <Input className="input" maxLength="255" placeholder="Enter admin name" />
                            </div>
                        </Col>
                    </Row>
                    <Row className="" gutter={ 24 }>
                        <Col className="gutter-row" xs={24} sm={24} md={24} lg={8} xl={8} xxl={8}>
                            <div className="form-group">
                                <label className="label">Admin Email</label>
                            </div>
                        </Col>
                        <Col className="gutter-row" xs={24} sm={24} md={24} lg={16} xl={16} xxl={16}>
                            <div className="form-group">
                                <Input className="input" maxLength="255" type="email" placeholder="Enter admin email" disabled />
                            </div>
                        </Col>
                    </Row>
                    <Row className="file-row" gutter={ 24 }>
                        <Col className="gutter-row" xs={24} sm={24} md={24} lg={8} xl={8} xxl={8}>
                            <div className="form-group">
                                <label className="label">Domain Name</label>
                            </div>
                        </Col>
                        <Col className="gutter-row" xs={24} sm={24} md={24} lg={16} xl={16} xxl={16}>
                            <div className="form-group">
                                <Select className="input input-tags" mode="tags" placeholder="Enter domain name" onChange={handleChange}>{children}</Select>
                            </div>
                        </Col>
                    </Row>
                    <Row className="file-row" gutter={ 24 }>
                        <Col className="gutter-row" xs={24} sm={24} md={24} lg={8} xl={8} xxl={8}>
                            <div className="form-group">
                                <label className="label">Organization Logo</label>
                            </div>
                        </Col>
                        <Col className="gutter-row" xs={24} sm={24} md={24} lg={16} xl={16} xxl={16}>
                            <div className="form-group">
                                <Upload className="full-width file-upload" {...props}>
                                    <Button className="input upload-btn">Select a file</Button>
                                </Upload>
                            </div>
                        </Col>
                    </Row>
                    <Row className="file-row" gutter={ 24 }>
                        <Col className="gutter-row" xs={24} sm={24} md={24} lg={8} xl={8} xxl={8}>
                            <div className="form-group">
                                <label className="label">Status</label>
                            </div>
                        </Col>
                        <Col className="gutter-row" xs={24} sm={24} md={24} lg={16} xl={16} xxl={16}>
                            <div className="form-group">
                                <Radio.Group onChange={this.onChange} value={this.state.value}>
                                    <Radio value={1}>Enable</Radio>
                                    <Radio value={2}>Disable</Radio>
                                </Radio.Group>
                            </div>
                        </Col>
                    </Row>
                </div>
            </Fragment>
        )
    }
}