import React, { Component, Fragment } from "react";

export default class CardHeader extends Component {

    constructor(props) {
        super(props)
        this.state = {

        }
    }
    componentDidMount() {
        this.getAssetHeader()
    }

    getAssetHeader() {
        // let allocationType = this.props.allocationType
        // let asset = this.props.assetHeader
        // this.setState({ asset: asset, allocationType: allocationType })
    }

    render() {
        var showQR = this.props.showQR;
        // let { asset } = this.state
        let asset = this.props.assetHeader
        let allocationType = this.props.allocationType
        return (
            <Fragment>
                <div className="cardHeader-container">
                    <div className="cardHeader-flexView">
                        <div className="deviceName-box header-item">
                            {/* <div className="device-image" style={{ backgroundImage: `url(${device})` }}></div> */}
                            <div className="device-name">{asset && asset.title}</div>
                        </div>
                        <div className="header-half-block">
                            <div className="deviceType-box header-item">
                                <div className="label lg-hide">Device Type</div>
                                <div className="deviceType-content">
                                    <div className="device-img-box">
                                        <div className="device-image" style={{ backgroundImage: `url(${asset && asset.type.url})` }}></div>
                                    </div>
                                    <h4 className="device-type">{asset && asset.type.name}</h4>
                                </div>
                            </div>
                            <div className="allocatedTo-box assigned header-item">
                                <div className="label lg-hide">Reference ID</div>
                                <div className="reference-id text-uppercase">{asset && asset.reference_id}</div>
                            </div>
                        </div>

                        {/* action buttons */}
                        {/* {allocationType == 'unallocated' &&
                            <div className="action-box">
                                <div className="action-box-buttons">
                                    <Tooltip placement="bottom" title={'QR Scan'}>
                                        <Button type="link" className="btn btn-light-secondary btn-icon" onClick={showQR}><Icon type="scan" /></Button>
                                    </Tooltip>
                                </div>
                            </div>
                        } */}
                    </div>
                </div>
            </Fragment>
        )
    }
}