import React from "react";
import { notification, Collapse, Modal, Upload, Icon, Drawer } from "antd";
import moment from "moment";

import { leaveAction } from "../../../admin/components/LeaveManagement/DataManager";
import CancelLeave from "../../../admin/components/LeaveManagement/components/CancelLeave/CancelLeave";
import _ from "lodash";
import CardDetails from "./CardDetails";
const CardHeader = ({ leave, setDataUpdate, loader, index }) => {
  const { Panel } = Collapse;
  const [assignedAccordionKey, setAssignedAccordionKey] = React.useState(null);
  const [showCancelDrawer, setShowCancelDrawer] = React.useState(false);
  const [leaveId, setLeaveId] = React.useState("");

  const { confirm } = Modal;

  // ---------------GETTING MONTH NAME FROM THE DATE
  const month = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  let date = new Date(leave.from_date);
  let startingMonth = month[date.getMonth()];
  let date2 = new Date(leave.to_date);
  let endingMonth = month[date2.getMonth()];

  const [cardData, setCardData] = React.useState({
    startMonth: startingMonth,
    startDate: leave.from_date.split("-")[2],
    endMonth: endingMonth,
    endDate: leave.to_date.split("-")[2],
    leaveType: leave.leave_type.name,
    status: "",
    from_session: "",
    to_session: "",
    statusColor: "",
    user_id: leave.id,
    actionReason: "",
    actionBy: "",
    actionOn: "",
    attachment: null,
  });

  const [imagePreviewVisible, setImagePreviewVisible] = React.useState(false);
  const [imagePreview, setImagePreview] = React.useState("");

  const getImageFiles = (imageUrls) => {
    if (imageUrls) {
      let imageResponse = [];
      imageUrls.forEach((url) => {
        imageResponse.push({ uid: "uid", url: url });
      });
      return imageResponse;
    }
    return [];
  };
  // RUN ONE TIME TO Set 'FROM DATE' TO 'TO DATE'
  React.useEffect(() => {
    setCardData((prevValue) => {
      let newFrom_session = "";
      let newTO_session = "";
      if (leave.from_date !== leave.to_date) {
        if (leave.from_session === 0) {
          newFrom_session = "full-day";
        } else {
          newFrom_session = "second-half";
        }

        if (leave.to_session === 0) {
          newTO_session = "first-half";
        } else {
          newTO_session = "full-day";
        }
      } else {
        if (leave.from_session === 0) {
          newFrom_session = "first-half";
          newTO_session = "first-half";
        } else if (leave.from_session === 1) {
          newFrom_session = "second-half";
          newTO_session = "second-half";
        } else {
          newFrom_session = "full-day";
          newTO_session = "full-day";
        }
      }

      return {
        ...prevValue,
        from_session: newFrom_session,
        to_session: newTO_session,
      };
    });
  }, []);

  React.useEffect(() => {
    setCardData((prevValue) => {
      let reason = "";
      let actionBy = "";
      let actionOn = "";
      let image = null;

      if (leave.action_reason) {
        reason = leave.action_reason;
      }
      if (leave.action_reason) {
        actionBy = leave.action_by.name;
      }
      if (leave.action_reason) {
        actionOn = leave.action_on;
      }
      if (leave.images) {
        image = leave.images;
      }

      return {
        ...prevValue,
        actionReason: reason,
        actionBy: actionBy,
        actionOn: actionOn,
        attachment: image,
      };
    });
  }, []);

  // RUN ONE TIME TO SET STATUS
  React.useEffect(() => {
    setCardData((prevValue) => {
      let statusMsg = "";
      let newStatusColor = "";

      if (leave.status === 0) {
        statusMsg = "Pending";
        newStatusColor = "warning";
      } else if (leave.status === 1) {
        statusMsg = "Approved";
        newStatusColor = "success";
      } else if (leave.status === 2) {
        statusMsg = "Declined";
        newStatusColor = "danger";
      } else if (leave.status === 3) {
        statusMsg = "Cancelled";
        newStatusColor = "info";
      } else {
        statusMsg = "Cancel Request";
        newStatusColor = "btn-secondary";
      }

      return {
        ...prevValue,
        status: statusMsg,
        statusColor: newStatusColor,
      };
    });
  }, [leave]);

  // for upload
  const handlePreviewCancel = () => {
    setImagePreviewVisible(false);
    setImagePreview("");
  };

  const handlePreview = (image) => {
    setImagePreview(image.url);
    setImagePreviewVisible(true);
  };

  const handleCancelClose = () => {
    setShowCancelDrawer(false);
  };
  const openCancelDrawer = () => {
    setShowCancelDrawer(true);
  };

  const handleCancel = (e) => {
    const time1 = new Date(leave.from_date).getTime();
    const today = new Date();
    const day = ("0" + today.getDate()).slice(-2);
    const month = ("0" + (today.getMonth() + 1)).slice(-2);
    const year = today.getFullYear();
    const time2 = new Date(year + "-" + month + "-" + day).getTime();
    const Difference_In_Time = time2 - time1;
    const Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
    let message =
      Difference_In_Days > 0
        ? "Cancelled successfully"
        : "Cancellation requested";
    confirm({
      title: "Are you Sure ?",
      onOk() {
        loader(true);
        e.preventDefault();
        leaveAction(
          {
            status: 3,
            action_reason: null,
          },
          leave.id
        )
          .then((res) => {
            setDataUpdate();
            notification.success({
              message: message,
            });
            loader(false);
          })
          .catch((error) => {
            loader(false);
            if (error.response.status === 422) {
              let errors = error.response.data.errors;
              let errorObject = {};
              Object.keys(errors).forEach((key) => {
                let err = [];
                errors[key].forEach((value) => {
                  err.push(new Error(value));
                });
                key = _.camelCase(key);
                errorObject[key] = { errors: err };
              });
            } else {
              notification.error(error.response.data.errors);
            }
          });
      },
    });
  };

  // For accordion dropdown click
  const assignedAccordionToggle = (key1) => {
    var assignedKey = assignedAccordionKey;
    setAssignedAccordionKey(assignedKey === key1 ? null : key1);
  };
  const actionTakenOn = moment(leave.action_on, "YYYY-MM-DD HH:mm:ss").format(
    "DD-MMM-YYYY"
  );
  const appliedOn = moment(leave.created_at, 'YYYY-MM-DD HH:mm:ss').format('DD-MMM-YYYY')

  return (
    <>
      {(leave.images && leave.images.length > 0) || leave.action_on ? (
        <Collapse className="leave-description-card-details" bordered={false}>
          <Panel
            showArrow={false}
            header={
              <CardDetails
                cardData={cardData}
                leave={leave}
                handleCancel={handleCancel}
                setLeaveId={setLeaveId}
                openDrawer={openCancelDrawer}
                padding="0"
              />
            }
            key={index + 1}
            extra={
              <Icon
                className="dropArrowPositionEmployee"
                type={assignedAccordionKey === index + 1 ? "up" : "down"}
                onClick={() => assignedAccordionToggle(index + 1)}
              />
            }
          >
            <div style={{ paddingLeft: "10px", paddingRight: "10px" }}>
              {leave.action_reason ? (
                <p>
                  <strong>
                    {leave.status === 1
                      ? "Reason for Approval"
                      : leave.status === 2
                      ? "Decline Reason"
                      : "Cancellation Reason"}
                    :&nbsp;
                  </strong>
                  {leave.action_reason}
                </p>
              ) : null}
              {leave.action_by ? (
                <p>
                  <strong>
                    {leave.status === 1
                      ? "Approved By"
                      : leave.status === 2
                      ? "Declined By"
                      : "Cancelled By"}
                    :&nbsp;
                  </strong>
                  {leave.action_by.name}
                </p>
              ) : null}
              {leave.action_on ? (
                <p>
                  <strong>
                    {leave.status === 1
                      ? "Approved On"
                      : leave.status === 2
                      ? "Declined On"
                      : "Cancelled On"}
                    :&nbsp;
                  </strong>
                  {actionTakenOn}
                </p>
              ) : null}
              {leave.created_at ? (
              <p><strong>Applied On: &nbsp;</strong>{appliedOn}</p>
            ) : null}
              {leave.images && leave.images.length > 0 && (
                <div style={{ paddingTop: "1rem" }}>
                  <Upload
                    beforeUpload={() => {
                      return false;
                    }}
                    listType="picture-card"
                    defaultFileList={getImageFiles(leave.images)}
                    isImageUrl={true}
                    onPreview={handlePreview}
                    showUploadList={{
                      showPreviewIcon: true,
                      showRemoveIcon: false,
                      showDownloadIcon: false,
                    }}
                  >
                    {null}
                  </Upload>

                  <Modal
                    className="viewImgModal"
                    visible={imagePreviewVisible}
                    footer={null}
                    onCancel={handlePreviewCancel}
                  >
                    <img
                      alt="leave"
                      style={{ width: "100%", maxHeight: "100%" }}
                      src={imagePreview}
                    />
                  </Modal>
                </div>
              )}
            </div>
          </Panel>
        </Collapse>
      ) : (
        <CardDetails
          cardData={cardData}
          leave={leave}
          handleCancel={handleCancel}
          openDrawer={openCancelDrawer}
          setLeaveId={setLeaveId}
          padding="10px"
        />
      )}

      <Drawer
        className="navigator-drawer declinedLeaves-drawer"
        title="Status"
        placement="right"
        width={500}
        onClose={handleCancelClose}
        visible={showCancelDrawer}
        bodyStyle={{ padding: 0 }}
        destroyOnClose={true}
      >
        {/* DRAWER FOR APPROVAL AND REJECTION */}
        <CancelLeave
          leaveId={leaveId}
          closeModal={() => setShowCancelDrawer(false)}
          loadLeaveData={setDataUpdate}
        />
      </Drawer>
    </>
  );
};

export default CardHeader;
