import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import { notification, Icon, Input, Button, Row, Col, Spin, Layout, Select, Modal, Collapse, Drawer, Tooltip, Divider, Pagination, message } from "antd";
import ForAdmin from "../../../../hocs/ForAdmin";
import lottie from 'lottie-web';
import QrReader from 'react-qr-reader'
import CardHeader from "./CardHeader";
import CardHeaderAssign from './CardHeaderAssign';
import CardContent from "./CardContent";
import CardContentAssign from "./CardContentAssign";
import AssetsHistory from './AssetsHistory';
import AddNewAsset from './AddNewAsset';
import { getAssetTypes, getAssets, addAssets, deleteAssets, editAssets, getOrganizationEmployees, getEmployeeAllocatedAssets, allocatedDeallocateAsset } from './DataManager';
import { allocateUsingQr } from '../../../itAssetsManagement/components/DataManager';
import ForAdminAndOfficeAdmin from "../../../../hocs/ForAdminAndOfficeAdmin";
import { withStore } from "../../../../hocs/withStore";
import emptyState from '../../../../assets/animation/json/emptyStateNew.json';
import ItAssetsIcon from "../../../../assets/images/icons/sibebar/it-assets.svg";
import NotificationUpdate from "../../../../hocs/NotificationUpdate";


const TITLE = "Assets";


// Pagination
const itemRender = (current, type, originalElement) => {
  if (type === 'prev') {
    return <a className="back-icon"> <Icon type="left" /> Back</a>;
  }
  if (type === 'next') {
    return <a className="next-icon">Next <Icon type="right" /></a>;
  }
  return originalElement;
}

// for Selectbox
const { Option } = Select;


// for collapse panel
const { Panel } = Collapse;

// for lottie
let animObj = null;

class ItAssetsManagementAdminPanel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible1: false,
      visible2: false,
      visible3: false,
      assignedAccordionKey: null,
      assetTypes: [],
      assetTypesloading: true,
      assets: [],
      editAsset: null,
      assetsloading: false,
      employees: [],
      employeesloading: false,
      employeeDisabled: true,
      status: 'unallocated',
      employee_uuid: '',
      loading: false,
      type_id: '',
      q: '',
      result: 'No result',
      qrReaderVisible: false,
      modal1Visible: false,
      pagination: {
        total: 0,
        count: 0,
        per_page: 1,
        current_page: 1,
        total_pages: 1
      }
    };
  }


  componentDidMount() {

    this.loadAssetTypes();
    this.loadAssets(this.state.status, this.state.type_id, 'false');
    this.loadOrganizationEmployees();
  }

  loadAnimation() {
    animObj = lottie.loadAnimation({
      container: this.animBox, // the dom element that will contain the animation
      renderer: 'svg',
      loop: true,
      autoplay: true,
      animationData: emptyState // the path to the animation json
    });
  }
  // For modal
  setModal1Visible(qrReaderVisible) {
    this.setState({ qrReaderVisible });
  }

  // for QR Reader
  handleScan = data => {

    if (data) {
      this.setState({
        result: data,
        qrReaderVisible: false,
        loading: true
      })


      allocateUsingQr(data)
        .then(res => {
          this.setState({ loading: false })

          if (res.data.returned_at) {
            notification.success({
              message: 'Asset Deallocated Successfully'
            })
          }

          else {
            notification.success({
              message: 'Asset Allocated Successfully'
            })
          }

          this.loadAssets(this.state.status, this.state.type_id, 'false');
        })
        .catch(error => {
          this.setState({ loading: false })

          notification.error({
            message: error.response.data.errors.message
          })
        })
    }
  }


  handleError = err => {
    message.error("QR scanner is only available on Safari for iOS Devices, Please allow access if you are using the same.", 5);
    this.setModal1Visible(false)
  }

  toggleQrReader = () => {
    var currentValue = this.state.qrReaderVisible;
    this.setState({
      qrReaderVisible: !currentValue
    })
  }

  //  For Drawer
  toggleModal = (field) => {
    this.setState({
      [field]: !this.state[field],
    });
  }

  // For accordion dropdown click
  assignedAccordionToggle = (key) => {
    var assignedKey = this.state.assignedAccordionKey;
    this.setState({
      assignedAccordionKey: assignedKey === key ? null : key
    })
  }

  // Allocation status filter 
  handleStatusChange = (newStatus) => {
    newStatus === 'allocated' ? this.setState({ employeeDisabled: false ,}) : this.setState({ employeeDisabled: true,employee_uuid: '' })

    this.setState({ status: newStatus, employee_uuid: '' })
    this.loadAssets(newStatus, this.state.type_id, 'false');

  }

  onEditAsset = (asset) => {
    this.setState({ editAsset: asset, visible2: true });
  }

  onCloseAddNew = (asset) => {
    this.setState({ editAsset: null, visible2: false });
  }

  // asset type selection filter 
  handleTypeChange = (value) => {
    this.setState({ type_id: value }, () => {
      if (this.state.employee_uuid != '')
        this.loadEmployeeChange(this.state.employee_uuid, this.state.q)
      else
        this.loadAssets(this.state.status, value, 'false', this.state.q);
    })
  }

  // employee change filter. 
  handleEmployeeChange = (value) => {
    this.setState({ employee_uuid: value, loading: true })
    if (value != '') {
      this.loadEmployeeChange(value, this.state.q)
      this.setState({ loading: false })
    }
    else {
      this.loadAssets(this.state.status, this.state.type_id, false, this.state.q, 1, value)
      this.setState({ loading: false })
    }
  }

  loadEmployeeChange = (id, q = '', page) => {
    this.setState({ assetsloading: true })
    return getEmployeeAllocatedAssets(this.state.type_id, id, q, page ? page : 1)
      .then(res => {
        this.setState({ assets: res.data, pagination: res.meta.pagination, assetsloading: false })
      })
      .catch(error => {
        notification.error({
          message: error.response.data.errors.message
        })
        this.setState({ assetsloading: false })
      })
  }


  loadAssets = (status, type_id, self, q = '', page, value = null) => {
    this.setState({ assetsloading: true})
    if (!value ) {
      return getAssets(status, type_id, self, q, page ? page : this.state.pagination.current_page)
        .then(res => {
          this.setState({ assets: res.data, pagination: res.meta.pagination, assetsloading: false, visible2: false, editAsset: null })
        })
        .catch(error => {
          this.setState({ assetsloading: false })
          notification.error({
            message: error.response.data.errors.message
          })
        })
    }
    else {
      this.loadEmployeeChange(this.state.employee_uuid, this.state.q)
      this.setState({ assetsloading: false, visible2: false, editAsset: null });
    }
  }


  loadAssetTypes = (pageNumber = 1) => {
    return getAssetTypes()
      .then(res => {
        this.setState({ assetTypes: res.data, assetTypesloading: false })
      })
      .catch(error => {
        notification.error({
          message: error.response.data.errors.message
        })
      })
  }

  deleteAsset = (id) => {
    return deleteAssets(id)
  }

  addNewAsset = (data) => {
    return addAssets(data)
  }

  editAsset = (id, data) => {
    return editAssets(id, data)
  }

  loadOrganizationEmployees = () => {
    this.setState({ employeesloading: true })
    return getOrganizationEmployees()
      .then(res => {

        this.setState({ employees: res.data, employeesloading: false })
      })
      .catch(error => {
        error && error.response && error.response.data && error.response.data.errors &&
          notification.error({
            message: error.response.data.errors.message
          })
      })
  }

  deallocateAsset = (id, deallocation_notes) => {
    return allocatedDeallocateAsset(id, false, deallocation_notes)
  }

  // For modal
  setModal1Visible(qrReaderVisible) {
    this.setState({ qrReaderVisible });
  }

  onChange = (e) => {
    let search = e.target.value
    this.setState({ q: search })
    if (this.state.employee_uuid == '')
      this.loadAssets(this.state.status, this.state.type_id, false, search)
    else
      this.loadEmployeeChange(this.state.employee_uuid, search)
  }

  onPageChange = (pageNumber) => {
    let state = this.state;
    state.loading = true;
    state.pagination.current_page = pageNumber;
    this.setState(state);
    const { employee_uuid, status, type_id, q } = this.state
    if (employee_uuid == '') {
      this.loadAssets(status, type_id, false, q, pageNumber);
      this.setState({ loading: false });
    }
    else {
      this.loadEmployeeChange(employee_uuid, q, pageNumber);
      this.setState({ loading: false });
    }

  }

  render() {
    const { status, assetTypes, assetTypesloading, assets, employees, employeesloading, employeeDisabled, assetsloading, loading ,employee_uuid} = this.state
    const { total, per_page, current_page } = this.state.pagination;
    return (
      <Fragment>
        <Helmet>
          <title>{TITLE}</title>
        </Helmet>
          
        {loading ? <div className="page-spinner" > <Spin /></div> : (
          <div className="page-wrapper">
            <div className="itassetsAp-container itassets-container">
              
              {/* page Header */}
              <div className="page-header">
                <div className="page-title">
                  <h2 className="title"><img className="icon icon-itassets" src={ItAssetsIcon} alt="view icon" />{" "} Assets Management</h2>
                </div>
                <div className="footer-buttons">
                  <Button className="btn btn-primary text-uppercase addAssetBtn" onClick={() => { this.toggleModal('visible2') }}><Icon type="plus" />Add New</Button>
                  <Button className="btn btn-secondary lg-hide scanBtn" onClick={() => this.setModal1Visible(true)}><Icon type="scan" /></Button>
                </div>

                {/* edit and add new asset drawer sidebar */}
                <Drawer
                  className="addAsset-drawer navigator-drawer"
                  title={this.state.editAsset ? "Edit Asset" : "Add New Asset"}
                  width={500}
                  onClose={() => { this.onCloseAddNew() }}
                  visible={this.state.visible2}
                  bodyStyle={{ padding: 0 }}
                  destroyOnClose={true}
                >
                  <AddNewAsset
                    editAsset={this.state.editAsset}
                    assetTypes={this.state.assetTypes}
                    addNewAsset={(data) => { return this.addNewAsset(data) }}
                    onEditAsset={(id, data) => { return this.editAsset(id, data) }}
                    loadAssets={() => { return this.loadAssets(this.state.status, this.state.type_id, 'false') }}
                  />
                </Drawer>

                {/* <Drawer
                  className="assignAssets-drawer navigator-drawer"
                  title="Assets Allocation"
                  width={500}
                  onClose={()=>{this.toggleModal('visible3')}}
                  visible={this.state.visible3}
                  bodyStyle={{ padding: 0 }}
              >
                <AddNewAsset />
              </Drawer> */}
              </div>

              {/* page info title and filter options*/}

              <section className="page-info-box itAssets-infoBox animated slideInRight">
                <div className="left multiple-item">
                  <div className="filter-firstHalf">
                    <div className="filter-dropdown-box allocation-dropdown">
                      <label className="label">Allocation status</label>
                      <Select
                        className="filter-dropdown"
                        defaultValue='unallocated'
                        style={{ width: 210 }}
                        placeholder="Select"
                        onChange={this.handleStatusChange}
                      >
                        <Option value="unallocated">Unallocated</Option>
                        <Option value="allocated">Allocated</Option>
                      </Select>
                    </div>

                    <div className="filter-dropdown-box device-type-dropdown">
                      <label className="label">Asset Type</label>
                      <Select
                        showSearch
                        className="filter-dropdown"
                        style={{ width: 210 }}
                        placeholder="Select"
                        onChange={this.handleTypeChange}
                        defaultValue=''
                        loading={assetTypesloading}
                        filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                      >
                        <Option key='0' value=''> All Assets </Option>
                        {assetTypes && assetTypes.map(types => (
                          <Option key={types.id} value={types.id}>{types.type}</Option>
                        ))}
                      </Select>
                    </div>

                  </div>

                  {!employeeDisabled &&
                    <div className="filter-secondHalf">
                      <div className="filter-dropdown-box employee-dropdown">
                        <label className="label">Employee</label>
                        <Select
                          showSearch
                          defaultValue=''

                          className="filter-dropdown"
                          style={{ width: 210 }}
                          placeholder="Select"
                          onChange={this.handleEmployeeChange}
                          disabled={employeeDisabled}
                          loading={employeesloading}
                          optionFilterProp="children"
                          filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                        >
                          <Option key={0} value={''}> All Employees </Option>
                          {employees && employees.map((employee, index) => (
                            <Option key={index} value={employee.uuid}>
                              {employee.name}
                              {/* <div className="dropdown-icon-box">
                                <div className="dropdowp-image" style={{ backgroundImage: `url(${employee.url})` }}></div>
                                <div className="username">{employee.name}</div>
                              </div> */}
                            </Option>
                          ))}

                        </Select>
                      </div>
                    </div>
                  }
                </div>
                <div className="right">
                  <div className="search-box">
                    <label className="label v-hidden sm-v-none">Search</label>
                    <Input placeholder="Search by Reference ID" onChange={this.onChange}
                      suffix={<Icon type="search" />}
                    />
                  </div>
                </div>
              </section>

              {/* page data */}
              {assetsloading ? <div className="page-spinner" > <Spin /></div> : (
                <section className="content-section assets-container-section animated slideInUp">
                  {assets.length !== 0 &&
                    <Fragment>
                      <div className="content-body">

                        <div className="head-row-flexView">
                          <div className="head-row-content">
                            <div className="device-name-box">
                              <div className="head-title label">Asset Name</div>
                            </div>
                            <div className="rest-headerColumn-box">
                              <div className="head-title label refID-title">Reference ID</div>
                              <div className="head-title label allocatedTo-title">Allocated To oiui</div>
                              <div className="head-title label action-title">Action</div>
                            </div>
                          </div>
                        </div>


                        <div className="accordion-row-box" >
                          <Collapse
                            className="itAssets-description-card"
                            bordered={false}
                            activeKey={this.state.assignedAccordionKey}
                          >

                            {status == "allocated" && assets && assets.map((asset, index) => (
                              <Panel header={<CardHeader
                                assetHeader={asset}
                                deallocateAsset={(id, deallocation_notes) => { return this.deallocateAsset(id, deallocation_notes) }}
                                loadAssets={() => { return this.loadAssets(this.state.status, this.state.type_id, 'false') }}
                                onEditAsset={this.onEditAsset}
                              >

                              </CardHeader>} key={index + 1} showArrow={false} extra={
                                <Icon type={this.state.assignedAccordionKey === index + 1 ? "up" : "down"} onClick={() => this.assignedAccordionToggle(index + 1)} />
                              } >
                                <CardContent assetContent={asset} />
                              </Panel>

                            ))}
                          </Collapse>
                        </div>



                        < div className="accordion-row-box" >
                          <Collapse
                            className="itAssets-description-card"
                            bordered={false}
                            activeKey={this.state.assignedAccordionKey}
                          >
                            {status == "unallocated" && assets && assets.map((asset, index) => (
                              <Panel header={<CardHeaderAssign
                                onEditAsset={this.onEditAsset}
                                assetHeader={asset}
                                deleteAssets={(id) => { return this.deleteAsset(id) }}
                                loadAssets={() => { return this.loadAssets(this.state.status, this.state.type_id, 'false') }}
                              />
                              } key={index + 1} showArrow={false} extra={
                                <Icon type={this.state.assignedAccordionKey === index + 1 ? "up" : "down"} onClick={() => this.assignedAccordionToggle(index + 1)} />
                              } >
                                <CardContentAssign assetContent={asset} />
                              </Panel>
                            ))}
                          </Collapse>
                        </div>

                      </div>
                      {total && per_page && total > per_page &&
                        <div className="pagination-box text-right">
                          <Pagination className="pagination" onChange={this.onPageChange} current={current_page} total={total} itemRender={itemRender} pageSize={per_page} />
                        </div>
                      }
                    </Fragment>
                  }
                </section>

              )}

              {/* empty state */}
              {!assets.length && < div className="emptyState-container animated slideInUp">
                <Row gutter={30} className="row">
                  <Col className="col gutter-row" lg={24}>
                    <div className="emptyStateContent">
                      <div className="lottieAnimaton" ref={ref => this.animBox = ref}></div>
                      <h2 className="noData-text text-center">No data found!</h2>
                      {this.loadAnimation}
                    </div>
                  </Col>
                </Row>
              </div>}

              <Modal
                className="qrReader-modal"
                title="Scan QR Code"
                centered
                visible={this.state.qrReaderVisible}
                onOk={() => this.setModal1Visible(false)}
                onCancel={() => this.setModal1Visible(false)}
                footer={null}
              >
                {this.state.qrReaderVisible &&
                  <div className="qrReader-container">
                    <div className="qrReader-content">
                      <QrReader className="qr-reader"
                        delay={1000}
                        onError={this.handleError}
                        onScan={this.handleScan}
                      />
                    </div>
                  </div>
                }
              </Modal>
              {/* <p>{this.state.result}</p> */}

            </div>
          </div>
        )}

      </Fragment >
    );
  }
}

export default ForAdminAndOfficeAdmin(withStore(NotificationUpdate(ItAssetsManagementAdminPanel)))
