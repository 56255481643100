import React, { Fragment, useState, useEffect } from "react";
import { Tabs, Spin, Form } from "antd";
import _ from "lodash";
import AdminEditProjectDetails from "./AdminEditProjectDetails";
import AdminEditTeam from "./AdminEditTeam";
import {
    getProjectDetailsEdit
  } from "../../../services/ProjectService";

// for tabs
const { TabPane } = Tabs;
const Project = (props) => {
  var initialLoading = props.loading;
  var new_project = props.new_project ? true : false;
  const autoUpdate = props.autoUpdate;
  const closeModal = props.closeModal;
  const [activeTab, setActiveTab] = useState("1");
  const [defaultData , setDefaultData] = useState([]);
 let id = "";

 const [ loadings ,setLoadings ] = useState(false);
 if(!new_project){
    if(props.data && props.data.id){
      id=props.data.id;
    }
 }
  
  useEffect(() => {
    if(id){
      setLoadings(true);  
    getProjectDetailsEdit(id).then((res) => {
        if(res && res.data && res.data.data){
        setDefaultData(res.data.data)
        }
        setLoadings(false);
      })
    }
  }, [id]);


const handletabChange= (active)=> {
     if(active == "1"){
        getProjectDetailsEdit(id).then((res) => {
            setDefaultData(res.data.data);
          });
     }
}

  return (
    <Fragment>
      <div className="profile-dashboard-container edit-team-directory">
        <h3 className="title text-primary">Project Details</h3>
        {initialLoading ? (
          <center>
            <Spin />
          </center>
        ) : (
          <section className="page-info-box">
            <Tabs 
             defaultActiveKey={"1"}
             activeKey={activeTab} 
             onChange= {(active)=>
               { 
                setActiveTab(active)
                handletabChange(active);
               }}

            >
              <TabPane tab="Details" key="1">
                <AdminEditProjectDetails
                  data={defaultData ? defaultData : null}
                  loading={loadings}
                  autoUpdate={autoUpdate}
                  closeModal={closeModal}
                  new_project={new_project}
                  onSave={() => {
                    setActiveTab("2");
                  }}
                />
              </TabPane>
              {!new_project && (
                <TabPane tab="Team" key="2">
                  <AdminEditTeam
                    data={defaultData ? defaultData : null}
                    loading={initialLoading}
                    autoUpdate={autoUpdate}
                    closeModal={closeModal}
                  />
                </TabPane>
              )}
            </Tabs>
          </section>
        )}
      </div>
    </Fragment>
  );
};

export default Form.create()(Project);
