import React, { Component, Fragment } from 'react';
import { Layout } from 'antd';
import { Helmet } from 'react-helmet';

import LeaveDashboard from './components/LeaveDashboard';

const TITLE = 'Leave Management';

export default class LeaveManagement extends Component {
  render() {
    return (
      <Fragment>
        <Helmet>
          <title>{TITLE}</title>
        </Helmet>
        <div className="page-wrapper">
          <LeaveDashboard></LeaveDashboard>
        </div>
      </Fragment>
    );
  }
}
