import _ from 'lodash';
import React, { Component } from "react";
import { Link } from 'react-router-dom';
import { Layout, Breadcrumb, notification, Menu, Tooltip, Dropdown, Switch, Popover, Icon, Button, message } from "antd";
import { withStore } from '../../hocs/withStore';
import url from '../../config/url';
import { axiosInstance as api } from '../../utilities/configureAxios';
import MetaTags from 'react-meta-tags';
import home from '../../assets/images/icons/home.svg';
import arrowback from '../../assets/images/icons/arrow-back.svg';
import bell from '../../assets/images/icons/notification.svg';
import signout from '../../assets/images/icons/signout.svg';
import back from '../../assets/images/icons/icon-back.svg';

const { Header } = Layout;

class HeaderPrimary extends Component {
  constructor(props) {
    super(props);
    this.handleMouseHover = this.handleMouseHover.bind(this);
    this.state = {
      isHovering: false,
      web: false,
      sms: false,
      user_roles_name: _.get(props, "store.data.user_roles_name", []),
      notification_popover: false,
      notification_loading: false
    };

  }

  componentDidMount() {
    const { store } = this.props
    let data = JSON.parse(localStorage.getItem('data'));

    var user_roles = [];
    var user_roles_name = [];
    this.getNotificationPreference();

    api
      .get(url["myRoles"])
      .then(res => {
        user_roles = _.get(res, 'data.data', [])
        user_roles_name = _.map(user_roles, 'name', [])
        data.user_roles_name = user_roles_name
        store.setData(data);
      })

    api
      .get(url["getUserByEmail"] + '?email=' + data.user.email)
      .then(res => {
        let user = _.get(res, 'data.data', [])
        data.user.is_reporting_manager = user.is_reporting_manager
        store.setData(data);
      })

  }

  getNotificationPreference = () => {

    api
      .get('/notification-preferences')
      .then(res => {
        this.setState({ web: res.data.data.web_push_notification, sms: res.data.data.sms_notification })
      })
      .catch(error => {
      });

  }

  setNotificationPreference = () => {
    this.setState({ notification_loading: true })
    let body = {
      sms_notification: this.state.sms ? 1 : 0,
      web_push_notification: this.state.web ? 1 : 0
    }

    api
      .post('/notification-preferences', body)
      .then(res => {
        this.getNotificationPreference();
        this.setState({ notification_loading: false, notification_popover: false })
        // notification['success']({
        //   message: "Notification preferences saved"
        // })
        message.success("Notification preferences saved.", 3);
      })
      .catch(error => {
        this.setState({ notification_loading: false, notification_popover: false })
        notification['error']({
          message: "Oops! An error occurred"
        })
      });
  }

  content = () => (

    < div className="switch-notification-box" >
      <div className="notification-switch web">
        <div className="title">Web</div>
        <div className="switch-box">
          <Switch defaultChecked={this.state.web} onChange={(checked) => { this.setState({ web: checked }) }} />
        </div>
      </div>
      <div className="notification-switch web">
        <div className="title">SMS</div>
        <div className="switch-box">
          <Switch defaultChecked={this.state.sms} onChange={(checked) => { this.setState({ sms: checked }) }} />
        </div>
      </div>
      <div className="notification-switch web">
        <div className="title">Email</div>
        <div className="switch-box">
          <Switch defaultChecked={true} disabled={true} />
        </div>
      </div>
      <div className="save-notification-btn">
        <Button className="btn btn-success full-width" disabled={this.state.notification_loading} loading={this.state.notification_loading} onClick={() => this.setNotificationPreference()}>Save</Button>
      </div>
    </div>
  )

  logout = () => {
    const { logout } = this.props.store;
    let device_id = document.cookie.replace(
      /(?:(?:^|.*;\s*)device_id\s*\=\s*([^;]*).*$)|^.*$/,
      "$1"
    );
    this.setState({ loading: true });
    logout();
    api
      .get('/logout' + '?id=' + device_id)
      .then(res => {
        this.setState({ loading: false });
        notification['success']({
          message: "Logged out successfully!"
        });

      })
      .catch(error => {
        notification['error']({
          message: _.get(error, 'response.data.errors.message', "Oops! Something went wrong!")
        });
        this.setState({ loading: false });
      });

  };

  handleMouseHover = () => {
    this.setState({ isHovering: false })
    this.setState({ isHovering: true })
  }

  handleHoverChange = () => {
    this.setState({
      notification_popover: !this.state.notification_popover
    });
  };

  render() {
    const profile_pic = _.get(this.props, 'store.data.profile_pic', null)
    const { user_roles_name } = this.state;
    const contract = user_roles_name.indexOf("contract_employee") != -1;
    
      let title = "";
      if(contract){
        title = "View Profile";
      }else{
        title = "Edit Profile";
      }
    return (
      <Layout>
        <MetaTags>
          <meta property="og:image" content="../../../public/seo/og-navigator-fece51b13ef6e73cb5fb941700cf7275.png" />
        </MetaTags>
        <Header className="header-container">
          <div className="header-content fx">
            <div className="header-breadcrumb left">
              <Breadcrumb separator={<img src={arrowback} alt="separator icon" />}>
                <Breadcrumb.Item className="item" href="/dashboard/home"><img className="home-icon" src={home} alt="home icon" /></Breadcrumb.Item>
                <Breadcrumb.Item className="item">Navigator</Breadcrumb.Item>
              </Breadcrumb>
            </div>
            <div className="sm-back-icon-box">
              <Link to="/dashboard/home">
                <img className="back-icon" src={back} alt="back icon" />
              </Link>
            </div>
            {/* <Dropdown overlay={menu} placement="bottomRight">
                          <a className="ant-dropdown-link" href="#"><img className="notification-icon" src={ notification } alt="notification icon" /></a>
                      </Dropdown> */}
            {/* {
                        this.state.isHovering &&
                        <div>
                          <NotificationHover />
                        </div>
                      } */}
            {/* </div> */}
            {/* visible={this.state.notification_popover} onClick={this.handleHoverChange} */}
            <div className="action right fx">
              <div className="notification-icon-box" >
                <Popover content={this.content()} trigger="click" placement="bottom" title="Notifications">
                  <img className="notification-icon" src={bell} alt="notification icon" />
                </Popover>

                {/* <Popover content={this.content()} trigger="click" placement="bottom" title="Push Notification">
                  <img className="notification-icon" src={bell} alt="notification icon" />
                </Popover> */}
              </div>

              <div className="user-profile-box">
                <div className="user-profile-link">
                  <Tooltip title={title} placement="bottom">
                    <Link className="profile-link" to="/dashboard/profile">
                      {/* <img className="user-img" src={profile_pic} alt="user-image" /> */}
                      <div className="user-img" style={{ backgroundImage: `url(${profile_pic})` }}></div>
                    </Link>
                  </Tooltip>
                </div>
              </div>

              <div className="logout-icon-box">
                <Tooltip title="Logout" placement="bottom">
                  <img className="logout-icon" src={signout} style={{ cursor: 'pointer' }} onClick={this.logout} alt="signout icon" />
                </Tooltip>
              </div>
            </div>

            {/* <div className="logout-icon-box">
              <Tooltip title="Logout" placement="bottom">
                <img className="logout-icon" src={signout} style={{ cursor: 'pointer' }} onClick={this.logout} alt="signout icon" />
              </Tooltip>
            </div> */}
          </div>
        </Header>
      </Layout>
    );
  }
}

export default withStore(HeaderPrimary)