import React, { Component , Fragment } from "react";
import { Form, Icon, Input, Button, Checkbox } from "antd";

// Importing images
import logo from "../../../assets/images/full-logo.svg";
import google from "../../../assets/images/icons/google.svg";
// import women from "../../../assets/images/user/icon-women.svg";


class SuperAdminLogin extends Component {

    handleSubmit = e => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {
            }
        });
    };
    
    render() {
        const { getFieldDecorator } = this.props.form;
        return (
            <Fragment>
                <div className="page-wrapper height--full-vh">
                    <div className="login-container superadmin-container">
                        <div className="login-content">
                            <div className="login-box">
                                <div className="logo-box mb--60">
                                    <img className="company-logo" src={logo} alt="logo" />
                                </div>
                                <h1 className="header-title lg">Welcome to <span className="highlight">Navigator</span></h1>
                                <p className="header-description info lg">Start Login as Super Admin</p>
                                
                                {/* Super Admin Login Form */}
                                <Form onSubmit={this.handleSubmit} className="login-form">
                                    <Form.Item className="form-username-row form-row">
                                        {getFieldDecorator('username', {
                                            rules: [{ required: true, message: 'Please input your username!' }],
                                        })(
                                            <Input className="input" maxLength="255" prefix={<Icon type="user" />} placeholder="Username" />,
                                        )}
                                    </Form.Item>
                                    <Form.Item className="form-password-row form-row">
                                        {getFieldDecorator('password', {
                                            rules: [{ required: true, message: 'Please input your Password!' }],
                                        })(
                                            <Input className="input" maxLength="255" prefix={<Icon type="lock" />} type="password" placeholder="Password" />,
                                        )}
                                    </Form.Item>
                                    <Form.Item className="form-submit-row form-row">
                                        <div className="form-remember-row">
                                            {getFieldDecorator('remember', {
                                                valuePropName: 'checked',
                                                initialValue: true,
                                            })(<Checkbox>Remember me</Checkbox>)}
                                            {/* <a className="login-form-forgot" href=""> Forgot password</a> */}
                                        </div>
                                        <div className="form-btn-row">
                                            <Button htmlType="submit" className="login-form-button btn btn-lg btn-primary full-width text-uppercase" href="/dashboard/superadmin/organization">Login</Button>
                                        </div>
                                        {/* <div className="form-register-row">
                                            <a href="">register now!</a>
                                        </div> */}
                                    </Form.Item>
                                </Form>
                                
                            </div>
                        </div>
                        <div className="powered-by-box">
                            <p className="text-poweredby sm">Powered by</p>
                            <a href="https://squareboat.com" target="_blank"><img className="footer-logo" src={logo} alt="logo" /></a>
                        </div>
                    </div>
                </div>
            </Fragment>   
        )
    }
}

const WrappedSuperAdminLogin = Form.create()(SuperAdminLogin);
export default (WrappedSuperAdminLogin);