import React, { Component, Fragment } from "react";
import { notification, Icon, Input, Button, Popconfirm, Spin, Row, Col, Form, Table, Divider, Modal, Radio, Upload, Tooltip, Select, Card } from "antd";
import ForAdmin from "../../../../hocs/ForAdmin";
import { withStore } from "../../../../hocs/withStore";
import _ from 'lodash';
import moment from "moment";
import { Link } from 'react-router-dom';
import file_icon from '../../../../assets/images/icons/icon-upload.svg';
import url_icon from '../../../../assets/images/icons/icon-url.svg';
import { axiosInstance as api } from '../../../../utilities/configureAxios';
import CompanyDocument from '../../../../assets/images/icons/sibebar/company-documents.svg';
import { Helmet } from 'react-helmet';
import { getFolderWiseDocument, getFolder, getSingleFolder } from './DataManager';
import url from '../../../../config/url';
import data_setting from '../../../../config/data_setting';


const TITLE = 'Documents'

// Pagination
const itemRender = (current, type, originalElement) => {
    if (type === 'prev') {
        return <a className="back-icon"> <Icon type="left" /> Back</a>;
    }
    if (type === 'next') {
        return <a className="next-icon">Next <Icon type="right" /></a>;
    }
    return originalElement;
}

// for select
const { Option } = Select;


class DocumentAdminFolder extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            single_folder: null,
            documents: [],
            document_type: null,
            current_document: null,
            document_file: null,
            uploaded_file: null,
            visible: false,
            slug: '',
            q: '',
            pagination: {
                total: 0,
                count: 0,
                per_page: 1,
                current_page: 1,
                total_pages: 1
            },
            modal1Visible: false,
        };
    }

    componentDidMount() {
        this.getDocuments()
        this.getDocumentFolders()
    }

    getDocuments = () => {
        const query = new URLSearchParams(this.props.location.search);
        const slug = query.get('id')
        this.setState({ slug: slug, loading: true })

        const { q } = this.state

        getFolderWiseDocument(slug, q, 1)
            .then(res => {
                const documents = _.get(res, 'data.data', [])
                const pagination = _.get(res, 'data.meta.pagination', []);
                this.setState({ documents, loading: false, pagination, current_document: null, uploaded_file: null, loading: false, visible: false });

            })
            .catch(error => {
                notification['error']({
                    message: _.get(error, 'response.data.errors.message', "Oops! Something went wrong!")
                });
                this.setState({ loading: false });
            });

        getSingleFolder(slug)
            .then(res => {
                this.setState({ single_folder: res.data })
            })
            .catch(error => {
                notification['error']({
                    message: _.get(error, 'response.data.errors.message', "Oops! Something went wrong!")
                });
            })
    }

    columns = [
        {
            title: 'Document Name',
            dataIndex: 'title',
            className: "document-name",
        },
        {
            title: 'Type',
            dataIndex: 'type',
            className: "document-name",
            render: (value) => (
                value == 'file' ? 'File' : 'Link'
            )
        },
        {
            title: 'Added on',
            dataIndex: 'updated_at',
            className: "document-date",
            render: (value) => (
                moment(value, 'YYYY-MM-DD HH:mm:ss').format('DD-MMM-YYYY')
                //new Date(value).toDateString()
            )
        },
        {
            title: 'Action',
            dataIndex: 'action',
            className: "action",
            render: (action, document) => (
                <span>
                    <a href={document.url} target="_blank">
                        <Button className="btn btn-light-secondary btn-icon">
                            {
                                document.type == 'link' && <Fragment>
                                    <Icon type="link" />
                                </Fragment>
                            }
                            {
                                document.type == 'file' && <Fragment>
                                    <Icon type="download" />
                                </Fragment>
                            }
                        </Button>
                    </a>
                    <Divider type="vertical" />
                    <Tooltip placement="bottom" title={'Edit'}><Button type="link" onClick={() => this.onDocumentEdit(document)} className="btn btn-light-info btn-icon text-uppercase"><Icon type="edit" theme="filled" /></Button></Tooltip>
                    <Divider type="vericle" />
                    <Popconfirm
                        placement="bottomLeft"
                        icon={<Icon type="warning" theme="twoTone" twoToneColor="#fcb103" />}
                        title="Are you sure you want to delete this document?"
                        onConfirm={() => this.onDocumentDelete(document.slug)}
                        okText="Yes"
                        cancelText="No"
                    >
                        <Tooltip placement="bottom" title={'Delete'}><Button type="link" className="btn btn-light-danger btn-icon text-uppercase"><Icon type="delete" theme="filled" /></Button></Tooltip>
                    </Popconfirm>
                </span>
            )
        },
    ];

    onPageChange = (pageNumber) => {
        let state = this.state
        state.loading = true
        state.pagination.current_page = pageNumber
        this.setState({ state });

        const { slug, q } = this.state

        getFolderWiseDocument(slug, q, pageNumber)
            .then(res => {
                const documents = _.get(res, 'data.data', [])
                const pagination = res.data.meta.pagination;
                this.setState({ documents, loading: false, pagination });
            })
            .catch(error => {
                notification['error']({
                    message: _.get(error, 'response.data.errors.message', "Oops! Something went wrong!")
                });
                this.setState({ loading: false });
            });
    }

    onDocumentEdit = current_document => {
        if (current_document && current_document.type == 'file') {
            this.setState({
                document_file: true
            })
        }
        this.setState({ current_document, document_type: current_document.type, visible: true }, () => {
        })
    }


    onDocumentDelete = document_slug => {
        this.setState({ loading: true });
        api
            .delete(url['getDocuments'] + "/" + document_slug)
            .then(res => {
                notification['success']({
                    message: "Document deleted successfully"
                });
                this.getDocuments()
            })
            .catch(error => {
                notification['error']({
                    message: _.get(error, 'response.data.errors.message', "Oops! Something went wrong!")
                });
                this.setState({ loading: false });
            });
    }

    handleOk = e => {
        const { uploaded_file, current_document } = this.state;
        e.preventDefault();
        this.props.form.validateFieldsAndScroll((err, formData) => {
            if (!err) {
                var form_data = new FormData();
                if (formData.type === 'file') {
                    if (formData.file) {
                        form_data.append('file', uploaded_file);
                    }
                }
                else {
                    if (!current_document || current_document.type === 'link')
                        form_data.append('url', formData.url);
                }
                if (current_document && current_document.type === 'file') {
                    if (formData.file) {
                        form_data.append('file', uploaded_file);
                    }
                }
                form_data.append('title', formData.title);
                form_data.append('folder_id', formData.folder_id);
                if (current_document)
                    form_data.append('type', current_document.type === 'file' ? data_setting["document"]["file"] : data_setting["document"]["link"]);
                else
                    form_data.append('type', formData.type === 'file' ? data_setting["document"]["file"] : data_setting["document"]["link"]);
                this.setState({ loading: true });
                api
                    .post(current_document ? url['getDocuments'] + "/" + current_document.slug : url['createDocument'], form_data)
                    .then(res => {
                        this.getDocuments()
                        notification['success']({
                            message: "Document " + (current_document ? "edited" : "created") + " successfully!"
                        });
                    })
                    .catch(error => {
                        const error_code = _.get(error, 'response.status');
                        if (error_code && error_code == '422') {
                            notification['error']({
                                message: "Invalid Data",
                                description: Object.values(_.get(error, 'response.data.errors', "Oops! Something went wrong!"))
                            });
                        }
                        else {
                            notification['error']({
                                message: _.get(error, 'response.data.errors.message', "Oops! Something went wrong!")
                            });
                            this.setState({ visible: false });
                        }
                        this.setState({ loading: false });
                    });
            }
        });
    };

    handleCancel = e => {
        this.setState({
            visible: false,
            current_document: null,
            document_type: null,
            document_file: null
        });
    };

    onTypeChange = (e) => {
        this.setState({
            document_type: e.target.value
        })
    }

    onSearchDoc = (e) => {

        this.setState({ q: e.target.value }, () => {
            this.getDocuments();
        })

    }

    setModal1Visible(modal1Visible) {
        this.setState({ modal1Visible });
    }

    getDocumentFolders = () => {
        return getFolder()
            .then(res => {
                this.setState({ folders: res.data })
            })
            .catch(error => {
                throw error
            })
    }

    render() {
        const { getFieldDecorator } = this.props.form;
        const { documents, document_type, current_document, visible, loading, folders, single_folder } = this.state;
        const { total, per_page, current_page } = this.state.pagination;
        return (
            <Fragment>
                <Helmet>
                    <title>{TITLE}</title>
                </Helmet>

                <div className="page-wrapper">
                    <div className="documentAP-container">
                        {/* page Header */}
                        <div className="page-header">
                            <div className="page-title">
                                <h2 className="title"><img className="icon icon-view" src={CompanyDocument} alt="view icon" /> Documents</h2>
                            </div>
                            {/* <Button className="btn btn-primary text-uppercase btn-icon"><Icon type="left" /> Back</Button> */}

                            <Link to="/dashboard/admin/document"  ><Button className="btn btn-primary text-uppercase btn-icon"><Icon type="left" /> Back</Button></Link>
                        </div>

                        {/* page info title */}

                        <section className="page-info-box">
                            <h3 className="info-title">{single_folder && single_folder[0].name}</h3>
                            <div className="right">
                                <div className="search-box">
                                    <Input placeholder="Search Documents" onChange={this.onSearchDoc} suffix={<Icon type="search" />} />
                                </div>
                            </div>
                        </section>

                        {loading ? (<center><Spin /></center>) : (
                            <section>

                                <section className="content-section document-table-info animated slideInUp">
                                    {documents && documents.length != 0 &&

                                        <Table className="table-content" columns={this.columns} dataSource={documents} rowKey={documents => documents.id} pagination={{ onChange: this.onPageChange, itemRender: itemRender, current: current_page, pageSize: per_page, total: total }} />

                                    }
                                </section>

                                {documents.length == 0 && <section className="page-info-box text-center">
                                    <h3 className="info-title">Sorry! No documents found!</h3>
                                </section>}

                                {/* Add New Document or Edit Document Modal */}
                                <Modal className="add-new-document-modal profileAP-modal"
                                    title={current_document ? "Edit Document" : "Add New Document"}
                                    centered
                                    visible={visible}
                                    onOk={this.handleOk}
                                    destroyOnClose={true}
                                    onCancel={this.handleCancel}
                                    footer={[
                                        <Button key="back" onClick={this.handleCancel} className="btn btn-light-secondary text-uppercase">Return</Button>,
                                        <Button onClick={this.handleOk} loading={loading} key="submit" className="btn btn-light-success text-uppercase">Submit</Button>,
                                    ]}
                                >
                                    <div className="add-new-document-modal-container">
                                        <Form onSubmit={this.handleOk}>
                                            <Row className="" gutter={24}>
                                                <Col className="gutter-row" xs={24} sm={24} md={24} lg={8} xl={8} xxl={8}>
                                                    <div className="form-group">
                                                        <label className="label">Document Name</label>
                                                    </div>
                                                </Col>
                                                <Col className="gutter-row" xs={24} sm={24} md={24} lg={16} xl={16} xxl={16}>
                                                    <div className="form-group">
                                                        <Form.Item>
                                                            {getFieldDecorator('title', {
                                                                initialValue: current_document && current_document.title || "",
                                                                rules: [
                                                                    {
                                                                        required: true,
                                                                        message: "Enter valid document name"
                                                                    }
                                                                ],
                                                                validateTrigger: 'onBlur'
                                                            })(
                                                                <Input className="input" maxLength="255" placeholder="Enter document name" onBlur={e => e.target.value = e.target.value.trim()} />
                                                            )}
                                                        </Form.Item>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row className="" gutter={24}>
                                                <Col className="gutter-row" xs={24} sm={24} md={24} lg={8} xl={8} xxl={8}>
                                                    <div className="form-group">
                                                        <label className="label">Select Folder</label>
                                                    </div>
                                                </Col>
                                                <Col className="gutter-row" xs={24} sm={24} md={24} lg={16} xl={16} xxl={16}>
                                                    <div className="form-group">
                                                        <Form.Item>
                                                            {getFieldDecorator('folder_id', {
                                                                initialValue: current_document && current_document.folder.id || 0,
                                                                // rules: [
                                                                //     {
                                                                //         required: true,
                                                                //         message: "Enter valid document name"
                                                                //     }
                                                                // ],
                                                                // validateTrigger: 'onBlur'
                                                            })(
                                                                < Select
                                                                    placeholder="Select Folder name"
                                                                >
                                                                    <option value={0}>Root Folder</option>
                                                                    {folders && folders.map(docFolder => (
                                                                        <Option value={docFolder.id}>{docFolder.name}</Option>
                                                                    ))}
                                                                </Select>
                                                            )}
                                                        </Form.Item>
                                                    </div>
                                                </Col>
                                            </Row>

                                            {current_document && <Row className="" gutter={24}>
                                                <Col className="gutter-row" xs={24} sm={24} md={24} lg={8} xl={8} xxl={8}>
                                                    <div className="form-group">
                                                        <label className="label">Document Type</label>
                                                    </div>
                                                </Col>
                                                <Col className="gutter-row" xs={24} sm={24} md={24} lg={16} xl={16} xxl={16}>
                                                    <div className="form-group">
                                                        <Form.Item>
                                                            {getFieldDecorator('type', {
                                                                initialValue: current_document && current_document.type || "",
                                                                rules: [
                                                                    {
                                                                        required: true,
                                                                        message: "Select valid document type"
                                                                    }
                                                                ],
                                                                validateTrigger: 'onBlur'
                                                            })(
                                                                <Radio.Group className="radio-btn" onChange={this.onTypeChange}>
                                                                    <div className="radio-icon file-icon">
                                                                        <Radio value="file"
                                                                            className="male-radio"
                                                                            disabled={current_document ? 'true' : 'false'}
                                                                        >
                                                                            File</Radio>
                                                                        <img className="icon file" src={file_icon} alt="file" />
                                                                    </div>
                                                                    <div className="radio-icon url-icon">
                                                                        <Radio value="link"
                                                                            className="female-radio"
                                                                            disabled={current_document ? 'true' : 'false'}
                                                                        >
                                                                            URL</Radio>
                                                                        <img className="icon url" src={url_icon} alt="url" />
                                                                    </div>
                                                                </Radio.Group>
                                                            )}
                                                        </Form.Item>
                                                    </div>
                                                </Col>
                                            </Row>}
                                            {document_type === "file" && document_type !== "link" && <Row className="" gutter={24}>
                                                <Col className="gutter-row" xs={24} sm={24} md={24} lg={8} xl={8} xxl={8}>
                                                    <div className="form-group">
                                                        {current_document && <label className="label"> Upload new file</label>}
                                                        {!current_document && <label className="label"> file</label>}
                                                    </div>
                                                </Col>
                                                <Col className="gutter-row" xs={24} sm={24} md={24} lg={16} xl={16} xxl={16}>
                                                    <div className="form-group">
                                                        <Form.Item>
                                                            {getFieldDecorator('file', {
                                                                rules: [
                                                                    !current_document && {
                                                                        //required: ,
                                                                        message: "Upload a document"
                                                                    }
                                                                ],
                                                                validateTrigger: 'onBlur'
                                                            })(
                                                                <Input className="input" type="file" accept=".pdf, application/pdf, .doc, application/msword, .docx, application/vnd.openxmlformats-officedocument.wordprocessingml.document, .jpeg, .jpg, image/jpeg, .png, image/png, .xls, application/vnd.ms-excel, .xlsx, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.google-apps.document, application/vnd.google-apps.spreadsheet"
                                                                    placeholder="Select document" onBlur={((e => this.setState({ uploaded_file: e.target.files[0] })))} />

                                                            )}
                                                        </Form.Item>

                                                    </div>
                                                </Col>
                                            </Row>}
                                            {document_type === "link" && document_type !== "file" && < Row className="" gutter={24}>
                                                <Col className="gutter-row" xs={24} sm={24} md={24} lg={8} xl={8} xxl={8}>
                                                    <div className="form-group">
                                                        <label className="label">Link</label>
                                                    </div>
                                                </Col>
                                                <Col className="gutter-row" xs={24} sm={24} md={24} lg={16} xl={16} xxl={16}>
                                                    <div className="form-group">
                                                        <Form.Item>
                                                            {getFieldDecorator('url', {
                                                                initialValue: current_document && current_document.type == "link" && current_document.url || "",
                                                                rules: [
                                                                    {
                                                                        type: 'url',
                                                                        message: "Enter a valid url"
                                                                    },
                                                                    {
                                                                        required: true,
                                                                        message: "Enter an url"
                                                                    }
                                                                ],
                                                                validateTrigger: 'onBlur'
                                                            })(
                                                                <Input className="input" type="url" maxLength="255" placeholder="Enter URL" onBlur={e => e.target.value = e.target.value.trim()} />
                                                            )}
                                                        </Form.Item>
                                                    </div>
                                                </Col>
                                            </Row>}
                                        </Form>
                                    </div>
                                </Modal>
                            </section>
                        )}
                    </div>
                </div>
            </Fragment >
        )
    }
}

export default Form.create({ name: 'document_folder' })(ForAdmin(withStore(DocumentAdminFolder)));