import { getTeamDirectoryListService, getPaginatedTeamDirectoryListService } from '../../services/TeamDirectoryService'

export const getTeamDirectoryList = (query) => {

    return getTeamDirectoryListService(query)
        .then(result => {
            return result.data;
        })
        .catch(error => {
            throw error;
        })
}


export const getPaginatedTeamDirectoryList = (page, query,body) => {

    return getPaginatedTeamDirectoryListService(page, query,body)
        .then(result => {
            return result.data;
        })
        .catch(error => {
            throw error;
        })
}