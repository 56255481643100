import _ from 'lodash';
import React, { Component, Fragment } from "react";
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { notification, Icon, Input, Button, Spin, Row, Col, Form, Card, Tooltip, Pagination, Upload, Modal } from "antd";

const { confirm } = Modal;

// Paginatio
function itemRender(current, type, originalElement) {
    if (type === 'prev') {
        return <a className="back-icon"> <Icon type="left" /> Back</a>;
    }
    if (type === 'next') {
        return <a className="next-icon">Next <Icon type="right" /></a>;
    }
    return originalElement;
}


class AddDevice extends Component {

    constructor(props) {
        super(props)
        this.state = {
            assets: null,
            loading: false,
            total: 0,
            per_page: 1,
            current_page: 1,
            fileList: []
        }
    }

    componentDidMount() {
        this.getAssetTypesData()
    }

    handleChange = ({ fileList }) => this.setState({ fileList });


    getAssetTypesData = (pageNumber = 1) => {
        this.setState({ loading: true })

        return this.props.getAssetTypes(pageNumber)
            .then((res) => {
                this.setState({
                    assets: res.data,
                    loading: false,
                    total: res.meta.pagination.total,
                    per_page: res.meta.pagination.per_page,
                    current_page: res.meta.pagination.current_page,
                })
                return res
            })
            .catch((error) => {
                this.setState({ loading: false })
                notification.error({
                    message: 'Oops! Something went wrong.'
                })
            })
    }

    handleDeleteAssetTypes = (id) => {
        const that = this;
        confirm({
            title: 'Do you Want to remove the Asset Type?',
            icon: <ExclamationCircleOutlined />,
            onOk() {
                that.props.deleteAssetTypes(id)
                    .then((result) => {
                        notification.success({
                            message: 'Asset Type deleted sucessfully'
                        })
                        that.getAssetTypesData()
                    })
                    .catch((error) => {
                        notification.error({
                            message: error.response.data.errors.message
                        })
                    })
            },
            onCancel() {
            },
        });

    }

    onPageChange = (pageNumber) => {
        this.setState({ loading: true });
        this.getAssetTypesData(pageNumber)
            .then(res => {
                this.setState({
                    assets: res.data,
                    total: res.meta.pagination.total,
                    per_page: res.meta.pagination.per_page,
                    current_page: res.meta.pagination.current_page,
                    loading: false
                });
            })
            .catch(error => {
                notification['error']({
                    message: "Oops! Something went wrong!"
                });
                this.setState({ loading: false });
            })
    }


    handleAddNewAssetType = (e) => {
        const { fileList } = this.state;
        e.preventDefault();
        this.props.form.validateFieldsAndScroll((err, formData) => {
            if (!err) {

                var form_data = new FormData();
                for (var i = 0; i < fileList.length; i++) {
                    form_data.append('images[0]', fileList[0].originFileObj)
                }
                form_data.append('name', formData.name)
                this.props.addAssetTypes(form_data)
                    .then(result => {
                        this.setState({ fileList: [] })
                        notification.success({
                            message: `Asset Type added successfully`
                        });
                        this.getAssetTypesData()
                    })
                    .catch(error => {
                        const error_code = _.get(error, 'response.status');
                        if (error_code && error_code == '422') {
                            notification['error']({
                                message: Object.values(_.get(error, 'response.data.errors', "Oops! Something went wrong1!"))
                            });
                        }
                        else {
                            notification['error']({
                                message: _.get(error, 'response.data.errors.message', "Oops! Something went wrong2!")
                            });
                        }
                        this.setState({ loading: false });
                    })
            }
        });
    }

    render() {

        const { getFieldDecorator } = this.props.form;
        let { loading, assets, total, per_page, current_page, fileList } = this.state

        return (
            <Fragment>
                {loading ? (<center><Spin /></center>) : (
                    <div className="">
                        <div className="addDevice-container addAction-container">
                            {/* Add new level container */}
                            <section className="add-level-box add-item-box card-shadow">
                                <div className="heading">
                                    <h3 className="title text-primary">Add new Asset type</h3>
                                </div>
                                <form>
                                    <Row className="" gutter={30}>
                                        <Col className="gutter-row" xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
                                            <div className="form-content form-group">
                                                <Form.Item label="Device Name" colon={false} >
                                                    {getFieldDecorator('name', {
                                                        rules: [
                                                            {
                                                                required: true,
                                                                message: "Enter a valid Asset Type"
                                                            }
                                                        ]
                                                    })(
                                                        <Input className="input" placeholder="Enter new Asset Type" onBlur={e => e.target.value = e.target.value.trim()} onPressEnter={this.handleAddNewAssetType} />
                                                    )}
                                                </Form.Item>
                                            </div>
                                        </Col>
                                        <Col className="gutter-row" xs={24} sm={24} md={24} lg={6} xl={6} xxl={6}>
                                            <div className="form-content form-group">
                                                <Form.Item label="Device Icon" colon={false} className="device-icon" >
                                                    <Upload
                                                        action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                                                        fileList={fileList}
                                                        onPreview={this.handlePreview}
                                                        onChange={this.handleChange}
                                                    >
                                                        <Button className="btn btn-light-warning">
                                                            <Icon type="upload" /> Upload
                                                        </Button>
                                                    </Upload>
                                                </Form.Item>
                                            </div>
                                        </Col>
                                        <Col className="gutter-row" xs={24} sm={24} md={24} lg={6} xl={6} xxl={6}>
                                            <div className="form-content form-group text-right sm-row-align">
                                                <Form.Item label="Add this Device" colon={false} className="add-level-label" >
                                                    <Button className="btn btn-light-primary text-uppercase" onClick={this.handleAddNewAssetType} >+ Add this Device</Button>
                                                </Form.Item>
                                            </div>
                                        </Col>
                                    </Row>
                                </form>
                            </section>

                            {/* card section */}
                            <section className="content-section card-section level-cards">
                                <Row className="" gutter={30}>
                                    {assets && assets.map((asset, index) => (
                                        <Col className="gutter-row" xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
                                            <Card className="card card-shadow level-card">
                                                <div className="icon-box">
                                                    <img className="icon icon-link" src={asset.url} alt="level image" />
                                                </div>
                                                <div className="title-box">
                                                    <h4 className="title">{asset.type}</h4>
                                                </div>
                                                <div className="action-box">
                                                    <Tooltip placement="bottom" title={'Delete'}><Button onClick={() => this.handleDeleteAssetTypes(asset.id)} className="btn btn-light-danger btn-icon"><Icon type="delete" theme="filled" /></Button></Tooltip>
                                                </div>
                                            </Card>
                                        </Col>
                                    ))}

                                </Row>

                                {total && per_page && total > per_page &&
                                    <div className="pagination-box text-right">
                                        <Pagination className="pagination" onChange={this.onPageChange} current={current_page} total={total} itemRender={itemRender} pageSize={per_page} />
                                    </div>
                                }
                            </section>
                        </div>
                    </div>
                )}
            </Fragment>

        )
    }
}

export default Form.create({ name: 'add_asset_types' })(AddDevice);