import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Layout, Menu, Icon, notification, Upload, message, Spin } from "antd";
import RequireAuth from "../../hocs/RequireAuth";
import { withStore } from "../../hocs/withStore";
import logo from "../../assets/images/full-logo-white.svg";
import logoIcon from "../../assets/images/favicon-white.svg";
import Hover from "../../assets/images/Hover.svg";
import _ from "lodash";
import imageCompression from "browser-image-compression";
import { axiosInstance as api } from "../../utilities/configureAxios";
import SideOut from "../components/SideOut";

// for sidebar icons
import TeamDirectory from "../../assets/images/icons/sibebar/team-directory.svg";
import CompanyDocument from "../../assets/images/icons/sibebar/company-documents.svg";
import Holiday from "../../assets/images/icons/sibebar/public-holidays.svg";
import Settings from "../../assets/images/icons/sibebar/settings.svg";
import ItAssets from "../../assets/images/icons/sibebar/it-assets.svg";
import intercom from "../../assets/images/icons/sibebar/intercom-directory.svg";
import leave from "../../assets/images/icons/sibebar/leave-management.svg";
import bell from "../../assets/images/icons/sprint/bell.svg";
import ProjectIcon from "../../assets/images/icons/sibebar/sprint-management.svg";
import ProjectM from "../../assets/images/icons/sibebar/project-m.svg";
import dailyStatus from "../../assets/images/icons/sibebar/daily-work-status.svg";
import bill from "../../assets/images/icons/sibebar/bill-management.svg";
import attendees from "../../assets/images/icons/sibebar/attendees.svg";
import home from "../../assets/images/icons/sibebar/home.svg";

const { Sider } = Layout;

class SiderPrimary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      wasActive: 0,
      hovering: null,
      user_roles_name: _.get(props, "store.data.user_roles_name", []),
      auth_user: _.get(props, "store.data.user", []),
      profile_pic: _.get(props, "store.data.profile_pic", null),
      imageLoading: false,
    };
  }

  // for submenu
  state = {
    collapsed: false,
  };

  onCollapse = (collapsed) => {
    this.setState({ collapsed });
  };

  getInitials = (user_name) => {
    var names = user_name.split(" "),
      initials = names[0].substring(0, 1).toUpperCase();

    if (names.length > 1) {
      initials += names[names.length - 1].substring(0, 1).toUpperCase();
    }
    return initials;
  };

  getInitialsLevel = (level) => {
    var names = level.split(" "),
      initials = names[0].substring(0, 1).toUpperCase();

    if (names.length > 1) {
      initials += "-" + names[names.length - 1].substring(0, 1).toUpperCase();
    }
    return initials;
  };

  render() {
    const isActive = parseInt(_.get(this.props, "store.data.activeTab", null));
    const {
      data: { user },
    } = this.props.store;

    const { hovering, user_roles_name, profile_pic, imageLoading, auth_user } =
      this.state;

    const admin = user_roles_name.indexOf("admin") != -1;
    const project_manager = user_roles_name.indexOf("project_manager") != -1;
    const hr = user_roles_name.indexOf("hr") != -1;
    const offc_admin = user_roles_name.indexOf("office_admin") != -1;
    const on_contract_employee =
      user_roles_name.indexOf("contract_employee") != -1;
    const employee = user_roles_name.indexOf("employee") != -1;

    const is_reporting_manager =
      user_roles_name.indexOf("reporting_manager") != -1;
    return (
      // <Sider className="web-sidebar"
      <Sider
        className="web-sidebar"
        collapsible
        collapsed={this.state.collapsed}
        onCollapse={this.onCollapse}
        breakpoint="lg"
        width="300"
        // collapsedWidth="0"
        collapsedWidth="80"
        // trigger={ <Icon type="menu" /> }
      >
        {/* dashboardIcon */}
        <div className="sticky-userInfo">
          <div className="sidebar-logo-box">
            <Link className="logo-link" to="/dashboard/home">
              <img className="company-logo" src={logo} alt="logo" />
              <img
                className="company-logo-icon"
                src={logoIcon}
                alt="logo icon"
              />
            </Link>
          </div>
          <div className="user-info-box">
            <div className="user-img animated bounceInDown">
              <Upload
                {...this.propse}
                showUploadList={false}
                beforeUpload={(file) => {
                  var options = {
                    maxSizeKB: 500,
                    maxWidthOrHeight: 200,
                    useWebWorker: true,
                  };
                  this.setState({ imageLoading: true });
                  const {
                    store: { setData },
                  } = this.props;
                  imageCompression(file, options)
                    .then((compressedFile) => {
                      const formData = new FormData();
                      formData.append("profile_image", compressedFile);

                      api
                        .post("profile-pic", formData)
                        .then((data) => {
                          api
                            .get("profile-pic")
                            .then((res) => {
                              const profile_pic = _.get(
                                res,
                                "data.data.profile_pic",
                                null
                              );
                              this.setState({
                                profile_pic,
                                imageLoading: false,
                              });
                              setData({ profile_pic });
                              notification["success"]({
                                message: "Profile pic changed successfully!",
                              });
                            })
                            .catch((error) => {
                              notification["error"]({
                                message: _.get(
                                  error,
                                  "response.data.errors.message",
                                  "Could not get your profile picture!"
                                ),
                              });
                              this.setState({
                                profile_pic,
                                imageLoading: false,
                              });
                            });
                        })
                        .catch((err) => {
                          message.error("file upload failed.");
                          this.setState({
                            imageLoading: false,
                          });
                        });
                      return false;
                    })
                    .catch(function (error) {
                      this.setState({ imageLoading: false });
                      return false;
                    });
                }}
              >
                <label className="file-input-label" htmlFor="file-input">
                  <div
                    className="image"
                    style={{ backgroundImage: `url(${profile_pic})` }}
                  ></div>
                  <div className="img-overlay">
                    <Icon className="edit-icon" type="edit" />
                  </div>
                  {imageLoading && (
                    <div className="spinner">
                      <Spin></Spin>
                    </div>
                  )}
                </label>
              </Upload>
            </div>
            <div className="user-info">
              <h2 className="user-name font-white animated fadeInLeftBig">
                {user.name}
              </h2>
              <h4 className="designation font-white animated fadeInLeftBig">
                {user.designation}
              </h4>
              <div className="font-white profile-btn text-uppercase bg-transparent level-badge animated fadeInLeftBig">
                {user.level}
              </div>
            </div>
            {/* <div className="user-collapsed-info">
                        <h2 className="user-name font-white animated fadeInLeftBig">{this.getInitials(user.name)}</h2>
                        <h4 className="font-white profile-btn text-uppercase bg-transparent level-badge animated fadeInLeftBig">{this.getInitialsLevel(user.level)}</h4>
                    </div> */}
          </div>
        </div>

        <Menu
          theme="dark"
          className="sidebar-menu"
          mode="inline"
          selectedKeys={window.location.pathname}
        >
          {
            <Menu.Item
              className="animated slideInUp"
              key="/dashboard/home"
              onClick={() => {
                this.props.store.setData({ activeTab: 25 });
              }}
            >
              <Link
                to="/dashboard/home"
                className="item-link"
                onMouseEnter={() => {
                  this.setState({ hovering: 25 });
                }}
                onMouseLeave={() => {
                  this.setState({ hovering: null });
                }}
              >
                {window.location.href.endsWith("/dashboard/home") && (
                  <span className="disable-collapse-hover">
                    <SideOut />
                  </span>
                )}
                {!window.location.href.endsWith("/dashboard/home") &&
                  hovering == 25 && (
                    <img className="hover-image" src={Hover} alt="hover" />
                  )}
                <span className="item-icon-name">
                  <div className="sidebar-icon">
                    <img className="icon-img" src={home} alt="home icon" />
                    <span className="nav-text">Dashboard</span>
                  </div>
                </span>
              </Link>
            </Menu.Item>
          }

          {(admin || hr) && (
            <Menu.Item
              className="animated slideInUp"
              key="/dashboard/employee"
              onClick={() => {
                this.props.store.setData({ activeTab: 0 });
              }}
            >
              <Link
                to="/dashboard/employee"
                className="item-link"
                onMouseEnter={() => {
                  this.setState({ hovering: 0 });
                }}
                onMouseLeave={() => {
                  this.setState({ hovering: null });
                }}
              >
                {window.location.href.endsWith("/dashboard/employee") && (
                  <span className="disable-collapse-hover">
                    <SideOut />
                  </span>
                )}
                {!window.location.href.endsWith("/dashboard/employee") &&
                  hovering == 0 && (
                    <img className="hover-image" src={Hover} alt="hover" />
                  )}
                <span className="item-icon-name">
                  <div className="sidebar-icon">
                    <img
                      className="icon-img"
                      src={TeamDirectory}
                      alt="team directory icon"
                    />
                    <span className="nav-text">Team Directory</span>
                  </div>
                </span>
              </Link>
            </Menu.Item>
          )}

          {/* { (
            <Menu.Item
              className="animated slideInUp"
              key="/dashboard/home"
              onClick={() => {
                this.props.store.setData({ activeTab: 1 });
              }}
            >
              <Link
                to="/dashboard/home"
                className="item-link"
                onMouseEnter={() => {
                  this.setState({ hovering: 1 });
                }}
                onMouseLeave={() => {
                  this.setState({ hovering: null });
                }}
              >
                {window.location.href.endsWith("/dashboard/home") && (
                  <span className="disable-collapse-hover">
                    <SideOut />
                  </span>
                )}
                {!window.location.href.endsWith("/dashboard/home") && hovering == 1 && (
                  <img className="hover-image" src={Hover} alt="hover" />
                )}
                <span className="item-icon-name">
                  <div className="sidebar-icon">
                    <img className="icon-img" src={home} alt="home icon" />
                    <span className="nav-text">Dashboard</span>
                  </div>
                </span>
              </Link>
            </Menu.Item>
          )} */}

          {/* Employee team directory */}
          {!admin && !hr && !on_contract_employee && (
            <Menu.Item
              className="animated slideInUp"
              key="/dashboard/team-directory"
              onClick={() => {
                this.props.store.setData({ activeTab: 1 });
              }}
            >
              <Link
                to="/dashboard/team-directory"
                className="item-link"
                onMouseEnter={() => {
                  this.setState({ hovering: 1 });
                }}
                onMouseLeave={() => {
                  this.setState({ hovering: null });
                }}
              >
                {window.location.href.endsWith("/dashboard/team-directory") && (
                  <span className="disable-collapse-hover">
                    <SideOut />
                  </span>
                )}
                {!window.location.href.endsWith("/dashboard/team-directory") &&
                  hovering == 1 && (
                    <img className="hover-image" src={Hover} alt="hover" />
                  )}
                <span className="item-icon-name">
                  <div className="sidebar-icon">
                    <img
                      className="icon-img"
                      src={TeamDirectory}
                      alt="team directory icon"
                    />
                    <span className="nav-text">Team Directory</span>
                  </div>
                </span>
              </Link>
            </Menu.Item>
          )}

          {!admin && !on_contract_employee && (
            <Menu.Item
              className="animated slideInUp"
              key="/dashboard/document"
              onClick={() => {
                this.props.store.setData({ activeTab: 2 });
              }}
            >
              <Link
                to="/dashboard/document"
                className="item-link"
                onMouseEnter={() => {
                  this.setState({ hovering: 2 });
                }}
                onMouseLeave={() => {
                  this.setState({ hovering: null });
                }}
              >
                {(window.location.pathname.endsWith("/dashboard/document") ||
                  window.location.pathname.endsWith(
                    "/dashboard/document-folder-list"
                  )) && (
                  <span className="disable-collapse-hover">
                    <SideOut />
                  </span>
                )}
                {!(
                  window.location.pathname.endsWith("/dashboard/document") ||
                  window.location.pathname.endsWith(
                    "/dashboard/document-folder-list"
                  )
                ) &&
                  hovering == 2 && (
                    <img className="hover-image" src={Hover} alt="hover" />
                  )}

                <span className="item-icon-name">
                  <div className="sidebar-icon">
                    <img
                      className="icon-img"
                      src={CompanyDocument}
                      alt="document icon"
                    />
                    <span className="nav-text">Documents</span>
                  </div>
                </span>
              </Link>
            </Menu.Item>
          )}

          {/* {!admin && <Menu.Item className="animated slideInUp" key="/dashboard/salary" onClick={() => { this.props.store.setData({ activeTab: 3 }); }}>
                        <Link to="/dashboard/salary" className="item-link" onMouseEnter={() => { this.setState({ hovering: 3 }); }} onMouseLeave={() => { this.setState({ hovering: null }); }}>
                            {window.location.href.endsWith('/dashboard/salary') && <span className="disable-collapse-hover"><SideOut /></span>}
                            {!window.location.href.endsWith('/dashboard/salary') && hovering == 3 && <img className="hover-image" src={Hover} alt="hover" />}
                            <span className="item-icon-name">
                                <div className="sidebar-icon">
                                    <img className="icon-img" src={SalarySlip} alt="salary slip icon" />
                                    <span className="nav-text">Salary Slips</span>
                                </div>
                            </span>
                        </Link>
                    </Menu.Item>} */}

          {!admin && !on_contract_employee && (
            <Menu.Item
              className="animated slideInUp"
              key="/dashboard/holiday"
              onClick={() => {
                this.props.store.setData({ activeTab: 4 });
              }}
            >
              <Link
                to="/dashboard/holiday"
                className="item-link"
                onMouseEnter={() => {
                  this.setState({ hovering: 4 });
                }}
                onMouseLeave={() => {
                  this.setState({ hovering: null });
                }}
              >
                {window.location.href.endsWith("/dashboard/holiday") && (
                  <span className="disable-collapse-hover">
                    <SideOut />
                  </span>
                )}
                {!window.location.href.endsWith("/dashboard/holiday") &&
                  hovering == 4 && (
                    <img className="hover-image" src={Hover} alt="hover" />
                  )}
                <span className="item-icon-name">
                  <div className="sidebar-icon">
                    <img
                      className="icon-img"
                      src={Holiday}
                      alt="holiday icon"
                    />
                    <span className="nav-text">Public Holidays</span>
                  </div>
                </span>
              </Link>
            </Menu.Item>
          )}

          {/* {admin && <Menu.Item className="animated slideInUp" key="/dashboard/admin/salary" onClick={() => { this.props.store.setData({ activeTab: 5 }); }}>
                        <Link to="/dashboard/admin/salary" className="item-link" onMouseEnter={() => { this.setState({ hovering: 5 }); }} onMouseLeave={() => { this.setState({ hovering: null }); }}>
                            {window.location.href.endsWith('/dashboard/admin/salary') && <span className="disable-collapse-hover"><SideOut /></span>}
                            {!window.location.href.endsWith('/dashboard/admin/salary') && hovering == 5 && <img className="hover-image" src={Hover} alt="hover" />}
                            <span className="item-icon-name">
                                <div className="sidebar-icon">
                                    <img className="icon-img" src={SalarySlip} alt="salary slip icon" />
                                    <span className="nav-text">Salary Slips</span>
                                </div>
                            </span>
                        </Link>
                    </Menu.Item>} */}

          {admin && (
            <Menu.Item
              className="animated slideInUp"
              key="/dashboard/admin/document"
              onClick={() => {
                this.props.store.setData({ activeTab: 6 });
              }}
            >
              <Link
                to="/dashboard/admin/document"
                className="item-link"
                onMouseEnter={() => {
                  this.setState({ hovering: 6 });
                }}
                onMouseLeave={() => {
                  this.setState({ hovering: null });
                }}
              >
                {(window.location.pathname.endsWith(
                  "/dashboard/admin/document"
                ) ||
                  window.location.pathname.endsWith(
                    "/dashboard/admin/document-folder"
                  )) && (
                  <span className="disable-collapse-hover">
                    <SideOut />
                  </span>
                )}
                {!(
                  window.location.pathname.endsWith(
                    "/dashboard/admin/document"
                  ) ||
                  window.location.pathname.endsWith(
                    "/dashboard/admin/document-folder"
                  )
                ) &&
                  hovering == 6 && (
                    <img className="hover-image" src={Hover} alt="hover" />
                  )}
                <span className="item-icon-name">
                  <div className="sidebar-icon">
                    <img
                      className="icon-img"
                      src={CompanyDocument}
                      alt="document icon"
                    />
                    <span className="nav-text">Documents</span>
                  </div>
                </span>
              </Link>
            </Menu.Item>
          )}

          {admin && !on_contract_employee && (
            <Menu.Item
              className="animated slideInUp"
              key="/dashboard/admin/holiday"
              onClick={() => {
                this.props.store.setData({ activeTab: 7 });
              }}
            >
              <Link
                to="/dashboard/admin/holiday"
                className="item-link"
                onMouseEnter={() => {
                  this.setState({ hovering: 7 });
                }}
                onMouseLeave={() => {
                  this.setState({ hovering: null });
                }}
              >
                {window.location.href.endsWith("/dashboard/admin/holiday") && (
                  <span className="disable-collapse-hover">
                    <SideOut />
                  </span>
                )}
                {!window.location.href.endsWith("/dashboard/admin/holiday") &&
                  hovering == 7 && (
                    <img className="hover-image" src={Hover} alt="hover" />
                  )}
                <span className="item-icon-name">
                  <div className="sidebar-icon">
                    <img
                      className="icon-img"
                      src={Holiday}
                      alt="holiday icon"
                    />
                    <span className="nav-text">Public Holidays</span>
                  </div>
                </span>
              </Link>
            </Menu.Item>
          )}

          {/* {admin && <Menu.Item key="/dashboard/admin/manage" onClick={() => { this.props.store.setData({ activeTab: 7 }); }}>
                        <Link to="/dashboard/admin/manage" className="item-link" onMouseEnter={() => { this.setState({ hovering: 7 }); }} onMouseLeave={() => { this.setState({ hovering: null }); }}>
                            {window.location.href.endsWith('/dashboard/admin/manage') && <span className="disable-collapse-hover"><SideOut /></span>}
                            {!window.location.href.endsWith('/dashboard/admin/manage') && hovering == 7 && <img className="hover-image" src={Hover} alt="hover" />}
                            <span className="item-icon-name">
                                <div className="sidebar-icon">
                                    <img className="icon-img" src={TeamDirectory} alt="team directory icon" />
                                    <span className="nav-text">Miscellaneous</span>
                                </div>
                            </span>
                        </Link>
                    </Menu.Item>} */}

          {admin && (
            <Menu.Item
              className="animated slideInUp"
              key="/dashboard/admin/manage-intercom"
              onClick={() => {
                this.props.store.setData({ activeTab: 8 });
              }}
            >
              <Link
                to="/dashboard/admin/manage-intercom"
                className="item-link"
                onMouseEnter={() => {
                  this.setState({ hovering: 8 });
                }}
                onMouseLeave={() => {
                  this.setState({ hovering: null });
                }}
              >
                {window.location.href.endsWith(
                  "/dashboard/admin/manage-intercom"
                ) && (
                  <span className="disable-collapse-hover">
                    <SideOut />
                  </span>
                )}
                {!window.location.href.endsWith(
                  "/dashboard/admin/manage-intercom"
                ) &&
                  hovering == 8 && (
                    <img className="hover-image" src={Hover} alt="hover" />
                  )}
                <span className="item-icon-name">
                  <div className="sidebar-icon">
                    <img
                      className="icon-img"
                      src={intercom}
                      alt="holiday icon"
                    />
                    <span className="nav-text">Intercom Directory</span>
                  </div>
                </span>
              </Link>
            </Menu.Item>
          )}

          {!admin && !on_contract_employee && (
            <Menu.Item
              className="animated slideInUp"
              key="/dashboard/intercom"
              onClick={() => {
                this.props.store.setData({ activeTab: 9 });
              }}
            >
              <Link
                to="/dashboard/intercom"
                className="item-link"
                onMouseEnter={() => {
                  this.setState({ hovering: 9 });
                }}
                onMouseLeave={() => {
                  this.setState({ hovering: null });
                }}
              >
                {window.location.href.endsWith("/dashboard/intercom") && (
                  <span className="disable-collapse-hover">
                    <SideOut />
                  </span>
                )}
                {!window.location.href.endsWith("/dashboard/intercom") &&
                  hovering == 9 && (
                    <img className="hover-image" src={Hover} alt="hover" />
                  )}
                <span className="item-icon-name">
                  <div className="sidebar-icon">
                    <img
                      className="icon-img"
                      src={intercom}
                      alt="holiday icon"
                    />
                    <span className="nav-text">Intercom Directory</span>
                  </div>
                </span>
              </Link>
            </Menu.Item>
          )}

          {(admin || offc_admin) && (
            <Menu.Item
              className="animated slideInUp"
              key="/dashboard/admin/manage-it-assets"
              onClick={() => {
                this.props.store.setData({ activeTab: 10 });
              }}
            >
              <Link
                to="/dashboard/admin/manage-it-assets"
                className="item-link"
                onMouseEnter={() => {
                  this.setState({ hovering: 10 });
                }}
                onMouseLeave={() => {
                  this.setState({ hovering: null });
                }}
              >
                {window.location.href.endsWith(
                  "/dashboard/admin/manage-it-assets"
                ) && (
                  <span className="disable-collapse-hover">
                    <SideOut />
                  </span>
                )}
                {!window.location.href.endsWith(
                  "/dashboard/admin/manage-it-assets"
                ) &&
                  hovering == 10 && (
                    <img className="hover-image" src={Hover} alt="hover" />
                  )}
                <span className="item-icon-name">
                  <div className="sidebar-icon">
                    <img
                      className="icon-img"
                      src={ItAssets}
                      alt="holiday icon"
                    />
                    <span className="nav-text">Assets</span>
                  </div>
                </span>
              </Link>
            </Menu.Item>
          )}

          {!admin && !offc_admin && !on_contract_employee && (
            <Menu.Item
              className="animated slideInUp"
              key="/dashboard/it-assets-management"
              onClick={() => {
                this.props.store.setData({ activeTab: 11 });
              }}
            >
              <Link
                to="/dashboard/it-assets-management"
                className="item-link"
                onMouseEnter={() => {
                  this.setState({ hovering: 11 });
                }}
                onMouseLeave={() => {
                  this.setState({ hovering: null });
                }}
              >
                {window.location.href.endsWith(
                  "/dashboard/it-assets-management"
                ) && (
                  <span className="disable-collapse-hover">
                    <SideOut />
                  </span>
                )}
                {!window.location.href.endsWith(
                  "/dashboard/it-assets-management"
                ) &&
                  hovering == 11 && (
                    <img className="hover-image" src={Hover} alt="hover" />
                  )}
                <span className="item-icon-name">
                  <div className="sidebar-icon">
                    <img
                      className="icon-img"
                      src={ItAssets}
                      alt="holiday icon"
                    />
                    <span className="nav-text">Assets</span>
                  </div>
                </span>
              </Link>
            </Menu.Item>
          )}

          {!admin && (
            <Menu.Item
              className="animated slideInUp"
              key="/dashboard/work-reports"
              onClick={() => {
                this.props.store.setData({ activeTab: 12 });
              }}
            >
              <Link
                to="/dashboard/work-reports"
                className="item-link"
                onMouseEnter={() => {
                  this.setState({ hovering: 12 });
                }}
                onMouseLeave={() => {
                  this.setState({ hovering: null });
                }}
              >
                {window.location.href.endsWith("/dashboard/work-reports") && (
                  <span className="disable-collapse-hover">
                    <SideOut />
                  </span>
                )}
                {!window.location.href.endsWith("/dashboard/work-reports") &&
                  hovering == 12 && (
                    <img className="hover-image" src={Hover} alt="hover" />
                  )}
                <span className="item-icon-name">
                  <div className="sidebar-icon">
                    <img
                      className="icon-img"
                      src={dailyStatus}
                      alt="daily status icon"
                    />
                    <span className="nav-text">Work Logs</span>
                  </div>
                </span>
              </Link>
            </Menu.Item>
          )}

          {(admin || hr || project_manager || is_reporting_manager) && (
            <Menu.Item
              className="animated slideInUp"
              key="/dashboard/admin/work-reports"
              onClick={() => {
                this.props.store.setData({ activeTab: 13 });
              }}
            >
              <Link
                to="/dashboard/admin/work-reports"
                className="item-link"
                onMouseEnter={() => {
                  this.setState({ hovering: 13 });
                }}
                onMouseLeave={() => {
                  this.setState({ hovering: null });
                }}
              >
                {window.location.href.endsWith(
                  "/dashboard/admin/work-reports"
                ) && (
                  <span className="disable-collapse-hover">
                    <SideOut />
                  </span>
                )}
                {!window.location.href.endsWith(
                  "/dashboard/admin/work-reports"
                ) &&
                  hovering == 13 && (
                    <img className="hover-image" src={Hover} alt="hover" />
                  )}
                <span className="item-icon-name">
                  <div className="sidebar-icon">
                    <img
                      className="icon-img"
                      src={dailyStatus}
                      alt="daily status icon"
                    />
                    <span className="nav-text">Manage Work Logs</span>
                  </div>
                </span>
              </Link>
            </Menu.Item>
          )}

          {(admin || hr) && (
            <Menu.Item
              className="animated slideInUp"
              key="/dashboard/admin/attendance"
              onClick={() => {
                this.props.store.setData({ activeTab: 24 });
              }}
            >
              <Link
                to="/dashboard/admin/attendance"
                className="item-link"
                onMouseEnter={() => {
                  this.setState({ hovering: 24 });
                }}
                onMouseLeave={() => {
                  this.setState({ hovering: null });
                }}
              >
                {window.location.href.endsWith(
                  "/dashboard/admin/attendance"
                ) && (
                  <span className="disable-collapse-hover">
                    <SideOut />
                  </span>
                )}
                {!window.location.href.endsWith(
                  "/dashboard/admin/attendance"
                ) &&
                  hovering == 24 && (
                    <img className="hover-image" src={Hover} alt="hover" />
                  )}
                <span className="item-icon-name">
                  <div className="sidebar-icon">
                    <img
                      className="icon-img"
                      src={attendees}
                      alt="attendance  icon"
                    />
                    <span className="nav-text">Manage Attendance</span>
                  </div>
                </span>
              </Link>
            </Menu.Item>
          )}
          {!admin && !on_contract_employee && !hr && (
            <Menu.Item
              className="animated slideInUp"
              key="/dashboard/attendance"
              onClick={() => {
                this.props.store.setData({ activeTab: 23 });
              }}
            >
              <Link
                to="/dashboard/attendance"
                className="item-link"
                onMouseEnter={() => {
                  this.setState({ hovering: 23 });
                }}
                onMouseLeave={() => {
                  this.setState({ hovering: null });
                }}
              >
                {window.location.href.endsWith("/dashboard/attendance") && (
                  <span className="disable-collapse-hover">
                    <SideOut />
                  </span>
                )}
                {!window.location.href.endsWith("/dashboard/attendance") &&
                  hovering == 23 && (
                    <img className="hover-image" src={Hover} alt="hover" />
                  )}
                <span className="item-icon-name">
                  <div className="sidebar-icon">
                    <img
                      className="icon-img"
                      src={attendees}
                      alt="attendance icon"
                    />
                    <span className="nav-text">Attendance</span>
                  </div>
                </span>
              </Link>
            </Menu.Item>
          )}

          {admin && (
            <Menu.Item
              className="animated slideInUp"
              key="/dashboard/admin/settings"
              onClick={() => {
                this.props.store.setData({ activeTab: 14 });
              }}
            >
              <Link
                to="/dashboard/admin/settings"
                className="item-link"
                onMouseEnter={() => {
                  this.setState({ hovering: 14 });
                }}
                onMouseLeave={() => {
                  this.setState({ hovering: null });
                }}
              >
                {window.location.href.endsWith("/dashboard/admin/settings") && (
                  <span className="disable-collapse-hover">
                    <SideOut />
                  </span>
                )}
                {!window.location.href.endsWith("/dashboard/admin/settings") &&
                  hovering == 14 && (
                    <img className="hover-image" src={Hover} alt="hover" />
                  )}
                <span className="item-icon-name">
                  <div className="sidebar-icon">
                    <img
                      className="icon-img"
                      src={Settings}
                      alt="Settings icon"
                    />
                    <span className="nav-text">Settings</span>
                  </div>
                </span>
              </Link>
            </Menu.Item>
          )}

          {(admin || hr || is_reporting_manager) && (
            <Menu.Item
              className="animated slideInUp"
              key="/dashboard/admin/leave-management"
              onClick={() => {
                this.props.store.setData({ activeTab: 17 });
              }}
            >
              <Link
                to="/dashboard/admin/leave-management"
                className="item-link"
                onMouseEnter={() => {
                  this.setState({ hovering: 17 });
                }}
                onMouseLeave={() => {
                  this.setState({ hovering: null });
                }}
              >
                {window.location.href.endsWith(
                  "/dashboard/admin/leave-management"
                ) && (
                  <span className="disable-collapse-hover">
                    <SideOut />
                  </span>
                )}
                {!window.location.href.endsWith(
                  "/dashboard/admin/leave-management"
                ) &&
                  hovering == 17 && (
                    <img className="hover-image" src={Hover} alt="hover" />
                  )}
                <span className="item-icon-name">
                  <div className="sidebar-icon">
                    <img
                      className="icon-img"
                      src={leave}
                      alt="leave management icon"
                    />
                    <span className="nav-text">Manage Out of Office</span>
                  </div>
                </span>
              </Link>
            </Menu.Item>
          )}

          {!admin && !hr && !on_contract_employee && (
            <Menu.Item
              className="animated slideInUp"
              key="/dashboard/leave-management"
              onClick={() => {
                this.props.store.setData({ activeTab: 20 });
              }}
            >
              <Link
                to="/dashboard/leave-management"
                className="item-link"
                onMouseEnter={() => {
                  this.setState({ hovering: 20 });
                }}
                onMouseLeave={() => {
                  this.setState({ hovering: null });
                }}
              >
                {window.location.href.endsWith(
                  "/dashboard/leave-management"
                ) && (
                  <span className="disable-collapse-hover">
                    <SideOut />
                  </span>
                )}
                {!window.location.href.endsWith(
                  "/dashboard/leave-management"
                ) &&
                  hovering === 20 && (
                    <img className="hover-image" src={Hover} alt="hover" />
                  )}
                <span className="item-icon-name">
                  <div className="sidebar-icon">
                    <img
                      className="icon-img"
                      src={leave}
                      alt="leave management icon"
                    />
                    <span className="nav-text">Out of Office</span>
                  </div>
                </span>
              </Link>
            </Menu.Item>
          )}

          {(admin || hr) && (
            <Menu.Item
              className="animated slideInUp"
              key="/dashboard/admin/announcements"
              onClick={() => {
                this.props.store.setData({ activeTab: 18 });
              }}
            >
              <Link
                to="/dashboard/admin/announcements"
                className="item-link"
                onMouseEnter={() => {
                  this.setState({ hovering: 18 });
                }}
                onMouseLeave={() => {
                  this.setState({ hovering: null });
                }}
              >
                {window.location.href.endsWith(
                  "/dashboard/admin/announcements"
                ) && (
                  <span className="disable-collapse-hover">
                    <SideOut />
                  </span>
                )}
                {!window.location.href.endsWith(
                  "/dashboard/admin/announcements"
                ) &&
                  hovering == 18 && (
                    <img className="hover-image" src={Hover} alt="hover" />
                  )}
                <span className="item-icon-name">
                  <div className="sidebar-icon">
                    <img
                      className="icon-img"
                      src={Holiday}
                      alt="leave management icon"
                    />
                    {/* <Icon style={{fontSize:'20px',marginTop:'5px'}} type="notification" /> */}
                    <span className="nav-text">Manage Announcements</span>
                  </div>
                </span>
              </Link>
            </Menu.Item>
          )}
          {/* 
          {(admin || project_manager) && (
            <Menu.Item
              className="animated slideInUp"
              key="/dashboard/admin/project-dashboard"
              onClick={() => {
                this.props.store.setData({ activeTab: 28 });
              }}
            >
              <Link
                to="/dashboard/admin/project-dashboard"
                className="item-link"
                onMouseEnter={() => {
                  this.setState({ hovering: 28 });
                }}
                onMouseLeave={() => {
                  this.setState({ hovering: null });
                }}
              >
                {window.location.href.endsWith(
                  "/dashboard/admin/project-dashboard"
                ) && (
                  <span className="disable-collapse-hover">
                    <SideOut />
                  </span>
                )}
                {!window.location.href.endsWith(
                  "/dashboard/admin/project-dashboard"
                ) &&
                  hovering == 28 && (
                    <img className="hover-image" src={Hover} alt="hover" />
                  )}
                <span className="item-icon-name">
                  <div className="sidebar-icon">
                    <img
                      className="icon-img"
                      src={ProjectM}
                      alt="leave management icon"
                    />
                    <span className="nav-text">Project Dashboard</span>
                  </div>
                </span>
              </Link>
            </Menu.Item>
          )} */}
          {!admin && !hr && !on_contract_employee && (
            <Menu.Item
              className="animated slideInUp"
              key="/dashboard/announcements"
              onClick={() => {
                this.props.store.setData({ activeTab: 19 });
              }}
            >
              <Link
                to="/dashboard/announcements"
                className="item-link"
                onMouseEnter={() => {
                  this.setState({ hovering: 19 });
                }}
                onMouseLeave={() => {
                  this.setState({ hovering: null });
                }}
              >
                {window.location.href.endsWith("/dashboard/announcements") && (
                  <span className="disable-collapse-hover">
                    <SideOut />
                  </span>
                )}
                {!window.location.href.endsWith("/dashboard/announcements") &&
                  hovering == 19 && (
                    <img className="hover-image" src={Hover} alt="hover" />
                  )}
                <span className="item-icon-name">
                  <div className="sidebar-icon">
                    <img
                      className="icon-img"
                      src={Holiday}
                      alt="leave management icon"
                    />
                    <span className="nav-text">Announcements</span>
                  </div>
                </span>
              </Link>
            </Menu.Item>
          )}

          {(admin || project_manager) && (
            <Menu.Item
              className="animated slideInUp"
              key="/dashboard/admin/project-management"
              onClick={() => {
                this.props.store.setData({ activeTab: 21 });
              }}
            >
              <Link
                to="/dashboard/admin/project-management"
                className="item-link"
                onMouseEnter={() => {
                  this.setState({ hovering: 21 });
                }}
                onMouseLeave={() => {
                  this.setState({ hovering: null });
                }}
              >
                {window.location.href.endsWith(
                  "/dashboard/admin/project-management"
                ) && (
                  <span className="disable-collapse-hover">
                    <SideOut />
                  </span>
                )}
                {!window.location.href.endsWith(
                  "/dashboard/admin/project-management"
                ) &&
                  hovering == 21 && (
                    <img className="hover-image" src={Hover} alt="hover" />
                  )}
                <span className="item-icon-name">
                  <div className="sidebar-icon">
                    <img
                      className="icon-img"
                      src={ProjectIcon}
                      alt="project management icon"
                    />
                    <span className="nav-text">Manage Projects</span>
                  </div>
                </span>
              </Link>
            </Menu.Item>
          )}
          {employee && !project_manager && !admin && (
            <Menu.Item
              className="animated slideInUp"
              key="/dashboard/projects"
              onClick={() => {
                this.props.store.setData({ activeTab: 22 });
              }}
            >
              <Link
                to="/dashboard/projects"
                className="item-link"
                onMouseEnter={() => {
                  this.setState({ hovering: 22 });
                }}
                onMouseLeave={() => {
                  this.setState({ hovering: null });
                }}
              >
                {window.location.href.endsWith("/dashboard/projects") && (
                  <span className="disable-collapse-hover">
                    <SideOut />
                  </span>
                )}
                {!window.location.href.endsWith("/dashboard/projects") &&
                  hovering == 22 && (
                    <img className="hover-image" src={Hover} alt="hover" />
                  )}
                <span className="item-icon-name">
                  <div className="sidebar-icon">
                    <img
                      className="icon-img"
                      src={ProjectIcon}
                      alt="project management icon"
                    />
                    <span className="nav-text">My Projects</span>
                  </div>
                </span>
              </Link>
            </Menu.Item>
          )}

          {!admin && !offc_admin && (
            <Menu.Item
              className="animated slideInUp"
              key="/dashboard/my-bills"
              onClick={() => {
                this.props.store.setData({ activeTab: 26 });
              }}
            >
              <Link
                to="/dashboard/my-bills"
                className="item-link"
                onMouseEnter={() => {
                  this.setState({ hovering: 26 });
                }}
                onMouseLeave={() => {
                  this.setState({ hovering: null });
                }}
              >
                {window.location.href.endsWith("/dashboard/my-bills") && (
                  <span className="disable-collapse-hover">
                    <SideOut />
                  </span>
                )}
                {!window.location.href.endsWith("/dashboard/my-bills") &&
                  hovering == 26 && (
                    <img className="hover-image" src={Hover} alt="hover" />
                  )}
                <span className="item-icon-name">
                  <div className="sidebar-icon">
                    <img
                      className="icon-img"
                      src={bill}
                      alt="bill management icon"
                    />
                    <span className="nav-text">My Bills</span>
                  </div>
                </span>
              </Link>
            </Menu.Item>
          )}

          {(admin || auth_user.is_reporting_manager) && (
            <Menu.Item
              className="animated slideInUp"
              key="/dashboard/admin/bill-management"
              onClick={() => {
                this.props.store.setData({ activeTab: 27 });
              }}
            >
              <Link
                to="/dashboard/admin/bill-management"
                className="item-link"
                onMouseEnter={() => {
                  this.setState({ hovering: 27 });
                }}
                onMouseLeave={() => {
                  this.setState({ hovering: null });
                }}
              >
                {window.location.href.endsWith(
                  "/dashboard/admin/bill-management"
                ) && (
                  <span className="disable-collapse-hover">
                    <SideOut />
                  </span>
                )}
                {!window.location.href.endsWith(
                  "/dashboard/admin/bill-management"
                ) &&
                  hovering == 27 && (
                    <img className="hover-image" src={Hover} alt="hover" />
                  )}
                <span className="item-icon-name">
                  <div className="sidebar-icon">
                    <img
                      className="icon-img"
                      src={bill}
                      alt="bill management icon"
                    />
                    <span className="nav-text">Manage Bills</span>
                  </div>
                </span>
              </Link>
            </Menu.Item>
          )}
        </Menu>
      </Sider>
    );
  }
}

export default withStore(RequireAuth(SiderPrimary));
