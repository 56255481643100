import React from "react";
import {
  Row,
  Col,
  Input,
  Form,
  Upload,
  Modal,
  Radio,
  Button,
  Select,
  DatePicker,
  notification,
  Spin,
} from "antd";

import moment from "moment";
import {
  getDateCalculationApi,
  getActiveOtherEmployeeLeaves,
} from "../../../../../leave/components/DataManager";
import { applyForUpdatedAdminLeave } from "../../DataManager";
import _ from "lodash";

const CreateEmployeeLeave = (props) => {
  const { closeModal, setDataUpdate, componentUpdate } = props;
  const leaveData = props.loadLeaveData;
  const refreshPage = props.refreshPage;
  const [sessionVisible, setSessionVisible] = React.useState(
    leaveData.from_date === leaveData.to_date
  );
  const [daySyntex, setDaySyntex] = React.useState("Days");
  const [daySyntexBox, setDaySyntexBox] = React.useState("Days");
  const [imagePreviewVisible, setImagePreviewVisible] = React.useState(false);
  const [imagePreview, setImagePreview] = React.useState("");
  const [notifyUsersValue, setNotifyUsersValue] = React.useState([]);

  const [formData, setFormData] = React.useState({
    userNamList: [],
    fromDate: leaveData.from_date,
    toDate: leaveData.to_date,
    fromSession: leaveData.from_session,
    toSession: leaveData.to_session,
    employeeLeaveId: leaveData.employee_leave_id,
    notes: leaveData.notes,
    notifyUsers: leaveData.notify_users,
    images: [],
    previewVisible: false,
    previewImage: "",
    employeeLeaveName: leaveData.name,
  });

  const { setFields } = props.form;
  const [dateDifference, setDateDifference] = React.useState(0);
  const [calculatedLeaves, setCalculatedLeaves] = React.useState(
    leaveData.counts
  );
  const [visible, setVisible] = React.useState(false);
  const [btnVisible, setBtnVisible] = React.useState(false);
  const [leaveTypes, setLeaveTypes] = React.useState([]);
  const [userId, setUserId] = React.useState(leaveData.user.uuid);
  const [calculatedLeavesMessage, setCalculatedLeavesMessage] =
    React.useState("");

  // FETCHING all leaves
  const loadLeavesOfEmployee = () => {
    getActiveOtherEmployeeLeaves(leaveData.user.uuid)
      .then((res) => {
        setLeaveTypes(res.data);
      })
      .catch((error) => {
        notification.error(error.response.data.errors);
      });
  };
  React.useEffect(() => {
    loadLeavesOfEmployee();
    notifyUsersInitialValue()
  }, []);

  React.useEffect(() => {
    if (leaveData.from_date !== leaveData.to_date) {
      getDateCalculation(leaveData.from_date, leaveData.to_date);
    }
    const time1 = new Date(leaveData.from_date).getTime();
    const time2 = new Date(leaveData.to_date).getTime();
    const Difference_In_Time = time2 - time1;
    const Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
    setDateDifference(Difference_In_Days + 1);

    Difference_In_Days === 0 ? setDaySyntex("Day") : setDaySyntex("Days");
  }, []);

  // HANDLE DATE CHANGE || HANDLING TOTAL DAYS
  const handleDateChange = (dates, dateStrings) => {
    const [date1String, date2String] = dateStrings;
    if (date1String !== date2String) {
      getDateCalculation(date1String, date2String);
    }
    const time1 = new Date(date1String).getTime();
    const time2 = new Date(date2String).getTime();
    const Difference_In_Time = time2 - time1;
    const Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
    setDateDifference(Difference_In_Days + 1);

    Difference_In_Days === 0 ? setDaySyntex("Day") : setDaySyntex("Days");

    setFormData((prevValue) => {
      return {
        ...prevValue,
        fromDate: date1String,
        toDate: date2String,
        toSession: 1,
        fromSession: 0,
      };
    });

    if (date1String === date2String) {
      setCalculatedLeaves(1);
      setSessionVisible(true);
      setFormData((prevValue) => {
        return {
          ...prevValue,
          fromSession: 2,
        };
      });
    } else {
      setSessionVisible(false);
    }
  };

  // Handling Full Day || Second Half leave -> START DATE
  const handleFromSession = (e) => {
    if (e.target.value === 1 && calculatedLeaves > 0 && !sessionVisible) {
      setCalculatedLeaves(calculatedLeaves - 0.5);
    } else if (
      e.target.value === 0 &&
      calculatedLeaves > 0 &&
      !sessionVisible
    ) {
      setCalculatedLeaves(calculatedLeaves + 0.5);
    }
    if (e.target.value === 1 && calculatedLeaves > 0 && sessionVisible) {
      setCalculatedLeaves(0.5);
    } else if (e.target.value === 0 && calculatedLeaves > 0 && sessionVisible) {
      setCalculatedLeaves(0.5);
    } else if (e.target.value === 2 && calculatedLeaves > 0 && sessionVisible) {
      setCalculatedLeaves(1);
    }
    setFormData((prevValue) => {
      return {
        ...prevValue,
        fromSession: e.target.value,
      };
    });
  };

  // Handling Full Day || Second Half leave -> END DATE
  const handleToSession = (e) => {
    if (e.target.value === 0 && calculatedLeaves > 0) {
      setCalculatedLeaves(calculatedLeaves - 0.5);
    } else if (e.target.value === 1 && calculatedLeaves > 0) {
      setCalculatedLeaves(calculatedLeaves + 0.5);
    }
    setFormData((prevValue) => {
      return {
        ...prevValue,
        toSession: e.target.value,
      };
    });
  };

  // SELECTING LEAVE TYPE
  const handleLeaveType = (value) => {
    setFormData((prevValue) => {
      return {
        ...prevValue,
        employeeLeaveId: value,
      };
    });
  };

  // SETTING REASON
  const handletextArea = (e) => {
    const notesMessage = e.target.value;
    setFormData((prevValue) => {
      return {
        ...prevValue,
        notes: notesMessage,
      };
    });
  };

  // SETTING USER TO NOTIFY
  const handleUserTextArea = (e) => {
    const notesMessage = e.target.value;
    setFormData((prevValue) => {
      return {
        ...prevValue,
        notifyUsers: notesMessage,
      };
    });
  };

  // for select box
  const { Option } = Select;
  const { TextArea } = Input;
  const { RangePicker } = DatePicker;

  const { getFieldDecorator } = props.form;

  const handlePreviewCancel = () => {
    setImagePreviewVisible(false);
    setImagePreview("");
  };

  const handlePreview = (image) => {
    setImagePreview(image.url);
    setImagePreviewVisible(true);
  };

  //Leave Calculation API
  const getDateCalculation = (fromDate, toDate) => {
    return getDateCalculationApi(fromDate, toDate)
      .then((res) => {
        setCalculatedLeaves(res.data.counts);
        setCalculatedLeavesMessage(res.data.message ? res.data.message : "");
        if (res.data.counts === 0) {
          setBtnVisible(true);
        } else {
          setBtnVisible(false);
        }
      })
      .catch((error) => {
        notification.error(error.response.data.errors);
      });
  };

  //Apply leave API
  const handleApply = (e) => {
    setVisible(true);
    setBtnVisible(true);
    let formdata = new FormData();
    formdata.append("employee_leave_id", formData.employeeLeaveId);
    formdata.append("from_date", formData.fromDate);
    formdata.append("to_date", formData.toDate);
    formdata.append("from_session", formData.fromSession);
    if (!sessionVisible) {
      formdata.append("to_session", formData.toSession);
    }
    formdata.append("counts", 2);
    formdata.append("notes", formData.notes);
    if (formData.notifyUsers) {
      formdata.append("notify_users", formData.notifyUsers);
    }
    formdata.append("user_id", userId);
    applyForUpdatedAdminLeave(formdata, leaveData.id)
      .then((res) => {
        notification.success({
          message: "Approved successfully",
        });
        closeModal();
        refreshPage();
        setDataUpdate(true);
        componentUpdate();
        setBtnVisible(false);
        setBtnVisible(false);
      })
      .catch((error) => {
        if (error && error.response && error.response.status) {
          if (error.response.status === 422) {
            let errors = error.response.data.errors;
            let errorObject = {};
            Object.keys(errors).forEach((key) => {
              let err = [];
              errors[key].forEach((value) => {
                err.push(new Error(value));
              });
              key = _.camelCase(key.split(".")[0]);
              errorObject[key] = { value: formData[key], errors: err };
            });
            setFields(errorObject);
          } else {
            notification.error(error.response.data.errors);
          }
        }
      });
    setBtnVisible(false);

    setVisible(false);
  };

  React.useEffect(() => {
    calculatedLeaves === 1 ? setDaySyntexBox("Day") : setDaySyntexBox("Days");
  }, [formData.fromSession, formData.toSession, handleDateChange]);

  const reasonText = () => {
    return (
      <div className="labelText">
        <div>Reason For Leave</div>
      </div>
    );
  };
  const labelText = () => {
    return (
      <div className="labelText">
        <div>Notify User</div>
      </div>
    );
  };
  const defaultLeaveName = () => {
    const result = leaveTypes.filter((val) => {
      return val.employee_leave_id === leaveData.employee_leave_id;
    });
    const leaveTyperesult = result[0];
    if (leaveTyperesult) {
      return (
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <div className="employeeLeaveId">{leaveTyperesult.name}</div>
          <div className="leaveValue">
            {"(" +
              leaveTyperesult.remaining_counts +
              "/" +
              leaveTyperesult.initial_counts +
              ")"}
          </div>
        </div>
      );
    }
  };

  const getImageFiles = (imageUrls) => {
    if (imageUrls) {
      let imageResponse = [];
      imageUrls.forEach((url) => {
        imageResponse.push({ uid: "uid", url: url });
      });
      return imageResponse;
    }
    return [];
  };

  const notifyUsersInitialValue = () => {
    if(leaveData.notify_users){
      setNotifyUsersValue(leaveData.notify_users.join(","))
    }
  }
  return (
    <>
      {visible && (
        <div
          className="center-me"
          style={{
            position: "absolute",
            top: "0px",
            bottom: "0px",
            left: "0px",
            right: "0px",
            zIndex: "100",
          }}
        >
          <Spin />
        </div>
      )}

      {
        <div className="applyLeave-container">
          <form>
            {/* Select Dates */}

            <div className="form-content">
              <Row className="row">
                <Col
                  className="col"
                  xs={24}
                  sm={24}
                  md={24}
                  lg={24}
                  xl={24}
                  xxl={24}
                >
                  {/* Calender */}
                  <Form.Item name="userId" label={"User Name"} colon={false}>
                    <Select
                      disabled
                      value={leaveData.user.name}
                      className="leaveType-selectbox"
                      defaultValue={leaveData.user.name}
                    ></Select>
                  </Form.Item>
                </Col>
              </Row>
            </div>

            <div className="form-content">
              <Row className="row">
                <Col
                  className="col"
                  xs={24}
                  sm={24}
                  md={24}
                  lg={24}
                  xl={24}
                  xxl={24}
                >
                  {/* Calender */}
                  <div className="select-leaveOption">
                    <Form.Item name="rangepicker" label={"Date"} colon={false}>
                      {getFieldDecorator("rangepicker", {
                        initialValue: [
                          moment(formData.fromDate),
                          moment(formData.toDate),
                        ],
                      })(
                        <RangePicker
                          allowClear={false}
                          onChange={handleDateChange}
                          separator={
                            <Input
                              placeholder={
                                isNaN(dateDifference)
                                  ? "0 Days"
                                  : dateDifference + " " + daySyntex
                              }
                              disabled
                              style={{
                                right: "40%",
                                left: "32%",
                                position: "absolute",
                                margin: "0 auto",
                                height: "100%",
                                width: "85px",
                                top: "0",
                              }}
                            />
                          }
                        />
                      )}
                    </Form.Item>
                  </div>
                  <div style={{ marginBottom: "1.5rem", color: "grey" }}>
                    Applying for {calculatedLeaves} {daySyntexBox}.
                    <br />
                    {calculatedLeavesMessage}
                  </div>
                  <div className="radio-flex">
                    {/* Start Date Type */}
                    <Form.Item name="fromDate" label={"From"} colon={false}>
                      <div className="">
                        <Radio.Group
                          className=""
                          onChange={handleFromSession}
                          value={formData.fromSession}
                        >
                          <Radio className="u-margin-bottom " value={0}>
                            {formData.fromDate !== formData.toDate
                              ? "Full Day"
                              : "First Half"}
                          </Radio>
                          <br />
                          <Radio className="u-margin-bottom" value={1}>
                            Second Half
                          </Radio>
                          <br />
                          {!sessionVisible ? null : (
                            <Radio className="u-margin-bottom" value={2}>
                              Full Day
                            </Radio>
                          )}
                        </Radio.Group>
                      </div>
                    </Form.Item>

                    {/* End Date Type */}
                    <Form.Item name="toDate" label={"To"} colon={false}>
                      <div className="">
                        <Radio.Group
                          className=""
                          onChange={handleToSession}
                          value={formData.toSession}
                        >
                          <Radio
                            className="u-margin-bottom"
                            value={0}
                            disabled={sessionVisible}
                          >
                            First Half
                          </Radio>
                          <br />
                          <Radio
                            className="u-margin-bottom"
                            value={1}
                            disabled={sessionVisible}
                          >
                            {formData.fromDate !== formData.toDate
                              ? "Full Day"
                              : "Second Half"}
                          </Radio>
                        </Radio.Group>
                      </div>
                    </Form.Item>
                  </div>
                </Col>
              </Row>
            </div>

            {/* Leave Type */}
            <div className="form-content leaveType-cont">
              <Row className="row">
                <Col
                  className="col"
                  xs={24}
                  sm={24}
                  md={24}
                  lg={24}
                  xl={24}
                  xxl={24}
                >
                  <div className="form-group">
                    <Form.Item
                      name="employeeLeaveId"
                      label={"Leave Type"}
                      colon={false}
                    >
                      <Select
                        placeholder="Please select Leave Type"
                        defaultValue={formData.employeeLeaveId}
                        onChange={handleLeaveType}
                        notFoundContent={
                          userId
                            ? "No leave found!"
                            : "Please select user first"
                        }
                      >
                        {leaveTypes.map((leave) => {
                          return (
                            <Option
                              key={leave.id}
                              value={leave.employee_leave_id}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                }}
                              >
                                <div className="employeeLeaveId">
                                  {leave.name}
                                </div>
                                <div className="leaveValue">
                                  {"(" +
                                    leave.remaining_counts +
                                    "/" +
                                    leave.initial_counts +
                                    ")"}
                                </div>
                              </div>
                            </Option>
                          );
                        })}
                      </Select>
                    </Form.Item>
                  </div>
                </Col>
              </Row>
            </div>

            {/* Reason */}
            <div className="form-content">
              <Row className="row">
                <Col
                  className="col"
                  xs={24}
                  sm={24}
                  md={24}
                  lg={24}
                  xl={24}
                  xxl={24}
                >
                  <Form.Item name="notes" label={reasonText()} colon={false}>
                    {getFieldDecorator("notes", {
                      initialValue: leaveData.notes,
                      rules: [
                        {
                          required: false,
                          message: "Enter a reason",
                        },
                      ],
                    })(
                      <TextArea
                        placeholder={"Enter a reason..."}
                        rows={3}
                        allowClear
                        onChange={handletextArea}
                      />
                    )}
                  </Form.Item>
                  <Form.Item
                    value={leaveData.notify_users}
                    name="notifyUsers"
                    label={labelText()}
                    colon={false}
                  >
                    {getFieldDecorator("notifyUsers", {
                      initialValue: notifyUsersValue,
                      rules: [
                        {
                          required: false,
                          message: "Enter valid emails",
                        },
                      ],
                    })(
                      <TextArea
                        rows={2}
                        allowClear
                        onChange={handleUserTextArea}
                      />
                    )}
                  </Form.Item>
                </Col>
              </Row>
            </div>
            {leaveData.images && leaveData.images.length > 0 && (
              <div className="form-content">
                <Row className="row">
                  <Col
                    className="col"
                    xs={24}
                    sm={24}
                    md={24}
                    lg={24}
                    xl={24}
                    xxl={24}
                  >
                    <div className="form-group">
                      <Form.Item label={"Attachment (If any)"} colon={false}>
                        <div className="clearfix">
                          <div style={{ paddingTop: "1rem" }}>
                            <Upload
                              beforeUpload={() => {
                                return false;
                              }}
                              listType="picture-card"
                              defaultFileList={getImageFiles(leaveData.images)}
                              isImageUrl={true}
                              onPreview={handlePreview}
                              showUploadList={{
                                showPreviewIcon: true,
                                showRemoveIcon: false,
                                showDownloadIcon: false,
                              }}
                            >
                              {null}
                            </Upload>

                            <Modal
                              className="viewImgModal"
                              visible={imagePreviewVisible}
                              footer={null}
                              onCancel={handlePreviewCancel}
                            >
                              <img
                                alt="leave"
                                style={{ width: "100%", maxHeight: "100%" }}
                                src={imagePreview}
                              />
                            </Modal>
                          </div>
                        </div>
                      </Form.Item>
                    </div>
                  </Col>
                </Row>
              </div>
            )}

            <div className="form-content">
              <Row className="row">
                <Col
                  className="col"
                  xs={24}
                  sm={24}
                  md={24}
                  lg={24}
                  xl={24}
                  xxl={24}
                ></Col>
              </Row>
            </div>
          </form>
        </div>
      }
      <div className="formAction-footer-cont">
        <div className="formAction-footer text-right">
          <Button
            onClick={handleApply}
            className="btn btn-wide btn-success apply-btn text-uppercase"
            disabled={btnVisible}
          >
            Approve
          </Button>
        </div>
      </div>
    </>
  );
};

export default Form.create()(CreateEmployeeLeave);
