import React from "react";
import {
  Popover,
  Select,
  Collapse,
  DatePicker,
  Pagination,
  Spin,
  notification,
  Empty,
  Icon,
  Modal,
  Button,
} from "antd";
import { RetweetOutlined } from "@ant-design/icons";

import info from "../../../../../../../assets/images/icons/leave/info.svg";
import calendar from "../../../../../../../assets/images/icons/monthly-calendar-white.svg";
import EmployeeDetailsCard from "../../EmployeeDetailsCard/EmployeeDetailsCard";
import CardDetail from "../../MultipleDay/CardDetail";
import CollapseHeader from "./CollapseHeader";
import {
  getFilteredListOfLeaves,
  getOrganizationEmployeesWithEx,
  getAllLeaveTypes,
} from "../../../DataManager";

const AllRequest = ({ setButtonVisible, dataUpdate, setDataUpdate }) => {
  const [employees, setEmployees] = React.useState([]);
  const [payload, setPayload] = React.useState({
    o_id: 1,
  });
  const [year, setYear] = React.useState([]);
  const [loader, setLoader] = React.useState(true);
  const [paginationTotal, setPaginationTotal] = React.useState(null);
  const [paginationPerPage, setPaginationPerPage] = React.useState(8);
  const [pageNumber, setPageNumber] = React.useState(1);
  const [listOfLeaves, setListOfLeaves] = React.useState([]);
  const [month, setMonth] = React.useState(null);
  const [assignedAccordionKey, setAssignedAccordionKey] = React.useState(null);
  const [singleLeaveType, setSingleLeaveType] = React.useState([]);
  const [leaveTypesDropdown, setLeaveTypesDropdown] = React.useState([]);

  // HIDING CORNER BUTTONS
  React.useEffect(() => {
    setButtonVisible((prevValue) => {
      return {
        ...prevValue,
        addLeave: true,
        leaveType: false,
        createLeaveType: false,
      };
    });
  }, []);

  // year selectbox
  const { Option } = Select;
  // Collapse
  const { Panel } = Collapse;
  // for monthPicker
  const { MonthPicker } = DatePicker;
  const { confirm } = Modal;

  // SETTING EMPLOYEE STATE
  const handleLeaveTypesDropdown = (value) => {
    setSingleLeaveType(value);
    setPageNumber(1);
  };

  React.useEffect(() => {
    setPayload((prevValue) => {
      return { ...prevValue, leave_type: singleLeaveType };
    });
    // handleLeaveTypeDropdownData()
  }, [singleLeaveType]);

  // FETCHING ALL EMPLOYEES NAMES
  const loadAllLeaveTypes = () => {
    return getAllLeaveTypes()
      .then((res) => {
        setLeaveTypesDropdown(res.data);
      })
      .catch((error) => {
        notification.error(error.response.data.errors);
      });
  };
  React.useEffect(() => {
    loadAllLeaveTypes();
  }, []);

  // FETCHING ALL EMPLOYEES NAMES
  const loadOrganizationEmployees = () => {
    return getOrganizationEmployeesWithEx()
      .then((res) => {
        setEmployees(res.data);
      })
      .catch((error) => {
        notification.error(error.response.data.errors);
      });
  };

  React.useEffect(() => {
    loadOrganizationEmployees();
  }, []);

  // SETTING EMPLOYEE STATE
  const handleEmployee = (value) => {
    setPayload((prevValue) => {
      return { ...prevValue, user_id: value };
    });
    setPageNumber(1);
  };

  // SETTING CALENDER
  const handleCalender = (date) => {
    setMonth(date);
    setPayload((prevValue) => {
      return { ...prevValue, month: date ? date.format("YYYY-MM") : undefined };
    });
    setPageNumber(1);
  };

  // SETTING STATUS
  const handleStatus = (value) => {
    setPayload((prevValue) => {
      return { ...prevValue, statuses: value };
    });
    setPageNumber(1);
  };

  // SETTING YEAR
  const handleYear = (value) => {
    setPayload((prevValue) => {
      return { ...prevValue, year: value };
    });
    setPageNumber(1);
  };

  // MAPPING YEAR
  React.useEffect(() => {
    const allYears = [];
    for (let i = Number(new Date().getFullYear()) + 1; i >= Number(2021); i--) {
      allYears.push(i);
    }
    setYear(allYears);
  }, []);

  // FETCHING MAPPING DATA FROM API HERE
  const loadLeaveData = () => {
    setLoader(true);
    let request_payload = {};
    Object.keys(payload).forEach((key) => {
      if (payload[key] !== undefined) {
        request_payload[key] = payload[key];
      }
    });
    return getFilteredListOfLeaves({
      ...request_payload,
      page: pageNumber,
    })
      .then((res) => {
        setPaginationTotal(res.meta.pagination.total);
        setPaginationPerPage(res.meta.pagination.per_page);
        setListOfLeaves(res.data);
        setLoader(false);
      })
      .catch((error) => {
        // notification.error(error.response.data.errors);
        setLoader(false);
      });
  };

  // USE EFFECT WHEN PAGE NUMBER IS CHANGING | Payload is changing
  React.useEffect(() => {
    loadLeaveData();
    setDataUpdate(false);
  }, [pageNumber, payload, dataUpdate]);

  // Handling Pagination
  const handlePagination = (pageNumber) => {
    setPageNumber(pageNumber);
  };

  // For accordion dropdown click
  const assignedAccordionToggle = (key) => {
    var assignedKey = assignedAccordionKey;
    setAssignedAccordionKey(assignedKey === key ? null : key);
  };

  return (
    <>
      {loader && (
        <div
          className="centerMe"
          style={{
            height: "500px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Spin />
        </div>
      )}
      {!loader && (
        <section className="content-section leaveRequest-section">
          <div className="content-header">
            <div className="left filters fx fx--ai-c">
              <div className="part filterfirst-part">
                <div className="empFilter-box filter-box">
                  {/* SELECT EMPLOYEE */}
                  <Select
                    allowClear={true}
                    className="empFilter-selectbox selectbox"
                    showSearch
                    style={{ width: 200 }}
                    placeholder="All Employees"
                    name="userName"
                    onChange={handleEmployee}
                    value={payload.user_id}
                    filterOption={(input, option) =>
                      option.props.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    <Option key={0} value={undefined} label="All Employee">
                      <div className="demo-option-label-item">All Employee</div>
                    </Option>
                    {employees.map((employee) => {
                      return (
                        <Option key={employee.id} value={employee.uuid} label={employee.name}>
                          <div className="demo-option-label-item">{employee.name}</div>
                        </Option>
                      );
                    })}
                  </Select>
                </div>
              </div>
              <div className="part filterSecond-part fx fx--ai-c">
                <div className="monthDataExport-box fx fx--ai-c">
                  <div className="monthFilter-box filter-box" id="calendarContainer">
                    {/* MONTH PICKER */}
                    <MonthPicker
                      allowClear={true}
                      style={{ width: 120 }}
                      format="MMM YY"
                      placeholder="Month"
                      onChange={handleCalender}
                      value={month}
                      suffixIcon={
                        <img className="calendar-icon" src={calendar} alt="calendar icon" />
                      }
                    />
                  </div>
                </div>
                <div className="statusFilter-box filter-box" style={{ marginRight: "10px" }}>
                  {/* STATUS  */}
                  <Select
                    allowClear={true}
                    className="status-selectbox selectbox"
                    style={{ width: 120 }}
                    placeholder="Status"
                    onChange={handleStatus}
                    value={payload.statuses}
                  >
                    <Option value="0">Pending</Option>
                    <Option value="1">Approved</Option>
                    <Option value="2">Declined</Option>
                    <Option value="3">Cancelled</Option>
                    <Option value="4">Cancel Request</Option>
                  </Select>
                </div>
                
              </div>
            </div>
            <div className="right multiple-item filters">
              {/* <div className="day-info-box">
                <Popover
                  placement="left"
                  overlayClassName="dayInfo-popover"
                  trigger="click"
                  getPopupContainer={(trigger) => trigger.parentNode}
                  content={
                    <div className="day-info-value">
                      <div className="full-day value">
                        <span className="colorCode"></span>Full Day
                      </div>
                      <div className="first-half value">
                        <span className="colorCode"></span>First Half
                      </div>
                      <div className="second-half value">
                        <span className="colorCode"></span>Second Half
                      </div>
                    </div>
                  }
                >
                  <div className="day-info">
                    <img className="icon" src={info} alt="info icon" />
                  </div>
                </Popover>
              </div> */}
              <Button
                className="btn"
                style={{ marginRight: "1rem", fontSize: "0.9rem" }}
                onClick={() => {
                  setLoader(true);
                  loadLeaveData();
                }}
              >
                <RetweetOutlined />
              </Button>
              <div className="year-filter">
                {/* YEAR */}
                <Select
                  allowClear={true}
                  className="year-selectbox selectbox"
                  style={{ width: 85 }}
                  placeholder="Year"
                  onChange={handleYear}
                  value={payload.year}
                >
                  {year.map((singleYear) => {
                    let year = singleYear.toString();
                    return (
                      <Option key={year} value={year}>
                        {year}
                      </Option>
                    );
                  })}
                </Select>
              </div>
              <div className="leave-type" style={{ margin:"1rem", borderWidth: "0" }}>
              <Select
                  allowClear={true}
                  className="empFilter-selectbox selectbox"
                  showSearch
                  style={{ width: 150,borderRadius: "10px" }}
                  placeholder="Leave Types"
                  name="leaveTypes"
                  onChange={handleLeaveTypesDropdown}
                  value={singleLeaveType}
                  filterOption={(input, option) =>
                    option.props.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  
                >
                  <Option key={0} value={undefined} label="All Employee">
                    <div className="demo-option-label-item">All Leave Types</div>
                  </Option>
                  {leaveTypesDropdown.map((leaveType) => {
                    return (
                      <Option key={leaveType.id} value={leaveType.id} label={leaveType.name}>
                        <div className="demo-option-label-item">{leaveType.name}</div>
                      </Option>
                    );
                  })}
                </Select>
              </div>
            </div>
          </div>

          {/* ----------------------------------------------------------------- */}
          <div className="content-body">
            {listOfLeaves.length === 0 ? null : (
              <div className="head-row-box">
                <div className="part first-part" style={{ textAlign:'center',marginLeft:'1rem' }} >
                  <div className="head-title item date">Date</div>
                  {/* <div className="head-title item empName" style={{ marginLeft:"25px" }}>Profile</div> */}
                  <div className="head-title item empName" style={{width:"20%",minWidth:'200px', maxWidth: "200px" , textAlign:'center'}}>Name</div>
                  <div className="head-title item leaveType" style={{marginRight:"2rem", textAlign:'center',   width: '40%',marginLeft:'1rem'}}>Leave Type</div>
                </div>
                <div className="part second-part">
                  <div className="head-title item status" >
                    Status
                  </div>
                  <div className="head-title item status">Action</div>
                </div>
              </div>
            )}
            <div className="body-row-box">
              <Collapse
                className="leave-description-card"
                bordered={false}
                expandIconPosition={"right"}
                activeKey={assignedAccordionKey}
              >
                {listOfLeaves.length === 0 ? (
                  <div className="center-me">
                    <Empty />
                  </div>
                ) : (
                  listOfLeaves.map((leave, index) => {
                    return (
                      <Panel
                        header={
                          <EmployeeDetailsCard
                            leaveData={leave}
                            loadLeaveData={loadLeaveData}
                            setLoader={setLoader}
                          />
                        }
                        key={index + 1}
                        showArrow={false}
                        extra={
                          <Icon
                            className="dropArrowPosition"
                            type={assignedAccordionKey === index + 1 ? "up" : "down"}
                            onClick={() => assignedAccordionToggle(index + 1)}
                          />
                        }
                      >
                        <CardDetail leaveData={leave} />
                      </Panel>
                    );
                  })
                )}
              </Collapse>
            </div>
          </div>
        </section>
      )}

      {/* Pagination */}
      {!loader && listOfLeaves.length > 0 && (
        <section className="pagination-container">
          <div className="pagination-box text-right">
            <Pagination
              className="pagination"
              total={paginationTotal}
              pageSize={paginationPerPage}
              defaultCurrent={pageNumber}
              onChange={handlePagination}
            />
          </div>
        </section>
      )}
    </>
  );
};

export default AllRequest;
