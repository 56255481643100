import React, { Component, Fragment } from "react";
import { Spin, Popconfirm, notification, Icon, Input, Button, Row, Col, Select, Table, Divider, Modal, DatePicker, Tooltip, Pagination, Upload } from "antd";
import compDocument from '../../../assets/images/icons/icon-comp-document-bg.svg';
import ForAdmin from "../../../hocs/ForAdmin";
import { withStore } from "../../../hocs/withStore";
import RequireAuth from "../../../hocs/RequireAuth";
import ProfileComplete from "../../../hocs/ProfileComplete";
import NotificationUpdate from "../../../hocs/NotificationUpdate";
import _ from 'lodash';
import { axiosInstance as api } from '../../../utilities/configureAxios';
import moment from 'moment';
import url from '../../../config/url';
import { Helmet } from 'react-helmet'
import PublicHoliday from '../../../assets/images/icons/sibebar/public-holidays.svg';

// Dropdown
const { Option } = Select;
const TITLE = 'Public Holidays'
const dateFormat = 'YYYY-MM-DD';

// Pagination
function itemRender(current, type, originalElement) {
    if (type === 'prev') {
        return <a className="back-icon"> <Icon type="left" /> Back</a>;
    }
    if (type === 'next') {
        return <a className="next-icon">Next <Icon type="right" /></a>;
    }
    return originalElement;
}

class HolidayAdminPanel extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            years: [],
            holidays: [],
            holiday_name: null,
            holiday_type: "1",
            holiday_date: moment(),
            holiday_url: null,
            visible: false,
            holiday_id: null,
            holiday_icon: null,
            fileList: [],
            selected_year: new Date().getFullYear(),
            pagination: {
                total: null,
                count: null,
                per_page: null,
                current_page: null,
                total_pages: null
            }
        };
    }

    componentDidMount = () => {
        let year = new Date().getFullYear();
        this.setState({ years: [year - 1, year, year + 1], selected_year: year });
        this.setState({ loading: true });
        api
            .post('/holidays?paginate=true', { year: year })
            .then(res => {
                const holidays = _.get(res, 'data.data', [])
                const pagination = _.get(res, 'data.meta.pagination', []);
                this.setState({
                    holidays,
                    loading: false,
                    pagination
                });
            })
            .catch(error => {
                notification['error']({
                    message: _.get(error, 'response.data.errors.message', "Oops! Something went wrong!")
                });
                this.setState({ loading: false });
            });
    }

    columns = [
        {
            title: 'Holiday Name',
            dataIndex: 'reason',
            className: "holiday-name",
        },
        {
            title: 'Holiday Type',
            dataIndex: 'type',
            className: "holiday-type",
            render: (value) => (
                value == 1 ? "Public" : value == 2 ? "Restricted" : "Sectional"
            )
        },
        {
            title: 'Holiday Date',
            dataIndex: 'date',
            className: "holiday-date",
            render: (value) => (
                moment(value, 'YYYY-MM-DD HH:mm:ss').format('DD-MMM-YYYY')
            )
        },
        {
            title: 'Action',
            key: 'action',
            className: 'action',
            render: (value, holiday) => (
                <div className="search_button_group">
                    <Tooltip placement="bottom" title={'Edit'}><Button type="link" onClick={() => this.onHolidayEdit(holiday)} className="btn btn-light-info btn-icon text-uppercase"><Icon type="edit" theme="filled" /></Button></Tooltip>
                    <Divider type="vericle" />
                    <Popconfirm
                        placement="bottomLeft"
                        icon={<Icon type="warning" theme="twoTone" twoToneColor="#fcb103" />}
                        title="Are you sure you want to delete holiday?"
                        onConfirm={() => this.onHolidayDelete(holiday.id)}
                        okText="Yes"
                        cancelText="No"
                    >
                        <Tooltip placement="bottom" title={'Delete'}><Button type="link" className="btn btn-light-danger btn-icon text-uppercase"><Icon type="delete" theme="filled" /></Button></Tooltip>
                    </Popconfirm>
                </div>
            )
        }
    ];

    onHolidayDelete = holiday_id => {
        const { selected_year } = this.state;
        this.setState({ loading: true });
        api
            .delete(`/holidays/${holiday_id}`)
            .then(res => {
                notification['success']({
                    message: "Holiday deleted successfully"
                });
                api
                    .post('/holidays?paginate=true', { year: selected_year })
                    .then(res => {
                        const holidays = _.get(res, 'data.data', [])
                        const pagination = _.get(res, 'data.meta.pagination', []);
                        this.setState({ holidays, loading: false, pagination });
                    })
                    .catch(error => {
                        notification['error']({
                            message: _.get(error, 'response.data.errors.message', "Oops! Something went wrong!")
                        });
                        this.setState({ loading: false });
                    });
            })
            .catch(error => {
                notification['error']({
                    message: _.get(error, 'response.data.errors.message', "Oops! Something went wrong!")
                });
                this.setState({ loading: false });
            });
    }

    onYearChange = (year) => {
        this.setState({ loading: true });
        api
            .post('/holidays?paginate=true', { year: year })
            .then(res => {
                const holidays = _.get(res, 'data.data', [])
                const pagination = _.get(res, 'data.meta.pagination', []);
                this.setState({
                    holidays, loading: false, selected_year: year, pagination
                });
            })
            .catch(error => {
                notification['error']({
                    message: _.get(error, 'response.data.errors.message', "Oops! Something went wrong!")
                });
                this.setState({ loading: false });
            });
    }

    onPageChange = (pageNumber) => {
        const { selected_year } = this.state;
        this.setState({ loading: true });
        api
            .post('/holidays?paginate=true&page=' + pageNumber, { year: selected_year })
            .then(res => {
                const holidays = _.get(res, 'data.data', [])
                const pagination = _.get(res, 'data.meta.pagination', []);
                this.setState({
                    holidays,
                    pagination,
                    loading: false,
                });
            })
            .catch(error => {
                notification['error']({
                    message: _.get(error, 'response.data.errors.message', "Oops! Something went wrong!")
                });
                this.setState({ loading: false });
            });
    }

    onHolidayEdit = holiday => {
        this.setState({
            visible: true,
            holiday_name: holiday.reason,
            holiday_type: holiday.type,
            holiday_date: holiday.date,
            holiday_id: holiday.id,
            holiday_url: holiday.url
        });
    };

    showModal = () => {
        this.setState({
            visible: true,
            holiday_name: null,
            holiday_type: "1",
            fileList: [],
            holiday_date: moment()
        });
    };

    onDateChange = (date) => {
        this.setState({
            holiday_date: date
        })
    }

    onNameChange = (e) => {
        this.setState({
            holiday_name: e.currentTarget.value
        })
    }

    handleChange = ({ fileList }) => this.setState({ fileList });

    onTypeChange = (value) => {
        this.setState({
            holiday_type: value
        })
    }


    handleOk = e => {
        const { holiday_name, holiday_type, holiday_date, selected_year, holiday_icon, fileList } = this.state;

        let formdata = new FormData()
        formdata.append('reason', holiday_name)
        formdata.append('type', holiday_type)
        formdata.append('date', moment(holiday_date).format(dateFormat))
        formdata.append('holiday_icon', fileList[0].originFileObj)

        api
            .post(url['createHolidays'], formdata)
            .then(res => {
                notification['success']({
                    message: "Holiday created successfully!"
                });
                api
                    .post('/holidays', { year: selected_year })
                    .then(res => {
                        const holidays = _.get(res, 'data.data', [])
                        const pagination = _.get(res, 'data.meta.pagination', []);
                        this.setState({
                            holidays, loading: false, visible: false,
                            holiday_name: null,
                            holiday_type: "1",
                            holiday_date: moment(),
                            holiday_url: null,
                            holiday_icon: null,
                            fileList: [],
                            pagination
                        });
                    })
                    .catch(error => {
                        notification['error']({
                            message: _.get(error, 'response.data.errors.message', "Oops! Something went wrong!")
                        });
                        this.setState({ loading: false, visible: false, holiday_name: null, holiday_type: 1, holiday_date: moment() });
                    });
            })
            .catch(error => {
                const error_code = _.get(error, 'response.status');
                if (error_code && error_code == '422') {
                    notification['error']({
                        message: Object.values(_.get(error, 'response.data.errors', "Oops! Something went wrong!"))
                    });
                }
                else {
                    notification['error']({
                        message: _.get(error, 'response.data.errors.message', "Oops! Something went wrong!")
                    });
                }
                this.setState({ loading: false });
            });
    };

    handleEditOk = e => {
        const { holiday_name, holiday_type, holiday_date, holiday_id, selected_year, holiday_icon, fileList } = this.state;

        let formdata = new FormData()
        formdata.append('reason', holiday_name)
        formdata.append('type', holiday_type)
        formdata.append('date', moment(holiday_date).format(dateFormat))
        if (fileList.length != 0)
            formdata.append('holiday_icon', fileList[0].originFileObj)

        api
            .post(`/holidays/${holiday_id}`, formdata)
            .then(res => {
                notification['success']({
                    message: "Holiday edited successfully!"
                });
                api
                    .post('/holidays?paginate=true', { year: selected_year })
                    .then(res => {
                        const holidays = _.get(res, 'data.data', [])
                        const pagination = _.get(res, 'data.meta.pagination', []);
                        this.setState({ holidays, loading: false, visible: false, holiday_name: null, holiday_type: 1, holiday_date: moment(), holiday_id: null, fileList: [], pagination });
                    })
                    .catch(error => {
                        notification['error']({
                            message: _.get(error, 'response.data.errors.message', "Oops! Something went wrong!")
                        });
                        this.setState({ loading: false, visible: false, holiday_name: null, holiday_type: 1, holiday_date: moment(), holiday_id: null });
                    });
            })
            .catch(error => {
                const error_code = _.get(error, 'response.status');
                if (error_code && error_code == '422') {
                    notification['error']({
                        message: _.get(error, 'response.data.errors.type', "Oops! Something went wrong!")
                    });
                }
                else {
                    notification['error']({
                        message: _.get(error, 'response.data.errors.message', "Oops! Something went wrong!")
                    });
                }

                this.setState({ loading: false });
            });
    };

    handleCancel = e => {
        this.setState({
            holiday_name: null,
            holiday_type: 1,
            holiday_date: moment(),
            visible: false,
            holiday_id: null,
            holiday_url: null,
            holiday_icon: null,
            fileList: []
        });
    };

    render() {
        const { holidays, selected_year, years, loading, visible, holiday_name, holiday_type, holiday_date, holiday_url, holiday_id, fileList } = this.state;
        const { total, per_page, current_page } = this.state.pagination;
        return (
            <Fragment>
                <Helmet>
                    <title>{TITLE}</title>
                </Helmet>
                <div className="page-wrapper">

                    <div className="holidayAP-container">
                        {loading ? (<div className="page-spinner"><Spin /></div>) : (
                            <Fragment>
                                {/* page Header */}
                                <div className="page-header">
                                    <div className="page-title">
                                        <h2 className="title"><img className="icon icon-view" src={PublicHoliday} alt="view icon" /> Public Holidays</h2>
                                    </div>
                                    <Button className="btn btn-success text-uppercase" onClick={this.showModal}><Icon type="plus" /> Add New</Button>
                                </div>

                                {/* page info title */}
                                <section className="page-info-box animated slideInRight">
                                    <h3 className="info-title">List of all Holidays</h3>
                                    <div className="right single-item">
                                        <div className="year-dropdown dropdown">
                                            <Select className="year-selectbox selectbox primary-shadow"
                                                style={{ width: 85 }}
                                                defaultValue={selected_year}
                                                placeholder="Year"
                                                optionFilterProp="children"
                                                onChange={this.onYearChange}
                                            >
                                                {years.map(year => (
                                                    <Option key={year} disabled={selected_year == year}>{year}</Option>
                                                ))}
                                            </Select>
                                        </div>
                                    </div>
                                </section>

                                {/* Admin Panel- Table content */}
                                <section className="content-section holiday-table-info animated slideInUp">
                                    <Table className="table-content" columns={this.columns} dataSource={holidays} rowKey={holidays => holidays.id} pagination={{ onChange: this.onPageChange, itemRender: itemRender, current: current_page, pageSize: per_page, total: total }} />
                                </section>


                                {/* <div className="pagination-box text-center">
                                    <Pagination className="pagination" onChange={this.onPageChange} current={current_page} total={total} itemRender={itemRender} pageSize={per_page} />
                                </div> */}

                                {/* Add New Holiday Modal */}
                                <Modal className="add-new-holiday-modal profileAP-modal"
                                    title="Add New Holiday"
                                    centered
                                    visible={visible}
                                    onOk={holiday_id ? this.handleEditOk : this.handleOk}
                                    onCancel={this.handleCancel}
                                    footer={[
                                        <Button key="back" onClick={this.handleCancel} className="btn btn-light-secondary text-uppercase">Return</Button>,
                                        <Button onClick={holiday_id ? this.handleEditOk : this.handleOk} disabled={!(holiday_name && holiday_name.trim() && holiday_type && holiday_date)} key="submit" className="btn btn-light-success text-uppercase">Submit</Button>,
                                    ]}
                                >
                                    <div className="add-new-holiday-modal-container">
                                        <Row className="" gutter={24}>
                                            <Col className="gutter-row" xs={24} sm={24} md={24} lg={8} xl={8} xxl={8}>
                                                <div className="form-group">
                                                    <label className="label">Holiday Name</label>
                                                </div>
                                            </Col>
                                            <Col className="gutter-row" xs={24} sm={24} md={24} lg={16} xl={16} xxl={16}>
                                                <div className="form-group">
                                                    <Input className="input" maxLength="255" onChange={this.onNameChange} value={holiday_name} placeholder="Enter holiday name" />
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row className="" gutter={24}>
                                            <Col className="gutter-row" xs={24} sm={24} md={24} lg={8} xl={8} xxl={8}>
                                                <div className="form-group">
                                                    <label className="label">Holiday Type</label>
                                                </div>
                                            </Col>
                                            <Col className="gutter-row" xs={24} sm={24} md={24} lg={16} xl={16} xxl={16}>
                                                <div className="holiday-type-dropdown dropdown form-group">
                                                    <Select className="year-selectbox selectbox"
                                                        showSearch
                                                        style={{ width: "100%" }}
                                                        placeholder="--Select holiday type--"
                                                        optionFilterProp="children"
                                                        onChange={this.onTypeChange}
                                                        value={holiday_type}
                                                    >
                                                        <Option key="1" value="1">Public</Option>
                                                        <Option key="2" value="2">Restricted</Option>
                                                        <Option key="3" value="3">Sectional</Option>
                                                    </Select>
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row className="" gutter={24}>
                                            <Col className="gutter-row" xs={24} sm={24} md={24} lg={8} xl={8} xxl={8}>
                                                <div className="form-group">
                                                    <label className="label">Holiday Date</label>
                                                </div>
                                            </Col>
                                            <Col className="gutter-row" xs={24} sm={24} md={24} lg={16} xl={16} xxl={16}>
                                                <div className="form-group">
                                                    <DatePicker className="datepicker" onChange={this.onDateChange} value={moment(holiday_date, dateFormat)} format={dateFormat} allowClear={false} placeholder="Select holiday date" />
                                                </div>
                                            </Col>
                                        </Row>

                                        <Row className="" gutter={24}>
                                            <Col className="gutter-row" xs={24} sm={24} md={24} lg={8} xl={8} xxl={8}>
                                                <label className="label">Holiday Icon</label>
                                            </Col>
                                            <Col className="gutter-row" xs={24} sm={24} md={24} lg={16} xl={16} xxl={16}>
                                                <div className="form-group">
                                                    {/* <Input className="input" onChange={this.onHolidayIconChange} value={icon_files} name="holiday_icon" type="file" /> */}
                                                    <Upload
                                                        action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                                                        listType="picture-card"
                                                        fileList={fileList}
                                                        onPreview={this.handlePreview}
                                                        onChange={this.handleChange}
                                                    >
                                                        {fileList && fileList.length >= 1 ? null : <div>
                                                            <Icon type='plus' />
                                                            <div className="ant-upload-text">Upload</div>
                                                        </div>}

                                                    </Upload>
                                                </div>
                                            </Col>
                                        </Row>

                                        {holiday_url && <Row className="" gutter={24}>
                                            <Col className="gutter-row" xs={24} sm={24} md={24} lg={8} xl={8} xxl={8}>
                                                <label className="label">Current Icon</label>
                                            </Col>
                                            <Col className="gutter-row" xs={24} sm={24} md={24} lg={16} xl={16} xxl={16}>
                                                <div className="form-group previous-img" style={{
                                                    backgroundImage: `url(${holiday_url})`
                                                }}>
                                                </div>
                                            </Col>
                                        </Row>
                                        }
                                    </div>

                                    {/* Importing add holiday component */}
                                    {/* <AddHoliday></AddHoliday> */}


                                </Modal>


                                {/* Edit holiday modal */}
                                <Modal className="edit-holiday-modal profileAP-modal"
                                    title="Edit Holiday"
                                    centered
                                    visible={this.state.modal2Visible}
                                    onOk={() => this.setModal2Visible(false)}
                                    onCancel={() => this.setModal2Visible(false)}
                                    footer={[
                                        <Button key="back" className="btn btn-light-secondary text-uppercase">Return</Button>,
                                        <Button key="submit" className="btn btn-light-success text-uppercase">Edit</Button>,
                                    ]}
                                >

                                    {/* Importing add holiday component */}
                                    {/* <EditHoliday></EditHoliday> */}


                                </Modal>
                            </Fragment>
                        )}
                    </div>

                </div>
            </Fragment>
        )
    }
}

export default ForAdmin(RequireAuth(withStore(ProfileComplete(NotificationUpdate(HolidayAdminPanel)))))