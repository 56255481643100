import * as SERVICEs from '../../../services/AssetService';


export const getAssetTypes = () => {
    return SERVICEs.getAssetTypesService()
        .then(result => {
            return result.data
        })
        .catch(error => {
            throw error
        })
}

export const getAssets = (status, type_id, self, q, page) => {
    let is_issuable = true
    return SERVICEs.getAssetsService(status, type_id, self, q, page, is_issuable)
        .then(result => {
            return result.data
        })
        .catch(error => {
            throw error
        })
}

export const allocateUsingQr = (id) => {
    let body = {
        user_id: 0
    }
    return SERVICEs.allocateDeallocateAssetService(id, body, true)
        .then(result => {
            return result.data
        })
        .catch(error => {
            throw error
        })
}