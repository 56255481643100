import * as APIs from '../constants/BackendConstant'
import { axiosInstance } from '../utilities/configureAxios'

export const getFolderService = (page, pagination) => {
    let url = APIs.GET_FOLDER.replace(':page', page)
    url = url.replace(':pagination', pagination)
    return axiosInstance.get(url);
}

export const getSingleFolderService = (slug) => {
    let url = APIs.GET_A_FOLDER.replace(':slug', slug)
    return axiosInstance.get(url)
}

export const addFolderService = (body) => {
    return axiosInstance.post(APIs.ADD_FOLDER, body);
}

export const updateFolderServie = (body, slug) => {
    let url = APIs.UPDATE_FOLDER.replace(':slug', slug);
    return axiosInstance.post(url, body);
}

export const deleteFolderService = (slug) => {
    let url = APIs.DELETE_FOLDER.replace(':slug', slug);
    return axiosInstance.delete(url);
}

export const getFolderWiseDocumentService = (slug, q, page) => {
    let url = APIs.GET_DOCUMENT_FOLDERWISE.replace(':slug', slug);
    url = url.replace(':q', q);
    url = url.replace(':page', page);
    return axiosInstance.get(url);
}
