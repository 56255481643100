import axios from "axios";
import { notification } from "antd";

let apiUrl = process.env.REACT_APP_API_URL;

const configureAxios = () =>
  axios.create({
    baseURL: apiUrl,
    timeout: 15000,
  });

export const axiosInstance = configureAxios();

axiosInstance.interceptors.request.use((config) => {
  return config;
});

axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (!(error && error.response)) {
      error = {
        response: {
          data: {
            errors: {
              message: "Something went wrong.",
            },
          },
        },
      };
    } else if (
      error &&
      error.response.status === 406 &&
      error.response.data &&
      error.response.data.errors.message === "Unread notification found."
    ) {
      localStorage.setItem(
        "notificationData",
        JSON.stringify(error.response.data)
      );
      let location = window.location;
      window.location = location;
    } else if (error.response && error.response.status === 401) {
      var user_data = {
        user: null,
        profile_pic: null,
        user_roles_name: [],
      };
      localStorage.setItem("data", JSON.stringify(user_data));
      notification["error"]({
        message: "Session Expired",
      });
      window.location = "/";
    } else {
      return Promise.reject(error);
    }
  }
);
