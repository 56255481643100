import React, { Component, Fragment } from "react";
import { notification, Tabs, Spin } from "antd";
import PersonalDetails from './PersonalDetails';
import TechnicalSkills from './TechnicalSkills';
import VehicleDetails from './VehicleDetails';
import Hobbies from './Hobbies';
import EmergencyContacts from './EmergencyContacts';
import BankDetails from './BankDetails';
import EmployeeDetails from './EmployeeDetails';
import RequireAuth from "../../../hocs/RequireAuth";
import { withStore } from "../../../hocs/withStore";
import _ from 'lodash';
import { axiosInstance as api } from '../../../utilities/configureAxios';
import url from '../../../config/url';
import profile from '../../../assets/images/icons/sibebar/profile.svg';
import NotificationUpdate from "../../../hocs/NotificationUpdate";
import ViewContractEmployeeDetails from "../../admin/components/ViewContractEmployeeDetails";

// for tabs
const { TabPane } = Tabs;

class Profile extends Component {
    constructor(props) {
        super(props);
        this.state = {
            user_details: null,
            loading: false
        };
    }
    componentDidMount = () => {
        this.props.store.setData({ activeTab: -1 })
        this.setState({ loading: true });
        api
            .get(url["getProfileWithSkills"])
            .then(res => {

                const user_details = _.get(res, 'data.data', [])
                this.setState({ user_details, loading: false });
            })
            .catch(error => {
                notification['error']({
                    message: _.get(error, 'response.data.errors.message', "Oops! Something went wrong!")
                });
                this.setState({ loading: false });
            });


    }
    render() {
        const { user_details, loading } = this.state;
      const { store: { data: { user_roles_name } } } = this.props;

        return (
            <Fragment>
                <div className="profile-dashboard-container">
                    {loading ? (<div className="page-spinner"><Spin /></div>) : (
            (user_roles_name.indexOf("contract_employee") == -1) ? 

                        <Fragment>
                            {/* page Header */}
                            <div className="page-header">
                                <div className="page-title">
                                    <h2 className="title"><img className="icon icon-view" src={profile} alt="view icon" /> My Profile</h2>
                                </div>
                            </div>

                            {/* page info title */}

                            <section className="page-info-box animated bounceInRight">

                                <Tabs defaultActiveKey="1">
                                    <TabPane tab="Personal Details" key="1">
                                        <PersonalDetails
                                            personal_details={user_details ? user_details.personal_details : null} />
                                    </TabPane>
                                    <TabPane tab="Skills" key="2">
                                        <TechnicalSkills
                                            user_skills={user_details ? user_details.skills : null} />
                                    </TabPane>
                                    {/* <TabPane tab="Vehicle Details" key="3">
                                <VehicleDetails></VehicleDetails>
                            </TabPane> */}
                                   <TabPane tab="Hobbies" key="3">
                                        <Hobbies
                                            user_hobbies={user_details ? user_details.hobbies : null} />
                                    </TabPane>
                                    <TabPane tab="Emergency Contacts" key="4">
                                        <EmergencyContacts
                                            emergency_contact_1={user_details && user_details.emergency_contacts ? user_details.emergency_contacts.emergency_contact_1 : null}
                                            emergency_contact_2={user_details && user_details.emergency_contacts ? user_details.emergency_contacts.emergency_contact_2 : null}
                                        />
                                    </TabPane>
                                    <TabPane tab="Financial Details" key="5">
                                        <BankDetails
                                            bank_details={user_details ? user_details.bank_details : null} />
                                    </TabPane>
                                    <TabPane tab="Employee Details" key="6">
                                        <EmployeeDetails
                                            employee_details={user_details ? user_details.employee_details : null} />
                                    </TabPane>
                                </Tabs>

                            </section>
                        </Fragment>
                        : (
                            <ViewContractEmployeeDetails contractEmployeeDetails={user_details ? user_details : null} profileKey={true} />
                        )
                    )}
                </div>
            </Fragment>
            
        )
    }
}

export default RequireAuth(withStore(NotificationUpdate(Profile)))