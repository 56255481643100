import React, { Component, Fragment } from "react";
import { notification, Form, Icon, Input, Button, Checkbox, Row, Col, Select, DatePicker, Radio,Spin } from "antd";
import male from '../../../../assets/images/icons/profile/personal-details/male.svg';
import female from '../../../../assets/images/icons/profile/personal-details/female.svg';
import heartMarried from '../../../../assets/images/icons/profile/personal-details/heart-married.svg';
import heartUnmarried from '../../../../assets/images/icons/profile/personal-details/heart-unmarried.svg';
import veg from '../../../../assets/images/icons/profile/personal-details/veg.svg';
import nonveg from '../../../../assets/images/icons/profile/personal-details/non-veg.svg';
import wine from '../../../../assets/images/icons/profile/personal-details/wine.svg';
import juice from '../../../../assets/images/icons/profile/personal-details/juice.svg';
import _ from 'lodash';
import moment from 'moment';
import { axiosInstance as api } from '../../../../utilities/configureAxios';
import data_setting from '../../../../config/data_setting'
import url from '../../../../config/url';
import { withStore } from '../../../../hocs/withStore';
import ForAdminAndHr from '../../../../hocs/ForAdminAndHr';


// Dropdown
const { Option } = Select;

class AdminEditContractDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
          editModalLoading:false,
          saveBtnDisabled:true,
            personal_details: null,
            employee_id: null,
            teams_loading: true,
      user_roles_id: [],
      skills_id:[],
      default_skills:[],
      default_hobbies:[],
      roles_loading: true,
      employee_details:null,
      company_name:null,
      roles: null,
      teams:null,
      skills_loading: true,
      hobbies_loading:true,
      skills:null,
      hobbies:null,
      closeModal:null
        };
    }
    componentDidMount = () => {
        const {
          store: { setData, storeData },
        } = this.props;
        this.setState({ loading: true });
        api
          .get(url["getRoles"])
          .then((res) => {
            const roles = _.get(res, "data.data", []);
            this.setState({ roles, roles_loading: false });
          })
          .catch((error) => {
            notification["error"]({
              message: _.get(
                error,
                "response.data.errors.message",
                "Oops! Something went wrong!"
              ),
            });
            this.setState({ roles_loading: false });
          });
        api
          .get(url["getTeams"])
          .then((res) => {
            const teams = _.get(res, "data.data", []);
            this.setState({ teams, teams_loading: false });
          })
          .catch((error) => {
            notification["error"]({
              message: _.get(
                error,
                "response.data.errors.message",
                "Oops! Something went wrong!"
              ),
            });
            this.setState({ teams_loading: false });
          });
        api
          .get(url["userRoles"] + "/" + this.state.employee_id)
          .then((res) => {
            const user_roles = _.get(res, "data.data", []);
            this.setState({ user_roles, user_roles_id: _.map(user_roles, "id") });
          })
          .catch((error) => {
            notification["error"]({
              message: _.get(
                error,
                "response.data.errors.message",
                "Oops! Something went wrong!"
              ),
            });
          });
          api
      .get(url['getSkills'])
      .then((res) => {
        const skills = _.get(res, 'data.data', []);
        this.setState({ skills, skills_loading: false });
      })
      .catch((error) => {
        notification['error']({
          message: _.get(
            error,
            'response.data.errors.message',
            'Oops! Something went wrong!'
          ),
        });
        this.setState({ skills_loading: false });
      });
      api
          .get(url["getHobbies"])
          .then(res => {
              const hobbies = _.get(res, 'data.data', [])
              this.setState({ hobbies, hobbies_loading: false });
          })
          .catch(error => {
              notification['error']({
                  message: _.get(error, 'response.data.errors.message', "Oops! Something went wrong!")
              });
              this.setState({ hobbies_loading: false });
          });

        this.setState({ loading: false });
      };

    static getDerivedStateFromProps(props, state) {
        return {
            personal_details: props.personal_details,
      employee_details: props.employee_details,
            employee_id: props.employee_id,
            default_skills:props.default_skills,
            default_hobbies:props.default_hobbies,
            closeModal:props.closeModal,
            company_name:props.company_name
        };
    }
    handleSubmit = e => {
        e.preventDefault();
        this.props.form.validateFieldsAndScroll((err, values) => {
            let arr = [];
            let arr_hobbies = [];

        values.skills.map(el=>{
            arr.push(this.state.skills.filter(val=>val.title===el))
        })
        values.hobbies.map(el=>{
          arr_hobbies.push(this.state.hobbies.filter(val=>val.title===el))
      })
        values.hobbies=[];
        values.skills=[]
        arr.map(val=>values.skills.push(val[0].id));
        arr_hobbies.map(val=>values.hobbies.push(val[0].id));
        values.date_of_exit = values.date_of_exit ? moment(values.date_of_exit).format(
            data_setting['post_date_format']
          ) : null;
          values.date_of_joining = moment(values.date_of_joining).format(
            data_setting['post_date_format']
          );

            if (!err) {
                this.setState({ loading: true });
                this.setState({ editModalLoading:true });
                api.
                    put(url["createContractUser"] + "/" + this.state.employee_id, values)
                    .then(data => {
                        notification['success']({
                            message: "Details updated successfully!"
                        });
                        this.state.closeModal()
                        this.setState({ loading: false,editModalLoading:false });
                    }).catch(error => {
                        const error_code = _.get(error, 'response.status');
                        if (error_code && error_code == '422') {
                            notification['error']({
                                message: Object.values(_.get(error, 'response.data.errors', "Oops! Something went wrong!"))
                            });
                        }
                        else {
                            notification['error']({
                                message: _.get(error, 'response.data.errors.message', "Oops! Something went wrong!")
                            });
                        }
                        this.setState({ loading: false ,editModalLoading:false});
                    });

            }
        });
    };
   disableExit = (current)=>{
      let joinig_date = this.props.form.getFieldValue("date_of_joining");
      if (joinig_date) {
        return current && current < moment(joinig_date).endOf("day").subtract("1", "day");
      } else {
        return false;
      }
    
   }
    render() {
        const { personal_details,employee_details,hobbies,loading,teams_loading,roles_loading,user_roles_id,roles,teams,skills_loading,hobbies_loading,skills,skills_id,default_skills,editModalLoading,company_name,default_hobbies } = this.state;
        const { getFieldDecorator, getFieldValue } = this.props.form;
        return (
          <>
          {editModalLoading && (
        <div
          className="center-me"
          style={{
            position: "absolute",
            top: "0px",
            bottom: "0px",
            left: "0px",
            right: "0px",
            zIndex: "100",
          }}
        >
          <Spin />
        </div>
      )}
            <Fragment>
                <section className="tab-content-container personal-details-tab card-shadow">
                    <Form className="login-form" >
                        <h3 className="title text-primary">Edit Contract Employee Details test</h3>


                        <Row className="" gutter={30}>
              <Col
                className="gutter-row first-half-col"
                xs={24}
                sm={24}
                md={24}
                lg={12}
                xl={12}
              >
                <div className="form-content">
                  <Row className="" gutter={30}>
                    <Col
                      className="gutter-row"
                      xs={24}
                      sm={24}
                      md={24}
                      lg={12}
                      xl={12}
                    >
                      <div className="form-content form-group">
                        <Form.Item label="First Name">
                          {getFieldDecorator('first_name', {
                           initialValue: personal_details ? personal_details.first_name : "",
                           rules: [
                               {
                                   required: true,
                                   message: "Please enter first name!"
                               },
                               {
                                pattern: new RegExp(/^[A-Za-z]+$/),
                                message: "Use Alphabets Only"
                               }
                           ],
                          })(
                            <Input
                              id="first-name"
                              className="input"
                              maxLength="255"
                              placeholder="Enter first name"
                              onBlur={(e) =>
                                (e.target.value = e.target.value.trim())
                              }
                              onChange={()=>this.setState({ saveBtnDisabled: false })}
                            />
                          )}
                        </Form.Item>
                      </div>
                    </Col>
                    <Col
                      className="gutter-row"
                      xs={24}
                      sm={24}
                      md={24}
                      lg={12}
                      xl={12}
                    >
                      <div className="form-content form-group">
                        <Form.Item label="Last Name">
                          {getFieldDecorator('last_name', {
                            initialValue: personal_details ? personal_details.last_name : "",
                            rules: [
                              {
                                required: true,
                                message: 'Enter valid last name',
                              },
                              {
                                pattern: new RegExp(/^[A-Za-z]+$/),
                                message: "Use Alphabets Only"
                               }
                            ],
                          })(
                            <Input
                              id="last-name"
                              className=""
                              maxLength="255"
                              placeholder="Enter last name"
                              onBlur={(e) =>
                                (e.target.value = e.target.value.trim())
                              }
                              onChange={()=>this.setState({ saveBtnDisabled: false })}
                            />
                          )}
                        </Form.Item>
                      </div>
                    </Col>
                  </Row>
                  <Row className="" gutter={30}>
                    <Col
                      className="gutter-row"
                      xs={24}
                      sm={24}
                      md={24}
                      lg={12}
                      xl={12}
                    >
                      <div className="form-content form-group">
                        <Form.Item label="Email (official)">
                          {getFieldDecorator('email_official', {
                              initialValue: employee_details
                              ? employee_details.email_official
                              : "",
                            rules: [
                              {
                                type: 'email',
                                message: 'Enter valid email',
                              },
                              {
                                required: true,
                                message: 'Enter an email',
                              },
                            ],
                          })(
                            <Input
                              id="email-id"
                              type="email"
                              maxLength="255"
                              className="input"
                              placeholder="Enter Official email ID"
                              onBlur={(e) =>
                                (e.target.value = e.target.value.trim())
                              }
                              onChange={()=>this.setState({ saveBtnDisabled: false })}
                            />
                          )}
                        </Form.Item>
                      </div>
                    </Col>

                    <Col className="" xs={24} sm={24} md={24} lg={12} xl={12}>
                      <div className="form-group">
                        <Form.Item label="Mobile Number">
                          {getFieldDecorator('mobile', {
                            initialValue: personal_details ? personal_details.mobile : "",
                            rules: [
                              {
                                required: true,
                                message: 'Please enter phone number!',
                              },
                            ],
                          })(
                            <Input
                              className="input"
                              maxLength="10"
                              minLength="10"
                              placeholder="Enter Mobile No."
                              onBlur={(e) =>
                                (e.target.value = e.target.value.replace(
                                  /\D/g,
                                  ''
                                ))
                              }
                              onChange={()=>this.setState({ saveBtnDisabled: false })}
                            />
                          )}
                        </Form.Item>
                      </div>
                    </Col>
                  </Row>

                  <Row className="" gutter={30}>
                    <Col
                      className="gutter-row"
                      xs={24}
                      sm={24}
                      md={24}
                      lg={12}
                      xl={12}
                    >
                      <div className="form-group">
                        <Form.Item label="Gender">
                          {getFieldDecorator('gender', {
                            initialValue: personal_details ? personal_details.gender == 'male' ? data_setting['gender']['male'] : personal_details.gender == 'female' ? data_setting['gender']['female'] : "" : "",
                            rules: [
                              {
                                required: true,
                                message: 'Select gender',
                              },
                            ],
                          })(
                            <Radio.Group 
                            className="radio-btn"
                            onChange={()=>this.setState({ saveBtnDisabled: false })}
                            >
                              <div className="radio-icon male-icon">
                                <Radio
                                  value={data_setting['gender']['male']}
                                  selected
                                  className="male-radio"
                                >
                                  Male
                                </Radio>
                                <img
                                  className="icon male"
                                  src={male}
                                  alt="male"
                                />
                              </div>
                              <div className="radio-icon female-icon">
                                <Radio
                                  value={data_setting['gender']['female']}
                                  className="female-radio"
                                >
                                  Female
                                </Radio>
                                <img
                                  className="icon female"
                                  src={female}
                                  alt="Female"
                                />
                              </div>
                            </Radio.Group>
                          )}
                        </Form.Item>
                      </div>
                    </Col>
                    <Col
                      className="gutter-row"
                      xs={24}
                      sm={24}
                      md={24}
                      lg={12}
                      xl={12}
                    >
                      <div className="form-content form-group">
                        <div className="emp-type-dropdown dropdown">
                          <Form.Item label="Employment Type">
                          {getFieldDecorator("employment_type", {
                      initialValue:
                        employee_details && employee_details.employment_type
                          ? data_setting["employment"][
                              employee_details.employment_type
                            ]
                          : "",
                    })(
                      <Select
                        className="emp-type-selectbox selectbox"
                        showSearch
                        style={{ width: "100%" }}
                        placeholder="Select Employment Type"
                        optionFilterProp="children"
                        onChange={()=>this.setState({ saveBtnDisabled: false })}
                      >
                        <Option value={data_setting["employment"]["Full-Time"]}>
                          Full Time
                        </Option>
                        <Option
                          value={data_setting["employment"]["Internship"]}
                        >
                          Internship
                        </Option>
                        <Option value={data_setting["employment"]["Contract"]}>
                          Contract
                        </Option>
                      </Select>
                    )}
                          </Form.Item>
                        </div>
                      </div>
                    </Col>
                    <Col
                className="gutter-row"
                xs={24}
                sm={24}
                md={24}
                lg={12}
                xl={12}
                xxl={12}
              >
                <div className="form-content form-group">
                  <Form.Item label="Hobbies" colon={false}>
                    {getFieldDecorator('hobbies', {
                      initialValue: default_hobbies ? default_hobbies.map(val=>val.hobby_name) : [],
                      rules: [
                        {
                          required: false,
                          message: 'Select hobby',
                        },
                      ],
                    })(
                      <Select
                        className="role-selectbox selectbox"
                        showSearch
                        mode="multiple"
                        style={{ width: '100%' }}
                        placeholder="Select Hobby"
                        optionFilterProp="children"
                        loading={hobbies_loading}
                        onChange={()=>this.setState({ saveBtnDisabled: false })}
                      >
                        {hobbies &&
                          hobbies.map((hobby) => (
                            <Option key={hobby.slug} value={hobby.title}>
                              {hobby.title}
                            </Option>
                          ))}
                      </Select>
                    )}
                  </Form.Item>
                </div>
              </Col>

              
                  </Row>
                </div>
              </Col>
              
              <Col
                className="gutter-row second-half-col"
                xs={24}
                sm={24}
                md={24}
                lg={12}
                xl={12}
              >
                <div className="form-content">
                  <Row className="" gutter={30}>
                 
              <Col
                      className="gutter-row"
                      xs={24}
                      sm={24}
                      md={24}
                      lg={12}
                      xl={12}
                    >
                      <div className="form-content form-group">
                        <Form.Item label="Employee ID">
                          {getFieldDecorator('emp_id', {
                              initialValue: employee_details
                              ? employee_details.employee_id
                              : "",
                            rules: [
                              {
                                required: true,
                                message: 'Enter valid employee id',
                              },
                            ],
                          })(
                            <Input
                              id="employee-id"
                              className="input"
                              maxLength="255"
                              placeholder="Enter Employee ID"
                              onBlur={(e) =>
                                (e.target.value = e.target.value.trim())
                              }
                              onChange={()=>this.setState({ saveBtnDisabled: false })}
                            />
                          )}
                        </Form.Item>
                      </div>
                    </Col>

                    <Col
                className="gutter-row"
                xs={24}
                sm={24}
                md={24}
                lg={12}
                xl={12}
                xxl={12}
              >
                <div className="form-content form-group">
                  <Form.Item label="Skills" colon={false}>
                    {getFieldDecorator('skills', {
                      initialValue: default_skills ? default_skills.map(val=>val.skill_name) : [],
                      rules: [
                        {
                          required: true,
                          message: 'Select skill',
                        },
                      ],
                    })(
                      <Select
                        className="role-selectbox selectbox"
                        showSearch
                        mode="multiple"
                        style={{ width: '100%' }}
                        placeholder="Select Skill"
                        optionFilterProp="children"
                        loading={skills_loading}
                        onChange={()=>this.setState({ saveBtnDisabled: false })}
                      >
                        {skills &&
                          skills.map((skill) => (
                            <Option key={skill.slug} value={skill.title}>
                              {skill.title}
                            </Option>
                          ))}
                      </Select>
                    )}
                  </Form.Item>
                </div>
              </Col>
                    
                    <Col
                      className="gutter-row"
                      xs={24}
                      sm={24}
                      md={24}
                      lg={12}
                      xl={12}
                    >
                      <div className="form-content form-group">
                        <Form.Item label="Designation">
                          {getFieldDecorator('designation', {
                              initialValue: employee_details
                              ? employee_details.designation
                              : "",
                            rules: [
                              {
                                required: true,
                                message: 'Enter designation',
                              },
                            ],
                          })(
                            <Input
                              className="input"
                              maxLength="255"
                              placeholder="Enter Designation"
                              onBlur={(e) =>
                                (e.target.value = e.target.value.trim())
                              }
                              onChange={()=>this.setState({ saveBtnDisabled: false })}
                            />
                          )}
                        </Form.Item>
                      </div>
                    </Col>
                    <Col
                      className="gutter-row"
                      xs={24}
                      sm={24}
                      md={24}
                      lg={12}
                      xl={12}
                    >
                      <div className="form-content form-group">
                        <div className="team-dropdown dropdown">
                          <Form.Item label="Team">
                            {getFieldDecorator('team', {
                                initialValue:
                                !teams_loading &&
                                employee_details &&
                                employee_details.team
                                  ? employee_details.team.id
                                  : "",
                              rules: [
                                {
                                  required: true,
                                  message: 'Select team',
                                },
                              ],
                            })(
                              <Select
                                className="team-selectbox selectbox"
                                showSearch
                                style={{ width: '100%' }}
                                placeholder="Select Team"
                                optionFilterProp="children"
                                loading={teams_loading}
                              onChange={()=>this.setState({ saveBtnDisabled: false })}
                              >
                                {teams &&
                                  teams.map((team) => (
                                    <Option key={team.id} value={team.id}>
                                      {team.title}
                                    </Option>
                                  ))}
                              </Select>
                            )}
                          </Form.Item>
                        </div>
                      </div>
                    </Col>
                    <Col
                      className="gutter-row"
                      xs={24}
                      sm={24}
                      md={24}
                      lg={12}
                      xl={12}
                    >
                      <div className="form-content form-group">
                        <div className="role-dropdown dropdown">
                          <Form.Item label="Company Name">
                            {getFieldDecorator('company_name', {
                                initialValue:
                                company_name && company_name,
                              rules: [
                                {
                                  required: true,
                                  message: 'Enter Company Name',
                                },
                              ],
                            })(
                            <Input
                              className="input"
                              maxLength="255"
                              placeholder="Enter Company Name"
                              onBlur={(e) =>
                                (e.target.value = e.target.value.trim())
                              }
                              onChange={()=>this.setState({ saveBtnDisabled: false })}
                            />
                            )}
                          </Form.Item>
                        </div>
                      </div>
                    </Col>
                    <Col
                      className="gutter-row"
                      xs={24}
                      sm={24}
                      md={24}
                      lg={12}
                      xl={12}
                    >
                      <div className="form-content form-group">
                        <div className="role-dropdown dropdown">
                          <Form.Item label="Role">
                            {getFieldDecorator('roles', {
                      initialValue: !roles_loading ? user_roles_id : [],
                              rules: [
                                {
                                  required: true,
                                  message: 'Select role',
                                },
                              ],
                            })(
                              <Select
                                className="role-selectbox selectbox"
                                showSearch
                                mode="multiple"
                                style={{ width: '100%' }}
                                placeholder="Select Role"
                                optionFilterProp="children"
                                loading={roles_loading}
                              onChange={()=>this.setState({ saveBtnDisabled: false })}
                              >
                                {roles &&
                                  roles.map((role) => (
                                    <Option key={role.id} value={role.id}>
                                      {role.name
                                        .replace('_', ' ')
                                        .toUpperCase()}
                                    </Option>
                                  ))}
                              </Select>
                            )}
                          </Form.Item>
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <Row className="" gutter={30}>
                    <Col
                      className="gutter-row"
                      xs={24}
                      sm={24}
                      md={24}
                      lg={12}
                      xl={12}
                    >
                      <div className="form-content form-group">
                        <Form.Item label="Onboarding Date">
                          {getFieldDecorator('date_of_joining', {
                              initialValue:
                              employee_details && employee_details.date_of_joining
                                ? moment(employee_details.date_of_joining)
                                : "",
                            rules: [
                              {
                                type: 'object',
                                required: true,
                                message: 'Please select onboarding date!',
                              },
                            ],
                          })(
                            <DatePicker
                              format={data_setting['date_format']}
                              disabledDate={(current) => {
                                return current > moment();
                              }}
                              onChange={()=>this.setState({ saveBtnDisabled: false })}
                            />
                          )}
                        </Form.Item>
                      </div>
                    </Col>
                    <Col
                      className="gutter-row"
                      xs={24}
                      sm={24}
                      md={24}
                      lg={12}
                      xl={12}
                    >
                      <div className="form-content form-group">
                        <Form.Item label="Contract Closure Date">
                          {getFieldDecorator('date_of_exit', {
                              initialValue:
                              employee_details && employee_details.date_of_exit
                                ? moment(employee_details.date_of_exit)
                                : "", 
                            rules: [
                              {
                                type: 'object',
                                required: false,
                                message: 'Please select Contract Closure Date!',
                              },
                            ],
                          })(
                            <DatePicker
                              format={data_setting['date_format']}
                              disabledDate={this.disableExit}
                              onChange={()=>this.setState({ saveBtnDisabled: false })}
                            />
                          )}
                        </Form.Item>
                      </div>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
            <section className="profile-save-btn-box mt--30">
                        <div className="save-btn-box text-right">
                            <Button className="btn btn-success btn-wide text-uppercase" onClick={this.handleSubmit} loading={loading} disabled={this.state.saveBtnDisabled}>Save</Button>
                        </div>
                    </section>
                    </Form>
                </section>

            </Fragment>
            </>
        )
    }
}


export default Form.create({ name: 'personal_details_form' })(ForAdminAndHr(withStore(AdminEditContractDetails)));