import React, { Component, Fragment } from 'react';
import { Row, Col } from 'antd';
import text from '../../../config/text';

export default class ViewBankDetails extends Component {
  constructor(props) {
    super(props);
  }

  static getDerivedStateFromProps(props, state) {
    return {
      bank_details: props.bank_details,
    };
  }
  render() {
    const { bank_details } = this.state;
    const {
      account_number = '-',
      ifsc = '-',
      bank_name = '-',
      pf_deduction = '-',
      esi_deduction = '-',
      aadhar_number = '-',
      pan_number = '-',
    } = bank_details || {};
    const fallback = '-';
    return (
      <Fragment>
        <section className="tab-content-container bank-details-tab">
          <h3 className="title text-primary">User Bank Details</h3>
          <Row className="" gutter={30}>
            <Col className="gutter-row" xs={24} sm={24} md={24} lg={6} xl={6}>
              <div className="form-content form-group">
                <label className="label">Account Number</label>
                <div className="value">{account_number || fallback}</div>
              </div>
            </Col>
            <Col className="gutter-row" xs={24} sm={24} md={24} lg={6} xl={6}>
              <div className="form-content form-group">
                <label className="label">Bank Name</label>
                <div className="value">{bank_name || fallback}</div>
              </div>
            </Col>
            <Col className="gutter-row" xs={24} sm={24} md={24} lg={6} xl={6}>
              <div className="form-content form-group">
                <label className="label">IFSC Code</label>
                <div className="value">{ifsc || fallback}</div>
              </div>
            </Col>
            <Col className="gutter-row" xs={12} sm={12} md={12} lg={3} xl={3}>
              <div className="form-content form-group">
                <label className="label">PF Deduction</label>
                <div className="value">{text[pf_deduction] || fallback}</div>
              </div>
            </Col>
            <Col className="gutter-row" xs={12} sm={12} md={12} lg={3} xl={3}>
              <div className="form-content form-group">
                <label className="label">ESI Deduction</label>
                <div className="value">{text[esi_deduction] || fallback}</div>
              </div>
            </Col>
          </Row>

          <h3 className="title text-primary">Other Financial Details</h3>
          <Row className="" gutter={30}>
            <Col className="gutter-row" xs={24} sm={24} md={24} lg={6} xl={6}>
              <div className="form-content form-group">
                <label className="label">Aadhar Number</label>
                <div className="value">{aadhar_number || fallback}</div>
              </div>
            </Col>
            <Col className="gutter-row" xs={24} sm={24} md={24} lg={6} xl={6}>
              <div className="form-content form-group">
                <label className="label">PAN Number</label>
                <div className="value">{pan_number || fallback}</div>
              </div>
            </Col>
          </Row>
        </section>
      </Fragment>
    );
  }
}
