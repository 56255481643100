import React, { Component, Fragment } from "react";
import { Link } from 'react-router-dom';
import { Layout, Button, Col, Row } from "antd";
import { Helmet } from 'react-helmet'
import lottie from 'lottie-web';
import animationData from '../../../assets/animation/json/404-error';

const TITLE = '404 Page not found'

// for lottie
let animObj = null;

export default class Error404 extends Component {

    // for lottie
    componentDidMount() {
        //call the loadAnimation to start the animation
        animObj = lottie.loadAnimation({
            container: this.animBox, // the dom element that will contain the animation
            renderer: 'svg',
            loop: true,
            autoplay: true,

            animationData: animationData // the path to the animation json
        });
    }

    render() {
        return (
            <Fragment>
                <Helmet>
                    <title>{TITLE}</title>
                </Helmet>
                <div className="error-container">
                    <Row>
                        <Col sm={16}>
                            <div className="errorPage-content">
                                <div className="error-box">
                                    <div className="oops-content bold fontSize-lg">oops!</div>
                                    <div className="error-no fontSize-xxl">404</div>
                                    <div className="error-message fontSize-lg">Page not found</div>
                                    <p className="error-note fontSize-md">We can't find the page that you're looking for.</p>
                                    <Link to="/dashboard/home" className="btn btn-light-primary text-uppercase back-btn">Back to Home</Link>
                                </div>
                            </div>
                        </Col>
                        <Col sm={8}>
                            <div className="lottie-aminationContainer">
                                <div className="lottie-amination" ref={ref => this.animBox = ref}></div>
                            </div>
                        </Col>
                    </Row>
                </div>
            </Fragment>
        );
    }
}
