export const GET_TEAM_DIRECTORY_LIST = "/employees?hidden_employees=true&q=";
export const GET_COMPLETE_TEAM_DIRECTORY_LIST = "/employees";
export const GET_EXISTING_TEAM_DIRECTORY_LIST =
  "/employees";
  export const GET_EXISTING_TEAM_DIRECTORY_LIST_CURRENT =
  "/employees?hidden_employees=true&disable=false";
  export const GET_EXISTING_TEAM_DIRECTORY_LIST_CURRENT_DISABLE =
  "/employees?hidden_employees=true&disable=true&is_x=true";
  export const GET_EXISTING_TEAM_DIRECTORY_LIST_ATTENDANCE =
  "/employees?paginate=true&page=:page&user_id=:user_id&six_month_ex=true";
  export const GET_NOTIFICATION_LIST =
  "/announcements/all?page=:page&notification_id=:notification_id&q=:q&paginate=1";
  export const GET_NOTIFICATION_ID =
  "/announcements?page=:page&notification_id=:notification_id&q=:q&paginate=1";
  export const GET_EMPLOYEE_NOTIFICATION =
  "/announcements/:id";
  export const HANDLE_CURRENT_NOTIFICATION =
  "/announcements/:id/update-status";
  
export const GET_ALL_LEAVE_TYPES = "/leave-types/data?o_id=1";
export const GET_PAGINATED_TEAM_DIRECTORY_LIST =
  "/employees?hidden_employees=:employeeFilter&paginate=true&page=:page&q=:query&skill=:skill&hobbies=:hobby&level=:level&team=:team";
export const REACT_APP_FCM_DATA_URL = "fcm";

export const ADD_ORG_SKILL = "/skills";
export const EDIT_ORG_SKILL = "/skills/:slug";
export const DELETE_ORG_SKILL = "/skills/:slug";
export const GET_ORG_SKILL = "/skills?paginate=:paginate&page=:page&q=:q";

export const ADD_ORG_TEAM = "/teams";
export const EDIT_ORG_TEAM = "/teams/:slug";
export const DELETE_ORG_TEAM = "/teams/:slug";
export const GET_ORG_TEAM = "/teams?paginate=true&page=:page";

export const ADD_ORG_LEVEL = "/levels";
export const EDIT_ORG_LEVEL = "/levels/:slug";
export const DELETE_ORG_LEVEL = "/levels/:slug";
export const GET_ORG_LEVEL = "/levels?paginate=true&page=:page";

export const ADD_INTERCOM = "/intercoms";
export const UPDATE_INTERCOM = "/intercoms/:slug";
export const DELETE_INTERCOM = "/intercom/:slug";
export const GET_INTERCOM = "/intercom";

export const ADD_ASSET_TYPES = "/asset-types";
export const DELETE_ASSET_TYPES = "/asset-types/:id";
export const GET_PAGINATED_ASSET_TYPES =
  "/asset-types?paginate=:paginate&page=:page";
export const GET_ASSET_TYPES = "/asset-types";

export const GET_ASSETS =
  "/assets?includes=images,allocations:allocated_images&status=:status&type_id=:type_id&allocated_to_self=:self&q=:q&page=:page&is_issuable=:is_issuable";
export const ADD_ASSETS = "/assets";
export const UPDATE_ASSETS = "/assets/:uuid";
export const DELETE_ASSETS = "/assets/:uuid";
export const GET_EMPLOYEE_ALLOCATED_ASSETS =
  "/assets/employees/:id?includes=images,allocations,allocated_images&type_id=:type_id&q=:q&page=:page";
export const GET_ASSETS_HISTORY =
  "/assets/:id/history?includes=allocated_images&page=:page";
export const ALLOCATE_DEALLOCATE_ASSET = "/assets/:id/allocate?QrScan=:qr_scan";

export const GET_DOCUMENT_FOLDERWISE =
  "/documents?folder_slug=:slug&q=:q&page=:page";
export const ADD_DOCUMENT = "documents/create";
export const DELETE_DOCUMENT = "/documents/:slug";
export const UPDATE_DOCUMENT = "/documents/:slug";

export const GET_FOLDER = "/document-folder?paginate=:pagination&page=:page";
export const GET_A_FOLDER = "/document-folder/:slug";
export const ADD_FOLDER = "/document-folder";
export const DELETE_FOLDER = "/document-folder/:slug";
export const UPDATE_FOLDER = "/document-folder/:slug";

export const GET_EMPLOYEE_WORK_REPORTS = "users/work-reports/self?page=:page";
export const GET_WORK_REPORTS_FOR_ADMIN =
  "users/work-reports?include=work_reports&date=:date&page=:page&user_id=:user_id";
export const GET_WORK_REPORTS_FOR_ADMIN_OF_AN_EMPLOYEE =
  "users/:user_id/work-reports?include=work_reports&from_date=:from_date&to_date=:to_date&page=:page";
export const ADD_NEW_WORK_REPORT_FOR_EMPLOYEE = "users/work-reports/new";
export const GET_ALL_EMPLOYEE_LEAVE_LIST =
  "leaves/date-wise-search?o_id=1&date=:date&type=:type";
export const GET_FILTERABLE_EMPLOYEES_FOR_WORK_REPORTS =
  "work-reports/filterable-users";

export const ADD_ORG_PROJECT = "/projects";
export const DELETE_ORG_PROJECT = "/projects/:uuid";
export const GET_ORG_PROJECT = "/projects?page=:page&bulk=:bulk&include=others";

export const CREATE_LEAVE_TYPES = "leave-types?type=:leave_type";
export const DAYS_LEAVE_CALCULATION = "leaves/days-calculation";
export const LEAVE_TYPE_DATA = "leave-types?o_id=:id&page=:page";
export const GET_LEAVE_DETAILS = "/leave-types/:id?o_id=:organizationId";
export const UPDATE_LEAVE_TYPE = "/leave-types/:leave_type_id";
export const LEAVE_TYPE_LIST = "/leave-types";
export const LIST_OF_LEAVES = "/leaves";
export const LIST_OF_ASSIGN_LEAVES = "/leave-types/data?o_id=1";
export const LIST_OF_LEAVES_TYPES = "/leave-types";
export const ASSIGN_LEAVE = "/leave-types/assign-leave";
export const LIST_OF_EMPLOYEE_LEAVES = "/employees/leave-type/list";
export const LIST_OF_LEAVES_FOR_OTHER_EMPLOYEE =
  "/employees/leave-type/list?o_id=:id&user_id=:user_id";
export const LIST_OF_ACTIVE_EMPLOYEE_LEAVES =
  "/employees/leave-type/list?is_active=1";
export const LIST_OF_ACTIVE_LEAVES_FOR_OTHER_EMPLOYEE =
  "/employees/leave-type/list?o_id=:id&user_id=:user_id&is_active=1";
export const APPLY_FOR_EMPLOYEE_LEAVES = "/employees/leaves";
export const HANDLE_CREATE_NOTIFICATION = "/announcements";
export const HANDLE_EDIT_NOTIFICATION = "/announcements/:id";
export const ASSIGN_LEAVES = "/leaves";
export const ASSIGN_Updated_LEAVES = "/leaves/:id";
export const SELF_LIST_OF_LEAVE_REQUEST =
  "/employees/leaves/list?is_active=1&o_id=:id&year=:year&page=:pageNumber";
export const GET_DATES_CALCULATED =
  "/leaves/days-calculation?o_id=1&from_date=:date1&to_date=:date2";
export const MANAGE_LIST_OF_LEAVE =
  "/leave-types/employees-list?o_id=:id&page=:page_number&year=:year&user_ids[]=:user_id&q=:search";
export const SEARCH_LIST_OF_LEAVE =
  "/leave-types/employees-list?o_id=:id&page=:page_number&q=:searchquery";
export const LEAVE_ACTION = "/leaves/update-status/:id";
export const GET_EMPLOYEE_LEAVE_DETAILS =
  "/employees/leave-type/list?o_id=:id&user_id=:user_id&year=:year";
  export const GET_PROJECT_LIST =
  "/projects?page=:page";
  export const GET_EMPLOYEE_PROJECT_LIST =
  "/projects/employees-project?paginate=1&page=:page";
  export const GET_EMPLOYEE_PROJECT_DETAILS =
  "/employees/projects/:id";
  export const GET_SKILLS = "/skills";
  export const GET_PROJECT_DETAILS = "/projects/:id"
  export  const GET_PROJECT_USER_DETAILS  = "/projects/:id/users?page=:page";
 export const  GET_USER_PROJECTS_DETAILS = "/employees/:id/projects?page=:page";
  export const GET_PROJECT_DETAILS_EDIT = "/projects/:id/edit"
  export const DELETE_PROJECT_DETAILS = "/projects/:id"
  export const GET_PROJECT_TEAM_DETAILS = "/projects/:id/update-team"
  export const CREATE_PROJECT = "/projects"
  export const SUGGESTED_EMPLOYEES = "/skills/suggest/employee?skill_ids=:skills&page=:page"


export const ADD_HOBBY = "/hobbies";
export const EDIT_HOBBY = "/hobbies/:slug";
export const DELETE_HOBBY = "/hobbies/:slug";
export const BILL = '/bill'
export const UPDATE_BILL = '/bill/update-status'
export const GET_HOBBY = "/hobbies?includes=images&paginate=:paginate&page=:page&q=:q";

export const GET_SELF_ATTENDANCE = "/attendance/self?month=:month&page=:page&status=:status";
export const GET_SELF_ATTENDANCE_BY_ID = "/attendance/:id?month=:month&page=:page&status=:status";

export const GET_EXISTING_TEAM_DIRECTORY_LIST_WITH_SIX_MONTH_EX_EMPLOYEES =
"/employees?six_month_ex=true";
