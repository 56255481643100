import React, { Component, Fragment } from "react";
import { Layout } from "antd";
import { Helmet } from 'react-helmet'
import Holiday from "./components/Holiday";

const TITLE = 'Public holidays'

export default class HolidayManagement extends Component {
  render() {
    return (
      <Fragment>
        <Helmet>
          <title>{TITLE}</title>
        </Helmet>
        <div className="page-wrapper">
          <Holiday></Holiday>
        </div>
      </Fragment>
    );
  }
}
