import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { withStore } from './withStore';
import RequireAuth from './RequireAuth';
import { notification } from 'antd';

export default ChildComponnent => {
  class ProfileComplete extends Component {
    constructor() {
      super();
    }
    render() {
      const { store: { data: { is_profile_complete, user_roles_name } } } = this.props;

      if (is_profile_complete || user_roles_name.indexOf("admin") != -1) {
        return <ChildComponnent {...this.props} />;
      } else {
        notification['error']({
          message: "Please complete your profile to continue!"
        });
        return <Redirect to="/dashboard/profile" />;
      }
    }
  }

  return withStore(RequireAuth(ProfileComplete));
};
