import React, { Fragment, useEffect, useState } from "react";
import { Spin,Row,Form,Input,Col,DatePicker,Button,Select,notification,Modal,Tooltip } from "antd";
import { getSkillsData } from "../../../services/ProjectService";
import TextArea from "antd/lib/input/TextArea";
import {createProject,updateProject,getSuggestedEmployees} from "../../../services/ProjectService";
import moment from "moment";
import _ from "lodash";
import SuggestedEmpView from "./SuggestedEmpView";

const AdminEditProjectDetails = (props) => {
  var initialLoading = props.loading;
  const autoUpdate = props.autoUpdate;
  const new_project = props.new_project;
  const closeModal = props.closeModal;
  const defaultData = props.data;
  const onSave = props.onSave;
  const { getFieldDecorator, validateFieldsAndScroll, setFields, getFieldValue } = props.form;
  const [loading, setLoading] = useState(false);
  const [btnDisable, setBtnDisable] = useState(false);
  const [skillsEmp, setSkillsForSuggestedEmployees] = useState("");
  const [suggestedEmpShortList, setSuggestedEmpShortList] = useState("");
  const [suggestModalVisible, setSuggestModalVisible] = useState(false);
  const [suggestEmpLoading, setSuggestEmpLoading] = useState(false);
  const [suggestedEmpList, setSuggestedEmpList] = useState("");

  const disabledDate = (current) => {
    let end_date = defaultData && defaultData.end_date ? defaultData.end_date : "";
    let deli_date = getFieldValue("next_deliverable_date");
    if (getFieldValue("end_date")) {
      end_date = getFieldValue("end_date");
    }
    if (deli_date) {
      return current && current > moment(deli_date).endOf("day");
    } else if (end_date) {
      return current && current > moment(end_date).endOf("day");
    } else {
      return false;
    }
  };

  const disabledendDate = (current) => {
    let start_date = defaultData && defaultData.start_date ? defaultData.start_date : "";
    if (getFieldValue("start_date")) {
      start_date = getFieldValue("start_date");
    }
    let deli_date = getFieldValue("next_deliverable_date");
    if (deli_date) {
      return current && current < moment(deli_date).endOf("day");
    } else if (start_date) {
      return current < moment(start_date).endOf("day").subtract("1", "day");
    } else {
      return false;
    }
  };
  const disabledDateNext = (current) => {
    let start_date = getFieldValue("start_date");
    let end_date = getFieldValue("end_date");
    return (
      current &&
      ((start_date && current < start_date) ||
        (end_date && current > moment(end_date).endOf("day")))
    );
  };

  const [skillIds, setSkillIds] = useState([]);
  const [formData, setFormData] = useState({
    notes: "",
    skills: null,
    start_date: "",
    end_date: "",
    title: "",
    project_type: "",
  });

  useEffect(() => {
    if (defaultData && defaultData.skills) {
      setSkillIds(defaultData.skills.map((val) => val.id));
      getSkills();
    }
  }, [defaultData]);

  const [formData1, setFormData1] = React.useState({
    skills_loading: false,
  });
  const { Option } = Select;

  const getSkills = () => {
    setFormData1((prevData) => {
      return {
        ...prevData,
        skills_loading: true,
      };
    });
    getSkillsData().then((res) => {
      setFormData((prevData) => {
        return {
          ...prevData,
          skills: res && res.data && res.data.data,
        };
      });
      setFormData1((prevData) => {
        return {
          ...prevData,
          skills_loading: false,
        };
      });
    });
  };

  useEffect(() => {
    getSkills();
  }, []);

  const onSubmit = (e) => {
    e.preventDefault();
    validateFieldsAndScroll((err, values) => {
      if(values.start_date){
      formData.start_date = values.start_date.format("YYYY-MM-DD");
      }else{
        formData.start_date="";
      }
      if (values.end_date) {
        formData.end_date = values.end_date.format("YYYY-MM-DD");
      }else{
        formData.end_date="";
      }
      if (values.next_deliverable_date) {
        formData.next_deliverable_date = values.next_deliverable_date.format("YYYY-MM-DD");
      }
      formData.title = values.title;
      formData.project_type = values.project_type;
      if (values.next_deliverable_notes) {
        formData.next_deliverable_notes = values.next_deliverable_notes;
      }
      if(values.notes){
        formData.notes = values.notes; 
      }else{
        formData.notes="";
      }
    
      if (values.skills) {
        formData.skills = values.skills;
      } else {
        formData.skills = [];
      }
      formData.status = values.status;

      if (!err) {
        setBtnDisable(true);
        setLoading(true);
        if (new_project) {
          createProject(formData)
            .then((res) => {
              notification.success({
                message: "Created successfully",
              });
              closeModal();
              setLoading(false);
              autoUpdate();
              setBtnDisable(false);
            })
            .catch((error) => {
              getSkills();
              setLoading(false);
              if (error && error.response && error.response.status) {
                if (error.response.status === 422) {
                  let errors = error.response.data.errors;
                  let errorObject = {};
                  Object.keys(errors).forEach((key) => {
                    let err = [];
                    errors[key].forEach((value) => {
                      err.push(new Error(value));
                    });

                    errorObject[key] = { value: formData[key], errors: err };
                  });
                  setFields(errorObject);
                }
              } else if (
                error &&
                error.response &&
                error.response.data &&
                error.response.data.errors
              ) {
                notification.error(error.response.data.errors);
              }
              setBtnDisable(false);
            });
        } else {
          updateProject(formData, defaultData.id)
            .then((res) => {
              notification.success({
                message: "Updated successfully",
              });
              // closeModal();
              setLoading(false);
              onSave();
              //autoUpdate();
              setBtnDisable(false);
            })
            .catch((error) => {
              getSkills();
              setLoading(false);
              if (error && error.response && error.response.status) {
                if (error.response.status === 422) {
                  let errors = error.response.data.errors;
                  let errorObject = {};
                  Object.keys(errors).forEach((key) => {
                    let err = [];
                    errors[key].forEach((value) => {
                      err.push(new Error(value));
                    });

                    errorObject[key] = { value: formData[key], errors: err };
                  });
                  setFields(errorObject);
                }
              } else if (
                error &&
                error.response &&
                error.response.data &&
                error.response.data.errors
              ) {
                notification.error(error.response.data.errors);
              }
              setBtnDisable(false);
            });
        }
      }
    });
  };

  const handleSuggestedEmployees = (val) => {
    setSuggestEmpLoading(true);
    let skills = val.map((el) => el).join(",");
    setSkillsForSuggestedEmployees(skills);
    getSuggestedEmployees(1, skills).then((res) => {
      let arr = [];
      res.data.data.map((el, index) => {
        if (index < 7) {
          arr.push(el.name);
        }
      });
      setSuggestedEmpShortList(arr);
      setSuggestedEmpList(res.data);
      setSuggestEmpLoading(false);
    });
  };
  useEffect(() => {
    if (defaultData && defaultData.skills && defaultData.skills.length) {
      handleSuggestedEmployees(defaultData.skills.map((val) => val.id));
    }
  }, [defaultData]);

  return (
    <Fragment>
      <div className="add-new-profile-modal-container">
        <section>
          {loading || initialLoading ? (
            <div className="page-spinner">
              <Spin />
            </div>
          ) : (
            <>
              <form>
                <Row className="row" gutter={30}>
                  <Col
                    className="gutter-row first-half-col"
                    xs={24}
                    sm={24}
                    md={24}
                    lg={24}
                    xl={24}
                  >
                    <Row gutter={30}>
                      <Col className="gutter-row" xs={24} sm={24} md={24} lg={12} xl={12}>
                        <Form.Item label="Title:">
                          {getFieldDecorator("title", {
                            initialValue: defaultData && defaultData.title ? defaultData.title : "",
                            rules: [
                              {
                                required: true,
                                message: "Enter a valid Title",
                              },
                            ],
                          })(
                            <Input
                              type="text"
                              className="input"
                              name="name"
                              placeholder="Enter Title"
                            />
                          )}
                        </Form.Item>
                      </Col>
                      <Col className="gutter-row" xs={24} sm={24} md={24} lg={12} xl={12}>
                        <div className="form-content form-group">
                          <div className="emp-type-dropdown dropdown">
                            <Form.Item label="Project Type">
                              {getFieldDecorator("project_type", {
                                initialValue:
                                  defaultData && defaultData.project_type
                                    ? defaultData.project_type
                                    : "",
                                rules: [
                                  {
                                    required: true,
                                    message: "Select project type",
                                  },
                                ],
                              })(
                                <Select
                                  className="emp-type-selectbox selectbox"
                                  showSearch
                                  style={{ width: "100%" }}
                                  placeholder="Select Project Type"
                                  optionFilterProp="children"
                                >
                                  <Option value={1}>EMP</Option>
                                  <Option value={2}>IMP</Option>
                                  <Option value={3}>INTERNAL</Option>
                                  <Option value={4}>RESEARCH</Option>
                                </Select>
                              )}
                            </Form.Item>
                          </div>
                        </div>
                      </Col>
                      <Col className="gutter-row" xs={24} sm={24} md={24} lg={12} xl={12}>
                        <Form.Item label="Start Date">
                          {getFieldDecorator("start_date", {
                            initialValue:
                              defaultData && defaultData.start_date
                                ? moment(defaultData.start_date)
                                : "",
                            rules: [
                              {
                                required: false,
                                message: "Select a valid date",
                              },
                            ],
                          })(<DatePicker className="ml-2" disabledDate={disabledDate} />)}
                        </Form.Item>
                      </Col>
                      <Col className="gutter-row" xs={24} sm={24} md={24} lg={12} xl={12}>
                        <Form.Item label="End Date">
                          {getFieldDecorator("end_date", {
                            initialValue:
                              defaultData && defaultData.end_date
                                ? moment(defaultData.end_date)
                                : "",
                            rules: [
                              {
                                required: false,
                                message: "Select a valid date",
                              },
                            ],
                          })(<DatePicker className="ml-2" disabledDate={disabledendDate} />)}
                        </Form.Item>
                      </Col>

                      <Col className="gutter-row" xs={24} sm={24} md={24} lg={16} xl={16}>
                        <div className="form-content form-group">
                          <Form.Item label="Skills" colon={false}>
                            {getFieldDecorator("skills", {
                              initialValue:
                                defaultData && defaultData.skills
                                  ? defaultData.skills.map((val) => val.id)
                                  : [],
                              rules: [
                                {
                                  required: false,
                                  message: "Select skill",
                                },
                              ],
                            })(
                              <Select
                                className="role-selectbox selectbox"
                                showSearch
                                mode="multiple"
                                style={{ width: "100%" }}
                                placeholder="Select Skill"
                                optionFilterProp="children"
                                loading={formData1.skills_loading}
                                onChange={(val) => {
                                  setSkillIds(val);
                                  if (!val.length) {
                                    setSuggestedEmpList(val);
                                  } else if (val.length) {
                                    handleSuggestedEmployees(val);
                                  }
                                }}
                              >
                                {formData.skills &&
                                  formData.skills.map((skill) => (
                                    <Option key={skill.slug} value={skill.id}>
                                      {skill.title}
                                    </Option>
                                  ))}
                              </Select>
                            )}
                          </Form.Item>
                        </div>
                      </Col>

                      <Col className="gutter-row" xs={24} sm={24} md={24} lg={8} xl={8}>
                        <div className="form-content form-group">
                          <div className="emp-type-dropdown dropdown">
                            <Form.Item label="Status">
                              {getFieldDecorator("status", {
                                initialValue:
                                  defaultData && (defaultData.status || defaultData.status == 0)
                                    ? defaultData.status
                                    : "",
                                rules: [
                                  {
                                    required: true,
                                    message: "Select status",
                                  },
                                ],
                              })(
                                <Select
                                  className="emp-type-selectbox selectbox"
                                  showSearch
                                  style={{ width: "100%" }}
                                  placeholder="Select Project Type"
                                  optionFilterProp="children"
                                >
                                  <Option value={0}>Pending</Option>
                                  <Option value={1}>Active</Option>
                                  <Option value={2}>On Hold</Option>
                                  <Option value={3}>Completed</Option>
                                  <Option value={4}>Cancelled</Option>
                                </Select>
                              )}
                            </Form.Item>
                          </div>
                        </div>
                      </Col>
                      {suggestedEmpShortList.length && skillIds.length ? (
                        <Col
                          className="gutter-row"
                          style={{ marginBottom: "2rem", display: "flex" }}
                          xs={24}
                          sm={24}
                          md={24}
                          lg={24}
                          xl={24}
                        >
                          <Form.Item label="Suggested Employees:" colon={false}>
                            <Row gutter={1}>
                              <Col className="gutter-row" xs={24} sm={24} md={19} lg={19} xl={19}>
                                {/* <ol type="1" style={{maxHeight:"100px",overflow:'auto',display:"inline-block"}}> */}
                                {/* {suggestedEmpList.map((el,index)=>index<7 ? el.name : null).join(", ")} */}
                                {suggestedEmpShortList.join(", ")}
                                {/* </ol> */}
                              </Col>
                              <Col className="gutter-row" xs={24} sm={24} md={4} lg={4} xl={4}>
                                <Tooltip placement="bottom" title={"Full Screen"}>
                                  <Button
                                    onClick={() => {
                                      // handleSuggestedEmployees();
                                      setSuggestModalVisible(true);
                                    }}
                                    className="btn btn-light-primary btn-icon text-uppercase"
                                  >
                                    {/* <Icon type="eye" theme="filled" /> */}
                                    More
                                  </Button>
                                </Tooltip>
                              </Col>
                            </Row>
                          </Form.Item>
                        </Col>
                      ) : null}
                      <Col className="gutter-row" xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                        <div className="form-content form-group">
                          <Form.Item label="Project Description" colon={false}>
                            {getFieldDecorator("notes", {
                              initialValue:
                                defaultData && defaultData.notes ? defaultData.notes : "",
                              rules: [
                                {
                                  required: false,
                                  message: "Please Enter Project Description",
                                },
                              ],
                            })(<TextArea />)}
                          </Form.Item>
                        </div>
                      </Col>
                      <Col className="gutter-row" xs={24} sm={24} md={24} lg={8} xl={8}>
                        <Form.Item label="Next Deliverable Date">
                          {getFieldDecorator("next_deliverable_date", {
                            initialValue:
                              defaultData && defaultData.next_deliverable_date
                                ? moment(defaultData.next_deliverable_date)
                                : "",
                            rules: [
                              {
                                required: false,
                                message: "Select a valid date",
                              },
                            ],
                          })(<DatePicker className="ml-2" disabledDate={disabledDateNext} />)}
                        </Form.Item>
                      </Col>
                      <Col className="gutter-row" xs={24} sm={24} md={24} lg={16} xl={16}>
                        <div className="form-content form-group">
                          <Form.Item label="Next Deliverable Notes" colon={false}>
                            {getFieldDecorator("next_deliverable_notes", {
                              initialValue:
                                defaultData && defaultData.next_deliverable_notes
                                  ? defaultData.next_deliverable_notes
                                  : "",
                              rules: [
                                {
                                  required: false,
                                  message: "Please Enter Notes",
                                },
                              ],
                            })(<TextArea />)}
                          </Form.Item>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <div style={{ marginTop: "1rem", width: "100%", textAlign: "right" }}>
                          <Button className="btn-primary" onClick={onSubmit} disabled={btnDisable}>
                            {new_project && "Submit"}
                            {!new_project && "Save & Continue"}
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </form>
              <div></div>
            </>
          )}
        </section>
      </div>
      <Modal
        className="view-profile-modal profileAP-modal"
        destroyOnClose={true}
        centered
        title={"Suggested Employee List"}
        visible={suggestModalVisible}
        onCancel={() => setSuggestModalVisible(false)}
        footer={null}
      >
        <div className="view-profile-modal-container">
          <SuggestedEmpView closeModal={() => setSuggestModalVisible(false)} skills={skillsEmp} />
        </div>
      </Modal>
    </Fragment>
  );
};

export default Form.create()(AdminEditProjectDetails);
