import React, { useState } from "react";
import {
  Icon,
  Button,
  Modal,
  Select,
  Tooltip,
  Spin,
  notification,
  Table,
  Form,
  Input,
} from "antd";
import _ from "lodash";
import male from "../../../assets/images/user/male.svg";
import url from "../../../config/url";
import { axiosInstance as api } from "../../../utilities/configureAxios";
import ForAdminAndHr from "../../../hocs/ForAdminAndHr";
import { withStore } from "../../../hocs/withStore";

import {
  getExistingTeamDirectoryListServiceCurr,
  getOrganizationEmployeesAttendance,
} from "../../../services/TeamDirectoryService";
import EmployeeAttendance from "./EmployeeAttendance";

const AllAttendance = (props) => {
  const [uploaded_file, setUploadedFile] = useState(null);
  // year selectbox
  const { Option } = Select;
  const { getFieldDecorator } = props.form;
  const autoUpdate = props.autoUpdate;

  const [loader, setLoader] = useState(false);
  const [allEmployees, setAllEmployees] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [employeeId, setEmployeeId] = useState(undefined);
  const [employeeName, setEmployeeName] = useState(undefined);
  const [pageNumber, setPageNumber] = useState(1);
  const [employeesList, setEmployeesList] = useState([]);
  const [viewModalVisible, setViewModalVisible] = useState(false);
  const [viewModalLoading, setViewModalLoading] = useState(false);
  const [uploadModalVisible, setUploadModalVisible] = useState(false);

  // Handling Pagination
  const handlePagination = (pagination) => {
    setPageNumber(pagination.current);
  };

  const handleOk = (e) => {
    e.preventDefault();
    props.form.validateFields(["file"], (err, formData) => {
      if (!err) {
        var form_data = new FormData();
        if (formData.file) {
          form_data.append("file", uploaded_file);
        }
        setLoader(true);
        api
          .post(url["uploadAttendence"], form_data)
          .then((res) => {
            setUploadModalVisible(false);
            loadOrganizationEmployeesList(allEmployees, pageNumber);
            setLoader(false);
            notification["success"]({
              message: "successful!",
            });
          })
          .catch((error) => {
            const error_code = _.get(error, "response.status");
            if (error_code && error_code == "422") {
              notification["error"]({
                message: "Invalid Data",
                description: Object.values(
                  _.get(
                    error,
                    "response.data.errors",
                    "Oops! Something went wrong!"
                  )
                ),
              });
            } else {
              notification["error"]({
                message: _.get(
                  error,
                  "response.data.errors.message",
                  "Oops! Something went wrong!"
                ),
              });
              setUploadModalVisible(false);
            }
            setLoader(false);
          });
      }
    });
  };

  // SETTING EMPLOYEE STATE
  const handleEmployee = (value) => {
    setPageNumber(1);
    setAllEmployees(value);
  };

  // FETCHING ALL EMPLOYEES NAMES
  const loadOrganizationEmployees = () => {
    return getExistingTeamDirectoryListServiceCurr()
      .then((res) => {
        setEmployees(res.data.data);
      })
      .catch((error) => {
        if (
          error.response &&
          error.response.data &&
          error.response.data.errors
        ) {
          notification.error(error.response.data.errors);
        }
      });
  };
  React.useEffect(() => {
    loadOrganizationEmployees();
  }, []);

  const loadOrganizationEmployeesList = () => {
    return getOrganizationEmployeesAttendance(allEmployees, pageNumber)
      .then((res) => {
        setEmployeesList(res);
        setLoader(false);
      })
      .catch((error) => {
        if (
          error.response &&
          error.response.data &&
          error.response.data.errors
        ) {
          notification.error(error.response.data.errors);
        }
      });
  };

  React.useEffect(() => {
    setLoader(true);
    loadOrganizationEmployeesList(allEmployees, pageNumber);
  }, [allEmployees, pageNumber, autoUpdate]);

  var columns = [
    {
      title: "Profile pic",
      dataIndex: "url",
      className: "profileAP-container",
      width: "15%",
      render: (data) => {
        return (
          <div
            className="user-img"
            style={{ backgroundImage: `url(${data})` }}
          ></div>
        );
      },
    },
    {
      title: "Emp. Id",
      dataIndex: "employee_id",
      className: "empid",
      width: "15%",
      render: (id, user) => {
        if (user.is_x !== "0" || user.disable !== "0") {
          return <div className="disabled_user">{id}</div>;
        }
        return id;
      },
    },
    {
      title: "Name",
      dataIndex: "name",
      className: "manage-leaves-name",
      width: "15%",
      render: (text) => {
        return (
          <div className="empName-box fx fx--ai-c">
            <div className="empImg-box">
              <div
                className="img"
                style={{ backgroundImage: `url(${male})` }}
              ></div>
            </div>
            <div className="empName">{text}</div>
          </div>
        );
      },
    },
    {
      title: "Action",
      dataIndex: "",
      className: " text-right manage-leaves-actions",
      width: "55%",
      render: (text) => (
        <span>
          <Tooltip title={"View Attendance"}>
            <Button
              type="link"
              onClick={() => {
                setViewModalLoading(true);
                setEmployeeId(text.uuid);
                setEmployeeName(text.name);
                setViewModalVisible(true);
              }}
              className="btn btn-light-info btn-icon text-uppercase"
            >
              <Icon type="eye" theme="filled" />
            </Button>
          </Tooltip>
        </span>
      ),
    },
  ];

  return (
    <>
      {loader && (
        <div
          className="centerMe"
          style={{
            height: "500px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Spin />
        </div>
      )}
      {!loader && (
        <section className="content-section manageLeaves-section">
          <div className="content">
            <div className="content-header single-searchBar manageLeaveFilters">
              <div className="left">
                {/* SELECT EMPLOYEE */}
                <Select
                  allowClear={true}
                  className="empFilter-selectbox selectbox"
                  showSearch
                  style={{ width: 200 }}
                  placeholder="All Employees"
                  name="userName"
                  onChange={handleEmployee}
                  value={allEmployees}
                  filterOption={(input, option) =>
                    option.props.label
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  <Option key={0} value={undefined} label="All Employee">
                    <div className="demo-option-label-item">All Employee</div>
                  </Option>
                  {employees.map((employee) => {
                    return (
                      <Option
                        key={employee.id}
                        value={employee.uuid}
                        label={employee.name}
                      >
                        <div className="demo-option-label-item">
                          {employee.name}
                        </div>
                      </Option>
                    );
                  })}
                </Select>

                <Button
                  style={{ marginLeft: "20px" }}
                  className="btn btn-primary text-uppercase employee-list"
                  onClick={() => setUploadModalVisible(true)}
                >
                  <Icon type="plus" /> Upload
                </Button>

                <Modal
                  className="add-new-document-modal profileAP-modal"
                  title={"Upload"}
                  centered
                  visible={uploadModalVisible}
                  onOk={handleOk}
                  destroyOnClose={true}
                  onCancel={() => setUploadModalVisible(false)}
                  footer={[
                    <Button
                      onClick={handleOk}
                      loading={loader}
                      key="submit"
                      className="btn btn-primary text-uppercase"
                    >
                      Submit
                    </Button>,
                  ]}
                >
                  <div className="add-new-document-modal-container">
                    <Form onSubmit={handleOk}>
                      <div
                        className="form-group"
                        style={{ minWidth: "550px !important" }}
                      >
                        <Form.Item colon={false}>
                          {getFieldDecorator("file", {
                            rules: [
                              {
                                required: true,
                                message: "Upload a document",
                              },
                            ],
                          })(
                            <Input
                              className="input"
                              type="file"
                              accept=" application/vnd.openxmlformats-officedocument.wordprocessingml.document,.xls, application/vnd.ms-excel, .xlsx, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.google-apps.document, application/vnd.google-apps.spreadsheet, .csv"
                              placeholder="Select document"
                              onBlur={(e) => setUploadedFile(e.target.files[0])}
                            />
                          )}
                        </Form.Item>
                      </div>
                    </Form>
                  </div>
                </Modal>
              </div>
            </div>

            {/* Admin Panel- Table content */}
            <section className="content-section profile-table-info animated slideInUp">
              <div id="container">
                <Table
                  className="table-content"
                  columns={columns}
                  dataSource={
                    employeesList &&
                    employeesList.data &&
                    employeesList.data.data
                  }
                  rowKey={(employee) => employee.uuid}
                  onChange={handlePagination}
                  pagination={{
                    total:
                      employeesList &&
                      employeesList.data &&
                      employeesList.data.meta &&
                      employeesList.data.meta.pagination &&
                      employeesList.data.meta.pagination.total,
                    pageSize:
                      employeesList &&
                      employeesList.data &&
                      employeesList.data.meta &&
                      employeesList.data.meta.pagination &&
                      employeesList.data.meta.pagination.per_page,
                    current: pageNumber,
                    showSizeChanger: false,
                    defaultCurrent: 1,
                  }}
                />
                {/* View Modal */}
                <Modal
                  className="view-profile-modal profileAP-modal"
                  destroyOnClose={true}
                  maskClosable={false}
                  centered
                  visible={viewModalVisible}
                  onCancel={() => setViewModalVisible(false)}
                  footer={null}
                >
                  <div className="view-profile-modal-container">
                    <EmployeeAttendance
                      loading={viewModalLoading}
                      closeModal={() => setViewModalVisible(false)}
                      id={employeeId}
                      employeeName={employeeName}
                    />
                  </div>
                </Modal>
              </div>
            </section>
          </div>
        </section>
      )}
    </>
  );
};

export default Form.create()(ForAdminAndHr(withStore(AllAttendance)));
