import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { withStore } from './withStore';
import RequireAuth from './RequireAuth';

export default ChildComponnent => {
    class ForAdminAndOfficeAdmin extends Component {
        constructor() {
            super();
        }
        render() {
            const { store: { data: { user_roles_name } } } = this.props;
            if ((user_roles_name.indexOf("admin") != -1) || (user_roles_name.indexOf("office_admin") != -1)) {
                return <ChildComponnent {...this.props} />;
            } else {
                return <Redirect to="/dashboard/team-directory" />;
            }
        }
    }

    return withStore(RequireAuth(ForAdminAndOfficeAdmin));
};
