import moment from "moment";
import React, { useEffect, useState } from "react";
import {
  Col,
  Row,
  Table,
  DatePicker,
  Button,
  Select,
  Divider,
  Spin,
  Tooltip,
  Pagination,
} from "antd";
import Graph from "./Graph";
import { axiosInstance as api } from "../../../utilities/configureAxios";
import url from "../../../config/url";
import WorkReport from "./WorkReport";

const ProjectTable = ({
  hoursArr,
  // loading,
  projectLoader,
  type,
  dateRange,
  employeeProjectHours,
  totalProjectHours,
  employeeTotalAllHours,
  employeeIDSelector,
  projectIDSelector,
  onProjectClick,
  pageNumber,
  setPageNumber,
  totalCount,
  setTotalCount,
  perPage,
  setPerPage,
  setQueryParam,
}) => {
  const [date, setDate] = useState(null);
  const [workReportLoader, setWorkReportLoader] = useState(false);
  const [projectWorkReports, setProjectWorkReports] = useState([]);
  const [employeeWorkReports, setEmployeeWorkReports] = useState([]);
  const [projectId, setProjectId] = useState("");
  const [projectGraph, setProjectGraph] = useState([]);
  const [employeeGraph, setEmployeeGraph] = useState([]);

  const { Option } = Select;
  const columns = [
    {
      title: type === 1 ? "Employee Name" : "Project Name",
      dataIndex: "name",
      key: "name",
      className: "left-column",
    },
    {
      title: "Hours",
      dataIndex: "hours",
      key: "hours",
      className: "right-column",
    },
  ];

  function fetchProjectDateWiseWorkReport() {
    setWorkReportLoader(true);
    setProjectWorkReports([]);
    api
      .get(
        `${url["projectList"]}/${
          projectIDSelector !== 0 ? projectIDSelector : 0
        }/work-reports?from_date=${dateRange[0].format(
          "YYYY-MM-DD"
        )}&to_date=${dateRange[1].format(
          "YYYY-MM-DD"
        )}&includes=user&page=${pageNumber}`
      )
      .then((res) => {
        setProjectWorkReports(res.data.data);
        setPageNumber(res.data.meta.pagination.current_page);
        setTotalCount(res.data.meta.pagination.total);
        setPerPage(res.data.meta.pagination.per_page);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally((err) => {
        setWorkReportLoader(false);
      });
  }

  function fetchEmployeeDateWiseWorkReport() {
    setWorkReportLoader(true);
    setEmployeeWorkReports([]);
    // setPageNumber(1);
    api
      .get(
        `${url["createUser"]}/${
          employeeIDSelector !== 0 ? employeeIDSelector : 0
        }/work-reports?from_date=${dateRange[0].format(
          "YYYY-MM-DD"
        )}&to_date=${dateRange[1].format("YYYY-MM-DD")}&page=${pageNumber}`
      )
      .then((res) => {
        setEmployeeWorkReports(res.data.data);
        setPageNumber(res.data.meta.pagination.current_page);
        setTotalCount(res.data.meta.pagination.total);
        setPerPage(res.data.meta.pagination.per_page);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally((err) => {
        setWorkReportLoader(false);
      });
  }

  useEffect(() => {
    if (dateRange !== null) fetchProjectDateWiseWorkReport();
  }, [projectIDSelector, dateRange, pageNumber]);

  useEffect(() => {
    if (dateRange !== null) fetchEmployeeDateWiseWorkReport();
  }, [employeeIDSelector, dateRange, pageNumber]);

  const projectHours = hoursArr?.map((i) => {
    let { id, name, designation, dates, time_consumed, profile_pic } = i;
    return {
      name: (
        <div className="project-hours" key={id}>
          <div className="left-col">
            <img src={profile_pic} alt={name.split(" ", 1)} className="img" />
          </div>
          <div className="right-col">
            <div
              className="name"
              onClick={() => {
                setQueryParam(0, id);
                onProjectClick(id, 0);
              }}
            >
              {name.length > 20 ? (
                <Tooltip
                  color={"#247bff"}
                  placement="top"
                  title={name}
                >{`${name.slice("", 20)}...`}</Tooltip>
              ) : (
                name
              )}
            </div>
            <div className="designation">
              {designation.length > 20 ? (
                <Tooltip
                  color={"#247bff"}
                  placement="bottom"
                  title={designation}
                >
                  {`${designation.slice("", 20)}...`}
                </Tooltip>
              ) : (
                designation
              )}
            </div>
          </div>
        </div>
      ),
      id: id,
      key: id,
      hours: time_consumed,
      dates: dates,
    };
  });
  const employeeHours = employeeProjectHours?.map((i) => {
    return {
      name: (
        <div
          className="project-name"
          onClick={() => {
            setQueryParam(1, i.project_id);
            onProjectClick(i.project_id, 1);
          }}
        >
          {i.project_name}
        </div>
      ),
      // name: i.project_name,
      id: i.project_id,
      key: i.project_id,
      hours: i.time_consumed,
      dates: i.dates,
    };
  });

  // function onSelectDate(date, dateString) {
  //   setDate(date);
  // }
  const dateFormatList = ["DD MMM", "DD MMM"];

  let graphEmployeeData =
    employeeTotalAllHours !== undefined &&
    Object.keys(employeeTotalAllHours).map((i) => ({
      date: i,
      hour: employeeTotalAllHours[i],
    }));

  let graphProjectData = Object.keys(totalProjectHours).map((key) => {
    return { date: key, hour: totalProjectHours[key] };
  });

  // useEffect(() => {
  //   setDate(dateRange[1]);
  // }, [dateRange]);
  useEffect(() => {
    setProjectId("All");
  }, [employeeIDSelector, projectIDSelector, type]);

  useEffect(() => {
    setEmployeeGraph(
      graphEmployeeData.every((item) => item.hour === 0) !== true
        ? graphEmployeeData
        : []
    );

    setProjectGraph(
      graphProjectData.every((item) => item.hour === 0) !== true
        ? graphProjectData
        : []
    );
  }, [dateRange, projectId, totalProjectHours, employeeTotalAllHours]);

  useEffect(() => {
    if (["", "All"].includes(projectId)) return;

    let filteredGraphData =
      type === 1
        ? hoursArr?.filter((d) => d?.id === projectId)[0]?.dates
        : employeeProjectHours?.filter((i) => i?.project_id === projectId)[0]
            ?.dates;

    let final =
      filteredGraphData !== undefined &&
      Object.keys(filteredGraphData).map((i) => ({
        date: i,
        hour: filteredGraphData[i],
      }));

    if (projectId !== "") {
      setProjectGraph(final);
      setEmployeeGraph(final);
    }
  }, [projectId, hoursArr, employeeProjectHours]);

  const onClickCSVDownloadNew = async () => {
    const startDate = dateRange[0]?.format("YYYY-MM-DD");
    const endDate = dateRange[1]?.format("YYYY-MM-DD");
    await api
      .get(
        `${
          type === 1 ? url["projectList"] : url["usersProjects"]
        }/export-csv?from_date=${startDate}&to_date=${endDate}${
          type === 1
            ? `&project_id=${projectIDSelector}${
                projectId === "All" ? "" : `&user_id=${projectId}`
              }`
            : `${
                projectId === "All" ? "" : `&project_id=${projectId}`
              }&user_id=${employeeIDSelector}`
        }`,
        { headers: { "Content-Type": "text/csv" } }
      )
      .then((res) => {
        const downloadTag = document.createElement("a");
        downloadTag.target = "_blank";
        downloadTag.download = `${
          type === 1 ? "Employee" : "Project"
        }_Report_${startDate}_${endDate}.csv`;
        downloadTag.href = `data:attachment/csv;charset=utf-8,${encodeURI(
          res.data
        )}`;
        downloadTag.click();
      })
      .catch((error) => {});
  };

  const filterOption = (input, option) => {
    return (option?.props.children ?? "")
      .toLowerCase()
      .includes(input.toLowerCase());
  };
  return (
    <div>
      <div className="project-table">
        <div className="left-table p-table">
          <Table
            scroll={{ y: 330 }}
            rowKey="key"
            dataSource={type === 1 ? projectHours : employeeHours}
            columns={columns}
            pagination={false}
            loading={projectLoader}
            bordered
          />
        </div>
        <div className="right-table p-table">
          <Row>
            <Col className="head-col">
              <p>Recent Work Logs</p>
            </Col>
            <WorkReport
              data={type === 1 ? projectWorkReports : employeeWorkReports}
              loader={workReportLoader}
            >
              {type === 1
                ? projectWorkReports.map((item) => {
                    let { work_reports } = item;
                    let fullDuration = work_reports.reduce(
                      (acc, curr) => acc + curr.duration,
                      0
                    );
                    let durationInHours = fullDuration / 60;
                    return (
                      <div key={item.id} className="body-col-wrap">
                        <div className="divider-col" id="divider-col">
                          <div className="divider"></div>
                        </div>
                        <Col className="work-report">
                          <div className="header">
                            <div className="head-row">
                              <div className="left">
                                <img
                                  src={item?.user?.profile_pic}
                                  alt="profile"
                                  className="img"
                                />
                                <p className="name">{item.user.name}</p>
                              </div>

                              <div className="right-side">
                                <p className="hours">{durationInHours} HOURS</p>
                                <p className="date">
                                  {moment(item.date).format("DD MMM")}
                                </p>
                              </div>
                            </div>
                            {item.work_reports.map((report, idx) => {
                              return (
                                <div
                                  className="points"
                                  key={idx}
                                  dangerouslySetInnerHTML={{
                                    __html: report.description,
                                  }}
                                />
                              );
                            })}
                          </div>
                        </Col>
                      </div>
                    );
                  })
                : employeeWorkReports.map((item, idx) => {
                    if (item.id !== null) {
                      if (item.work_reports !== null) {
                        return (
                          <div key={item.id} className="body-col-wrap">
                            <div className="divider-col" id="divider-col">
                              <div className="divider"></div>
                            </div>
                            <Col className="work-report">
                              <div className="" style={{ width: "100%" }}>
                                {item.work_reports.map((report, idx) => {
                                  return (
                                    <div className="header">
                                      <div className="head-row">
                                        <div className="left">
                                          <p className="name">
                                            {" "}
                                            {report.title}
                                          </p>
                                        </div>
                                        <div className="right-side">
                                          <p className="hours">
                                            {report.duration / 60} HOURS
                                          </p>
                                          <p className="date">
                                            {moment(item.date).format("DD MMM")}
                                          </p>
                                        </div>
                                      </div>
                                      <div
                                        className="points"
                                        dangerouslySetInnerHTML={{
                                          __html: report.description,
                                        }}
                                      />
                                      {/* {item.work_reports.length > 1 && (
                                        <Divider dashed />
                                      )} */}
                                    </div>
                                  );
                                })}
                              </div>
                            </Col>
                          </div>
                        );
                      } else {
                        return (
                          <div key={item.id} className="work_not_filled_wrap">
                            <div className="work_not_filled">
                              <div className="left-wnf">
                                <p className="name">Work report not filled</p>
                              </div>
                              <div className="right-wnf">
                                <p className="hours">0 HOURS</p>
                                <p className="date">
                                  {moment(item.date).format("DD MMM")}
                                </p>
                              </div>
                            </div>
                          </div>
                        );
                      }
                    }
                  })}
            </WorkReport>
          </Row>
          <div className="pagination__pd">
            {totalCount > 10 && (
              <Pagination
                current={pageNumber}
                total={totalCount}
                pageSize={perPage}
                onChange={(pageNumber) => setPageNumber(pageNumber)}
              />
            )}
          </div>
        </div>
      </div>

      <div className="graph">
        <div className="graph-items">
          <p className="heading">{`${
            type === 1 ? "Employee " : "Project "
          }  wise - Daily Hours Consumed`}</p>
          <div className="button-select">
            <Button
              type="primary"
              style={{
                background: "#E9F2FF",
                color: "#53A6F5",
                border: "6px",
              }}
              onClick={onClickCSVDownloadNew}
              size={window.innerWidth < 640 ? "default" : "large"}
            >
              EXPORT CSV
            </Button>
            <div>
              <Select
                allowClear={true}
                className="status-selectbox selectbox"
                placeholder="Select type"
                onChange={(val) => {
                  setProjectId(val);
                }}
                defaultValue="All"
                value={projectId}
                showSearch
                filterOption={filterOption}
                size={window.innerWidth < 640 ? "middle" : "large"}
              >
                {type === 1
                  ? [
                      { id: "All", name: "All employees" },
                      ...(hoursArr ?? []),
                    ]?.map((i) => {
                      return (
                        <Option value={i?.id} key={i?.id}>
                          {i?.name}
                        </Option>
                      );
                    })
                  : [
                      { project_id: "All", project_name: "All projects" },
                      ...(employeeProjectHours ?? []),
                    ]?.map((i) => {
                      return (
                        <Option value={i?.project_id} key={i?.project_id}>
                          {i?.project_name}
                        </Option>
                      );
                    })}
              </Select>
            </div>
          </div>
        </div>
        {!projectLoader ? (
          type === 1 ? (
            projectGraph.length ? (
              <Graph data={projectGraph} />
            ) : (
              <div style={{ textAlign: "center" }}>No Data found</div>
            )
          ) : employeeGraph.length ? (
            <Graph data={employeeGraph} />
          ) : (
            <div style={{ textAlign: "center" }}>No Data found</div>
          )
        ) : (
          <div className="centerMe__workReport">
            <Spin />
          </div>
        )}
      </div>
    </div>
  );
};

export default ProjectTable;
