import React, { Component, Fragment } from "react";
import { Form, notification, Icon, Rate, Button, Row, Col, Select, Card, Modal, Input } from "antd";
import hobby_image from '../../../../assets/images/icons/profile/skills/hobby.svg';
import _ from 'lodash';
import { axiosInstance as api } from '../../../../utilities/configureAxios';
import url from '../../../../config/url';
import { withStore } from '../../../../hocs/withStore';
import ForAdminAndHr from '../../../../hocs/ForAdminAndHr';

// Dropdown
const { Option } = Select;

// For search
const { Search } = Input;

class AdminEditHobbies extends Component {
    constructor(props) {
        super(props);
        this.state = {
            user_hobbies: props.user_hobbies,
            employee_id: props.employee_id,
            hobbies: null,
            loading: false,
            delete_loading: false,
            hobbies_loading: true,
            suggest_loading: false,
            modal1Visible: false,
        };
    }

    componentDidMount = () => {
        this.setState({ loading: true });
        api
            .get(url["getHobbies"])
            .then(res => {
                const hobbies = _.get(res, 'data.data', [])
                this.setState({ hobbies, hobbies_loading: false });
            })
            .catch(error => {
                notification['error']({
                    message: _.get(error, 'response.data.errors.message', "Oops! Something went wrong!")
                });
                this.setState({ hobbies_loading: false });
            });
        this.setState({ loading: false });
    }

    handleSubmit = e => {
        e.preventDefault();

        this.props.form.validateFieldsAndScroll(['hobby_name'],(err, values) => {
            if (!err) {
                const formData = new FormData();
                this.setState({ loading: true });
                api
                    .post(url["addUserHobby"] + "/" + values.hobby_name + '/user/' +
                    this.state.employee_id, formData)
                    .then(data => {
                        notification['success']({
                            message: "Hobby added successfully!"
                        });
                       
                        this.props.form.resetFields();
                        api
                            .get(url["getHobbies"] + '/' + this.state.employee_id)
                            .then(res => {
                                const user_hobbies = _.get(res, 'data.data', [])
                                this.setState({ user_hobbies, loading: false });
                            })
                            .catch(error => {
                                notification['error']({
                                    message: _.get(error, 'response.data.errors.message', "Oops! Something went wrong!")
                                });
                                this.setState({ loading: false });
                            });
                    }).catch(error => {
                        const error_code = _.get(error, 'response.status');
                        if (error_code && error_code == '422') {
                            notification['error']({
                                message: Object.values(_.get(error, 'response.data.errors', "Oops! Something went wrong!"))
                            });
                        }
                        else {
                            notification['error']({
                                message: _.get(error, 'response.data.errors.message', "Oops! Something went wrong!")
                            });
                        }
                        this.setState({ loading: false });
                    });

            }
        });
    };
    
    handleDelete = user_hobby_id => {
        this.setState({ delete_loading: true });
        api
            .delete(url["myHobbies"] + "/" + user_hobby_id)
            .then(data => {
                notification['success']({
                    message: "Hobby deleted successfully!"
                });
                api
                    .get(url["getHobbies"]+ '/' + this.state.employee_id)
                    .then(res => {
                        const user_hobbies = _.get(res, 'data.data', [])
                        this.setState({ user_hobbies, delete_loading: false });
                    })
                    .catch(error => {
                        notification['error']({
                            message: _.get(error, 'response.data.errors.message', "Oops! Something went wrong!")
                        });
                        this.setState({ delete_loading: false });
                    });
            }).catch(error => {
                const error_code = _.get(error, 'response.status');
                notification['error']({
                    message: _.get(error, 'response.data.errors.message', "Oops! Something went wrong!")
                });
                this.setState({ delete_loading: false });
            });
    };


    setModal1Visible(modal1Visible) {
        this.setState({ modal1Visible });
    }


    render() {
        const { getFieldDecorator } = this.props.form;
        const { user_hobbies, loading, hobbies, hobbies_loading, delete_loading } = this.state;
        return (
            <Fragment>
                <section className="tab-content-container technical-skills-tab card-shadow">
                   
                    <Form>
                        <Row className="" gutter={30}>
                            <Col className="gutter-row" xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
                                <div className="form-content form-group">
                                    <Form.Item label="Hobby Name" colon={false} >
                                        {getFieldDecorator('hobby_name', {
                                            rules: [
                                                {
                                                    required: true,
                                                    message: "Select hobby"
                                                }
                                            ],
                                            validateTrigger: 'onBlur'
                                        })(
                                            <Select className="role-selectbox selectbox"
                                                showSearch
                                                style={{ width: "100%" }}
                                                placeholder="Select Hobby"
                                                optionFilterProp="children"
                                                loading={hobbies_loading}
                                            >
                                                {hobbies && hobbies.map(hobby => (
                                                    <Option key={hobby.slug} value={hobby.slug} >{hobby.title}</Option>
                                                ))}
                                            </Select>)}
                                    </Form.Item>
                                </div>
                            </Col>
                            <Col  className="gutter-row" xs={24} sm={24} md={24} lg={6} xl={6} xxl={6}>
                            </Col>
                            <Col className="gutter-row" xs={24} sm={24} md={24} lg={6} xl={6} xxl={6}>
                                <div className="form-content form-group text-right sm-row-align">
                                    {/* <label className="label">&nbsp;</label> */}
                                    <Form.Item label=" " colon={false} className="add-hobby-label" >
                                        <Button className="btn btn-light-primary text-uppercase" onClick={this.handleSubmit} loading={loading} disabled={loading} >+ Add this Hobby</Button>
                                    </Form.Item>
                                </div>
                            </Col>
                        </Row>
                    </Form>
                </section>

                {/* Technical hobby cards */}
                <section className="content-section skills-card-section">
                    <Row className="" gutter={30}>
                        {user_hobbies && user_hobbies.map(user_hobby => (
                            <Col className="gutter-row" xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
                                <Card className="card card-shadow skills-card">
                                    <div className="skill-icon-box">
                                        <img className="icon icon-link" src={ user_hobby.url ? user_hobby.url : hobby_image } alt={user_hobby.hobby_name} />
                                    </div>
                                    <div className="skill-title-box">
                                        <h4 className="title">{user_hobby.hobby_name}</h4>
                                    </div>
                                    <div className="skill-action-box">
                                        <Button className="btn btn-light-secondary btn-icon" onClick={() => this.handleDelete(user_hobby.id)} loading={delete_loading} disabled={delete_loading}><Icon type="delete" theme="filled" /></Button>
                                    </div>
                                </Card>
                            </Col>
                        ))}
                        {!user_hobbies || user_hobbies.length == 0 && (
                            <Col className="gutter-row" xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
                                <Card className="card card-shadow skills-card">
                                    <h3>No hobbies found! 😟</h3>
                                </Card>
                            </Col>
                        )}
                    </Row>
                </section>


            </Fragment>
        )
    }
}
export default Form.create({ name: 'hobbies_form' })(ForAdminAndHr(withStore(AdminEditHobbies)));
