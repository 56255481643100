import React,{Fragment} from "react";
import RequireAuth from "../../../hocs/RequireAuth";
import { withStore } from "../../../hocs/withStore";
import {Row,Col} from "antd";
import text from "../../../config/text";
import moment from "moment"

const ViewContractEmployeeDetails = ({contractEmployeeDetails,profileKey}) => {

    const fallback = "-";

  return(
    <Fragment>
    {contractEmployeeDetails && (
      <section className="tab-content-container personal-details-tab view-personaldetails-tab">
        <h3 className="title text-primary">{profileKey ? "Profile Details" : "Contract Employee Details"}</h3>
        <Row className="" gutter={30}>
          <Col
            className="gutter-row first-half-col"
            xs={24}
            sm={24}
            md={24}
            lg={12}
            xl={12}
          >
            <div className="form-content">
              <Row className="" gutter={30}>
                <Col
                  className="gutter-row"
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  xl={12}
                >
                  <div className="form-group">
                    <label className="label">First Name</label>
                    <div className="value">
                      {contractEmployeeDetails.personal_details.first_name || fallback}
                    </div>
                  </div>
                </Col>
                <Col className="" xs={12} sm={12} md={12} lg={12} xl={12}>
                  <div className="form-group">
                    <label className="label">Last Name</label>
                    <div className="value">
                      {contractEmployeeDetails.personal_details.last_name || fallback}
                    </div>
                  </div>
                </Col>
              </Row>
              <Row className="" gutter={30}>
                <Col
                  className="gutter-row"
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  xl={12}
                >
                  <div className="form-group">
                    <label className="label">Email</label>
                    <div className="value">
                      <a href={contractEmployeeDetails.employee_details.email_official}>
                        {contractEmployeeDetails.employee_details.email_official || fallback}
                      </a>
                    </div>
                  </div>
                </Col>
                <Col className="" xs={12} sm={12} md={12} lg={12} xl={12}>
                  <div className="form-group">
                    <label className="label">Mobile Number</label>
                    <div className="value">
                      <a href={contractEmployeeDetails.mobile}>
                        {contractEmployeeDetails.personal_details.mobile || fallback}
                      </a>
                    </div>
                  </div>
                </Col>
                <Col
                  className="gutter-row"
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  xl={12}
                >
                  <div className="form-group">
                    <label className="label">Gender</label>
                    <div className="value">
                      {text[contractEmployeeDetails.personal_details.gender] || fallback}
                    </div>
                  </div>
                </Col>

                                <Col className="gutter-row" xs={12} sm={12} md={12} lg={12} xl={12}>
                                    <div className="form-content form-group">
                                        <label className="label">Company Name</label>
                                        <div className="value">{contractEmployeeDetails.company_name || fallback}</div>
                                    </div>
                                </Col>
                                <Col className="gutter-row" xs={12} sm={12} md={12} lg={12} xl={12}>
                                    <div className="form-content form-group">
                                        <label className="label">Date of Joining</label>
                                        <div className="value">{contractEmployeeDetails.employee_details.date_of_joining && moment(contractEmployeeDetails.employee_details.date_of_joining, 'YYYY-MM-DD HH:mm:ss').format('DD-MMM-YYYY') || fallback}</div>
                                    </div>
                                </Col>
                                <Col className="gutter-row" xs={12} sm={12} md={12} lg={12} xl={12}>
                                    <div className="form-content form-group">
                                        <label className="label">Contract Closure Date</label>
                                        <div className="value">{contractEmployeeDetails.contract_close_date && moment(contractEmployeeDetails.contract_close_date, 'YYYY-MM-DD HH:mm:ss').format('DD-MMM-YYYY') || fallback}</div>
                                    </div>
                                </Col>
              </Row>
          
            </div>
          </Col>
          <Col
            className="gutter-row first-half-col"
            xs={24}
            sm={24}
            md={24}
            lg={12}
            xl={12}
          >
              <Row gutter={30}>
              <Col className="gutter-row" xs={12} sm={12} md={12} lg={12} xl={12}>
                                    <div className="form-content form-group">
                                        <label className="label">Employment Type</label>
                                        <div className="value">{contractEmployeeDetails.employee_details.employment_type || fallback}</div>
                                    </div>
                                </Col>
                                
                                <Col className="gutter-row" xs={12} sm={12} md={12} lg={12} xl={12}>
                                    <div className="form-content form-group">
                                        <label className="label">Employee ID</label>
                                        <div className="value">{contractEmployeeDetails.employee_details.employee_id || fallback}</div>
                                    </div>
                                </Col>
                               
                            </Row>
                            <Row className="" gutter={30}>
                                
                                <Col className="gutter-row" xs={12} sm={12} md={12} lg={12} xl={12}>
                                    <div className="form-content form-group">
                                        <label className="label">Designation</label>
                                        <div className="value">{contractEmployeeDetails.employee_details.designation || fallback}</div>
                                    </div>
                                </Col>
                                <Col className="gutter-row" xs={12} sm={12} md={12} lg={12} xl={12}>
                                    <div className="form-content form-group">
                                        <label className="label">Team</label>
                                        <div className="value">{contractEmployeeDetails.employee_details.team && contractEmployeeDetails.employee_details.team.title || fallback}</div>
                                    </div>
                                </Col>
                                <Col className="gutter-row" xs={24} sm={24} md={24} lg={24} xl={24}>
                                    <div className="form-content form-group">
                                        <label className="label">Skills</label>
                                        <div className="value">{contractEmployeeDetails && contractEmployeeDetails.skills && contractEmployeeDetails.skills.map(value=>value.skill_name).join(", ") || fallback}</div>
                                    </div>
                                </Col>
                                <Col className="gutter-row" xs={24} sm={24} md={24} lg={24} xl={24}>
                                    <div className="form-content form-group">
                                        <label className="label">Hobbies</label>
                                        <div className="value">{contractEmployeeDetails && contractEmployeeDetails.hobbies && contractEmployeeDetails.hobbies.map(value=>value.hobby_name).join(", ") || fallback}</div>
                                    </div>
                                </Col>
              </Row>

          </Col>
        </Row>
      </section>
    )}
  </Fragment>
  )
}

export default RequireAuth(withStore(ViewContractEmployeeDetails));
