import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import { Row, Col, Select, DatePicker, Card, Button, Icon, Input, Upload, Modal, notification, Form } from "antd";
import lottie from 'lottie-web';
import _ from 'lodash';
import moment from 'moment';
import ForAdminAndOfficeAdmin from "../../../../../hocs/ForAdminAndOfficeAdmin";
import { withStore } from "../../../../../hocs/withStore";
import { allocatedDeallocateAsset, getOrganizationEmployeesDisable } from './DataManager'
import emptyState from '../../../../../assets/animation/json/emptyState.json';
import male from '../../../../../assets/images/user/male.svg';

// for lottie
let animObj = null;

// for Selectbox
const { Option } = Select;

function handleChange(value) {
}

// For Datepicker
function onChange(date, dateString) {
}

// For Textarea
const { TextArea } = Input;
const onTextareaChange = e => {
};

// For Upload
function getBase64(file) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });
}

class AssignAsset extends Component {

    constructor(props) {
        super(props)
        this.state = {
            previewVisible: false,
            employees: [],
            assetId: null,
            saveButtonLoading: false,
            employeesloading: true,
            previewImage: '',
            fileList: [],
        };
    }

    // for lottie
    componentDidMount() {

        //call the loadAnimation to start the animation
        animObj = lottie.loadAnimation({
            container: this.animBox, // the dom element that will contain the animation
            renderer: 'svg',
            loop: true,
            autoplay: true,
            animationData: emptyState // the path to the animation json
        });

        this.loadOrganizationEmployees()
    }


    handleCancel = () => this.setState({ previewVisible: false });

    handlePreview = async file => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }

        this.setState({
            previewImage: file.url || file.preview,
            previewVisible: true,
        });
    };

    handleChange = ({ fileList }) => this.setState({ fileList });

    loadOrganizationEmployees = () => {
        let assetId = this.props.assetid

        this.setState({ employeesloading: true, assetId: assetId })
        return getOrganizationEmployeesDisable()
            .then(res => {
                this.setState({ employees: res.data, employeesloading: false })
            })
            .catch(error => {
                notification.error({
                    message: error.response.data.errors.message
                })
            })
    }

    getAssetsData() {
        this.props.loadAssets()
    }

    handleOk = e => {
        const { fileList } = this.state;
        e.preventDefault();
        this.props.form.validateFieldsAndScroll((err, formData) => {

            if (!err) {
                this.setState({ saveButtonLoading: true })
                var form_data = new FormData();

                for (var i = 0; i < fileList.length; i++) {
                    form_data.append(`images[${i}]`, fileList[i].originFileObj)
                }
                form_data.append('user_id', formData.user_id)
                form_data.append('allocated_at', moment(formData.allocated_at).format('YYYY-MM-DD HH:mm:ss'))
                form_data.append('allocation_notes', formData.allocation_notes)


                allocatedDeallocateAsset(this.state.assetId, form_data, false)
                    .then(res => {
                        this.setState({ saveButtonLoading: false })
                        this.getAssetsData()
                        notification['success']({
                            message: 'Asset Allocated Successfully'
                        });
                    })
                    .catch(error => {
                        this.setState({ saveButtonLoading: false })
                        const error_code = _.get(error, 'response.status');
                        if (error_code && error_code == '422') {
                            notification['error']({
                                message: "Invalid Data",
                                description: Object.values(_.get(error, 'response.data.errors', "Oops! Something went wrong!"))
                            });
                        }
                        else {
                            notification['error']({
                                message: _.get(error, 'response.data.errors.message', "Oops! Something went wrong!")
                            });
                            this.setState({ visible: false });
                        }
                    })

            }
        })
    }




    render() {
        const { getFieldDecorator } = this.props.form;
        // for Upload
        const { previewVisible, previewImage, fileList, employeesloading, employees, saveButtonLoading } = this.state;
        const uploadButton = (
            <div>
                <Icon type="plus" />
                <div className="ant-upload-text">Add Photo</div>
            </div>
        );

        return (
            <Fragment>
                <div className="addAsset-Container assetsAllocation-container">
                    <div className="animated slideInRight">
                        <form className="">

                            <h3 className="drawer-title text-primary mb--20">{this.props.assetname} </h3>

                            <div className="form-content">
                                <Row className="row" gutter={30}>

                                    <Col className="gutter-row col" xs={24} sm={24} md={12} lg={12} xl={12}>
                                        <div className="form-group">
                                            {/* <label className="label">Allocate To</label> */}
                                            <Form.Item label="Allocate To" colon={false}>
                                                {getFieldDecorator('user_id', {
                                                    initialValue: "",
                                                    rules: [
                                                        {
                                                            required: true,
                                                            message: 'Please select an employee'
                                                        }
                                                    ],
                                                })(
                                                    <Select
                                                        showSearch
                                                        className="filter-dropdown"
                                                        style={{ width: 210 }}
                                                        placeholder="Select"
                                                        onChange={this.handleEmployeeChange}
                                                        loading={employeesloading}
                                                        //onSearch={this.onSearch}
                                                        optionFilterProp="children"
                                                        filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                                    >
                                                        <Option key='0' value="">
                                                            Not Selected
                                                 </Option>
                                                        {employees && employees.map(employee => (
                                                            <Option key={employee.name} value={employee.id}>
                                                                {employee.name}
                                                                {/* <div className="dropdown-icon-box">
                                                                    <div className="dropdowp-image" style={{ backgroundImage: `url(${employee.url})` }}></div>
                                                                    <div className="username">{employee.name}</div>
                                                                </div> */}
                                                            </Option>
                                                        ))}

                                                    </Select>
                                                )}
                                            </Form.Item>
                                        </div>
                                    </Col>

                                    <Col className="gutter-row col" xs={24} sm={24} md={12} lg={12} xl={12}>
                                        <div className="form-group">
                                            {/* <label className="label">Allocation Date & Time</label> */}
                                            <Form.Item label="Allocation Date & Time" colon={false}>
                                                {getFieldDecorator('allocated_at', {
                                                    initialValue: moment(),
                                                    rules: [
                                                        {
                                                            type: 'object',
                                                            required: true,
                                                            message: 'Please select date and time of allocation!'
                                                        }
                                                    ],
                                                })(
                                                    <DatePicker
                                                        placeholder="Select data & time"
                                                        showTime
                                                        onChange={onChange}
                                                        format="DD-MM-YYYY HH:mm:ss"
                                                    />
                                                )}
                                            </Form.Item>
                                        </div>
                                    </Col>
                                </Row>
                            </div>

                            <div className="form-content">
                                <Row className="row" gutter={30}>
                                    <Col className="gutter-row col" xs={24} sm={24} md={24} lg={24} xl={24}>
                                        <div className="form-group">
                                            <Form.Item label="Allocation Notes" colon={false}>
                                                {getFieldDecorator('allocation_notes', {
                                                    initialValue: "",
                                                    rules: [
                                                        {
                                                            message: 'Please enter allocation notes here!'
                                                        }
                                                    ],
                                                })(
                                                    <TextArea className="textarea" placeholder="Enter allocation notes" allowClear onChange={onChange} maxlength={100}/>
                                                )}
                                            </Form.Item>
                                        </div>
                                    </Col>
                                </Row>
                            </div>

                            <div className="form-content">
                                <Row className="row" gutter={30}>
                                    <Col className="gutter-row col" xs={24} sm={24} md={24} lg={24} xl={24}>
                                        <div className="form-group">
                                            {/* <label className="label">Attachment (Allocated)</label> */}
                                            <Form.Item label="Attachment (Allocated)" colon={false}>
                                                <div className="clearfix">

                                                    <Upload
                                                        action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                                                        listType="picture-card"
                                                        fileList={fileList}
                                                        onPreview={this.handlePreview}
                                                        onChange={this.handleChange}
                                                        showUploadList={{ showPreviewIcon: false, showRemoveIcon: true, showDownloadIcon: false }}
                                                    >
                                                        {fileList.length >= 10 ? null : uploadButton}
                                                    </Upload>

                                                    <Modal visible={previewVisible} footer={null} onCancel={this.handleCancel}>
                                                        <img alt="example" style={{ width: '100%' }} src={previewImage} />
                                                    </Modal>
                                                </div>
                                            </Form.Item>
                                        </div>
                                    </Col>
                                </Row>
                            </div>

                        </form>
                    </div>
                </div>

                <div className="formAction-footer-cont">
                    <div className="formAction-footer text-right">
                        <Button onClick={this.handleOk} loading={saveButtonLoading} className="btn btn-success text-uppercase">Allocate</Button>
                    </div>
                </div>
            </Fragment>
        )
    }
}

export default Form.create({ name: 'assign_asset' })(ForAdminAndOfficeAdmin(withStore(AssignAsset)));