import moment from "moment";
import renderHTML from "react-render-html";
import { convertMinutesToHoursAndMinutesString } from "../../../../../utilities/DateUtility";
import React, { Component, Fragment } from "react";
import { getWorkReportsForAdmin, getFilterableEmployees } from "../DataManager";
import data_setting from "../../../../../config/data_setting";
import {
  Icon,
  Drawer,
  notification,
  Upload,
  Modal,
  Pagination,
  Select,
  DatePicker,
  Divider,
  Spin,
  Form,
  Button,
} from "antd";
import dailyStatus from "../../../../../assets/images/icons/sibebar/daily-work-status.svg";
import calendar from "../../../../../assets/images/icons/monthly-calendar-white.svg";
import UserWorkStatus from "./UserWorkStatusDrawer/UserWorkStatus";
import NotificationUpdate from "../../../../../hocs/NotificationUpdate";

// for month picker
const { MonthPicker } = DatePicker;

// for selectbox
const { Option } = Select;

function handleChange(value) {}

// Pagination
function itemRender(current, type, originalElement) {
  if (type === "prev") {
    return (
      <a className="back-icon">
        {" "}
        <Icon type="left" /> Back
      </a>
    );
  }
  if (type === "next") {
    return (
      <a className="next-icon">
        Next <Icon type="right" />
      </a>
    );
  }
  return originalElement;
}

// for upload
function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}

class DailyStatusDashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userWorkReports: null,
      reportVisibility: [],
      endOpen: false,
      visible: false,
      previewVisible: false,
      previewImage: "",
      fileList: [],
      employees: null,
      loading: false,
      employeesloading: false,
      filterDate: null,
      filterEmployee: undefined,
      highlightedEmployee: null,
      allWorkReportVisibility: false,
      pagination: {
        total: 0,
        count: 0,
        per_page: 1,
        current_page: 1,
        total_pages: 1,
      },
    };
  }

  componentDidMount() {
    this.setState({ filterDate: moment() });
    this.getWorkReportsFunction();
    this.loadFilterableEmployeesFunction();
  }

  // ================================ More details toggle ================================
  toggleDetails = (index) => {
    var current_value = this.state.reportVisibility[index];
    const { reportVisibility } = this.state;
    var length = reportVisibility.length;

    const newReportVisibility = [
      ...reportVisibility.slice(0, index),
      !current_value,
      ...reportVisibility.slice(index + 1),
    ];
    this.setState({ reportVisibility: newReportVisibility });
  };

  // for drawer
  showDrawer = (user) => {
    this.setState({
      visible: true,
      highlightedEmployee: user,
    });
  };

  onClose = () => {
    this.setState({
      visible: false,
    });
  };

  // for upload
  handleCancel = () => this.setState({ previewVisible: false });

  handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }

    this.setState({
      previewImage: file.url || file.preview,
      previewVisible: true,
    });
  };

  handleChange = ({ fileList }) => this.setState({ fileList });

  setDateFilter = (gotFilterDate) => {
    let date = gotFilterDate
      ? gotFilterDate.format("YYYY-MM-DD")
      : moment().format("YYYY-MM-DD");
    this.setState({ filterDate: gotFilterDate }, () => {
      this.getWorkReportsFunction(1, date);
    });
  };

  handleEmployeeChange = (user_id) => {
    this.setState({ filterEmployee: user_id }, () => {
      this.getWorkReportsFunction(1, this.state.filterDate);
    });
  };

  handleStartOpenChange = (open) => {
    if (!open) {
      this.setState({ endOpen: true });
    }
  };

  handleEndOpenChange = (open) => {
    this.setState({ endOpen: open });
  };

  getCalendarContainer() {
    return this.d || document.getElementById("calendarContainer");
  }

  prepareFileList = (images) => {
    var returnableFilelist = [];
    images.map((image, index) =>
      returnableFilelist.push({
        uid: index,
        status: "done",
        url: image,
      })
    );
    return returnableFilelist;
  };

  getFormattedDateTime = (dateTime) => {
    return moment(dateTime).format("DD-MM-YYYY HH:mm");
  };

  setEachReportVisibility = () => {
    if (this.state.userWorkReports) {
      var length = this.state.userWorkReports.length;
      var reportVisibility = [];
      for (var index = 0; index < length; index++) {
        reportVisibility[index] = false;
      }
      this.setState({ reportVisibility: reportVisibility });
    }
  };

  loadFilterableEmployeesFunction = () => {
    this.setState({ employeesloading: true });
    return getFilterableEmployees()
      .then((res) => {
        this.setState({ employees: res.data, employeesloading: false });
      })
      .catch((error) => {
        notification.error({
          message: error.response.data.errors.message,
        });
      });
  };

  getWorkReportsFunction = (page = 1) => {
    this.setState({ loading: true });

    let user_id = this.state.filterEmployee;
    let date = this.state.filterDate ? this.state.filterDate : moment();
    return getWorkReportsForAdmin(
      page,
      date.format("YYYY-MM-DD"),
      user_id ? user_id : ""
    )
      .then((res) => {
        console.log("res", res);
        this.setState(
          {
            userWorkReports: res.data,
            pagination: res.meta.pagination,
            loading: false,
          },
          () => this.setEachReportVisibility()
        );
      })
      .catch((error) => {
        console.log("error", { error });
        notification.error({
          message: error.response.data.errors.message,
        });
      });
  };

  onPageChange = (pageNumber) => {
    this.getWorkReportsFunction(pageNumber);
  };

  isWorkReportMissing(workReport) {
    if (
      !workReport.id &&
      !workReport.reason.is_weekend &&
      !workReport.reason.is_holiday
    ) {
      return true;
    }
    return false;
  }

  showSeeWorkStatusToggle(workReport) {
    if (workReport.reason) {
      //&& !workReport.reason.is_weekend && !workReport.reason.is_holiday
      return false;
    }
    return true;
  }

  toggleAllWorkReports = () => {
    const { allWorkReportVisibility } = this.state;

    if (this.state.userWorkReports) {
      var length = this.state.userWorkReports.length;
      var reportVisibility = [];
      for (var index = 0; index < length; index++) {
        reportVisibility[index] = allWorkReportVisibility;
      }
      this.setState({
        reportVisibility: reportVisibility,
        allWorkReportVisibility: !allWorkReportVisibility,
      });
    }
  };

  getWorkReportEntity = (userWorkReport) => {
    if (!userWorkReport.work_report_reminder) {
      return (
        <Fragment>
          {!userWorkReport.work_reports.id && (
            <div className="userName text-default">{userWorkReport.name}</div>
          )}
        </Fragment>
      );
    }
    return (
      <Fragment>
        {userWorkReport.work_reports.id && (
          <div className="userInfo">
            <div className="userName text-success">{userWorkReport.name}</div>{" "}
            <div className="timestamp mt--10">
              <i>
                <b>Submitted at: </b>
                {this.getFormattedDateTime(
                  userWorkReport.work_reports.created_at
                )}
              </i>
            </div>
          </div>
        )}
        {!userWorkReport.work_reports.id &&
          !userWorkReport.work_reports.reason.is_weekend &&
          userWorkReport.work_reports.reason.is_holiday && (
            <div className="userName text-default">{userWorkReport.name}</div>
          )}
        {!userWorkReport.work_reports.id &&
          !userWorkReport.work_reports.reason.is_holiday &&
          userWorkReport.work_reports.reason.is_weekend && (
            <div className="userName text-default">{userWorkReport.name}</div>
          )}
        {!userWorkReport.work_reports.id &&
          !userWorkReport.work_reports.reason.is_holiday &&
          !userWorkReport.work_reports.reason.is_weekend && (
            <div className="userName text-danger">{userWorkReport.name}</div>
          )}
        {!userWorkReport.work_reports.id &&
          userWorkReport.work_reports.reason.is_weekend &&
          userWorkReport.work_reports.reason.is_holiday && (
            <div className="userName text-default">{userWorkReport.name}</div>
          )}
      </Fragment>
    );
  };

  render() {
    const {
      userWorkReports,
      previewVisible,
      previewImage,
      fileList,
      startValue,
      endValue,
      endOpen,
      loading,
      filterDate,
      employees,
      filterEmployee,
      highlightedEmployee,
    } = this.state;
    const { total, per_page, current_page } = this.state.pagination;
    const { getFieldDecorator } = this.props.form;

    const uploadButton = (
      <div>
        <Icon type="plus" />
        <div className="ant-upload-text">Upload</div>
      </div>
    );
    return (
      <Fragment>
        <div className="dailyStatus-dashboard-container">
          {/* page Header */}
          <div className="page-header">
            <div className="page-title">
              <h2 className="title">
                <img
                  className="icon icon-itassets"
                  src={dailyStatus}
                  alt="icon"
                />
                Work Logs Management
              </h2>
            </div>
          </div>

          {/* Get this toggle fix by Deepak */}
          {/* <section className="page-info-box single-searchBar animated slideInRight">
                        <div className="right">
                            <Button onClick={() => this.toggleAllWorkReports()} placeholder="Toggle" suffix={<Icon type="search" />} >
                                Toggle Work Reports
                            </Button>
                        </div>
                    </section> */}

          {/* page info title */}
          <section className="page-info-box single-searchBar animated slideInRight">
            <div className="left multiple-item">
              <div className="filter-firstHalf">
                {/* <div className="filter-label-box fx fx--ai-c">
                                    <div className="img-box">
                                        <img className="icon" src={filterIcon} alt="filter icon" />
                                    </div>
                                    <div className="filterBy-title">Filter By</div>
                                </div> */}
                <div className="filter-dropdowns fx fx--ai-c">
                  <div className="filter-dropdown-box">
                    {getFieldDecorator("employee", {
                      initialValue: filterEmployee,
                    })(
                      <Select
                        showSearch
                        className="employee-filter"
                        style={{ width: 200 }}
                        placeholder="All Employee"
                        onChange={(value) => {
                          this.handleEmployeeChange(value);
                        }}
                        getPopupContainer={(trigger) => trigger.parentNode}
                        filterOption={(input, option) =>
                          option.props.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                        allowClear={true}
                      >
                        {/* <Option key={0} value={null} default>
                          All employees
                        </Option> */}
                        {employees &&
                          employees.map((employee) => (
                            <Option key={employee.name} value={employee.id}>
                              {employee.name}
                            </Option>
                          ))}
                      </Select>
                    )}
                  </div>
                  <div className="filter-dropdown-box" id="calendarContainer">
                    <div
                      className="rangePicker-box fx fx--ai-c"
                      id="calendarContainer"
                    >
                      <div className="">
                        {getFieldDecorator("date", {
                          initialValue: filterDate,
                        })(
                          <DatePicker
                            style={{ width: 150 }}
                            format={data_setting["date_format"]}
                            placeholder="For Date"
                            onChange={(date) => {
                              this.setDateFilter(date);
                            }}
                            onOpenChange={this.handleStartOpenChange}
                            getCalendarContainer={this.getCalendarContainer}
                            suffixIcon={
                              <img
                                className="calendar-icon"
                                src={calendar}
                                alt="calendar icon"
                              />
                            }
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          {loading ? (
            <div className="page-spinner">
              {" "}
              <Spin />
            </div>
          ) : (
            <Fragment>
              <section className="content-section dailyStatusInfoCard">
                <div className="content-box animated slideInLeft">
                  <div className="body-row-content card">
                    {userWorkReports &&
                      userWorkReports.map((userWorkReport, index) => (
                        <Fragment>
                          <div className="card-content">
                            <div className="content fx fx--ai-c fx--jc-sb">
                              <div className="employeesInfo-box">
                                <div
                                  className="employeesInfo fx fx--ai-c"
                                  onClick={() =>
                                    this.showDrawer(userWorkReport)
                                  }
                                >
                                  <div className="img-box">
                                    <div
                                      className="img"
                                      style={{
                                        backgroundImage: `url(${userWorkReport.profile_pic})`,
                                      }}
                                    ></div>
                                  </div>
                                  {this.getWorkReportEntity(userWorkReport)}
                                </div>
                              </div>
                              {this.showSeeWorkStatusToggle(
                                userWorkReport.work_reports
                              ) && (
                                <div className="moreDetails-box">
                                  <div
                                    disabled={true}
                                    className="moreDetails-toggleBtn"
                                    onClick={() => this.toggleDetails(index)}
                                  >
                                    <div className="moreDetails-title text-uppercase fx fx--ai-c">
                                      {this.state.reportVisibility[index] ? (
                                        <Fragment>
                                          <span className="mr--5">Hide</span>{" "}
                                          <span className="mr--5">
                                            Work Status
                                          </span>{" "}
                                          <Icon type="up" />
                                        </Fragment>
                                      ) : (
                                        <Fragment>
                                          <span className="mr--5">See</span>{" "}
                                          <span className="mr--5">
                                            Work Status
                                          </span>{" "}
                                          <Icon type="down" />
                                        </Fragment>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              )}
                            </div>
                            {this.state.reportVisibility[index] && (
                              <div className="bodyContent animated fadeIn">
                                {userWorkReport.work_reports.id && (
                                  <Fragment>
                                    {userWorkReport.work_reports.work_reports.map(
                                      (workReport, innerIndex) => (
                                        <Fragment>
                                          <div className="workStatus-info">
                                            <div className="work-status-heading">
                                              {workReport.title}
                                              {workReport.duration && (
                                                <div className="project-time-spent">
                                                  <i>
                                                    (Time Spent:{" "}
                                                    {convertMinutesToHoursAndMinutesString(
                                                      workReport.duration
                                                    )}
                                                    )
                                                  </i>
                                                </div>
                                              )}
                                            </div>
                                            <div className="description">
                                              {renderHTML(
                                                workReport.description
                                              )}
                                            </div>
                                            {workReport.images.length != 0 && (
                                              <div className="imgAttachment-box">
                                                <div className="clearfix">
                                                  <Upload
                                                    action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                                                    listType="picture-card"
                                                    fileList={this.prepareFileList(
                                                      workReport.images
                                                    )}
                                                    onPreview={
                                                      this.handlePreview
                                                    }
                                                    onChange={this.handleChange}
                                                    showUploadList={{
                                                      showPreviewIcon: true,
                                                      showRemoveIcon: false,
                                                      showDownloadIcon: false,
                                                    }}
                                                  ></Upload>
                                                  <Modal
                                                    className="viewImgModal"
                                                    visible={previewVisible}
                                                    footer={null}
                                                    onCancel={this.handleCancel}
                                                  >
                                                    <img
                                                      alt="example"
                                                      style={{ width: "100%" }}
                                                      src={previewImage}
                                                    />
                                                  </Modal>
                                                </div>
                                              </div>
                                            )}
                                            {userWorkReport.work_reports
                                              .work_reports &&
                                              userWorkReport.work_reports
                                                .work_reports.length -
                                                1 ==
                                                innerIndex && (
                                                <div className="status-strip-box fx fx--ai-c fx--jc-sb">
                                                  {userWorkReport.work_reports
                                                    .day_type && (
                                                    <div className="dayStatus">
                                                      <i>
                                                        <b>Day Status: </b>
                                                        {userWorkReport
                                                          .work_reports
                                                          .day_type ===
                                                        "full_day"
                                                          ? "Full Day"
                                                          : "Half Day"}
                                                      </i>
                                                    </div>
                                                  )}
                                                </div>
                                              )}
                                          </div>
                                          {userWorkReport.work_reports
                                            .work_reports &&
                                            userWorkReport.work_reports
                                              .work_reports.length -
                                              1 !=
                                              innerIndex && (
                                              <Divider type="horizontal" />
                                            )}
                                        </Fragment>
                                      )
                                    )}
                                  </Fragment>
                                )}

                                {this.isWorkReportMissing(
                                  userWorkReport.work_reports
                                ) && (
                                  <div className="bodyContent animated fadeIn">
                                    <div className="workStatus-info">
                                      <div className="emptyState">
                                        <div className="message mb--20">
                                          OOPS, Work log is missing for this
                                          date.
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                )}

                                {!this.isWorkReportMissing(
                                  userWorkReport.work_reports
                                ) &&
                                  !userWorkReport.work_reports.id && (
                                    <div className="bodyContent animated fadeIn">
                                      <div className="workStatus-info">
                                        <div className="emptyState">
                                          <div className="message mb--20">
                                            {" "}
                                            Some days must not be counted :)
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  )}
                              </div>
                            )}
                          </div>
                          {userWorkReports.length - 1 != index && (
                            <Divider type="horizontal" />
                          )}
                        </Fragment>
                      ))}
                  </div>
                </div>
              </section>
            </Fragment>
          )}

          {/* Pagination */}
          <section className="pagination-container animated slideInRight">
            <div className="pagination-box text-right">
              <Pagination
                className="pagination"
                onChange={this.onPageChange}
                current={current_page}
                total={total}
                itemRender={itemRender}
                pageSize={per_page}
              />
            </div>
          </section>

          {/* for User work status */}
          <Drawer
            className="navigator-drawer userWorkStatus-drawer"
            title={highlightedEmployee && highlightedEmployee.name}
            placement="right"
            width={700}
            onClose={this.onClose}
            destroyOnClose={true}
            visible={this.state.visible}
            bodyStyle={{ padding: 0 }}
          >
            <UserWorkStatus user={highlightedEmployee} />
          </Drawer>
        </div>
      </Fragment>
    );
  }
}
export default Form.create({ name: "admin_daily_status_screen" })(
  NotificationUpdate(DailyStatusDashboard)
);
