import React, { Component } from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';


import Login from '../src/shared/auth/components/Login';
import SuperAdminLogin from '../src/app/superAdmin/components/LoginScreen'
import DashboardConatiner from './app/DashboardContainer';
import Error404 from './app/error/components/Error404';
import Error500 from './app/error/components/Error500';

class AppRouter extends Component {
    render() {
        return (
            <BrowserRouter>
                <Switch>
                    <Route exact path='/' component={Login}></Route>
                    <Route exact path='/superadmin' component={SuperAdminLogin}></Route>
                    <Route path='/dashboard' component={DashboardConatiner}></Route>
                    <Route path='/404' component={Error404}></Route>
                    <Route path='/500' component={Error500}></Route>
                </Switch>
            </BrowserRouter>
        )
    }
}

export default AppRouter;