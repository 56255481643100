import React, { Component , Fragment } from "react";
import { Icon, Button, Table, Divider, Modal } from "antd";

import compDocument from '../../../assets/images/icons/icon-comp-document-bg.svg';

import AddNewAdmin from './AddNewAdmin';
import EditAdmin from './EditAdmin';

export default class ManageOrganization extends Component {

    // Modal
    state = {
        modal1Visible: false,
        modal2Visible: false,
    };
    
    setModal1Visible(modal1Visible) {
        this.setState({ modal1Visible });
    }
    
    setModal2Visible(modal2Visible) {
        this.setState({ modal2Visible });
    }
    
    render() {
    
        // Table
        const columns = [
            { title: 'Name', dataIndex: 'name', className: "name" },
            { title: 'Email', dataIndex: 'email', className: "email" },
            { title: 'Status', dataIndex: 'status', className: "status" },
            {
                title: 'Action',
                dataIndex: 'action',
                className: "action",
                render: action =>
                <span>
                    <a className="btn btn-light-info btn-icon text-uppercase" onClick={() => this.setModal2Visible(true)}><Icon type="edit" theme="filled" /> Edit</a>
                    <Divider type="vertical" />
                    <a className="btn btn-light-danger btn-icon text-uppercase"><Icon type="delete" theme="filled" /> Delete</a>
                </span>,
            },
        ];
          const data = [
            { key: '1', name: 'Admin 1', email: 'admin1@gmail.com', status: 'Enable' },
            { key: '2', name: 'Admin 2', email: 'admin2@gmail.com', status: 'Disable' },
            { key: '3', name: 'Admin 3', email: 'admin3@gmail.com', status: 'Disable' },
            { key: '4', name: 'Admin 4', email: 'admin4@gmail.com', status: 'Disable' },
            { key: '5', name: 'Admin 5', email: 'admin5@gmail.com', status: 'Enable' },
        ];
    
        return (
            <Fragment>
            
                <div className="page-wrapper">
                    
                    <div className="organization-SA-container">
                        
                        {/* page Header */}
                        <div className="page-header">
                            <div className="page-title">
                                <h2 className="title"><img className="icon icon-view" src={ compDocument } alt="view icon" /> SuperAdmin Panel- Manage Organization</h2>
                            </div>
                        </div>
                        
                        {/* page info title */}
                        <section className="page-info-box">
                            <h3 className="info-title">List of Organization</h3>
                            <Button className="btn btn-success text-uppercase" onClick={() => this.setModal1Visible(true)}><Icon type="file-add" theme="filled" /> Add New</Button>
                        </section>
                        
                        {/* SuperAdmin Panel- Manage Organization table */}
                        <section className="content-section sa-organization-table-info">
                            <Table className="table-content" columns={columns} dataSource={data} />
                        </section>
            
            
                        {/* Add New Admin Modal */}
                        <Modal className="add-new-admin-modal"
                            title="Add New Admin"
                            centered
                            visible={this.state.modal1Visible}
                            onOk={() => this.setModal1Visible(false)}
                            onCancel={() => this.setModal1Visible(false)}
                            footer={[
                                <Button key="back" className="btn btn-light-secondary text-uppercase">Cancel</Button>,
                                <Button key="submit" className="btn btn-light-success text-uppercase">Add</Button>,
                            ]}
                        >
                        
                        {/* Importing Add Admin Component */}
                        <AddNewAdmin></AddNewAdmin>
                            
                        </Modal>
                        
                        {/* Edit Admin Modal */}
                        <Modal className="edit-admin-modal"
                            title="Edit Admin"
                            centered
                            visible={this.state.modal2Visible}
                            onOk={() => this.setModal2Visible(false)}
                            onCancel={() => this.setModal2Visible(false)}
                            footer={[
                                <Button key="back" className="btn btn-light-secondary text-uppercase">Cancel</Button>,
                                <Button key="submit" className="btn btn-light-info text-uppercase">Edit</Button>,
                            ]}
                        >
                            
                            {/* Importing Edit Admin Component */}
                            <EditAdmin></EditAdmin>
                            
                        </Modal>
                        
                    </div>
                </div>
            
            </Fragment>
        )
    }
}