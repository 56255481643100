import React, { Fragment, useEffect, useState } from "react";
import {
  Spin,
  Row,
  Form,
  Input,
  Col,
  DatePicker,
  Button,
  Checkbox,
  Select,
  InputNumber,
  notification,
  Modal,
  Tooltip,
  Icon,
} from "antd";
import moment from "moment";
import { getSkillsData, getOrgTeams } from "../../../services/ProjectService";
import TextArea from "antd/lib/input/TextArea";
import { getOrganizationEmployeesCurrent } from "../../admin/components/LeaveManagement/DataManager";
import { createProject, getSuggestedEmployees } from "../../../services/ProjectService";
import _ from "lodash";
import SuggestedEmpView from "./SuggestedEmpView";

const NewProject = (props) => {
  const { getFieldDecorator, validateFieldsAndScroll, setFields, getFieldValue, setFieldsValue } =
    props.form;
  const [loading, setLoading] = useState(false);
  const [teamValue, setTeamValue] = useState("");
  const [suggestEmpLoading, setSuggestEmpLoading] = useState(false);
  const [suggestModalVisible, setSuggestModalVisible] = useState(false);
  const [employees, setEmployees] = useState([]);
  const [teams, setTeams] = useState([]);
  // const [resourceFieldData, setResourceFieldData] = useState([]);
  const [resourceFieldData, setResourceFieldData] = useState([]);
  const [usersList, setUsersList] = useState([]);
  const [skillIds, setSkillIds] = useState([]);
  const [ skillsEmp , setSkillsForSuggestedEmployees]= useState("");
  const [suggestedEmpList, setSuggestedEmpList] = useState("");
  const [suggestedEmpShortList, setSuggestedEmpShortList] = useState("");
  const [formData, setFormData] = useState({
    users: [],
    notes: "",
    skills: null,
    start_date: "",
    end_date: "",
    title: "",
    project_type: "",
  });
  const disabledDate = (current) => {
    // Can not select days before today and today
    let end_date = getFieldValue("end_date");
    return end_date ? current && current > moment(end_date).endOf("day") : false;
  };
  const disabledendDate = (current) => {
    // Can not select days before today and toda
    let start_date = getFieldValue("start_date");
    return start_date
      ? current && current < moment(start_date).endOf("day").subtract("1", "day")
      : false;
  };
  const [formData1, setFormData1] = React.useState({
    teams_loading: true,
    skills_loading: true,
    shortageRole: "",
    isShortDesign: false,
    isShortPM: false,
    isShortMentor: false,
    isShortFrontend: false,
    isShortBackend: false,
    isShortApp: false,
    isShortQuality: false,
    isShortDevOps: false,
  });
  const [shortageFieldVisibility, setShortageFieldVisibility] = useState([]);
  const { Option } = Select;
  const { closeModal, autoUpdate } = props;
  const [numberVisible, setNumberVisible] = useState([]);

  const getSkills = () => {
    setFormData1((prevData) => {
      return {
        ...prevData,
        skills_loading: true,
      };
    });
    getSkillsData().then((res) => {
      setFormData((prevData) => {
        return {
          ...prevData,
          skills: res && res.data && res.data.data,
        };
      });
      setFormData1((prevData) => {
        return {
          ...prevData,
          skills_loading: false,
        };
      });
    });
  };
  const getOrgTeamsData = () => {
    setFormData1((prevData) => {
      return {
        ...prevData,
        teams_loading: true,
      };
    });
    getOrgTeams().then((res) => {
      setTeams(res && res.data && res.data.data);
      setFormData1((prevData) => {
        return {
          ...prevData,
          teams_loading: false,
        };
      });
    });
  };
  useEffect(() => {
    getSkills();
    getOrgTeamsData();
  }, []);

  const onSubmit = () => {
    validateFieldsAndScroll((err, values) => {
      formData.start_date = values.start_date.format("YYYY-MM-DD");
      if(values.end_date){
         formData.end_date = values.end_date.format("YYYY-MM-DD");
      }
      if (values.next_deliverable_date) {
        formData.next_deliverable_date = values.next_deliverable_date.format("YYYY-MM-DD");
      }
      formData.title = values.title;
      formData.project_type = values.project_type;
      if (values.next_deliverable_notes) {
        formData.next_deliverable_notes = values.next_deliverable_notes;
      }
      formData.notes = values.notes;
      if(values.skills){
        formData.skills = values.skills;
      }else{
        formData.skills = [];
      }
      formData.status = values.status;

      if (!err) {
        resourceFieldData.map((val) => {
          let key = val.title;
          let team_id = val.id;
          if (values[key] && values[key].length) {
            usersList.push(
              values[key].map((el) => {
                return {
                  user_id: el,
                  team_id: team_id,
                  status: 1,
                };
              })
            );
          }
          let user = [];
          usersList.map((el) => el.map((val) => user.push(val)));
          formData.users = user;
        });
        formData.shortage = [];
        resourceFieldData.map((val) => {
          let team_id = val.id;
          let shortageName = val.title + "_shortage";
          if (values[shortageName]) {
            formData.shortage.push({
              team_id: team_id,
              counts: values[shortageName],
            });
          }
        });
        setLoading(true);
        createProject(formData)
          .then((res) => {
            notification.success({
              message: "Created successfully",
            });
            closeModal();
            setLoading(false);
            autoUpdate();
          })
          .catch((error) => {
            setLoading(false);
            getSkills();
            getOrgTeamsData();
            if (error && error.response && error.response.status) {
              if (error.response.status === 422) {
                let errors = error.response.data.errors;
                let errorObject = {};
                Object.keys(errors).forEach((key) => {
                  let err = [];
                  errors[key].forEach((value) => {
                    err.push(new Error(value));
                  });
                  key = _.camelCase(key.split(".")[0]);
                  errorObject[key] = { value: formData[key], errors: err };
                });
                setFields(errorObject);
              }else if (
                error &&
                error.response &&
                error.response.data &&
                error.response.data.errors
              ) {
                setLoading(false);
                notification.error(error.response.data.errors);
              }
            } 
          });
      }
    });
  };

  const handleSuggestedEmployees = (val) => {
    setSuggestEmpLoading(true);
    let skills = val.map((el) => el).join(",");
    setSkillsForSuggestedEmployees(skills);
    getSuggestedEmployees(1,skills).then((res) => {
      let arr = [];
      res.data.data.map((el, index) => {
        if (index < 7) {
          arr.push(el.name);
        }
      });
      setSuggestedEmpShortList(arr);
      setSuggestedEmpList(res.data);
      setSuggestEmpLoading(false);
    });
  };
  // FETCHING ALL EMPLOYEES NAMES
  const loadOrganizationEmployees = () => {
    return getOrganizationEmployeesCurrent()
      .then((res) => {
        setEmployees(res.data);
      })
      .catch((error) => {
        notification.error(
          error && error.response && error.response.data && error.response.data.errors
        );
      });
  };
  React.useEffect(() => {
    loadOrganizationEmployees();
  }, []);

  // const showResourceFiels = (val) => {
  //   let fieldData = val.map((valId) => teams.filter((el) => el.id === valId));
  //   setResourceFieldData(fieldData);
  // };
  const showResourceFiels = (val) => {
    if (val) {
      let fieldData = teams.filter((el) => el.id === val);
      if (!resourceFieldData.find((el) => el.title === fieldData[0].title)) {
        setResourceFieldData(() => {
          return [...resourceFieldData, fieldData && fieldData[0]];
        });
      }
    }
  };
  const removeResourceFiels = (val) => {
    const index = resourceFieldData.findIndex((el) => el.id === val.id);
    if (index > 0) {
      resourceFieldData.splice(index, 1);
      shortageFieldVisibility.splice(index, 1);
    }
    if(index == 0){
      resourceFieldData.splice(index, 1);
      shortageFieldVisibility.splice(index, 1);
      setFieldsValue({ team: undefined });
    }
    setNumberVisible([...numberVisible]);
  };
  return (
    <Fragment>
      <div className="add-new-profile-modal-container">
        <section>
          <h3 className="title text-primary">Create Project</h3>
          {loading ? (
            <div className="page-spinner">
              <Spin />
            </div>
          ) : (
            <>
              <form>
                <Row className="row" gutter={30}>
                  <Col
                    className="gutter-row first-half-col"
                    xs={24}
                    sm={24}
                    md={24}
                    lg={12}
                    xl={12}
                  >
                    <Row gutter={30}>
                      <Col className="gutter-row" xs={24} sm={24} md={12} lg={12} xl={12}>
                        <Form.Item label="Title:">
                          {getFieldDecorator("title", {
                            rules: [
                              {
                                required: true,
                                message: "Enter a valid Title",
                              },
                            ],
                          })(
                            <Input
                              type="text"
                              className="input"
                              name="name"
                              placeholder="Enter Title"
                            />
                          )}
                        </Form.Item>
                      </Col>
                      <Col className="gutter-row" xs={24} sm={24} md={24} lg={12} xl={12}>
                        <div className="form-content form-group">
                          <div className="emp-type-dropdown dropdown">
                            <Form.Item label="Project Type">
                              {getFieldDecorator("project_type", {
                                rules: [
                                  {
                                    required: true,
                                    message: "Select employment type",
                                  },
                                ],
                              })(
                                <Select
                                  className="emp-type-selectbox selectbox"
                                  showSearch
                                  style={{ width: "100%" }}
                                  placeholder="Select Project Type"
                                  optionFilterProp="children"
                                >
                                  <Option value={1}>EMP</Option>
                                  <Option value={2}>IMP</Option>
                                </Select>
                              )}
                            </Form.Item>
                          </div>
                        </div>
                      </Col>
                      <Col className="gutter-row" xs={24} sm={24} md={12} lg={12} xl={12}>
                        <Form.Item label="Start Date">
                          {getFieldDecorator("start_date", {
                            rules: [
                              {
                                required: true,
                                message: "Select a valid date",
                              },
                            ],
                          })(<DatePicker className="ml-2" disabledDate={disabledDate} />)}
                        </Form.Item>
                      </Col>
                      <Col className="gutter-row" xs={24} sm={24} md={12} lg={12} xl={12}>
                        <Form.Item label="End Date">
                          {getFieldDecorator("end_date", {
                            rules: [
                              {
                                required: false,
                                message: "Select a valid date",
                              },
                            ],
                          })(<DatePicker className="ml-2" disabledDate={disabledendDate} />)}
                        </Form.Item>
                      </Col>
                      <Col className="gutter-row" xs={24} sm={24} md={24} lg={12} xl={12}>
                        <div className="form-content form-group">
                          <div className="emp-type-dropdown dropdown">
                            <Form.Item label="Status">
                              {getFieldDecorator("status", {
                                rules: [
                                  {
                                    required: true,
                                    message: "Select status",
                                  },
                                ],
                              })(
                                <Select
                                  className="emp-type-selectbox selectbox"
                                  showSearch
                                  style={{ width: "100%" }}
                                  placeholder="Select Project Type"
                                  optionFilterProp="children"
                                >
                                  <Option value={0}>Pending</Option>
                                  <Option value={1}>Active</Option>
                                  <Option value={2}>On Hold</Option>
                                  <Option value={3}>Compeleted</Option>
                                  <Option value={4}>Cancelled</Option>
                                </Select>
                              )}
                            </Form.Item>
                          </div>
                        </div>
                      </Col>
                      <Col className="gutter-row" xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
                        <div className="form-content form-group">
                          <Form.Item label="Skills" colon={false}>
                            {getFieldDecorator("skills", {
                              rules: [
                                {
                                  required: false,
                                  message: "Select skill",
                                },
                              ],
                            })(
                              <Select
                                className="role-selectbox selectbox"
                                showSearch
                                mode="multiple"
                                style={{ width: "100%" }}
                                placeholder="Select Skill"
                                optionFilterProp="children"
                                loading={formData1.skills_loading}
                                onChange={(val) => {
                                  setSkillIds(val);
                                  if (!val.length) {
                                    setSuggestedEmpList(val);
                                  } else if (val.length) {
                                    handleSuggestedEmployees(val);
                                  }
                                }}
                              >
                                {formData.skills &&
                                  formData.skills.map((skill) => (
                                    <Option key={skill.slug} value={skill.id}>
                                      {skill.title}
                                    </Option>
                                  ))}
                              </Select>
                            )}
                          </Form.Item>
                        </div>
                      </Col>
                      <Col
                        className="gutter-row"
                        style={{ display: "flex" }}
                        xs={24}
                        sm={24}
                        md={24}
                        lg={24}
                        xl={24}
                      >
                        {/* <Button
                        loading={suggestEmpLoading}
                          className="btn-success"
                          onClick={() => {
                            skillIds.length &&
                            handleSuggestedEmployees()
                          }}
                        >
                          Suggested Employees
                        </Button> */}
                        {suggestedEmpShortList.length && skillIds.length ? (
                          <Form.Item label="Suggested Employees:" colon={false}>
                            <Row gutter={1}>
                              <Col className="gutter-row" xs={24} sm={24} md={19} lg={19} xl={19}>
                                {/* <ol type="1" style={{maxHeight:"100px",overflow:'auto',display:"inline-block"}}> */}
                                {/* {suggestedEmpList.map((el,index)=>index<7 ? el.name : null).join(", ")} */}
                                {suggestedEmpShortList.join(", ")}
                                {/* </ol> */}
                              </Col>
                              <Col className="gutter-row" xs={24} sm={24} md={4} lg={4} xl={4}>
                                <Tooltip placement="bottom" title={"Full Screen"}>
                                  <Button
                                    onClick={() => {
                                      // handleSuggestedEmployees();
                                      setSuggestModalVisible(true);
                                    }}
                                    className="btn btn-light-primary btn-icon text-uppercase"
                                  >
                                    {/* <Icon type="eye" theme="filled" /> */}
                                    More
                                  </Button>
                                </Tooltip>
                              </Col>
                            </Row>
                          </Form.Item>
                        ) : null}
                      </Col>
                      <Col className="gutter-row" xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                        <div className="form-content form-group">
                          <Form.Item label="Project Description" colon={false}>
                            {getFieldDecorator("notes", {
                              rules: [
                                {
                                  required: true,
                                  message: "Please Enter Project Description",
                                },
                              ],
                            })(<TextArea />)}
                          </Form.Item>
                        </div>
                      </Col>
                      {/* <Col
                        className="gutter-row"
                        xs={24}
                        sm={24}
                        md={24}
                        lg={24}
                        xl={24}
                        xxl={24}
                      >
                        <div className="form-content form-group">
                          <Form.Item
                            label="Next Deliverable Notes"
                            colon={false}
                          >
                            {getFieldDecorator("next_deliverable_notes", {
                              rules: [
                                {
                                  required: false,
                                  message: "Please Enter Notes",
                                },
                              ],
                            })(<TextArea />)}
                          </Form.Item>
                        </div>
                      </Col> */}
                    </Row>
                  </Col>

                  <Col
                    className="gutter-row second-half-col"
                    xs={24}
                    sm={24}
                    md={24}
                    lg={12}
                    xl={12}
                  >
                    <Row gutter={30}>
                      <Col className="gutter-row" xs={24} sm={24} md={24} lg={12} xl={12}>
                        <div className="form-content form-group">
                          <div className="team-dropdown dropdown">
                            <Form.Item label="Team">
                              {getFieldDecorator("team", {
                                rules: [
                                  {
                                    required: false,
                                    message: "Select team",
                                  },
                                ],
                              })(
                                <Select
                                  // mode="multiple"
                                  className="team-selectbox selectbox"
                                  showSearch
                                  style={{ width: "100%" }}
                                  placeholder="Select Team"
                                  optionFilterProp="children"
                                  loading={formData1.teams_loading}
                                  onChange={(val) => setTeamValue(val)}
                                  // onChange={(val) => showResourceFiels(val)}
                                  allowClear={true}
                                >
                                  {teams &&
                                    teams.map((team) => (
                                      <Option key={team.id} value={team.id}>
                                        {team.title}
                                      </Option>
                                    ))}
                                </Select>
                              )}
                            </Form.Item>
                          </div>
                        </div>
                      </Col>
                      <Col className="gutter-row" xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
                        <div className="form-content form-group sm-row-align">
                          <Form.Item label=" " colon={false} className="add-skill-label">
                            <Button
                              className="btn btn-light-primary text-uppercase"
                              onClick={() => showResourceFiels(teamValue)}
                            >
                              Add
                            </Button>
                          </Form.Item>
                        </div>
                      </Col>

                      {/* isShort container starts */}
                      {resourceFieldData.length
                        ? resourceFieldData.map((fieildInfo, index) => {
                            return (
                              fieildInfo && (
                                <Col className="gutter-row" xs={24} sm={24} md={24} lg={24} xl={24}>
                                  <Row gutter={30}>
                                    <Col
                                      className="gutter-row"
                                      xs={24}
                                      sm={24}
                                      md={24}
                                      lg={10}
                                      xl={10}
                                    >
                                      <div className="isShort-pair">
                                        <Form.Item label={fieildInfo && fieildInfo.title}>
                                          {getFieldDecorator(
                                            fieildInfo ? fieildInfo.title : "title",
                                            {
                                              rules: [
                                                {
                                                  required: !shortageFieldVisibility.includes(
                                                    fieildInfo.title
                                                  ),
                                                  message: `Select ${
                                                    fieildInfo && fieildInfo.title
                                                  }`,
                                                },
                                              ],
                                              validateTrigger: "onBlur",
                                            }
                                          )(
                                            <Select
                                              // validateTrigger={onBlur}
                                              mode="multiple"
                                              allowClear={true}
                                              className="empFilter-selectbox selectbox"
                                              showSearch
                                              placeholder={"Select" + " " + fieildInfo.title}
                                              filterOption={(input, option) =>
                                                option.props.label
                                                  .toLowerCase()
                                                  .indexOf(input.toLowerCase()) >= 0
                                              }
                                            >
                                              {employees.map((employee) => {
                                                return (
                                                  <Option
                                                    key={employee.id}
                                                    value={employee.uuid}
                                                    label={employee.name}
                                                  >
                                                    <div className="demo-option-label-item">
                                                      {employee.name}
                                                    </div>
                                                  </Option>
                                                );
                                              })}
                                            </Select>
                                          )}
                                        </Form.Item>
                                      </div>
                                    </Col>
                                    <Col
                                      className="gutter-row isShortDisplay"
                                      xs={20}
                                      sm={20}
                                      md={20}
                                      lg={8}
                                      xl={8}
                                      // style={{marginTop:"2rem"}}
                                    >
                                      <div className="isShort">
                                        <Form.Item>
                                          <label className="myCustomLabel">Is Short:</label>
                                          <Checkbox
                                            title="isShort"
                                            checked={shortageFieldVisibility.includes(
                                              fieildInfo.title
                                            )}
                                            onClick={(e) => {
                                              if (e.target.checked) {
                                                setShortageFieldVisibility([
                                                  ...shortageFieldVisibility,
                                                  fieildInfo.title,
                                                ]);
                                              } else {
                                                const index = shortageFieldVisibility.indexOf(
                                                  fieildInfo.title
                                                );
                                                if (index > -1) {
                                                  shortageFieldVisibility.splice(index, 1);
                                                }
                                                setNumberVisible([...numberVisible]);
                                              }
                                            }}
                                          ></Checkbox>
                                        </Form.Item>
                                      </div>
                                      {/* Select Shortage */}
                                      <div className="form-content leaveType-cont">
                                        <div className="form-group">
                                          {!shortageFieldVisibility.includes(
                                            fieildInfo.title
                                          ) ? null : (
                                            <Form.Item name="isShort" colon={false}>
                                              {getFieldDecorator(fieildInfo.title + "_shortage", {
                                                initialValue: 1,
                                                rules: [
                                                  {
                                                    pattern: /^[1-9]$|^[1-9][0-9]$|^[1-9][0-9][0-9]$|^(1000)$/,
                                                    message: "Invalid Value",
                                                  },
                                                  {
                                                    required: shortageFieldVisibility.includes(
                                                      fieildInfo.title
                                                    ),
                                                    message: "Enter a number",
                                                  },
                                                ],
                                              })(
                                                <InputNumber
                                                  className="isShort-number"
                                                  name="Shortage"
                                                  placeholder="Short by"
                                                />
                                              )}
                                            </Form.Item>
                                          )}
                                        </div>
                                      </div>
                                    </Col>
                                    <Col
                                      className="gutter-row removeIcon"
                                      xs={4}
                                      sm={4}
                                      md={4}
                                      lg={4}
                                      xl={4}
                                    >
                                      <div className="action-box">
                                        <Tooltip placement="bottom" title={"Delete"}>
                                          <Button
                                            className="btn btn-light-danger btn-icon"
                                            onClick={() => {
                                              removeResourceFiels(fieildInfo);
                                            }}
                                          >
                                            <Icon type="delete" theme="filled" />
                                          </Button>
                                        </Tooltip>
                                      </div>
                                    </Col>
                                  </Row>
                                </Col>
                              )
                            );
                          })
                        : null}
                      {/* isSure container ends */}
                    </Row>
                  </Col>
                </Row>
              </form>
              <div></div>
            </>
          )}
        </section>
        <div style={{ marginTop: "1rem", width: "100%", textAlign: "right" }}>
          <Button className="btn-primary" onClick={onSubmit}>
            Submit
          </Button>
        </div>
      </div>
      <Modal
        className="view-profile-modal profileAP-modal"
        destroyOnClose={true}
        centered
        title={"Suggested Employee List"}
        visible={suggestModalVisible}
        onCancel={() => setSuggestModalVisible(false)}
        footer={null}
      >
        <div className="view-profile-modal-container">
          <SuggestedEmpView
            closeModal={() => setSuggestModalVisible(false)}
            skills={skillsEmp}
          />
        </div>
      </Modal>
    </Fragment>
  );
};

export default Form.create()(NewProject);
