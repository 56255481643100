import React, { useState } from "react";
import { Icon, Button, Tabs, Modal } from "antd";
import NotificationUpdate from "../../hocs/NotificationUpdate";
import { withStore } from "../../hocs/withStore";
import projectIcon from "../../assets/images/icons/sibebar/sprint-management.svg";
import AllProjects from "./Components/AllProjects";
import Employee from "./Components/Employee";
import NewProject from "./Components/NewProject";
import { Helmet } from 'react-helmet';
import _ from "lodash";
import ProjectDetails from "./Components/ProjectDetails";


const ManageProject = (props) => {
  const state = {
    wasActive: 0,
    hovering: null,
    user_roles_name: _.get(props, "store.data.user_roles_name", []),
    profile_pic: _.get(props, "store.data.profile_pic", null),
    imageLoading: false,
  };
  const { user_roles_name } = state;
  const admin = user_roles_name.indexOf("admin") != -1;
  const project_manager = user_roles_name.indexOf("project_manager") != -1;
  const employee = user_roles_name.indexOf("employee") != -1;
  const [tabType, setTabType] = useState("listOfProjects");
  const [createModalVisible, setCreateModalVisible] = useState(false);
  const [autoUpdate, setAutoUpdate] = useState([]);
  const [showCreate , setShowCreate ] = useState(true);
  
  // for tabs
  const { TabPane } = Tabs;
  return (
    <>
          <Helmet>
          <title>Project Management</title>
        </Helmet>
      <div className="page-wrapper">
        {/* page Header */}
        <div className="page-header">
          <div className="page-title">
           {(admin || project_manager) && (<h1 className="title">
              <img
                className="icon icon-itassets"
                src={projectIcon}
                alt="icon"
              />{" "}
              Project Management
            </h1>)}
            {(employee && !admin && !project_manager) && (<h1 className="title">
              <img
                className="icon icon-itassets"
                src={projectIcon}
                alt="icon"
              />{" "}
              My Projects 
            </h1>)}
            
                </div>
                {(admin || project_manager) && showCreate &&(
                  <Button
                    className="btn text-uppercase btn-primary btn-one"
                    onClick={() => setCreateModalVisible(true)}
                  >
                      <Icon type="form" />
                    Create New
                  </Button>  )}
              </div> 
            
           

        {/* PAGE SUB HEADINGS */}
        <section className="page-info-box">
          <Tabs
            defaultActiveKey={tabType}
            onChange={(key) => {
              setTabType(key);
              setShowCreate(!showCreate);
            }}
          >
            <TabPane tab="Projects" key="listOfProjects">
              <AllProjects autoUpdate={autoUpdate} />
            </TabPane>
            {(admin || project_manager) &&(
            <TabPane tab="Employees" key="employees">
              <Employee />
            </TabPane> )}
          </Tabs>
        </section>

        {/* Create Modal */}
        <Modal
          className="profileAP-modal"
          destroyOnClose={true}
          maskClosable={false}
          centered
          visible={createModalVisible}
          onCancel={() => setCreateModalVisible(false)}
          footer={null}
        >
          <div className="view-profile-modal-container">
            <ProjectDetails
              closeModal={() => setCreateModalVisible(false)}
              autoUpdate={() => setAutoUpdate([...autoUpdate])}
              new_project={true}
            />
          </div>
        </Modal>
      </div>
    </>
  );
};

export default withStore(NotificationUpdate(ManageProject));
