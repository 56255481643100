import React, { useEffect, useState, Fragment } from "react";
import { withStore } from "../../../hocs/withStore";
import ForAdminAndHr from "../../../hocs/ForAdminAndHr";
import calendar from "../../../assets/images/icons/monthly-calendar-white.svg";
import { getAttendanceDataById } from "../../../services/Attendance";
import { Table, Spin, Form, DatePicker, Select } from "antd";
import _ from "lodash";
import moment from "moment";

const EmployeeAttendance = (props) => {
  const state = {
    wasActive: 0,
    hovering: null,
    user_roles_name: _.get(props, "store.data.user_roles_name", []),
  };

  const autoUpdate = props.autoUpdate;
  const uuid = props.id;
  const emp_name = props.employeeName;
  const [statusKey, setStatusKey] = useState("1");
  const [attendanceData, setAttendanceData] = useState("");
  const [totalDuration, setTotalDuration] = useState(0);
  const [pageCurrent, setpageCurrent] = useState(1);
  const [loading, setLoading] = useState(false);
  const [month, setMonth] = useState(moment());
  const { MonthPicker } = DatePicker;

  const { Option } = Select;
  const getAttendanceById = (uuid, page, month, statusKey) => {
    setLoading(true);
    return getAttendanceDataById(uuid, page, month, statusKey).then((res) => {
      setAttendanceData(res);
      let total = 0;
      if (res && res.data && res.data.data) {
        res.data.data.map((att) => {
          total += att.duration;
        });
      }
      setTotalDuration(total);
      setLoading(false);
    });
  };

  useEffect(() => {
    setLoading(true);
    const timer = setTimeout(() => {
      setpageCurrent(1);
      getAttendanceById(uuid, 1, month, statusKey);
    }, 500);
    return () => clearTimeout(timer);
  }, [uuid, month, autoUpdate, statusKey]);

  const columns = [
    {
      title: "S.No.",
      key: "index",
      className: "text-center",
      render: (text, object, index) => (
        <div>{(pageCurrent - 1) * 10 + (index + 1)}</div>
      ),
    },
    {
      title: "Date",
      dataIndex: "date",
      render: (text) => <div>{text}</div>,
    },
    {
      title: "In Time",
      align: "center",
      dataIndex: "in_time",
      render: (alligned) => <div> {alligned}</div>,
    },
    {
      title: "Out Time",
      align: "center",
      dataIndex: "out_time",
      render: (alligned) => <div>{alligned}</div>,
    },
    {
      title: "Duration",
      align: "center",
      dataIndex: "duration",
      render: (time) => (
        <div>{`${Math.floor(time / 60)}  hours  ${time % 60} minutes `}</div>
      ),
    },
    {
      title: "Issues",
      align: "center",
      dataIndex: "issues",
      render: (issues) => (
        <ul style={{ margin: "0px", listStyle: "none" }}>
          {issues.map((issue) => {
            if (issue.includes("Half") || issue.includes("Day")) {
              return <li className="text-warning">{issue}</li>;
            } else {
              return <li className="text-info">{issue}</li>;
            }
          })}
        </ul>
      ),
    },
  ];

  const handlePagination = (pagination) => {
    setpageCurrent(pagination.current);
    getAttendanceById(uuid, pagination.current, month, statusKey);
  };

  return (
    <Fragment>
      <div className="add-new-profile-modal-container">
        <section>
          <h3 className="title text-primary">{`Attendance of ${emp_name}`}</h3>

          <>
            <div
              className="projectListFilter"
              style={{ paddingBottom: "10px" }}
            >
              <Select
                className="status-selectbox selectbox"
                style={{ width: "150px", marginRight: "5px" }}
                onChange={(val) => setStatusKey(val)}
                defaultValue={"1"}
              >
                <Option value="1">All</Option>
                <Option value="2">Defaulters</Option>
              </Select>

              <div className="search-box">
                {/* MONTH PICKER */}
                <MonthPicker
                  style={{ width: "150px" }}
                  allowClear={false}
                  format="MMM YY"
                  placeholder="Month"
                  onChange={(val) => setMonth(val)}
                  value={month}
                  suffixIcon={
                    <img
                      className="calendar-icon"
                      src={calendar}
                      alt="calendar icon"
                    />
                  }
                />
              </div>

              {!loading && (
                <div style={{ margin: "10px", marginLeft: "100px" }}>
                  Total Duration :{" "}
                  {`${Math.floor(totalDuration / 60)}  hours  ${
                    totalDuration % 60
                  } minutes `}
                </div>
              )}
            </div>
            {loading ? (
              <div className="page-spinner">
                <Spin />
              </div>
            ) : (
              <>
                <Table
                  dataSource={
                    attendanceData &&
                    attendanceData.data &&
                    attendanceData.data.data
                  }
                  columns={columns}
                  onChange={handlePagination}
                  pagination={{
                    total:
                      attendanceData &&
                      attendanceData.data &&
                      attendanceData.data.meta &&
                      attendanceData.data.meta.pagination &&
                      attendanceData.data.meta.pagination.total,
                    pageSize:
                      attendanceData &&
                      attendanceData.data &&
                      attendanceData.data.meta &&
                      attendanceData.data.meta.pagination &&
                      attendanceData.data.meta.pagination.per_page,
                    current: pageCurrent,
                    showSizeChanger: false,
                    defaultCurrent: 1,
                  }}
                />
              </>
            )}
          </>
        </section>
      </div>
    </Fragment>
  );
};

export default Form.create()(ForAdminAndHr(withStore(EmployeeAttendance)));
