import React from "react";
import lottie from "lottie-web";
import animationData from "../../assets/animation/json/Click-State";

let animObj = null;

class SideOut extends React.Component {
  constructor() {
    super();
    this.isPlaying = false;
  }
  componentDidMount() {
    //call the loadAnimation to start the animation
    animObj = lottie.loadAnimation({
      container: this.animBox, // the dom element that will contain the animation
      renderer: "svg",
      loop: false,
      autoplay: false,
      animationData: animationData, // the path to the animation json
    });
    // animObj.setSpeed(0.9);
    this.handlePlay();
    // animObj.onLoopComplete = (() => {
    //   alert(this);
    //   this.isPlaying = false;
    // });
  }
  handleStop = () => {
    animObj.stop();
    animObj.play();
  };
  handlePlay() {
    animObj.play();
  }

  handleAnimationPress() {
    this.isPlaying === false ? this.handlePlay() : this.handleStop();
    this.isPlaying = !this.isPlaying;
  }

  render() {
    return (
      <div className="out-animation-box">
        {this.props.text}
        {/* <h2>This is my Lottie Web animation</h2>
        This is you wrapper where animation will load */}
        <div
          className="out-animation"
          style={{}}
          ref={(ref) => (this.animBox = ref)}
        ></div>
        {/* <button onClick={this.handleStop}>Stop</button>
        <button onClick={this.handlePlay}>Play</button> */}
      </div>
    );
  }
}

export default SideOut;
