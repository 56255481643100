import React, { useState ,useEffect } from "react";
import { withStore } from "../../../hocs/withStore";
import NotificationUpdate from "../../../hocs/NotificationUpdate";
import { Table, Spin, Row, Col ,Typography} from "antd";
import moment from "moment";
import {
  getUserProjectsDetails,
} from "../../../services/ProjectService";

const EmployeeViewProject = ({ employeeProjectDetails, viewModalLoading }) => {
  const [pageCurrent, setpageCurrent] = useState(1);
  const viewdata =
    employeeProjectDetails &&
    employeeProjectDetails.data &&
    employeeProjectDetails.data.data;
  const [prevId, setPrevId] = useState(viewdata.id);
   
  const fallback = "-";
  const [searchKey, setSearchKey] = useState("");
  const [allData, setAllData] = useState("");
  const COLUMN_STYLE = { width: 300 };
  const [loading, setLoading] = useState(false);
  const getUsersProjectData = (page,id) => {
    setLoading(true);
    return getUserProjectsDetails(page,id).then((res) => {
      setAllData(res.data)
      setLoading(false);
    });
  };

  useEffect(()=>{
   
    setpageCurrent(1)
    if(viewdata &&viewdata.id && viewdata.id){
      setSearchKey(viewdata.id)
      getUsersProjectData(1,viewdata.id)
    }
  },[viewdata.id])
  const handlePagination = (pagination) => {
    setpageCurrent(pagination.current);
    getUsersProjectData(pagination.current,searchKey);
  };
  const columns = [
    {
      title: "S.No.",
      key: "index",
      className: "text-center",
      render: (text, object, index) => (
        <div>{(pageCurrent - 1) * 10 + (index + 1)}</div>
      ),
    },
    {
      title: "Project Name",
      dataIndex: "name",
      key: "id",
      render: (text) => (
        <Typography.Text ellipsis={true} style={COLUMN_STYLE}>
          {text}
        </Typography.Text>
      )
    },
    {
      title: "From",
      dataIndex: "start_date",
      render: (text) => (
        text ?
        <span>{moment(text).format("DD-MMM-YYYY")}</span> :
        (<span style={{marginLeft : '5px'}}>-</span>)
      ),
    },
    {
      title: "To",
      dataIndex: "end_date",
      render: (text) => (
        text ?
        <span>{moment(text).format("DD-MMM-YYYY")}</span> :
        (<span style={{marginLeft : '5px'}}>-</span>)
      ),
    },
    {
      title: "Team",
      dataIndex: "role",
      key: "id",
    },
  ];

  return viewModalLoading ? (
    <div className="page-spinner">
      <Spin />
    </div>
  ) : (
    <>
      <Row gutter={30}>
        <Col className="gutter-row" xs={24} sm={24} md={24} lg={8} xl={8}>
          <div className="form-content form-group">
            <label className="label">Name</label>
            <div className="value">
              {(viewdata && viewdata.name) || fallback}
            </div>
          </div>
        </Col>
        <Col className="gutter-row" xs={24} sm={24} md={24} lg={8} xl={8}>
          <div className="form-content form-group">
            <label className="label">Employee ID</label>
            <div className="value">
              {(viewdata && viewdata.employee_id) || fallback}
            </div>
          </div>
        </Col>
        {/* <Col className="gutter-row" xs={24} sm={24} md={24} lg={24} xl={24}>
          <div className="form-content form-group">
            <label className="label">Skills</label>
            <div className="value">
              {(viewdata &&
                viewdata.skills &&
                viewdata.skills.map((el) => el.title).join(",")) ||
                fallback}
            </div>
          </div>
        </Col> */}
      </Row>
      {loading ? (
              <div className="page-spinner">
                <Spin />
              </div>
            ) : (
              <>
      <Table
        dataSource={
          allData &&
          allData.data
         }
        columns={columns}
        onChange={handlePagination}
            pagination={{
              total:
              allData &&
              allData.meta &&
              allData.meta.pagination &&
              allData.meta.pagination.total,
              pageSize:
              allData &&
              allData.meta &&
              allData.meta.pagination &&
              allData.meta.pagination.per_Page,
              current: pageCurrent,
              showSizeChanger: false,
              defaultCurrent: 1,
            }}
      />
      </>
      )}
    </>
  );
};

export default withStore(NotificationUpdate(EmployeeViewProject));
