import _ from 'lodash';
import React, { Component, Fragment } from "react";
import AddTeam from '../../../../../assets/images/icons/sibebar/add-team.svg'
import { tsThisType } from "@babel/types";
import { error } from "util";
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { notification, Icon, Input, Button, Spin, Row, Col, Form, Card, Tooltip, Pagination, Modal } from "antd";

// For search
const { Search } = Input;
const { confirm } = Modal;

// Pagination
function itemRender(current, type, originalElement) {
    if (type === 'prev') {
        return <a className="back-icon"> <Icon type="left" /> Back</a>;
    }
    if (type === 'next') {
        return <a className="next-icon">Next <Icon type="right" /></a>;
    }
    return originalElement;
}

class AddTeams extends Component {
    constructor(props) {
        super(props)
        this.state = {
            teams: null,
            newteam: null,
            loading: false,
            total: 0,
            per_page: 1,
            current_page: 1
        }
    }

    componentDidMount() {
        this.getTeamsData()
    }

    handleAddNewTeam = e => {
        e.preventDefault();
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                this.props.addNewTeam(values['title'])
                    .then(result => {
                        notification.success({
                            message: `Team added successfully`
                        });
                        this.getTeamsData()
                    })
                    .catch(error => {
                        const error_code = _.get(error, 'response.status');
                        if (error_code && error_code == '422') {
                            notification['error']({
                                message: Object.values(_.get(error, 'response.data.errors', "Oops! Something went wrong!"))
                            });
                        }
                        else {
                            notification['error']({
                                message: _.get(error, 'response.data.errors.message', "Oops! Something went wrong!")
                            });
                        }
                        this.setState({ loading: false });
                    })
            }
        });
    }


    getTeamsData = (pageNumber = 1) => {
        this.setState({ loading: true })
        return this.props.getTeams(pageNumber)
            .then((res) => {
                this.setState({
                    teams: res.data,
                    loading: false,
                    total: res.meta.pagination.total,
                    per_page: res.meta.pagination.per_page,
                    current_page: res.meta.pagination.current_page
                })
                return res
            })
            .catch((error) => {
                this.setState({ loading: true })
                notification.error({
                    message: error.response.data.errors.message
                })
            })
    }

    onPageChange = (pageNumber) => {
        this.setState({ loading: true });
        this.getTeamsData(pageNumber)
            .then(res => {
                this.setState({
                    teams: res.data,
                    total: res.meta.pagination.total,
                    per_page: res.meta.pagination.per_page,
                    current_page: res.meta.pagination.current_page,
                    loading: false
                });
            })
            .catch(error => {
                notification['error']({
                    message: "Oops! Something went wrong!"
                });
                this.setState({ loading: false });
            })
    }

    handleDeleteteam = (slug) => {

        const that = this;
        confirm({
            title: 'Do you Want to remove the Team?',
            icon: <ExclamationCircleOutlined />,
            onOk() {
                that.props.deleteTeam(slug)
                    .then((result) => {
                        notification.success({
                            message: `Team deleted successfully`
                        });
                        that.getTeamsData();
                    })
                    .catch((error) => {
                        notification.error({
                            message: _.get(error, 'response.data.errors.message', 'Something went wrong')
                        });
                    })
            },
            onCancel() {
            },
        });
    }

    render() {
        const { getFieldDecorator } = this.props.form;
        const { teams, loading, total, per_page, current_page } = this.state
        return (
            <Fragment>
                {loading ? (<center><Spin /></center>) : (
                    <div className="">
                        <div className="addTeam-container addAction-container">
                            {/* page Header */}
                            {/* <div className="page-header">
                            <div className="page-title">
                                <h2 className="title"><img className="icon icon-view" src={AddTeam} alt="view icon" /> Add Team</h2>
                            </div>
                        </div> */}

                            {/* <section className="search-section">
                            <div className="search-box">
                                <Search placeholder="Search" />
                            </div>
                        </section> */}

                            {/* Add new skill container */}
                            <section className="add-team-box add-item-box card-shadow">
                                <div className="heading">
                                    <h3 className="title text-primary">Add new Team</h3>
                                </div>
                                <form>
                                    <Row className="" gutter={30}>

                                        <Col className="gutter-row" xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
                                            <div className="form-content form-group">
                                                <Form.Item label="Team Name" colon={false} >
                                                    {getFieldDecorator('title', {
                                                        rules: [
                                                            {
                                                                required: true,
                                                                message: "Enter a valid Team name"
                                                            }
                                                        ]
                                                    })(
                                                        <Input className="input" placeholder="Enter new Team" onBlur={e => e.target.value = e.target.value.trim()} onPressEnter={this.handleAddNewTeam} />
                                                    )}
                                                </Form.Item>
                                            </div>
                                        </Col>
                                        <Col className="gutter-row" xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
                                            <div className="form-content form-group text-right sm-row-align">
                                                <Form.Item label="Add this Team" colon={false} className="add-team-label" >
                                                    <Button className="btn btn-light-primary text-uppercase" onClick={this.handleAddNewTeam}>+ Add this Team</Button>
                                                </Form.Item>
                                            </div>
                                        </Col>

                                    </Row>
                                </form>
                            </section>

                            {/* card section */}
                            <section className="content-section card-section team-cards">
                                <Row className="" gutter={30}>
                                    {teams && teams.map((team, index) => (
                                        <Col className="gutter-row" xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
                                            <Card className="card card-shadow teams-card">
                                                <div className="icon-box">
                                                    <img className="icon icon-link" src={AddTeam} alt="team image" />
                                                </div>
                                                <div className="title-box">
                                                    <h4 className="title">{team.title}</h4>
                                                </div>
                                                <div className="action-box">
                                                    {/* <Tooltip placement="bottom" title={'Edit'}><Button className="btn btn-light-info btn-icon"><Icon type="edit" theme="filled" /></Button></Tooltip> */}
                                                    <Tooltip placement="bottom" title={'Delete'}><Button onClick={() => this.handleDeleteteam(team.slug, team.title)} className="btn btn-light-danger btn-icon" ><Icon type="delete" theme="filled" /></Button></Tooltip>
                                                </div>
                                            </Card>
                                        </Col>
                                    ))}
                                </Row>

                                {total && per_page && total > per_page &&
                                    <div className="pagination-box text-right">
                                        <Pagination className="pagination" onChange={this.onPageChange} current={current_page} total={total} itemRender={itemRender} pageSize={per_page} />
                                    </div>
                                }
                            </section>

                        </div>
                    </div>
                )}
            </Fragment>
        )
    }
}

export default Form.create({ name: 'add_teams' })(AddTeams);