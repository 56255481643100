import React, { Component } from "react";
import GlobalContext from "../contexts/GlobalContext";
import { axiosInstance } from "../utilities/configureAxios";
import _ from "lodash";

export default class StoreProvider extends Component {
  constructor() {
    super();
    let data = localStorage.getItem("data");
    if (data) {
      data = JSON.parse(data);
      const access_token = _.get(data, "user.token");
      if (access_token) {
        axiosInstance.defaults.headers.common[
          "Authorization"
        ] = `Bearer ${access_token}`;
      }
    } else {
      data = {
        user: null,
        profile_pic: null,
        user_roles_name: [],
        activeTab: -1,
        is_profile_complete: false,
      };
    }
    this.state = data;
  }

  setData = (data) => {
    this.setState(data, () => {
      localStorage.setItem("data", JSON.stringify(this.state));
    });
  };

  logout = () => {
    this.setData({
      user: null,
      profile_pic: null,
      user_roles_name: [],
      activeTab: -1,
    });
    localStorage.setItem("requestBody", null);
  };

  render() {
    return (
      <GlobalContext.Provider
        value={{
          data: this.state,
          setData: this.setData,
          logout: this.logout,
        }}
      >
        {this.props.children}
      </GlobalContext.Provider>
    );
  }
}
