export default {
  getEmployeeProjectList: "/projects/employees-project-list",
  projectList: "/projects",
  getEmployees: "/employees",
  getEmployeeDetails: "/profile",
  getDocuments: "/documents",
  paginatedDocuments: "/documents?page=",
  createDocument: "/documents/create",
  uploadAttendence: "/attendance/upload",
  getRoles: "/roles",
  getManagers: "/managers",
  getTeams: "/teams",
  getLevels: "/levels",
  getSkills: "/skills",
  addUserSkill: "/skills",
  createUser: "/users",
  usersProjects: "/users/projects",
  createContractUser: "/users/contact/onboarding",
  getHolidays: "/holidays",
  createHolidays: "/holidays/create",
  getProfile: "/profile",
  getProfileWithSkills: "/profile?includes=skills,hobbies",
  myRoles: "/roles/self",
  mySkills: "/my-skills",
  userRoles: "/roles",
  suggestSkills: "/skills/suggest",
  sendOtp: "/mobile-login",
  verifyOtp: "/verify-otp",
  getIntercoms: "/intercoms",
  updateIntercoms: "/intercoms",
  deleteIntercoms: "/intercoms",
  addIntercoms: "/intercoms",
  getUserByEmail: "users/get-by-email",
  getEmployeeList: "/employees/export",
  getHobbies: "/hobbies",
  addUserHobby: "/hobbies",
  myHobbies: "/my-hobbies",
  suggestHobbies: "/hobbies/suggest",
};
