import React, { Component, Fragment } from 'react';
import { Row, Col, Input, Form, Spin, notification, Button } from 'antd';
import {  updateBillStatus } from '../../../../services/billService';

// for select box

// for textarea
const { TextArea } = Input;

// for upload
function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}
class BillReason extends Component {
  constructor(props) {
    super(props);
    this.state = {
      reason: '',
      isLoading: false
    };
  }

  showNotification = (type, title, subtitle) => {
    notification[type]({
      message: title,
      description: subtitle
    });
  };


  submitReason = (e) => {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll(async (err, formData) => {
    if(!err){ 
     this.props.setIsStatusUpdateLoading(true);
     updateBillStatus(this.props.id, { status: 'disapproved', decline_reason: this.state.reason })
      .then((res) => {
        this.props.setIsStatusUpdateLoading(false);
        this.props.setIsBillModified(true);
        this.props.closeDrawer();
        this.showNotification('success', 'Updated Bill Status Successfully');
      })
      .catch((error) => {
        this.props.setIsStatusUpdateLoading(false);
        if (error.response.status === 422) {
          notification.error("Something went wrong.");
        } else {
          notification.error(error.response.data.errors);
        }
      });
    }
  });
  };

  render() {
    const {getFieldDecorator } = this.props.form;
    return (
      <Fragment>
        <div className='uploadBill-container'>
          <form>
            <div className='form-content'>
              <Row className='row'>
                <Col className='col' xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                  <div className='form-group'>
                    <Form.Item label='Enter Reason' colon={false}>
                    { getFieldDecorator("reason", { 
                        rules: [
                          {
                            required: true,
                            message: "Enter reason",
                          },
                          { whitespace  : true, message: '(No White Space Allowed)' },
                        ],
                      })
                      (
                      <TextArea
                        placeholder='Enter here...'
                        autoSize={{ minRows: 5, maxRows: 5 }}
                        style={{resize: 'none' }}
                        maxLength={255}
                        allowClear
                        onChange={(e) => this.setState({ reason: e.target.value })}
                        value={this.state.reason}
                      />)}
                    </Form.Item>
                  </div>
                </Col>
              </Row>
            </div>
          </form>
        </div>
        <div className='formAction-footer-cont'>
          <div className='formAction-footer text-right'>
            <div className='upload-bill-btn-container'>
              <Spin spinning={this.props.isStatusUpdateLoading}>
                <Button onClick={this.submitReason} className='btn btn-success btn-wide text-uppercase'>
                  Submit
                </Button>
              </Spin>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default Form.create()(BillReason);
