import React, { Component, Fragment } from "react";
import { Link } from 'react-router-dom';
import { Layout } from "antd";
import { Helmet } from 'react-helmet'
import lottie from 'lottie-web';
import animationData from '../../../assets/animation/json/404New';

const TITLE = '500 Internal Server Error'

// for lottie
let animObj = null;

export default class Error500 extends Component {

    // for lottie
    componentDidMount() {
        //call the loadAnimation to start the animation
        animObj = lottie.loadAnimation({
            container: this.animBox, // the dom element that will contain the animation
            renderer: 'svg',
            loop: true,
            autoplay: true,

            animationData: animationData // the path to the animation json
        });
    }

    render() {
        return (
            <Fragment>
                <Helmet>
                    <title>{TITLE}</title>
                </Helmet>
                <div className="error-container">
                    {/* <div className="lottie-amination" ref={ ref => this.animBox = ref}></div> */}
                    <div className="errorPage-content">
                        <div className="error-box">
                            <div className="oops-content bold fontSize-lg">oops!</div>
                            <div className="error-no fontSize-xxl">500</div>
                            <div className="error-message fontSize-lg">Internal Server Error</div>
                            <p className="error-note fontSize-md">The server has been deserted for a while.<br /> Please be patient or try again later.</p>
                            <Link to="/dashboard/home" className="btn btn-light-primary text-uppercase back-btn">Back to Home</Link>
                        </div>
                    </div>
                </div>
            </Fragment>
        );
    }
}
