import React, { Component, Fragment } from "react";
import { Layout } from "antd";
import { Helmet } from "react-helmet";

import DailyStatusDashboard from "./components/DailyStatusDashboard";

const TITLE = 'Work log Management'

export default class DailyStatusManagement extends Component {
    render() {
        return (
            <Fragment>
                <Helmet>
                    <title>{TITLE}</title>
                </Helmet>
                <div className="page-wrapper">
                    <DailyStatusDashboard></DailyStatusDashboard>
                </div>
            </Fragment>
        );
    }
}
