import React, { Component, Fragment } from "react";
import { Form, notification, Input, Button, Row, Col, Switch } from "antd";
import _ from 'lodash';
import { axiosInstance as api } from '../../../../utilities/configureAxios';
import url from '../../../../config/url';
import { withStore } from '../../../../hocs/withStore';
import ForAdminAndHr from '../../../../hocs/ForAdminAndHr';

class AdminEditBankDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            bank_details: null,
            employee_id: null,
        };
    }

    static getDerivedStateFromProps(props, state) {
        return {
            bank_details: props.bank_details,
            employee_id: props.employee_id,
        };
    }

    handleSubmit = e => {
        e.preventDefault();
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                this.setState({ loading: true });
                var formData = new FormData()
                formData.append('profile_tab', 'bank')
                formData.append('account_number', values.account_number)
                formData.append('bank_name', values.bank_name)
                formData.append('aadhar_number', values.aadhar_number)
                formData.append('pan_number', values.pan_number)
                formData.append('ifsc', values.ifsc)
                formData.append('pf_deduction', values.pf_deduction == true ? 1 : values.pf_deduction == false ? 0 : this.state.bank_details && this.state.bank_details.pf_deduction == 'yes' ? 1 : 0)
                formData.append('esi_deduction', values.esi_deduction == true ? 1 : values.esi_deduction == false ? 0 : this.state.bank_details && this.state.bank_details.esi_deduction == 'yes' ? 1 : 0)
                api.
                    post(url["createUser"] + "/" + this.state.employee_id, formData)
                    .then(data => {

                        notification['success']({
                            message: "Financial details updated successfully!"
                        });
                        this.setState({ loading: false });
                    }).catch(error => {
                        const error_code = _.get(error, 'response.status');
                        if (error_code && error_code == '422') {
                            notification['error']({
                                message: Object.values(_.get(error, 'response.data.errors', "Oops! Something went wrong!"))
                            });
                        }
                        else {
                            notification['error']({
                                message: _.get(error, 'response.data.errors.message', "Oops! Something went wrong!")
                            });
                        }
                        this.setState({ loading: false });
                    });

            }
        });
    };

    render() {
        const { bank_details, loading } = this.state;
        const { getFieldDecorator } = this.props.form;
        return (
            <Fragment>
                <section className="tab-content-container bank-details-tab card-shadow">
                    <h3 className="title text-primary">Bank Details</h3>
                    <form>
                        <Row className="" gutter={30}>
                            <Col className="gutter-row" xs={24} sm={24} md={24} lg={6} xl={6}>
                                <div className="form-content form-group">
                                    <Form.Item label="Account Number">
                                        {getFieldDecorator('account_number', {
                                            initialValue: bank_details ? bank_details.account_number : "",
                                            rules: [{ required: true, message: 'Please enter account no!' },
                                            { max: 20, min: 5, message: 'Please enter valid account no.!' }],
                                            validateTrigger: 'onBlur'
                                        })(
                                            <Input className="input" maxLength="20" minLength="5" placeholder="Enter Account No." onBlur={e => e.target.value = e.target.value.replace(/\D/g, '')} />
                                        )}
                                    </Form.Item>
                                </div>
                            </Col>
                            <Col className="gutter-row" xs={24} sm={24} md={24} lg={6} xl={6}>
                                <div className="form-content form-group">
                                    <Form.Item label="Bank Name">
                                        {getFieldDecorator('bank_name', {
                                            initialValue: bank_details ? bank_details.bank_name : "",
                                            rules: [{ required: true, message: 'Please enter bank name!' }],
                                            validateTrigger: 'onBlur'
                                        })(
                                            <Input className="input" maxLength="255" placeholder="Enter bank name" onBlur={e => e.target.value = e.target.value.trim()} />
                                        )}
                                    </Form.Item>
                                </div>
                            </Col>
                            <Col className="gutter-row" xs={24} sm={24} md={24} lg={6} xl={6}>
                                <div className="form-content form-group">
                                    <Form.Item label="IFSC Code">
                                        {getFieldDecorator('ifsc', {
                                            initialValue: bank_details ? bank_details.ifsc : "",
                                            rules: [{ required: true, message: 'Please enter IFSC Code!' },
                                            { pattern: new RegExp("^[A-Z0-9]+$"), message: 'Please enter valid IFSC Code!' }],
                                            validateTrigger: 'onBlur'
                                        })(
                                            <Input className="input" maxLength="255" placeholder="Enter IFSC Code" onBlur={e => e.target.value = e.target.value.trim().toUpperCase()} />
                                        )}
                                    </Form.Item>
                                </div>
                            </Col>
                            <Col className="gutter-row" xs={12} sm={12} md={12} lg={3} xl={3}>
                                <div className="form-content form-group text-center switch-btn-box">
                                    <Form.Item label="PF Deduction">
                                        {getFieldDecorator('pf_deduction', {
                                        })(
                                            <Switch className="switch-btn" defaultChecked={bank_details ? bank_details.pf_deduction == "yes" ? true : false : false} />
                                        )}
                                    </Form.Item>
                                </div>
                            </Col>
                            <Col className="gutter-row" xs={12} sm={12} md={12} lg={3} xl={3}>
                                <div className="form-content form-group text-center switch-btn-box">
                                    <Form.Item label="ESI Deduction">
                                        {getFieldDecorator('esi_deduction', {
                                        })(
                                            <Switch className="switch-btn" defaultChecked={bank_details ? bank_details.esi_deduction == 'yes' ? true : false : false} />
                                        )}
                                    </Form.Item>
                                </div>
                            </Col>
                        </Row>

                        <h3 className="title text-primary">Other Financial Details</h3>
                        <Row className="" gutter={30}>
                            <Col className="gutter-row" xs={24} sm={24} md={24} lg={6} xl={6}>
                                <div className="form-content form-group">
                                    <Form.Item label="Aadhar Number" colon={false}>
                                        {getFieldDecorator('aadhar_number', {
                                            initialValue: bank_details ? bank_details.aadhar_number : "",
                                            rules: [{ required: true, message: 'Please enter Aadhar Number!' },
                                            { pattern: new RegExp("^[A-Z0-9]+$"), message: 'Please enter valid Aadhar Number!' }],
                                            validateTrigger: 'onBlur'
                                        })(
                                            <Input className="input" maxLength="12" minLength="12" placeholder="Enter Aadhar Number." onBlur={e => e.target.value = e.target.value.replace(/\D/g, '')} />
                                        )}
                                    </Form.Item>
                                </div>
                            </Col>

                            <Col className="gutter-row" xs={24} sm={24} md={24} lg={6} xl={6}>
                                <div className="form-content form-group">
                                    <Form.Item label="PAN Number" colon={false}>
                                        {getFieldDecorator('pan_number', {
                                            initialValue: bank_details ? bank_details.pan_number : "",
                                            rules: [{ required: true, message: 'Please enter PAN number!' },
                                            { pattern: new RegExp("^[A-Z0-9]+$"), message: 'Please enter valid PAN number!' }],
                                            validateTrigger: 'onBlur'
                                        })(
                                            <Input className="input" maxLength="10" placeholder="Enter PAN number" onBlur={e => e.target.value = e.target.value.trim().toUpperCase()} />
                                        )}
                                    </Form.Item>
                                </div>
                            </Col>
                        </Row>
                    </form>
                </section>

                <section className="profile-save-btn-box mt--30">
                    <div className="save-btn-box text-right">
                        <Button className="btn btn-success btn-wide text-uppercase" onClick={this.handleSubmit} loading={loading} disabled={loading}>Save</Button>
                    </div>
                </section>
            </Fragment>
        )
    }
}

export default Form.create({ name: 'bank_details_form' })(ForAdminAndHr(withStore(AdminEditBankDetails)));