import _ from 'lodash';
import { Row, Col, Icon, notification, Modal, Spin, Popover, Pagination } from "antd";
import lottie from 'lottie-web';
import moment from 'moment';
import React, { Component, Fragment } from "react";
import emptyState from '../../../../../assets/animation/json/emptyStateNew.json';
import { getAssetsHistory } from './DataManager'
import Flickity from 'react-flickity-component'

// for lottie
let animObj = null;

const itemRender = (current, type, originalElement) => {
  if (type === 'prev') {
    return <a className="back-icon"> <Icon type="left" /> Back</a>;
  }
  if (type === 'next') {
    return <a className="next-icon">Next <Icon type="right" /></a>;
  }
  return originalElement;
}

export default class AssetsHistory extends Component {

  constructor(props) {
    super(props)
    this.state = {
      historyObj: null,
      modal1Visible: false,
      historyloading: true,
      imageModalImage: null,
      historyIndex: null,
      pagination: {
        total: 0,
        count: 0,
        per_page: 1,
        current_page: 1,
        total_pages: 1
      }
    }
  }

  onPageChange = (pageNumber) => {
    this.setState({ loading: true });
    //const { current_page } = this.state
    this.getAssetsHistory(pageNumber)

  }

  setModal1Visible(modal1Visible) {
    this.setState({ modal1Visible });
  }

  // for lottie
  componentDidMount() {
    this.lottieEmptyState()
    this.getAssetsHistory()
  }


  lottieEmptyState() {
    //call the loadAnimation to start the animation
    animObj = lottie.loadAnimation({
      container: this.animBox, // the dom element that will contain the animation
      renderer: 'svg',
      loop: true,
      autoplay: true,
      animationData: emptyState // the path to the animation json
    });
  }

  getAssetsHistory(pageNumber = 1) {
    let assetId = this.props.assetid
    this.setState({ historyloading: true })
    return getAssetsHistory(assetId, pageNumber)
      .then(res => {
        const historyObj = _.get(res, 'data', [])
        const pagination = _.get(res, 'meta.pagination',
          {
            total: 0,
            count: 0,
            per_page: 1,
            current_page: 1,
            total_pages: 1
          }
        );

        this.setState({ historyObj, historyloading: false, pagination })
      })
      .catch(error => {
        notification.error({
          message: error.response.data.errors.message
        })
        this.setState({ historyloading: false })
      })
  } //setModal1Visible


  showImageModal = (index, historyIndex) => {
    this.setModal1Visible(true)
    this.setState({ imageModalImage: index })
    this.setState({ historyIndex: historyIndex })
  }



  render() {
    const { historyObj, historyloading } = this.state;
    const { total, per_page, current_page } = this.state.pagination;

    return (
      <Fragment>
        {historyloading ? <div className="page-spinner"> < Spin /></div > : (

          <div className="assetsHistory-Container">
            {/* Asset History List */}
            <div className="assetHistory-list animated slideInRight">
              <Row gutter={30} className="row">
                <Col className="col gutter-row" lg={24}>
                  <div className="histroyList-content">
                    <h2 className="historyList-title">Asset Allocation history 👍</h2>

                    {historyObj && historyObj.map((history, index) => (
                      <div className="asset-history-block" key={index}>
                        <div className="asset-history-view">
                          <div className="image-box">
                            <div className="user-img" style={{ backgroundImage: `url(${history.profile_pic})` }}></div>
                          </div>
                          <div className="history-description">
                            <div className="user-history-block">
                              <div className="user-info-box">
                                <div className="user-name text-primary">{history.assigned_to} </div>
                                <div className="action-box">
                                  <Popover placement="left"
                                    content={
                                      < div className="historyNotes-popoverContent">
                                        <div className="content">
                                          <div className="form-group">
                                            <label className="label">Allocation Notes</label>
                                            <div className="value">{history.allocation_notes ? history.allocation_notes : 'N/A'}</div>
                                          </div>
                                          <div className="form-group">
                                            <label className="label">Deallocation Notes</label>
                                            <div className="value">{history.deallocation_notes ? history.deallocation_notes : 'N/A'}</div>
                                          </div>
                                        </div>
                                      </div>
                                    }
                                    title="Asset Allocation/Deallocation Logs"
                                    trigger="click">
                                    <Icon type="more" />
                                  </Popover>
                                </div>
                              </div>
                              {/* {history.returned_at ?
                              <div className="status-badge success">Deallocated</div>
                              :
                              <div className="status-badge danger">Allocated</div>} */}
                              <div className="timeStamp-block">
                                <div className="time-stamp from"><span className="icon">⏱ <span className="status-badge success">Allocated</span></span> {moment(history.assigned_at).format('LLLL')}</div>
                                {history.returned_at ?
                                  <div className="time-stamp to"><span className="icon">⏱ <span className="status-badge danger">Deallocated</span></span> {moment(history.returned_at).format('LLLL')}</div>
                                  :
                                  <div className="time-stamp to"><span className="icon">⏱</span> Not Returned</div>}
                              </div>
                            </div>
                          </div>
                        </div>
                        {
                          history.allocated_images[0] &&
                          <Fragment>
                            <div className="history-imageBox" style={{ marginLeft: '0rem', marginBottom: '10px' }}>
                              <div className="">
                                <label className="label">Related Images</label>
                                <div className="image-box mt--10" >
                                  {history.allocated_images.map((image, idx) => (
                                    <div className="historyImg">
                                      <div className="history-img" style={{ backgroundImage: `url(${image.url})` }}></div>
                                      <div className="img-overlay" onClick={() => this.showImageModal(idx, index)} ><Icon className="view-icon" type="eye" /></div>
                                    </div>
                                  ))}
                                </div>
                              </div>
                            </div>
                          </Fragment>
                        }

                        {historyObj && history.deallocated_by &&
                          <div className="asset-deallocateBy-box " style={{ marginLeft: '0rem' }}>
                            <p className="deallocatedBy-text">
                              <ul>
                                <li><strong className="user-name">{history.deallocated_by}</strong> deallocated this Asset.</li>
                              </ul>
                            </p>
                          </div>
                        }
                        {/* </div> */}
                        {/* </div> */}
                      </div>
                    ))}
                  </div>
                </Col>
              </Row>

              {total > 0 && per_page > 0 && total > per_page &&
                <div className="pagination-box text-right">
                  <Pagination className="pagination" onChange={this.onPageChange} current={current_page} total={total} itemRender={itemRender} pageSize={per_page} />
                </div>
              }
            </div>


            {/* empty state */}
            {historyObj && historyObj.length == 0 &&
              < div className="emptyState-container animated slideInUp">
                <Row gutter={30} className="row">
                  <Col className="col gutter-row" lg={24}>
                    <div className="emptyStateContent">
                      <div className="lottieAnimaton" ref={ref => this.animBox = ref}></div>
                      <h2 className="noData-text text-center">No History found!</h2>
                    </div>
                  </Col>
                </Row>
              </div>
            }

          </div>
        )
        }
        <Modal
          className="history-assetImage"
          title="View Asset history image"
          centered
          visible={this.state.modal1Visible}
          destroyOnClose
          footer={false}
          onCancel={() => { this.setState({ imageModalImage: null }); this.setModal1Visible(false) }}
        >
          {this.state.imageModalImage !== null &&
            <div className="history-assetImageContainer">
              <Flickity
                className={'carousel'}
                elementType={'div'}
                options={{
                  initialIndex: this.state.imageModalImage
                }}
                disableImagesLoaded={false}
                reloadOnUpdate
                static
              >
                {historyObj[this.state.historyIndex].allocated_images.map((image, index) => (
                  <div className="carousel-img" style={{ backgroundImage: `url(${image.url})` }} />
                ))}
              </Flickity>
            </div>
          }
        </Modal>
      </Fragment >
    )
  }
}