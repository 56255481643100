import React, { Component } from "react";
import { Link } from "react-router-dom";
import {
  notification,
  Menu,
  Icon,
  Upload,
  message,
  Spin,
  Switch,
  Popover,
  Button,
} from "antd";
import logo from "../../assets/images/full-logo-white.svg";
import signout from "../../assets/images/icons/signout.svg";
import RequireAuth from "../../hocs/RequireAuth";
import _ from "lodash";
import imageCompression from "browser-image-compression";
import { axiosInstance as api } from "../../utilities/configureAxios";
import DashBoardMobile from "../../app/dashboard/components/DashBoardMobile";
import bill from "../../assets/images/icons/sibebar/bill-management.svg";

// for sidebar icons
import TeamDirectory from "../../assets/images/icons/sibebar/team-directory.svg";
import CompanyDocument from "../../assets/images/icons/sibebar/company-documents.svg";
import Holiday from "../../assets/images/icons/sibebar/public-holidays.svg";
import Settings from "../../assets/images/icons/sibebar/settings.svg";
import bell from "../../assets/images/icons/notification.svg";
import ItAssets from "../../assets/images/icons/sibebar/it-assets.svg";
import leave from "../../assets/images/icons/sibebar/leave-management.svg";
import intercom from "../../assets/images/icons/sibebar/intercom-directory.svg";
import dailyStatus from "../../assets/images/icons/sibebar/daily-work-status.svg";
import attendees from "../../assets/images/icons/sibebar/attendees.svg";
import ProjectIcon from "../../assets/images/icons/sibebar/sprint-management.svg";
import Home from "../../assets/images/icons/sibebar/home.svg";

// year selectbox

class MobileSider extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user_roles_name: _.get(props, "store.data.user_roles_name", []),
      profile_pic: _.get(props, "store.data.profile_pic", null),
      auth_user: _.get(props, "store.data.user", []),
      imageLoading: false,
      isHovering: false,
      web: false,
      sms: false,
      notification_popover: false,
      notification_loading: false,
    };
  }

  componentDidMount() {
    this.getNotificationPreference();
  }

  getNotificationPreference = () => {
    this.setState({ notification_loading: true });
    api
      .get("/notification-preferences")
      .then((res) => {
        this.setState({
          web: res.data.data.web_push_notification,
          sms: res.data.data.sms_notification,
        });
        this.setState({ notification_loading: false });
      })
      .catch((error) => {
        this.setState({ notification_loading: false });
        notification["error"]({
          message: "Oops! An error occurred",
        });
      });
  };

  setNotificationPreference = () => {
    this.setState({ notification_loading: true });
    let body = {
      sms_notification: this.state.sms ? 1 : 0,
      web_push_notification: this.state.web ? 1 : 0,
    };

    api
      .post("/notification-preferences", body)
      .then((res) => {
        this.getNotificationPreference();
        this.setState({
          notification_loading: false,
          notification_popover: false,
        });
        // notification['success']({
        //     message: "Notification preferences saved"
        // })
        message.success("Notification preferences saved", 3);
      })
      .catch((error) => {
        this.setState({
          notification_loading: false,
          notification_popover: false,
        });
        notification["error"]({
          message: "Oops! An error occurred",
        });
      });
  };

  logout = () => {
    const { logout } = this.props.store;
    let device_id = document.cookie.replace(
      /(?:(?:^|.*;\s*)device_id\s*\=\s*([^;]*).*$)|^.*$/,
      "$1"
    );
    this.setState({ loading: true });
    logout();
    api
      .get("/logout" + "?id=" + device_id)
      .then((res) => {
        this.setState({ loading: false });
        notification["success"]({
          message: "Logged out successfully!",
        });
      })
      .catch((error) => {
        notification["error"]({
          message: _.get(
            error,
            "response.data.errors.message",
            "Oops! Something went wrong!"
          ),
        });
        this.setState({ loading: false });
      });
  };

  handleHoverChange = () => {
    this.setState({
      notification_popover: !this.state.notification_popover,
    });
  };

  // for notification popover
  content = () => (
    <div className="switch-notification-box">
      <div className="notification-switch web">
        <div className="title">Web</div>
        <div className="switch-box">
          <Switch
            defaultChecked={this.state.web}
            onChange={(checked) => {
              this.setState({ web: checked });
            }}
          />
        </div>
      </div>
      <div className="notification-switch web">
        <div className="title">SMS</div>
        <div className="switch-box">
          <Switch
            defaultChecked={this.state.sms}
            onChange={(checked) => {
              this.setState({ sms: checked });
            }}
          />
        </div>
      </div>
      <div className="notification-switch web">
        <div className="title">Email</div>
        <div className="switch-box">
          <Switch defaultChecked={true} disabled={true} />
        </div>
      </div>
      <div className="save-notification-btn">
        <Button
          className="btn btn-success full-width"
          disabled={this.state.notification_loading}
          loading={this.state.notification_loading}
          onClick={() => this.setNotificationPreference()}
        >
          Save
        </Button>
      </div>
    </div>
  );

  render() {
    const {
      data: { user },
    } = this.props.store;
    const { user_roles_name, profile_pic, imageLoading, auth_user } =
      this.state;
    const admin = user_roles_name.indexOf("admin") !== -1;
    const hr = user_roles_name.indexOf("hr") !== -1;
    const offc_admin = user_roles_name.indexOf("office_admin") !== -1;
    const project_manager = user_roles_name.indexOf("project_manager") !== -1;
    const on_contract_employee =
      user_roles_name.indexOf("contract_employee") != -1;
    const employee = user_roles_name.indexOf("employee") != -1;

    const is_reporting_manager =
      user_roles_name.indexOf("reporting_manager") !== -1;
    return (
      <div className="mobile-screen-container">
        <div className="header-box">
          <Link className="logo-link" to="/dashboard/Mobile">
            <div className="logo-box">
              <img className="company-logo" src={logo} alt="logo" />
            </div>
          </Link>
          <div className="action right">
            <div className="notification-icon-nav">
              <Popover
                content={this.content()}
                disabled={this.state.notification_loading}
                trigger="click"
                placement="bottom"
                title="Notification"
              >
                <img
                  className="notification-icon"
                  src={bell}
                  alt="notification icon"
                />
              </Popover>
            </div>

            <div className="user-profile-nav">
              <Link className="profile-link" to="/dashboard/profile">
                <div
                  className="user-img"
                  style={{ backgroundImage: `url(${profile_pic})` }}
                ></div>
              </Link>
            </div>
            <div className="logout-icon-nav">
              <img
                className="logout-icon"
                src={signout}
                onClick={this.logout}
                alt="signout icon"
              />
            </div>
          </div>
        </div>
        <div className="user-info-box">
          <div className="user-img animated bounceInDown">
            <Upload
              {...this.propse}
              showUploadList={false}
              beforeUpload={(file) => {
                var options = {
                  maxSizeKB: 500,
                  maxWidthOrHeight: 200,
                  useWebWorker: true,
                };
                this.setState({ imageLoading: true });
                const {
                  store: { setData },
                } = this.props;
                imageCompression(file, options)
                  .then((compressedFile) => {
                    const formData = new FormData();
                    formData.append("profile_image", compressedFile);
                    api
                      .post("profile-pic", formData)
                      .then((data) => {
                        api
                          .get("profile-pic")
                          .then((res) => {
                            const profile_pic = _.get(
                              res,
                              "data.data.profile_pic",
                              null
                            );
                            this.setState({ profile_pic, imageLoading: false });
                            setData({ profile_pic });
                            notification["success"]({
                              message: "Profile pic changed successfully!",
                            });
                          })
                          .catch((error) => {
                            notification["error"]({
                              message: _.get(
                                error,
                                "response.data.errors.message",
                                "Could not get your profile picture!"
                              ),
                            });
                            this.setState({ profile_pic, imageLoading: false });
                          });
                      })
                      .catch((err) => {
                        message.error("file upload failed.");
                        this.setState({
                          imageLoading: false,
                        });
                      });
                    return false;
                  })
                  .catch(function (error) {
                    this.setState({ imageLoading: false });
                    return false;
                  });
              }}
            >
              <label className="file-input-label" htmlFor="file-input">
                {/* <img className="image" src={ profile_pic } alt="User" /> */}
                <div
                  className="image"
                  style={{ backgroundImage: `url(${profile_pic})` }}
                ></div>
                <div className="img-overlay">
                  <Icon className="edit-icon" type="edit" />
                </div>
                {imageLoading && (
                  <div className="spinner">
                    <Spin></Spin>
                  </div>
                )}
              </label>
            </Upload>
          </div>
          <h2 className="user-name font-white animated fadeInLeftBig">
            {user.name}
          </h2>
          <h4 className="designation font-white animated fadeInLeftBig">
            {user.designation}
          </h4>
          <div className="font-white text-uppercase bg-transparent level-badge animated fadeInLeftBig">
            {user.level}
          </div>
        </div>

        <div className="mobile-menu-container">
          <Menu className="mobile-menu animated slideInUp" mode="inline">
            {
              <Menu.Item className="animated slideInUp" key="25">
                <Link to="/dashboard/Mobile" className="item-link">
                  <span className="item-icon-name">
                    <img
                      className="icon-img"
                      src={Home}
                      alt="team directory icon"
                    />
                    <span className="nav-text">Dashboard</span>
                  </span>
                </Link>
              </Menu.Item>
            }
            {(admin || hr) && (
              <Menu.Item className="animated slideInUp" key="0">
                <Link to="/dashboard/employee" className="item-link">
                  <span className="item-icon-name">
                    <img
                      className="icon-img"
                      src={TeamDirectory}
                      alt="team directory icon"
                    />
                    <span className="nav-text">Team Directory</span>
                  </span>
                </Link>
              </Menu.Item>
            )}
            {!admin && !hr && !on_contract_employee && (
              <Menu.Item className="animated slideInUp" key="1">
                <Link to="/dashboard/team-directory" className="item-link">
                  <span className="item-icon-name">
                    <img
                      className="icon-img"
                      src={TeamDirectory}
                      alt="team directory icon"
                    />
                    <span className="nav-text">Team Directory</span>
                  </span>
                </Link>
              </Menu.Item>
            )}
            {!admin && !on_contract_employee && (
              <Menu.Item className="animated slideInUp" key="2">
                <Link to="/dashboard/document" className="item-link">
                  <span className="item-icon-name">
                    <img
                      className="icon-img"
                      src={CompanyDocument}
                      alt="document icon"
                    />
                    <span className="nav-text">Documents</span>
                  </span>
                </Link>
              </Menu.Item>
            )}
            {!admin && !on_contract_employee && (
              <Menu.Item className="animated slideInUp" key="3">
                <Link to="/dashboard/holiday" className="item-link">
                  <span className="item-icon-name">
                    <img
                      className="icon-img"
                      src={Holiday}
                      alt="holiday icon"
                    />
                    <span className="nav-text">Public Holidays</span>
                  </span>
                </Link>
              </Menu.Item>
            )}
            {admin && (
              <Menu.Item className="animated slideInUp" key="6">
                <Link to="/dashboard/admin/document" className="item-link">
                  <span className="item-icon-name">
                    <img
                      className="icon-img"
                      src={CompanyDocument}
                      alt="document icon"
                    />
                    <span className="nav-text">Documents</span>
                  </span>
                </Link>
              </Menu.Item>
            )}

            {admin && (
              <Menu.Item className="animated slideInUp" key="7">
                <Link to="/dashboard/admin/holiday" className="item-link">
                  <span className="item-icon-name">
                    <img
                      className="icon-img"
                      src={Holiday}
                      alt="holiday icon"
                    />
                    <span className="nav-text">Public Holidays</span>
                  </span>
                </Link>
              </Menu.Item>
            )}

            {admin && (
              <Menu.Item className="animated slideInUp" key="8">
                <Link
                  to="/dashboard/admin/manage-intercom"
                  className="item-link"
                >
                  <span className="item-icon-name">
                    <img
                      className="icon-img"
                      src={intercom}
                      alt="Directory icon"
                    />
                    <span className="nav-text">Intercom Directory</span>
                  </span>
                </Link>
              </Menu.Item>
            )}

            {!admin && !on_contract_employee && (
              <Menu.Item className="animated slideInUp" key="9">
                <Link to="/dashboard/intercom" className="item-link">
                  <span className="item-icon-name">
                    <img
                      className="icon-img"
                      src={intercom}
                      alt="Directory icon"
                    />
                    <span className="nav-text">Intercom Directory</span>
                  </span>
                </Link>
              </Menu.Item>
            )}

            {(admin || offc_admin) && (
              <Menu.Item className="animated slideInUp" key="10">
                <Link
                  to="/dashboard/admin/manage-it-assets"
                  className="item-link"
                >
                  <span className="item-icon-name">
                    <img
                      className="icon-img"
                      src={ItAssets}
                      alt="ItAssets icon"
                    />
                    <span className="nav-text">Assets</span>
                  </span>
                </Link>
              </Menu.Item>
            )}

            {!admin && !offc_admin && !on_contract_employee && (
              <Menu.Item className="animated slideInUp" key="11">
                <Link
                  to="/dashboard/it-assets-management"
                  className="item-link"
                >
                  <span className="item-icon-name">
                    <img
                      className="icon-img"
                      src={ItAssets}
                      alt="ItAssets icon"
                    />
                    <span className="nav-text">Assets</span>
                  </span>
                </Link>
              </Menu.Item>
            )}

            {!admin && (
              <Menu.Item className="animated slideInUp" key="12">
                <Link to="/dashboard/work-reports" className="item-link">
                  <span className="item-icon-name">
                    <img
                      className="icon-img"
                      src={dailyStatus}
                      alt="daily status management icon"
                    />
                    <span className="nav-text">Work Logs</span>
                  </span>
                </Link>
              </Menu.Item>
            )}

            {(admin || hr || project_manager || is_reporting_manager) && (
              <Menu.Item className="animated slideInUp" key="13">
                <Link to="/dashboard/admin/work-reports" className="item-link">
                  <span className="item-icon-name">
                    <img
                      className="icon-img"
                      src={dailyStatus}
                      alt="daily status management icon"
                    />
                    <span className="nav-text">Manage Work Logs</span>
                  </span>
                </Link>
              </Menu.Item>
            )}

            {admin && (
              <Menu.Item className="animated slideInUp" key="23">
                <Link to="/dashboard/admin/attendance" className="item-link">
                  <span className="item-icon-name">
                    <img
                      className="icon-img"
                      src={attendees}
                      alt="attendance management icon"
                    />
                    <span className="nav-text">Manage Attendance</span>
                  </span>
                </Link>
              </Menu.Item>
            )}
            {!admin && !on_contract_employee && (
              <Menu.Item className="animated slideInUp" key="24">
                <Link to="/dashboard/attendance" className="item-link">
                  <span className="item-icon-name">
                    <img
                      className="icon-img"
                      src={attendees}
                      alt="attendance management icon"
                    />
                    <span className="nav-text">Attendance</span>
                  </span>
                </Link>
              </Menu.Item>
            )}

            {admin && (
              <Menu.Item className="animated slideInUp" key="14">
                <Link to="/dashboard/admin/settings" className="item-link">
                  <span className="item-icon-name">
                    <img
                      className="icon-img"
                      src={Settings}
                      alt="settings icon"
                    />
                    <span className="nav-text">Settings</span>
                  </span>
                </Link>
              </Menu.Item>
            )}

            {(admin || hr || is_reporting_manager) && (
              <Menu.Item className="animated slideInUp" key="17">
                <Link
                  to="/dashboard/admin/leave-management"
                  className="item-link"
                >
                  <span className="item-icon-name">
                    <img
                      className="icon-img"
                      src={leave}
                      alt="leave management icon"
                    />
                    <span className="nav-text">Manage Out of Office</span>
                  </span>
                </Link>
              </Menu.Item>
            )}

            {!admin && !hr && !on_contract_employee && (
              <Menu.Item className="animated slideInUp" key="18">
                <Link to="/dashboard/leave-management" className="item-link">
                  <span className="item-icon-name">
                    <img
                      className="icon-img"
                      src={leave}
                      alt="petty cash management icon"
                    />
                    <span className="nav-text">Out of Office</span>
                  </span>
                </Link>
              </Menu.Item>
            )}
            {(admin || hr) && (
              <Menu.Item className="animated slideInUp" key="17">
                <Link to="/dashboard/admin/announcements" className="item-link">
                  <span className="item-icon-name">
                    <img
                      className="icon-img"
                      src={leave}
                      alt="leave management icon"
                    />
                    <span className="nav-text">Manage Announcements</span>
                  </span>
                </Link>
              </Menu.Item>
            )}
            {/* {(admin || project_manager) && (
              <Menu.Item className="animated slideInUp" key="17">
                <Link
                  to="/dashboard/admin/project-dashboard"
                  className="item-link"
                >
                  <span className="item-icon-name">
                    <img
                      className="icon-img"
                      src={leave}
                      alt="leave management icon"
                    />
                    <span className="nav-text">Project Dashboard</span>
                  </span>
                </Link>
              </Menu.Item>
            )} */}

            {!admin && !hr && !on_contract_employee && (
              <Menu.Item className="animated slideInUp" key="18">
                <Link to="/dashboard/announcements" className="item-link">
                  <span className="item-icon-name">
                    <img
                      className="icon-img"
                      src={leave}
                      alt="petty cash management icon"
                    />
                    <span className="nav-text">Announcements</span>
                  </span>
                </Link>
              </Menu.Item>
            )}

            {(admin || project_manager) && (
              <Menu.Item className="animated slideInUp" key="19">
                <Link
                  to="/dashboard/admin/project-management"
                  className="item-link"
                >
                  <span className="item-icon-name">
                    <img
                      className="icon-img"
                      src={ProjectIcon}
                      alt="daily status management icon"
                    />
                    <span className="nav-text">Manage Projects</span>
                  </span>
                </Link>
              </Menu.Item>
            )}
            {employee && !project_manager && !admin && (
              <Menu.Item className="animated slideInUp" key="20">
                <Link to="/dashboard/projects" className="item-link">
                  <span className="item-icon-name">
                    <img
                      className="icon-img"
                      src={ProjectIcon}
                      alt="daily status management icon"
                    />
                    <span className="nav-text">My Projects</span>
                  </span>
                </Link>
              </Menu.Item>
            )}
            {(admin || auth_user.is_reporting_manager) && (
              <Menu.Item className="animated slideInUp" key="22">
                <Link
                  to="/dashboard/admin/bill-management"
                  className="item-link"
                >
                  <span className="item-icon-name">
                    <img
                      className="icon-img"
                      src={bill}
                      alt="Bill management icon"
                    />
                    <span className="nav-text">Manage Bills</span>
                  </span>
                </Link>
              </Menu.Item>
            )}

            {!admin && !offc_admin && (
              <Menu.Item className="animated slideInUp" key="23">
                <Link to="/dashboard/my-bills" className="item-link">
                  <span className="item-icon-name">
                    <img
                      className="icon-img"
                      src={bill}
                      alt="Bill management icon"
                    />
                    <span className="nav-text">My Bills</span>
                  </span>
                </Link>
              </Menu.Item>
            )}
          </Menu>
        </div>
      </div>
    );
  }
}
export default RequireAuth(MobileSider);
