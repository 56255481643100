import React, { Component, Fragment } from "react";
import { Row, Col, Icon, Upload, Modal, Tooltip, Button, Divider, Drawer } from "antd";
import moment from 'moment'

import Flickity from 'react-flickity-component';

// For Upload
function getBase64(file) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });
}

export default class CardContent extends Component {

    constructor(props) {
        super(props)
        this.state = {
            previewVisible: false,
            previewImage: '',
            assets: null,
            allocationType: null,
            fileList: [

            ],
        };
    }

    componentDidMount() {
        this.getAssetContents()
    }

    // componentDidUpdate(prevProps, prevState) {
    //     if (prevProps.assetContent !== this.props.assetContent)
    //         this.setState({ assets: this.props.assetContent })
    //     if (prevProps.allocationType !== this.props.allocationType)
    //         this.setState({ allocationType: this.props.allocationType })
    // }

    getAssetContents() {
        let assets = this.props.assetContent;
        let allocationType = this.props.allocationType
        this.setState({ assets: assets, allocationType: allocationType })
    }


    handleCancel = () => this.setState({ previewVisible: false });

    handlePreview = async file => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }

        this.setState({
            previewImage: file.url || file.preview,
            previewVisible: true,
        });
    };

    handleChange = ({ fileList }) => this.setState({ fileList });

    render() {

        // for Upload
        const { previewVisible, previewImage, fileList } = this.state;
        let assets = this.props.assetContent;
        let allocationType = this.props.allocationType

        const uploadButton = (
            <div>
                <Icon type="plus" />
                <div className="ant-upload-text">Add Photo</div>
            </div>
        );

        return (
            <Fragment>
                <div className="cardContent-container">
                    <div className="cardContent-body">
                        <div className="cardContent-flexView">
                            <div className="notes-content-block">
                                <div className="column">
                                    <div className="notes-box">
                                        <p className="notes"><strong className="text-uppercase">Notes: </strong>{assets && assets.notes}</p>
                                    </div>
                                </div>
                            </div>
                            <div className="assets-details-content-block">
                                <div className="column">
                                    <div className="assets-info form-group">
                                        <label className="label">Serial No:</label>
                                        <div className="value">{assets && assets.serial_number ? assets.serial_number : '-'}</div>
                                    </div>
                                    <div className="assets-info form-group">
                                        <label className="label">Status:</label>
                                        {assets && Object.values(assets.status)[0] == 'Active' ?
                                            <div className="status-badge success">{assets && Object.values(assets.status)[0]}</div>
                                            :
                                            <div className="status-badge danger">{assets && Object.values(assets.status)[0]}</div>}
                                    </div>
                                </div>
                                <div className="column">
                                    {allocationType != 'unallocated' &&
                                        <div className="assets-info form-group">
                                            <label className="label">Allocated At:</label>
                                            <div className="value">{assets && assets.allocations && assets.allocations.length == 1 && moment(assets.allocations[0].assigned_at, 'YYYY-MM-DD HH:mm:ss').format('DD-MMM-YYYY HH:mm')}</div>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>

                    {allocationType != 'unallocated' &&
                        <Row gutter={30} className="row">
                            <Col className="gutter-row col" xs={24} md={12} lg={12}>
                                <div className="clearfix">
                                    <Upload
                                        action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                                        listType="picture-card"
                                        fileList={assets && assets.images}
                                        onPreview={this.handlePreview}
                                        onChange={this.handleChange}
                                        showUploadList={{ showPreviewIcon: true, showRemoveIcon: false, showDownloadIcon: false }}
                                    >
                                        {null}
                                    </Upload>
                                    {/* <Modal className="previewImg-modal" visible={previewVisible} footer={null} onCancel={this.handleCancel}>
                                        <img alt="example" style={{ width: '100%' }} src={previewImage} />
                                    </Modal> */}
                                    <Modal className="previewImg-modal" visible={previewVisible} footer={null} onCancel={this.handleCancel}>
                                        <Flickity
                                            className={'carousel'}
                                            elementType={'div'}
                                        >
                                            {assets && assets.images.map((image, index) => (
                                                <div className="carousel-img" style={{ backgroundImage: `url(${image.url})` }} />
                                            ))}
                                        </Flickity>
                                    </Modal>
                                </div>
                            </Col>
                        </Row>
                    }

                    {allocationType == 'unallocated' &&
                        <Row gutter={30} className="row">
                            <Col className="gutter-row col" xs={24} md={12} lg={12}>
                                <div className="clearfix">
                                    <Upload
                                        action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                                        listType="picture-card"
                                        fileList={assets && assets.images}
                                        onPreview={this.handlePreview}
                                        onChange={this.handleChange}
                                        showUploadList={{ showPreviewIcon: true, showRemoveIcon: false, showDownloadIcon: false }}
                                    >
                                        {null}
                                    </Upload>
                                    {/* <Modal className="previewImg-modal" visible={previewVisible} footer={null} onCancel={this.handleCancel}>
                                        <img alt="example" style={{ width: '100%' }} src={previewImage} />
                                    </Modal> */}
                                    <Modal className="previewImg-modal" visible={previewVisible} footer={null} onCancel={this.handleCancel}>
                                        <Flickity
                                            className={'carousel'}
                                            elementType={'div'}
                                        >
                                            {assets && assets.images.map((image, index) => (
                                                <div className="carousel-img" style={{ backgroundImage: `url(${image.url})` }} />
                                            ))}
                                        </Flickity>
                                    </Modal>
                                </div>
                            </Col>
                        </Row>
                    }
                </div>

            </Fragment >
        )
    }
}