import React, { Fragment, useState } from "react";
import {
  Spin,
  Button,
  Icon,
  Input,
  Table,
  Tooltip,
  Modal,
  notification,
} from "antd";
import { Helmet } from 'react-helmet';
import moment from "moment";
import nottification from "../../assets/images/icons/notification.svg";
import ViewNotification from "./components/ViewNotification";
import {
  getNotificationId,
} from "../admin/components/LeaveManagement/DataManager";
import { withStore } from "../../hocs/withStore";
import RequireAuth from "../../hocs/RequireAuth";
import ProfileComplete from "../../hocs/ProfileComplete";
import NotificationUpdate from "../../hocs/NotificationUpdate";

const EmployeeNotification = (props) => {
  const [loading, setLoading] = useState(false);
  const [viewModalVisible, setViewModalVisible] = useState(false);
  const [notificationListData, setNotificationListData] = useState([]);
  const [viewCardData, setViewCardData] = useState("");
  const [viewForAdmin, setViewForAdmin] = useState(false);
  const [searchById, setSearchById] = useState("");
  const [searchByTitle, setSearchByTitle] = useState("");
  const [pageCurrent, setpageCurrent] = useState(1);

  const loadNotificationIdData = (
    page = 1,
    searchId = "",
    searchTitle = ""
  ) => {
    setLoading(true);
    return getNotificationId(page, searchId, searchTitle)
      .then((res) => {
        setNotificationListData(res);
        setLoading(false);
      })
      .catch((error) => {
        error && error.response && error.response.data && error.response.data.errors &&
        notification.error(error.response.data.errors);
      });
  };

  // For getting notification List
  React.useEffect(() => {
    loadNotificationIdData();
  }, []);
  const viewModalData = (id) => {
    if (notificationListData && notificationListData.data.length > 0) {
      const viewDataFilter = notificationListData.data.filter(
        (value) => value.notification_id === id
      );
      setViewCardData(viewDataFilter[0]);
      setViewModalVisible(true);
    }
  };
  var columns = [
    {
      title: "S.No.",
      key: "index",
      className: "",
      render: (text, object, index) => (
        <div>{(pageCurrent - 1) * 10 + (index + 1)}</div>
      ),
    },

    {
      title: "Announcement ID",
      dataIndex: "notification_id",
      className: "name",
      render: (name, user) => {
        if (user.is_x !== "0" || user.disable !== "0") {
          return <div className="disabled_user">{name}</div>;
        }
        return name;
      },
    },
    {
      title: "Title",
      dataIndex: "title",
      className: "name",
      render: (name, user) => {
        if (user.is_x !== "0" || user.disable !== "0") {
          return <div className="disabled_user">{name}</div>;
        }
        return name;
      },
    },
    {
      title: "Date",
      dataIndex: "announcement_date",
      render: (join_date, user) => {
        let date = (join_date && join_date) || "";
        if (user.is_x !== "0" || user.disable !== "0") {
          return (
            <div className="disabled_user">
              {date == ""
                ? ""
                : moment(date, "YYYY-MM-DD HH:mm:ss").format("DD-MMM-YYYY")}
            </div>
          );
        }
        return date == ""
          ? ""
          : moment(date, "YYYY-MM-DD HH:mm:ss").format("DD-MMM-YYYY");
      },
    },
    {
      title: "Action",
      dataIndex: "",
      className: "action",
      render: (text) => (
        <span>
          <Fragment></Fragment>
          <Tooltip placement="bottom" title={"View Announcement"}>
            <Button
              type="link"
              onClick={() => {
                viewModalData(text.notification_id);
              }}
              className="btn btn-light-primary btn-icon text-uppercase"
            >
              <Icon type="eye" theme="filled" />
            </Button>
          </Tooltip>
        </span>
      ),
    },
  ];

  const handlePagination = (pagination) => {
    setpageCurrent(pagination.current);
    loadNotificationIdData(pagination.current);
  };

  const notificationSearch = (e) => {
    setSearchById(e.target.value);
    loadNotificationIdData(1, e.target.value, searchByTitle);
  };
  const titleSearch = (e) => {
    setSearchByTitle(e.target.value);
    loadNotificationIdData(1, searchById, e.target.value);
  };

  return (
    <Fragment>
            <Helmet>
          <title>Announcements</title>
        </Helmet>
      <div className="page-wrapper">
        <div className="profileAP-container">
          {/* page Header */}

          <Fragment>
            <div className="page-header">
              <div className="page-title">
                <h2 className="title">
                  <img
                    className="icon icon-view"
                    src={nottification}
                    alt="view icon"
                  />{" "}
                  Announcements
                </h2>
              </div>
            </div>

            {/* page info title */}

            <section className="page-info-box  animated slideInUp">
              <h3 className="info-title">List of Announcements</h3>
              <div className="right multiple-item sm-reverse-item notification">
                <div className="search-box notification">
                  <Input
                    allowClear={true}
                    placeholder="Search by ID"
                    onChange={notificationSearch}
                    suffix={<Icon type="search" />}
                  />
                </div>
                <div className="search-box">
                  <Input
                    allowClear={true}
                    placeholder="Search by title"
                    onChange={titleSearch}
                    suffix={<Icon type="search" />}
                  />
                </div>
              </div>
            </section>

            {/* Table content */}
            {loading ? (
              <div className="page-spinner">
                <Spin />
              </div>
            ) : (
              <section className="content-section profile-table-info animated slideInUp">
                <div id="container">
                  <Table
                    className="table-content"
                    columns={columns}
                    dataSource={notificationListData.data}
                    onChange={handlePagination}
                    pagination={{
                      total:
                        notificationListData &&
                        notificationListData.meta &&
                        notificationListData.meta.pagination &&
                        notificationListData.meta.pagination.total,
                      pageSize:
                        notificationListData &&
                        notificationListData.meta &&
                        notificationListData.meta.pagination &&
                        notificationListData.meta.pagination.per_Page,
                      current: pageCurrent,
                      showSizeChanger: false,
                      defaultCurrent: 1,
                    }}
                  />
                </div>
              </section>
            )}
          </Fragment>
        </div>
      </div>

      {/* Veiw Modal */}
      <Modal 
      centered 
      visible={viewModalVisible}
      onCancel={() => setViewModalVisible(false)}
      footer={null}>
        <div className="view-profile-modal-container">
          <ViewNotification
            viewForAdmin={viewForAdmin}
            closeModal={() => setViewModalVisible(false)}
            viewCardData={viewCardData}
          />
        </div>
      </Modal>
    </Fragment>
  );
};

export default withStore(RequireAuth(ProfileComplete(NotificationUpdate(EmployeeNotification))));
