import React, { Component, Fragment } from 'react';
import { Row, Col, Input, Form, Spin, notification, Button } from 'antd';
import {  updateBillStatus } from '../../../../services/billService';


// for select box

// for textarea
const { TextArea } = Input;

class PaidDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      payment_details:''
    };
  }

  showNotification = (type, title, subtitle) => {
    notification[type]({
      message: title,
      description: subtitle
    });
  };

  submitDetails = (e) => {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll(async (err, formData) => {
    if(!err){ 
    this.props.setIsStatusUpdateLoading(true);
    updateBillStatus(this.props.id, { status: 'paid', payment_details: this.state.payment_details })
      .then((res) => {
        this.props.setIsStatusUpdateLoading(false);
        this.props.setIsBillModified(true);
        this.props.closeDrawer();
        this.showNotification('success', 'Updated Bill Status Successfully');
      })
      .catch((e) => {
        this.props.setIsStatusUpdateLoading(false);
        this.showNotification('error', 'Failed to update status');
      });
    }
    });
  };

  render() {
    const {getFieldDecorator } = this.props.form;
    return (
      <Fragment>
        <div className='uploadBill-container'>
          <form>
            <div className='form-content'>
              <Row className='row'>
                <Col className='col' xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                  <div className='form-group'>
                    <Form.Item label='Enter Payment Details' colon={false}>
                        { getFieldDecorator("payment_details", { 
                        rules: [
                          {
                            required: true,
                            message: "Enter payment details",
                          },
                          { whitespace  : true, message: '(No White Space Allowed)' },
                        ],
                      })
                      (
                      <TextArea
                        placeholder='Enter here...'
                        autoSize={{ minRows: 5, maxRows: 5 }}
                        style={{resize: 'none' }}
                        maxLength={255}
                        allowClear
                        onChange={(e) => this.setState({ payment_details: e.target.value })}
                        value={this.state.payment_details}
                      />)}
                    </Form.Item>
                  </div>
                </Col>
              </Row>
            </div>
          </form>
        </div>
        <div className='formAction-footer-cont'>
          <div className='formAction-footer text-right'>
            <div className='upload-bill-btn-container'>
              <Spin spinning={this.props.isStatusUpdateLoading}>
                <Button onClick={this.submitDetails} className='btn btn-success btn-wide text-uppercase'>
                  Submit
                </Button>
              </Spin>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default Form.create()(PaidDetails);
