import React, { Component, Fragment } from "react";
import {
  notification,
  Icon,
  Button,
  Spin,
  Modal,
  Divider,
  Table,
  Input,
  Tooltip,
  Progress,
  Drawer,
} from "antd";
import moment from "moment";
import { withStore } from "../../../hocs/withStore";
import _, { values } from "lodash";
import { axiosInstance as api } from "../../../utilities/configureAxios";
import url from "../../../config/url";
import { Helmet } from "react-helmet";
import AdminProfileManagement from "../Editprofile/AdminProfileManagement";
import ViewProfile from "./ViewProfile";
import AddProfile from "./AddProfile";
import AddContractProfile from "./AddContractProfile";
import FilterEmployee from "./FilterEmployee";
import data_setting from "../../../config/data_setting";
import RequireAuth from "../../../hocs/RequireAuth";
import ProfileComplete from "../../../hocs/ProfileComplete";
import NotificationUpdate from "../../../hocs/NotificationUpdate";
import male from "../../../assets/images/user/male.svg";
import TeamDirectory from "../../../assets/images/icons/sibebar/team-directory.svg";
import EmployeeHistory from "./ViewProfile/EmployeeHistory";
import { withRouter } from "react-router";

// Pagination
function itemRender(current, type, originalElement) {
  if (type === "prev") {
    return (
      <a className="back-icon">
        {" "}
        <Icon type="left" /> Back
      </a>
    );
  }
  if (type === "next") {
    return (
      <a className="next-icon">
        Next <Icon type="right" />
      </a>
    );
  }
  return originalElement;
}

// For search
const { Search } = Input;
const TITLE = "Team Directory";

class ProfileAdminPanel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      employees: [],
      user_name: null,
      designation: null,
      pp_url: null,
      employee_uid: null,
      addNewModal: false,
      addNewContractEmployee: false,
      addContractBtnDisabled: false,
      editModal: false,
      viewModal: false,
      user_roles_name: _.get(props, "store.data.user_roles_name", []),
      filter_skill: "",
      filter_hobby: "",
      filter_level: "",
      filter_team: "",
      employment_type: "",
      csvData: [],
      filter_is_x: true,
      name: "",
      total: 0,
      per_page: 1,
      current_page: 1,
      employee_download_loading: false,
      show_drawer: false,
      pagination: {
        page: 1,
        per_page: 1,
        current_page: 1,
      },
      addNewProfileLoadingState: false,
    };
  }

  componentDidMount = () => {
    this.setState({ loading: true });
    api
      .get(`${url["getEmployees"]}?paginate=true&page=1&hidden_employees=true`)
      .then((res) => {
        const employees = _.get(res, "data.data", []);
        const total = _.get(res, "data.meta.pagination.total", 0);
        const per_page = _.get(res, "data.meta.pagination.per_page", 1);
        const current_page = _.get(res, "data.meta.pagination.current_page", 1);
        this.setState({
          employees,
          total,
          per_page,
          current_page,
          loading: false,
        });
      })
      .catch((error) => {
        notification["error"]({
          message: _.get(
            error,
            "response.data.errors.message",
            "Oops! Something went wrong!"
          ),
        });
        this.setState({ loading: false });
      });
  };

  handleEmployeeList = () => {
    this.setState({ employee_download_loading: true });
    const {
      filter_is_x,
      filter_level,
      filter_skill,
      filter_team,
      employment_type,
    } = this.state;
    const empType =
      employment_type !== "" ? `&employment_type=${employment_type}` : "";
    api
      .get(
        `${url["getEmployeeList"]}?hidden_employees=${this.state.filter_is_x}&skill=${this.state.filter_skill}&hobbies=${this.state.filter_hobby}&level=${this.state.filter_level}&team=${this.state.filter_team}${empType}`
      )
      .then((res) => {
        const fileURL = window.URL.createObjectURL(
          new Blob([res.data], {
            type: "application/csv",
          })
        );
        let alink = document.createElement("a");
        alink.href = fileURL;
        alink.download = "EmployeeList.csv";
        alink.click();
        this.setState({ employee_download_loading: false });
      })
      .catch((error) => {
        notification["error"]({
          message: _.get(
            error,
            "response.data.errors.message",
            "Oops! Something went wrong!"
          ),
        });
        this.setState({ employee_download_loading: false });
      });
  };

  user_name = (data) => {
    let name =
      data.employee.personal_details.first_name +
      " " +
      data.employee.personal_details.last_name;
    let designation = data.employee.employee_details.designation;
    let pp_url = data.employee.url;
    this.setState({ user_name: name, designation, pp_url });
  };

  onPageChange = (pageNumber) => {
    this.setState({ loading: true });
    const {
      filter_hobby,
      filter_skill,
      filter_level,
      filter_team,
      filter_is_x,
      name,
      employment_type,
    } = this.state;
    const empType =
      employment_type !== "" ? `&employment_type=${employment_type}` : "";

    api
      .get(
        url["getEmployees"] +
          "?paginate=true&page=" +
          pageNumber +
          `&hidden_employees=${filter_is_x}&q=${name}&skill=${filter_skill}&hobbies=${this.state.filter_hobby}&level=${filter_level}&team=${filter_team}${empType}`
      )
      .then((res) => {
        const employees = _.get(res, "data.data", []);
        const total = _.get(res, "data.meta.pagination.total", 0);
        const per_page = _.get(res, "data.meta.pagination.per_page", 0);
        const current_page = _.get(res, "data.meta.pagination.current_page", 1);
        this.setState({
          employees,
          total,
          per_page,
          current_page,
          loading: false,
        });
      })
      .catch((error) => {
        notification["error"]({
          message: _.get(
            error,
            "response.data.errors.message",
            "Oops! Something went wrong!"
          ),
        });
        this.setState({ loading: false });
      });
    // }
  };

  submitAddNewModal = (values) => {
    const { current_page } = this.state;
    values.dob_official = moment(values.dob_official).format(
      data_setting["post_date_format"]
    );
    values.date_of_joining = moment(values.date_of_joining).format(
      data_setting["post_date_format"]
    );
    this.setState({ addNewProfileLoadingState: true });
    api
      .post(url["createUser"], values)
      .then((res) => {
        notification["success"]({
          message: "User Created Successfully!",
        });
        this.setState({ addNewProfileLoadingState: false });
        api
          .get(
            url["getEmployees"] +
              "?paginate=true&hidden_employees=true&page=" +
              current_page
          )
          .then((res) => {
            const employees = _.get(res, "data.data", []);
            const total = _.get(res, "data.meta.pagination.total", 0);
            const per_page = _.get(res, "data.meta.pagination.per_page", 0);
            const current_page = _.get(
              res,
              "data.meta.pagination.current_page",
              1
            );
            this.setState({
              employees,
              total,
              per_page,
              current_page,
              loading: false,
              addNewModal: false,
              addNewProfileLoadingState: false,
            });
          })
          .catch((error) => {
            notification["error"]({
              message: _.get(
                error,
                "response.data.errors.message",
                "Oops! Something went wrong!"
              ),
            });

            this.setState({
              loading: false,
              addNewModal: false,
              addNewProfileLoadingState: false,
            });
          });
      })
      .catch((error) => {
        const error_code = _.get(error, "response.status");
        if (error_code && error_code == "422") {
          notification["error"]({
            message: Object.values(
              _.get(
                error,
                "response.data.errors",
                "Oops! Something went wrong!"
              )
            ),
          });
        } else {
          notification["error"]({
            message: _.get(
              error,
              "response.data.errors.message",
              "Oops! Something went wrong!"
            ),
          });
        }
        this.setState({ loading: false, addNewProfileLoadingState: false });
      });
  };

  setAddNewModal = (visibility) => {
    this.setState({ addNewModal: visibility });
  };

  submitAddNewContractModal = (values, form) => {
    // const { setFields } = form;
    const { current_page } = this.state;
    this.setState({ addModalLoading: true });
    values.date_of_exit = values.date_of_exit
      ? moment(values.date_of_exit).format(data_setting["post_date_format"])
      : null;
    values.date_of_joining = values.date_of_joining
      ? moment(values.date_of_joining).format(data_setting["post_date_format"])
      : null;
    api
      .post(url["createContractUser"], values)
      .then((res) => {
        notification["success"]({
          message: "User Created Successfully!",
        });
        api
          .get(
            url["getEmployees"] +
              "?paginate=true&hidden_employees=true&page=" +
              current_page
          )
          .then((res) => {
            const employees = _.get(res, "data.data", []);
            const total = _.get(res, "data.meta.pagination.total", 0);
            const per_page = _.get(res, "data.meta.pagination.per_page", 0);
            const current_page = _.get(
              res,
              "data.meta.pagination.current_page",
              1
            );
            this.setState({
              employees,
              total,
              per_page,
              current_page,
              loading: false,
              addNewContractEmployee: false,
              addModalLoading: false,
            });
          })
          .catch((error) => {
            notification["error"]({
              message: _.get(
                error,
                "response.data.errors.message",
                "Oops! Something went wrong!"
              ),
            });
            this.setState({
              loading: false,
              addNewContractEmployee: false,
              addModalLoading: false,
            });
          });
      })
      .catch((error) => {
        this.setState({ addModalLoading: false });

        const error_code = _.get(error, "response.status");
        if (error_code && error_code == "422") {
          notification["error"]({
            message: Object.values(
              _.get(
                error,
                "response.data.errors",
                "Oops! Something went wrong!"
              )
            ),
          });
        } else {
          notification["error"]({
            message: _.get(
              error,
              "response.data.errors.message",
              "Oops! Something went wrong!"
            ),
          });
        }
        this.setState({ loading: false, addModalLoading: false });
      });
  };

  setAddNewContractModal = (visibility) => {
    this.setState({ addNewContractEmployee: visibility });
  };

  handleCancel = () => {
    const {
      current_page,
      filter_is_x,
      filter_level,
      filter_skill,
      filter_hobby,
      filter_team,
      employment_type,
      name,
    } = this.state;
    const empType =
      employment_type !== "" ? `&employment_type=${employment_type}` : "";

    this.setState({ loading: true, editModal: false });
    api
      .get(
        url["getEmployees"] +
          "?paginate=true&page=" +
          current_page +
          `&hidden_employees=${filter_is_x}&q=${name}&skill=${filter_skill}&hobbies=${filter_hobby}&level=${filter_level}&team=${filter_team}${empType}`
      )
      .then((res) => {
        const employees = _.get(res, "data.data", []);
        const total = _.get(res, "data.meta.pagination.total", 0);
        const per_page = _.get(res, "data.meta.pagination.per_page", 0);
        const current_page = _.get(res, "data.meta.pagination.current_page", 1);
        this.setState({
          employees,
          total,
          per_page,
          current_page,
          loading: false,
        });
      })
      .catch((error) => {
        notification["error"]({
          message: _.get(
            error,
            "response.data.errors.message",
            "Oops! Something went wrong!"
          ),
        });
        this.setState({ loading: false });
      });
  };

  setEditModal = (visibility, employee) => {
    if (employee) {
      this.setState({
        editModal: visibility,
        employee_uid: employee.uuid,
        user_name: employee.name,
        pp_url: employee.url,
        designation: employee.designation,
      });
    } else {
      this.setState({
        viewModal: visibility,
        employee_uid: null,
        user_name: null,
        pp_url: null,
      });
    }
  };
  setViewModal = (visibility, employee) => {
    if (employee) {
      this.setState({
        viewModal: visibility,
        employee_uid: employee.uuid,
        user_name: employee.name,
        pp_url: employee.url,
        designation: employee.designation,
      });
    } else {
      this.setState({
        viewModal: visibility,
        employee_uid: null,
        user_name: null,
        pp_url: null,
      });
    }
  };

  showDrawer = (employee) => {
    this.setState({
      show_drawer: true,
      employee_uid: employee.uuid,
    });
  };

  onClose = () => {
    this.setState({
      show_drawer: false,
    });
  };

  // for filter modal
  state = {
    modal1Visible: false,
  };
  setModal1Visible(modal1Visible) {
    this.setState({ modal1Visible });
  }

  onChange = (e) => {
    const {
      filter_skill,
      filter_hobby,
      filter_level,
      filter_team,
      filter_is_x,
      employment_type,
    } = this.state;
    const name = e.target.value;
    const empType =
      employment_type !== "" ? `&employment_type=${employment_type}` : "";
    this.setState({ name });

    api
      .get(
        url["getEmployees"] +
          `?paginate=true&page=1&hidden_employees=${filter_is_x}&q=${name}&skill=${filter_skill}&hobbies=${filter_hobby}&level=${filter_level}&team=${filter_team}${empType}`
      )
      .then((res) => {
        const employees = _.get(res, "data.data", []);
        const total = _.get(res, "data.meta.pagination.total", 0);
        const per_page = _.get(res, "data.meta.pagination.per_page", 0);
        const current_page = _.get(res, "data.meta.pagination.current_page", 1);
        this.setState({
          employees,
          total,
          per_page,
          current_page,
          loading: false,
        });
      })
      .catch((error) => {
        notification["error"]({
          message: _.get(
            error,
            "response.data.errors.message",
            "Oops! Something went wrong!"
          ),
        });
        this.setState({ loading: false });
      });
  };

  resetFilter = () => {
    this.setState({ loading: true });
    api
      .get(`${url["getEmployees"]}?paginate=true&page=1&hidden_employees=true`)
      .then((res) => {
        const employees = _.get(res, "data.data", []);
        const total = _.get(res, "data.meta.pagination.total", 0);
        const per_page = _.get(res, "data.meta.pagination.per_page", 1);
        const current_page = _.get(res, "data.meta.pagination.current_page", 1);
        this.setState({
          employees,
          total,
          per_page,
          current_page,
          filter_skill: "",
          filter_hobby: "",
          filter_level: "",
          filter_team: "",
          employment_type: "",
          filter_is_x: true,
          loading: false,
        });
      })
      .catch((error) => {
        notification["error"]({
          message: _.get(
            error,
            "response.data.errors.message",
            "Oops! Something went wrong!"
          ),
        });
        this.setState({ loading: false });
      });
  };

  setFilter = (value, form) => {
    const { name } = this.state;
    this.setModal1Visible(false);
    this.setState({ loading: true });
    let filter_skill = _.get(value, "skill", "");
    let filter_hobby = _.get(value, "hobby", "");
    let filter_level = _.get(value, "level", "");
    let filter_team = _.get(value, "team", "");
    let filter_is_x = _.get(value, "is_x", "");
    let employment_type = _.get(value, "employment_type", "");
    this.setState({
      filter_hobby,
      filter_skill,
      filter_level,
      filter_team,
      filter_is_x,
      employment_type,
    });
    const empType =
      employment_type !== "" ? `&employment_type=${employment_type}` : "";
    api
      .get(
        `${url["getEmployees"]}?paginate=true&hidden_employees=${filter_is_x}&page=1&q=${name}&skill=${filter_skill}&hobbies=${filter_hobby}&level=${filter_level}&team=${filter_team}${empType}`
      )
      .then((res) => {
        form.resetFields();
        const employees = _.get(res, "data.data", []);
        const total = _.get(res, "data.meta.pagination.total", 0);
        const per_page = _.get(res, "data.meta.pagination.per_page", 1);
        const current_page = _.get(res, "data.meta.pagination.current_page", 1);
        this.setState({
          employees,
          total,
          per_page,
          current_page,
          loading: false,
        });
      })
      .catch((error) => {
        notification["error"]({
          message: _.get(
            error,
            "response.data.errors.message",
            "Oops! Something went wrong!"
          ),
        });
      });
  };

  render() {
    const { history } = this.props;
    const setQueryParam = (id) => {
      history.push({
        pathname: "/dashboard/admin/project-dashboard",
        search: `?employee=${id}`,
      });
    };
    const {
      employees,
      loading,
      user_roles_name,
      current_page,
      total,
      per_page,
      filter_skill,
      filter_hobby,
      filter_level,
      filter_team,
      employment_type,
      filter_is_x,
      addNewProfileLoadingState,
    } = this.state;
    var columns = [
      {
        title: "Profile pic",
        dataIndex: "url",
        className: "url",
        render: (data) => {
          return (
            <div
              className="user-img"
              style={{ backgroundImage: `url(${data})` }}
            ></div>
          );
        },
      },
      {
        title: "Emp. Id",
        dataIndex: "employee_id",
        className: "empid",
        render: (id, user) => {
          if (user.is_x !== "0" || user.disable !== "0") {
            return <div className="disabled_user">{id}</div>;
          }
          return id;
        },
      },
      {
        title: "Name",
        dataIndex: "name",
        className: "name",
        render: (name, user) => {
          if (user.is_x !== "0" || user.disable !== "0") {
            return <div className="disabled_user">{name}</div>;
          }
          return name;
        },
      },
      {
        title: "Joining Date",
        dataIndex: "date_of_joining",
        className: "joining-date",
        render: (join_date, user) => {
          let date = (join_date && join_date) || "";
          if (user.is_x !== "0" || user.disable !== "0") {
            return (
              <div className="disabled_user">
                {date == ""
                  ? ""
                  : moment(date, "YYYY-MM-DD HH:mm:ss").format("DD-MMM-YYYY")}
              </div>
            );
          }
          return date == ""
            ? ""
            : moment(date, "YYYY-MM-DD HH:mm:ss").format("DD-MMM-YYYY");
        },
      },
      {
        title: "Email",
        dataIndex: "email",
        className: "email",
        render: (mail, user) => {
          if (user.is_x !== "0" || user.disable !== "0") {
            return <div className="disabled_user">{mail}</div>;
          }
          return <a href={"mailto:" + mail}>{mail}</a>;
        },
      },
      {
        title: "Mobile",
        dataIndex: "mobile",
        className: "mobile",
        render: (mob_number, user) => {
          if (user.is_x !== "0" || user.disable !== "0") {
            return (
              <div className="disabled_user">
                <a href={"tel:" + mob_number}>{mob_number}</a>
              </div>
            );
          }
          return <a href={"tel:" + mob_number}>{mob_number}</a>;
        },
      },
      {
        title: "Profile",
        dataIndex: "profile_percentage",
        className: "profile",
        render: (data) => {
          return (
            <div className="profile-percentage-content">
              <Progress type="circle" percent={data} />
            </div>
          );
        },
      },
      {
        title: "Action",
        dataIndex: "action",
        className: "action",
        render: (value, employee) => (
          <span>
            {not_admin && (
              <Fragment>
                <Tooltip placement="bottom" title={"Edit Profile"}>
                  <Button
                    type="link"
                    onClick={() => this.setEditModal(true, employee)}
                    className="btn btn-light-info btn-icon text-uppercase"
                  >
                    <Icon type="edit" theme="filled" />
                  </Button>
                </Tooltip>
                <Divider type="vertical" />
              </Fragment>
            )}

            {/* employess edit action button */}
            <Tooltip placement="bottom" title={"View Profile"}>
              <Button
                type="link"
                onClick={() => this.setViewModal(true, employee)}
                className="btn btn-light-primary btn-icon text-uppercase"
              >
                <Icon type="eye" theme="filled" />
              </Button>
            </Tooltip>
            <Divider type="vertical" />

            {/* employess history action button */}
            <Tooltip placement="bottom" title={"View dashboard"}>
              <Button
                type="link"
                className="btn btn-light-success btn-icon"
                onClick={() => {
                  // setQueryParam();
                  setQueryParam(employee.uuid);
                  this.showDrawer(employee);
                }}
              >
                <Icon type="info" />
              </Button>
            </Tooltip>
          </span>
        ),
      },
    ];
    const not_admin =
      user_roles_name.indexOf("admin") != -1 ||
      user_roles_name.indexOf("hr") != -1;

    return (
      <Fragment>
        <Helmet>
          <title>{TITLE}</title>
        </Helmet>
        <div className="page-wrapper">
          <div className="profileAP-container">
            {/* page Header */}
            {loading ? (
              <div className="page-spinner">
                <Spin />
              </div>
            ) : (
              <Fragment>
                <div className="page-header">
                  <div className="page-title">
                    <h2 className="title">
                      <img
                        className="icon icon-view"
                        src={TeamDirectory}
                        alt="view icon"
                      />{" "}
                      Team Directory
                    </h2>
                  </div>
                  {not_admin && (
                    <>
                      <Button
                        className="btn btn-success text-uppercase employee"
                        onClick={() => this.setAddNewModal(true)}
                      >
                        <Icon type="user-add" /> Add New
                      </Button>
                      {/* <Button
                        className="btn btn-info text-uppercase"
                        onClick={() => this.setAddNewContractModal(true)}
                      >
                        <Icon type="user-add" /> New Contract
                      </Button> */}
                    </>
                  )}
                </div>

                {/* page info title */}

                <section className="page-info-box  animated slideInUp">
                  <h3 className="info-title">List of Employees</h3>
                  {not_admin && (
                    <div className="right multiple-item sm-reverse-item">
                      {(this.state.filter_level ||
                        this.state.filter_skill ||
                        this.state.filter_hobby ||
                        this.state.filter_team ||
                        this.state.employment_type ||
                        this.state.filter_is_x == false) && (
                        <Button
                          className="btn btn-danger text-uppercase"
                          onClick={this.resetFilter}
                        >
                          <Icon type="close" /> Reset
                        </Button>
                      )}
                      <Button
                        className="btn btn-warning text-uppercase"
                        onClick={() => this.setModal1Visible(true)}
                      >
                        <Icon type="filter" /> Filter
                      </Button>
                      <Button
                        className="btn btn-primary text-uppercase employee-list"
                        onClick={() => this.handleEmployeeList()}
                        disabled={this.state.employee_download_loading}
                        loading={this.state.employee_download_loading}
                      >
                        <Icon type="download" /> Employee List
                      </Button>

                      <div className="search-box">
                        <Input
                          allowClear={true}
                          placeholder="Search"
                          value={this.state.name}
                          onChange={this.onChange}
                          suffix={<Icon type="search" />}
                        />
                      </div>
                    </div>
                  )}
                </section>

                {/* Admin Panel- Table content */}
                <section className="content-section profile-table-info animated slideInUp">
                  <div id="container">
                    <Table
                      className="table-content"
                      columns={columns}
                      dataSource={employees}
                      rowKey={(employee) => employee.uuid}
                      pagination={{
                        onChange: this.onPageChange,
                        current: current_page,
                        total: total,
                        pageSize: per_page,
                        itemRender: itemRender,
                      }}
                    />
                  </div>
                </section>

                {/* Add New Profile Modal */}
                {not_admin && (
                  <Modal
                    className="add-new-profile-modal profileAP-modal"
                    title="Add New Profile"
                    centered
                    destroyOnClose={true}
                    visible={this.state.addNewModal}
                    onOk={() => this.submitAddNewModal()}
                    onCancel={() => this.setAddNewModal(false)}
                    footer={[]}
                  >
                    <AddProfile
                      onSubmit={this.submitAddNewModal}
                      isLoading={addNewProfileLoadingState}
                      onCancel={() => this.setAddNewModal(false)}
                    />
                  </Modal>
                )}

                {/* Add New Contract Profile Modal */}
                {not_admin && (
                  <Modal
                    className="add-new-profile-modal profileAP-modal"
                    title="Add New Contract Profile"
                    centered
                    destroyOnClose={true}
                    visible={this.state.addNewContractEmployee}
                    onCancel={() => this.setAddNewContractModal(false)}
                    footer={[]}
                  >
                    <AddContractProfile
                      onSubmit={this.submitAddNewContractModal}
                      onCancel={() => this.setAddNewContractModal(false)}
                      addModalLoading={this.state.addModalLoading}
                    />
                  </Modal>
                )}

                {/* Edit Profile Modal */}
                {not_admin && (
                  <Modal
                    className="edit-profile-modal profileAP-modal"
                    destroyOnClose={true}
                    title="Edit Profile"
                    centered
                    visible={this.state.editModal}
                    onOk={() => this.setEditModal(false)}
                    onCancel={() => this.handleCancel(false)}
                    footer={null}
                  >
                    <div className="edit-profile-modal-container">
                      <AdminProfileManagement
                        employee_uid={this.state.employee_uid}
                        closeModal={() => this.setState({ editModal: false })}
                      />
                    </div>
                  </Modal>
                )}

                {/* Veiw Profile Modal */}
                <Modal
                  className="view-profile-modal profileAP-modal"
                  title={[
                    <div className="view-profile-modalTitle">
                      <div
                        className="user-img"
                        style={{
                          backgroundImage: `url(${
                            this.state.pp_url ? this.state.pp_url : male
                          })`,
                        }}
                      ></div>
                      <div className="username">
                        {this.state.user_name
                          ? this.state.user_name
                          : "User Name"}
                      </div>
                      <div className="seperator">|</div>
                      <div className="designation">
                        {this.state.designation
                          ? this.state.designation
                          : "Designation"}
                      </div>
                    </div>,
                  ]}
                  centered
                  destroyOnClose={true}
                  visible={this.state.viewModal}
                  onOk={() => this.setViewModal(false)}
                  onCancel={() => this.setViewModal(false)}
                  footer={null}
                >
                  <div className="view-profile-modal-container">
                    <ViewProfile
                      employee_uid={this.state.employee_uid}
                      // user_name ={(data)=>this.user_name(data)}
                    />
                  </div>
                </Modal>

                {/* employee history drawer   */}
                {not_admin && (
                  <Drawer
                    className="employee-history-drawer navigator-drawer"
                    title="Assets History"
                    width={500}
                    onClose={this.onClose}
                    visible={this.state.show_drawer}
                    bodyStyle={{ padding: 10 }}
                    destroyOnClose={true}
                  >
                    <div className="view-profile-modal-container">
                      <EmployeeHistory employee_uid={this.state.employee_uid} />
                    </div>
                  </Drawer>
                )}
              </Fragment>
            )}
          </div>

          {/* filter Modal */}
          <Modal
            className="filter-modal profileAp-modal"
            title="Filter Employee"
            centered
            visible={this.state.modal1Visible}
            onOk={() => this.setModal1Visible(false)}
            onCancel={() => this.setModal1Visible(false)}
            footer={null}
          >
            <div className="filter-employee-modal-container">
              <FilterEmployee
                handleSubmit={this.setFilter}
                filter_skill={filter_skill}
                filter_hobby={filter_hobby}
                filter_level={filter_level}
                filter_team={filter_team}
                employment_type={employment_type}
                filter_is_x={filter_is_x}
              />
            </div>
          </Modal>
        </div>
      </Fragment>
    );
  }
}

export default RequireAuth(
  withStore(ProfileComplete(NotificationUpdate(ProfileAdminPanel)))
);
