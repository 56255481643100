import React, { Component, Fragment } from "react";
import { Table, Divider, Icon, Input, notification, Button, Popconfirm, Tooltip, Row, Col, Card, Pagination, Spin } from "antd";
import CompanyDocument from '../../../assets/images/icons/sibebar/company-documents.svg';
import link from "../../../assets/images/icons/icon-link-bg.svg";
import { Link } from 'react-router-dom';
import fileIcon from '../../../config/fileIcon';
import { Helmet } from 'react-helmet';
import moment from "moment";
import _ from 'lodash';
import { getFolderWiseDocument, getSingleFolder } from '../../admin/components/Documents/DataManager';


const TITLE = 'Documents'

// Pagination
const itemRender = (current, type, originalElement) => {
    if (type === 'prev') {
        return <a className="back-icon"> <Icon type="left" /> Back</a>;
    }
    if (type === 'next') {
        return <a className="next-icon">Next <Icon type="right" /></a>;
    }
    return originalElement;
}

export default class DocumentFolderList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            slug: '',
            single_folder: null,
            documents: [],
            loading: false,
            q: '',
            total: 0,
            per_page: 1,
            current_page: 1
        }
    }
    componentDidMount() {
        this.getDocuments()
    }

    getDocuments() {
        const query = new URLSearchParams(this.props.location.search);
        const slug = query.get('id')
        this.setState({ slug: slug, loading: true })
        const { q } = this.state

        getFolderWiseDocument(slug, q, 1)
            .then(res => {
                const documents = _.get(res, 'data.data', [])
                const total = _.get(res, 'data.meta.pagination.total', 0)
                const per_page = _.get(res, 'data.meta.pagination.per_page', 1)
                const current_page = _.get(res, 'data.meta.pagination.current_page', 1)
                this.setState({ documents, total, per_page, current_page, loading: false });
            })
            .catch(error => {
                notification['error']({
                    message: _.get(error, 'response.data.errors.message', "Oops! Something went wrong!")
                });
                this.setState({ loading: false });
            });

        getSingleFolder(slug)
            .then(res => {
                this.setState({ single_folder: res.data })
            })
            .catch(error => {
                notification['error']({
                    message: _.get(error, 'response.data.errors.message', "Oops! Something went wrong!")
                });
            })
    }

    onPageChange = (pageNumber) => {
        let state = this.state
        state.loading = true
        this.setState({ state });

        const { slug, q } = this.state

        getFolderWiseDocument(slug, q, pageNumber)
            .then(res => {
                const documents = _.get(res, 'data.data', [])
                const total = _.get(res, 'data.meta.pagination.total', 0)
                const per_page = _.get(res, 'data.meta.pagination.per_page', 1)
                const current_page = _.get(res, 'data.meta.pagination.current_page', 1)
                this.setState({ documents, total, per_page, current_page, loading: false });
            })
            .catch(error => {
                notification['error']({
                    message: _.get(error, 'response.data.errors.message', "Oops! Something went wrong!")
                });
                this.setState({ loading: false });
            });
    }


    onSearchDoc = (e) => {

        this.setState({ q: e.target.value }, () => {
            this.getDocuments();
        })

    }

    getDate = date => {
        let new_date = new Date(date);
        return new_date.toDateString();
    }

    getFileIcon = type => {
        return fileIcon[type];
    }

    count = data => {
        return data.length;
    }

    render() {
        const { documents, total, per_page, current_page, loading, single_folder } = this.state;

        return (
            <Fragment>
                <Helmet>
                    <title>{TITLE}</title>
                </Helmet>

                <div className="page-wrapper">


                    <div className="documentAP-container">
                        {/* page Header */}
                        <div className="page-header">
                            <div className="page-title">
                                <h2 className="title"><img className="icon icon-view" src={CompanyDocument} alt="view icon" /> Documents</h2>
                            </div>
                            <Link to="/dashboard/document" ><Button className="btn btn-primary text-uppercase btn-icon"><Icon type="left" /> Back</Button></Link>
                        </div>

                        {/* page info title */}
                        <section className="page-info-box">
                            <h3 className="info-title">{single_folder && single_folder[0].name}</h3>
                            <div className="right">
                                <div className="search-box">
                                    <Input onChange={this.onSearchDoc} placeholder="Search Documents" suffix={<Icon type="search" />} />
                                </div>
                            </div>
                        </section>

                        {loading ? (<div className="page-spinner"><Spin /></div>) : (
                            <section>
                                <section className="content-section document-card-section animated slideInUp">
                                    <Row className="" gutter={24}>
                                        {documents.map(document => (
                                            <Col className="gutter-row" key={document.id} xs={24} sm={24} md={24} lg={12} xl={12} xxl={8}>
                                                <Card className="card card-shadow doc-card">
                                                    <div className="doc-icon-box">
                                                        <img className="icon icon-link" src={this.getFileIcon(document.file_type)} alt="link icon" />
                                                    </div>
                                                    <div className="doc-title-box">
                                                        <h4 className="title">{document.title}</h4>
                                                        <p className="last-update text-gray">Last modified: {moment(document.updated_at, 'YYYY-MM-DD HH:mm:ss').format('DD-MMM-YYYY')}</p>
                                                    </div>
                                                    <div className="doc-link-box">
                                                        {/* <Button className="btn btn-light-primary btn-icon"><Icon type="eye" theme="filled" /></Button> */}
                                                        <a href={document.url} target="_blank">
                                                            <Button className="btn btn-light-secondary btn-icon">
                                                                {
                                                                    document.type == 'link' && <Fragment>
                                                                        <Icon type="link" />
                                                                    </Fragment>
                                                                }
                                                                {
                                                                    document.type == 'file' && <Fragment>
                                                                        <Icon type="download" />
                                                                    </Fragment>
                                                                }
                                                            </Button>
                                                        </a>
                                                    </div>
                                                </Card>
                                            </Col>
                                        ))}
                                    </Row>
                                </section>

                                {documents.length == 0 && <section className="page-info-box text-center">
                                    <h3 className="info-title">Sorry! No documents found!</h3>
                                </section>}

                                {documents.length != 0 && <section className="pagination-container">
                                    <div className="pagination-box text-right">
                                        <Pagination className="pagination" onChange={this.onPageChange} current={current_page} total={total} itemRender={itemRender} pageSize={per_page} />
                                    </div>
                                </section>}

                            </section>
                        )}

                    </div>


                </div>
            </Fragment >
        )
    }
}