import React, { Component, Fragment } from "react";
import {
  Form,
  notification,
  Input,
  Button,
  Row,
  Col,
  Switch,
  Popconfirm,
  Select,
  Card,
  Tabs,
  DatePicker,
  Radio,
} from "antd";
import _ from "lodash";
import { axiosInstance as api } from "../../../../utilities/configureAxios";
import url from "../../../../config/url";
import data_setting from "../../../../config/data_setting";
import { withStore } from "../../../../hocs/withStore";
import ForAdminAndHr from "../../../../hocs/ForAdminAndHr";
import moment from "moment";
const { Option } = Select;

// textarea
const { TextArea } = Input;

class AdminEditEmployeeDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      employee_details: null,
      roles: null,
      managers: null,
      teams: null,
      roles_loading: true,
      managers_loading: true,
      teams_loading: true,
      levels_loading: true,
      loading: false,
      user_roles: null,
      user_roles_id: [],
      employee_id: null,
      disable_switch: false,
      user_roles_name: _.get(props, "store.data.user_roles_name", []),
    };
  }

  componentDidMount = () => {
    const {
      store: { setData, storeData },
    } = this.props;
    this.setState({ loading: true });
    api
      .get(url["getRoles"])
      .then((res) => {
        const roles = _.get(res, "data.data", []);
        this.setState({ roles, roles_loading: false });
      })
      .catch((error) => {
        notification["error"]({
          message: _.get(
            error,
            "response.data.errors.message",
            "Oops! Something went wrong!"
          ),
        });
        this.setState({ roles_loading: false });
      });
    api
      .get(url["getManagers"])
      .then((res) => {
        const managers = _.get(res, "data.data", []);
        this.setState({ managers, managers_loading: false });
      })
      .catch((error) => {
        notification["error"]({
          message: _.get(
            error,
            "response.data.errors.message",
            "Oops! Something went wrong!"
          ),
        });
        this.setState({ managers_loading: false });
      });
    api
      .get(url["getTeams"])
      .then((res) => {
        const teams = _.get(res, "data.data", []);
        this.setState({ teams, teams_loading: false });
      })
      .catch((error) => {
        notification["error"]({
          message: _.get(
            error,
            "response.data.errors.message",
            "Oops! Something went wrong!"
          ),
        });
        this.setState({ teams_loading: false });
      });
    api
      .get(url["getLevels"])
      .then((res) => {
        const levels = _.get(res, "data.data", []);
        this.setState({ levels, levels_loading: false });
      })
      .catch((error) => {
        notification["error"]({
          message: _.get(
            error,
            "response.data.errors.message",
            "Oops! Something went wrong!"
          ),
        });
        this.setState({ levels_loading: false });
      });
    api
      .get(url["userRoles"] + "/" + this.state.employee_id)
      .then((res) => {
        const user_roles = _.get(res, "data.data", []);
        this.setState({ user_roles, user_roles_id: _.map(user_roles, "id") });
      })
      .catch((error) => {
        notification["error"]({
          message: _.get(
            error,
            "response.data.errors.message",
            "Oops! Something went wrong!"
          ),
        });
      });
    api
      .get(url["myRoles"])
      .then((res) => {
        const user_roles = _.get(res, "data.data", []);
        const user_roles_name = _.map(user_roles, "name", []);
        setData({ user_roles_name: [...user_roles_name] });
      })
      .catch((error) => {
        notification["error"]({
          message: _.get(
            error,
            "response.data.errors.message",
            "Oops! Something went wrong!"
          ),
        });
      });
    this.setState({ loading: false });
  };

  static getDerivedStateFromProps(props, state) {
    let employee_details = props.employee_details;
    return {
      employee_details: employee_details,
      employee_id: props.employee_id,
    };
  }

  change_disable = () => {
    this.setState({ disable_switch: this.state.disable_switch });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        //alert(values.disable);
        if (
          this.state.user_roles_name.indexOf("admin") != -1 ||
          this.state.user_roles_name.indexOf("hr") != -1
        ) {
          values.date_of_joining = moment(values.date_of_joining).format(
            data_setting["post_date_format"]
          );
          values.dob_official = moment(values.dob_official).format(
            data_setting["post_date_format"]
          );
          if (values.is_x)
            values.date_of_exit = moment(values.date_of_exit).format(
              data_setting["post_date_format"]
            );
        }
        this.setState({ loading: true });
        values["profile_tab"] = "employee";
        values.disable =
          values.disable === true
            ? 1
            : values.disable === false
            ? 0
            : this.state.employee_details &&
              this.state.employee_details.disable === "yes"
            ? 1
            : 0;
        switch (values["employment_sub_type"]) {
          case "Normal":
            values["employment_sub_type"] =
              data_setting["employment_sub_type"]["normal"];
            break;
          case "Notice Period":
            values["employment_sub_type"] =
              data_setting["employment_sub_type"]["notice_period"];
            break;
          case "Probation":
            values["employment_sub_type"] =
              data_setting["employment_sub_type"]["probation"];
            break;
          default:
            values["employment_sub_type"] =
              data_setting["employment_sub_type"]["normal"];
        }
        api
          .post(url["createUser"] + "/" + this.state.employee_id, values)
          .then((data) => {
            notification["success"]({
              message: "Employee details updated successfully!",
            });
            this.setState({ loading: false });
          })
          .catch((error) => {
            const errorData = _.get(error, "response.data.errors");
            if (errorData) {
              for (const field in errorData) {
                if (Object.prototype.hasOwnProperty.call(errorData, field)) {
                  this.props.form.setFields({
                    [field]: {
                      value: values[field],
                      errors: [new Error(errorData[field])],
                    },
                  });
                }
              }
            } else {
              notification["error"]({
                message: _.get(
                  error,
                  "response.data.errors.message",
                  "Oops! Something went wrong!"
                ),
              });
            }
            this.setState({ loading: false });
          });
      }
    });
  };

  render() {
    const { getFieldDecorator, getFieldValue } = this.props.form;
    const {
      disable_switch,
      employee_details,
      loading,
      roles,
      managers,
      teams,
      levels,
      levels_loading,
      teams_loading,
      roles_loading,
      managers_loading,
      user_roles_id,
    } = this.state;
    return (
      <Fragment>
        <section className="tab-content-container employee-details-tab card-shadow">
          <form>
            <h3 className="title text-primary">Employement details</h3>
            <Row className="" gutter={30}>
              <Col className="gutter-row" xs={24} sm={24} md={24} lg={6} xl={6}>
                <div className="form-content form-group">
                  <Form.Item label="Employee ID">
                    {getFieldDecorator("emp_id", {
                      initialValue: employee_details
                        ? employee_details.employee_id
                        : "",
                      rules: [{ required: true, message: "Please enter Id" }],
                      validateTrigger: "onBlur",
                    })(
                      <Input
                        className="input"
                        maxLength="255"
                        placeholder="Enter ID"
                      />
                    )}
                  </Form.Item>
                </div>
              </Col>
              <Col className="gutter-row" xs={24} sm={24} md={24} lg={6} xl={6}>
                <div className="form-content form-group">
                  <Form.Item label="Official Email ID">
                    {getFieldDecorator("email_official", {
                      initialValue: employee_details
                        ? employee_details.email_official
                        : "",
                      rules: [
                        { required: true, message: "Please enter email" },
                        { type: "email", message: "Please enter valid email" },
                      ],
                      validateTrigger: "onBlur",
                    })(
                      <Input
                        type="email"
                        maxLength="255"
                        className="input"
                        placeholder="Enter Official email ID"
                      />
                    )}
                  </Form.Item>
                </div>
              </Col>
              <Col className="gutter-row" xs={24} sm={24} md={24} lg={6} xl={6}>
                <div className="form-content form-group">
                  <Form.Item label="Reporting Manager">
                    {getFieldDecorator("managers[0]", {
                      initialValue:
                        !managers_loading &&
                        employee_details &&
                        employee_details.managers
                          ? employee_details.managers[0].id
                          : "",
                    })(
                      <Select
                        className="manager-selectbox selectbox"
                        showSearch
                        style={{ width: "100%" }}
                        placeholder="Select reporting manager"
                        optionFilterProp="children"
                        loading={managers_loading}
                      >
                        {managers &&
                          managers.map((manager) => (
                            <Option key={manager.id} value={manager.id}>
                              {manager.name}
                            </Option>
                          ))}
                      </Select>
                    )}
                  </Form.Item>
                </div>
              </Col>
              <Col className="gutter-row" xs={24} sm={24} md={24} lg={6} xl={6}>
                <div className="form-content form-group">
                  <Form.Item label="Date of Joining">
                    {getFieldDecorator("date_of_joining", {
                      initialValue:
                        employee_details && employee_details.date_of_joining
                          ? moment(employee_details.date_of_joining)
                          : "",
                      rules: [
                        {
                          type: "object",
                          required: true,
                          message: "Please select date of joining!",
                        },
                      ],
                    })(
                      <DatePicker
                        format={data_setting["date_format"]}
                        disabledDate={(current) => {
                          return current > moment();
                        }}
                      />
                    )}
                  </Form.Item>
                </div>
              </Col>
            </Row>
            <Row className="" gutter={30}>
              <Col className="gutter-row" xs={24} sm={24} md={24} lg={6} xl={6}>
                <div className="form-content form-group">
                  <Form.Item label="Employment Type">
                    {getFieldDecorator("employment_type", {
                      initialValue:
                        employee_details && employee_details.employment_type
                          ? data_setting["employment"][
                              employee_details.employment_type
                            ]
                          : "",
                    })(
                      <Select
                        className="emp-type-selectbox selectbox"
                        showSearch
                        style={{ width: "100%" }}
                        placeholder="Select Employment Type"
                        optionFilterProp="children"
                      >
                        <Option value={data_setting["employment"]["Full-Time"]}>
                          Full Time
                        </Option>
                        <Option
                          value={data_setting["employment"]["Internship"]}
                        >
                          Internship
                        </Option>
                        <Option value={data_setting["employment"]["Contract"]}>
                          Contract
                        </Option>
                      </Select>
                    )}
                  </Form.Item>
                </div>
              </Col>
              <Col className="gutter-row" xs={24} sm={24} md={24} lg={6} xl={6}>
                <div className="form-content form-group">
                  <Form.Item label="Employment Sub Type">
                    {getFieldDecorator("employment_sub_type", {
                      initialValue:
                        employee_details &&
                        employee_details.employment_sub_type,
                      rules: [
                        {
                          required: true,
                          message: "Please select valid sub type",
                        },
                      ],
                    })(
                      <Select
                        className="emp-type-selectbox selectbox"
                        showSearch
                        style={{ width: "100%" }}
                        placeholder="Select Employment Type"
                        optionFilterProp="children"
                      >
                        <Option value="Probation">Probation</Option>
                        <Option value="Normal">Normal</Option>
                        <Option value="Notice Period">Notice Period</Option>
                      </Select>
                    )}
                  </Form.Item>
                </div>
              </Col>
              <Col className="gutter-row" xs={24} sm={24} md={24} lg={6} xl={6}>
                <div className="form-content form-group">
                  <Form.Item label="Designation">
                    {getFieldDecorator("designation", {
                      initialValue: employee_details
                        ? employee_details.designation
                        : "",
                      rules: [
                        {
                          required: true,
                          message: "Please enter designation!",
                        },
                      ],
                      validateTrigger: "onBlur",
                    })(
                      <Input
                        className="input"
                        maxLength="255"
                        placeholder="Enter designation"
                        onBlur={(e) => (e.target.value = e.target.value.trim())}
                      />
                    )}
                  </Form.Item>
                </div>
              </Col>
              <Col className="gutter-row" xs={24} sm={24} md={24} lg={6} xl={6}>
                <div className="form-content form-group">
                  <Form.Item label="Team">
                    {getFieldDecorator("team", {
                      initialValue:
                        !teams_loading &&
                        employee_details &&
                        employee_details.team
                          ? employee_details.team.id
                          : "",
                      rules: [
                        {
                          required: true,
                          message: "Select team",
                        },
                      ],
                      validateTrigger: "onBlur",
                    })(
                      <Select
                        className="team-selectbox selectbox"
                        showSearch
                        style={{ width: "100%" }}
                        placeholder="Select Team"
                        optionFilterProp="children"
                        loading={teams_loading}
                      >
                        {teams &&
                          teams.map((team) => (
                            <Option key={team.id} value={team.id}>
                              {team.title}
                            </Option>
                          ))}
                      </Select>
                    )}
                  </Form.Item>
                </div>
              </Col>
              <Col className="gutter-row" xs={24} sm={24} md={24} lg={6} xl={6}>
                <div className="form-content form-group">
                  <Form.Item label="DOB (Official)">
                    {getFieldDecorator("dob_official", {
                      initialValue:
                        employee_details && employee_details.dob_official
                          ? moment(employee_details.dob_official)
                          : "",
                      rules: [
                        {
                          type: "object",
                          required: true,
                          message: "Please select date of birth!",
                        },
                      ],
                    })(
                      <DatePicker
                        readOnly
                        format={data_setting["date_format"]}
                        disabledDate={(current) => {
                          return current > moment();
                        }}
                      />
                    )}
                  </Form.Item>
                </div>
              </Col>
              <Col className="gutter-row" xs={24} sm={24} md={24} lg={6} xl={6}>
                <div className="form-content form-group">
                  <Form.Item label="Role">
                    {getFieldDecorator("roles", {
                      initialValue: !roles_loading ? user_roles_id : [],
                      rules: [
                        {
                          required: true,
                          message: "Select role",
                        },
                      ],
                      validateTrigger: "onBlur",
                    })(
                      <Select
                        className="role-selectbox selectbox"
                        showSearch
                        mode="multiple"
                        style={{ width: "100%" }}
                        placeholder="Select Role"
                        optionFilterProp="children"
                        loading={roles_loading}
                      >
                        {roles &&
                          roles.map((role) => (
                            <Option key={role.id} value={role.id}>
                              {role.name.replace("_", " ").toUpperCase()}
                            </Option>
                          ))}
                      </Select>
                    )}
                  </Form.Item>
                </div>
              </Col>
              <Col className="gutter-row" xs={24} sm={24} md={24} lg={6} xl={6}>
                <div className="form-content form-group">
                  <Form.Item label="Level">
                    {getFieldDecorator("level", {
                      initialValue:
                        !levels_loading &&
                        employee_details &&
                        employee_details.level
                          ? employee_details.level.id
                          : "",
                      rules: [
                        {
                          required: true,
                          message: "Select level",
                        },
                      ],
                      validateTrigger: "onBlur",
                    })(
                      <Select
                        className="role-selectbox selectbox"
                        showSearch
                        style={{ width: "100%" }}
                        placeholder="Select Role"
                        optionFilterProp="children"
                        loading={levels_loading}
                      >
                        {levels &&
                          levels.map((level) => (
                            <Option key={level.id} value={level.id}>
                              {level.title}
                            </Option>
                          ))}
                      </Select>
                    )}
                  </Form.Item>
                </div>
              </Col>
            </Row>
            <h3 className="title text-primary">Your social profiles</h3>
            <Row className="" gutter={30}>
              <Col className="gutter-row" xs={24} sm={24} md={24} lg={6} xl={6}>
                <div className="form-content form-group-card">
                  <Form.Item label="Trello Username">
                    {getFieldDecorator("trello", {
                      initialValue: employee_details
                        ? employee_details.trello
                        : "",
                    })(
                      <Input
                        className="input"
                        maxLength="255"
                        placeholder="Enter trello username"
                        onBlur={(e) => (e.target.value = e.target.value.trim())}
                        onChange={(e) => {
                          this.props.form.setFields({
                            "trello": {
                              value: e.target.value,
                              errors: null,
                            },
                          });
                        }}
                      />
                    )}
                  </Form.Item>
                </div>
              </Col>
              <Col className="gutter-row" xs={24} sm={24} md={24} lg={6} xl={6}>
                <div className="form-content form-group-card">
                  <Form.Item label="Bitbucket Username">
                    {getFieldDecorator("bitbucket", {
                      initialValue: employee_details
                        ? employee_details.bitbucket
                        : "",
                    })(
                      <Input
                        className="input"
                        maxLength="255"
                        placeholder="Enter bitbucket username"
                        onBlur={(e) => (e.target.value = e.target.value.trim())}
                        onChange={(e) => {
                          this.props.form.setFields({
                            "bitbucket": {
                              value: e.target.value,
                              errors: null,
                            },
                          });
                        }}
                      />
                    )}
                  </Form.Item>
                </div>
              </Col>
              <Col className="gutter-row" xs={24} sm={24} md={24} lg={6} xl={6}>
                <div className="form-content form-group-card">
                  <Form.Item label="Dribbble Username">
                    {getFieldDecorator("dribble", {
                      initialValue: employee_details
                        ? employee_details.dribble
                        : "",
                    })(
                      <Input
                        className="input"
                        maxLength="255"
                        placeholder="Enter dribble username"
                        onBlur={(e) => (e.target.value = e.target.value.trim())}
                        onChange={(e) => {
                          this.props.form.setFields({
                            "dribble": {
                              value: e.target.value,
                              errors: null,
                            },
                          });
                        }}
                      />
                    )}
                  </Form.Item>
                </div>
              </Col>
              <Col className="gutter-row" xs={24} sm={24} md={24} lg={6} xl={6}>
                <div className="form-content form-group-card">
                  <Form.Item label="Github Username">
                    {getFieldDecorator("github", {
                      initialValue: employee_details
                        ? employee_details.github
                        : "",
                    })(
                      <Input
                        className="input"
                        maxLength="255"
                        placeholder="Enter Github username"
                        onBlur={(e) => (e.target.value = e.target.value.trim())}
                        onChange={(e) => {
                          this.props.form.setFields({
                            "github": {
                              value: e.target.value,
                              errors: null,
                            },
                          });
                        }}
                      />
                    )}
                  </Form.Item>
                </div>
              </Col>
              <Col className="gutter-row" xs={24} sm={24} md={24} lg={6} xl={6}>
                <div className="form-content form-group-card">
                  <Form.Item label="Twitter Username" colon={false}>
                    {getFieldDecorator("twitter", {
                      initialValue: employee_details
                        ? employee_details.twitter
                        : "",
                    })(
                      <Input
                        className="input"
                        maxLength="255"
                        placeholder="Enter Twitter username"
                        onBlur={(e) => (e.target.value = e.target.value.trim())}
                        onChange={(e) => {
                          this.props.form.setFields({
                            "twitter": {
                              value: e.target.value,
                              errors: null,
                            },
                          });
                        }}
                      />
                    )}
                  </Form.Item>
                </div>
              </Col>
              <Col className="gutter-row" xs={24} sm={24} md={24} lg={6} xl={6}>
                <div className="form-content form-group-card">
                  <Form.Item label="Facebook URL" colon={false}>
                    {getFieldDecorator("facebook", {
                      initialValue: employee_details
                        ? employee_details.facebook
                        : "",
                    })(
                      <Input
                        className="input"
                        maxLength="255"
                        placeholder="Enter Facebook URL"
                        onBlur={(e) => (e.target.value = e.target.value.trim())}
                        onChange={(e) => {
                          this.props.form.setFields({
                            "facebook": {
                              value: e.target.value,
                              errors: null,
                            },
                          });
                        }}
                      />
                    )}
                  </Form.Item>
                </div>
              </Col>
              <Col className="gutter-row" xs={24} sm={24} md={24} lg={6} xl={6}>
                <div className="form-content form-group-card">
                  <Form.Item label="LinkedIn URL" colon={false}>
                    {getFieldDecorator("linkedin", {
                      initialValue: employee_details
                        ? employee_details.linkedin
                        : "",
                    })(
                      <Input
                        className="input"
                        maxLength="255"
                        placeholder="Enter LinkedIn URL"
                        onBlur={(e) => (e.target.value = e.target.value.trim())}
                        onChange={(e) => {
                          this.props.form.setFields({
                            "linkedin": {
                              value: e.target.value,
                              errors: null,
                            },
                          });
                        }}
                      />
                    )}
                  </Form.Item>
                </div>
              </Col>
              <Col className="gutter-row" xs={24} sm={24} md={24} lg={6} xl={6}>
                <div className="form-content form-group-card">
                  <Form.Item label="Instagram Username" colon={false}>
                    {getFieldDecorator("instagram", {
                      initialValue: employee_details
                        ? employee_details.instagram
                        : "",
                    })(
                      <Input
                        className="input"
                        maxLength="255"
                        placeholder="Enter Instagram Username"
                        onBlur={(e) => (e.target.value = e.target.value.trim())}
                        onChange={(e) => {
                          this.props.form.setFields({
                            "instagram": {
                              value: e.target.value,
                              errors: null,
                            },
                          });
                        }}
                      />
                    )}
                  </Form.Item>
                </div>
              </Col>
              <Col className="gutter-row" xs={24} sm={24} md={24} lg={6} xl={6}>
                <div className="form-content form-group-card">
                  <Form.Item label="Blog URL" colon={false}>
                    {getFieldDecorator("blog", {
                      initialValue: employee_details
                        ? employee_details.blog
                        : "",
                    })(
                      <Input
                        className="input"
                        maxLength="255"
                        placeholder="Enter Blog URL"
                        onBlur={(e) => (e.target.value = e.target.value.trim())}
                        onChange={(e) => {
                          this.props.form.setFields({
                            "blog": {
                              value: e.target.value,
                              errors: null,
                            },
                          });
                        }}
                      />
                    )}
                  </Form.Item>
                </div>
              </Col>
              <Col className="gutter-row" xs={24} sm={24} md={24} lg={6} xl={6}>
                <div className="form-content form-group-card">
                  <Form.Item label="Medium URL" colon={false}>
                    {getFieldDecorator("medium", {
                      initialValue: employee_details
                        ? employee_details.medium
                        : "",
                    })(
                      <Input
                        className="input"
                        maxLength="255"
                        placeholder="Enter Medium URL"
                        onBlur={(e) => (e.target.value = e.target.value.trim())}
                        onChange={(e) => {
                          this.props.form.setFields({
                            "medium": {
                              value: e.target.value,
                              errors: null,
                            },
                          });
                        }}
                      />
                    )}
                  </Form.Item>
                </div>
              </Col>
              <Col className="gutter-row" xs={24} sm={24} md={24} lg={6} xl={6}>
                <div className="form-content form-group-card">
                  <Form.Item label="Youtube URL" colon={false}>
                    {getFieldDecorator("youtube", {
                      initialValue: employee_details
                        ? employee_details.youtube
                        : "",
                    })(
                      <Input
                        className="input"
                        maxLength="255"
                        placeholder="Enter Youtube URL"
                        onBlur={(e) => (e.target.value = e.target.value.trim())}
                        onChange={(e) => {
                          this.props.form.setFields({
                            "youtube": {
                              value: e.target.value,
                              errors: null,
                            },
                          });
                        }}
                      />
                    )}
                  </Form.Item>
                </div>
              </Col>
            </Row>

            <Row className="" gutter={30}>
              <Col
                className="gutter-row"
                xs={24}
                sm={24}
                md={24}
                lg={12}
                xl={12}
              >
                <Row>
                  <Col className="" xs={24} sm={24} md={24} lg={8} xl={8}>
                    <div className="form-content form-group">
                      <Form.Item label="Ex-Employee">
                        {getFieldDecorator("is_x", {
                          initialValue: employee_details
                            ? employee_details.is_x == "0"
                              ? false
                              : true
                            : true,
                        })(
                          <Radio.Group value={this.state.value}>
                            <Radio value={true}>Yes</Radio>
                            <Radio value={false}>No</Radio>
                          </Radio.Group>
                        )}
                      </Form.Item>
                    </div>
                  </Col>
                  <Col className="" xs={24} sm={24} md={24} lg={8} xl={8}>
                    <div className="form-content form-group disableProfileSwitch">
                      <Form.Item label="Disable Account:">
                        {getFieldDecorator(
                          "disable",
                          {}
                        )(
                          <Switch
                            className="switch-btn disable-switch"
                            defaultChecked={
                              employee_details
                                ? employee_details.disable == "yes"
                                  ? true
                                  : false
                                : false
                            }
                          /> //
                        )}
                      </Form.Item>
                    </div>
                  </Col>
                  <Col className="" xs={24} sm={24} md={24} lg={8} xl={8}>
                    <div className="form-content form-group">
                      <Form.Item label="Work log reminders">
                        {getFieldDecorator("work_report_reminders", {
                          initialValue:
                            employee_details &&
                            employee_details.work_report_reminders,
                        })(
                          <Select
                            className="role-selectbox selectbox"
                            style={{ width: "100%" }}
                            placeholder="Work log reminders"
                            optionFilterProp="children"
                          >
                            <Option key={0} value={"enabled"}>
                              Enabled
                            </Option>
                            <Option key={1} value={"disabled"}>
                              Disabled
                            </Option>
                          </Select>
                        )}
                      </Form.Item>
                    </div>
                  </Col>
                  {getFieldValue("is_x") && (
                    <Fragment>
                      <Col className="" xs={24} sm={24} md={24} lg={12} xl={12}>
                        <div className="form-content form-group">
                          <Form.Item label="Date of Exit">
                            {getFieldDecorator("date_of_exit", {
                              initialValue:
                                employee_details &&
                                employee_details.date_of_exit
                                  ? moment(employee_details.date_of_exit)
                                  : "",
                              rules: [
                                {
                                  type: "object",
                                  required: true,
                                  message: "Please select date of exit",
                                },
                              ],
                            })(
                              <DatePicker
                                format={data_setting["date_format"]}
                                disabledDate={(current) => {
                                  return current > moment();
                                }}
                              />
                            )}
                          </Form.Item>
                        </div>
                      </Col>
                      <Col className="" xs={24} sm={24} md={24} lg={24} xl={24}>
                        <div className="form-content form-group">
                          <Form.Item label="Exit Notes">
                            {getFieldDecorator("notes", {
                              initialValue: employee_details
                                ? employee_details.notes
                                : "",
                              rules: [
                                {
                                  required: true,
                                  message: "Please enter exit notes",
                                },
                              ],
                              validateTrigger: "onBlur",
                            })(
                              <TextArea
                                className="textarea"
                                rows="3"
                                placeholder="Exit Notes"
                                onBlur={(e) =>
                                  (e.target.value = e.target.value.trim())
                                }
                              />
                            )}
                          </Form.Item>
                        </div>
                      </Col>
                    </Fragment>
                  )}
                </Row>
              </Col>
            </Row>
          </form>
        </section>

        <section className="profile-save-btn-box mt--30">
          <div className="save-btn-box text-right">
            <Button
              className="btn btn-success btn-wide text-uppercase"
              onClick={this.handleSubmit}
              loading={loading}
              disabled={loading}
            >
              Save
            </Button>
          </div>
        </section>
      </Fragment>
    );
  }
}
export default Form.create({ name: "employee_contacts_form" })(
  ForAdminAndHr(withStore(AdminEditEmployeeDetails))
);
