import React from "react";
import { Button, Card, Spin, Tabs, Table } from "antd";
import moment from "moment"

const ViewNotification = ({
  closeModal,
  viewCardData,
  viewForAdmin,
  setViewModalLoading,
  viewModaloading,
}) => {
  const { TabPane } = Tabs;
  const [tabType, setTabType] = React.useState("Announcement");
  const userRoleName =  JSON.parse(localStorage.getItem("data")).user_roles_name;
  // console.log('userRoleNames',userRoleName)

  const admin = userRoleName.indexOf("admin") != -1;
  const hr = userRoleName.indexOf("hr") != -1;
  let seenEmployees;

  if (viewCardData && viewCardData.users && viewCardData.users.length > 0) {
    seenEmployees = viewCardData.users.filter((val) => val.is_seen === 1);
  }

  const columns = [
    {
      title: "Employee Id",
      dataIndex: "employee_id",
      key: "employee_id",
      render: (text) => <div>{text}</div>,
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "age",
      render: (text) => <div>{text}</div>,
    },
    {
      title: "Status",
      dataIndex: "is_seen",
      key: "is_seen",
      render: (text) => (
        <div>
          {text === 1 ? (
            <div style={{ color: "green" }}>Seen</div>
          ) : (
            <div style={{ color: "red" }}>Not Seen</div>
          )}
        </div>
      ),
    },
    {
      title: "Seen On",
      dataIndex: "seen_date",
      key: "seen_date",
      render: (text) => <div>{text}</div>,
    },
  ];

  const NotificationTab = () => {
    return (
      <>
        {viewCardData && (
          <div dangerouslySetInnerHTML={{ __html: viewCardData.description }} />
        )}
      </>
    );
  };

  const UsersTab = () => {
    return (
      <>
        {viewCardData && (
          <Table dataSource={viewCardData.users} columns={columns} />
        )}
      </>
    );
  };

  return (
    <>
      {viewModaloading ? (
        <div className="page-spinner">
          <Spin />
        </div>
      ) : (
        <Card
          title={viewCardData && viewCardData.title}
          className="card docFolder-card viewNotification"
          extra={
            viewCardData &&
            moment(viewCardData.announcement_date, 'YYYY-MM-DD HH:mm:ss').format('DD-MMM-YYYY')
          }
        >
          <Tabs
            style={(admin || hr ) ? { padding: "1rem 24px 0px" } : { padding: "0px 24px" }}
            defaultActiveKey={tabType}
            onChange={(key) => {
              setTabType(key);
            }}
          >
            <TabPane tab={(admin || hr ) && "Announcement"} key="Announcement">
              <NotificationTab />
            </TabPane>
            {(admin || hr )  && (
              <TabPane tab="Users" key="Users">
                <UsersTab />
              </TabPane>
            )}
          </Tabs>
          <div style={{ marginTop: "5rem", textAlign: "right", width: "100%" }}>
            <Button
              className="btn-success"
              onClick={() => {
                closeModal();
              }}
            >
              close
            </Button>
          </div>
        </Card>
      )}
    </>
  );
};

export default ViewNotification;
