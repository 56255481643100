import React, { Component, Fragment } from "react";
import {
  notification,
  Form,
  Button,
  Spin,
  Modal,
  Divider,
  Table,
  Row,
  Col,
  Input,
  Select,
} from "antd";
import { axiosInstance as api } from "../../../utilities/configureAxios";
import { getFilteredLeave } from "./LeaveManagement/DataManager";

// for selectbox
const { Option } = Select;

const FilterLeave = ({
  filterData,
  setFilterData,
  setShowResetButton,
  setModal1Visible,
}) => {
  const [isAutomated, setIsAutomated] = React.useState(filterData.is_automated);
  const [isApplicable, setIsApplicable] = React.useState(
    filterData.is_applicable_for_all
  );
  const [isActive, setIs_active] = React.useState(filterData.is_active);

  // Handling applicable for all
  const handleApplicableForAll = (value) => {
    setIsApplicable(value);
  };

  // Handling is automated
  const handleIsAutomated = (value) => {
    setIsAutomated(value);
  };

  // Handling is active
  const handleIsActive = (value) => {
    setIs_active(value);
  };

  // HandleSave
  const handleSaveButton = () => {
    setFilterData((prevValue) => {
      return {
        ...prevValue,
        is_automated: isAutomated,
        is_applicable_for_all: isApplicable,
        is_active: isActive,
      };
    });
    setShowResetButton(true);
    setModal1Visible(false);
  };

  return (
    <>
      <div className="">
        <Row className="" gutter={24}>
          {/* Applicable For all */}
          <Col
            className="gutter-row"
            xs={24}
            sm={24}
            md={24}
            lg={8}
            xl={8}
            xxl={8}
          >
            <div className="form-group">
              <label className="label">Applicable for all</label>
            </div>
          </Col>
          <Col
            className="gutter-row"
            xs={24}
            sm={24}
            md={24}
            lg={16}
            xl={16}
            xxl={16}
          >
            <div className="form-group">
              <Select
                allowClear={true}
                style={{ width: "100%" }}
                placeholder="Select option"
                optionFilterProp="children"
                onChange={handleApplicableForAll}
                value={isApplicable}
              >
                <Option value={1}>True</Option>
                <Option value={0}>False</Option>
              </Select>
            </div>
          </Col>

          {/* Automated*/}
          <Col
            className="gutter-row"
            xs={24}
            sm={24}
            md={24}
            lg={8}
            xl={8}
            xxl={8}
          >
            <div className="form-group">
              <label className="label">Automated</label>
            </div>
          </Col>
          <Col
            className="gutter-row"
            xs={24}
            sm={24}
            md={24}
            lg={16}
            xl={16}
            xxl={16}
          >
            <div className="form-group">
              <Select
                allowClear={true}
                style={{ width: "100%" }}
                placeholder="Select option"
                optionFilterProp="children"
                onChange={handleIsAutomated}
                value={isAutomated}
              >
                <Option value={1}>True</Option>
                <Option value={0}>False</Option>
              </Select>
            </div>
          </Col>

          {/*Is Active */}
          <Col
            className="gutter-row"
            xs={24}
            sm={24}
            md={24}
            lg={8}
            xl={8}
            xxl={8}
          >
            <div className="form-group">
              <label className="label">Leave is active</label>
            </div>
          </Col>
          <Col
            className="gutter-row"
            xs={24}
            sm={24}
            md={24}
            lg={16}
            xl={16}
            xxl={16}
          >
            <div className="form-group">
              <Select
                allowClear={true}
                style={{ width: "100%" }}
                placeholder="Select option"
                optionFilterProp="children"
                onChange={handleIsActive}
                value={isActive}
              >
                <Option value={1}>True</Option>
                <Option value={0}>False</Option>
              </Select>
            </div>
          </Col>
        </Row>
      </div>
      <div className="text-right">
        <Button
          className="btn btn-light-success text-uppercase"
          onClick={handleSaveButton}
        >
          Save
        </Button>
      </div>
    </>
  );
};

export default FilterLeave;
