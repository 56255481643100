import React, { Component, Fragment } from "react";
import { Layout } from "antd";
import { Helmet } from 'react-helmet'
import Salary from "./components/Salary";

const TITLE = 'Salary Slips'

export default class SalaryManagement extends Component {
  render() {
    return (
      <Fragment>
        <Helmet>
          <title>{TITLE}</title>
        </Helmet>
        <div className="page-wrapper">
          <Salary></Salary>
        </div>
      </Fragment>
    );
  }
}
