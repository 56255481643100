import React from "react";
import {
  Row,
  Col,
  Popover,
  Icon,
  Drawer,
  Button,
  notification,
  Modal,
  Spin,
} from "antd";

import DeclinedLeaves from "../DeclinedLeavesDrawer/DeclinedLeaves";
import CancelLeave from "../CancelLeave/CancelLeave";
import EditAtApproval from "../EditAtApproval/EditAtApproval";
import { leaveAction } from "../../DataManager";
const EmployeeDetailsCard = ({ leaveData, loadLeaveData, setLoader }) => {
  const [showDrawer, setShowDrawer] = React.useState(false);
  const [showCancelDrawer, setShowCancelDrawer] = React.useState(false);
  const [showApproveDrawer, setShowApproveDrawer] = React.useState(false);
  const { confirm } = Modal;

  // ---------------GETTING MONTH NAME FROM THE DATE
  const month = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  let date = new Date(leaveData.from_date);
  let startingMonth = month[date.getMonth()];
  let date2 = new Date(leaveData.to_date);
  let endingMonth = month[date2.getMonth()];
  // ---------------___________________--------------------

  const [cardData, setCardData] = React.useState({
    startMonth: startingMonth,
    startDate: leaveData.from_date.split("-")[2],
    endMonth: endingMonth,
    endDate: leaveData.to_date.split("-")[2],
    userName: leaveData.user ? leaveData.user.name : "",
    leaveType: leaveData.leave_type.name,
    status: "",
    from_session: "",
    to_session: "",
    statusColor: "",
    user_profile_pic: leaveData.user.profile_pic
  });
  const [leaveId, setLeaveId] = React.useState("");
  const [loading, setLoading] = React.useState(false);

  // RUN ONE TIME TO Set 'FROM DATE' TO 'TO DATE'
  React.useEffect(() => {
    setCardData((prevValue) => {
      let newFrom_session = "";
      let newTO_session = "";
      if (leaveData.from_date !== leaveData.to_date) {
        if (leaveData.from_session === 0) {
          newFrom_session = "full-day";
        } else {
          newFrom_session = "second-half";
        }

        if (leaveData.to_session === 0) {
          newTO_session = "first-half";
        } else {
          newTO_session = "full-day";
        }
      } else {
        if (leaveData.from_session === 0) {
          newFrom_session = "first-half";
          newTO_session = "first-half";
        } else if (leaveData.from_session === 1) {
          newFrom_session = "second-half";
          newTO_session = "second-half";
        } else {
          newFrom_session = "full-day";
          newTO_session = "full-day";
        }
      }

      return {
        ...prevValue,
        from_session: newFrom_session,
        to_session: newTO_session,
      };
    });
  }, []);

  // RUN ONE TIME TO SET STATUS
  React.useState(() => {
    setCardData((prevValue) => {
      let statusMsg = "";
      let newStatusColor = "";

      if (leaveData.status === 0) {
        statusMsg = "Pending";
        newStatusColor = "warning";
      } else if (leaveData.status === 1) {
        statusMsg = "Approved";
        newStatusColor = "success";
      } else if (leaveData.status === 2) {
        statusMsg = "Declined";
        newStatusColor = "danger";
      } else if (leaveData.status === 3) {
        statusMsg = "Cancelled";
        newStatusColor = "info";
      } else {
        statusMsg = "Cancel Request";
        newStatusColor = "btn-secondary";
      }

      return {
        ...prevValue,
        status: statusMsg,
        statusColor: newStatusColor,
      };
    });
  }, []);

  // ON CLICKING STATUS
  const handleStatus = () => {
    setShowDrawer(true);
  };

  // ON CLICKING STATUS
  const handleCancelStatus = () => {
    setShowCancelDrawer(true);
  };
  const handleApproveStatus = () => {
    setShowApproveDrawer(true);
  };

  // ON CLICKING STATUS
  const handleClose = () => {
    setShowDrawer(false);
  };
  const handleCancelClose = () => {
    setShowCancelDrawer(false);
  };
  const handleApproveClose = () => {
    setShowApproveDrawer(false);
  };

  const handleSubmit = (status, id) => {
    setLoading(true);
    setLoader(true);
    leaveAction(
      {
        status: status,
      },
      id
    )
      .then((res) => {
        setLoading(false);
        setLoader(false);
        notification.success({
          message:
            status == 2 ? "Declined successfully" : "Approved Successfully",
        });
        loadLeaveData();
      })
      .catch((error) => {
        setLoading(false);
        setLoader(false);
        if (error.response.status === 422) {
          notification.error("Something went wrong.");
        } else {
          notification.error(error.response.data.errors);
        }
      });
  };

  const handleCancel = (id) => {
    confirm({
      title: "Are you Sure ?",
      onOk() {
        setLoading(true);
        setLoader(true);
        leaveAction(
          {
            status: 3,
            action_reason: null,
          },
          id
        )
          .then((res) => {
            notification.success({
              message: "Cancelled successfully",
            });
            setLoading(false);
            setLoader(false);
            loadLeaveData();
          })
          .catch((error) => {
            setLoading(false);
            setLoader(false);
            if (error.response.status === 422) {
              notification.error("Something went wrong.");
            } else {
              notification.error(error.response.data.errors);
            }
          });
      },
    });
  };
  function truncateText(text, maxLength) {
    if (text.length > maxLength) {
      return text.substring(0, maxLength) + "...";
    }
    return text;
  }


  return (
    <>
      <div className="card-header-container" >
        <div className="part first-part">
          <div className="dateDetail-box">
            {/* START MONTH */}
            <div className="double">
              <div className="month-name heading">{cardData.startMonth}</div>
              <div className={`date-content ${cardData.from_session}`}>
                {cardData.startDate}
              </div>
            </div>

            {/* END MONTH */}
            <div className="double">
              <div className="month-name heading">{cardData.endMonth}</div>
              <div className={`date-content ${cardData.to_session}`}>
                {cardData.endDate}
              </div>
            </div>
          </div>

          {/* EMPLOYEE Profile */}
          {/* <div className="empName-box">
            <div
              className="label lg-hide"
              style={{ fontSize: "0.9rem !important" }}
            >
              Employee Profile
            </div>
            <div className='profileAP-container_bill' style={{ backgroundImage: `url(${cardData.user_profile_pic})` }}></div>
          </div> */}

          {/* EMPLOYEE NAME */}
          <div className="emp_name">
            <div
              className='profileAP-container_bill_admin'
              style={{ backgroundImage: `url(${cardData.user_profile_pic})` }}
            ></div>
            <div className="empName value" style={{ fontSize: "0.9rem", marginLeft: "10px", minWidth: '120px', maxWidth: "120px", overflow: "hidden", textOverflow: "ellipsis" }}>
              {truncateText(cardData.userName, 15)} {/* Limit to 15 characters */}
            </div>
          </div>



          {/* LEAVE TYPE */}
          <div className="leaveType-box">
            <div className="label lg-hide">Leave Type</div>
            <div className="leaveType value" style={{ fontSize: "0.9rem !important" }}>
              {cardData.leaveType}
            </div>
          </div>

        </div>

        {/* STATUS  */}
        <div className="part second-part" style={{ justifyContent: "start" }}>
          <div className="status-box">
            <div className="label lg-hide">Status</div>
            <div
              className={`status-badge ${cardData.statusColor} badge-wide badge-bold`}
            >
              {cardData.status}
            </div>
          </div>
          <div className="action-box">
            <div className="admin-leave-action">
              {/* 
              0 => Pending
              1 => Approved
              2 => Rejected
              3 => Cancelled
              4 => Requested
            */}

              {[0].includes(leaveData.status) && (
                <Button
                  className="btn  btn-danger text-uppercase"
                  style={{ marginRight: "5px", fontSize: "0.9rem" }}
                  onClick={() => {
                    handleStatus();
                    setLeaveId(leaveData.id);
                  }}
                >
                  Decline
                </Button>
              )}

              {[1, 4].includes(leaveData.status) && (
                <Button
                  className="btn btn-light-danger  text-uppercase"
                  style={{ marginRight: "5px", fontSize: "0.9rem" ,width:"7rem"}}
                  onClick={() => {
                    handleCancelStatus();
                    // handleCancel(leaveData.id)
                    setLeaveId(leaveData.id);
                  }}
                >
                  Cancel
                </Button>
              )}

              {[0].includes(leaveData.status) && (
                <Button
                  className="btn  btn-success text-uppercase"
                  style={{ fontSize: "0.9rem" }}
                  onClick={() => {
                    handleApproveStatus()
                    // handleSubmit(1, leaveData.id);
                    setLeaveId(leaveData.id);
                  }}
                >
                  Approve
                </Button>
              )}
            </div>
          </div>
        </div>
      </div>
      <p
        style={{
          marginTop: "1rem",
          wordBreak: "break-All",
          fontSize: "0.9rem",
        }}
      >
        <strong>Leave Reason: </strong>
        {leaveData.notes}
      </p>

      {/* for approved leaves */}
      <Drawer
        className="navigator-drawer declinedLeaves-drawer"
        title="Status"
        placement="right"
        width={500}
        onClose={handleClose}
        visible={showDrawer}
        bodyStyle={{ padding: 0 }}
        destroyOnClose={true}
      >
        {/* DRAWER FOR APPROVAL AND REJECTION */}
        <DeclinedLeaves
          leaveId={leaveId}
          closeModal={() => setShowDrawer(false)}
          loadLeaveData={loadLeaveData}
        />
      </Drawer>

      <Drawer
        className="navigator-drawer declinedLeaves-drawer"
        title="Status"
        placement="right"
        width={500}
        onClose={handleCancelClose}
        visible={showCancelDrawer}
        bodyStyle={{ padding: 0 }}
        destroyOnClose={true}
      >
        {/* DRAWER FOR APPROVAL AND REJECTION */}
        <CancelLeave
          leaveId={leaveId}
          closeModal={() => setShowCancelDrawer(false)}
          loadLeaveData={loadLeaveData}
        />
      </Drawer>

      <Drawer
        className="navigator-drawer declinedLeaves-drawer"
        title="Status"
        placement="right"
        width={500}
        onClose={handleApproveClose}
        visible={showApproveDrawer}
        bodyStyle={{ padding: 0 }}
        destroyOnClose={true}
      >
        {/* DRAWER FOR APPROVAL AND REJECTION */}
        <EditAtApproval
          leaveId={leaveId}
          closeModal={handleApproveClose}
          loadLeaveData={leaveData}
          refreshPage={() => loadLeaveData()}
        />
      </Drawer>
    </>
  );
};

export default EmployeeDetailsCard;
