import React, { Component, Fragment } from "react";
import { Link } from 'react-router-dom';
import { notification, Icon, Input, Button, message, Spin, Row, Col, Layout, Select, Card } from "antd";

import ForNonAdmin from "../../../hocs/ForNonAdmin";
import ProfileComplete from "../../../hocs/ProfileComplete";
import { withStore } from "../../../hocs/withStore";

import _ from 'lodash';
import { axiosInstance as api } from '../../../utilities/configureAxios';

import salary from '../../../assets/images/icons/icon-salary-bg.svg';
import SalarySlip from '../../../assets/images/icons/sibebar/salary-slips.svg';
import view from '../../../assets/images/icons/icon-view-bg.svg';
import RequireAuth from "../../../hocs/RequireAuth";
// import axios from 'axios';


// year selectbox

const { Option } = Select;

class Salary extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            years: [],
            salary_slips: [],
            selected_year: new Date().getFullYear(),
            current_Index: 0,
            month_present: [],
            current_month: 1,
            requested_slips: [],
            requested_list: [],
            requested_month: [],
            disabledMonth: [],
            RequestLoading: true
        };
    }

    componentDidMount = () => {
        let year = new Date().getFullYear();
        this.setState({ years: [year - 2, year - 1, year], selected_year: year });
        this.setState({ loading: true });

        //get applied list 
        api
            .get("/salary-slips/get/" + year)
            .then(res => {
                this.setState({
                    requested_list: res.data.data,
                    requested_month: []
                })

            });


        //get salary slips
        api.get("/salary-slips/" + year)
            .then(res => {
                const salary_slips = _.get(res, 'data.data', [])
                this.setState({ salary_slips, loading: false });

                const monthsPresent = [];
                salary_slips.forEach(slip => {
                    monthsPresent.push(slip.month);
                });
                var date = new Date();
                this.setState({
                    month_present: monthsPresent,
                    current_month: date.getMonth() + 1
                });
            })
            .catch(error => {
                notification['error']({
                    message: _.get(error, 'response.data.errors.message', "Oops! Something went wrong!")
                });
                this.setState({ loading: false });
            });
    }

    onChange = (year) => {
        this.setState({ selected_year: year });
        this.setState({ loading: true });

        //get requested list
        api
            .get("/salary-slips/get/" + year)
            .then(res => {
                this.setState({
                    requested_list: res.data.data,
                    requested_month: [],
                    disabledMonth: []
                })

            });

        //get salary slips
        api.get("/salary-slips/" + year)
            .then(res => {
                const salary_slips = _.get(res, 'data.data', [])
                this.setState({ salary_slips, loading: false });

                const monthsPresent = [];
                salary_slips.forEach(slip => {
                    monthsPresent.push(slip.month);
                });
                var date = new Date();
                this.setState({
                    month_present: monthsPresent,
                    current_month: date.getMonth() + 1
                });
            })
            .catch(error => {
                notification['error']({
                    message: _.get(error, 'response.data.errors.message', "Oops! Something went wrong!")
                });
                this.setState({ loading: false });
            });
    }

    handleRequestButton = (year, month) => {
        this.setState({
            RequestLoading: true,
            disabledMonth: [...this.state.disabledMonth, month],
        })

        let formData = new FormData();
        formData.append("year", year);
        formData.append("month", month);

        api
            .post("/salary-slips", formData).then(data => {
                message.success("Request posted Sucessfully.");
                this.setState({
                    requestLoading: false,   //check here,
                })


            }).catch(err => {
                message.error("Some error while posting Request");
                let newDisabledMonthArray = this.state.disabledMonth.filter(arrmonth => arrmonth != month);
                this.setState({
                    RequestLoading: true,
                    disabledMonth: newDisabledMonthArray
                })
            })

    }



    render() {
        const { salary_slips, selected_year, years, loading, month_present, current_month, requested_list, requested_month, disabledMonth } = this.state;

        let month_number = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
        let months_array = {
            1: ["January", "jan"],
            2: ["February", "feb"],
            3: ["March", "mar"],
            4: ["April", "apr"],
            5: ["May", "may"],
            6: ["June", "jun"],
            7: ["July", "jul"],
            8: ["August", "aug"],
            9: ["September", "sep"],
            10: ["October", "oct"],
            11: ["November", "nov"],
            12: ["December", "dec"]
        };
        //extract already requested month 

        for (let i = 0; i < this.state.requested_list.length; i++) {
            this.state.requested_month.push(this.state.requested_list[i].month);
        }


        let cardsDisplay = month_number.map((month, index) => {
            let cardProperties = "card month-card " + months_array[month][1] + "-card card-shadow";
            let cardClass = "card-title " + months_array[month][1];
            let linkToDownload = "";

            for (let i = 0; i < salary_slips.length; i++) {

                if (salary_slips[i].month == index + 1) {
                    linkToDownload = salary_slips[i].url;
                }
            }
            // if(month <= current_month && selected_year == new Date().getFullYear())
            if (month_present.includes(`${index + 1}`) && (month <= current_month
                && selected_year == new Date().getFullYear() ||
                selected_year < new Date().getFullYear())) {

                return (
                    <Col className="gutter-row" xs={24} sm={24} md={12} lg={12} xl={6}>
                        <Card className={cardProperties}>

                            <h3 className={cardClass}>{months_array[month][0]}</h3>
                            <div className="card-footer">
                                <Button className="btn btn-light-primary text-uppercase"><a href={linkToDownload} target="_blank" >Download</a></Button>
                            </div>
                        </Card>
                    </Col>
                )
            }
            else {//if not present then 2 case ->either request or not valid
                if (month < current_month && selected_year == new Date().getFullYear()
                    || selected_year < new Date().getFullYear()) {

                    if (requested_month.includes(`${index + 1}`)) {
                        return (
                            <Col className="gutter-row" xs={24} sm={24} md={12} lg={12} xl={6}>
                                <Card className={cardProperties}>
                                    <h3 className={cardClass}>{months_array[month][0]}</h3>
                                    <div className="card-footer">
                                        <Button className="btn btn-light-success text-uppercase" disabled >Requested</Button>
                                    </div>
                                </Card>
                            </Col>
                        )
                    } else {
                        return (
                            <Col className="gutter-row" xs={24} sm={24} md={12} lg={12} xl={6}>
                                <Card className={cardProperties}>
                                    <h3 className={cardClass}>{months_array[month][0]}</h3>
                                    <div className="card-footer">
                                        <Button className="btn btn-light-success text-uppercase"
                                            disabled={disabledMonth.includes(month) ? true : false}
                                            loading={requested_month.includes(month) ? this.state.RequestLoading : false}
                                            onClick={() => { this.handleRequestButton(selected_year, month) }}>Request</Button>
                                    </div>
                                </Card>
                            </Col>
                        )
                    }
                } else {//return N/A
                    return (
                        <Col className="gutter-row" xs={24} sm={24} md={12} lg={12} xl={6}>
                            <Card className={cardProperties}>
                                <h3 className={cardClass}>{months_array[month][0]}</h3>
                                <div className="card-footer">
                                    <Button className="btn btn-light-secondary text-uppercase disabled">N/A</Button>
                                </div>
                            </Card>
                        </Col>
                    )
                }
            }
        })//array ends




        return (

            <div className="salary-dashboard-container">
                {loading ? (<div className="page-spinner"><Spin /></div>) : (
                    <Fragment>
                        {/* page Header */}
                        <div className="page-header animated slideInUp">
                            <div className="page-title">
                                <h2 className="title"><img className="icon icon-view" src={SalarySlip} alt="view icon" /> Salary Slips</h2>
                                {/* <h2 className="title"><img className="icon icon-view" src={salary} alt="view icon" /> Salary Slips</h2> */}
                            </div>
                        </div>

                        {/* page info title */}

                        <section className="page-info-box animated slideInRight">
                            <h3 className="info-title">Month-wise slips</h3>
                            <div className="right single-item">
                                <div className="year-filter">
                                    <Select className="year-selectbox selectbox primary-shadow"
                                        style={{ width: 85 }}
                                        placeholder="Year"
                                        defaultValue={selected_year}
                                        optionFilterProp="children"
                                        onChange={this.onChange}
                                        filterOption={(input, option) =>
                                            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                    >
                                        {years.map(year => (
                                            <Option key={year} disabled={selected_year == year}>{year}</Option>
                                        ))}
                                    </Select>
                                </div>
                            </div>
                        </section>

                        {/* salary cards */}

                        <section className="content-section salaryslips-card-section animated slideInUp">
                            <Row className="" gutter={24}>
                                {
                                    cardsDisplay
                                }
                            </Row>
                        </section>
                    </Fragment>
                )}
            </div>
        )
    }
}

export default RequireAuth(withStore(ProfileComplete(Salary)))