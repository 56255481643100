import React, { Component, Fragment } from 'react';
import {
  Form,
  notification,
  Icon,
  Rate,
  Button,
  Row,
  Col,
  Select,
  Card,
} from 'antd';
import skill_image from '../../../../assets/images/icons/profile/skills/skill.svg';
import _ from 'lodash';
import { axiosInstance as api } from '../../../../utilities/configureAxios';
import url from '../../../../config/url';
import { withStore } from '../../../../hocs/withStore';
import ForAdminAndHr from '../../../../hocs/ForAdminAndHr';

// Dropdown
const { Option } = Select;

class AdminEditTechnicalSkills extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user_skills: props.user_skills,
      employee_id: props.employee_id,
      skills: null,
      loading: false,
      delete_loading: false,
      skills_loading: true,
    };
  }

  componentDidMount = () => {
    this.setState({ loading: true });
    api
      .get(url['getSkills'])
      .then((res) => {
        const skills = _.get(res, 'data.data', []);
        this.setState({ skills, skills_loading: false });
      })
      .catch((error) => {
        notification['error']({
          message: _.get(
            error,
            'response.data.errors.message',
            'Oops! Something went wrong!'
          ),
        });
        this.setState({ skills_loading: false });
      });
    this.setState({ loading: false });
  };

  handleSubmit = (e) => {
    e.preventDefault();

    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        const formData = new FormData();
        formData.append('self_rating', values.self_rating);
        this.setState({ loading: true });
        api
          .post(
            url['addUserSkill'] +
              '/' +
              values.skill_name +
              '/user/' +
              this.state.employee_id,
            formData
          )
          .then((data) => {
            notification['success']({
              message: 'Skill added successfully!',
            });
            // this.props.form.setFieldsValue({
            //     self_rating: 3,
            //     skill_name: null
            //   });

            this.props.form.resetFields();
            api
              .get(url['getSkills'] + '/' + this.state.employee_id)
              .then((res) => {
                const user_skills = _.get(res, 'data.data', []);
                this.setState({ user_skills, loading: false });
              })
              .catch((error) => {
                notification['error']({
                  message: _.get(
                    error,
                    'response.data.errors.message',
                    'Oops! Something went wrong!'
                  ),
                });
                this.setState({ loading: false });
              });
          })
          .catch((error) => {
            const error_code = _.get(error, 'response.status');
            if (error_code && error_code == '422') {
              notification['error']({
                message: Object.values(
                  _.get(
                    error,
                    'response.data.errors',
                    'Oops! Something went wrong!'
                  )
                ),
              });
            } else {
              notification['error']({
                message: _.get(
                  error,
                  'response.data.errors.message',
                  'Oops! Something went wrong!'
                ),
              });
            }
            this.setState({ loading: false });
          });
      }
    });
  };

  handleDelete = (user_skill_id) => {
    this.setState({ delete_loading: true });
    api
      .delete(url['mySkills'] + '/' + user_skill_id)
      .then((data) => {
        notification['success']({
          message: 'Skill deleted successfully!',
        });
        api
          .get(url['getSkills'] + '/' + this.state.employee_id)
          .then((res) => {
            const user_skills = _.get(res, 'data.data', []);
            this.setState({ user_skills, delete_loading: false });
          })
          .catch((error) => {
            notification['error']({
              message: _.get(
                error,
                'response.data.errors.message',
                'Oops! Something went wrong!'
              ),
            });
            this.setState({ delete_loading: false });
          });
      })
      .catch((error) => {
        const error_code = _.get(error, 'response.status');
        notification['error']({
          message: _.get(
            error,
            'response.data.errors.message',
            'Oops! Something went wrong!'
          ),
        });
        this.setState({ delete_loading: false });
      });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const { user_skills, loading, skills, skills_loading, delete_loading } =
      this.state;
    return (
      <Fragment>
        <section className="tab-content-container technical-skills-tab card-shadow">
          <div className="technical-skills-heading">
            <h3 className="title text-primary">Add employees skills</h3>
          </div>
          <Form>
            <Row className="" gutter={30}>
              <Col
                className="gutter-row"
                xs={24}
                sm={24}
                md={24}
                lg={12}
                xl={12}
                xxl={12}
              >
                <div className="form-content form-group">
                  <Form.Item label="Skill Name" colon={false}>
                    {getFieldDecorator('skill_name', {
                      rules: [
                        {
                          required: true,
                          message: 'Select skill',
                        },
                      ],
                      validateTrigger: 'onBlur',
                    })(
                      <Select
                        className="role-selectbox selectbox"
                        showSearch
                        style={{ width: '100%' }}
                        placeholder="Select Skill"
                        optionFilterProp="children"
                        loading={skills_loading}
                      >
                        {skills &&
                          skills.map((skill) => (
                            <Option key={skill.slug} value={skill.slug}>
                              {skill.title}
                            </Option>
                          ))}
                      </Select>
                    )}
                  </Form.Item>
                </div>
              </Col>
              <Col
                className="gutter-row"
                xs={24}
                sm={24}
                md={24}
                lg={6}
                xl={6}
                xxl={6}
              >
                <div className="form-content form-group">
                  <Form.Item label="Rate employee for this skill" colon={false}>
                    {getFieldDecorator('self_rating', {
                      initialValue: 3,
                      rules: [
                        {
                          required: true,
                          message: 'Select rating',
                        },
                      ],
                    })(<Rate className="rating" allowClear={false} />)}
                  </Form.Item>
                </div>
              </Col>
              <Col
                className="gutter-row"
                xs={24}
                sm={24}
                md={24}
                lg={6}
                xl={6}
                xxl={6}
              >
                <div className="form-content form-group text-right sm-row-align">
                  <Form.Item
                    label="Add this skill"
                    colon={false}
                    className="add-skill-label"
                  >
                    <Button
                      className="btn btn-light-primary text-uppercase"
                      onClick={this.handleSubmit}
                      loading={loading}
                      disabled={loading}
                    >
                      + Add this Skill
                    </Button>
                  </Form.Item>
                </div>
              </Col>
            </Row>
          </Form>
        </section>

        {/* Technical skills cards */}
        <section className="content-section skills-card-section">
          <Row className="" gutter={30}>
            {user_skills &&
              user_skills.map((user_skill) => (
                <Col
                  className="gutter-row"
                  xs={24}
                  sm={24}
                  md={24}
                  lg={12}
                  xl={12}
                  xxl={8}
                >
                  <Card className="card card-shadow skills-card">
                    <div className="skill-icon-box">
                      <img
                        className="icon icon-link"
                        src={skill_image}
                        alt={user_skill.skill_name}
                      />
                    </div>
                    <div className="skill-title-box">
                      <h4 className="title">{user_skill.skill_name}</h4>
                      <Rate className="rating" value={user_skill.self_rating} />
                    </div>
                    <div className="skill-action-box">
                      <button
                        type="button"
                        className="ant-btn btn btn-light-danger btn-icon"
                        onClick={() => this.handleDelete(user_skill.id)}
                        loading={delete_loading}
                        disabled={delete_loading}
                      >
                        <Icon type="delete" theme="filled" />
                      </button>
                    </div>
                  </Card>
                </Col>
              ))}
            {!user_skills ||
              (user_skills.length == 0 && (
                <Col
                  className="gutter-row"
                  xs={24}
                  sm={24}
                  md={24}
                  lg={12}
                  xl={12}
                  xxl={8}
                >
                  <Card className="card card-shadow skills-card">
                    <h3>No skills found! 😟</h3>
                  </Card>
                </Col>
              ))}
          </Row>
        </section>
      </Fragment>
    );
  }
}

export default Form.create({ name: 'skills_form' })(
  ForAdminAndHr(withStore(AdminEditTechnicalSkills))
);
