import React from 'react';
import GlobalContext from '../contexts/GlobalContext';

export const withStore = WrappedComponent => {
  return class extends React.Component {
    render() {
      return (
        <GlobalContext.Consumer>
          {context => <WrappedComponent store={context} {...this.props} />}
        </GlobalContext.Consumer>
      );
    }
  };
};
