import React from "react";
import { Icon, Button, Drawer, Tabs } from "antd";
import _ from "lodash";
import leave from "../../../../../assets/images/icons/sibebar/leave-management.svg";
import AllRequests from "./Tabs/AllRequestTab/AllRequests";
import ManageLeaves from "./Tabs/ManageLeavesTab/ManageLeaves";
import AddLeaveType from "./AddLeaveType/AddLeaveType";
import AddLeave from "./AddLeave/AddLeave";
import { withStore } from "../../../../../hocs/withStore";
import CreateEmployeeLeave from "../components/CreateLeave/ApplyForEmployeeLeave";
import NotificationUpdate from "../../../../../hocs/NotificationUpdate";
const LeaveDashboard = (props) => {
  const [fileImages, setFileImages] = React.useState([]);
  const store = props && props.store && props.store.data && props.store.data;

  const userRoles = _.get(props, "store.data.user_roles_name", []);
  const isAdminOrHr =
  userRoles.indexOf("admin") !== -1 || userRoles.indexOf("hr") !== -1;
  const isReportingManagerArray = _.get(
    props,
    "store.data.user_roles_name",
    true
    );
    const isReportingManager = isReportingManagerArray.indexOf("reporting_manager") != -1;
    // console.log('role============',userRoles,isReportingManager);

  // STATE FOR BUTTON VISIBLE
  const [buttonVisible, setButtonVisible] = React.useState({
    addLeave: true,
    leaveType: false,
    createLeaveType: false,
  });
  // STATE FOR MODAL VISIBLE
  const [modalVisible, setModalVisible] = React.useState({
    addLeaveModal: false,
    leaveTypeModal: false,
    createLeaveModal: false,
  });

  const [tabType, setTabType] = React.useState("addLeave");
  const [dataUpdate, setDataUpdate] = React.useState(false);
  // for tabs
  const { TabPane } = Tabs;
  return (
    <>
      <div className="leave-dashboard-container admin">
        {/* page Header */}
        <div className="page-header">
          <div className="page-title">
            <h1 className="title">
              <img className="icon icon-itassets" src={leave} alt="icon" />{" "}
              {/* {!not_hr ? 'leave management hr' : 'leave management admin'} */}
              Out of Office
            </h1>
          </div>
          <div className="footer-buttons">
            {/* TOP CORNER BUTTON STARTS FROM HERE */}

            {/* ADD LEAVE BUTTON */}

            {tabType === "addLeave" && !isReportingManager &&(
              <Button
                className="btn text-uppercase btn-primary btn-one"
                onClick={() =>
                  setModalVisible((prevValue) => {
                    return { ...prevValue, createLeaveModal: true };
                  })
                }
              >
                <span className="lg-hide">
                  <Icon type="form" />
                </span>
                Apply Leave
              </Button>
            )}

            {tabType === "leaveType" && (
              <Button
                className="btn text-uppercase btn-primary btn-one"
                onClick={() =>
                  setModalVisible((prevValue) => {
                    return { ...prevValue, addLeaveModal: true };
                  })
                }
              >
                <span className="lg-hide">
                  <Icon type="form" />
                </span>
                Assign Leave
              </Button>
            )}

            {tabType === "createLeaveType" && (
              <Button
                className="btn text-uppercase btn-primary btn-one"
                onClick={() =>
                  setModalVisible((prevValue) => {
                    return {
                      ...prevValue,
                      leaveTypeModal: true,
                    };
                  })
                }
              >
                <span className="lg-hide">
                  <Icon type="form" />
                </span>
                Add Leave Type
              </Button>
            )}
          </div>
        </div>

        {/* PAGE SUB HEADINGS */}
        <section className="page-info-box">
          <Tabs
            defaultActiveKey={tabType}
            onChange={(key) => {
              setTabType(key);
            }}
          >
            <TabPane tab="All requests" key="addLeave">
              <AllRequests
                setButtonVisible={setButtonVisible}
                dataUpdate={dataUpdate}
                setDataUpdate={setDataUpdate}
              />
            </TabPane>
            {(!isReportingManager || isAdminOrHr) && (
              <TabPane tab="Manage leaves" key="leaveType">
                <ManageLeaves
                  setButtonVisible={setButtonVisible}
                  dataUpdate={dataUpdate}
                  setDataUpdate={setDataUpdate}
                />
              </TabPane>
            )}
          </Tabs>
        </section>

        {/* for Adding a leave */}
        {modalVisible && modalVisible.addLeaveModal && (
          <Drawer
            className="navigator-drawer createLeaveType-drawer"
            title="Assign Leave"
            placement="right"
            width={500}
            onClose={() =>
              setModalVisible((prevValue) => {
                return { ...prevValue, addLeaveModal: false };
              })
            }
            visible={modalVisible.addLeaveModal}
            bodyStyle={{ padding: 0 }}
            destroyOnClose={true}
          >
            <AddLeave
              setModalVisible={setModalVisible}
              setDataUpdate={setDataUpdate}
            />
          </Drawer>
        )}

        {/* for Adding a leave Type */}
        {modalVisible && modalVisible.leaveTypeModal && (
          <Drawer
            className="navigator-drawer createLeaveType-drawer"
            title="Add Leave Type"
            placement="right"
            width={500}
            onClose={() =>
              setModalVisible((prevValue) => {
                return {
                  ...prevValue,
                  leaveTypeModal: false,
                };
              })
            }
            visible={modalVisible.leaveTypeModal}
            bodyStyle={{ padding: 0 }}
            destroyOnClose={true}
          >
            <AddLeaveType
              closeModal={setModalVisible}
              setDataUpdate={setDataUpdate}
            />
          </Drawer>
        )}

        {/* for create a leave type */}
        {modalVisible && modalVisible.createLeaveModal && (
          <Drawer
            className="navigator-drawer createLeaveType-drawer"
            title="Apply Leave"
            placement="right"
            width={500}
            destroyOnClose={true}
            onClose={() => {
              setFileImages([]);
              setModalVisible((prevValue) => {
                return { ...prevValue, createLeaveModal: false };
              });
            }}
            visible={modalVisible.createLeaveModal}
            bodyStyle={{ padding: 0 }}
          >
            <CreateEmployeeLeave
              closeModal={setModalVisible}
              setDataUpdate={setDataUpdate}
              fileImages={fileImages}
              setFileImages={setFileImages}
            />
          </Drawer>
        )}
      </div>
    </>
  );
};

export default withStore(NotificationUpdate(LeaveDashboard));
