import React, { Component } from "react";

import { Button } from "antd";

const CardDetails = ({
  cardData,
  leave,
  handleCancel,
  padding,
  openDrawer,
  setLeaveId,
}) => {
  // const {cardData, leave, handleCancel, padding,openDrawer,setLeaveId } = props
  return (
    <div className="card-header-container" style={{ padding: padding }}>
      <div className="part first-part" style={{ alignItems: "center" }}>
        <div className="dateDetail-box">
          {/* START MONTH */}
          <div className="double">
            <div className="month-name heading">{cardData.startMonth}</div>
            <div className={`date-content ${cardData.from_session}`}>
              {cardData.startDate}
            </div>
          </div>
          {/* END MONTH */}
          <div className="double">
            <div className="month-name heading">{cardData.endMonth}</div>
            <div className={`date-content ${cardData.to_session}`}>
              {cardData.endDate}
            </div>
          </div>
        </div>
        <div className="leaveReason-box">
          <div className="label lg-hide">Reason for leave</div>
          <div className="leave-description" style={{ overflow: "auto" }}>
            {leave.notes}
          </div>
        </div>
      </div>
      <div className="part second-part" style={{ alignItems: "center" }}>
        <div className="leaveType-box">
          <div className="label lg-hide">Leave type</div>
          <div className="leave-type">{leave.leave_type.name}</div>
        </div>
        <div className="status-box">
          <div className="label lg-hide">Status</div>
          <div
            className={`status-badge ${cardData.statusColor} badge-wide badge-bold`}
          >
            {cardData.status}
          </div>
        </div>
        <div className="action-box">
          {leave.status !== 0 && leave.status !== 1 ? null : (
            <Button
              className="btn btn-light-danger "
              onClick={() => {
                openDrawer();
                setLeaveId(leave.id);
              }}
            >
              Cancel
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

export default CardDetails;
