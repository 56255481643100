import React from "react";
import {
  Row,
  Col,
  Input,
  Form,
  Upload,
  Modal,
  Radio,
  Button,
  Select,
  DatePicker,
  notification,
  Spin,Tooltip
} from "antd";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import moment from "moment";
import {
  applyForEmployeeLeave,
  getDateCalculationApi,
  getActiveEmployeeLeaves,
} from "../DataManager";
import _ from "lodash";

const CreateLeave = (props) => {
  const { fileImages, setFileImages } = props;
  const { closeModal, setDataUpdate } = props;
  const [daySyntex, setDaySyntex] = React.useState("Days");
  const [daySyntexBox, setDaySyntexBox] = React.useState("Days");
  const [sessionVisible, setSessionVisible] = React.useState(false);
  const [imageLoading, setImageLoading] = React.useState(false);
  const [leaveTypes, setLeaveTypes] = React.useState([]);

  const [formData, setFormData] = React.useState({
    userNamList: [],
    fromDate: "",
    toDate: "",
    fromSession: 0,
    toSession: 1,
    employeeLeaveId: "",
    notes: "",
    notifyUsers: "",
    images: [],
    previewVisible: false,
    previewImage: "",
  });
  const { setFields, validateFields } = props.form;
  const [dateDifference, setDateDifference] = React.useState(0);
  const [calculatedLeaves, setCalculatedLeaves] = React.useState("0");
  const [visible, setVisible] = React.useState(false);
  const [btnVisible, setBtnVisible] = React.useState(false);
  const [calculatedLeavesMessage, setCalculatedLeavesMessage] =
    React.useState("");
  // HANDLE DATE CHANGE || HANDLING TOTAL DAYS
  const handleDateChange = (dates, dateStrings) => {
    const [date1String, date2String] = dateStrings;
    if (date1String !== date2String) {
      getDateCalculation(date1String, date2String);
    }
    const time1 = new Date(date1String).getTime();
    const time2 = new Date(date2String).getTime();
    const Difference_In_Time = time2 - time1;
    const Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
    setDateDifference(Difference_In_Days + 1);
    Difference_In_Days === 0 ? setDaySyntex("Day") : setDaySyntex("Days");
    setFormData((prevValue) => {
      return {
        ...prevValue,
        fromDate: date1String,
        toDate: date2String,
        toSession: 1,
        fromSession: 0,
      };
    });

    if (date1String === date2String) {
      setCalculatedLeaves(1);
      setSessionVisible(true);
      setFormData((prevValue) => {
        return {
          ...prevValue,
          fromSession: 2,
        };
      });
    } else {
      setSessionVisible(false);
      setFormData((prevValue) => {
        return {
          ...prevValue,
          fromSession: 0,
        };
      });
    }
  };

  React.useEffect(() => {
    getActiveEmployeeLeaves()
      .then((res) => {
        setLeaveTypes(res.data);
      })
      .catch((error) => {
        notification.error(error.response.data.errors);
      });
  }, []);

  // Handling Full Day || Second Half leave -> START DATE
  const handleFromSession = (e) => {
    if (e.target.value === 1 && calculatedLeaves > 0 && !sessionVisible) {
      setCalculatedLeaves(calculatedLeaves - 0.5);
    } else if (
      e.target.value === 0 &&
      calculatedLeaves > 0 &&
      !sessionVisible
    ) {
      setCalculatedLeaves(calculatedLeaves + 0.5);
    }
    if (e.target.value === 1 && calculatedLeaves > 0 && sessionVisible) {
      setCalculatedLeaves(0.5);
    } else if (e.target.value === 0 && calculatedLeaves > 0 && sessionVisible) {
      setCalculatedLeaves(0.5);
    } else if (e.target.value === 2 && calculatedLeaves > 0 && sessionVisible) {
      setCalculatedLeaves(1);
    }
    setFormData((prevValue) => {
      return {
        ...prevValue,
        fromSession: e.target.value,
      };
    });
  };

  // Handling Full Day || Second Half leave -> END DATE
  const handleToSession = (e) => {
    if (e.target.value === 0 && calculatedLeaves > 0) {
      setCalculatedLeaves(calculatedLeaves - 0.5);
    } else if (e.target.value === 1 && calculatedLeaves > 0) {
      setCalculatedLeaves(calculatedLeaves + 0.5);
    }
    setFormData((prevValue) => {
      return {
        ...prevValue,
        toSession: e.target.value,
      };
    });
  };

  // SELECTING LEAVE TYPE
  const handleLeaveType = (value) => {
    setFormData((prevValue) => {
      return {
        ...prevValue,
        employeeLeaveId: value,
      };
    });
  };

  // SETTING REASON
  const handletextArea = (e) => {
    const notesMessage = e.target.value;
    setFormData((prevValue) => {
      return {
        ...prevValue,
        notes: notesMessage,
      };
    });
  };

  // SETTING USER TO NOTIFY
  const handleUserTextArea = (e) => {
    const notesMessage = e.target.value;
    setFormData((prevValue) => {
      return {
        ...prevValue,
        notifyUsers: notesMessage,
      };
    });
  };
  // for select box
  const { Option } = Select;
  const { TextArea } = Input;
  const { RangePicker } = DatePicker;

  const { getFieldDecorator } = props.form;

  // for upload
  function getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  }

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }

    setFormData((prevValue) => {
      return {
        ...prevValue,
        previewImage: file.url || file.preview,
        previewVisible: true,
      };
    });
  };

  // const handleChange = ({ fileList }) => setFileImages(fileList);

  const handleChange = (info) => {
    setFileImages(info.fileList);
    if (info.file.status === "uploading") {
      setImageLoading(true);
      return;
    }

    if (info.file.status === "done") {
      setImageLoading(false);
    }
    if (info.file.status === "error") {
      setImageLoading(false);
    }
  };
  const uploadButton = (
    <div>
      {imageLoading ? <LoadingOutlined /> : <PlusOutlined />}
      <div
        style={{
          marginTop: 8,
        }}
      >
        Upload
      </div>
    </div>
  );

  // for upload
  const handleCancel = () => {
    setFormData((prevValue) => {
      return {
        ...prevValue,
        previewVisible: false,
      };
    });
  };

  //Leave Calculation API
  const getDateCalculation = (fromDate, toDate) => {
    getDateCalculationApi(fromDate, toDate)
      .then((res) => {
        // setEmployees(res.data);
        setCalculatedLeaves(res.data.counts);
        setCalculatedLeavesMessage(res.data.message ? res.data.message : "");
        if (res.data.counts === 0) {
          setBtnVisible(true);
        } else {
          setBtnVisible(false);
        }
      })
      .catch((error) => {
        notification.error(error.response.data.errors);
      });
  };

  //Apply leave API
  const handleApply = (e) => {
    setVisible(true);
    setBtnVisible(true);
    validateFields((error) => {
      if (formData.fromDate === "" && formData.toDate === "") {
        setBtnVisible(false);
        setVisible(false);

        return false;
      }
      if (formData.employeeLeaveId === "") {
        setBtnVisible(false);
        setVisible(false);

        return false;
      }
      if (formData.notes === "") {
        setBtnVisible(false);
        setVisible(false);

        return false;
      }
      if (formData.quantity > 365) {
        notification.error({
          message: "The leave counts must not be greater than 365",
        });
        setBtnVisible(false);
        setVisible(false);

        return false;
      }
      if (!error) {
        let formdata = new FormData();
        for (var i = 0; i < fileImages.length; i++) {
          formdata.append(`images[${i}]`, fileImages[i].originFileObj);
        }
        formdata.append("employee_leave_id", formData.employeeLeaveId);
        formdata.append("from_date", formData.fromDate);
        formdata.append("to_date", formData.toDate);
        formdata.append("from_session", formData.fromSession);
        if (!sessionVisible) {
          formdata.append("to_session", formData.toSession);
        }
        formdata.append("counts", 2);
        formdata.append("notes", formData.notes);
        if (formData.notifyUsers) {
          formdata.append("notify_users", formData.notifyUsers);
        }
        applyForEmployeeLeave(formdata)
          .then((res) => {
            notification.success({
              message: "Applied successfully",
            });
            closeModal();
            setBtnVisible(false);
            setVisible(false);
            setDataUpdate();
            setFileImages([]);
          })
          .catch((error) => {
            if (error.response.status === 422) {
              let errors = error.response.data.errors;
              let errorObject = {};
              Object.keys(errors).forEach((key) => {
                let err = [];
                errors[key].forEach((value) => {
                  err.push(new Error(value));
                });
                key = _.camelCase(key.split(".")[0]);
                errorObject[key] = { value: formData[key], errors: err };
              });
              setFields(errorObject);
            } else {
              notification.error(error.response.data.errors);
            }
            setBtnVisible(false);
            setVisible(false);
          });
      }
    });
  };

  const disabledDate = (current) => {
    // Can not select days before today and today
    return current && current <= moment().startOf("day");
  };
  React.useEffect(() => {
    calculatedLeaves === 1 ? setDaySyntexBox("Day") : setDaySyntexBox("Days");
  }, [formData.fromSession, formData.toSession, handleDateChange]);

  const reasonText = () => {
    return(
      <div className="labelText">
        <div>Reason For Leave</div>
        <div className="label-bracket-text">(Explain in detail) <sup className="reasonSup">*</sup></div>
      </div>
    )
  }
  const labelText = () => {
    return(
      <div className="labelText">
        <div>Notify User</div>
        <div style={{marginLeft:'3px',fontSize:'0.8rem'}}>(Enter Email Id's, Seperated By Comma) </div>
      </div>
    )
  }

  return (
    <>
      {visible && (
        <div
          className="center-me"
          style={{
            position: "absolute",
            top: "0px",
            bottom: "0px",
            left: "0px",
            right: "0px",
            zIndex: "100",
          }}
        >
          <Spin />
        </div>
      )}

      {
        <div className="applyLeave-container">
          <form>
            {/* Select Dates */}
            <div className="form-content">
              <Row className="row">
                <Col
                  className="col"
                  xs={24}
                  sm={24}
                  md={24}
                  lg={24}
                  xl={24}
                  xxl={24}
                >
                  {/* Calender */}
                  <div className="select-leaveOption">
                    <Form.Item name="rangepicker" label={"Date"} colon={false}>
                      {getFieldDecorator("rangepicker", {
                        rules: [
                          {
                            required: true,
                            message: "Select valid dates",
                          },
                        ],
                      })(
                        <RangePicker
                          disabledDate={disabledDate}
                          onChange={handleDateChange}
                          separator={
                            <Input
                              placeholder={
                                isNaN(dateDifference)
                                  ? "0 Days"
                                  : dateDifference + " " + daySyntex
                              }
                              disabled
                              style={{
                                right: "40%",
                                left: "32%",
                                position: "absolute",
                                margin: "0 auto",
                                height: "100%",
                                width: "85px",
                                top: "0",
                              }}
                            />
                          }
                        />
                      )}
                    </Form.Item>
                  </div>
                  <div style={{ marginBottom: "1.5rem", color: "grey" }}>
                    Applying for {calculatedLeaves} {daySyntexBox}
                    <br />
                    {calculatedLeavesMessage}
                  </div>

                  <div className="radio-flex">
                    {/* Start Date Type */}
                    <Form.Item name="fromDate" label={"From"} colon={false}>
                      <div className="">
                        <Radio.Group
                          className=""
                          onChange={handleFromSession}
                          value={formData.fromSession}
                        >
                          <Radio className="u-margin-bottom " value={0}>
                            {formData.fromDate !== formData.toDate
                              ? "Full Day"
                              : "First Half"}
                          </Radio>
                          <br />
                          <Radio className="u-margin-bottom" value={1}>
                            Second Half
                          </Radio>
                          <br />
                          {!sessionVisible ? null : (
                            <Radio className="u-margin-bottom" value={2}>
                              Full Day
                            </Radio>
                          )}
                        </Radio.Group>
                      </div>
                    </Form.Item>

                    {/* End Date Type */}
                    <Form.Item name="toDate" label={"To"} colon={false}>
                      <div className="">
                        <Radio.Group
                          className=""
                          onChange={handleToSession}
                          value={formData.toSession}
                        >
                          <Radio
                            className="u-margin-bottom"
                            value={0}
                            disabled={sessionVisible}
                          >
                            First Half
                          </Radio>
                          <br />
                          <Radio
                            className="u-margin-bottom"
                            value={1}
                            disabled={sessionVisible}
                          >
                            {formData.fromDate !== formData.toDate
                              ? "Full Day"
                              : "Second Half"}
                          </Radio>
                        </Radio.Group>
                      </div>
                    </Form.Item>
                  </div>
                </Col>
              </Row>
            </div>

            {/* Leave Type */}
            <div className="form-content leaveType-cont">
              <Row className="row">
                <Col
                  className="col"
                  xs={24}
                  sm={24}
                  md={24}
                  lg={24}
                  xl={24}
                  xxl={24}
                >
                  <div className="form-group">
                    <Form.Item
                      name="employeeLeaveId"
                      label={"Leave Type"}
                      colon={false}
                    >
                      {getFieldDecorator("employeeLeaveId", {
                        rules: [
                          {
                            required: true,
                            message: "Select a leave type",
                          },
                        ],
                      })(
                        //  <div className="leaveType-block">
                        <Select
                          onChange={handleLeaveType}
                          placeholder="Please select Leave Type"
                        >
                          {leaveTypes.map((leave) => {
                            return (
                              <Option
                                key={leave.id}
                                value={leave.employee_leave_id}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                  }}
                                >
                                  <div className="employeeLeaveId">
                                    {leave.name}
                                  </div>
                                  <div className="leaveValue">
                                    {"(" +
                                      leave.remaining_counts +
                                      "/" +
                                      leave.initial_counts +
                                      ")"}
                                  </div>
                                </div>
                              </Option>
                            );
                          })}
                        </Select>
                        //  </div>
                      )}
                    </Form.Item>
                  </div>
                </Col>
              </Row>
            </div>

            {/* Reason */}
            <div className="form-content">
              <Row className="row">
                <Col
                  className="col"
                  xs={24}
                  sm={24}
                  md={24}
                  lg={24}
                  xl={24}
                  xxl={24}
                >
                  {/* <div className="form-group"> */}
                  <Form.Item
                    name="notes"
                    label={reasonText()}
                    colon={false}
                  >
                    {getFieldDecorator("notes", {
                      rules: [
                        {
                          required: true,
                          message: "Enter a reason",
                        },
                      ],
                    })(
                      <TextArea
                        placeholder="Enter a reason for leave..."
                        rows={3}
                        allowClear
                        onChange={handletextArea}
                      />
                    )}
                  </Form.Item>

                  <Form.Item
                    name="notifyUsers"
                    label={labelText()}
                    colon={false}
                  >
                    {getFieldDecorator("notifyUsers", {
                      rules: [
                        {
                          required: false,
                          message: "Enter valid emails",
                        },
                      ],
                    })(
                      <TextArea
                        placeholder="Enter users to notify..."
                        rows={2}
                        allowClear
                        onChange={handleUserTextArea}
                      />
                    )}
                  </Form.Item>
                  {/* </div> */}
                </Col>
              </Row>
            </div>

            <div className="form-content">
              <Row className="row">
                <Col
                  className="col"
                  xs={24}
                  sm={24}
                  md={24}
                  lg={24}
                  xl={24}
                  xxl={24}
                >
                  <div className="form-group">
                    <Form.Item label={"Attachment (If any)"} colon={false}>
                      <div className="clearfix">
                        <Upload
                          beforeUpload={() => {
                            return false;
                          }}
                          listType="picture-card"
                          fileList={fileImages}
                          onPreview={handlePreview}
                          onChange={handleChange}
                          showUploadList={{
                            showPreviewIcon: true,
                            showRemoveIcon: true,
                            showDownloadIcon: false,
                          }}
                          accept=".png,.jpeg,.jpg"
                        >
                          {fileImages.length >= 3 ? null : uploadButton}
                        </Upload>
                        <Modal
                          className="viewImgModal"
                          visible={formData.previewVisible}
                          footer={null}
                          onCancel={handleCancel}
                        >
                          <img
                            alt="example"
                            style={{ width: "100%" }}
                            src={formData.previewImage}
                          />
                        </Modal>
                      </div>
                    </Form.Item>
                  </div>
                </Col>
              </Row>
            </div>
          </form>
        </div>
      }
      <div className="formAction-footer-cont">
        <div className="formAction-footer text-right">
          <Button
            onClick={handleApply}
            className="btn btn-wide btn-success apply-btn text-uppercase"
            disabled={btnVisible}
          >
            Apply
          </Button>
        </div>
      </div>
    </>
  );
};

export default Form.create()(CreateLeave);
