import React, { useEffect } from "react";
import { Redirect } from "react-router-dom";
import { notification } from "antd";
import NotificationUpdate from "../../hocs/NotificationUpdate";
import { withStore } from "../../hocs/withStore";
import projectM from "../../assets/images/icons/sibebar/project-m.svg";
import { Helmet } from "react-helmet";
import _ from "lodash";
import ProjectWise from "./Components/ProjectWise";

const ProjDashboard = (props) => {
  const state = {
    wasActive: 0,
    hovering: null,
    user_roles_name: _.get(props, "store.data.user_roles_name", []),
    profile_pic: _.get(props, "store.data.profile_pic", null),
    imageLoading: false,
  };
  const { user_roles_name } = state;
  const admin = user_roles_name.indexOf("admin") !== -1;
  const project_manager = user_roles_name.indexOf("project_manager") !== -1;
  // const hr = user_roles_name.indexOf("hr") != -1;
  // const employee = user_roles_name.indexOf("employee") != -1;

  const openNotificationWithIcon = (type) => {
    notification[type]({
      message: "Access Denied",
      description:
        "You are not allowed to access this url, Redirecting to Dashboard Home page",
    });
  };

  useEffect(() => {
    if (!admin && !project_manager) {
      openNotificationWithIcon("error");
    }
  }, [project_manager, admin]);

  // for tabs
  // const { TabPane } = Tabs;
  return (
    <>
      <Helmet>
        <title>Project Dashboard</title>
      </Helmet>

      <div className="page-wrapper">
        {admin || project_manager ? (
          <>
            <div className="page-header">
              <div className="page-title">
                {/* {(admin || project_manager) && ( */}
                <h1 className="title">
                  <img
                    className="icon icon-itassets"
                    src={projectM}
                    alt="icon"
                  />{" "}
                  Project Dashboard
                </h1>
                {/* )} */}
              </div>
            </div>

            <section className="page-info-box">
              <ProjectWise />
            </section>
          </>
        ) : (
          <Redirect to="/dashboard/home" />
        )}
      </div>
    </>
  );
};

export default withStore(NotificationUpdate(ProjDashboard));
