import React, { Component, Fragment } from "react";
import { notification, Form, Button, Spin, Modal, Divider, Table, Row, Col, Input, Select } from "antd";
import { axiosInstance as api } from '../../../utilities/configureAxios';
import url from '../../../config/url';
import _ from 'lodash';
import data_setting from "../../../config/data_setting";
import { withStore } from "../../../hocs/withStore";
import RequireAuth from "../../../hocs/RequireAuth";

// for selectbox
const { Option } = Select;

class FilterEmployee extends Component {
  constructor(props) {
    super(props)
    this.state = {
      user_roles_name: _.get(props, "store.data.user_roles_name", []),
      levels: null,
      teams: null,
      skills: null,
      teams_loading: true,
      levels_loading: true,
      skills_loading: true,
      hobbies_loading:true,
      filter_skill: '',
      filter_hobby:'',
      filter_level: '',
      filter_team: '',
      employment_type:'',
      filter_is_x: ''
    }
  }

  static getDerivedStateFromProps(props, state) {
    return {
      filter_skill: props.filter_skill,
      filter_hobby: props.filter_hobby,
      filter_level: props.filter_level,
      filter_team: props.filter_team,
      employment_type:props.employment_type,
      filter_is_x: props.filter_is_x
    };
  }

  componentDidMount = () => {
    api
      .get(url["getTeams"])
      .then(res => {
        const teams = _.get(res, 'data.data', [])
        this.setState({ teams, teams_loading: false });
      })
      .catch(error => {
        notification['error']({
          message: _.get(error, 'response.data.errors.message', "Oops! Something went wrong!")
        });
        this.setState({ teams_loading: false });
      });

    api
      .get(url["getLevels"])
      .then(res => {
        const levels = _.get(res, 'data.data', [])
        this.setState({ levels, levels_loading: false });
      })
      .catch(error => {
        notification['error']({
          message: _.get(error, 'response.data.errors.message', "Oops! Something went wrong!")
        });
        this.setState({ levels_loading: false });
      });

    api
      .get(url["getSkills"])
      .then(res => {
        const skills = _.get(res, 'data.data', [])
        this.setState({ skills, skills_loading: false });
      })
      .catch(error => {
        notification['error']({
          message: _.get(error, 'response.data.errors.message', "Oops! Something went wrong!")
        });
        this.setState({ skills_loading: false });
      });

      api
      .get(url["getHobbies"])
      .then(res => {
        const hobbies = _.get(res, 'data.data', [])
        this.setState({ hobbies, hobbies_loading: false });
      })
      .catch(error => {
        notification['error']({
          message: _.get(error, 'response.data.errors.message', "Oops! Something went wrong!")
        });
        this.setState({ hobbies_loading: false });
      });
  }

  handleFilters = e => {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        const { handleSubmit } = this.props;

        if(values['is_x'] == undefined){
          values['is_x'] = true;
        }
    
        handleSubmit(values, this.props.form);
      }
    });
  }

  render() {
    const { getFieldDecorator } = this.props.form;
    const { hobbies,levels, teams, skills, teams_loading, levels_loading,hobbies_loading ,skills_loading, filter_skill,filter_hobby, filter_level, filter_team, filter_is_x, employment_type ,user_roles_name} = this.state
    const is_employee = user_roles_name.indexOf("employee") != -1;
    const is_hr = user_roles_name.indexOf("hr") !== -1;
    const is_admin = user_roles_name.indexOf("admin") !== -1;
    const is_office_admin = user_roles_name.indexOf("office_admin") !== -1;
    return (
      <Fragment>
        <div className="">
          <Row className="" gutter={24}>
            <Col className="gutter-row" xs={24} sm={24} md={24} lg={8} xl={8} xxl={8}>
              <div className="form-group">
                <label className="label">Team</label>
              </div>
            </Col>
            <Col className="gutter-row" xs={24} sm={24} md={24} lg={16} xl={16} xxl={16}>
              <div className="form-group">
                {getFieldDecorator('team', {
                  initialValue: filter_team
                })(
                  <Select
                    showSearch
                    style={{ width: '100%' }}
                    placeholder="Select a team"
                    optionFilterProp="children"
                    loading={teams_loading}
                    filterOption={(input, option) =>
                      option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    <Option key={0} value={''} > -Select team- </Option>
                    {teams && teams.map(team => (
                      <Option key={team.id} value={team.id}>{team.title}</Option>
                    ))}
                  </Select>)}
              </div>
            </Col>

            <Col className="gutter-row" xs={24} sm={24} md={24} lg={8} xl={8} xxl={8}>
              <div className="form-group">
                <label className="label">Skill</label>
              </div>
            </Col>
            <Col className="gutter-row" xs={24} sm={24} md={24} lg={16} xl={16} xxl={16}>
              <div className="form-group">
                {getFieldDecorator('skill', {
                  initialValue: filter_skill
                })(
                  <Select
                    showSearch
                    style={{ width: '100%' }}
                    placeholder="Select a skill"
                    optionFilterProp="children"
                    loading={skills_loading}
                    filterOption={(input, option) =>
                      option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    <Option key={0} value={''} > -Select skill- </Option>
                    {skills && skills.map(skill => (
                      <Option key={skill.id} value={skill.id} >{skill.title}</Option>
                    ))}
                  </Select>)}
              </div>
            </Col>
            <Col className="gutter-row" xs={24} sm={24} md={24} lg={8} xl={8} xxl={8}>
              <div className="form-group">
                <label className="label">Hobby</label>
              </div>
            </Col>
            <Col className="gutter-row" xs={24} sm={24} md={24} lg={16} xl={16} xxl={16}>
              <div className="form-group">
                {getFieldDecorator('hobby', {
                  initialValue: filter_hobby
                })(
                  <Select
                    showSearch
                    style={{ width: '100%' }}
                    placeholder="Select a hobby"
                    optionFilterProp="children"
                    loading={hobbies_loading}
                    filterOption={(input, option) =>
                      option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    <Option key={0} value={''} > -Select Hobby- </Option>
                    {hobbies && hobbies.map(hobby => (
                      <Option key={hobby.id} value={hobby.title} >{hobby.title}</Option>
                    ))}
                  </Select>)}
              </div>
            </Col>

            <Col className="gutter-row" xs={24} sm={24} md={24} lg={8} xl={8} xxl={8}>
              <div className="form-group">
                <label className="label">Level</label>
              </div>
            </Col>
            <Col className="gutter-row" xs={24} sm={24} md={24} lg={16} xl={16} xxl={16}>
              <div className="form-group">
                {getFieldDecorator('level', {
                  initialValue: filter_level
                })(
                  <Select
                    showSearch
                    style={{ width: '100%' }}
                    placeholder="Select a level"
                    optionFilterProp="children"
                    loading={levels_loading}
                    filterOption={(input, option) =>
                      option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    <Option key={0} value={''} > -Select level- </Option>
                    {levels && levels.map(level => (
                      <Option key={level.id} value={level.id} >{level.title}</Option>
                    ))}
                  </Select>)}
              </div>
            </Col>

            {(is_admin || is_office_admin || is_hr || !is_employee)&& (<Col className="gutter-row" xs={24} sm={24} md={24} lg={8} xl={8} xxl={8}>
              <div className="form-group">
                <label className="label">Current/ Ex Employee</label>
              </div>
            </Col>)}
            {(is_admin || is_office_admin || is_hr || !is_employee) && (<Col className="gutter-row" xs={24} sm={24} md={24} lg={16} xl={16} xxl={16}>
              <div className="form-group">
                {getFieldDecorator('is_x', {
                  initialValue: filter_is_x
                })(
                  <Select
                    showSearch
                    style={{ width: '100%' }}
                    placeholder="Select a type"
                    optionFilterProp="children"
                    loading={levels_loading}
                    filterOption={(input, option) =>
                      option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    <Option key={0} value={true} > Current Employees </Option>
                    <Option key={1} value={false} > Ex-Employees </Option>

                  </Select>)}
              </div>
            </Col>)}
            <Col className="gutter-row" xs={24} sm={24} md={24} lg={8} xl={8} xxl={8}>
              <div className="form-group">
                <label className="label">Employment Type</label>
              </div>
            </Col>
            <Col
                      className="gutter-row"
                      xs={24}
                      sm={24}
                      md={24}
                      lg={16} 
                      xl={16} 
                      xxl={16}
                    >
                      <div className="form-content form-group">
                            {getFieldDecorator('employment_type', {
                              initialValue: employment_type
                            })(
                              <Select
                                className="emp-type-selectbox selectbox"
                                showSearch
                                style={{ width: '100%' }}
                                placeholder="Select Employment Type"
                                optionFilterProp="children"
                              >
                                <Option key={0} value={''} > -Select Employement Type- </Option>
                                <Option
                                key={1}
                                  value={
                                    data_setting['employment']['Full-Time']
                                  }
                                >
                                  Full Time
                                </Option>
                                <Option
                                key={2}
                                  value={
                                    data_setting['employment']['Internship']
                                  }
                                >
                                  Internship
                                </Option>
                                <Option
                                key={3}
                                  value={data_setting['employment']['Contract']}
                                >
                                  Contract
                                </Option>
                              </Select>
                            )}
                      </div>
                    </Col>
          </Row>
        </div>
        <div className="text-right">
          <Button className="btn btn-light-success text-uppercase" onClick={this.handleFilters}>Submit</Button>
        </div>
      </Fragment >
    )
  }
}

export default Form.create({ name: 'filter' })(withStore(RequireAuth(FilterEmployee)));
