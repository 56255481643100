import React, { Component, Fragment } from 'react';
import {
  Row,
  Col,
  Input,
  Form,
  Button,
  Select,
  notification,
  Spin
} from 'antd';
import { leaveAction } from '../../DataManager';
import _ from "lodash"
// for select box
const { Option } = Select;


// for textarea
const { TextArea } = Input;


const DeclinedLeaves = (props) => {

  const loadLeaveData = props.loadLeaveData
  const [visible, setVisible] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  const [formData, setFormData] = React.useState({
    notes: "",
  });
  const { setFields, getFieldDecorator } = props.form;
  const closeModal = props.closeModal
  const leaveId = props.leaveId
  // SETTING REASON
  const handletextArea = (e) => {
    const notesMessage = e.target.value;
    setFormData((prevValue) => {
      return {
        ...prevValue,
        notes: notesMessage,
      };
    });
  };

  //for approve
  const handleSubmit = (status) => {
    setLoading(true);
    let notes = formData.notes.trim();
    if (status == 2 && !notes) {
      setLoading(false);
      let errorObject = {
        notes: {
          errors: [new Error("Please enter decline reason.")]
        }
      };
      setFields(errorObject);
    } else {
      leaveAction({
        status: status,
        action_reason: formData.notes,
      }, leaveId)
        .then((res) => {
          setLoading(false)
          notification.success({
            message: status == 2 ? "Declined successfully" : "Approved Successfully",
          });
          setVisible(false);
          setFormData((prevValue) => {
            return {
              ...prevValue,
              notes: '',
            };
          });
          closeModal()
          loadLeaveData()
        })
        .catch((error) => {
          setLoading(false)
          if (error.response.status === 422) {
            let errors = error.response.data.errors;
            let errorObject = {};
            Object.keys(errors).forEach((key) => {
              let err = [];
              errors[key].forEach((value) => {
                err.push(new Error(value));
              });
              key = _.camelCase(key);
              errorObject[key] = { errors: err };
            });
            setFields(errorObject);
          } else {
            notification.error(error.response.data.errors);
          }
        });
    }
  }

  return (

    <Fragment>
      <div className="declinedLeaves-container">
        {loading && (
          <div className="centerMe" style={{ height: '700px' }}>
            <Spin />
          </div>
        )}
        {!loading && (
          <Form>
            <div className="form-content">
              <Row className="row">
                <Col
                  className="col"
                  xs={24}
                  sm={24}
                  md={24}
                  lg={24}
                  xl={24}
                  xxl={24}
                >
                  <div className="form-group">
                    <Form.Item name="notes" label={'Reason'} colon={false}>
                    {getFieldDecorator("notes")(
                      <TextArea
                        placeholder="Enter a reason..."
                        rows={3}
                        allowClear
                        onChange={handletextArea}
                      />)}
                    </Form.Item>
                  </div>
                </Col>
              </Row>
            </div>
          </Form>
        )}
      </div>
      <div className="formAction-footer-cont">
        <div className="formAction-footer text-right ">
          <Button className="btn btn-wide btn-danger text-uppercase" onClick={() => handleSubmit(2)}>
            Decline
          </Button>

          {/* <Button className="btn btn-wide btn-success text-uppercase" onClick={() => handleSubmit(1)}>
            Approve
          </Button> */}
        </div>
      </div>
    </Fragment>

  );
}

export default Form.create()(DeclinedLeaves);
