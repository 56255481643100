import React, { Component, Fragment } from 'react';
import {
  notification,
  Icon,
  Input,
  Button,
  Popconfirm,
  Spin,
  Row,
  Col,
  Form,
  Table,
  Divider,
  Modal,
  Radio,
  Upload,
  Tooltip,
  Select,
  Card,
  Pagination,
} from 'antd';
import ForAdmin from '../../../../hocs/ForAdmin';
import { withStore } from '../../../../hocs/withStore';
import _ from 'lodash';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { axiosInstance as api } from '../../../../utilities/configureAxios';
import compDocument from '../../../../assets/images/icons/icon-comp-document-bg.svg';
import file_icon from '../../../../assets/images/icons/icon-upload.svg';
import url_icon from '../../../../assets/images/icons/icon-url.svg';
import url from '../../../../config/url';
import { Helmet } from 'react-helmet';
import data_setting from '../../../../config/data_setting';
import CompanyDocument from '../../../../assets/images/icons/sibebar/company-documents.svg';
import folder from '../../../../assets/images/icons/sibebar/folder-icon.svg';
import RequireAuth from '../../../../hocs/RequireAuth';
import ProfileComplete from '../../../../hocs/ProfileComplete';
import NotificationUpdate from '../../../../hocs/NotificationUpdate';
import {
  addFolder,
  getFolder,
  updateFolder,
  deleteFolder,
  getFolderWiseDocument,
} from './DataManager';

const { Search } = Input;
const TITLE = 'Documents';

// Pagination
const itemRender = (current, type, originalElement) => {
  if (type === 'prev') {
    return (
      <a className="back-icon">
        {' '}
        <Icon type="left" /> Back
      </a>
    );
  }
  if (type === 'next') {
    return (
      <a className="next-icon">
        Next <Icon type="right" />
      </a>
    );
  }
  return originalElement;
};

// for select
const { Option } = Select;

class DocumentAdminPanel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      loading2: false,
      documents: [],
      document_type: null,
      current_document: null,
      document_file: null,
      uploaded_file: null,
      visible: false,
      editFolder: null,
      slug: '',
      q: '',
      folders: null,
      folder_dropdown: null,
      pagination: {
        total: 0,
        count: 0,
        per_page: 1,
        current_page: 1,
        total_pages: 1,
      },
      pagination2: {
        total2: 0,
        count2: 0,
        per_page2: 1,
        current_page2: 1,
        total_pages2: 1,
      },
      current_active_page2: 1,

      modal1Visible: false,
    };
  }

  componentDidMount = () => {
    this.onPageLoad();
    this.getDocumentFolders();
    this.getDocumentFoldersPaginated();
  };

  onPageLoad = () => {
    this.setState({ loading: true });
    const { slug, q } = this.state;

    getFolderWiseDocument(slug, q, 1)
      .then((res) => {
        const documents = _.get(res, 'data.data', []);
        const pagination = _.get(res, 'data.meta.pagination', []);
        this.setState({ documents, loading: false, pagination });
      })
      .catch((error) => {
        notification['error']({
          message: _.get(
            error,
            'response.data.errors.message',
            'Oops! Something went wrong!'
          ),
        });
        this.setState({ loading: false });
      });
  };

  showAddNewModal = () => {
    this.setState({
      visible: true,
      current_document: null,
    });
  };

  columns = [
    {
      title: 'Document Name',
      dataIndex: 'title',
      className: 'document-name',
    },
    {
      title: 'Type',
      dataIndex: 'type',
      className: 'document-name',
      render: (value) => (value == 'file' ? 'File' : 'Link'),
    },
    {
      title: 'Added on',
      dataIndex: 'updated_at',
      className: 'document-date',
      render: (value) =>
        moment(value, 'YYYY-MM-DD HH:mm:ss').format('DD-MMM-YYYY'),
        //new Date(value).toDateString()
    },
    {
      title: 'Action',
      dataIndex: 'action',
      className: 'action',
      render: (action, document) => (
        <span>
          <a href={document.url} target="_blank">
            <Button className="btn btn-light-secondary btn-icon">
              {document.type == 'link' && (
                <Fragment>
                  <Icon type="link" />
                </Fragment>
              )}
              {document.type == 'file' && (
                <Fragment>
                  <Icon type="download" />
                </Fragment>
              )}
            </Button>
          </a>
          <Divider type="vertical" />
          <Tooltip placement="bottom" title={'Edit'}>
            <Button
              type="link"
              onClick={() => this.onDocumentEdit(document)}
              className="btn btn-light-info btn-icon text-uppercase"
            >
              <Icon type="edit" theme="filled" />
            </Button>
          </Tooltip>
          <Divider type="vericle" />
          <Popconfirm
            placement="bottomLeft"
            icon={
              <Icon type="warning" theme="twoTone" twoToneColor="#fcb103" />
            }
            title="Are you sure you want to delete this document?"
            onConfirm={() => this.onDocumentDelete(document.slug)}
            okText="Yes"
            cancelText="No"
          >
            <Tooltip placement="bottom" title={'Delete'}>
              <Button
                type="link"
                className="btn btn-light-danger btn-icon text-uppercase"
              >
                <Icon type="delete" theme="filled" />
              </Button>
            </Tooltip>
          </Popconfirm>
        </span>
      ),
    },
  ];

  onPageChange = (pageNumber) => {
    let state = this.state;
    state.loading = true;
    state.pagination.current_page = pageNumber;
    this.setState({ state });

    const { slug, q } = this.state;

    getFolderWiseDocument(slug, q, pageNumber)
      .then((res) => {
        const documents = _.get(res, 'data.data', []);
        const pagination = _.get(res, 'data.meta.pagination', []);
        this.setState({ documents, loading: false, pagination });
      })
      .catch((error) => {
        notification['error']({
          message: _.get(
            error,
            'response.data.errors.message',
            'Oops! Something went wrong!'
          ),
        });
        this.setState({ loading: false });
      });
  };

  onDocumentEdit = (current_document) => {
    if (current_document && current_document.type == 'file') {
      this.setState({
        document_file: true,
      });
    }
    this.setState({
      current_document,
      document_type: current_document.type,
      visible: true,
    });
  };

  onDocumentDelete = (document_slug) => {
    this.setState({ loading: true });
    api
      .delete(url['getDocuments'] + '/' + document_slug)
      .then((res) => {
        notification['success']({
          message: 'Document deleted successfully',
        });
        api
          .get(url['getDocuments'])
          .then((res) => {
            const documents = _.get(res, 'data.data', []);
            this.setState({ documents, loading: false });
          })
          .catch((error) => {
            notification['error']({
              message: _.get(
                error,
                'response.data.errors.message',
                'Oops! Something went wrong!'
              ),
            });
            this.setState({ loading: false });
          });
      })
      .catch((error) => {
        notification['error']({
          message: _.get(
            error,
            'response.data.errors.message',
            'Oops! Something went wrong!'
          ),
        });
        this.setState({ loading: false });
      });
  };

  handleOk = (e) => {
    const { uploaded_file, current_document } = this.state;
    e.preventDefault();
    this.props.form.validateFields(
      ['title', 'folder_id', 'type', 'file', 'url'],
      (err, formData) => {
        if (!err) {
          var form_data = new FormData();
          if (formData.type === 'file') {
            if (formData.file) {
              form_data.append('file', uploaded_file);
            }
          } else {
            if (!current_document || current_document.type === 'link')
              form_data.append('url', formData.url);
          }
          if (current_document && current_document.type === 'file') {
            if (formData.file) {
              form_data.append('file', uploaded_file);
            }
          }
          form_data.append('title', formData.title);
          form_data.append('folder_id', formData.folder_id);
          if (current_document)
            form_data.append(
              'type',
              current_document.type === 'file'
                ? data_setting['document']['file']
                : data_setting['document']['link']
            );
          else
            form_data.append(
              'type',
              formData.type === 'file'
                ? data_setting['document']['file']
                : data_setting['document']['link']
            );
          this.setState({ loading: true });
          api
            .post(
              current_document
                ? url['getDocuments'] + '/' + current_document.slug
                : url['createDocument'],
              form_data
            )
            .then((res) => {
              api
                .get(url['getDocuments'])
                .then((res) => {
                  const documents = _.get(res, 'data.data', []);
                  this.setState({
                    documents,
                    current_document: null,
                    uploaded_file: null,
                    loading: false,
                    visible: false,
                  });
                })
                .catch((error) => {
                  notification['error']({
                    message: _.get(
                      error,
                      'response.data.errors.message',
                      'Oops! Something went wrong!'
                    ),
                  });
                  this.setState({
                    current_document: null,
                    uploaded_file: null,
                    loading: false,
                    visible: false,
                  });
                });
              notification['success']({
                message:
                  'Document ' +
                  (current_document ? 'edited' : 'created') +
                  ' successfully!',
              });
            })
            .catch((error) => {
              const error_code = _.get(error, 'response.status');
              if (error_code && error_code == '422') {
                notification['error']({
                  message: 'Invalid Data',
                  description: Object.values(
                    _.get(
                      error,
                      'response.data.errors',
                      'Oops! Something went wrong!'
                    )
                  ),
                });
              } else {
                notification['error']({
                  message: _.get(
                    error,
                    'response.data.errors.message',
                    'Oops! Something went wrong!'
                  ),
                });
                this.setState({ visible: false });
              }
              this.setState({ loading: false });
            });
        }
      }
    );
  };

  handleCancel = (e) => {
    this.setState({
      visible: false,
      current_document: null,
      document_type: null,
      document_file: null,
    });
  };

  onTypeChange = (e) => {
    this.setState({
      document_type: e.target.value,
    });
  };

  onSearchDoc = (e) => {
    this.setState({ q: e.target.value }, () => {
      this.onPageLoad();
    });
  };

  setModal1Visible(modal1Visible) {
    this.setState({ editFolder: null, modal1Visible: modal1Visible });
  }

  showAddFolderModal() {
    this.setState({ modal1Visible: true });
  }

  getDocumentFolders = () => {
    return getFolder(1, false)
      .then((res) => {
        const folder_dropdown = res.data;
        this.setState({ folder_dropdown: folder_dropdown });
      })
      .catch((error) => {
        throw error;
      });
  };

  getDocumentFoldersPaginated = () => {
    const { page } = this.state.current_active_page2;

    return getFolder(page, true)
      .then((res) => {
        const folders = res.data;
        const total2 = res.meta.pagination.total;
        const per_page2 = res.meta.pagination.per_page;
        const current_page2 = res.meta.pagination.current_page;
        const count2 = res.meta.pagination.count;
        const total_pages2 = res.meta.pagination.total_pages;

        const pagination = {
          total2,
          per_page2,
          current_page2,
          count2,
          total_pages2,
        };

        this.setState({ folders: folders, pagination2: pagination });
      })
      .catch((error) => {
        notification['error']({
          message: _.get(
            error,
            'response.data.errors.message',
            'Oops! Something went wrong!'
          ),
        });
      });
  };

  onPageChange2 = (pageNumber) => {
    this.setState({ current_active_page2: pageNumber, loading2: true });

    return getFolder(pageNumber, true)
      .then((res) => {
        const folders = res.data;
        const total2 = res.meta.pagination.total;
        const per_page2 = res.meta.pagination.per_page;
        const current_page2 = res.meta.pagination.current_page;
        const count2 = res.meta.pagination.count;
        const total_pages2 = res.meta.pagination.total_pages;

        const pagination = {
          total2,
          per_page2,
          current_page2,
          count2,
          total_pages2,
        };

        this.setState({
          folders: folders,
          pagination2: pagination,
          loading2: false,
        });
      })
      .catch((error) => {
        notification['error']({
          message: _.get(
            error,
            'response.data.errors.message',
            'Oops! Something went wrong!'
          ),
        });
        this.setState({ loading2: false });
      });
  };

  addNewFolder = () => {
    const { editFolder } = this.state;

    if (editFolder) {
      this.props.form.validateFields(['name'], (err, values) => {
        if (!err) {
          this.setState({ loading: true });
          return updateFolder(values, editFolder.slug)
            .then((res) => {
              notification['success']({
                message: 'Folder edited successfully',
              });
              this.getDocumentFoldersPaginated();
              this.getDocumentFolders();
              this.setState({ loading: false, modal1Visible: false });
            })
            .catch((error) => {
              const error_code = _.get(error, 'response.status');
              if (error_code && error_code == '422') {
                notification['error']({
                  message: 'Invalid Data',
                  description: Object.values(
                    _.get(
                      error,
                      'response.data.errors',
                      'Oops! Something went wrong!'
                    )
                  ),
                });
              } else {
                notification['error']({
                  message: _.get(
                    error,
                    'response.data.errors.message',
                    'Oops! Something went wrong!'
                  ),
                });
              }
              this.setState({ loading: false });
            });
        }
      });
    } else {
      this.props.form.validateFields(['name'], (err, values) => {
        if (!err) {
          this.setState({ loading: true });
          return addFolder(values)
            .then((res) => {
              notification['success']({
                message: 'Folder added successfully',
              });
              this.getDocumentFoldersPaginated(1, true);
              this.getDocumentFolders();
              this.setState({ loading: false, modal1Visible: false });
            })
            .catch((error) => {
              const error_code = _.get(error, 'response.status');
              if (error_code && error_code == '422') {
                notification['error']({
                  message: 'Invalid Data',
                  description: Object.values(
                    _.get(
                      error,
                      'response.data.errors',
                      'Oops! Something went wrong!'
                    )
                  ),
                });
              } else {
                notification['error']({
                  message: _.get(
                    error,
                    'response.data.errors.message',
                    'Oops! Something went wrong!'
                  ),
                });
              }
              this.setState({ loading: false });
            });
        }
      });
    }
  };

  deleteFolder(slug) {
    this.setState({ loading: true });
    return deleteFolder(slug)
      .then((res) => {
        notification['success']({
          message: 'Folder deleted successfully',
        });

        this.getDocumentFoldersPaginated();
        this.getDocumentFolders();
        this.setState({ loading: false, modal1Visible: false });
      })
      .catch((error) => {
        notification['error']({
          message: _.get(
            error,
            'response.data.errors.message',
            'Oops! Something went wrong!'
          ),
        });
        this.setState({ loading: false });
      });
  }

  editFolder(folder) {
    this.setState({ modal1Visible: true, editFolder: folder });
  }

  render() {
    const { getFieldDecorator } = this.props.form;
    const {
      documents,
      document_type,
      current_document,
      visible,
      loading,
      folders,
      editFolder,
      folder_dropdown,
      loading2,
      q,
    } = this.state;
    const { total, per_page, current_page } = this.state.pagination;
    const { total2, per_page2, current_page2 } = this.state.pagination2;
    return (
      <Fragment>
        <Helmet>
          <title>{TITLE}</title>
        </Helmet>

        <div className="page-wrapper">
          <Fragment>
            <div className="documentAP-container">
              <Fragment>
                {/* page Header */}
                <div className="page-header">
                  <div className="page-title">
                    <h2 className="title">
                      <img
                        className="icon icon-view"
                        src={CompanyDocument}
                        alt="view icon"
                      />{' '}
                      Documents
                    </h2>
                  </div>
                  <div className="footer-buttons">
                    <Button
                      className="btn btn-primary text-uppercase btn-one"
                      onClick={() => this.showAddFolderModal()}
                    >
                      <Icon type="folder-add" /> Add Folder
                    </Button>
                    <Button
                      className="btn btn-success text-uppercase btn-second"
                      onClick={() => this.showAddNewModal()}
                    >
                      <Icon type="plus" /> Add New
                    </Button>
                  </div>
                </div>
              </Fragment>

              {/* page info title */}
              <section className="page-info-box">
                <h3 className="info-title">All Document</h3>
                <div className="right">
                  <div className="search-box">
                    <Input
                      onChange={this.onSearchDoc}
                      placeholder="Search Documents"
                      suffix={<Icon type="search" />}
                    />
                  </div>
                </div>
              </section>

              {loading ? (
                <center>
                  <Spin />
                </center>
              ) : (
                <section>
                  {/* Admin Panel- Document table */}
                  <section className="content-section document-table-info animated slideInUp">
                    {/* folder structure */}
                    {loading2 ? (
                      <center>
                        <Spin />
                      </center>
                    ) : (
                      <div className="docFolder-container document-container">
                        {q == '' && (
                          <div>
                            {folder_dropdown && folder_dropdown.length != 0 && (
                              <h3 className="text-primary">
                                Folder directories{' '}
                              </h3>
                            )}
                            <Row gutter={30} className="row">
                              {folders &&
                                folders.map((docFolder) => (
                                  <Col
                                    className="gutter-row col"
                                    xs={24}
                                    sm={24}
                                    md={24}
                                    lg={12}
                                    xl={12}
                                    xxl={12}
                                  >
                                    <Card className="card card-shadow docFolder-card">
                                      <div className="icon-box">
                                        <img
                                          className="icon icon-link"
                                          src={folder}
                                          alt="image"
                                        />
                                      </div>
                                      <div className="title-box">
                                        <h4 className="title">
                                          {docFolder.name}
                                        </h4>
                                      </div>
                                      <div className="action-box">
                                        <Tooltip
                                          placement="bottom"
                                          title={'View'}
                                        >
                                          <Link
                                            to={
                                              '/dashboard/admin/document-folder?id=' +
                                              docFolder.slug
                                            }
                                          >
                                            <Button className="btn btn-light-success btn-icon">
                                              <Icon type="eye" theme="filled" />
                                            </Button>
                                          </Link>
                                        </Tooltip>
                                        <Tooltip
                                          placement="bottom"
                                          title={'Edit'}
                                        >
                                          <Button
                                            onClick={() =>
                                              this.editFolder(docFolder)
                                            }
                                            className="btn btn-light-info btn-icon"
                                          >
                                            <Icon type="edit" theme="filled" />
                                          </Button>
                                        </Tooltip>
                                        <Popconfirm
                                          placement="bottomLeft"
                                          icon={
                                            <Icon
                                              type="warning"
                                              theme="twoTone"
                                              twoToneColor="#fcb103"
                                            />
                                          }
                                          title="Are you sure you want to Delete this Folder?"
                                          trigger="click"
                                          onConfirm={() =>
                                            this.deleteFolder(docFolder.slug)
                                          }
                                          okText="Yes"
                                          cancelText="No"
                                        >
                                          <Tooltip
                                            placement="bottom"
                                            title={'Delete'}
                                          >
                                            <Button className="btn btn-light-danger btn-icon">
                                              <Icon
                                                type="delete"
                                                theme="filled"
                                              />
                                            </Button>
                                          </Tooltip>
                                        </Popconfirm>
                                      </div>
                                    </Card>
                                  </Col>
                                ))}
                            </Row>

                            {total2 > 0 && per_page2 > 0 && total2 > per_page2 && (
                              <div className="pagination-box text-right">
                                <Pagination
                                  className="pagination"
                                  onChange={this.onPageChange2}
                                  current={current_page2}
                                  total={total2}
                                  itemRender={itemRender}
                                  pageSize={per_page2}
                                />
                              </div>
                            )}
                          </div>
                        )}
                      </div>
                    )}

                    <div className="docFile-conatiner document-container">
                      {folders && folders.length != 0 && (
                        <h3 className="text-primary">Root Directory</h3>
                      )}
                      {documents && documents.length != 0 && (
                        <Table
                          className="table-content"
                          columns={this.columns}
                          dataSource={documents}
                          rowKey={(documents) => documents.id}
                          pagination={{
                            onChange: this.onPageChange,
                            itemRender: itemRender,
                            current: current_page,
                            pageSize: per_page,
                            total: total,
                          }}
                        />
                      )}
                    </div>
                  </section>

                  {documents.length == 0 && (
                    <section className="page-info-box text-center">
                      <h3 className="info-title">Sorry! No documents found!</h3>
                    </section>
                  )}

                  {/* Add New Document or Edit Document Modal */}
                  <Modal
                    className="add-new-document-modal profileAP-modal"
                    title={
                      current_document ? 'Edit Document' : 'Add New Document'
                    }
                    centered
                    visible={visible}
                    onOk={this.handleOk}
                    destroyOnClose={true}
                    onCancel={this.handleCancel}
                    footer={[
                      <Button
                        key="back"
                        onClick={this.handleCancel}
                        className="btn btn-light-secondary text-uppercase"
                      >
                        Return
                      </Button>,
                      <Button
                        onClick={this.handleOk}
                        loading={loading}
                        key="submit"
                        className="btn btn-light-success text-uppercase"
                      >
                        Submit
                      </Button>,
                    ]}
                  >
                    <div className="add-new-document-modal-container">
                      <Form onSubmit={this.handleOk}>
                        <Row className="" gutter={24}>
                          <Col
                            className="gutter-row"
                            xs={24}
                            sm={24}
                            md={24}
                            lg={8}
                            xl={8}
                            xxl={8}
                          >
                            <div className="form-group">
                              <label className="label">Document Name</label>
                            </div>
                          </Col>
                          <Col
                            className="gutter-row"
                            xs={24}
                            sm={24}
                            md={24}
                            lg={16}
                            xl={16}
                            xxl={16}
                          >
                            <div className="form-group">
                              <Form.Item>
                                {getFieldDecorator('title', {
                                  initialValue:
                                    (current_document &&
                                      current_document.title) ||
                                    '',
                                  rules: [
                                    {
                                      required: true,
                                      message: 'Enter valid document name',
                                    },
                                  ],
                                  validateTrigger: 'onBlur',
                                })(
                                  <Input
                                    className="input"
                                    maxLength="255"
                                    placeholder="Enter document name"
                                    onBlur={(e) =>
                                      (e.target.value = e.target.value.trim())
                                    }
                                  />
                                )}
                              </Form.Item>
                            </div>
                          </Col>
                        </Row>
                        <Row className="" gutter={24}>
                          <Col
                            className="gutter-row"
                            xs={24}
                            sm={24}
                            md={24}
                            lg={8}
                            xl={8}
                            xxl={8}
                          >
                            <div className="form-group">
                              <label className="label">Select Folder</label>
                            </div>
                          </Col>
                          <Col
                            className="gutter-row"
                            xs={24}
                            sm={24}
                            md={24}
                            lg={16}
                            xl={16}
                            xxl={16}
                          >
                            <div className="form-group">
                              <Form.Item>
                                {getFieldDecorator('folder_id', {
                                  initialValue:
                                    (current_document &&
                                      current_document.folder.id) ||
                                    0,
                                  // rules: [
                                  //     {
                                  //         required: true,
                                  //         message: "Enter valid document name"
                                  //     }
                                  // ],
                                  // validateTrigger: 'onBlur'
                                })(
                                  <Select
                                    placeholder="Select Folder name"
                                    //defaultValue={current_document && current_document.folder.id || '0'}
                                  >
                                    <option value={0}>Root Folder</option>
                                    {folder_dropdown &&
                                      folder_dropdown.map((docFolder) => (
                                        <Option value={docFolder.id}>
                                          {docFolder.name}
                                        </Option>
                                      ))}
                                  </Select>
                                )}
                              </Form.Item>
                            </div>
                          </Col>
                        </Row>

                        <Row className="" gutter={24}>
                          <Col
                            className="gutter-row"
                            xs={24}
                            sm={24}
                            md={24}
                            lg={8}
                            xl={8}
                            xxl={8}
                          >
                            <div className="form-group">
                              <label className="label">Document Type</label>
                            </div>
                          </Col>
                          <Col
                            className="gutter-row"
                            xs={24}
                            sm={24}
                            md={24}
                            lg={16}
                            xl={16}
                            xxl={16}
                          >
                            <div className="form-group">
                              <Form.Item>
                                {getFieldDecorator('type', {
                                  initialValue:
                                    (current_document &&
                                      current_document.type) ||
                                    '',
                                  rules: [
                                    {
                                      required: true,
                                      message: 'Select valid document type',
                                    },
                                  ],
                                  validateTrigger: 'onBlur',
                                })(
                                  <Radio.Group
                                    className="radio-btn"
                                    onChange={this.onTypeChange}
                                  >
                                    <div className="radio-icon file-icon">
                                      <Radio
                                        value="file"
                                        className="male-radio"
                                        disabled={
                                          current_document ? true : false
                                        }
                                      >
                                        File
                                      </Radio>
                                      <img
                                        className="icon file"
                                        src={file_icon}
                                        alt="file"
                                      />
                                    </div>
                                    <div className="radio-icon url-icon">
                                      <Radio
                                        value="link"
                                        className="female-radio"
                                        disabled={
                                          current_document ? true : false
                                        }
                                      >
                                        URL
                                      </Radio>
                                      <img
                                        className="icon url"
                                        src={url_icon}
                                        alt="url"
                                      />
                                    </div>
                                  </Radio.Group>
                                )}
                              </Form.Item>
                            </div>
                          </Col>
                        </Row>

                        {document_type === 'file' && document_type !== 'link' && (
                          <Row className="" gutter={24}>
                            <Col
                              className="gutter-row"
                              xs={24}
                              sm={24}
                              md={24}
                              lg={8}
                              xl={8}
                              xxl={8}
                            >
                              <div className="form-group">
                                {current_document && (
                                  <label className="label">
                                    {' '}
                                    Upload new file
                                  </label>
                                )}
                                {!current_document && (
                                  <label className="label"> file</label>
                                )}
                              </div>
                            </Col>
                            <Col
                              className="gutter-row"
                              xs={24}
                              sm={24}
                              md={24}
                              lg={16}
                              xl={16}
                              xxl={16}
                            >
                              <div className="form-group">
                                <Form.Item>
                                  {getFieldDecorator('file', {
                                    rules: [
                                      !current_document && {
                                        message: 'Upload a document',
                                      },
                                    ],
                                    validateTrigger: 'onBlur',
                                  })(
                                    <Input
                                      className="input"
                                      type="file"
                                      accept=".pdf, application/pdf, .doc, application/msword, .docx, application/vnd.openxmlformats-officedocument.wordprocessingml.document, .jpeg, .jpg, image/jpeg, .png, image/png, .xls, application/vnd.ms-excel, .xlsx, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.google-apps.document, application/vnd.google-apps.spreadsheet"
                                      placeholder="Select document"
                                      onBlur={(e) =>
                                        this.setState({
                                          uploaded_file: e.target.files[0],
                                        })
                                      }
                                    />
                                  )}
                                </Form.Item>
                              </div>
                            </Col>
                          </Row>
                        )}
                        {document_type === 'link' && document_type !== 'file' && (
                          <Row className="" gutter={24}>
                            <Col
                              className="gutter-row"
                              xs={24}
                              sm={24}
                              md={24}
                              lg={8}
                              xl={8}
                              xxl={8}
                            >
                              <div className="form-group">
                                <label className="label">Link</label>
                              </div>
                            </Col>
                            <Col
                              className="gutter-row"
                              xs={24}
                              sm={24}
                              md={24}
                              lg={16}
                              xl={16}
                              xxl={16}
                            >
                              <div className="form-group">
                                <Form.Item>
                                  {getFieldDecorator('url', {
                                    initialValue:
                                      (current_document &&
                                        current_document.type == 'link' &&
                                        current_document.url) ||
                                      '',
                                    rules: [
                                      {
                                        type: 'url',
                                        message: 'Enter a valid url',
                                      },
                                      {
                                        required: true,
                                        message: 'Enter an url',
                                      },
                                    ],
                                    validateTrigger: 'onBlur',
                                  })(
                                    <Input
                                      className="input"
                                      type="url"
                                      maxLength="255"
                                      placeholder="Enter URL"
                                      onBlur={(e) =>
                                        (e.target.value = e.target.value.trim())
                                      }
                                    />
                                  )}
                                </Form.Item>
                              </div>
                            </Col>
                          </Row>
                        )}
                      </Form>
                    </div>
                  </Modal>

                  {/* Add Folder Modal */}
                  <Modal
                    className="addFolder-modal profileAP-modal"
                    title={editFolder ? 'Edit Folder' : 'Add New Folder'}
                    centered
                    visible={this.state.modal1Visible}
                    // onOk={() => this.setModal1Visible(false)}
                    onOk={() => this.addNewFolder()}
                    onCancel={() => this.setModal1Visible(false)}
                    footer={[
                      <Button
                        onClick={() => this.setModal1Visible(false)}
                        className="btn btn-light-secondary text-uppercase"
                      >
                        Return
                      </Button>,
                      <Button
                        onClick={() => this.addNewFolder()}
                        className="btn btn-light-success text-uppercase"
                      >
                        Submit
                      </Button>,
                    ]}
                  >
                    <div className="addFolder-modalContainer">
                      <Form onSubmit={this.handleOk}>
                        <Row className="" gutter={24}>
                          <Col
                            className="gutter-row"
                            xs={24}
                            sm={24}
                            md={24}
                            lg={8}
                            xl={8}
                            xxl={8}
                          >
                            <div className="form-group">
                              <label className="label">Folder Name</label>
                            </div>
                          </Col>
                          <Col
                            className="gutter-row"
                            xs={24}
                            sm={24}
                            md={24}
                            lg={16}
                            xl={16}
                            xxl={16}
                          >
                            <div className="form-group">
                              <Form.Item onSubmit={this.addNewFolder}>
                                {getFieldDecorator('name', {
                                  initialValue:
                                    (editFolder && editFolder.name) || '',
                                  rules: [
                                    {
                                      required: true,
                                      message: 'Enter valid document name',
                                    },
                                  ],
                                  validateTrigger: 'onBlur',
                                })(
                                  <Input
                                    autoFocus
                                    onPressEnter={this.addNewFolder}
                                    className="input"
                                    type="text"
                                    minLength="2"
                                    placeholder="Enter folder name"
                                  />
                                )}
                              </Form.Item>
                            </div>
                          </Col>
                        </Row>
                      </Form>
                    </div>
                  </Modal>
                </section>
              )}
            </div>
          </Fragment>
        </div>
      </Fragment>
    );
  }
}

export default Form.create({ name: 'add_document' })(
  ForAdmin(RequireAuth(withStore(ProfileComplete(NotificationUpdate(DocumentAdminPanel)))))
);
