import * as SERVICEs from "../../../services/LeaveService";

// GETTING EMPLOYEE LEAVE TYPE DATA
export const getEmployeeLeave = () => {
  return SERVICEs.getEmployeeLeaves()
    .then((result) => {
      return result.data;
    })
    .catch((error) => {
      throw error;
    });
};

export const getOtherEmployeeLeave = (user_id) => {
  return SERVICEs.getOtherEmployeeLeaves(user_id)
    .then((result) => {
      return result.data;
    })
    .catch((error) => {
      throw error;
    });
};

export const getActiveEmployeeLeaves = () => {
  return SERVICEs.getActiveEmployeeLeaves()
    .then((result) => {
      return result.data;
    })
    .catch((error) => {
      throw error;
    });
};

export const getActiveOtherEmployeeLeaves = (user_id) => {
  return SERVICEs.getActiveOtherEmployeeLeaves(user_id)
    .then((result) => {
      return result.data;
    })
    .catch((error) => {
      throw error;
    });
};




// APPLY FOR EMPLOYEE LEAVE
export const applyForEmployeeLeave = (body) => {
  return SERVICEs.applyForEmployeeLeave(body)
    .then((result) => {
      return result.data;
    })
    .catch((error) => {
      throw error;
    });
};

export const getMyAllLeave = (year,pageNumber) => {
  return SERVICEs.getMyLeaves(year,pageNumber)
    .then((result) => {
      return result.data;
    })
    .catch((error) => {
      throw error;
    });
};

export const getDateCalculationApi = (fromDate, toDate) => {
  return SERVICEs.getLeavesCalculated(fromDate, toDate)
    .then((result) => {
      return result.data;
    })
    .catch((error) => {
      throw error;
    });
};

// CREATE NOTIFICATION
export const handleNotificationCreate = (body) => {
  return SERVICEs.createNotification(body)
    .then((result) => {
      return result.data;
    })
    .catch((error) => {
      throw error;
    });
};
// EDIT NOTIFICATION
export const handleNotificationEDIT = (body,id) => {
  return SERVICEs.EDITNotification(body,id)
    .then((result) => {
      return result.data;
    })
    .catch((error) => {
      throw error;
    });
};

