import React, { Component, Fragment } from "react";
import { notification, Tabs, Spin } from "antd";
import { Helmet } from 'react-helmet';

import setting from '../../../../assets/images/icons/sibebar/settings.svg';

import AddSkills from './AddSkills/index.js';
import AddTeams from "./AddTeams/index.js";
import AddLevels from "./AddLevels/index.js";
import AddDevice from "./AddDevice/index.js";
import AddProjects from "./AddProjects/index.js";
import AddHobbies from "./AddHobbies/index.js";
import ManageLeavesTypes from "../LeaveManagement/components/Tabs/ManageLeavesTypes/ManageLeavesTypes";
import {
    getOrgSkill, addOrgSkill, editOrgSkill, deleteOrgSkill, getOrgTeam, deleteOrgTeam, addOrgTeam,
    getOrgLevel, addOrgLevel, deleteOrgLevel, addAssetType, getAssetType, deleteAssetType, getOrgProject, deleteOrgProject, addOrgProject,
    getHobby, addHobby, editHobby, deleteHobby
} from './DataManager';
import NotificationUpdate from "../../../../hocs/NotificationUpdate";
import ForAdmin from "../../../../hocs/ForAdmin";
import { withStore } from "../../../../hocs/withStore";

// for tabs
const { TabPane } = Tabs;
const TITLE = 'Settings'

class Settings extends Component {
    constructor(props) {
        super(props)
        this.state = {
        }
    }

    getSkillData = (pageNumber, paginate, q) => {
        return getOrgSkill(pageNumber, paginate, q);
    }

    addNewSkill = (data) => {
        return addOrgSkill(data);
    }

    editSkill = (slug, data) => {
        return editOrgSkill(slug, data);
    }

    deleteSkill = (slug) => {
        return deleteOrgSkill(slug);
    }

    getHobbyData = (pageNumber, paginate, q) => {
        return getHobby(pageNumber, paginate, q);
    }

    addNewHobby = (slug,data) => {
        return addHobby(data);
    }

    editHobby = (slug, data) => {
        return editHobby(slug, data);
    }

    deleteHobby = (slug) => {
        return deleteHobby(slug);
    }

    getTeamData = (pageNumber) => {
        return getOrgTeam(pageNumber);
    }

    deleteTeam = (slug) => {
        return deleteOrgTeam(slug);
    }

    addNewTeam = (data) => {
        return addOrgTeam(data);
    }

    getLevelData = (pageNumber) => {
        return getOrgLevel(pageNumber);
    }

    deleteLevel = (slug) => {
        return deleteOrgLevel(slug);
    }

    addNewLevel = (data) => {
        return addOrgLevel(data);
    }

    getAssetType = (pageNumber) => {
        return getAssetType(pageNumber);
    }

    deleteAssetType = (id) => {
        return deleteAssetType(id);
    }

    addAssetType = (data) => {
        return addAssetType(data);
    }

    getProjectData = (pageNumber) => {
        return getOrgProject(pageNumber);
    }

    deleteProject = (slug) => {
        return deleteOrgProject(slug);
    }

    addNewProject = (data) => {
        return addOrgProject(data);
    }

    render() {
        return (
            <Fragment>
                <Helmet>
                    <title>{TITLE}</title>
                </Helmet>
                <div className="page-wrapper">
                    <div className="settings-container">
                        {/* page Header */}
                        <div className="page-header">
                            <div className="page-title">
                                <h2 className="title"><img className="icon icon-view" src={setting} alt="view icon" /> Settings</h2>
                            </div>
                        </div>

                        {/* page info title */}
                        <section className="page-info-box animated bounceInRight">

                            <Tabs defaultActiveKey="1" >
                                <TabPane tab="Skills" key="1">
                                    <AddSkills
                                        getSkills={(pageNumber, paginate, q) => { return this.getSkillData(pageNumber, paginate, q) }}
                                        addNewSkill={(data) => { return this.addNewSkill(data) }}
                                        editSkill={(slug, data) => { return this.editSkill(slug, data) }}
                                        deleteSkill={(slug) => { return this.deleteSkill(slug) }}
                                    />
                                </TabPane>
                                <TabPane tab="Teams" key="2">
                                    <AddTeams
                                        getTeams={(pageNumber) => { return this.getTeamData(pageNumber) }}
                                        addNewTeam={(data) => { return this.addNewTeam(data) }}
                                        deleteTeam={(slug) => { return this.deleteTeam(slug) }}
                                    />
                                </TabPane>
                                <TabPane tab="Levels" key="3">
                                    <AddLevels
                                        getLevels={(pageNumber) => { return this.getLevelData(pageNumber) }}
                                        addNewLevel={(data) => { return this.addNewLevel(data) }}
                                        deleteLevel={(slug) => { return this.deleteLevel(slug) }}
                                    />
                                </TabPane>
                                <TabPane tab="Asset Types" key="4">
                                    <AddDevice
                                        getAssetTypes={(pageNumber) => { return this.getAssetType(pageNumber) }}
                                        addAssetTypes={(data) => { return this.addAssetType(data) }}
                                        deleteAssetTypes={(id) => { return this.deleteAssetType(id) }}
                                    />
                                </TabPane>
                                {/* <TabPane tab="Projects" key="5">
                                    <AddProjects
                                        getProjects={(pageNumber) => { return this.getProjectData(pageNumber) }}
                                        addNewProject={(data) => { return this.addNewProject(data) }}
                                        deleteProject={(slug) => { return this.deleteProject(slug) }}
                                    />
                                </TabPane> */}
                                <TabPane tab="Leaves Types" key="createLeaveType">
              <ManageLeavesTypes  />
            </TabPane>
                               <TabPane tab="Hobbies" key="7">
                                    <AddHobbies
                                        getHobbies={(pageNumber, paginate, q) => { return this.getHobbyData(pageNumber, paginate, q) }}
                                        addNewHobby={(slug,data) => { return this.addNewHobby(slug,data) }}
                                        editHobby={(slug, data) => { return this.editHobby(slug, data) }}
                                        deleteHobby={(slug) => { return this.deleteHobby(slug) }}
                                    />
                                </TabPane>
                            </Tabs>
                        </section>
                    </div>
                </div>
            </Fragment>
        )
    }
}


export default ForAdmin(withStore(NotificationUpdate(Settings)));