import * as SERVICEs from '../../../../services/DocumentService';

export const addFolder = (body) => {

    return SERVICEs.addFolderService(body)
        .then(result => {
            return result
        })
        .catch(error => {
            throw error
        })
}

export const getFolder = (page, pagination) => {
    return SERVICEs.getFolderService(page, pagination)
        .then(result => {
            return result.data
        })
        .catch(error => {
            throw error
        })
}

export const getSingleFolder = (slug) => {
    return SERVICEs.getSingleFolderService(slug)
        .then(result => {
            return result.data
        })
        .catch(error => {
            throw error
        })
}

export const updateFolder = (body, slug) => {
    return SERVICEs.updateFolderServie(body, slug)
        .then(result => {
            return result
        })
        .catch(error => {
            throw error
        })
}

export const deleteFolder = (slug) => {
    return SERVICEs.deleteFolderService(slug)
        .then(result => {
            return result
        })
        .catch(error => {
            throw error
        })
}

export const getFolderWiseDocument = (slug, q, page) => {
    return SERVICEs.getFolderWiseDocumentService(slug, q, page)
        .then(result => {
            return result
        })
        .catch(error => {
            throw error
        })
}