import React, { Component, Fragment } from 'react';
import {
  Icon,
  Row,
  Col,
  Input,
  Form,
  Upload,
  Modal,
  Radio,
  Button,
  Select,
  Popover,
  Checkbox,
} from 'antd';

// for checkbox
function onChangeCheckbox(e) {
  console.log(`checked = ${e.target.checked}`);
}

export default class ManageLeavesDrawer extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <Fragment>
        <div className="manageLeavesDrawer-comtainer">
          <h3 className="heading">Assigned leaves</h3>
          <form>
            <div className="head-row-content">
              <Row className="row">
                <Col
                  className="col"
                  xs={10}
                  sm={10}
                  md={10}
                  lg={10}
                  xl={10}
                  xxl={10}
                >
                  <div className="label v-hidden">Leave Type</div>
                </Col>
                <Col
                  className="col"
                  xs={14}
                  sm={14}
                  md={14}
                  lg={14}
                  xl={14}
                  xxl={14}
                >
                  <div className="label-box fx fx--ai-c">
                    <div className="label used">Used</div>
                    <div className="label total">Total</div>
                  </div>
                </Col>
              </Row>
            </div>
            <div className="body-row-content">
              <div className="form-content">
                <Row className="row">
                  <Col
                    className="col"
                    xs={10}
                    sm={10}
                    md={10}
                    lg={10}
                    xl={10}
                    xxl={10}
                  >
                    <div className="leaveType-box">
                      <div className="leaveType">
                        <Checkbox
                          onChange={onChangeCheckbox}
                          className="leaveTypeName success"
                        >
                          Sick/Casual
                        </Checkbox>
                      </div>
                    </div>
                  </Col>
                  <Col
                    className="col"
                    xs={14}
                    sm={14}
                    md={14}
                    lg={14}
                    xl={14}
                    xxl={14}
                  >
                    <div className="assignedLeaves-box fx fx--ai-c">
                      <div className="usedLeave-box">
                        <div className="inputbox">
                          <Input
                            disabled
                            className="input"
                            type="text"
                            inputMode="numeric"
                            pattern="[0-9]*"
                          />
                        </div>
                      </div>
                      <div className="totalLeave-box">
                        <div className="inputbox">
                          <Input
                            className="input"
                            type="text"
                            inputMode="numeric"
                            pattern="[0-9]*"
                          />
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>

              <div className="form-content">
                <Row className="row">
                  <Col
                    className="col"
                    xs={10}
                    sm={10}
                    md={10}
                    lg={10}
                    xl={10}
                    xxl={10}
                  >
                    <div className="leaveType-box">
                      <div className="leaveType">
                        <Checkbox
                          onChange={onChangeCheckbox}
                          className="leaveTypeName success"
                        >
                          Earned
                        </Checkbox>
                      </div>
                    </div>
                  </Col>
                  <Col
                    className="col"
                    xs={14}
                    sm={14}
                    md={14}
                    lg={14}
                    xl={14}
                    xxl={14}
                  >
                    <div className="assignedLeaves-box fx fx--ai-c">
                      <div className="usedLeave-box">
                        <div className="inputbox">
                          <Input
                            disabled
                            className="input"
                            type="text"
                            inputMode="numeric"
                            pattern="[0-9]*"
                          />
                        </div>
                      </div>
                      <div className="totalLeave-box">
                        <div className="inputbox">
                          <Input
                            className="input"
                            type="text"
                            inputMode="numeric"
                            pattern="[0-9]*"
                          />
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>

              <div className="form-content">
                <Row className="row">
                  <Col
                    className="col"
                    xs={10}
                    sm={10}
                    md={10}
                    lg={10}
                    xl={10}
                    xxl={10}
                  >
                    <div className="leaveType-box">
                      <div className="leaveType">
                        <Checkbox
                          onChange={onChangeCheckbox}
                          className="leaveTypeName success"
                        >
                          Paternity
                        </Checkbox>
                      </div>
                    </div>
                  </Col>
                  <Col
                    className="col"
                    xs={14}
                    sm={14}
                    md={14}
                    lg={14}
                    xl={14}
                    xxl={14}
                  >
                    <div className="assignedLeaves-box fx fx--ai-c">
                      <div className="usedLeave-box">
                        <div className="inputbox">
                          <Input
                            disabled
                            className="input"
                            type="text"
                            inputMode="numeric"
                            pattern="[0-9]*"
                          />
                        </div>
                      </div>
                      <div className="totalLeave-box">
                        <div className="inputbox">
                          <Input
                            className="input"
                            type="text"
                            inputMode="numeric"
                            pattern="[0-9]*"
                          />
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>

              <div className="form-content">
                <Row className="row">
                  <Col
                    className="col"
                    xs={10}
                    sm={10}
                    md={10}
                    lg={10}
                    xl={10}
                    xxl={10}
                  >
                    <div className="leaveType-box">
                      <div className="leaveType">
                        <Checkbox
                          onChange={onChangeCheckbox}
                          className="leaveTypeName success"
                        >
                          Maternity
                        </Checkbox>
                      </div>
                    </div>
                  </Col>
                  <Col
                    className="col"
                    xs={14}
                    sm={14}
                    md={14}
                    lg={14}
                    xl={14}
                    xxl={14}
                  >
                    <div className="assignedLeaves-box fx fx--ai-c">
                      <div className="usedLeave-box">
                        <div className="inputbox">
                          <Input
                            disabled
                            className="input"
                            type="text"
                            inputMode="numeric"
                            pattern="[0-9]*"
                          />
                        </div>
                      </div>
                      <div className="totalLeave-box">
                        <div className="inputbox">
                          <Input
                            className="input"
                            type="text"
                            inputMode="numeric"
                            pattern="[0-9]*"
                          />
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>

              <div className="form-content">
                <Row className="row">
                  <Col
                    className="col"
                    xs={10}
                    sm={10}
                    md={10}
                    lg={10}
                    xl={10}
                    xxl={10}
                  >
                    <div className="leaveType-box">
                      <div className="leaveType">
                        <Checkbox
                          onChange={onChangeCheckbox}
                          className="leaveTypeName success"
                        >
                          Compoffs
                        </Checkbox>
                      </div>
                    </div>
                  </Col>
                  <Col
                    className="col"
                    xs={14}
                    sm={14}
                    md={14}
                    lg={14}
                    xl={14}
                    xxl={14}
                  >
                    <div className="assignedLeaves-box fx fx--ai-c">
                      <div className="usedLeave-box">
                        <div className="inputbox">
                          <Input
                            disabled
                            className="input"
                            type="text"
                            inputMode="numeric"
                            pattern="[0-9]*"
                          />
                        </div>
                      </div>
                      <div className="totalLeave-box">
                        <div className="inputbox">
                          <Input
                            className="input"
                            type="text"
                            inputMode="numeric"
                            pattern="[0-9]*"
                          />
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          </form>
        </div>
        <div className="formAction-footer-cont">
          <div className="formAction-footer text-right">
            <Button className="btn btn-wide btn-success text-uppercase">
              Save
            </Button>
          </div>
        </div>
      </Fragment>
    );
  }
}
