import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { withStore } from './withStore';

export default ChildComponnent => {
  class RequireAuth extends Component {
    constructor() {
      super();
    }
    render() {
      const {
        store: {
          data: { user }
        }
      } = this.props;
      if (!user) {
        return <Redirect to="/" />;
      } else {
        return <ChildComponnent {...this.props} />;
      }
    }
  }

  return withStore(RequireAuth);
};
