import React, { Component, Fragment } from "react";
import {
  Icon,
  Row,
  Col,
  Input,
  Form,
  Upload,
  Modal,
  Button,
  DatePicker,
  Checkbox,
  Spin,
  notification,
} from "antd";
import { createBill, updateBill } from "../../../../services/billService";
import moment from "moment";
import imageCompression from "browser-image-compression";
import { validateImage } from "image-validator";



const { TextArea } = Input;

// for upload
function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}
class UploadBill extends Component {
  constructor(props) {
    super(props);
    this.state = {
      previewVisible: false,
      previewImage: "",
      fileList: [],
      hover: false,
      amount: "",
      title: "",
      description: "",
      editBill: this.props.data ? this.props.data : "",
      haveAgreedToTnc: this.props.data ? true : false,
      uploadedImages: (this.props.data && this.props.data.images) ? this.props.data.images : [],
      isBillCreateLoading: false,
      deleted_images: [],
      id: this.props.data ? this.props.data.id : "",
    };
  }

  // for calendar
  disabledStartDate = (current) => {
    const { getFieldValue } = this.props.form;
    let end_date = "";
    if (getFieldValue("end_date")) {
      end_date = getFieldValue("end_date");
    }
    if (end_date) {
      return current && (current > moment() || current > moment(end_date).endOf("day") || current < moment().subtract(3, 'months'));
    } else {
      return current > moment() || current < moment().subtract(3, 'months');
    }
  };
  // To validate a file
  fileValidation = async (file) => {
    return await validateImage(file);
  };
  disabledEndDate = (current) => {
    const { getFieldValue } = this.props.form;
    let start_date = "";
    if (getFieldValue("start_date")) {
      start_date = getFieldValue("start_date");
    }
    if (start_date) {
      return current && (current > moment() || current < moment(start_date).endOf("day").subtract("1", "day") || current < moment().subtract(3, 'months'));
    } else {
      return current > moment() || current < moment().subtract(3, 'months');
    }
  };

  onChange = (field, value) => {
    this.setState({
      [field]: value,
    });
  };

  onChangeUploadedImages = ({ file }) => {
    if (file.status == "removed") {
      this.setState(prevState => ({
        deleted_images: [...prevState.deleted_images, file.uid]
      }))
      var images = this.state.uploadedImages.filter(function (ele) {
        return ele.uid != file.uid;
      });
      this.setState({
        uploadedImages: images,
      });
    }
  };

  handleCancel = () => this.setState({ previewVisible: false });

  handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }

    this.setState({
      previewImage: file.url || file.preview,
      previewVisible: true,
    });
  };

  onCheckboxChange = (e) => {
    this.setState({ haveAgreedToTnc: e.target.checked });
  };
  appendNormal = (deleted, form_data) => {

    for (let i = 0; i < deleted.length; i++) {
      var file = deleted[i];
      form_data.append(`deleted_images[${i}]`, file);
    }
    return form_data;
  }
  appendImage = async (fileList, form_data) => {
    var options = {
      maxSizeKB: 500,
      maxWidthOrHeight: 500,
      useWebWorker: true,
    };
    for (let i = 0; i < fileList.length; i++) {
      var file = fileList[i].originFileObj;
      if (fileList[i].size > 5000000) {
        await imageCompression(file, options)
          .then((compressedFile) => {
            var upload_file = new File([compressedFile], file.name);
            return upload_file;
          })
          .then((file) => {
            form_data.append(`images[${i}]`, file);
          });
      } else {
        form_data.append(`images[${i}]`, file);
      }
    }
    return form_data;
  };
  handleChange = async ({ fileList, file }) => {
    if (file && file.status == 'removed') {
      this.setState({ fileList });
    } else {
      this.fileValidation(file).then((flag) => {
        if (flag == false) {
          notification.error({ message: "Unsupported Image" });
        } else if ((fileList[0] && fileList[0].size > 50000000)) {
          notification.error({ message: "Max Allowed Size of Image is 50MB" })
        } else if ((fileList[1] && fileList[1].size > 50000000)) {
          notification.error({ message: "Max Allowed Size of Image is 50MB" })
        }
        else {
          this.setState({ fileList });
        }
      });
    }
  }

  getFormattedDate = (date) => {
    return moment(date).format("YYYY-MM-DD");
  };

  getFormattedTime = (date) => {
    return moment(date).format("HH:mm");
  };

  showNotification = (type, title, subtitle) => {
    notification[type]({
      message: title,
      description: subtitle,
    });
  };

  handleHover = () => {
    this.setState({ hover: !this.state.hover });
  };

  submitUploadBill = (e) => {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll(async (err, values) => {
      if (!err) {
        this.setState({ isBillCreateLoading: true });
        const { deleted_images, fileList, editBill, id } = this.state;
        let startDate = values.start_date.format("YYYY-MM-DD HH:mm");
        let endDate = values.end_date.format("YYYY-MM-DD HH:mm");

        var formData = new FormData();
        formData = await this.appendImage(fileList, formData);
        formData.append("start_datetime", startDate);
        formData.append("end_datetime", endDate);
        formData.append("title", values.title);
        formData.append("description", values.description);
        formData.append("amount", values.amount);
        let call = "";
        if (editBill) {
          if (deleted_images.length > 0) {
            formData = this.appendNormal(deleted_images, formData);
          }
          call = updateBill;
        } else {
          call = createBill;
        }

        call(id, formData)
          .then((res) => {
            this.props.setIsBillModified(true);
            this.setState({ isBillCreateLoading: false });
            this.props.closeDrawer();
            if (editBill) {
              this.showNotification('success', "Bill Updated Successfully");
            } else {
              this.showNotification('success', "Bill Uploaded Successfully");
            }
          })
          .catch((error) => {
            this.setState({ isBillCreateLoading: false });
            if (error && error.response && error.response.status) {
              if (error.response.status === 422) {
                let errors = error.response.data.errors;
                let errorObject = {};
                Object.keys(errors).forEach((key) => {
                  let err = [];
                  errors[key].forEach((value) => {
                    err.push(new Error(value));
                  });

                  errorObject[key] = { value: formData[key], errors: err };
                });
                this.props.form.setFields(errorObject);
              }
            } else if (
              error &&
              error.response &&
              error.response.data &&
              error.response.data.errors
            ) {
              notification.error(error.response.data.errors);
            }
          });
      }
    });
  };

  render() {
    const { previewVisible, previewImage, fileList, editBill, uploadedImages } = this.state;
    const { getFieldDecorator } = this.props.form;
    const uploadButton = (
      <div>
        <Icon type="plus" />
        <div className="ant-upload-text">Upload</div>
      </div>
    );
    return (
      <Fragment>
        <div className="uploadBill-container">
          <Form>
            <div className="form-content">
              <Row className="row">
                <Col className="col" xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
                  <div className="from">
                    <Form.Item label="From Date">
                      {getFieldDecorator("start_date", {
                        initialValue: (editBill && editBill.start_datetime) ? moment(editBill.start_datetime) : null,
                        rules: [
                          {
                            required: true,
                            message: "Enter from date",
                          },
                        ],
                      })(
                        <DatePicker style={{ width: 250 }} disabledDate={this.disabledStartDate} />
                      )}
                    </Form.Item>
                  </div>
                </Col>
                <Col className="col" xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
                  <div className="to">
                    <Form.Item label="To Date">
                      {getFieldDecorator("end_date", {
                        initialValue: (editBill && editBill.end_datetime) ? moment(editBill.end_datetime) : null,
                        rules: [
                          {
                            required: true,
                            message: "Enter to date",
                          },
                        ],
                      })(<DatePicker style={{ width: 250 }} disabledDate={this.disabledEndDate} />)}
                    </Form.Item>
                  </div>
                </Col>
              </Row>
            </div>

            <div className="form-content">
              <Row className="row">
                <Col className="col" xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                  <div className="form-group">
                    <Form.Item label="Enter Amount">
                      {getFieldDecorator("amount", {
                        initialValue: (editBill && editBill.amount) ? editBill.amount : "",
                        rules: [
                          {
                            pattern: /^[1-9][0-9]*$/,
                            message: "Invalid Amount",
                          },
                          {
                            required: true,
                            message: "Enter Amount",
                          },
                        ],
                      })(
                        <Input
                          className="input"
                          placeholder="Enter amount"
                          maxLength={6}
                          inputMode="numeric"
                          onChange={(e) => this.setState({ amount: e.target.value })}
                        />
                      )}
                    </Form.Item>
                  </div>
                </Col>
              </Row>
            </div>

            <div className="form-content">
              <Row className="row">
                <Col className="col" xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                  <div className="form-group">
                    <Form.Item label="Enter title">
                      {getFieldDecorator("title", {
                        initialValue: (editBill && editBill.title) ? editBill.title : "",
                        rules: [
                          { min: 3, message: "Title must be minimum 3 characters." },
                          { max: 50, message: "Title must be maximum 50 characters." },
                          { whitespace: true, message: "(No White Space Allowed)" },
                          {
                            pattern: /[a-zA-Z0-9].+$/,
                            message: "Invalid Title",
                          },
                          {
                            required: true,
                            message: "Enter Title",
                          },
                        ],
                      })(
                        <Input
                          className="input"
                          placeholder="Enter title"
                          maxLength={100}
                          onChange={(e) => this.setState({ title: e.target.value })}
                          allowClear
                        />
                      )}
                    </Form.Item>
                  </div>
                </Col>
              </Row>
            </div>

            <div className="form-content">
              <Row className="row">
                <Col className="col" xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                  <div className="form-group">
                    <Form.Item label="Enter Description">
                      {getFieldDecorator("description", {
                        initialValue: (editBill && editBill.description) ? editBill.description : "",
                        rules: [
                          {
                            required: true,
                            message: "Enter description",
                          },
                          { min: 3, message: "Description must be minimum 3 characters." },
                          { whitespace: true, message: "(No White Space Allowed)" },
                        ],
                      })(
                        <TextArea
                          placeholder="Enter here..."
                          autoSize={{ minRows: 3, maxRows: 3 }}
                          style={{ resize: "none" }}
                          maxLength={255}
                          allowClear
                          onChange={(e) => this.setState({ description: e.target.value })}
                        />
                      )}
                    </Form.Item>
                  </div>
                </Col>
              </Row>
            </div>
            
            {editBill && editBill.images && editBill.images.length >= 0 && (
              <div className="form-content">
                <Row className="row" >
                  <Col className="col" xs={24} sm={24} md={24} lg={24} xl={24}>
                    <div className="form-group">
                      {uploadedImages && uploadedImages.length > 0 && (
                        <label className="label">Uploaded Images</label>
                      )}
                      <div className="clearfix">
                        <div className="upload-image fx">
                          <Upload
                            accept="image/png, image/jpeg, image/svg"
                            listType="picture-card"
                            fileList={uploadedImages}
                            onChange={this.onChangeUploadedImages}
                            showUploadList={{
                              showPreviewIcon: false,
                              showRemoveIcon: true,
                              showDownloadIcon: false,
                            }}
                          >
                            {null}
                          </Upload>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
            )}
            {uploadedImages && uploadedImages.length < 20 &&
              (<div className="form-content">
                <Row className="row">
                  <Col className="col" xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                    <div className="form-group">
                    <Form.Item label={<span>Attachments (Optional) (jpg,png) <i className="fas fa-info-circle" onMouseEnter={this.handleHover} onMouseLeave={this.handleHover} /></span>} colon={false}>
                          {this.state.hover && <p>max 20 images</p>}
                        <div className="clearfix">
                          <Upload
                            accept="image/png, image/jpeg, image/svg"
                            listType="picture-card"
                            beforeUpload={() => false}
                            fileList={fileList}
                            onPreview={this.handlePreview}

                            onChange={this.handleChange}
                            showUploadList={{
                              showPreviewIcon: false,
                              showRemoveIcon: true,
                              showDownloadIcon: false,
                            }}
                          >
                            {uploadedImages
                              ? uploadedImages.length + fileList.length >= 20
                                ? null
                                : uploadButton
                              : fileList.length >= 20
                                ? null
                                : uploadButton}
                          </Upload>
                          <Modal
                            className="viewImgModal"
                            visible={previewVisible}
                            footer={null}
                            onCancel={this.handleCancel}
                          >
                            <img alt="example" style={{ width: "100%" }} src={previewImage} />
                          </Modal>
                        </div>
                      </Form.Item>
                    </div>
                  </Col>
                </Row>
              </div>)}

            <div className="form-content">
              <Row className="row">
                <Col className="col" xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                  <div className="form-group">
                    <Checkbox className="success" onChange={this.onCheckboxChange} defaultChecked={this.state.editBill ? true : false}>
                      I hereby certify that the above statements are true and correct to the best of
                      my knowledge. I understand that a false statement may disqualify me for
                      benefits.{" "}
                    </Checkbox>
                  </div>
                </Col>
              </Row>
            </div>
          </Form>
        </div>

        <div className="formAction-footer-cont">
          <div className="formAction-footer text-right">
            <div className="upload-bill-btn-container">
              <Spin spinning={this.state.isBillCreateLoading}>
                <Button
                  disabled={this.state.haveAgreedToTnc === false}
                  onClick={this.submitUploadBill}
                  className="btn btn-success btn-wide text-uppercase"
                >
                  Upload
                </Button>
              </Spin>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default Form.create()(UploadBill);
