import React, { Component, Fragment } from "react";
import Profile from "./components/Profile";
import RequireAuth from "../../../hocs/RequireAuth";
import { withStore } from "../../../hocs/withStore";
import ForAdminAndHr from "../../../hocs/ForAdminAndHr";
class AdminProfileManagement extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <Fragment>
        <div className="page-wrapper">
          <Profile
            employee_uid={this.props.employee_uid} 
            closeModal={this.props.closeModal}
            />
        </div>
      </Fragment>
    );
  }
}

export default ForAdminAndHr(withStore(AdminProfileManagement))
