import * as APIs from "../../../../services/SettingsService";
import * as AssetTypeAPIs from "../../../../services/AssetService";

export const addOrgSkill = (state) => {
  let body = {
    title: state,
  };
  return APIs.addOrgSkillService(body)
    .then((result) => {
      return result;
    })
    .catch((error) => {
      throw error;
    });
};

export const editOrgSkill = (slug, data) => {
  let body = {
    title: data,
  };

  return APIs.updateOrgSkillService(slug, body)
    .then((result) => {
      return result;
    })
    .catch((error) => {
      throw error;
    });
};

export const deleteOrgSkill = (state) => {
  return APIs.deleteOrgSkillService(state)
    .then((result) => {
      return result;
    })
    .catch((error) => {
      throw error;
    });
};

export const getOrgSkill = (pageNumber, paginate, q) => {
  return APIs.getOrgSkillService(pageNumber, paginate, q)
    .then((result) => {
      return result.data;
    })
    .catch((error) => {
      throw error;
    });
};

export const addHobby = (body) => {
return APIs.addHobbyService(body)
    .then((result) => {
      return result;
    })
    .catch((error) => {
      throw error;
    });
};

export const editHobby = (slug, data) => {
  return APIs.updateHobbyService(slug, data)
    .then((result) => {
      return result;
    })
    .catch((error) => {
      throw error;
    });
};

export const deleteHobby = (state) => {
  return APIs.deleteHobbyService(state)
    .then((result) => {
      return result;
    })
    .catch((error) => {
      throw error;
    });
};

export const getHobby = (pageNumber, paginate, q) => {
  return APIs.getHobbyService(pageNumber, paginate, q)
    .then((result) => {
      return result.data;
    })
    .catch((error) => {
      throw error;
    });
};

export const getOrgTeam = (pageNumber) => {
  return APIs.getOrgTeamService(pageNumber)
    .then((result) => {
      return result.data;
    })
    .catch((error) => {
      throw error;
    });
};

export const addOrgTeam = (data) => {
  let body = {
    title: data,
  };

  return APIs.addOrgTeamService(body)
    .then((result) => {
      return result;
    })
    .catch((error) => {
      throw error;
    });
};

export const deleteOrgTeam = (slug) => {
  return APIs.deleteOrgTeamService(slug)
    .then((result) => {
      return result;
    })
    .catch((error) => {
      throw error;
    });
};

export const getOrgLevel = (pageNumber) => {
  return APIs.getOrgLevelService(pageNumber)
    .then((result) => {
      return result.data;
    })
    .catch((error) => {
      throw error;
    });
};

export const addOrgLevel = (data) => {
  let body = {
    title: data,
  };

  return APIs.addOrgLevelService(body)
    .then((result) => {
      return result;
    })
    .catch((error) => {
      throw error;
    });
};

export const deleteOrgLevel = (slug) => {
  return APIs.deleteOrgLevelService(slug)
    .then((result) => {
      return result;
    })
    .catch((error) => {
      throw error;
    });
};

export const addAssetType = (data) => {
  return AssetTypeAPIs.addAssetTypesService(data)
    .then((result) => {
      return result;
    })
    .catch((error) => {
      throw error;
    });
};

export const getAssetType = (pageNumber) => {
  return AssetTypeAPIs.getPaginatedAssetTypesService(true, pageNumber)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      throw error;
    });
};

export const deleteAssetType = (id) => {
  return AssetTypeAPIs.deleteAssetTypesService(id)
    .then((result) => {
      return result;
    })
    .catch((error) => {
      throw error;
    });
};

export const getOrgProject = (pageNumber = 1, bulk = false) => {
  console.log("DATAMANAGER ", pageNumber);

  return APIs.getOrgProjectService(pageNumber, bulk)
    .then((result) => {
      return result.data;
    })
    .catch((error) => {
      throw error;
    });
};

export const addOrgProject = (data) => {
  let body = {
    title: data,
  };

  return APIs.addOrgProjectService(body)
    .then((result) => {
      return result;
    })
    .catch((error) => {
      throw error;
    });
};

export const deleteOrgProject = (uuid) => {
  return APIs.deleteOrgProjectService(uuid)
    .then((result) => {
      return result;
    })
    .catch((error) => {
      throw error;
    });
};
