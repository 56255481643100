import { getWorkReportsForAdminService, getWorkReportsForAdminOfAnEmployeeService, getFiterableEmployees } from '../../../../services/DailyStatusService'

export const getWorkReportsForAdmin = (page, date, user_id) => {

    return getWorkReportsForAdminService(page, date, user_id)
        .then(result => {
            return result.data
        })
        .catch(error => {
            throw error
        })
}

export const getWorkReportsForAdminOfAnEmployee = (page, fromDate, toDate, user_id) => {

    return getWorkReportsForAdminOfAnEmployeeService(page, fromDate, toDate, user_id)
        .then(result => {
            return result.data
        })
        .catch(error => {
            throw error
        })
}

export const getFilterableEmployees = () => {
    return getFiterableEmployees()
        .then(result => {
            return result.data
        })
        .catch(error => {
            throw error
        })
}
