import React, { useState } from "react";
import NotificationUpdate from "../../hocs/NotificationUpdate";
import { withStore } from "../../hocs/withStore";
import { Helmet } from 'react-helmet';
import _ from "lodash";
import SelfAttendance from "./components/SelfAttendance";



const Attendance = (props) => {
  const state = {
    wasActive: 0,
    hovering: null,
    user_roles_name: _.get(props, "store.data.user_roles_name", [])
  };
  
  const [autoUpdate, setAutoUpdate] = useState([]);
  

  return (
    <>
          <Helmet>
          <title>Attendance</title>
        </Helmet>
      <div className="page-wrapper">
        {/* page Header */}
        <div className="page-header">
          <div className="page-title">
           { (<h1 className="title">
             {" "}
              Attendance
            </h1>)}
           </div>
              </div> 
      </div>
      <section className="page-wrapper">
              < SelfAttendance autoUpdate={autoUpdate} />
        </section>
    </>
  );
};

export default withStore(NotificationUpdate(Attendance));
