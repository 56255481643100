import React, { Component, Fragment } from "react";
import { notification, Tabs, Spin } from "antd";

import ViewPersonalDetails from "../ViewPersonalDetails";
import ViewTechnicalSkills from "../ViewTechnicalSkills";
import ViewVehicleDetails from "../ViewVehicleDetails";
import ViewHobbies from "../ViewHobbies";
import ViewEmergencyContacts from "../ViewEmergencyContacts";
import ViewContractEmployeeDetails from "../ViewContractEmployeeDetails";
import ViewBankDetails from "../ViewBankDetails";
import ViewEmployeeDetails from "../ViewEmployeeDetails";
import RequireAuth from "../../../../hocs/RequireAuth";
import { withStore } from "../../../../hocs/withStore";
import _ from "lodash";
import { axiosInstance as api } from "../../../../utilities/configureAxios";
import url from "../../../../config/url";

// for tabs
const { TabPane } = Tabs;

class ViewProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      employee: null,
      loading: false,
      user_roles_name: _.get(props, "store.data.user_roles_name", []),
    };
  }

  componentDidMount() {
    this.setState({ loading: true });
    api
      .get(
        url["getEmployeeDetails"] +
          "/" +
          this.props.employee_uid +
          "?includes=skills,hobbies"
      )
      .then((res) => {
        const employee = _.get(res, "data.data", []);
        this.setState({ employee, loading: false });
      })
      .catch((error) => {
        notification["error"]({
          message: _.get(
            error,
            "response.data.errors.message",
            "Oops! Something went wrong!"
          ),
        });
        this.setState({ loading: false });
      });
  }

  render() {
    const { employee, loading, user_roles_name } = this.state;
    return (
      <Fragment>
        <div className="profile-dashboard-container view-profile-container">
          {loading ? (
            <center>
              <Spin />
            </center>
          ) : employee &&
            employee.employee_details &&
            employee.employee_details.employment_type !== "Contract" ? (
            <section className="page-info-box">
              <Tabs defaultActiveKey="1">
                <TabPane tab="Personal Details" key="1">
                  <ViewPersonalDetails
                    personal_details={
                      employee ? employee.personal_details : null
                    }
                  />
                </TabPane>
                <TabPane tab="Skills" key="2">
                  <ViewTechnicalSkills
                    user_skills={employee ? employee.skills : null}
                  />
                </TabPane>

                <TabPane tab="Hobbies" key="3">
                  <ViewHobbies
                    user_hobbies={employee ? employee.hobbies : null}
                  />
                </TabPane>
                {/* <TabPane tab="Vehicle Details" key="3">
                                <ViewVehicleDetails></ViewVehicleDetails>
                            </TabPane> */}
                <TabPane tab="Emergency Contacts" key="4">
                  <ViewEmergencyContacts
                    emergency_contact_1={
                      employee && employee.emergency_contacts
                        ? employee.emergency_contacts.emergency_contact_1
                        : null
                    }
                    emergency_contact_2={
                      employee && employee.emergency_contacts
                        ? employee.emergency_contacts.emergency_contact_2
                        : null
                    }
                  />
                </TabPane>
                {user_roles_name.indexOf("admin") != -1 && (
                  <TabPane tab="Financial Details" key="5">
                    <ViewBankDetails
                      bank_details={employee ? employee.bank_details : null}
                    />
                  </TabPane>
                )}
                <TabPane tab="Employee Details" key="6">
                  <ViewEmployeeDetails
                    employee_details={
                      employee ? employee.employee_details : null
                    }
                  />
                </TabPane>
              </Tabs>
            </section>
          ) : (
            <ViewContractEmployeeDetails
              contractEmployeeDetails={employee ? employee : null}
              profileKey={false}
            />
          )}
        </div>
      </Fragment>
    );
  }
}

export default RequireAuth(withStore(ViewProfile));
