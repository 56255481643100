import React, { useState, useEffect } from "react";
import {
  Icon,
  Button,
  Input,
  Drawer,
  Modal,
  Pagination,
  Select,
  DatePicker,
  Collapse,
  notification,
  Spin,
} from "antd";
import { get } from "lodash";
import bill from "../../../assets/images/icons/sibebar/bill-management.svg";
import CardHeaderMultipleDay from "./MultipleDay/CardHeaderMultipleDay";
import CardDetailMultipleDay from "./MultipleDay/CardDetailMultipleDay";
import UploadBill from "./UploadBillDrawer/UploadBill";
import BillReason from "./BillReason";
import PaidDetails from "./PaidDetails";
import { deleteBill, getBills } from "../../../services/billService";
import { Empty } from "antd";
import { useStateWithCallback } from "../../../hocs/UseStateWithCallback";
import ApproveDate from "./ApproveDate";

// for selectbox
const { Option } = Select;

const { RangePicker } = DatePicker;

// Collapse
const { Panel } = Collapse;

const EmpBillManagementDashboard = (props) => {
  const [visible1, setVisible1] = useState(false);
  const [assignedAccordionKey, setAssignedAccordionKey] = useState();
  const [page, setPage] = useStateWithCallback(1);
  const [isBillModified, setIsBillModified] = useState(false);
  const [isEmp, setIsEmp] = useState(props.isEmpBill);
  const [bills, setBills] = useState([]);
  const [isGetBillLoading, setIsGetBillLoading] = useState(false);
  const [metaData, setMetaData] = useState({});
  const [isConfirmModalVisible, setisConfirmModalVisible] = useState(false);
  const [billToBeDeleted, setBillToBeDeleted] = useState({});
  const [isDeleteLoading, setIsDeleteLoading] = useState(false);
  const [selectedFilterStatus, setSelectedFilterStatus] = useState(null);
  const [selectedItems, setSelectedItems] = useState([]);
  const [isStatusUpdateLoading, setIsStatusUpdateLoading] = useState(false);
  const [isPaidDetailsDrawerVisible, setIsPaidDetailsDrawerVisible] = useState(false);
  const [isApproveDetailsDrawerVisible, setIsApproveDetailsDrawerVisible] = useState(false);
  const [search, setSearch] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [isReasonDrawerVisible, setIsReasonDrawerVisible] = useState(false);
  const [selectedIdForStatusUpdate, setSelectedIdForStatusUpdate] = useState("");
  const [flag, setFlag] = useState(false);
  const [editBill, setEditBill] = useState(null);
  // for drawer
  const showDrawer1 = () => {
    setVisible1(true);
  };

  const onClose1 = () => {
    setVisible1(false);
    setEditBill(null);
  };

  const onEditBill = (val) => {
    setVisible1(true);
    setEditBill(val);
  };
  // For accordion dropdown click
  const assignedAccordionToggle = (key) => {
    setAssignedAccordionKey(assignedAccordionKey === key ? null : key);
  };

  // Pagination
  function itemRender(current, type, originalElement) {
    if (type === "prev") {
      return (
        <a className="back-icon">
          {" "}
          <Icon type="left" /> Back
        </a>
      );
    }
    if (type === "next") {
      return (
        <a className="next-icon">
          Next <Icon type="right" />
        </a>
      );
    }
    return originalElement;
  }
  const hideConfirmModal = () => {
    setisConfirmModalVisible(false);
  };

  useEffect(() => {
    if (isBillModified) {
      setIsBillModified(false);
    }
  }, [isBillModified]);

  const showNotification = (type, title, subtitle) => {
    notification[type]({
      message: title,
      description: subtitle,
    });
  };

  const fetchBills = (setLoading, page) => {
    setLoading(true);
    let sDate = startDate === null ? null : `${startDate} 00:00`;
    let eDate = endDate === null ? null : `${endDate} 00:00`;

    let parameters = {
      include: "services",
      page: page,
      start_datetime: sDate,
      end_datetime: eDate,
      isEmp: isEmp,
      status: selectedFilterStatus,
      q: search,
    };

    getBills(parameters)
      .then((res) => {
        setBills(get(res, "data.data", []));
        setAssignedAccordionKey(null);
        setMetaData(get(res, "data.meta.pagination", {}));
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
        console.log("bill get err", e);
      });
    setSelectedItems([]);
    setFlag(true);
  };

  const handlePagination = (val) => {
    setPage(val, (prevValue, newValue) => {
      fetchBills(setIsGetBillLoading, val);
    });
  };

  // * Gets the bill first time
  useEffect(() => {
    const timer = setTimeout(() => {
      if (flag) {
        setPage(1, (prevValue, newValue) => {
          fetchBills(setIsGetBillLoading, 1);
        });
      }
    }, 500);
    return () => clearTimeout(timer);
  }, [search]);

  useEffect(() => {
    fetchBills(setIsGetBillLoading, page);
  }, [isBillModified]);

  useEffect(() => {
    setPage(1, (prevValue, newValue) => {
      fetchBills(setIsGetBillLoading, 1);
    });
  }, [selectedFilterStatus, startDate, endDate]);

  const handleDeleteVendor = (id) => {
    setIsDeleteLoading(true);
    deleteBill(id)
      .then((res) => {
        setIsDeleteLoading(false);
        showNotification("success", "Bill Deleted Successfully");
        setIsBillModified(true);
      })
      .catch((e) => {
        setIsDeleteLoading(false);
        showNotification("error", "Cannot delete Bill");
      });
    hideConfirmModal();
  };

  const { total, count, per_page, current_page, total_pages } = metaData;
  const statusTypes = ["approved", "pending", "disapproved", "paid"];

  return (
    <>
      <div className="leave-dashboard-container admin">
        <div className="page-header">
          <div className="page-title">
            <h2 className="title ">
              <img className="icon icon-itassets " src={bill} alt="view icon" />
              <span>{props.isEmpBill ? "My Bills" : "Bill Management"}</span>
            </h2>
          </div>
          {props.isEmpBill && (
            <Button className="btn text-uppercase btn-primary btn-one" onClick={showDrawer1}>
              <span className="lg-hide">
                <Icon type="plus" />
              </span>
              Upload Bill
            </Button>
          )}
        </div>
        <section className="content-section leaveRequest-section">
          <div className="content-header">
            <div className="left filters fx fx--ai-c">
              <div className="part filterfirst-part">
                <div className="" id="calendarContainer">
                  <RangePicker
                    separator={<Icon type="swap-right" />}
                    format={"DD-MM-YYYY"}
                    onChange={(value) => {
                      if (value[0] !== undefined) {
                        setStartDate(value[0].format("DD-MM-YYYY"));
                      } else {
                        setStartDate(null);
                      }
                      if (value[1] !== undefined) {
                        setEndDate(value[1].format("DD-MM-YYYY"));
                      } else {
                        setEndDate(null);
                      }
                    }}
                  />
                </div>
              </div>
              <div className="part filterSecond-part fx fx--ai-c">
                <div className="statusFilter-box filter-box" style={{ marginRight: "10px" }}>
                  {/* STATUS  */}
                  <Select
                    className="status-selectbox selectbox"
                    allowClear={true}
                    onChange={(value) => setSelectedFilterStatus(value)}
                    placeholder="All Status"

                    // value={payload.statuses}
                  >
                    <Option value="pending">Pending</Option>
                    <Option value="disapproved">Disapproved</Option>
                    <Option value="approved">Approved</Option>
                    <Option value="paid">Paid</Option>
                  </Select>
                </div>
              </div>
            </div>
            <div className="right">
              <div className="search-box">
                <Input
                  placeholder={
                    props.isEmpBill ? "Search By Expensed On" : "Search By Name OR Expensed On"
                  }
                  style={{ width: 250 }}
                  allowClear={true}
                  onChange={(e) => setSearch(e.target.value)}
                  suffix={<Icon type="search" />}
                />
              </div>
            </div>
          </div>

          {/* ----------------------------------------------------------------- */}
          {isGetBillLoading || isStatusUpdateLoading || isDeleteLoading ? (
            <div className="page-spinner">
              <Spin />
            </div>
          ) : (
            <div className="content-body">
              {bills && bills.length === 0 && (
                <div className="head-row-box">
                  <div className="part first-part">
                    <div className="head-title item date">Date</div>
                    <div className="head-title item empName">Name</div>
                    <div className="head-title item leaveType">Amount</div>
                  </div>
                  <div className="part second-part">
                    <div className="head-title item status" style={{ paddingLeft: "1rem" }}>
                      Status
                    </div>
                    <div className="head-title item status">Action</div>
                  </div>
                </div>
              )}
              <div className="body-row-box">
                <Collapse
                  className="leave-description-card"
                  bordered={false}
                  expandIconPosition={"right"}
                  activeKey={assignedAccordionKey}
                >
                  {bills && bills.length === 0 ? (
                    <div className="center-me">
                      <Empty />
                    </div>
                  ) : (
                    bills &&
                    bills.map((bill, index) => {
                      return (
                        <Panel
                          header={
                            <CardHeaderMultipleDay
                              setClickedItem={setBillToBeDeleted}
                              setisConfirmModalVisible={setisConfirmModalVisible}
                              data={bill}
                              setIsBillModified={setIsBillModified}
                              isEmpBill={props.isEmpBill}
                              showNotification={showNotification}
                              setSelectedItems={setSelectedItems}
                              selectedItems={selectedItems}
                              isStatusUpdateLoading={isStatusUpdateLoading}
                              setIsStatusUpdateLoading={setIsStatusUpdateLoading}
                              statusTypes={statusTypes}
                              onEditBill={onEditBill}
                              setIsReasonDrawerVisible={setIsReasonDrawerVisible}
                              setIsPaidDetailsDrawerVisible={setIsPaidDetailsDrawerVisible}
                              setIsApproveDetailsDrawerVisible={setIsApproveDetailsDrawerVisible}
                              selectedIdForStatusUpdate={selectedIdForStatusUpdate}
                            />
                          }
                          key={index + 1}
                          showArrow={false}
                          extra={
                            <Icon
                              className="dropArrowPosition"
                              type={assignedAccordionKey === index + 1 ? "up" : "down"}
                              onClick={() => assignedAccordionToggle(index + 1)}
                            />
                          }
                        >
                          <CardDetailMultipleDay data={bill} />
                        </Panel>
                      );
                    })
                  )}
                </Collapse>
              </div>
            </div>
          )}
        </section>
        {/* Pagination */}
        {bills && bills.length > 0 && (
          <section className="pagination-container">
            <div className="pagination-box text-right">
              <Pagination
                className="pagination"
                current={page || 1}
                pageSize={per_page || 8}
                onChange={(val) => handlePagination(val)}
                itemRender={itemRender}
                total={total}
              />
            </div>
          </section>
        )}
        {/* for upload bill */}
        <Drawer
          className="navigator-drawer uploadBill-drawer"
          title={editBill ? "Edit Bill" : "Upload Bill"}
          placement="right"
          width={550}
          destroyOnClose={true}
          maskClosable={false}
          onClose={onClose1}
          visible={visible1}
          bodyStyle={{ padding: 0 }}
        >
          <UploadBill
            data={editBill}
            setIsBillModified={setIsBillModified}
            closeDrawer={onClose1}
          />
        </Drawer>
        {/* Reason of disapproving Drawer */}
        <Drawer
          className="navigator-drawer uploadBill-drawer"
          title="Reason for Disapproving Bill"
          placement="right"
          destroyOnClose={true}
          maskClosable={false}
          width={500}
          onClose={() => setIsReasonDrawerVisible(false)}
          visible={isReasonDrawerVisible}
          bodyStyle={{ padding: 0 }}
        >
          {/* < setIsBillModified={setIsBillModified} closeDrawer={onClose1} /> */}
          <BillReason
            closeDrawer={() => setIsReasonDrawerVisible(false)}
            isStatusUpdateLoading={isStatusUpdateLoading}
            setIsStatusUpdateLoading={setIsStatusUpdateLoading}
            setIsBillModified={setIsBillModified}
            id={billToBeDeleted.id}
          />
        </Drawer>
        {/* Bank Details Drawer */}
        <Drawer
          className="navigator-drawer uploadBill-drawer"
          title="Payment Details"
          placement="right"
          destroyOnClose={true}
          maskClosable={false}
          width={500}
          onClose={() => setIsPaidDetailsDrawerVisible(false)}
          visible={isPaidDetailsDrawerVisible}
          bodyStyle={{ padding: 0 }}
        >
          {/* < setIsBillModified={setIsBillModified} closeDrawer={onClose1} /> */}
          <PaidDetails
            closeDrawer={() => setIsPaidDetailsDrawerVisible(false)}
            isStatusUpdateLoading={isStatusUpdateLoading}
            setIsStatusUpdateLoading={setIsStatusUpdateLoading}
            setIsBillModified={setIsBillModified}
            id={billToBeDeleted.id}
          />
        </Drawer>

        {/* approve reason drawer */}
        <Drawer
          className="navigator-drawer uploadBill-drawer"
          title="Expected Pay Date"
          placement="right"
          destroyOnClose={true}
          maskClosable={false}
          width={500}
          onClose={() => setIsApproveDetailsDrawerVisible(false)}
          visible={isApproveDetailsDrawerVisible}
          bodyStyle={{ padding: 0 }}
        >
          {/* < setIsBillModified={setIsBillModified} closeDrawer={onClose1} /> */}
          <ApproveDate
            closeDrawer={() => setIsApproveDetailsDrawerVisible(false)}
            isStatusUpdateLoading={isStatusUpdateLoading}
            setIsStatusUpdateLoading={setIsStatusUpdateLoading}
            setIsBillModified={setIsBillModified}
            id={billToBeDeleted.id}
          />
        </Drawer>
        <Modal
          title="Delete Bill"
          visible={isConfirmModalVisible}
          onOk={() => handleDeleteVendor(billToBeDeleted.id)}
          okButtonProps={{ type: "danger" }}
          onCancel={hideConfirmModal}
          okText="Yes, Delete"
          cancelText="Cancel"
          keyboard
        >
          <Spin spinning={isDeleteLoading} />
          Are your sure you want to delete this Bill?
        </Modal>
      </div>
    </>
  );
};

export default EmpBillManagementDashboard;
